import { Stack } from '@fluentui/react';
import React from 'react';
import { Field } from 'react-final-form';
import { ProjectViewModel } from '../../common/models';
import { AddOrUpdateExpirationFormFieldGroup } from '../../environment/expiration-date/add-or-update-expiration-form-field-group/add-or-update-expiration-form-field-group';
import { AddEnvironmentFormFieldName, EnvironmentDefinitionViewModel, EnvironmentTypeViewModel } from '../models';
import AddEnvironmentFormEnvironmentDefinitionControl from './add-environment-form-environment-definition-control';
import AddEnvironmentFormEnvironmentTypeControl from './add-environment-form-environment-type-control';
import AddEnvironmentFormNameControl from './add-environment-form-name-control';
import AddEnvironmentFormProjectControl from './add-environment-form-project-control';

interface AddEnvironmentFormFieldGroupProps {
    isSubmitting: boolean;
    projects: ProjectViewModel[];
    environmentTypes: EnvironmentTypeViewModel[];
    environmentDefinitions: EnvironmentDefinitionViewModel[];
    environmentDefinitionOnChange: (value: EnvironmentDefinitionViewModel | undefined) => void;
    selectedProject: ProjectViewModel | undefined;
    selectedEnvironmentDefinition: EnvironmentDefinitionViewModel | undefined;
    selectedEnvironmentType: EnvironmentTypeViewModel | undefined;
    didSomeProjectFailToLoadCreateResources: boolean;
    enableExpiration: boolean;
}

const containerTokens = { childrenGap: 16 };

export const AddEnvironmentFormFieldGroup: React.FC<AddEnvironmentFormFieldGroupProps> = (props) => {
    const {
        isSubmitting,
        projects,
        environmentTypes,
        environmentDefinitions,
        environmentDefinitionOnChange,
        selectedEnvironmentDefinition,
        selectedEnvironmentType,
        selectedProject,
        didSomeProjectFailToLoadCreateResources,
        enableExpiration,
    } = props;

    return (
        <Stack tokens={containerTokens}>
            <Stack.Item>
                <Field<string> name={AddEnvironmentFormFieldName.EnvironmentName}>
                    {(fieldProps) => {
                        const { input, meta } = fieldProps;
                        const { value, onChange } = input;
                        const { error, pristine } = meta;

                        const errorMessage = !pristine ? error : undefined;

                        return (
                            <AddEnvironmentFormNameControl
                                environmentName={value}
                                onChange={onChange}
                                isSubmitting={isSubmitting}
                                errorMessage={errorMessage}
                            />
                        );
                    }}
                </Field>
            </Stack.Item>
            <Stack.Item>
                <Field<ProjectViewModel> name={AddEnvironmentFormFieldName.SelectedProject}>
                    {(fieldProps) => {
                        const { input, meta } = fieldProps;
                        const { onChange } = input;
                        const { error, pristine } = meta;

                        const errorMessage = !pristine ? error : undefined;

                        return (
                            <AddEnvironmentFormProjectControl
                                projects={projects}
                                onChange={onChange}
                                isSubmitting={isSubmitting}
                                selectedProject={selectedProject}
                                errorMessage={errorMessage}
                                showFailedToLoadWarning={didSomeProjectFailToLoadCreateResources}
                            />
                        );
                    }}
                </Field>
            </Stack.Item>
            {!!selectedProject && (
                <Stack.Item>
                    <Field<EnvironmentTypeViewModel> name={AddEnvironmentFormFieldName.SelectedEnvironmentType}>
                        {(fieldProps) => {
                            const { input, meta } = fieldProps;
                            const { onChange } = input;
                            const { error, pristine } = meta;

                            const errorMessage = !pristine ? error : undefined;

                            return (
                                <AddEnvironmentFormEnvironmentTypeControl
                                    environmentTypes={environmentTypes}
                                    onChange={onChange}
                                    isSubmitting={isSubmitting}
                                    selectedEnvironmentType={selectedEnvironmentType}
                                    errorMessage={errorMessage}
                                />
                            );
                        }}
                    </Field>
                </Stack.Item>
            )}
            {!!selectedProject && (
                <Stack.Item>
                    <Field<EnvironmentDefinitionViewModel>
                        name={AddEnvironmentFormFieldName.SelectedEnvironmentDefinition}
                    >
                        {(fieldProps) => {
                            const { meta } = fieldProps;
                            const { error, pristine } = meta;

                            const errorMessage = !pristine ? error : undefined;

                            return (
                                <AddEnvironmentFormEnvironmentDefinitionControl
                                    environmentDefinitions={environmentDefinitions}
                                    onChange={environmentDefinitionOnChange}
                                    isSubmitting={isSubmitting}
                                    selectedEnvironmentDefinition={selectedEnvironmentDefinition}
                                    errorMessage={errorMessage}
                                />
                            );
                        }}
                    </Field>
                </Stack.Item>
            )}
            {!!selectedProject && (
                <AddOrUpdateExpirationFormFieldGroup enableExpiration={enableExpiration} isSubmitting={isSubmitting} />
            )}
        </Stack>
    );
};

export default AddEnvironmentFormFieldGroup;
