import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { isValidDataPlaneUri, parseDataPlanePath } from './data-plane';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const customizationTaskDefinitionPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.Catalog}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.CustomizationTask}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createCustomizationTaskDefinitionDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'catalogName' | 'devCenter' | 'projectName' | 'customizationTaskName'>
): string => {
    const { catalogName, devCenter, projectName, customizationTaskName } = tokens;

    if (isUndefinedOrWhiteSpace(catalogName)) {
        throw new ClientError('Catalog name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(customizationTaskName)) {
        throw new ClientError('Customization task name token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.Catalog,
        catalogName.toLowerCase(),
        DataPlaneResourcePathPrefix.CustomizationTask,
        customizationTaskName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromCustomizationTaskDefinitionDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'catalogName' | 'devCenter' | 'projectName' | 'customizationTaskName'> => {
    if (!isValidCustomizationTaskDefinitionDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a customization task');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , catalogName, , customizationTaskName] = parseDataPlanePath(pathname);

    return {
        catalogName,
        devCenter: origin,
        projectName,
        customizationTaskName,
    };
};

export const isValidCustomizationTaskDefinitionDataPlaneUri = (uri: string): boolean =>
    isValidDataPlaneUri(uri, customizationTaskDefinitionPathPattern);

export const tryCreateCustomizationTaskDefinitionDataPlaneUri = tryOrDefault(
    createCustomizationTaskDefinitionDataPlaneUri
);
export const tryGetTokensFromCustomizationTaskDefinitionDataPlaneUri = tryOrDefault(
    getTokensFromCustomizationTaskDefinitionDataPlaneUri
);
