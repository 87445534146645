import { createDevBoxDataPlaneUri } from '../../ids/dev-box';
import { getTokensFromDevBoxActionDataPlaneUri } from '../../ids/dev-box-action';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxActionAdapter } from '../adapters/dev-box-action-adapters';
import { StoreState } from '../store/store-state';
import { createGroupedMapFromEntitiesSelector, createMapFromEntitiesSelector } from './common';

const getDevBoxIdForDevBoxActionId = (devBoxActionId: string) => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxActionDataPlaneUri(devBoxActionId);

    return createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user });
};

/**
 * Entity state selectors
 */

const devBoxActionSelectors = devBoxActionAdapter.getSelectors<StoreState>(
    (store) => store.devBoxActionStore.data.devBoxActions
);

const statusesForDelayAllDevBoxActionsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxActionStore.status.delayAllDevBoxActions
);

const statusesForDelayDevBoxActionSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxActionStore.status.delayDevBoxAction
);

const statusesForListDevBoxActionsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxActionStore.status.listDevBoxActions
);

const statusesForSkipAllDevBoxActionsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxActionStore.status.skipAllDevBoxActions
);

const statusesForSkipDevBoxActionSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxActionStore.status.skipDevBoxAction
);

/**
 * Composed selectors
 */

export const getDevBoxActionsGroupedByDevBoxId = createGroupedMapFromEntitiesSelector(
    devBoxActionSelectors.selectAll,
    (entity) => getDevBoxIdForDevBoxActionId(entity.id)
);

export const getStatusesForDelayAllDevBoxActionsByDevBoxId = createMapFromEntitiesSelector(
    statusesForDelayAllDevBoxActionsSelectors.selectAll
);

export const getStatusesForDelayDevBoxActionGroupedByDevBoxId = createGroupedMapFromEntitiesSelector(
    statusesForDelayDevBoxActionSelectors.selectAll,
    (entity) => getDevBoxIdForDevBoxActionId(entity.id)
);

export const getStatusesForListDevBoxActionsByDevBoxId = createMapFromEntitiesSelector(
    statusesForListDevBoxActionsSelectors.selectAll
);

export const getStatusesForSkipAllDevBoxActionsByDevBoxId = createMapFromEntitiesSelector(
    statusesForSkipAllDevBoxActionsSelectors.selectAll
);

export const getStatusesForSkipDevBoxActionGroupedByDevBoxId = createGroupedMapFromEntitiesSelector(
    statusesForSkipDevBoxActionSelectors.selectAll,
    (entity) => getDevBoxIdForDevBoxActionId(entity.id)
);
