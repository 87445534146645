import { UnionMap } from '../../types/union-map';

export type LocalizationState = 'Error' | 'NotReady' | 'Ready';

export const LocalizationState: UnionMap<LocalizationState> = {
    Error: 'Error',
    NotReady: 'NotReady',
    Ready: 'Ready',
};

export type LocalizationDataState = 'Configured' | 'Error' | 'NotConfigured';

export const LocalizationDataState: UnionMap<LocalizationDataState> = {
    Configured: 'Configured',
    Error: 'Error',
    NotConfigured: 'NotConfigured',
};

export type LocalizationSettingState = 'NotSet' | 'Set' | 'SetToFallback';

export const LocalizationSettingState: UnionMap<LocalizationSettingState> = {
    NotSet: 'NotSet',
    Set: 'Set',
    SetToFallback: 'SetToFallback',
};
