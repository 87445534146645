import { createSelector } from 'reselect';
import { Status } from '../../models/common';
import { SerializableSet } from '../../types/serializable-set';
import { statusAdapter } from '../adapters/common/status-adapter';
import { graphDirectoryObjectAdapter } from '../adapters/graph-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector } from './common';
import { getActiveAccount, getUsername } from './identity-selectors';

/**
 * Basic selectors
 */

export const getIdsOfOrphanedObjects: StoreStateSelector<SerializableSet<string>> = (store) =>
    store.graphStore.data.orphanedObjectIds;

export const getOrganizationDisplayName: StoreStateSelector<string> = (store) =>
    store.graphStore.data.organizationDisplayName ?? '';

export const getStatusForGetOrganization: StoreStateSelector<Status> = (store) =>
    store.graphStore.status.getOrganization;

export const getStatusForGetSignedInUser: StoreStateSelector<Status> = (store) =>
    store.graphStore.status.getSignedInUser;

export const getGraphUserDisplayName: StoreStateSelector<string | undefined> = (store) =>
    store.graphStore.data.userDisplayName;
export const getUserGivenName: StoreStateSelector<string | undefined> = (store) => store.graphStore.data.userGivenName;
export const getUserPhotoUrl: StoreStateSelector<string | undefined> = (store) => store.graphStore.data.userPhotoUrl;

/**
 * Entity state selectors
 */

const graphDirectoryObjectSelectors = graphDirectoryObjectAdapter.getSelectors<StoreState>(
    (store) => store.graphStore.data.graphDirectoryObjects
);

const statusesForGetGraphDirectoryObjectSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.graphStore.status.getGraphDirectoryObject
);

/**
 * Composed selectors
 */

export const getGraphDirectoryObjects = createMapFromEntitiesSelector(graphDirectoryObjectSelectors.selectAll);

export const getStatusesForGetGraphDirectoryObject = createMapFromEntitiesSelector(
    statusesForGetGraphDirectoryObjectSelectors.selectAll
);

export const getUserDisplayName: StoreStateSelector<string> = createSelector(
    [getGraphUserDisplayName, getActiveAccount, getUsername],
    (graphDisplayName, activeAccount, username) => graphDisplayName ?? activeAccount?.name ?? username ?? ''
);
