import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { dismissContentListenerSaga } from './dismiss-content';
import { dismissQuickActionListenerSaga } from './dismiss-quick-action';
import { fatalErrorListenerSaga } from './fatal-error';
import { initializeApplicationListenerSaga } from './initialize-application';
import { syncDismissedContentListenerSaga } from './sync-dismissed-content';

export function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        fork(dismissContentListenerSaga),
        fork(dismissQuickActionListenerSaga),
        fork(fatalErrorListenerSaga),
        fork(initializeApplicationListenerSaga),
        fork(syncDismissedContentListenerSaga),
    ]);
}
