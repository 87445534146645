import { createSelector } from 'reselect';
import {
    createCustomizationGroupDataPlaneUri,
    getTokensFromCustomizationGroupDataPlaneUri,
} from '../../ids/customization-group';
import { getTokensFromCustomizationTaskLogDataPlaneUri } from '../../ids/customization-task-log';
import { createDevBoxDataPlaneUri } from '../../ids/dev-box';
import { Status } from '../../models/common';
import { CustomizationTask, CustomizationTaskListValidationResult } from '../../models/customization';
import { compact } from '../../utilities/array';
import { values } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import { stringAdapter } from '../adapters/common/string-adapter';
import {
    customizationFailureAdapter,
    customizationGroupAdapter,
    customizationTaskDefinitionAdapter,
} from '../adapters/customization-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createGroupedMapFromEntitiesSelector, createMapFromEntitiesSelector } from './common';

/**
 * Basic selectors
 */

export const getStatusForCreateCustomizationGroup: StoreStateSelector<Status> = (store) =>
    store.customizationStore.status.createCustomizationGroup;

export const getValidateCustomizationTasksResult: StoreStateSelector<
    CustomizationTaskListValidationResult | undefined
> = (store) => store.customizationStore.data.customizationValidationResult;

export const getStatusForValidateCustomizationTasks: StoreStateSelector<Status> = (store) =>
    store.customizationStore.status.validateCustomizationTasks;

export const getStatusForListCustomizationTaskDefinitions: StoreStateSelector<Status> = (store) =>
    store.customizationStore.status.listCustomizationTaskDefinitions;

/**
 * Entity state selectors
 */

const customizationGroupSelectors = customizationGroupAdapter.getSelectors<StoreState>(
    (store) => store.customizationStore.data.customizationGroups
);

const customizationTaskDefinitionSelectors = customizationTaskDefinitionAdapter.getSelectors<StoreState>(
    (store) => store.customizationStore.data.customizationTaskDefinitions
);

const customizationTaskLogSelectors = stringAdapter.getSelectors<StoreState>(
    (store) => store.customizationStore.data.downloadCustomizationTaskLogUrls
);

const customizationGroupFailureSelectors = customizationFailureAdapter.getSelectors<StoreState>(
    (store) => store.customizationStore.data.customizationFailures
);

const statusesForListCustomizationGroupsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.customizationStore.status.listCustomizationGroups
);

/**
 * Composed selectors
 */

export const getCustomizationGroups = createMapFromEntitiesSelector(customizationGroupSelectors.selectAll);

export const getCustomizationGroupFailures = createMapFromEntitiesSelector(
    customizationGroupFailureSelectors.selectAll
);

export const getCustomizationGroupFailuresByDevBox = createGroupedMapFromEntitiesSelector(
    customizationGroupFailureSelectors.selectAll,
    (entity) => {
        const { id } = entity;
        const { devBoxName, devCenter, projectName, user } = getTokensFromCustomizationGroupDataPlaneUri(id);

        return createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user });
    }
);

export const getCustomizationTaskLogs = createMapFromEntitiesSelector(customizationTaskLogSelectors.selectAll);

export const getCustomizationTaskLogsByCustomizationGroup = createGroupedMapFromEntitiesSelector(
    customizationTaskLogSelectors.selectAll,
    (entity) => {
        const { id } = entity;

        const { devBoxName, devCenter, projectName, user, customizationGroupName } =
            getTokensFromCustomizationTaskLogDataPlaneUri(id);

        return createCustomizationGroupDataPlaneUri({
            devBoxName,
            devCenter,
            projectName,
            user,
            customizationGroupName,
        });
    }
);

export const getCustomizationGroupsByDevBox = createGroupedMapFromEntitiesSelector(
    customizationGroupSelectors.selectAll,
    (entity) => {
        const { id } = entity;
        const { devBoxName, devCenter, projectName, user } = getTokensFromCustomizationGroupDataPlaneUri(id);

        return createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user });
    }
);

export const getStatusesForListCustomizationGroups = createMapFromEntitiesSelector(
    statusesForListCustomizationGroupsSelectors.selectAll
);

export const getCustomizationGroupTasks: StoreStateSelector<CustomizationTask[]> = createSelector(
    [getCustomizationGroups],
    (customizationGroups) => {
        return compact(values(customizationGroups).flatMap((group) => group.tasks?.map((task) => task)));
    }
);

export const getCustomizationTaskDefinitions = createMapFromEntitiesSelector(
    customizationTaskDefinitionSelectors.selectAll
);
