import { SagaIterator } from 'redux-saga';
import { CallEffect, call, put } from 'redux-saga/effects';
import { awaitPromise } from '../../utilities/await-promise';
import { AsyncAction } from '../actions/core-actions';

/**
 * Types
 */

export type PutAndAwaitEffect<TAwaited> = CallEffect<TAwaited>;
export type PutAndAwaitSignature<TAwaited> = (action: AsyncAction<TAwaited>) => SagaIterator<TAwaited>;

/**
 * Effects
 */

export function* putAndAwaitSaga<TAwaited>(action: AsyncAction<TAwaited>): SagaIterator<TAwaited> {
    const { async } = action;
    const { promise } = async;

    yield put(action);

    return yield call(awaitPromise, promise);
}

export const putAndAwait = <TAwaited>(action: AsyncAction<TAwaited>): PutAndAwaitEffect<TAwaited> =>
    call<PutAndAwaitSignature<TAwaited>>(putAndAwaitSaga, action);
