import { createSelector } from '@reduxjs/toolkit';
import { Status } from '../../models/common';
import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../models/permission';
import { EnableStatus, ProjectEnvironmentTypeResource } from '../../models/project-environment-type';
import { SerializableMap } from '../../types/serializable-map';
import { getKey } from '../../utilities/key-value-pair';
import { getParentResourceId } from '../../utilities/resource-manager/get-parent-resource-id';
import { filter, keys } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import { projectEnvironmentTypeResourceAdapter } from '../adapters/project-environment-type-adapters';
import { StoreState } from '../store/store-state';
import {
    StoreStateSelector,
    createGroupedMapFromEntitiesSelector,
    createGroupedMapSelector,
    createMapFromEntitiesSelector,
} from './common';
import {
    getIsProjectAuthorizedForEnvironmentRead,
    getIsProjectAuthorizedForEnvironmentWrite,
    getIsProjectEnvironmentTypeAuthorizedForEnvironmentRead,
    getIsProjectEnvironmentTypeAuthorizedForEnvironmentWrite,
    getPermissionsForProjectEnvironmentTypes,
    getPermissionsForProjects,
} from './permission-selectors';

const createAreThereAnyProjectEnvironmentTypesWithPermissionsSelector =
    (
        projectPredicate: (permissions: SerializableMap<ProjectPermissionRecord>, id: string) => boolean,
        projectEnvironmentTypePredicate: (
            permissions: SerializableMap<ProjectEnvironmentTypePermissionRecord>,
            id: string
        ) => boolean
    ) =>
    (
        projectEnvironmentTypes: SerializableMap<ProjectEnvironmentTypeResource>,
        permissions: SerializableMap<ProjectEnvironmentTypePermissionRecord>,
        projectPermissions: SerializableMap<ProjectPermissionRecord>
    ) =>
        keys(projectEnvironmentTypes).some((id) => {
            // Project environment types are considered authorized if the project has environment write permissions, or if
            // the environment type has environment write permissions.
            const projectResourceId = getParentResourceId(id);

            return (
                projectPredicate(projectPermissions, projectResourceId) ||
                projectEnvironmentTypePredicate(permissions, id)
            );
        });

const createFilterToProjectEnvironmentTypesWithPermissionsSelector =
    (
        projectPredicate: (permissions: SerializableMap<ProjectPermissionRecord>, id: string) => boolean,
        projectEnvironmentTypePredicate: (
            permissions: SerializableMap<ProjectEnvironmentTypePermissionRecord>,
            id: string
        ) => boolean
    ) =>
    (
        projectEnvironmentTypes: SerializableMap<ProjectEnvironmentTypeResource>,
        permissions: SerializableMap<ProjectEnvironmentTypePermissionRecord>,
        projectPermissions: SerializableMap<ProjectPermissionRecord>
    ) =>
        filter(projectEnvironmentTypes, (_, id) => {
            // Project environment types are considered authorized if the project has environment write permissions, or if
            // the environment type has environment write permissions.
            const projectResourceId = getParentResourceId(id);

            return (
                projectPredicate(projectPermissions, projectResourceId) ||
                projectEnvironmentTypePredicate(permissions, id)
            );
        });

/**
 * Basic selectors
 */

export const getIsProjectEnvironmentTypeEnabled = (projectEnvironmentType: ProjectEnvironmentTypeResource): boolean =>
    projectEnvironmentType.properties.status === EnableStatus.Enabled;

export const getStatusForDiscoverProjectEnvironmentTypes: StoreStateSelector<Status> = (store) =>
    store.projectEnvironmentTypeStore.status.discoverProjectEnvironmentTypes;

/**
 * Entity state selectors
 */

const projectEnvironmentTypeSelectors = projectEnvironmentTypeResourceAdapter.getSelectors<StoreState>(
    (store) => store.projectEnvironmentTypeStore.data.projectEnvironmentTypes
);

const statusesForListProjectEnvironmentTypesSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.projectEnvironmentTypeStore.status.listProjectEnvironmentTypes
);

/**
 * Composed selectors
 */

export const getProjectEnvironmentTypes = createMapFromEntitiesSelector(projectEnvironmentTypeSelectors.selectAll);

export const getProjectEnvironmentTypesByProject = createGroupedMapFromEntitiesSelector(
    projectEnvironmentTypeSelectors.selectAll,
    (entity) => getParentResourceId(entity.id)
);

export const getEnabledProjectEnvironmentTypes: StoreStateSelector<SerializableMap<ProjectEnvironmentTypeResource>> =
    createSelector([getProjectEnvironmentTypes], (projectEnvironmentTypes) =>
        filter(projectEnvironmentTypes, getIsProjectEnvironmentTypeEnabled)
    );

export const getEnabledProjectEnvironmentTypesByProject = createGroupedMapSelector(
    getEnabledProjectEnvironmentTypes,
    (entry) => getParentResourceId(getKey(entry))
);

export const getHasProjectEnvironmentTypesAuthorizedForEnvironmentRead: StoreStateSelector<boolean> = createSelector(
    [getProjectEnvironmentTypes, getPermissionsForProjectEnvironmentTypes, getPermissionsForProjects],
    createAreThereAnyProjectEnvironmentTypesWithPermissionsSelector(
        getIsProjectAuthorizedForEnvironmentRead,
        getIsProjectEnvironmentTypeAuthorizedForEnvironmentRead
    )
);

export const getHasProjectEnvironmentTypesAuthorizedForEnvironmentWrite: StoreStateSelector<boolean> = createSelector(
    [getEnabledProjectEnvironmentTypes, getPermissionsForProjectEnvironmentTypes, getPermissionsForProjects],
    createAreThereAnyProjectEnvironmentTypesWithPermissionsSelector(
        getIsProjectAuthorizedForEnvironmentWrite,
        getIsProjectEnvironmentTypeAuthorizedForEnvironmentWrite
    )
);

export const getProjectEnvironmentTypesAuthorizedForEnvironmentWrite: StoreStateSelector<
    SerializableMap<ProjectEnvironmentTypeResource>
> = createSelector(
    [getEnabledProjectEnvironmentTypes, getPermissionsForProjectEnvironmentTypes, getPermissionsForProjects],
    createFilterToProjectEnvironmentTypesWithPermissionsSelector(
        getIsProjectAuthorizedForEnvironmentWrite,
        getIsProjectEnvironmentTypeAuthorizedForEnvironmentWrite
    )
);

export const getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject = createGroupedMapSelector(
    getProjectEnvironmentTypesAuthorizedForEnvironmentWrite,
    (entry) => getParentResourceId(getKey(entry))
);

export const getStatusesForListProjectEnvironmentTypes = createMapFromEntitiesSelector(
    statusesForListProjectEnvironmentTypesSelectors.selectAll
);
