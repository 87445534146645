import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { EnvironmentDefinitionViewModel } from '../../../../add-environment-panel/models';
import ResourceDropdown, { ResourceDropdownWrapperProps } from './resource-dropdown';
import { getOptionKeyForIndexedModel } from './selectors';

const messages = defineMessages({
    addEnvironmentFormEnvironmentDefinitionFieldDropdownDisambiguatedName: {
        id: 'AddEnvironmentFormEnvironmentDefinitionControl_Dropdown_DisambiguatedName',
        defaultMessage: '{name} ({catalog})',
        description:
            'Dropdown option text to disambiguate between environment definitions with duplicate names. Do not localize {name}. Do not localize {catalog}',
    },
});

export type EnvironmentDefinitionDropdownProps = ResourceDropdownWrapperProps<EnvironmentDefinitionViewModel>;

export const EnvironmentDefinitionDropdown: React.FC<EnvironmentDefinitionDropdownProps> = (props) => {
    // Intl hooks
    const { formatMessage } = useIntl();

    const getOptionText = React.useCallback(
        (environmentDefinition: EnvironmentDefinitionViewModel) => {
            const { name, catalogName: catalog, isDuplicateName } = environmentDefinition;

            if (!isDuplicateName) {
                return name;
            }

            const values = {
                name,
                catalog,
            };

            return formatMessage(
                messages.addEnvironmentFormEnvironmentDefinitionFieldDropdownDisambiguatedName,
                values
            );
        },
        [formatMessage]
    );

    return (
        <ResourceDropdown<EnvironmentDefinitionViewModel>
            {...props}
            getOptionKey={getOptionKeyForIndexedModel}
            getOptionText={getOptionText}
        />
    );
};

export default EnvironmentDefinitionDropdown;
