import { ActionButton, IIconProps, makeStyles, Stack, TooltipHost } from '@fluentui/react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FluentIconNames } from '../../../common/fluent-icon-names';
import { TimeZoneDropdown } from '../../../common/form/dropdown/data-controls/timezone-dropdown';

interface AddOrUpdateExpirationFormTimeZoneControlProps {
    label?: string;
    value?: string;
    onChange: (value: string | undefined) => void;
    isSubmitting: boolean;
}

const worldClockIcon: IIconProps = { iconName: FluentIconNames.WorldClock };

const useDefaultTooltipHostStyles = makeStyles({
    root: {
        display: 'inline-block',
    },
});

const messages = defineMessages({
    selectTimezone: {
        id: 'AddOrUpdateExpirationFormTimezoneControl_Label',
        defaultMessage: 'Time zone',
        description: 'Label for the timezone picker',
    },
});

export const AddOrUpdateExpirationFormTimeZoneControl: React.FC<AddOrUpdateExpirationFormTimeZoneControlProps> = (
    props: AddOrUpdateExpirationFormTimeZoneControlProps
) => {
    const { value, onChange, isSubmitting } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const tooltipHostStyles = useDefaultTooltipHostStyles();

    const [isTimeZoneClicked, setIsTimeZoneClicked] = React.useState<boolean>(false);

    // Intl hooks

    const onButtonClick = React.useCallback(
        (_ev: unknown): void => {
            setIsTimeZoneClicked(!isTimeZoneClicked);
        },
        [isTimeZoneClicked]
    );

    return (
        <Stack>
            {!!isTimeZoneClicked && (
                <Stack.Item>
                    <TimeZoneDropdown
                        label={formatMessage(messages.selectTimezone)}
                        disabled={isSubmitting}
                        value={value}
                        onChange={onChange}
                    />
                </Stack.Item>
            )}
            <Stack.Item>
                <TooltipHost content={formatMessage(messages.selectTimezone)} styles={tooltipHostStyles}>
                    <ActionButton
                        iconProps={worldClockIcon}
                        allowDisabledFocus
                        toggle
                        checked={isTimeZoneClicked}
                        onClick={onButtonClick}
                        ariaLabel={formatMessage(messages.selectTimezone)}
                    />
                </TooltipHost>
            </Stack.Item>
        </Stack>
    );
};

export default AddOrUpdateExpirationFormTimeZoneControl;
