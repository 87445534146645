import { makeStyles } from '@fluentui/react';
import { getSemanticColor } from '../../utilities/styles';
import { AppSemanticColor } from '../app-semantic-colors';

export const useDetailsPanelStyles = makeStyles((theme) => ({
    root: {
        fontSize: 12,
    },
    overlay: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
    },
    main: {
        marginTop: '48px',
    },
    header: {
        marginBottom: '31px',
    },
}));
