import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { DevBox } from '../../../models/dev-box';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverDevBoxesInTenant,
    discoverDevBoxesInTenantError,
    discoverDevBoxesInTenantFailed,
    discoverDevBoxesInTenantSuccess,
    listDevBoxes,
} from '../../actions/dev-box/dev-box-action-creators';
import { DiscoverDevBoxesInTenantAction } from '../../actions/dev-box/dev-box-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getIdsForProjectsRequiringDevBoxes } from './selectors';

export function* discoverDevBoxesInTenantSaga(action: DiscoverDevBoxesInTenantAction): SagaIterator {
    const startTime = new Date();

    try {
        const ids: string[] = yield select(getIdsForProjectsRequiringDevBoxes);
        const responses: DataResponse<DevBox[]>[] = yield all(ids.map((id) => putAndAwait(listDevBoxes({ id }))));

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.DiscoverDevBoxesInTenant, ...failures);
            yield put(discoverDevBoxesInTenantFailed({ failure }));
        } else {
            yield put(discoverDevBoxesInTenantSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverDevBoxesInTenant,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DiscoverDevBoxesInTenant);
        yield put(discoverDevBoxesInTenantError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverDevBoxesInTenant,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverDevBoxesInTenantListenerSaga(): SagaIterator {
    yield takeLeading(discoverDevBoxesInTenant, discoverDevBoxesInTenantSaga);
}
