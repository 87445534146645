/**
 * Types
 */

export type OneOrMany<T> = T | T[];

/**
 * Type guards
 */

export const isMany = <T>(value: OneOrMany<T>): value is T[] => Array.isArray(value);
export const isOne = <T>(value: OneOrMany<T>): value is T => !Array.isArray(value);
