import { ListEnvironmentTypesResponse } from '../../../data/services/data-plane-api/environment-type';
import { ListProjectEnvironmentTypesResponse } from '../../../data/services/resource-manager/project-environment-type';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    ListProjectEnvironmentTypesFromDataplaneSuccessPayload,
    ListProjectEnvironmentTypesSuccessPayload,
    ProjectEnvironmentTypeActionType,
} from './project-environment-type-actions';

/**
 * Action creators
 */

export const discoverProjectEnvironmentTypes = createActionCreatorWithoutPayload<AggregatedResult>(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypes,
    { async: true }
);

export const discoverProjectEnvironmentTypesError = createActionCreator<ErrorPayload>(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesError
);

export const discoverProjectEnvironmentTypesFailed = createActionCreator<FailedPayload>(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesFailed
);

export const discoverProjectEnvironmentTypesSuccess = createActionCreatorWithoutPayload(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesSuccess
);

export const listProjectEnvironmentTypes = createActionCreator<IndexedPayload, ListProjectEnvironmentTypesResponse>(
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypes,
    { async: true, groupable: true, startsActivity: true }
);

export const listProjectEnvironmentTypesError = createActionCreator<ErrorPayload & IndexedPayload>(
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesError,
    { groupable: true }
);

export const listProjectEnvironmentTypesFailed = createActionCreator<FailedPayload & IndexedPayload>(
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesFailed,
    { groupable: true }
);

export const listProjectEnvironmentTypesSuccess = createActionCreator<ListProjectEnvironmentTypesSuccessPayload>(
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesSuccess,
    { groupable: true }
);

/**
 *  Dataplane
 */

export const discoverProjectEnvironmentTypesFromDataplane = createActionCreatorWithoutPayload<AggregatedResult>(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesFromDataplane,
    { async: true }
);

export const discoverProjectEnvironmentTypesFromDataplaneError = createActionCreator<ErrorPayload>(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesFromDataplaneError
);

export const discoverProjectEnvironmentTypesFromDataplaneFailed = createActionCreator<FailedPayload>(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesFromDataplaneFailed
);

export const discoverProjectEnvironmentTypesFromDataplaneSuccess = createActionCreatorWithoutPayload(
    ProjectEnvironmentTypeActionType.DiscoverProjectEnvironmentTypesFromDataplaneSuccess
);

export const listProjectEnvironmentTypesFromDataplane = createActionCreator<
    IndexedPayload,
    ListEnvironmentTypesResponse
>(ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesFromDataplane, {
    async: true,
    startsActivity: true,
});

export const listProjectEnvironmentTypesFromDataplaneError = createActionCreator<ErrorPayload & IndexedPayload>(
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesFromDataplaneError
);

export const listProjectEnvironmentTypesFromDataplaneFailed = createActionCreator<FailedPayload & IndexedPayload>(
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesFromDataplaneFailed
);

export const listProjectEnvironmentTypesFromDataplaneSuccess =
    createActionCreator<ListProjectEnvironmentTypesFromDataplaneSuccessPayload>(
        ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesFromDataplaneSuccess
    );
