import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import discoverSchedulesAllButOneWorking from './discover-schedules-all-but-one-working.json';
import discoverSchedulesAllWorking from './discover-schedules-all-working.json';
import discoverSchedulesOneFailing from './discover-schedules-one-failing.json';
import getScheduleThrottled from './get-schedule-throttled.json';

export type DataPlaneApiScheduleScenarioName =
    | 'DiscoverSchedulesAllWorking'
    | 'DiscoverSchedulesOneFailing'
    | 'DiscoverSchedulesAllButOneWorking'
    | 'GetScheduleThrottled';

export const DataPlaneApiScheduleScenarioName: UnionMap<DataPlaneApiScheduleScenarioName> = {
    DiscoverSchedulesAllWorking: 'DiscoverSchedulesAllWorking',
    DiscoverSchedulesOneFailing: 'DiscoverSchedulesOneFailing',
    DiscoverSchedulesAllButOneWorking: 'DiscoverSchedulesAllButOneWorking',
    GetScheduleThrottled: 'GetScheduleThrottled',
};

export const DataPlaneApiScheduleScenarios: UnionValueMap<DataPlaneApiScheduleScenarioName, MockScenarioConfiguration> =
    {
        DiscoverSchedulesAllWorking: discoverSchedulesAllWorking as MockScenarioConfiguration,
        DiscoverSchedulesOneFailing: discoverSchedulesOneFailing as MockScenarioConfiguration,
        DiscoverSchedulesAllButOneWorking: discoverSchedulesAllButOneWorking as MockScenarioConfiguration,
        GetScheduleThrottled: getScheduleThrottled as MockScenarioConfiguration,
    };
