import {
    ProjectEnvironmentTypeFromDataPlane,
    ProjectEnvironmentTypeResource,
} from '../../../models/project-environment-type';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    discoverProjectEnvironmentTypes,
    discoverProjectEnvironmentTypesFromDataplane,
    listProjectEnvironmentTypes,
    listProjectEnvironmentTypesFromDataplane,
} from './project-environment-type-action-creators';

/**
 * Action types
 */

type ProjectEnvironmentTypeActionName =
    | ActionName<'DiscoverProjectEnvironmentTypes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListProjectEnvironmentTypes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DiscoverProjectEnvironmentTypesFromDataplane', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListProjectEnvironmentTypesFromDataplane', 'Error' | 'Failed' | 'Success'>;

export type ProjectEnvironmentTypeActionType =
    | ActionType<'DISCOVER_PROJECT_ENVIRONMENT_TYPES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_PROJECT_ENVIRONMENT_TYPES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISCOVER_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const ProjectEnvironmentTypeActionType: UnionValueMap<
    ProjectEnvironmentTypeActionName,
    ProjectEnvironmentTypeActionType
> = {
    DiscoverProjectEnvironmentTypes: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES',
    DiscoverProjectEnvironmentTypesError: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_ERROR',
    DiscoverProjectEnvironmentTypesFailed: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_FAILED',
    DiscoverProjectEnvironmentTypesSuccess: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_SUCCESS',
    ListProjectEnvironmentTypes: 'LIST_PROJECT_ENVIRONMENT_TYPES',
    ListProjectEnvironmentTypesError: 'LIST_PROJECT_ENVIRONMENT_TYPES_ERROR',
    ListProjectEnvironmentTypesFailed: 'LIST_PROJECT_ENVIRONMENT_TYPES_FAILED',
    ListProjectEnvironmentTypesSuccess: 'LIST_PROJECT_ENVIRONMENT_TYPES_SUCCESS',
    DiscoverProjectEnvironmentTypesFromDataplane: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE',
    DiscoverProjectEnvironmentTypesFromDataplaneSuccess: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE_SUCCESS',
    DiscoverProjectEnvironmentTypesFromDataplaneError: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE_ERROR',
    DiscoverProjectEnvironmentTypesFromDataplaneFailed: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE_FAILED',
    ListProjectEnvironmentTypesFromDataplane: 'LIST_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE',
    ListProjectEnvironmentTypesFromDataplaneError: 'LIST_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE_ERROR',
    ListProjectEnvironmentTypesFromDataplaneFailed: 'LIST_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE_FAILED',
    ListProjectEnvironmentTypesFromDataplaneSuccess: 'LIST_PROJECT_ENVIRONMENT_TYPES_FROM_DATAPLANE_SUCCESS',
};

/**
 * Payloads
 */

export type ListProjectEnvironmentTypesSuccessPayload = IndexedPayload &
    ResultPayload<ProjectEnvironmentTypeResource[]>;

export type ListProjectEnvironmentTypesFromDataplaneSuccessPayload = IndexedPayload &
    ResultPayload<ProjectEnvironmentTypeFromDataPlane[]>;

/**
 * Action shorthands
 */

export type DiscoverProjectEnvironmentTypesAction = ReturnType<typeof discoverProjectEnvironmentTypes>;
export type ListProjectEnvironmentTypesAction = ReturnType<typeof listProjectEnvironmentTypes>;

export type ListProjectEnvironmentTypesFromDataplaneAction = ReturnType<
    typeof listProjectEnvironmentTypesFromDataplane
>;

export type DiscoverProjectEnvironmentTypesFromDataplaneAction = ReturnType<
    typeof discoverProjectEnvironmentTypesFromDataplane
>;
