import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    IModalProps,
    makeStyles,
    PrimaryButton,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useConfirmationDialogContext } from '../../../hooks/context/dialogs';

export interface ConfirmationDialogComponentProps {
    hidden?: boolean;
    onDismiss: () => void;
    onPrimaryButtonSubmitted: () => void;
    primaryButtonAriaLabel?: string;
    primaryButtonText: string;
    subText: string;
    title: string;
}

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'ConfirmationDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel the "delete" operation',
        description: 'Aria label for the cancel button to dismiss the dialog and perform no operation',
    },
    cancelButtonText: {
        id: 'ConfirmationDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the delete dialog and perform no operation',
    },
    closeButtonAriaLabel: {
        id: 'ConfirmationDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss the "Delete" dialog',
        description: "Aria label for the delete dialog's close button",
    },
});

/**
 * Styles
 */

// Not able to find a work around from using !important here. The model automatically sets the width of the dialog.
const modalWidth = '560px !important';

const useModalStyles = makeStyles({
    main: {
        maxWidth: modalWidth,
        width: modalWidth,
    },
});

/**
 * END Styles
 */

const ConfirmationDialog: React.FC<ConfirmationDialogComponentProps> = (props: ConfirmationDialogComponentProps) => {
    const { hidden, onDismiss, onPrimaryButtonSubmitted, primaryButtonAriaLabel, primaryButtonText, subText, title } =
        props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const modalStyles = useModalStyles();

    // Callback hooks
    const onPrimaryButtonClicked = React.useCallback(() => {
        onPrimaryButtonSubmitted();
        onDismiss();
    }, [onDismiss, onPrimaryButtonSubmitted]);

    // Memoized data
    const dialogContentProps: IDialogContentProps = React.useMemo(
        () => ({
            subText,
            title,
            type: DialogType.normal,
        }),
        [subText, title]
    );

    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    return (
        <Dialog
            closeButtonAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
            dialogContentProps={dialogContentProps}
            hidden={hidden}
            modalProps={modalProps}
            onDismiss={onDismiss}
        >
            <DialogFooter>
                <PrimaryButton
                    ariaLabel={primaryButtonAriaLabel}
                    onClick={onPrimaryButtonClicked}
                    text={primaryButtonText}
                />

                <DefaultButton
                    ariaLabel={formatMessage(messages.cancelButtonAriaLabel)}
                    onClick={onDismiss}
                    text={formatMessage(messages.cancelButtonText)}
                />
            </DialogFooter>
        </Dialog>
    );
};

export const ConfirmationDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useConfirmationDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <ConfirmationDialog {...properties} hidden={!isOpen} onDismiss={closeSurface} />;
};

export default ConfirmationDialogContextWrapper;
