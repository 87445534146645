import { FontSizes, FontWeights, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import offlineImage from '../content/images/Error_Disconnected.svg';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';

const messages = defineMessages({
    offlineImageAlt: {
        id: 'OfflinePage_OfflineImage_Alt',
        defaultMessage: 'No connection',
        description: 'Alt text for "offline" image',
    },
});

const useMessageContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: 344,
        paddingTop: 6,
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        maxWidth: 344,
        paddingTop: 6,
    },
});

export const OfflinePage: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const messageContainerStyles = useMessageContainerStyles();
    const titleContainerStyles = useTitleContainerStyles();

    return (
        <CenteredPortalLayout contentPaddingBottom={40} contentPaddingTop={40} id="offline" verticalFill hideActionBar>
            <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                <Stack.Item>
                    <img alt={formatMessage(messages.offlineImageAlt)} src={offlineImage} />
                </Stack.Item>

                <Stack.Item styles={titleContainerStyles}>
                    <FormattedMessage
                        id="OfflinePage_OfflineTitle_Text"
                        defaultMessage="You're offline"
                        description="Text alerting the user that they are offline."
                    />
                </Stack.Item>

                <Stack.Item styles={messageContainerStyles}>
                    <FormattedMessage
                        id="OfflinePage_OfflineMessage_Text"
                        defaultMessage="The internet connection has been lost. Please reconnect to continue using the developer portal."
                        description="Message informing the user that they do not have an internet connection."
                    />
                </Stack.Item>
            </Stack>
        </CenteredPortalLayout>
    );
};

export default OfflinePage;
