import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AddDevBoxFormProjectViewModel } from '../../../../add-dev-box-panel/models';
import ResourceDropdown, { ResourceDropdownWrapperProps } from './resource-dropdown';
import { getFinalDisplayNameForProject, getOptionKeyForIndexedModel } from './selectors';

const messages = defineMessages({
    addDevBoxFormProjectFieldDropdownLimitsName: {
        id: 'AddDevBoxFormProjectControl_Dropdown_LimitsName',
        defaultMessage: '{name} ({used}/{limit} dev boxes used)',
        description:
            'Dropdown option text to show limits on how many dev boxes per project. Do not localize {name}. Do not localize {limit}. Do not localize {used}.',
    },
    addDevBoxFormProjectFieldDropdownDuplicateDisplayName: {
        id: 'AddDevBoxFormProjectControl_Dropdown_DuplicateDisplayName',
        defaultMessage: '{displayName} ({resourceName})',
        description:
            'Dropdown option text to show duplicate display name. Do not localize {name}. Do not localize {resourceName}.',
    },
});

export type DevBoxProjectDropdownProps = ResourceDropdownWrapperProps<AddDevBoxFormProjectViewModel>;

export const DevBoxProjectDropdown: React.FC<DevBoxProjectDropdownProps> = (props) => {
    // Intl hooks
    const { formatMessage } = useIntl();

    const getOptionText = React.useCallback(
        (project: AddDevBoxFormProjectViewModel) => {
            const {
                name: resourceName,
                maxDevBoxesPerUser: limit,
                usedDevBoxes: used,
                displayName,
                isDisplayNameDuplicate,
            } = project;

            const duplicateValues = { displayName, resourceName };

            const name = getFinalDisplayNameForProject(
                displayName,
                isDisplayNameDuplicate,
                resourceName,
                formatMessage(messages.addDevBoxFormProjectFieldDropdownDuplicateDisplayName, duplicateValues)
            );

            if (!limit) {
                return name;
            }

            const values = {
                name,
                used,
                limit,
            };

            return formatMessage(messages.addDevBoxFormProjectFieldDropdownLimitsName, values);
        },
        [formatMessage]
    );

    return (
        <ResourceDropdown<AddDevBoxFormProjectViewModel>
            {...props}
            getOptionKey={getOptionKeyForIndexedModel}
            getOptionText={getOptionText}
        />
    );
};

export default DevBoxProjectDropdown;
