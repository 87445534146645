import { CapitalizedUnionMap, UnionMap, UnionValueMap } from '../types/union-map';
import { DataPlaneErrorCode } from './data-plane';

export type DevBoxRequestOption = 'UserId';

export const DevBoxRequestOption: UnionMap<DevBoxRequestOption> = {
    UserId: 'UserId',
};

export type DevBoxFailureReason =
    | 'CreateFailed'
    | 'CustomizeFailed'
    | 'DelayFailed'
    | 'DeleteFailed'
    | 'GetRemoteConnectionFailed'
    | 'HibernateFailed'
    | 'ProvisionedWithWarning'
    | 'ProvisioningFailed'
    | 'RepairFailed'
    | 'RestartFailed'
    | 'ResumeFailed'
    | 'ShutdownFailed'
    | 'SkipFailed'
    | 'StartFailed';

export const DevBoxFailureReason: UnionMap<DevBoxFailureReason> = {
    CreateFailed: 'CreateFailed',
    CustomizeFailed: 'CustomizeFailed',
    DelayFailed: 'DelayFailed',
    DeleteFailed: 'DeleteFailed',
    GetRemoteConnectionFailed: 'GetRemoteConnectionFailed',
    HibernateFailed: 'HibernateFailed',
    ProvisionedWithWarning: 'ProvisionedWithWarning',
    ProvisioningFailed: 'ProvisioningFailed',
    RepairFailed: 'RepairFailed',
    RestartFailed: 'RestartFailed',
    ResumeFailed: 'ResumeFailed',
    ShutdownFailed: 'ShutdownFailed',
    SkipFailed: 'SkipFailed',
    StartFailed: 'StartFailed',
};

export const UserIdValue = 'me';

export const DevBoxOperationConstants: UnionValueMap<DevBoxRequestOption, string> = {
    UserId: UserIdValue,
};

export type DevBoxProvisioningState =
    | 'Succeeded'
    | 'Failed'
    | 'Canceled'
    | 'ProvisionedWithWarning'
    | 'Provisioning'
    | 'Creating'
    | 'Deleting'
    | 'Updating';

export const DevBoxProvisioningState: UnionMap<DevBoxProvisioningState> = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Canceled: 'Canceled',
    ProvisionedWithWarning: 'ProvisionedWithWarning',

    Provisioning: 'Provisioning',
    Creating: 'Creating',
    Deleting: 'Deleting',
    Updating: 'Updating',
};

export const terminalDevBoxProvisioningStates: DevBoxProvisioningState[] = [
    DevBoxProvisioningState.Succeeded,
    DevBoxProvisioningState.Failed,
    DevBoxProvisioningState.Canceled,
    DevBoxProvisioningState.ProvisionedWithWarning,
];

export type ActionState =
    | 'Unknown'
    | 'Failed'
    | 'Starting'
    | 'Started'
    | 'Stopping'
    | 'Stopped'
    | 'Restarting'
    | 'Repairing'
    | 'Repaired';

export const ActionState: UnionMap<ActionState> = {
    Unknown: 'Unknown',
    Failed: 'Failed',
    Starting: 'Starting',
    Started: 'Started',
    Stopping: 'Stopping',
    Stopped: 'Stopped',
    Restarting: 'Restarting',
    Repairing: 'Repairing',
    Repaired: 'Repaired',
};

export const terminalDevBoxActionStates: ActionState[] = [
    ActionState.Failed,
    ActionState.Stopped,
    ActionState.Started,
    ActionState.Repaired,
    // This should be considered as a terminal state because it is there when the user has not triggered any actions for the vm.
    ActionState.Unknown,
];

export type OSType = 'Windows';

export const OSType: UnionMap<OSType> = {
    Windows: 'Windows',
};

export type PowerState = 'Unknown' | 'Stopped' | 'Running' | 'Hibernated' | 'Deallocated' | 'PoweredOff';

export const PowerState: UnionMap<PowerState> = {
    Unknown: 'Unknown',
    Stopped: 'Stopped',
    Running: 'Running',
    Hibernated: 'Hibernated',
    Deallocated: 'Deallocated',
    PoweredOff: 'PoweredOff',
};

export type DevBoxActionKey = 'delete' | 'start' | 'stop';

export const DevBoxActionKey: CapitalizedUnionMap<DevBoxActionKey> = {
    Delete: 'delete',
    Start: 'start',
    Stop: 'stop',
};

export type CreateDevBoxErrorCode =
    | 'DeprecatedSku'
    | 'GuestAccountsNotCurrentlySupported'
    | 'PoolUnhealthyState'
    | 'QuotaExceeded'
    | DataPlaneErrorCode;

export const CreateDevBoxErrorCode: UnionMap<CreateDevBoxErrorCode> = {
    DeprecatedSku: 'DeprecatedSku',
    GuestAccountsNotCurrentlySupported: 'GuestAccountsNotCurrentlySupported',
    PoolUnhealthyState: 'PoolUnhealthyState',
    QuotaExceeded: 'QuotaExceeded',
    ...DataPlaneErrorCode,
};

export type DevBoxResourceErrorCode = 'ProvisioningFailed';

export const DevBoxResourceErrorCode: UnionMap<DevBoxResourceErrorCode> = {
    /** This error code is a placeholder for missing errorDetails on a failed dev box, does not come from the backend. */
    ProvisioningFailed: 'ProvisioningFailed',
};

export type DevBoxOperationKind = 'Start' | 'Stop' | 'Restart' | 'Repair';

export const DevBoxOperationKind: UnionMap<DevBoxOperationKind> = {
    Start: 'Start',
    Stop: 'Stop',
    Restart: 'Restart',
    Repair: 'Repair',
};

export const DefaultCustomizationGroupName = 'Initial';

// TODO: These defaults will not be needed once this info starts flowing through from the api.
// Keeping the defaults in one place for now.
export const DefaultCpuCount = 8;
export const DefaultDiskSizeInGb = 1024;
export const DefaultMemoryInGb = 32;
export const DefaultOSType: OSType = OSType.Windows;
