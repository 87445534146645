import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ClientError, FailureOperation } from '../../models/common';
import { flush, trackException } from '../../utilities/telemetry/channel';

export const crashReporterMiddleware: Middleware =
    ({}: MiddlewareAPI) =>
    (next: Dispatch) =>
    (action: Action) => {
        try {
            return next(action);
        } catch (error) {
            const { type } = action;
            const clientError = new ClientError(error, FailureOperation.FatalError);

            trackException(clientError, { properties: { type } });

            flush();

            return undefined;
        }
    };
