import { SagaIterator } from 'redux-saga';
import { delay, put } from 'redux-saga/effects';
import { updateDisplayTime } from '../../actions/time/time-action-creators';
import { takeLatest } from '../../effects/take';

const delayMs = 60000;

export function* updateDisplayTimeSaga(): SagaIterator {
    yield delay(delayMs);

    const time = new Date();

    yield put(updateDisplayTime({ time }));
}

export function* updateDisplayTimeListenerSaga(): SagaIterator {
    yield takeLatest(updateDisplayTime, updateDisplayTimeSaga);
}
