import { SagaIterator } from '@redux-saga/types';
import { call, put } from 'redux-saga/effects';
import {
    ListDevBoxOperationsResponse,
    listDevBoxOperations,
} from '../../../data/services/data-plane-api/dev-box-operation';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    listDevBoxOperations as listDevBoxOperationsActionCreator,
    listDevBoxOperationsError,
    listDevBoxOperationsFailed,
    listDevBoxOperationsSuccess,
} from '../../actions/dev-box-operation/dev-box-operation-action-creators';
import { ListDevBoxOperationsAction } from '../../actions/dev-box-operation/dev-box-operation-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listDevBoxOperationsSaga(action: ListDevBoxOperationsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListDevBoxOperationsResponse = yield call(listDevBoxOperations, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(listDevBoxOperationsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;

        yield put(listDevBoxOperationsSuccess({ id, result: data }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListDevBoxOperations);
        yield put(listDevBoxOperationsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listDevBoxOperationsListenerSaga(): SagaIterator {
    yield takeEvery(listDevBoxOperationsActionCreator, listDevBoxOperationsSaga);
}
