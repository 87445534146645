import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { tryConvertStringToUserSettings } from '../../../types/user-settings';
import { getStorageValue } from '../../actions/storage/storage-action-creators';
import {
    syncUserSettings,
    syncUserSettingsError,
    syncUserSettingsFailed,
    syncUserSettingsSuccess,
} from '../../actions/user-settings/user-settings-action-creator';
import { SyncUserSettingsAction } from '../../actions/user-settings/user-settings-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';

export function* syncSettingsSaga(action: SyncUserSettingsAction): SagaIterator {
    try {
        const storageType = yield select(getStorageType);

        const response: DataResponse<string> = yield putAndAwait(
            getStorageValue({
                getForSignedInUser: true,
                key: StorageKey.UserSettings,
                storageType,
            })
        );

        if (isFailureResponse(response)) {
            yield put(syncUserSettingsFailed({ failure: response }));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;
        const userSettings = tryConvertStringToUserSettings(data);
        yield put(syncUserSettingsSuccess({ result: userSettings }));

        // Resolve the action with the settings data

        yield resolveAction(action, { succeeded: true, data: userSettings });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SyncUserSettings);
        yield put(syncUserSettingsError({ error }));
        yield rejectAction(action, error);
    }
}

export function* syncSettingsListenerSaga(): SagaIterator {
    yield takeEvery(syncUserSettings, syncSettingsSaga);
}
