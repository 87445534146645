import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import { SkipDevBoxActionErrorCode } from '../../../../data/contracts/dev-box-action';
import { SkipDevBoxActionResponse, skipDevBoxAction } from '../../../../data/services/data-plane-api/dev-box-action';
import { ClientError, FailureOperation, FailureResponse, isSuccessResponse } from '../../../../models/common';
import { getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../../utilities/telemetry/helpers';
import {
    skipAllDevBoxActions,
    skipAllDevBoxActionsError,
    skipAllDevBoxActionsFailed,
    skipAllDevBoxActionsSuccess,
    skipDevBoxActionFailed,
    skipDevBoxActionSuccess,
} from '../../../actions/dev-box-action/dev-box-action-action-creators';
import {
    SkipAllDevBoxActionsAction,
    SkipDevBoxActionFailedPayload,
    SkipDevBoxActionSuccessPayload,
} from '../../../actions/dev-box-action/dev-box-action-actions';
import { getAccessToken } from '../../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../../actions/identity/identity-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { takeEvery } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';
import { getIdsOfDevBoxActionsWithin24HoursForDevBox } from './selectors';

export function* skipAllDevBoxActionsSaga(action: SkipAllDevBoxActionsAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const devBoxActionIds: string[] = yield select(getIdsOfDevBoxActionsWithin24HoursForDevBox, id);

        const responses: SkipDevBoxActionResponse[] = yield all(
            devBoxActionIds.map((devBoxActionId) => call(skipDevBoxAction, devBoxActionId, accessToken, activityId))
        );

        const failedPayloads: SkipDevBoxActionFailedPayload[] = [];
        const succeededPayloads: SkipDevBoxActionSuccessPayload[] = [];

        responses.forEach((response, index) => {
            if (isSuccessResponse(response)) {
                succeededPayloads.push({ id: devBoxActionIds[index] });
            } else {
                // The validator returns 204 if the action has already been skipped, let's map this to our already skipped status code
                const failure = FailureResponse({
                    ...response,
                    code:
                        response.statusCode === 204
                            ? SkipDevBoxActionErrorCode.DevBoxActionAlreadySkipped
                            : response.code,
                });

                failedPayloads.push({ failure, id: devBoxActionIds[index] });
            }
        });

        // Dispatch actions for individual dev box action operations
        if (failedPayloads.length > 0) {
            yield put(skipDevBoxActionFailed(failedPayloads, meta));
        }

        if (succeededPayloads.length > 0) {
            yield put(skipDevBoxActionSuccess(succeededPayloads, meta));
        }

        // Dispatch actions for skip-all operations
        if (failedPayloads.length > 0 && succeededPayloads.length < 1) {
            const failures = failedPayloads.map((payload) => payload.failure);
            const failure = aggregateFailureResponses(FailureOperation.SkipAllDevBoxActions, ...failures);
            yield put(skipAllDevBoxActionsFailed({ failure, id }, meta));
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.SkipAllDevBoxActions,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, ...getErrorCodes(failures))
            );

            return;
        }

        yield put(skipAllDevBoxActionsSuccess({ id }, meta));

        const outcome = failedPayloads.length > 0 ? AsyncOutcome.PartialSuccess : AsyncOutcome.Success;

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.SkipAllDevBoxActions,
            startTime,
            outcome,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SkipAllDevBoxActions);
        yield put(skipAllDevBoxActionsError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.SkipAllDevBoxActions,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* skipAllDevBoxActionsListenerSaga(): SagaIterator {
    yield takeEvery(skipAllDevBoxActions, skipAllDevBoxActionsSaga);
}
