import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { discoverProjectEnvironmentTypesAbilitiesListenerSaga } from './discover-project-environment-type-abilities';
import { getProjectEnvironmentTypeAbilitiesListenerSaga } from './get-project-environment-type-abilities';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverProjectEnvironmentTypesAbilitiesListenerSaga),
        fork(getProjectEnvironmentTypeAbilitiesListenerSaga),
    ]);
}
