import { DataResponse } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import {
    ApplicationActionType,
    DismissContentErrorPayload,
    DismissContentFailedPayload,
    DismissContentPayload,
    DismissContentSuccessPayload,
    DismissQuickActionErrorPayload,
    DismissQuickActionFailedPayload,
    DismissQuickActionPayload,
    DismissQuickActionSuccessPayload,
    FatalErrorPayload,
} from './application-actions';

/**
 * Action creators
 */

export const dismissContent = createActionCreator<DismissContentPayload>(ApplicationActionType.DismissContent);

export const dismissContentError = createActionCreator<DismissContentErrorPayload>(
    ApplicationActionType.DismissContentError
);

export const dismissContentFailed = createActionCreator<DismissContentFailedPayload>(
    ApplicationActionType.DismissContentFailed
);

export const dismissContentSuccess = createActionCreator<DismissContentSuccessPayload>(
    ApplicationActionType.DismissContentSuccess
);

export const dismissQuickAction = createActionCreator<DismissQuickActionPayload>(
    ApplicationActionType.DismissQuickAction
);

export const dismissQuickActionError = createActionCreator<DismissQuickActionErrorPayload>(
    ApplicationActionType.DismissQuickActionError
);

export const dismissQuickActionFailed = createActionCreator<DismissQuickActionFailedPayload>(
    ApplicationActionType.DismissQuickActionFailed
);

export const dismissQuickActionSuccess = createActionCreator<DismissQuickActionSuccessPayload>(
    ApplicationActionType.DismissQuickActionSuccess
);

export const fatalError = createActionCreator<FatalErrorPayload>(ApplicationActionType.FatalError);

export const initializeApplication = createActionCreatorWithoutPayload(ApplicationActionType.InitializeApplication);

export const initializeApplicationError = createActionCreator<ErrorPayload>(
    ApplicationActionType.InitializeApplicationError
);

export const initializeApplicationFailed = createActionCreator<FailedPayload>(
    ApplicationActionType.InitializeApplicationFailed
);

export const initializeApplicationSuccess = createActionCreatorWithoutPayload(
    ApplicationActionType.InitializeApplicationSuccess
);

export const syncDismissedContent = createActionCreatorWithoutPayload<DataResponse<string[]>>(
    ApplicationActionType.SyncDismissedContent,
    { async: true }
);

export const syncDismissedContentError = createActionCreator<ErrorPayload>(
    ApplicationActionType.SyncDismissedContentError
);

export const syncDismissedContentFailed = createActionCreator<FailedPayload>(
    ApplicationActionType.SyncDismissedContentFailed
);

export const syncDismissedContentSuccess = createActionCreator<ResultPayload<string[]>>(
    ApplicationActionType.SyncDismissedContentSuccess
);
