import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import discoverPoolsAllFailing from './discover-pools-all-failing.json';
import discoverPoolsAllWorking from './discover-pools-all-working.json';
import discoverPoolsOneFailingZeroCode from './discover-pools-one-failing-zero-code.json';
import discoverPoolsOneFailing from './discover-pools-one-failing.json';
import discoverPoolsVariedMachineSpecs from './discover-pools-varied-machine-specs.json';
import listPoolsFail from './list-pools-fail.json';

export type DataPlaneApiPoolScenarioName =
    | 'DiscoverPoolsAllFailing'
    | 'DiscoverPoolsAllWorking'
    | 'DiscoverPoolsOneFailing'
    | 'DiscoverPoolsOneFailingZeroCode'
    | 'DiscoverPoolsVariedMachineSpecs'
    | 'ListPoolsFail';

export const DataPlaneApiPoolScenarioName: UnionMap<DataPlaneApiPoolScenarioName> = {
    DiscoverPoolsAllFailing: 'DiscoverPoolsAllFailing',
    DiscoverPoolsAllWorking: 'DiscoverPoolsAllWorking',
    DiscoverPoolsOneFailing: 'DiscoverPoolsOneFailing',
    DiscoverPoolsOneFailingZeroCode: 'DiscoverPoolsOneFailingZeroCode',
    DiscoverPoolsVariedMachineSpecs: 'DiscoverPoolsVariedMachineSpecs',
    ListPoolsFail: 'ListPoolsFail',
};

export const DataPlaneApiPoolScenarios: UnionValueMap<DataPlaneApiPoolScenarioName, MockScenarioConfiguration> = {
    DiscoverPoolsAllFailing: discoverPoolsAllFailing as MockScenarioConfiguration,
    DiscoverPoolsAllWorking: discoverPoolsAllWorking as MockScenarioConfiguration,
    DiscoverPoolsOneFailing: discoverPoolsOneFailing as MockScenarioConfiguration,
    DiscoverPoolsOneFailingZeroCode: discoverPoolsOneFailingZeroCode as MockScenarioConfiguration,
    DiscoverPoolsVariedMachineSpecs: discoverPoolsVariedMachineSpecs as MockScenarioConfiguration,
    ListPoolsFail: listPoolsFail as MockScenarioConfiguration,
};
