import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity, isNothing } from '../../models/common';
import { GraphDirectoryObject } from '../../models/graph';
import { SerializableSet } from '../../types/serializable-set';
import { union } from '../../utilities/serializable-set';
import {
    getGraphDirectoryObject,
    getGraphDirectoryObjectError,
    getGraphDirectoryObjectFailed,
    getGraphDirectoryObjectSuccess,
} from '../actions/graph/directory-objects-action-creators';
import {
    getBannerLogoForOrganization,
    getBannerLogoForOrganizationError,
    getBannerLogoForOrganizationFailed,
    getBannerLogoForOrganizationSuccess,
    getOrganization,
    getOrganizationError,
    getOrganizationFailed,
    getOrganizationSuccess,
} from '../actions/graph/organization-action-creators';
import {
    getPhotoForSignedInUser,
    getPhotoForSignedInUserError,
    getPhotoForSignedInUserFailed,
    getPhotoForSignedInUserSuccess,
    getSignedInUser,
    getSignedInUserError,
    getSignedInUserFailed,
    getSignedInUserSuccess,
} from '../actions/graph/signed-in-user-action-creators';
import { graphDirectoryObjectAdapter } from '../adapters/graph-adapters';
import { GraphDataStore, GraphStatusStore, GraphStore } from '../store/graph-store';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const graphReducer = combineReducers<GraphStore>({
    data: createReducer(GraphDataStore(), (builder) => {
        builder
            .addCase(getBannerLogoForOrganizationSuccess, (store, action) => {
                const { bannerLogoUrl } = getPayload(action);
                store.bannerLogoUrl = bannerLogoUrl;
            })
            .addCase(getGraphDirectoryObjectSuccess, (store, action) => {
                const actions = getActionsInGroup(action);
                const actionsWithDocuments = actions.filter((action) => !isNothing(action.payload.result));
                const actionsWithNothing = actions.filter((action) => isNothing(action.payload.result));

                // If we received a document for an object ID, store that data.
                graphDirectoryObjectAdapter.setMany(
                    store.graphDirectoryObjects,
                    actionsWithDocuments.map((action) => {
                        const result = getPayload(action).result as GraphDirectoryObject;
                        return Entity(result.id, result);
                    })
                );

                // If we didn't receive a document for an object ID, mark it as orphaned.
                store.orphanedObjectIds = union(
                    store.orphanedObjectIds,
                    SerializableSet(actionsWithNothing.map((action) => action.payload.id))
                );
            })
            .addCase(getOrganizationSuccess, (store, action) => {
                const { result } = getPayload(action);
                const { displayName, id } = result;
                store.organizationDisplayName = displayName;
                store.organizationId = id;
            })
            .addCase(getPhotoForSignedInUserSuccess, (store, action) => {
                const { photoUrl } = getPayload(action);
                store.userPhotoUrl = photoUrl;
            })
            .addCase(getSignedInUserSuccess, (store, action) => {
                const { result } = getPayload(action);
                const { displayName, givenName, id, surname } = result;
                store.userDisplayName = displayName;
                store.userGivenName = givenName;
                store.userId = id;
                store.userSurname = surname;
            });
    }),

    status: combineReducers<GraphStatusStore>({
        getBannerLogoForOrganization: createStatusReducer({
            inProgress: getBannerLogoForOrganization,
            error: getBannerLogoForOrganizationError,
            failed: getBannerLogoForOrganizationFailed,
            success: getBannerLogoForOrganizationSuccess,
        }),

        getGraphDirectoryObject: createIndexedStatusReducer({
            inProgress: getGraphDirectoryObject,
            error: getGraphDirectoryObjectError,
            failed: getGraphDirectoryObjectFailed,
            success: getGraphDirectoryObjectSuccess,
        }),

        getOrganization: createStatusReducer({
            inProgress: getOrganization,
            error: getOrganizationError,
            failed: getOrganizationFailed,
            success: getOrganizationSuccess,
        }),

        getPhotoForSignedInUser: createStatusReducer({
            inProgress: getPhotoForSignedInUser,
            error: getPhotoForSignedInUserError,
            failed: getPhotoForSignedInUserFailed,
            success: getPhotoForSignedInUserSuccess,
        }),

        getSignedInUser: createStatusReducer({
            inProgress: getSignedInUser,
            error: getSignedInUserError,
            failed: getSignedInUserFailed,
            success: getSignedInUserSuccess,
        }),
    }),
});
