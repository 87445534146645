import { makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { SimpleLayoutProps } from './simple-layout';

interface CenteredSimpleLayoutProps extends SimpleLayoutProps {
    verticalFill?: boolean;
}

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        height: '100vh',
        justifyContent: 'center',
        width: '100%',
    },
});

const useContentStyles = makeStyles({
    root: {
        height: 'fit-content',
        textAlign: 'center',
        width: 680,
    },
});

/**
 * END Styles
 */

export const CenteredSimpleLayout: React.FC<CenteredSimpleLayoutProps> = (props: CenteredSimpleLayoutProps) => {
    const { children, id, verticalFill } = props;

    // Style hooks
    const containerStyles = useContainerStyles();
    const contentStyles = useContentStyles();

    return (
        <Stack
            grow={verticalFill}
            horizontal
            horizontalAlign="center"
            id={id}
            styles={containerStyles}
            verticalAlign="center"
            verticalFill={verticalFill}
        >
            <Stack.Item styles={contentStyles}>{children}</Stack.Item>
        </Stack>
    );
};

export default CenteredSimpleLayout;
