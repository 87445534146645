import {
    BaseButton,
    FontSizes,
    FontWeights,
    Icon,
    IconButton,
    IIconProps,
    IStackTokens,
    makeStyles,
    Stack,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CssSelector } from '../../themes/constants/css-selectors';
import { isUndefinedOrWhiteSpace } from '../../utilities/string';
import { getSemanticColor } from '../../utilities/styles';

interface QuickActionCardProps {
    iconAriaLabel?: string;
    iconName?: string;
    onActionInvoked: () => void;
    onDismiss?: () => void;
    subtitle: React.ReactNode;
    title: React.ReactNode;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'QuickActionCard_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for quick action card "Close" button',
    },
});

/**
 * Styles
 */

const useCardStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, 'quickActionCardBackground'),
        borderRadius: '4px',
        // Indicates that this element is clickable
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        width: 256,

        ':before': {
            backgroundColor: getSemanticColor(theme, 'quickActionCardStripeBackground'),
            bottom: 0,
            // IMPORTANT: need to double-quote content properties. Empty single quotes get translated to undefined,
            // essentially, meaning the ::before element won't render
            content: '""',
            display: 'block',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '4px',
        },

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },
    },
}));

const useCloseButtonStyles = makeStyles({
    icon: {
        fontSize: '12px',
    },
    root: {
        height: '24px',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '24px',
    },
});

const useContentContainerStyles = makeStyles({
    root: {
        padding: '12px 12px 12px 16px',
    },
});

const useIconContainerStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'quickActionCardIcon'),
    },
}));

const useStyles = makeStyles({
    icon: {
        fontSize: FontSizes.size24,
        lineHeight: '24px',
        width: '32px',
    },
});

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size10,
        lineHeight: '14px',
    },
});

const useTextContainerStyles = makeStyles({
    root: {
        // setting a min width value for this element allows it to shrink
        minWidth: 0,
    },
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        width: '100%',
    },
});

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.semibold,
        lineHeight: '16px',
    },
});

/**
 * END Styles
 */

const closeButtonIconProps: IIconProps = {
    iconName: 'Cancel',
};

const contentTokens: IStackTokens = {
    childrenGap: 12,
};

const textTokens: IStackTokens = {
    childrenGap: 2,
};

export const QuickActionCard: React.FC<QuickActionCardProps> = React.memo((props: QuickActionCardProps) => {
    const { iconAriaLabel, iconName, onActionInvoked, onDismiss, subtitle, title } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const cardStyles = useCardStyles();
    const classNames = useStyles();
    const closeButtonStyles = useCloseButtonStyles();
    const contentContainerStyles = useContentContainerStyles();
    const iconContainerStyles = useIconContainerStyles();
    const subtitleStyles = useSubtitleStyles();
    const textContainerStyles = useTextContainerStyles();
    const textStyles = useTextStyles();
    const titleStyles = useTitleStyles();

    // Callback hooks
    const onDismissHandler = React.useCallback(
        (
            event: React.MouseEvent<
                HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton,
                MouseEvent
            >
        ) => {
            if (onDismiss) {
                // Block event from propagating up to the card's click handler
                event.stopPropagation();

                onDismiss();
            }
        },
        [onDismiss]
    );

    return (
        <Stack tabIndex={0} horizontal onClick={onActionInvoked} styles={cardStyles} onKeyPress={onActionInvoked}>
            <Stack.Item grow={1} styles={contentContainerStyles} verticalFill>
                <Stack horizontal tokens={contentTokens} verticalAlign="center">
                    {!isUndefinedOrWhiteSpace(iconName) && (
                        <Stack.Item styles={iconContainerStyles}>
                            <Icon aria-label={iconAriaLabel} className={classNames.icon} iconName={iconName} />
                        </Stack.Item>
                    )}

                    <Stack.Item grow={1} styles={textContainerStyles}>
                        <Stack styles={textStyles} tokens={textTokens}>
                            <Stack.Item styles={titleStyles}>{title}</Stack.Item>
                            <Stack.Item styles={subtitleStyles}>{subtitle}</Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            {onDismiss && (
                <IconButton
                    ariaLabel={formatMessage(messages.closeButtonAriaLabel)}
                    iconProps={closeButtonIconProps}
                    onClick={onDismissHandler}
                    styles={closeButtonStyles}
                />
            )}
        </Stack>
    );
});

export default QuickActionCard;
