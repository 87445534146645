import { StorageType } from '../../constants/storage';
import { createInitializer } from '../../utilities/initializer';
import { InitializationStatusStore } from './initialization-status-store';

export interface StorageDataStore {
    storageType: StorageType;
}

export const StorageDataStore = createInitializer<StorageDataStore>({
    storageType: StorageType.LocalStorage,
});

export interface StorageStore {
    data: StorageDataStore;
    status: InitializationStatusStore;
}

export const StorageStore = createInitializer<StorageStore>({
    data: StorageDataStore(),
    status: InitializationStatusStore(),
});
