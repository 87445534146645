import { UnionValueMap } from '../../../types/union-map';
import { UserSettings } from '../../../types/user-settings';
import { ActionName, ActionType, OptionalResultPayload } from '../core-actions';
import { setPartialUserSettings, setUserSettings, syncUserSettings } from './user-settings-action-creator';

type SetUserSettingsActionName = 'SetUserSettings';
type SetPartialUserSettingsActionName = 'SetPartialUserSettings';

type StoreUserSettingsActionName = 'StoreUserSettingsError' | 'StoreUserSettingsFailed' | 'StoreUserSettingsSuccess';
type SyncUserSettingsActionName = ActionName<'SyncUserSettings', 'Error' | 'Failed' | 'Success'>;

type SetUserSettingsActionType = 'SET_USER_SETTINGS';
type SetPartialUserSettingsActionType = 'SET_PARTIAL_USER_SETTINGS';
type StoreUserSettingsActionType =
    | 'STORE_USER_SETTINGS_ERROR'
    | 'STORE_USER_SETTINGS_FAILED'
    | 'STORE_USER_SETTINGS_SUCCESS';

type SyncUserSettingsActionType = ActionType<'SYNC_USER_SETTINGS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export type UserSettingsActionName =
    | SetUserSettingsActionName
    | SyncUserSettingsActionName
    | StoreUserSettingsActionName
    | SetPartialUserSettingsActionName;

export type UserSettingsActionType =
    | SetUserSettingsActionType
    | SyncUserSettingsActionType
    | StoreUserSettingsActionType
    | SetPartialUserSettingsActionType;

export const UserSettingsActionType: UnionValueMap<UserSettingsActionName, UserSettingsActionType> = {
    /**
     * Action to set Settings;
     */
    SetUserSettings: 'SET_USER_SETTINGS',
    SetPartialUserSettings: 'SET_PARTIAL_USER_SETTINGS',
    /**
     * Action to store theme mode in browser local storage
     * This action is only responsible for storing the settings, should storing user settings fail we don't want to stop
     * the changing of the settings for the customer. Instead we will log the error and allow the setting change to still happen.
     */
    StoreUserSettingsError: 'STORE_USER_SETTINGS_ERROR',
    StoreUserSettingsFailed: 'STORE_USER_SETTINGS_FAILED',
    StoreUserSettingsSuccess: 'STORE_USER_SETTINGS_SUCCESS',
    /**
     * Load the user settings from the local storage of the browser
     */
    SyncUserSettings: 'SYNC_USER_SETTINGS',
    SyncUserSettingsError: 'SYNC_USER_SETTINGS_ERROR',
    SyncUserSettingsFailed: 'SYNC_USER_SETTINGS_FAILED',
    SyncUserSettingsSuccess: 'SYNC_USER_SETTINGS_SUCCESS',
};

/**
 * Payloads
 */
export type SetUserSettingsPayload = UserSettings;

export type SetPartialUserSettingsPayload = Partial<UserSettings>;

export type SyncUserSettingsPayload = OptionalResultPayload<UserSettings>;

/**
 * Action shorthands
 */
export type SetUserSettingsAction = ReturnType<typeof setUserSettings>;
export type SetPartialUserSettingsAction = ReturnType<typeof setPartialUserSettings>;
export type SyncUserSettingsAction = ReturnType<typeof syncUserSettings>;
