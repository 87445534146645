import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { GetDevBoxResponse, RepairDevBoxResponse, repairDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getDevBoxState } from '../../../utilities/dev-box';
import { createFailureResponseFromCloudErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import { listDevBoxOperations } from '../../actions/dev-box-operation/dev-box-operation-action-creators';
import {
    pollNonTerminalDevBox,
    repairDevBoxAccepted,
    repairDevBox as repairDevBoxActionCreator,
    repairDevBoxError,
    repairDevBoxFailed,
    repairDevBoxSuccess,
} from '../../actions/dev-box/dev-box-action-creators';
import { RepairDevBoxAcceptedAction, RepairDevBoxAction } from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { DevBoxState } from '../../store/dev-box-state';

export function* repairDevBoxSaga(action: RepairDevBoxAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: RepairDevBoxResponse = yield call(repairDevBox, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(repairDevBoxFailed({ failure: response, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.RepairDevBox,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );

            return;
        }

        yield put(repairDevBoxAccepted({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.RepairDevBox,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.RepairDevBox);
        yield put(repairDevBoxError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.RepairDevBox,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* repairDevBoxAcceptedSaga(action: RepairDevBoxAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetDevBoxResponse = yield putAndAwait(pollNonTerminalDevBox({ id }, meta));

        if (isFailureResponse(response)) {
            yield put(repairDevBoxFailed({ failure: response, id }, meta));
            return;
        }

        const { data } = response;
        const { actionState } = data;
        const state = getDevBoxState(data);

        switch (actionState) {
            case DevBoxState.Repaired:
                yield put(listDevBoxOperations({ id }));
                yield put(repairDevBoxSuccess({ id, result: data }, meta));
                break;
            default:
                // Unexpected state: log cases where we're falling back on the default error message. This means we're
                // in a failed state, but there are no error details.
                if (!data.error) {
                    trackEvent(EventName.DevBoxInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState: data.provisioningState,
                            devBoxState: state,
                        },
                    });
                }

                const failure = createFailureResponseFromCloudErrorBodyOrDefault(
                    data.error,
                    FailureOperation.RepairDevBox
                );

                yield put(repairDevBoxFailed({ failure, id, result: data }, meta));
                break;
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.RepairDevBox);
        yield put(repairDevBoxError({ error, id }, meta));
    }
}

export function* repairDevBoxListenerSaga(): SagaIterator {
    yield takeLeading(repairDevBoxActionCreator, repairDevBoxSaga);
}

export function* repairDevBoxAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(repairDevBoxAccepted, repairDevBoxAcceptedSaga);
}
