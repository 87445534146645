import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { discoverSchedulesForDevBoxesListenerSaga } from './discover-schedules-for-dev-boxes';
import { discoverSchedulesForPoolsListenerSaga } from './discover-schedules-for-pools';
import { listSchedulesByProjectListenerSaga } from './list-schedules-by-project';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverSchedulesForDevBoxesListenerSaga),
        fork(discoverSchedulesForPoolsListenerSaga),
        fork(listSchedulesByProjectListenerSaga),
    ]);
}
