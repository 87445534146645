import { ApiVersion } from '../../../constants/azure';
import {
    AdoPathPart as PathPart,
    AdoQueryParam as QueryParam,
    AdoQueryParamValue as QueryParamValue,
} from '../../../constants/azure-dev-ops';
import { Method } from '../../../constants/http';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    SuccessResponse,
    isSuccessResponse,
} from '../../../models/common';
import Settings from '../../../settings/settings';
import { isAdoUrl } from '../../../utilities/azure-dev-ops';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    AzureDevOpsBranchContract,
    AzureDevOpsCommitContract,
    AzureDevOpsListResponseContract,
    AzureDevOpsRepoContract,
    AzureDevOpsTreeContract,
} from '../../contracts/azure-dev-ops';
import {
    FetchAndHandleResponseOptions,
    FetchOptions,
    fetchAndHandleResponse,
    fetchAndHandleTextStreamResponse,
} from '../fetch-request';

const adoRequestOptions: FetchOptions<Response> = {
    headers: {
        'X-TFS-FedAuthRedirect': 'Suppress',
    },
};

export type GetAzureDevOpsRepoResponse = DataResponse<AzureDevOpsRepoContract>;
export type ListAzureDevOpsBranchesResponse = DataResponse<AzureDevOpsBranchContract[]>;
export type GetAzureDevOpsCommitResponse = DataResponse<AzureDevOpsCommitContract>;
export type GetAzureDevOpsTreeResponse = DataResponse<AzureDevOpsTreeContract>;
export type GetAzureDevOpsRepoItemResponse = DataResponse<string>;

const fetchAzureDevOpsList = async <TValue extends Array<unknown>>(
    url: string,
    operation: FailureOperation,
    options?: FetchAndHandleResponseOptions<Response>
): Promise<DataResponse<TValue>> => {
    const dataResponse = await fetchAndHandleResponse<AzureDevOpsListResponseContract<TValue>>(
        url,
        Method.GET,
        operation,
        options
    );

    if (isSuccessResponse(dataResponse)) {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        // Justification: TypeGuard pain
        return { ...dataResponse, data: dataResponse.data.value } as any as SuccessResponse<TValue>;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    return dataResponse;
};

export const getAzureDevOpsRepo = async (
    organizationName: string,
    projectName: string,
    repoName: string,
    accessToken: string,
    activityId?: string
): Promise<GetAzureDevOpsRepoResponse> => {
    const options: FetchOptions<Response> = {
        ...adoRequestOptions,
        activityId,
        accessToken,
    };

    const url = `${Settings.AzureDevOpsBaseUrl}/${organizationName}/${projectName}/${PathPart.Apis}/${PathPart.Git}/${PathPart.Repositories}/${repoName}?${QueryParam.ApiVersion}=${ApiVersion.AzureDevOps}`;

    return await fetchAndHandleResponse(url, Method.GET, FailureOperation.GetAzureDevOpsRepo, options);
};

export const listAzureDevOpsBranches = async (
    repoUrl: string,
    accessToken: string,
    activityId?: string
): Promise<ListAzureDevOpsBranchesResponse> => {
    if (!isAdoUrl(repoUrl)) {
        throw new ClientError(
            'Invalid repo url passed to listAzureDevOpsBranches',
            FailureOperation.ListAzureDevOpsBranches
        );
    }

    const options: FetchAndHandleResponseOptions<Response> = {
        ...adoRequestOptions,
        activityId,
        accessToken,
    };

    const url = `${repoUrl}/${PathPart.Refs}?${QueryParam.ApiVersion}=${ApiVersion.AzureDevOps}`;

    return await fetchAzureDevOpsList(url, FailureOperation.ListAzureDevOpsBranches, options);
};

export const getAzureDevOpsCommit = async (
    repoUrl: string,
    branchObjectId: string,
    accessToken: string,
    activityId?: string
): Promise<GetAzureDevOpsCommitResponse> => {
    if (!isAdoUrl(repoUrl)) {
        throw new ClientError('Invalid repo url passed to getAzureDevOpsCommit', FailureOperation.GetAzureDevOpsCommit);
    }

    const options: FetchAndHandleResponseOptions<Response> = {
        ...adoRequestOptions,
        activityId,
        accessToken,
    };

    const url = `${repoUrl}/${PathPart.Commits}/${branchObjectId}?${QueryParam.ApiVersion}=${ApiVersion.AzureDevOps}`;

    return await fetchAndHandleResponse(url, Method.GET, FailureOperation.GetAzureDevOpsCommit, options);
};

export const getAzureDevOpsTree = async (
    repoUrl: string,
    treeId: string,
    accessToken: string,
    activityId?: string
): Promise<GetAzureDevOpsTreeResponse> => {
    if (!isAdoUrl(repoUrl)) {
        throw new ClientError('Invalid repo url passed to getAzureDevOpsTree', FailureOperation.GetAzureDevOpsTree);
    }

    const options: FetchAndHandleResponseOptions<Response> = {
        ...adoRequestOptions,
        activityId,
        accessToken,
    };

    const url = `${repoUrl}/${PathPart.Trees}/${treeId}?${QueryParam.Recursive}=true&${QueryParam.ApiVersion}=${ApiVersion.AzureDevOps}`;

    return await fetchAndHandleResponse(url, Method.GET, FailureOperation.GetAzureDevOpsTree, options);
};

export const getAzureDevOpsRepoItem = async (
    repoUrl: string,
    path: string,
    branchName?: string,
    accessToken?: string,
    activityId?: string
): Promise<GetAzureDevOpsRepoItemResponse> => {
    if (!isAdoUrl(repoUrl)) {
        throw new ClientError('Invalid repo url passed to getAzureDevOpsItem');
    }

    const options: FetchAndHandleResponseOptions<Response> = {
        ...adoRequestOptions,
        activityId,
        accessToken,
    };

    const hasBranch = !isUndefinedOrWhiteSpace(branchName);
    const branchParams = `${QueryParam.Version}=${branchName}&${QueryParam.VersionType}=${QueryParamValue.Branch}`;
    const queryParams = `${hasBranch ? `${branchParams}&` : ''}${QueryParam.ApiVersion}=${ApiVersion.AzureDevOps}`;

    const url = `${repoUrl}/${PathPart.Items}?${QueryParam.Path}=${path}&${queryParams}`;

    return await fetchAndHandleTextStreamResponse(url, Method.GET, FailureOperation.GetAzureDevOpsTree, options);
};
