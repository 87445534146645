import {
    Checkbox,
    FontWeights,
    IShimmerElement,
    Icon,
    Link,
    Shimmer,
    ShimmerElementType,
    Stack,
    makeStyles,
} from '@fluentui/react';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { SupportSectionLinks } from '../../../constants/support-section-links';
import { Status } from '../../../models/common';
import {
    CustomizationTaskListValidationError,
    CustomizationTaskListValidationStatus,
} from '../../../models/customization';
import { ProjectFromDiscoveryService, ProjectResource } from '../../../models/project';
import { SerializableMap } from '../../../types/serializable-map';
import { has } from '../../../utilities/serializable-map';
import { FluentIconNames } from '../../common/fluent-icon-names';
import Label from '../../common/form/label';
import Separator from '../../common/form/separator';
import { HibernateReadonlyField } from '../../dev-box/hibernate-readonly-field';
import {
    AddDevBoxFormField,
    AddDevBoxFormProjectViewModel,
    CustomizationData,
    PoolViewModel,
    ProjectToPoolViewModelMap,
} from '../models';
import AddDevBoxFormNameControl from './add-dev-box-form-name-control';
import AddDevBoxFormPoolControls from './add-dev-box-form-pool-controls';
import AddDevBoxFormProjectControls from './add-dev-box-form-project-controls';

interface AddDevBoxFormFieldGroupProps {
    isSubmitting: boolean;
    projects: AddDevBoxFormProjectViewModel[];
    pools: ProjectToPoolViewModelMap;
    devBoxName: string;
    selectedProject: AddDevBoxFormProjectViewModel | undefined;
    selectedPool: PoolViewModel | undefined;
    fileCustomizations: CustomizationData | undefined;
    didSomeProjectFailToLoadCreateResources: boolean;
    isSelectedProjectLimitReached: (project: AddDevBoxFormProjectViewModel | undefined) => boolean;
    projectOnChange: (value: AddDevBoxFormProjectViewModel | undefined) => void;
    customizationsOnChange: (value: CustomizationData | undefined) => void;
    projectsAuthorizedForDevBoxCustomize: SerializableMap<ProjectResource | ProjectFromDiscoveryService>;
    statusForValidateCustomizationTasks: Status;
    validationResult: CustomizationTaskListValidationStatus | undefined;
    errors: CustomizationTaskListValidationError[] | undefined;
    isLoading: boolean;
    hasCustomizationTaskDefinitions: boolean;
}

interface MultiPageCustomizationsFormFieldGroupProps {
    hasCustomizationTaskDefinitions: boolean;
    isLoading: boolean;
}

const messages = defineMessages({
    addDevBoxFormFieldGroupEnableCustomizationsCheckboxLabel: {
        id: 'AddDevBoxFormFieldGroup_EnableCustomizationsCheckbox_Label',
        defaultMessage: 'Apply customizations',
        description: 'Checkbox label for apply customizations',
    },
    addDevBoxFormFieldGroupEnableCustomizationsCheckboxAriaLabel: {
        id: 'AddDevBoxFormFieldGroup_EnableCustomizationsCheckbox_AriaLabel',
        defaultMessage: 'Apply customizations',
        description: 'Aria label for apply customizations',
    },
    customizationsPreviewTag: {
        id: 'AddDevBoxFormFieldGroup_PreviewTag',
        defaultMessage: 'preview',
        description:
            'A tag displayed to the right of the "Customize your dev box" field of the create dev box form indicating that customizations is a preview feature.',
    },
    customizationsFieldAriaLabel: {
        id: 'AddDevBoxFormFieldGroup_AriaLabel',
        defaultMessage: 'Customizations (preview)',
        description: 'Aria label for customizations field with preview tag.',
    },
});

const customizationsLearnMoreMessageValues = {
    Link: (chunks: string) => (
        <Link href={SupportSectionLinks.CustomizationsLink} target="_blank">
            {chunks}
        </Link>
    ),
};

const customizationsBoldValues = {
    b: (chunks: string) => <b>{chunks}</b>,
};

/**
 * Style Section
 */

const useLabelStyles = makeStyles({
    root: {
        fontWeight: FontWeights.regular,
        paddingLeft: 4,
    },
});

const useInfoIconStyles = makeStyles({
    root: {
        paddingTop: 3,
    },
});

const containerTokens = { childrenGap: 16 };

const infoTokens = { childrenGap: 8 };

const shimmerElements: IShimmerElement[] = [{ type: ShimmerElementType.line, height: 24 }];

const MultiPageCustomizationsFormFieldGroup: React.FC<MultiPageCustomizationsFormFieldGroupProps> = (props) => {
    const { hasCustomizationTaskDefinitions, isLoading } = props;

    const { formatMessage } = useIntl();

    const labelStyles = useLabelStyles();
    const infoIconStyles = useInfoIconStyles();

    const onRenderLabel = React.useCallback(() => {
        return (
            <Label
                tagContent={formatMessage(messages.customizationsPreviewTag)}
                aria-label={formatMessage(messages.customizationsFieldAriaLabel)}
                styles={labelStyles}
            >
                <FormattedMessage
                    id="AddDevBoxFormFieldGroup_Label"
                    defaultMessage="Apply customizations"
                    description="Label for customizations info field"
                />
            </Label>
        );
    }, [formatMessage, labelStyles]);

    if (isLoading) {
        return (
            <Stack tokens={containerTokens}>
                <Stack.Item>
                    <Separator />
                </Stack.Item>
                <Stack.Item>
                    <Shimmer shimmerElements={shimmerElements} />
                </Stack.Item>
                <Stack.Item>
                    <Shimmer shimmerElements={shimmerElements} />
                </Stack.Item>
            </Stack>
        );
    }

    if (!hasCustomizationTaskDefinitions) {
        return (
            <Stack tokens={containerTokens}>
                <Stack.Item>
                    <Separator />
                </Stack.Item>
                <Stack.Item>
                    <FormattedMessage
                        id="AddDevBoxFormFieldGroup_CustomizationsNotEnabledPartTwo_Text"
                        defaultMessage="<b>Customizations</b> is not enabled."
                        description="Text to let users know that customizations are not enabled for the project, <b></b> tags should not be localized."
                        values={customizationsBoldValues}
                    />
                </Stack.Item>
                <Stack horizontal tokens={infoTokens}>
                    <Stack.Item styles={infoIconStyles}>
                        <Icon iconName={FluentIconNames.Info} />
                    </Stack.Item>
                    <Stack.Item>
                        <FormattedMessage
                            id="AddDevBoxFormFieldGroup_NoTaskDefinitions_Text"
                            defaultMessage="To customize this dev box please ask the project admin to <Link>add a customization catalog with tasks</Link> to the project."
                            description="Text to let users know that they need to contact their project admin in order to customize their dev box. <Link></Link> tags should not be localized."
                            values={customizationsLearnMoreMessageValues}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack tokens={containerTokens}>
            <Stack.Item>
                <Separator />
            </Stack.Item>
            <Stack.Item>
                <FormattedMessage
                    id="AddDevBoxFormFieldGroup_Text"
                    defaultMessage="Configuration-as-code customizations will allow you to create a fully personalized ready-to-code environment. <Link>Learn more.</Link>"
                    description="Text giving information about customizations with a learn more link. <Link></Link> tags should not be localized."
                    values={customizationsLearnMoreMessageValues}
                />
            </Stack.Item>

            <Stack.Item>
                <Field<boolean> name={AddDevBoxFormField.ApplyCustomizationsEnabled}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { onChange, value } = input;

                        // Callback hooks
                        const onCustomizationsCheckboxChange = React.useCallback(
                            (_event: unknown, checked?: boolean) => {
                                onChange(checked ?? false);
                            },
                            [onChange]
                        );

                        return (
                            <Checkbox
                                checked={value}
                                onChange={onCustomizationsCheckboxChange}
                                onRenderLabel={onRenderLabel}
                                ariaLabel={formatMessage(
                                    messages.addDevBoxFormFieldGroupEnableCustomizationsCheckboxAriaLabel
                                )}
                            />
                        );
                    }}
                </Field>
            </Stack.Item>
        </Stack>
    );
};

export const AddDevBoxFormFieldGroup: React.FC<AddDevBoxFormFieldGroupProps> = (props) => {
    const {
        isSubmitting,
        projects,
        pools,
        selectedProject,
        devBoxName,
        selectedPool,
        didSomeProjectFailToLoadCreateResources,
        isSelectedProjectLimitReached,
        projectOnChange,
        projectsAuthorizedForDevBoxCustomize,
        isLoading,
        hasCustomizationTaskDefinitions,
    } = props;

    // Callback hooks
    const userCanCustomizeSelectedProject = React.useMemo(() => {
        if (selectedProject) {
            return has(projectsAuthorizedForDevBoxCustomize, selectedProject.id);
        }

        return false;
    }, [selectedProject, projectsAuthorizedForDevBoxCustomize]);

    return (
        <Stack tokens={containerTokens}>
            <Stack.Item>
                <Field<string> name={AddDevBoxFormField.DevBoxName}>
                    {(fieldProps) => {
                        const { input, meta } = fieldProps;
                        const { onChange } = input;
                        const { modified, active, error } = meta;

                        return (
                            <AddDevBoxFormNameControl
                                devBoxName={devBoxName}
                                onChange={onChange}
                                disabled={isSubmitting}
                                errorMessage={modified && !active ? error : undefined}
                            />
                        );
                    }}
                </Field>
            </Stack.Item>
            <Stack.Item>
                <Field<AddDevBoxFormProjectViewModel> name={AddDevBoxFormField.SelectedProject}>
                    {(fieldProps) => {
                        const { meta } = fieldProps;
                        const { modified, active, error } = meta;

                        return (
                            <AddDevBoxFormProjectControls
                                projects={projects}
                                disabled={isSubmitting}
                                onChange={projectOnChange}
                                selectedProject={selectedProject}
                                errorMessage={modified && !active ? error : undefined}
                                showFailedToLoadWarning={didSomeProjectFailToLoadCreateResources}
                            />
                        );
                    }}
                </Field>
            </Stack.Item>
            <Stack.Item>
                <Field<PoolViewModel> name={AddDevBoxFormField.SelectedPool}>
                    {(fieldProps) => {
                        const { input, meta } = fieldProps;
                        const { onChange } = input;
                        const { modified, active, error } = meta;

                        // Note: this is placed here because selectedProject will be undefined until it is registered (i.e. when we use 'Field').
                        // Only when its registered, will the initial value will be set for the field.
                        const selectedProjectId = React.useMemo(() => selectedProject?.id, [selectedProject]);

                        if (isSelectedProjectLimitReached(selectedProject)) {
                            return <></>;
                        }

                        return (
                            <AddDevBoxFormPoolControls
                                poolsByProject={pools}
                                disabled={isSubmitting}
                                onChange={onChange}
                                selectedProjectId={selectedProjectId}
                                selectedPool={selectedPool}
                                errorMessage={modified && !active ? error : undefined}
                            />
                        );
                    }}
                </Field>
            </Stack.Item>
            {selectedPool && (
                <Stack.Item>
                    <HibernateReadonlyField hibernateSupport={selectedPool.hibernateSupport} />
                </Stack.Item>
            )}
            {selectedProject && userCanCustomizeSelectedProject && (
                <MultiPageCustomizationsFormFieldGroup
                    hasCustomizationTaskDefinitions={hasCustomizationTaskDefinitions}
                    isLoading={isLoading}
                />
            )}
        </Stack>
    );
};

export default AddDevBoxFormFieldGroup;
