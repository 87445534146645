import { Context, useContext } from 'react';
import { SurfaceName } from '../../constants/app';
import { Severity } from '../../constants/telemetry';
import { FilterContext, SurfaceContext, SurfaceContextWithProperties } from '../../types/contexts';
import { trackTrace } from '../../utilities/telemetry/channel';

export const defaultCloseSurfaceHandler = (surface: SurfaceName): (() => void) => {
    return () => {
        trackTrace(
            `Attempted to close "${surface}" but no handler is available for it. Please check that a provider is configured for "${surface}" and that the consumer is a descendant of that provider.`,
            { severity: Severity.Verbose }
        );
    };
};

export const defaultOpenSurfaceHandler = (surface: SurfaceName): (() => void) => {
    return () => {
        trackTrace(
            `Attempted to open "${surface}" but no handler is available for it. Please check that a provider is configured for "${surface}" and that the consumer is a descendant of that provider.`,
            { severity: Severity.Verbose }
        );
    };
};

export const defaultOpenSurfaceHandlerWithProperties = <TProperties>(surface: SurfaceName): (() => void) => {
    return (_properties?: TProperties) => {
        trackTrace(
            `Attempted to open "${surface}" but no handler is available for it. Please check that a provider is configured for "${surface}" and that the consumer is a descendant of that provider.`,
            { severity: Severity.Verbose }
        );
    };
};

export const surfaceContextHook = (
    context: Context<SurfaceContext>,
    defaultOpenSurface: () => void,
    defaultCloseSurface: () => void
): (() => Required<SurfaceContext>) => {
    return () => {
        const { closeSurface, isOpen, openSurface } = useContext(context);

        return {
            closeSurface: closeSurface ?? defaultCloseSurface,
            isOpen,
            openSurface: openSurface ?? defaultOpenSurface,
        };
    };
};

export const surfaceContextWithPropertiesHook = <TProperties>(
    context: Context<SurfaceContextWithProperties<TProperties>>,
    defaultOpenSurface: (properties?: TProperties) => void,
    defaultCloseSurface: () => void,
    defaultProperties: TProperties
): (() => Required<SurfaceContextWithProperties<TProperties>>) => {
    return () => {
        const { closeSurface, isOpen, openSurface, properties } = useContext(context);

        return {
            closeSurface: closeSurface ?? defaultCloseSurface,
            isOpen,
            openSurface: openSurface ?? defaultOpenSurface,
            properties: properties ?? defaultProperties,
        };
    };
};

export const filterContextHook = <T>(context: Context<FilterContext<T>>): (() => Required<FilterContext<T>>) => {
    return () => {
        const { value, onChange } = useContext(context);

        return {
            value,
            onChange,
        };
    };
};
