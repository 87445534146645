import { OperationStatus } from '../../../models/common';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, PollPayload, ResultPayload } from '../core-actions';
import { pollOperationStatus } from './long-running-operation-action-creators';

/**
 * Action types
 */

type LongRunningOperationActionName = ActionName<'PollOperationStatus', 'Complete' | 'Error' | 'Failed'>;

export type LongRunningOperationActionType = ActionType<'POLL_OPERATION_STATUS', '_COMPLETE' | '_ERROR' | '_FAILED'>;

export const LongRunningOperationActionType: UnionValueMap<
    LongRunningOperationActionName,
    LongRunningOperationActionType
> = {
    PollOperationStatus: 'POLL_OPERATION_STATUS',
    PollOperationStatusError: 'POLL_OPERATION_STATUS_ERROR',
    PollOperationStatusFailed: 'POLL_OPERATION_STATUS_FAILED',
    PollOperationStatusComplete: 'POLL_OPERATION_STATUS_COMPLETE',
};

/**
 * Payloads
 */

export interface PollLongRunningOperationPayload extends PollPayload {
    operationLocation: string;
}
export type PollLongRunningOperationCompletePayload = ResultPayload<OperationStatus>;

/**
 * Action shorthands
 */

export type PollLongRunningOperationAction = ReturnType<typeof pollOperationStatus>;
