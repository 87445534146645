import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ListAzureDevOpsBranchesResponse } from '../../../data/services/azure-dev-ops/vso-code';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    getAzureDevOpsRepoContents,
    listAzureDevOpsBranches,
    loadAzureDevOpsRepoResources,
    loadAzureDevOpsRepoResourcesError,
    loadAzureDevOpsRepoResourcesFailed,
    loadAzureDevOpsRepoResourcesSuccess,
} from '../../actions/azure-dev-ops/azure-dev-ops-action-creators';
import {
    GetAzureDevOpsRepoContentsResponse,
    LoadAzureDevOpsRepoSubResourcesAction,
} from '../../actions/azure-dev-ops/azure-dev-ops-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery } from '../../effects/take';

export function* loadAzureDevOpsRepoResourcesSaga(action: LoadAzureDevOpsRepoSubResourcesAction): SagaIterator {
    const { meta, payload } = action;
    const { repoUrl, repo } = payload;

    try {
        const branchesResponse: ListAzureDevOpsBranchesResponse = yield putAndAwait(
            listAzureDevOpsBranches({ repoUrl }, meta)
        );

        if (isFailureResponse(branchesResponse)) {
            yield put(loadAzureDevOpsRepoResourcesFailed({ failure: branchesResponse, repoUrl }, meta));
            return;
        }

        // Loaded branches successfully, now load default branch's contents
        const defaultBranch = branchesResponse.data.find((branch) => branch.name === repo.defaultBranch);

        // Repo does not have default branch set up.
        // This is uncommon and means the repo has not been set up yet, but is not a load failure.
        if (!defaultBranch) {
            yield put(loadAzureDevOpsRepoResourcesSuccess({ repoUrl }, meta));
            return;
        }

        // Found default branch, now get repo contents
        const repoContentsResponse: GetAzureDevOpsRepoContentsResponse = yield putAndAwait(
            getAzureDevOpsRepoContents({ branchObjectId: defaultBranch.objectId, repoUrl }, meta)
        );

        if (isFailureResponse(repoContentsResponse)) {
            yield put(loadAzureDevOpsRepoResourcesFailed({ failure: repoContentsResponse, repoUrl }, meta));
            return;
        }

        // Loaded all sub-resources successfully
        yield put(loadAzureDevOpsRepoResourcesSuccess({ repoUrl }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadAzureDevOpsRepoResources);
        yield put(loadAzureDevOpsRepoResourcesError({ error, repoUrl }, meta));
    }
}

export function* loadAzureDevOpsRepoResourcesListenerSaga(): SagaIterator {
    yield takeEvery(loadAzureDevOpsRepoResources, loadAzureDevOpsRepoResourcesSaga);
}
