import { ApiVersion, AzureSearchParameter, ResourceType } from '../../../constants/azure';
import { Method } from '../../../constants/http';
import { BatchRequestName } from '../../../constants/resource-manager';
import { Severity } from '../../../constants/telemetry';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    isFailureResponse,
    isSuccessResponse,
} from '../../../models/common';
import { KeyValuePair } from '../../../types/key-value-pair';
import { SerializableMap } from '../../../types/serializable-map';
import { entries, get, set, size } from '../../../utilities/serializable-map';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackTrace } from '../../../utilities/telemetry/channel';
import { ProjectEnvironmentTypeResourceContract } from '../../contracts/project-environment-type';
import { PagedResultContract } from '../../contracts/resource-manager';
import { paginatedResourceRequest } from '../paginated-resource-request';
import { BatchedResourceRequest, batchedResourceRequest } from './batched-resource-request';

export type ListProjectEnvironmentTypesResponse = DataResponse<ProjectEnvironmentTypeResourceContract[]>;

const createBatchedListProjectEnvironmentTypesRequest = (resourceId: string): BatchedResourceRequest => ({
    method: Method.GET,
    name: `${BatchRequestName.ListProjectEnvironmentTypes}|${resourceId}`,
    operation: FailureOperation.ListProjectEnvironmentTypes,
    path: getPathForListProjectEnvironmentTypes(resourceId),
});

const getPathForListProjectEnvironmentTypes = (projectResourceId: string) =>
    `${projectResourceId}/${ResourceType.ProjectEnvironmentTypes}?${AzureSearchParameter.ApiVersion}=${ApiVersion.DevCenterControlPlane}`;

const hasNextLinksTrace = (resourceCount: number) =>
    `Batched request for project environment types required additional pages of data for ${resourceCount} resources.`;

const requestAdditionalPages = async (
    resourceId: string,
    nextLink: string,
    accessToken: string,
    activityId?: string
): Promise<[string, DataResponse<ProjectEnvironmentTypeResourceContract[]>]> => {
    const { pathname: path } = new URL(nextLink);

    return [
        resourceId,
        await paginatedResourceRequest<ProjectEnvironmentTypeResourceContract>(path, Method.GET, accessToken, {
            activityId,
            operation: FailureOperation.ListProjectEnvironmentTypes,
        }),
    ];
};

export const listManyProjectEnvironmentTypes = async (
    projectResourceIds: string[],
    accessToken: string,
    activityId?: string
): Promise<KeyValuePair<string, ListProjectEnvironmentTypesResponse>[]> => {
    // If only one project was requested, use the unbatched function
    if (projectResourceIds.length === 1) {
        const [projectResourceId] = projectResourceIds;
        const result = await listProjectEnvironmentTypes(projectResourceId, accessToken, activityId);

        return [KeyValuePair(projectResourceId, result)];
    }

    // Fire off initial set of requests as one batch request
    const batchRequests = projectResourceIds.map(createBatchedListProjectEnvironmentTypesRequest);

    const batchedResponses = await batchedResourceRequest<PagedResultContract<ProjectEnvironmentTypeResourceContract>>(
        batchRequests,
        accessToken,
        activityId
    );

    let dataResponses = SerializableMap<ListProjectEnvironmentTypesResponse>();
    let nextLinks = SerializableMap<string>();

    batchedResponses.forEach((response, index) => {
        const projectResourceId = projectResourceIds[index];

        if (isFailureResponse(response)) {
            dataResponses = set(dataResponses, projectResourceId, response);
            return;
        }

        const { data } = response;
        const { nextLink, value } = data;

        dataResponses = set(dataResponses, projectResourceId, {
            data: value,
            succeeded: true,
        });

        nextLinks = isNotUndefinedOrWhiteSpace(nextLink) ? set(nextLinks, projectResourceId, nextLink) : nextLinks;
    });

    // If any of the batched responses had nextLinks, page those individually and wait for completion.
    // Note: not batching these because we don't anticipate this will happen very often. If that turns out not to be the
    // case, consider writing a batch helper that handles paginated responses.
    if (size(nextLinks) > 0) {
        trackTrace(hasNextLinksTrace(size(nextLinks)), { severity: Severity.Warning });
    }

    const nextLinkResults = await Promise.all(
        entries(nextLinks).map((entry) => requestAdditionalPages(entry[0], entry[1], accessToken, activityId))
    );

    nextLinkResults.forEach((result) => {
        const [resourceId, response] = result;

        if (isFailureResponse(response)) {
            dataResponses = set(dataResponses, resourceId, response);
            return;
        }

        const { data } = response;
        const existingResponse = get(dataResponses, resourceId);
        const existingProjectEnvironmentTypes =
            existingResponse && isSuccessResponse(existingResponse) ? existingResponse.data : [];

        dataResponses = set(dataResponses, resourceId, {
            data: [...existingProjectEnvironmentTypes, ...data],
            succeeded: true,
        });
    });

    // Convert the map into a list of key-value pairs
    return entries(dataResponses);
};

export const listProjectEnvironmentTypes = async (
    projectResourceId: string,
    accessToken: string,
    activityId?: string
): Promise<ListProjectEnvironmentTypesResponse> => {
    if (isUndefinedOrWhiteSpace(projectResourceId)) {
        throw new ClientError(
            'Project resource ID cannot be empty or white space',
            FailureOperation.ListProjectEnvironmentTypes
        );
    }

    return await paginatedResourceRequest<ProjectEnvironmentTypeResourceContract>(
        getPathForListProjectEnvironmentTypes(projectResourceId),
        Method.GET,
        accessToken,
        {
            activityId,
            operation: FailureOperation.ListProjectEnvironmentTypes,
        }
    );
};
