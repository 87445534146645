import * as React from 'react';
import { FailureResponse } from '../../models/common';
import { SurfaceContextWithProperties } from '../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../common/surface-context-provider';
import { ErrorDetailsPanelProps } from './error-details-panel';

export type ErrorDetailsPanelContextProperties = Omit<ErrorDetailsPanelProps, 'isOpen' | 'onDismiss'>;

export const ErrorDetailsPanelContext = React.createContext<
    SurfaceContextWithProperties<ErrorDetailsPanelContextProperties>
>({
    isOpen: false,
    properties: {
        failure: FailureResponse(),
    },
});

export const ErrorDetailsPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<ErrorDetailsPanelContextProperties>(ErrorDetailsPanelContext);
