import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { getTokensFromScheduleDataPlaneUri } from '../../../ids/schedule';
import {
    DataResponse,
    FailureOperation,
    FailureResponse,
    isFailureResponse,
    isSuccessResponse,
} from '../../../models/common';
import { ScheduleContract } from '../../contracts/schedule';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetScheduleResponse = DataResponse<ScheduleContract>;
export type ListSchedulesByProjectResponse = DataResponse<ScheduleContract[]>;

export const getSchedule = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetScheduleResponse> => {
    const { devCenter, poolName, projectName, scheduleName } = getTokensFromScheduleDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetSchedule,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getScheduleByPool(
                projectName,
                poolName,
                scheduleName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.getScheduleByPool(
                projectName,
                poolName,
                scheduleName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listSchedulesByProject = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListSchedulesByProjectResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListSchedulesByProject,

        whenUsingBetaClient: (client) =>
            client.devBoxes.listSchedulesByProject(projectName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.listSchedulesByProject(projectName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const isScheduleSuccessResponse = (response: GetScheduleResponse): boolean =>
    isSuccessResponse(response) || response.statusCode === 404;
export const isScheduleFailureResponse = (response: DataResponse): response is FailureResponse =>
    isFailureResponse(response) && response.statusCode !== 404;
