import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { isValidDataPlaneUri, parseDataPlanePath } from './data-plane';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const devBoxActionPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.User}/${StringifiedPattern.Guid}/${DataPlaneResourcePathPrefix.DevBox}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.DevBoxAction}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createDevBoxActionDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'devBoxActionName' | 'devBoxName' | 'devCenter' | 'projectName' | 'user'>
): string => {
    const { devBoxActionName, devBoxName, devCenter, projectName, user } = tokens;

    if (isUndefinedOrWhiteSpace(devBoxActionName)) {
        throw new ClientError('Dev box action name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devBoxName)) {
        throw new ClientError('Dev box name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(user)) {
        throw new ClientError('User token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.User,
        user.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBox,
        devBoxName.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBoxAction,
        devBoxActionName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromDevBoxActionDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'devBoxActionName' | 'devBoxName' | 'devCenter' | 'projectName' | 'user'> => {
    if (!isValidDevBoxActionDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a dev box action');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , user, , devBoxName, , devBoxActionName] = parseDataPlanePath(pathname);

    return {
        devBoxActionName,
        devBoxName,
        devCenter: origin,
        projectName,
        user,
    };
};

export const isValidDevBoxActionDataPlaneUri = (uri: string): boolean =>
    isValidDataPlaneUri(uri, devBoxActionPathPattern);

export const tryCreateDevBoxActionDataPlaneUri = tryOrDefault(createDevBoxActionDataPlaneUri);
export const tryGetTokensFromDevBoxActionDataPlaneUri = tryOrDefault(getTokensFromDevBoxActionDataPlaneUri);
