import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { discoverPoolsForDevBoxesListenerSaga } from './discover-pools-for-dev-boxes';
import { discoverPoolsForProjectsListenerSaga } from './discover-pools-for-projects';
import { listPoolsListenerSaga } from './list-pools';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverPoolsForProjectsListenerSaga),
        fork(listPoolsListenerSaga),
        fork(discoverPoolsForDevBoxesListenerSaga),
    ]);
}
