import { UnionMap, UnionValueMap } from '../types/union-map';

type ContentTypeName =
    | 'ApplicationJson'
    | 'ImageAll'
    | 'ImagePng'
    | 'TextCss'
    | 'TextHtml'
    | 'TextPlain'
    | 'ApplicationOctetStream';

export type ContentType =
    | 'application/json'
    | 'image/*'
    | 'image/png'
    | 'text/css'
    | 'text/html'
    | 'text/plain'
    | 'application/octet-stream';

export const ApplicationJson: ContentType = 'application/json';
export const ImageAll: ContentType = 'image/*';
export const ImagePng: ContentType = 'image/png';
export const TextCss: ContentType = 'text/css';
export const TextHtml: ContentType = 'text/html';
export const TextPlain: ContentType = 'text/plain';
export const ApplicationOctetStream: ContentType = 'application/octet-stream';

export const ContentType: UnionValueMap<ContentTypeName, ContentType> = {
    ApplicationJson,
    ImageAll,
    ImagePng,
    TextCss,
    TextHtml,
    TextPlain,
    ApplicationOctetStream,
};

type HeaderName =
    | 'Accept'
    | 'AcceptLanguage'
    | 'ActivityId'
    | 'Authorization'
    | 'ClientRequestId'
    | 'ContentType'
    | 'RetryAfter'
    | 'Traceparent';

export type Header =
    | 'Accept'
    | 'Accept-Language'
    | 'Authorization'
    | 'Content-Type'
    | 'Retry-After'
    | 'traceparent'
    | 'x-ms-client-request-id'
    | 'x-ms-dev-portal-activity-id';

export const Header: UnionValueMap<HeaderName, Header> = {
    Accept: 'Accept',
    AcceptLanguage: 'Accept-Language',
    ActivityId: 'x-ms-dev-portal-activity-id',
    Authorization: 'Authorization',
    ClientRequestId: 'x-ms-client-request-id',
    ContentType: 'Content-Type',
    RetryAfter: 'Retry-After',
    Traceparent: 'traceparent',
};

export type Method = 'DELETE' | 'GET' | 'HEAD' | 'OPTIONS' | 'PATCH' | 'POST' | 'PUT' | 'TRACE';

export const DELETE: Method = 'DELETE';
export const GET: Method = 'GET';
export const HEAD: Method = 'HEAD';
export const OPTIONS: Method = 'OPTIONS';
export const PATCH: Method = 'PATCH';
export const POST: Method = 'POST';
export const PUT: Method = 'PUT';
export const TRACE: Method = 'TRACE';

export const Method: UnionMap<Method> = {
    DELETE,
    GET,
    HEAD,
    OPTIONS,
    PATCH,
    POST,
    PUT,
    TRACE,
};

type StatusCodeName = 'ServiceUnavailable' | 'TooManyRequests';

export type StatusCode = 429 | 503;

export const StatusCode: UnionValueMap<StatusCodeName, StatusCode> = {
    ServiceUnavailable: 503,
    TooManyRequests: 429,
};
