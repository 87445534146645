import { AzureDevOpsRepo, AzureDevOpsTreeEntry } from '../../../models/azure-dev-ops';
import { DataResponse, Nothing } from '../../../models/common';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload } from '../core-actions';
import {
    getAzureDevOpsRepoByCloneUri,
    getAzureDevOpsRepoContents,
    getAzureDevOpsRepoItem,
    listAzureDevOpsBranches,
    loadAzureDevOpsRepoResources,
} from './azure-dev-ops-action-creators';

/**
 * Action types
 */

type AzureDevOpsActionNamePrefixes =
    | 'LoadAzureDevOpsRepoResources'
    | 'GetAzureDevOpsRepoByCloneUri'
    | 'ListAzureDevOpsBranches'
    | 'GetAzureDevOpsRepoContents'
    | 'GetAzureDevOpsRepoItem';

type AzureDevOpsActionName = ActionName<AzureDevOpsActionNamePrefixes, 'Error' | 'Failed' | 'Success'>;

type AzureDevOpsActionTypePrefixes =
    | 'LOAD_AZURE_DEV_OPS_REPO_RESOURCES'
    | 'GET_AZURE_DEV_OPS_REPO_BY_CLONE_URI'
    | 'LIST_AZURE_DEV_OPS_BRANCHES'
    | 'GET_AZURE_DEV_OPS_REPO_CONTENTS'
    | 'GET_AZURE_DEV_OPS_REPO_ITEM';

export type AzureDevOpsActionType = ActionType<AzureDevOpsActionTypePrefixes, '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const AzureDevOpsActionType: UnionValueMap<AzureDevOpsActionName, AzureDevOpsActionType> = {
    LoadAzureDevOpsRepoResources: 'LOAD_AZURE_DEV_OPS_REPO_RESOURCES',
    LoadAzureDevOpsRepoResourcesError: 'LOAD_AZURE_DEV_OPS_REPO_RESOURCES_ERROR',
    LoadAzureDevOpsRepoResourcesFailed: 'LOAD_AZURE_DEV_OPS_REPO_RESOURCES_FAILED',
    LoadAzureDevOpsRepoResourcesSuccess: 'LOAD_AZURE_DEV_OPS_REPO_RESOURCES_SUCCESS',
    GetAzureDevOpsRepoByCloneUri: 'GET_AZURE_DEV_OPS_REPO_BY_CLONE_URI',
    GetAzureDevOpsRepoByCloneUriError: 'GET_AZURE_DEV_OPS_REPO_BY_CLONE_URI_ERROR',
    GetAzureDevOpsRepoByCloneUriFailed: 'GET_AZURE_DEV_OPS_REPO_BY_CLONE_URI_FAILED',
    GetAzureDevOpsRepoByCloneUriSuccess: 'GET_AZURE_DEV_OPS_REPO_BY_CLONE_URI_SUCCESS',
    ListAzureDevOpsBranches: 'LIST_AZURE_DEV_OPS_BRANCHES',
    ListAzureDevOpsBranchesError: 'LIST_AZURE_DEV_OPS_BRANCHES_ERROR',
    ListAzureDevOpsBranchesFailed: 'LIST_AZURE_DEV_OPS_BRANCHES_FAILED',
    ListAzureDevOpsBranchesSuccess: 'LIST_AZURE_DEV_OPS_BRANCHES_SUCCESS',
    GetAzureDevOpsRepoContents: 'GET_AZURE_DEV_OPS_REPO_CONTENTS',
    GetAzureDevOpsRepoContentsError: 'GET_AZURE_DEV_OPS_REPO_CONTENTS_ERROR',
    GetAzureDevOpsRepoContentsFailed: 'GET_AZURE_DEV_OPS_REPO_CONTENTS_FAILED',
    GetAzureDevOpsRepoContentsSuccess: 'GET_AZURE_DEV_OPS_REPO_CONTENTS_SUCCESS',
    GetAzureDevOpsRepoItem: 'GET_AZURE_DEV_OPS_REPO_ITEM',
    GetAzureDevOpsRepoItemError: 'GET_AZURE_DEV_OPS_REPO_ITEM_ERROR',
    GetAzureDevOpsRepoItemFailed: 'GET_AZURE_DEV_OPS_REPO_ITEM_FAILED',
    GetAzureDevOpsRepoItemSuccess: 'GET_AZURE_DEV_OPS_REPO_ITEM_SUCCESS',
};

/**
 * Payloads
 */

export type RepoCloneUrlIndexedPayload = IndexedPayload<'cloneUrl'>;

export type RepoUrlIndexedPayload = IndexedPayload<'repoUrl'>;

export type BranchObjectIdIndexedPayload = IndexedPayload<'branchObjectId'>;

export type LoadAzureDevOpsRepoSubResourcesPayload = RepoUrlIndexedPayload & { repo: AzureDevOpsRepo };

export type GetAzureDevOpsRepoContentsPayload = BranchObjectIdIndexedPayload & { repoUrl: string };

export type RepoItemPathIdentifierIndexedPayload = IndexedPayload<'repoItemPathIdentifier'>;

/**
 * Action shorthands
 */

export type LoadAzureDevOpsRepoSubResourcesAction = ReturnType<typeof loadAzureDevOpsRepoResources>;

export type GetAzureDevOpsRepoByCloneUriAction = ReturnType<typeof getAzureDevOpsRepoByCloneUri>;

export type ListAzureDevOpsBranchesAction = ReturnType<typeof listAzureDevOpsBranches>;

export type GetAzureDevOpsRepoContentsAction = ReturnType<typeof getAzureDevOpsRepoContents>;

export type GetAzureDevOpsRepoItemAction = ReturnType<typeof getAzureDevOpsRepoItem>;

/**
 * Responses
 */

export type GetAzureDevOpsRepoByCloneUriResponse = DataResponse<AzureDevOpsRepo | Nothing>;

export type GetAzureDevOpsRepoContentsResponse = DataResponse<AzureDevOpsTreeEntry[]>;
