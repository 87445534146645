import { SerializableMap } from '../../types/serializable-map';
import { get } from '../../utilities/serializable-map';
import {
    EnvironmentDefinitionParameterValue,
    EnvironmentDefinitionParameterViewModel,
} from '../environment/parameters-field/models';
import { setParameterValue } from '../environment/parameters-field/selectors';

const getEditableValueForParameter = (
    parameter: EnvironmentDefinitionParameterViewModel,
    value: unknown
): EnvironmentDefinitionParameterValue => {
    switch (parameter.type) {
        // Bug #1820334: if the parameter is an array or object, the parameter value has an array or object runtime
        // type, but we want the user to edit its JSON representation as a string. Default serialization will
        // convert to values like "[object Object]", however. As a special case, set initial val to stringified form
        case 'array':
        case 'object':
            return JSON.stringify(value);

        default:
            return value as EnvironmentDefinitionParameterValue;
    }
};

export const getInitialParameterValuesForRedeploy = (
    environmentDefinitionParameters: EnvironmentDefinitionParameterViewModel[] | undefined,
    environmentParameters: { [key: string]: unknown } | undefined
): SerializableMap<EnvironmentDefinitionParameterValue> => {
    let initialValues = SerializableMap<EnvironmentDefinitionParameterValue>();

    const environmentParameterMap = SerializableMap(environmentParameters);

    environmentDefinitionParameters?.forEach((parameter) => {
        const parameterValue = get(environmentParameterMap, parameter.id);

        if (parameterValue) {
            const editableValue = getEditableValueForParameter(parameter, parameterValue);
            initialValues = setParameterValue(initialValues, parameter.id, editableValue);
            return;
        }

        if (parameter.default) {
            initialValues = setParameterValue(initialValues, parameter.id, parameter.default);
            return;
        }
    });

    return initialValues;
};
