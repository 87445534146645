import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

export const useActionCreator = <TFunc extends (...args: never[]) => AnyAction>(
    actionCreator: TFunc
): ((...args: Parameters<TFunc>) => void) => {
    const dispatch = useDispatch();

    return useCallback(
        (...args: Parameters<TFunc>) => {
            dispatch(actionCreator(...args));
        },
        [actionCreator, dispatch]
    );
};
