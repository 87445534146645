import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity, Nothing } from '../../models/common';
import { trackTrace } from '../../utilities/telemetry/channel';
import {
    getAzureDevOpsRepoByCloneUri,
    getAzureDevOpsRepoByCloneUriError,
    getAzureDevOpsRepoByCloneUriFailed,
    getAzureDevOpsRepoByCloneUriSuccess,
    getAzureDevOpsRepoContents,
    getAzureDevOpsRepoContentsError,
    getAzureDevOpsRepoContentsFailed,
    getAzureDevOpsRepoContentsSuccess,
    getAzureDevOpsRepoItem,
    getAzureDevOpsRepoItemError,
    getAzureDevOpsRepoItemFailed,
    getAzureDevOpsRepoItemSuccess,
    listAzureDevOpsBranches,
    listAzureDevOpsBranchesError,
    listAzureDevOpsBranchesFailed,
    listAzureDevOpsBranchesSuccess,
    loadAzureDevOpsRepoResources,
    loadAzureDevOpsRepoResourcesError,
    loadAzureDevOpsRepoResourcesFailed,
    loadAzureDevOpsRepoResourcesSuccess,
} from '../actions/azure-dev-ops/azure-dev-ops-action-creators';
import {
    BranchObjectIdIndexedPayload,
    RepoCloneUrlIndexedPayload,
    RepoItemPathIdentifierIndexedPayload,
    RepoUrlIndexedPayload,
} from '../actions/azure-dev-ops/azure-dev-ops-actions';
import {
    azureDevOpsBranchesAdapter,
    azureDevOpsRepoAdapter,
    azureDevOpsRepoContentsAdapter,
} from '../adapters/azure-dev-ops-adapters';
import { stringAdapter } from '../adapters/common/string-adapter';
import { AzureDevOpsDataStore, AzureDevOpsStatusStore, AzureDevOpsStore } from '../store/azure-dev-ops-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducerWithCustomId } from './indexed-status-reducer';

export const azureDevOpsReducer = combineReducers<AzureDevOpsStore>({
    data: createReducer(AzureDevOpsDataStore(), (builder) => {
        builder.addCase(getAzureDevOpsRepoByCloneUriSuccess, (store, action) => {
            const { result, cloneUrl } = getPayload(action);

            // If the repo does not exist / user does not have access, the result value will be `Nothing`
            if (result !== Nothing) {
                azureDevOpsRepoAdapter.setOne(store.repos, Entity(cloneUrl, result));
            }
        });

        builder.addCase(listAzureDevOpsBranchesSuccess, (store, action) => {
            const { result, repoUrl } = getPayload(action);

            azureDevOpsBranchesAdapter.setOne(store.branches, Entity(repoUrl, result));
        });

        builder.addCase(getAzureDevOpsRepoContentsSuccess, (store, action) => {
            const { result, branchObjectId } = getPayload(action);

            // We are assuming that branchObjectId is globally unique. Telemetry to make sure that is the case.
            if (store.repoContents.ids.includes(branchObjectId)) {
                trackTrace('Duplicate Azure DevOps branch objectId');
            }

            azureDevOpsRepoContentsAdapter.setOne(store.repoContents, Entity(branchObjectId, result));
        });

        builder.addCase(getAzureDevOpsRepoItemSuccess, (store, action) => {
            const { result, repoItemPathIdentifier } = getPayload(action);

            stringAdapter.setOne(store.repoItems, Entity(repoItemPathIdentifier, result));
        });
    }),

    status: combineReducers<AzureDevOpsStatusStore>({
        getRepoByCloneUrl: createIndexedStatusReducerWithCustomId({
            inProgress: getAzureDevOpsRepoByCloneUri,
            error: getAzureDevOpsRepoByCloneUriError,
            failed: getAzureDevOpsRepoByCloneUriFailed,
            success: getAzureDevOpsRepoByCloneUriSuccess,
            getId: (action: PayloadAction<RepoCloneUrlIndexedPayload>) => action.payload.cloneUrl,
        }),

        loadRepoResources: createIndexedStatusReducerWithCustomId({
            inProgress: loadAzureDevOpsRepoResources,
            error: loadAzureDevOpsRepoResourcesError,
            failed: loadAzureDevOpsRepoResourcesFailed,
            success: loadAzureDevOpsRepoResourcesSuccess,
            getId: (action: PayloadAction<RepoUrlIndexedPayload>) => action.payload.repoUrl,
        }),

        listBranches: createIndexedStatusReducerWithCustomId({
            inProgress: listAzureDevOpsBranches,
            error: listAzureDevOpsBranchesError,
            failed: listAzureDevOpsBranchesFailed,
            success: listAzureDevOpsBranchesSuccess,
            getId: (action: PayloadAction<RepoUrlIndexedPayload>) => action.payload.repoUrl,
        }),

        getRepoContents: createIndexedStatusReducerWithCustomId({
            inProgress: getAzureDevOpsRepoContents,
            error: getAzureDevOpsRepoContentsError,
            failed: getAzureDevOpsRepoContentsFailed,
            success: getAzureDevOpsRepoContentsSuccess,
            getId: (action: PayloadAction<BranchObjectIdIndexedPayload>) => action.payload.branchObjectId,
        }),

        getRepoItem: createIndexedStatusReducerWithCustomId({
            inProgress: getAzureDevOpsRepoItem,
            error: getAzureDevOpsRepoItemError,
            failed: getAzureDevOpsRepoItemFailed,
            success: getAzureDevOpsRepoItemSuccess,
            getId: (action: PayloadAction<RepoItemPathIdentifierIndexedPayload>) =>
                action.payload.repoItemPathIdentifier,
        }),
    }),
});
