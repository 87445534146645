import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { DevBoxDetailsPanelProps } from './dev-box-details-panel';

export type DevBoxDetailsPanelContextProperties = Omit<DevBoxDetailsPanelProps, 'isOpen' | 'onDismiss' | 'locations'>;

export const DevBoxDetailsPanelContext = React.createContext<
    SurfaceContextWithProperties<DevBoxDetailsPanelContextProperties>
>({ isOpen: false });

export const DevBoxDetailsPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<DevBoxDetailsPanelContextProperties>(DevBoxDetailsPanelContext);
