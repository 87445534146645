import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { RepairDialogProps } from './repair-dialog';

export type RepairDialogProperties = Omit<RepairDialogProps, 'isOpen' | 'onDismiss'>;

export const RepairDialogContext = React.createContext<SurfaceContextWithProperties<RepairDialogProperties>>({
    isOpen: false,
});

export const RepairDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<RepairDialogProperties>(RepairDialogContext);
