import { FontSizes, FontWeights, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { operatingSystemFamily } from '../../../utilities/browser';
import { getSemanticColor } from '../../../utilities/styles';
import { DownloadRemoteDesktopButton } from './download-remote-desktop-button';
import OperatingSystemFamilyImage from './operating-system-family-image';
import { getMessageDescriptorForTitle } from './selectors';

/**
 * Styles
 */

const useContainerStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, 'downloadRemoteDesktopCardBackground'),
        borderRadius: '4px',
        boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
        padding: '20px 14px 16px 20px',
    },
}));

const useImageContainerStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${getSemanticColor(theme, 'downloadRemoteDesktopCardImageBorder')}`,
        borderRadius: '6px',
        padding: '14px',
    },
}));

const useStyles = makeStyles({
    image: {
        verticalAlign: 'middle',
    },
});

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
    },
});

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
        lineHeight: '20px',
    },
});

/**
 * END Styles
 */

const containerTokens: IStackTokens = {
    childrenGap: 12,
};

const contentTokens: IStackTokens = {
    childrenGap: 16,
};

const informationTokens: IStackTokens = {
    childrenGap: 2,
};

export const DownloadRemoteDesktopCard: React.FC = () => {
    // Style hooks
    const classNames = useStyles();
    const containerStyles = useContainerStyles();
    const imageContainerStyles = useImageContainerStyles();
    const subtitleStyles = useSubtitleStyles();
    const titleStyles = useTitleStyles();

    // Memoized data
    const titleMessageDescriptor = React.useMemo(() => getMessageDescriptorForTitle(operatingSystemFamily), []);

    return (
        <Stack styles={containerStyles} tokens={containerTokens}>
            <Stack.Item>
                <Stack horizontal tokens={contentTokens}>
                    <Stack.Item styles={imageContainerStyles}>
                        <OperatingSystemFamilyImage
                            className={classNames.image}
                            operatingSystemFamily={operatingSystemFamily}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        <Stack tokens={informationTokens}>
                            <Stack.Item styles={titleStyles}>
                                <FormattedMessage {...titleMessageDescriptor} />
                            </Stack.Item>

                            <Stack.Item styles={subtitleStyles}>
                                <FormattedMessage
                                    id="DownloadRemoteDesktopCard_Subtitle_Text"
                                    defaultMessage="Microsoft Corporation"
                                    description="Text informing the user of the company (Microsoft) that produced the Remote Desktop software we're linking to."
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>

            <Stack.Item>
                <Stack horizontal horizontalAlign="end">
                    <Stack.Item>
                        <DownloadRemoteDesktopButton operatingSystemFamily={operatingSystemFamily} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default DownloadRemoteDesktopCard;
