import { EnvironmentDefinition } from '../../../models/environment-definition';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    discoverEnvironmentDefinitionsForEnvironments,
    discoverEnvironmentDefinitionsForProjects,
    listEnvironmentDefinitions,
} from './environment-definition-action-creators';

/**
 * Action types
 */

type EnvironmentDefinitionActionName =
    | ActionName<'DiscoverEnvironmentDefinitionsForEnvironments', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DiscoverEnvironmentDefinitionsForProjects', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListEnvironmentDefinitions', 'Error' | 'Failed' | 'Success'>;

export type EnvironmentDefinitionActionType =
    | ActionType<'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_ENVIRONMENTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_PROJECTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_ENVIRONMENT_DEFINITIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const EnvironmentDefinitionActionType: UnionValueMap<
    EnvironmentDefinitionActionName,
    EnvironmentDefinitionActionType
> = {
    DiscoverEnvironmentDefinitionsForEnvironments: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_ENVIRONMENTS',
    DiscoverEnvironmentDefinitionsForEnvironmentsError: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_ENVIRONMENTS_ERROR',
    DiscoverEnvironmentDefinitionsForEnvironmentsFailed: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_ENVIRONMENTS_FAILED',
    DiscoverEnvironmentDefinitionsForEnvironmentsSuccess: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_ENVIRONMENTS_SUCCESS',
    DiscoverEnvironmentDefinitionsForProjects: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_PROJECTS',
    DiscoverEnvironmentDefinitionsForProjectsError: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_PROJECTS_ERROR',
    DiscoverEnvironmentDefinitionsForProjectsFailed: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_PROJECTS_FAILED',
    DiscoverEnvironmentDefinitionsForProjectsSuccess: 'DISCOVER_ENVIRONMENT_DEFINITIONS_FOR_PROJECTS_SUCCESS',
    ListEnvironmentDefinitions: 'LIST_ENVIRONMENT_DEFINITIONS',
    ListEnvironmentDefinitionsError: 'LIST_ENVIRONMENT_DEFINITIONS_ERROR',
    ListEnvironmentDefinitionsFailed: 'LIST_ENVIRONMENT_DEFINITIONS_FAILED',
    ListEnvironmentDefinitionsSuccess: 'LIST_ENVIRONMENT_DEFINITIONS_SUCCESS',
};

/**
 * Payloads
 */

export type ListEnvironmentDefinitionsSuccessPayload = IndexedPayload & ResultPayload<EnvironmentDefinition[]>;

/**
 * Action shorthands
 */

export type DiscoverEnvironmentDefinitionsForEnvironmentsAction = ReturnType<
    typeof discoverEnvironmentDefinitionsForEnvironments
>;

export type DiscoverEnvironmentDefinitionsForProjectsAction = ReturnType<
    typeof discoverEnvironmentDefinitionsForProjects
>;

export type ListEnvironmentDefinitionsAction = ReturnType<typeof listEnvironmentDefinitions>;
