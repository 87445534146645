import { DependencyList, useCallback, useMemo } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';

export const useFormatter = <T>(
    fn: (value: T, formatters: IntlFormatters) => string,
    dependencies: DependencyList = []
): ((value: T) => string) => {
    const {
        formatDate,
        formatDateTimeRange,
        formatDateToParts,
        formatDisplayName,
        formatList,
        formatListToParts,
        formatMessage,
        formatNumber,
        formatNumberToParts,
        formatPlural,
        formatRelativeTime,
        formatTime,
        formatTimeToParts,
        $t,
    } = useIntl();

    // Recreating IntlFormatters instance in order to not assume useIntl is doing memoization
    const formatters = useMemo<IntlFormatters>(
        () => ({
            formatDate,
            formatDateTimeRange,
            formatDateToParts,
            formatDisplayName,
            formatList,
            formatListToParts,
            formatMessage,
            formatNumber,
            formatNumberToParts,
            formatPlural,
            formatRelativeTime,
            formatTime,
            formatTimeToParts,
            $t,
        }),
        [
            formatDate,
            formatDateTimeRange,
            formatDateToParts,
            formatDisplayName,
            formatList,
            formatListToParts,
            formatMessage,
            formatNumber,
            formatNumberToParts,
            formatPlural,
            formatRelativeTime,
            formatTime,
            formatTimeToParts,
            $t,
        ]
    );

    return useCallback((value: T) => fn(value, formatters), [fn, formatters, ...dependencies]);
};
