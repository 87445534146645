import { IRenderFunction, ITextFieldProps, makeStyles, mergeStyleSets, TextField } from '@fluentui/react';
import React from 'react';
import { usePrevious } from '../../../../hooks/use-previous';
import { InputElementProps } from '../types';

interface NumberTextInputProps extends Omit<ITextFieldProps, 'onChange' | 'value'> {
    onChange: (value: number | undefined, error?: string) => void;
    value: number | undefined;
}

/**
 * Styles
 */

// Styles to hide the up-down arrows on type=number input
const useNumberTextFieldStyles = makeStyles({
    field: {
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
});

/**
 * END Styles
 */

const suppressWheelEvent = (event: WheelEvent) => event.preventDefault();
const getStringValue = (value: number | undefined) => (value === undefined ? '' : value.toString());

export const NumberTextInput: React.FC<NumberTextInputProps> = (props: NumberTextInputProps) => {
    const { onChange, value, onRenderInput: providedOnRenderInput, styles: providedStyles } = props;

    const defaultNumberTextFieldStyles = useNumberTextFieldStyles();
    const numberTextFieldStyles = React.useMemo(
        () => mergeStyleSets(defaultNumberTextFieldStyles, providedStyles),
        [defaultNumberTextFieldStyles, providedStyles]
    );

    // Local state to store string value of number while maintaining a number value prop
    const [stringValue, setStringValue] = React.useState<string | undefined>(getStringValue(value));

    // Effect to update local value state when value prop changes
    React.useEffect(() => {
        if (Number(stringValue) !== value) {
            setStringValue(getStringValue(value));
        }
    }, [value, stringValue]);

    const inputRef = React.useRef<HTMLInputElement>(null);
    const previousInputRef = usePrevious(inputRef);
    React.useEffect(() => {
        // Disable default behavior of changing value on scroll
        inputRef.current?.addEventListener('wheel', suppressWheelEvent);
        previousInputRef?.current?.removeEventListener('wheel', suppressWheelEvent);
    }, [inputRef.current, previousInputRef?.current]);

    const onChangeHandler = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined): void => {
            setStringValue(value);

            const numberValue = !!value ? Number(value) : undefined;
            onChange(numberValue);
        },
        [onChange]
    );

    const onRenderInput: IRenderFunction<InputElementProps> = React.useCallback(
        (props, defaultRender) => {
            const { onKeyDown: providedOnKeyDown } = props ?? {};

            const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
                providedOnKeyDown?.(event);

                // Disable default behavior of changing value on arrow key press
                if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                    event.preventDefault();
                }

                return;
            };

            const numberInputProps: InputElementProps = {
                ...props,
                type: 'number',
                onKeyDown,
                ref: inputRef,
            };

            if (providedOnRenderInput) {
                return providedOnRenderInput(numberInputProps, defaultRender);
            }

            // defaultRender won't ever be undefined, but Fluent's typedef says it could be
            if (!defaultRender) {
                return null;
            }

            return defaultRender(numberInputProps);
        },
        [providedOnRenderInput, inputRef]
    );

    return (
        <TextField
            {...props}
            onRenderInput={onRenderInput}
            onChange={onChangeHandler}
            value={stringValue}
            styles={numberTextFieldStyles}
        />
    );
};

export default NumberTextInput;
