import { combineReducers } from 'redux';
import {
    warmAllDevCenterNameRecords,
    warmAllDevCenterNameRecordsError,
    warmAllDevCenterNameRecordsFailed,
    warmAllDevCenterNameRecordsSuccess,
    warmDevCenterNameRecord,
    warmDevCenterNameRecordError,
    warmDevCenterNameRecordFailed,
    warmDevCenterNameRecordSuccess,
} from '../actions/dev-center/dev-center-action-creators';
import { DevCenterStatusStore, DevCenterStore } from '../store/dev-center-store';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const devCenterReducer = combineReducers<DevCenterStore>({
    status: combineReducers<DevCenterStatusStore>({
        warmAllDevCenterNameRecords: createStatusReducer({
            inProgress: warmAllDevCenterNameRecords,
            error: warmAllDevCenterNameRecordsError,
            failed: warmAllDevCenterNameRecordsFailed,
            success: warmAllDevCenterNameRecordsSuccess,
        }),

        warmDevCenterNameRecord: createIndexedStatusReducer({
            inProgress: warmDevCenterNameRecord,
            error: warmDevCenterNameRecordError,
            failed: warmDevCenterNameRecordFailed,
            success: warmDevCenterNameRecordSuccess,
        }),
    }),
});
