import { FontSizes, FontWeights, IStackTokens, makeStyles, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { PropsWithChildren } from '../../types/props-with-children';
import { AriaLiveWrapper } from './accessibility/aria-live-wrapper';

type InProgressStateMessageProps = PropsWithChildren<{
    ariaLabel: string;
}>;

const useInProgressStateMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.semibold,
        lineHeight: 16,
    },
});

const inProgressStateMessageTokens: IStackTokens = { childrenGap: 8 };

export const InProgressStateMessage: React.FC<InProgressStateMessageProps> = (props) => {
    const { children, ariaLabel } = props;

    // Style hooks
    const inProgressStateMessageContainerStyles = useInProgressStateMessageContainerStyles();

    return (
        <AriaLiveWrapper>
            <Stack horizontal tokens={inProgressStateMessageTokens} verticalAlign="center">
                <Stack.Item>
                    <Spinner ariaLabel={ariaLabel} size={SpinnerSize.small} />
                </Stack.Item>

                <Stack.Item styles={inProgressStateMessageContainerStyles}>{children}</Stack.Item>
            </Stack>
        </AriaLiveWrapper>
    );
};

export default InProgressStateMessage;
