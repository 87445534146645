import { Depths, FontSizes, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { getSemanticColor } from '../../../utilities/styles';

type ConnectViaAppDownloadCardProps = {
    contentFooter?: React.ReactNode;
    paddingBottom?: number;
    subtitle: React.ReactNode;
};

/**
 * Styles
 */

const rootStylesFactory = (paddingBottom?: number) =>
    makeStyles((theme) => ({
        root: {
            backgroundColor: getSemanticColor(theme, 'openWindowsDesktopClientOptionCardBackground'),
            borderRadius: '4px',
            boxShadow: Depths.depth16,

            padding: paddingBottom !== undefined ? `32px 32px ${paddingBottom}px 32px` : '32px',
        },
    }));

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        marginBottom: '10px',
    },
});

const useTextContainerStyles = makeStyles({
    root: {
        // setting a min width value for this element allows it to shrink
        minWidth: 0,
    },
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        width: '100%',
    },
});

/**
 * END Styles
 */

const contentTokens: IStackTokens = {
    childrenGap: 20,
};

const rootTokens: IStackTokens = {
    childrenGap: 24,
};

const textTokens: IStackTokens = {
    childrenGap: 2,
};

export const ConnectViaAppDownloadCard: React.FC<ConnectViaAppDownloadCardProps> = (
    props: ConnectViaAppDownloadCardProps
) => {
    const { contentFooter, paddingBottom, subtitle } = props;

    // Style hooks
    const useRootStyles = useDynamicMakeStyles(rootStylesFactory, paddingBottom);
    const rootStyles = useRootStyles();
    const subtitleStyles = useSubtitleStyles();
    const textContainerStyles = useTextContainerStyles();
    const textStyles = useTextStyles();

    return (
        <Stack styles={rootStyles} tokens={rootTokens}>
            <Stack.Item>
                <Stack horizontal tokens={contentTokens} verticalAlign="start">
                    <Stack.Item grow={1} styles={textContainerStyles}>
                        <Stack styles={textStyles} tokens={textTokens}>
                            <Stack.Item styles={subtitleStyles}>{subtitle}</Stack.Item>
                            {contentFooter && <Stack.Item>{contentFooter}</Stack.Item>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default ConnectViaAppDownloadCard;
