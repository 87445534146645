import React from 'react';
import {
    EnvironmentDefinitionParameterValue,
    EnvironmentDefinitionParameterViewModel,
    isEnvironmentDefinitionArrayParameterViewModel,
    isEnvironmentDefinitionBooleanParameterViewModel,
    isEnvironmentDefinitionNumberParameterViewModel,
    isEnvironmentDefinitionObjectParameterViewModel,
    isEnvironmentDefinitionStringParameterViewModel,
} from './models';
import BooleanParameterControl from './parameter-type-control/boolean-parameter-control';
import { ParameterControlProps } from './parameter-type-control/models';
import NumberParameterControl from './parameter-type-control/number-parameter-control';
import StringParameterControl from './parameter-type-control/string-parameter-control';

interface EnvironmentParameterControlProps extends ParameterControlProps<EnvironmentDefinitionParameterValue> {
    parameter: EnvironmentDefinitionParameterViewModel;
    onChange: (parameterId: string, value: EnvironmentDefinitionParameterValue | undefined) => void;
}

export const EnvironmentParameterControl: React.FC<EnvironmentParameterControlProps> = (props) => {
    const { parameter, onChange, value } = props;
    const { id } = parameter;

    const onChangeCallback = React.useCallback(
        (value: EnvironmentDefinitionParameterValue | undefined) => {
            onChange(id, value);
        },
        [onChange, id]
    );

    if (
        isEnvironmentDefinitionStringParameterViewModel(parameter) ||
        isEnvironmentDefinitionArrayParameterViewModel(parameter) ||
        isEnvironmentDefinitionObjectParameterViewModel(parameter)
    ) {
        return (
            <StringParameterControl
                {...props}
                parameter={parameter}
                value={value as string | undefined}
                onChange={onChangeCallback}
            />
        );
    }

    if (isEnvironmentDefinitionBooleanParameterViewModel(parameter)) {
        return (
            <BooleanParameterControl
                {...props}
                value={value as boolean | undefined}
                onChange={onChangeCallback}
                parameter={parameter}
            />
        );
    }

    if (isEnvironmentDefinitionNumberParameterViewModel(parameter)) {
        return (
            <NumberParameterControl
                {...props}
                value={value as number | undefined}
                onChange={onChangeCallback}
                parameter={parameter}
            />
        );
    }

    return <></>;
};

export default EnvironmentParameterControl;
