import { InvariantLocale } from '../constants/localization';

// TODO: Add areStringsEquivalentCaseInsensitive helper function (#1693942)
export const areStringsEquivalent = (
    a: string | undefined,
    b: string | undefined,
    caseInsensitive = false,
    locale?: string
): boolean => compareStrings(a, b, caseInsensitive, locale) === 0;

export const compareStrings = (
    a: string | undefined,
    b: string | undefined,
    caseInsensitive = false,
    locale?: string
): number => {
    // Undefined, blank, and white space are all treated as equivalent
    if (isUndefinedOrWhiteSpace(a) && isUndefinedOrWhiteSpace(b)) {
        return 0;
    } else if (isUndefinedOrWhiteSpace(a)) {
        return -1;
    } else if (isUndefinedOrWhiteSpace(b)) {
        return 1;
    }

    const compareOptions: Intl.CollatorOptions = caseInsensitive ? { sensitivity: 'base' } : {};

    return a.localeCompare(b, locale ?? InvariantLocale, compareOptions);
};

export const concat = (...strings: string[]): string => strings.join('');
export const isNotUndefinedOrEmpty = (s: string | undefined): s is string => !isUndefinedOrEmpty(s);
export const isNotUndefinedOrWhiteSpace = (s: string | undefined): s is string => !isUndefinedOrWhiteSpace(s);
export const isUndefinedOrEmpty = (s: string | undefined): s is undefined | '' => s === undefined || s.length < 1;
export const isUndefinedOrWhiteSpace = (s: string | undefined): s is undefined | '' =>
    s === undefined || s.trim().length < 1;

export const removeSpaces = (s: string): string => concat(...s.split(' '));

export const isSubstringOfTextCaseInsensitive = (value: string, searchText: string): boolean =>
    value.trim().toLowerCase().indexOf(searchText.trim().toLowerCase()) > -1;

export const doesStringEndWith = (value: string, endsWith: string, caseInsensitive = false): boolean => {
    if (caseInsensitive === true) {
        value = value.toLowerCase();
        endsWith = endsWith.toLowerCase();
    }

    return value.trim().endsWith(endsWith.trim());
};

export const isString = (s: unknown): s is string => typeof s === 'string';

export const caseInsensitiveLocaleAwareIncludes = (
    a: string | undefined,
    b: string | undefined,
    locale?: string
): boolean => {
    if (isUndefinedOrWhiteSpace(a) || isUndefinedOrWhiteSpace(b)) {
        return false;
    }

    return a.toLocaleLowerCase(locale).includes(b.toLocaleLowerCase(locale));
};
