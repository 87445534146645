import { ListEnvironmentDefinitionsResponse } from '../../../data/services/data-plane-api/environment-definition';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    EnvironmentDefinitionActionType,
    ListEnvironmentDefinitionsSuccessPayload,
} from './environment-definition-actions';

export const discoverEnvironmentDefinitionsForEnvironments = createActionCreatorWithoutPayload<AggregatedResult>(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForEnvironments,
    { async: true }
);

export const discoverEnvironmentDefinitionsForEnvironmentsError = createActionCreator<ErrorPayload>(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForEnvironmentsError
);

export const discoverEnvironmentDefinitionsForEnvironmentsFailed = createActionCreator<FailedPayload>(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForEnvironmentsFailed
);

export const discoverEnvironmentDefinitionsForEnvironmentsSuccess = createActionCreatorWithoutPayload(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForEnvironmentsSuccess
);

export const discoverEnvironmentDefinitionsForProjects = createActionCreatorWithoutPayload<AggregatedResult>(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForProjects,
    { async: true }
);

export const discoverEnvironmentDefinitionsForProjectsError = createActionCreator<ErrorPayload>(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForProjectsError
);

export const discoverEnvironmentDefinitionsForProjectsFailed = createActionCreator<FailedPayload>(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForProjectsFailed
);

export const discoverEnvironmentDefinitionsForProjectsSuccess = createActionCreatorWithoutPayload(
    EnvironmentDefinitionActionType.DiscoverEnvironmentDefinitionsForProjectsSuccess
);

export const listEnvironmentDefinitions = createActionCreator<IndexedPayload, ListEnvironmentDefinitionsResponse>(
    EnvironmentDefinitionActionType.ListEnvironmentDefinitions,
    { async: true, startsActivity: true }
);

export const listEnvironmentDefinitionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentDefinitionActionType.ListEnvironmentDefinitionsError
);

export const listEnvironmentDefinitionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentDefinitionActionType.ListEnvironmentDefinitionsFailed
);

export const listEnvironmentDefinitionsSuccess = createActionCreator<ListEnvironmentDefinitionsSuccessPayload>(
    EnvironmentDefinitionActionType.ListEnvironmentDefinitionsSuccess
);
