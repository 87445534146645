import * as React from 'react';
import { Dropdown, DropdownProps, DropdownValueOption } from '../dropdown';

type StringDropdownOption = Omit<DropdownValueOption<string>, 'data'>;

export interface StringDropdownProps extends Omit<DropdownProps<string>, 'options' | 'selectedKey'> {
    options: string[];
    value: string | undefined;
}

export const StringDropdown: React.FC<StringDropdownProps> = (props) => {
    const { options: values, value } = props;

    const options: StringDropdownOption[] = React.useMemo(
        () => values.map((value) => ({ value, text: value, key: value })),
        [values]
    );

    return <Dropdown<string> {...props} selectedKey={value} options={options} />;
};
