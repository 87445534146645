import { ThemeMode } from '../../themes/themes';
import { UnionMap } from '../../types/union-map';
import { RemoteAppOption, UserSettings } from '../../types/user-settings';
import { createInitializer } from '../../utilities/initializer';

export type UserSettingsFormFieldData = {
    userSettingsValue: string;
};

export const UserSettingsFormFieldData = createInitializer<UserSettingsFormFieldData>({
    userSettingsValue: '',
});

export const UserSettingsFormFieldName: UnionMap<Uncapitalize<keyof UserSettings>> = {
    themeMode: 'themeMode',
    useMultiMonitor: 'useMultiMonitor',
    preferredRemoteApp: 'preferredRemoteApp',
};

export type UserSettingsFormData = {
    themeMode: ThemeMode;
    useMultiMonitor: boolean;
    preferredRemoteApp?: RemoteAppOption;
};

export const UserSettingsFormData = createInitializer<UserSettingsFormData>({
    themeMode: ThemeMode.DarkMode,
    useMultiMonitor: false,
    preferredRemoteApp: RemoteAppOption.useWindowsApp,
});
