import * as React from 'react';
import { BooleanString } from '../../../../constants/boolean';
import { TreeItemEvent } from '../../../fluent-web-components/components/tree/models';
import TreeItem, { TreeItemRefTarget } from '../../../fluent-web-components/components/tree/tree-item';
import { TreeView } from '../../../fluent-web-components/components/tree/tree-view';
import { arrangePathsIntoTree } from './selectors';

export interface PathTreeNode {
    path: string;
    name: string;
    items: PathTreeNode[];
    isFullFilePath: boolean;
}

export interface PathTreeItemProps {
    node: PathTreeNode;
}

export interface PathTreeProps {
    paths: string[];
    onChange?: (value: string) => void;
}

const getPathFromTreeItemEvent = (event: TreeItemEvent): string | undefined => {
    const { target } = event;

    if (!target) {
        return undefined;
    }

    const { attributes } = target;
    const { ['data-key']: dataKey } = attributes;

    return dataKey?.value;
};

const shouldStopSelectionPropagation = (event: Event): boolean => {
    const { target } = event as TreeItemEvent;

    if (!target) {
        return false;
    }

    const { attributes, selected } = target;

    const isSelectable = attributes['data-isSelectable']?.value === BooleanString.true;

    // Stop event propagation when unselectable tree item is clicked,
    // or when selected tree item is clicked (prevent un-selecting)
    return !isSelectable || selected;
};

const handleClick = (event: Event) => {
    if (shouldStopSelectionPropagation(event)) {
        event.stopImmediatePropagation();
    }
};

const handleEnter = (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
        return;
    }

    if (shouldStopSelectionPropagation(event)) {
        event.stopImmediatePropagation();
    }
};

const PathTreeItem: React.FC<PathTreeItemProps> = (props: PathTreeItemProps) => {
    const { node } = props;
    const { name, items, path, isFullFilePath } = node;

    const [isExpanded, setIsExpanded] = React.useState(false);

    // Only allow users to select nodes that represent a full file path
    // (Converting to string because data attributes are stored as strings)
    const isSelectable = String(isFullFilePath);

    const treeItemRef = React.useRef<TreeItemRefTarget>(null);
    React.useEffect(() => {
        // Prevent user from selecting an unselectable item, or un-selecting a selected item
        treeItemRef?.current?.addEventListener('click', handleClick);
        treeItemRef?.current?.addEventListener('keydown', handleEnter);
    }, [treeItemRef?.current]);

    // Callback to track expanded changes triggered by TreeItem click
    // (we use this to conditionally render sub-items only when an item is expanded)
    const handleExpandedChange = React.useCallback(
        (event: TreeItemEvent) => {
            const { target } = event;

            if (!target) {
                return;
            }

            const { expanded } = target;

            const eventPath = getPathFromTreeItemEvent(event);

            // These events propagate, so we need to make sure the event originated at this node
            if (eventPath === path) {
                setIsExpanded(expanded);
            }
        },
        [path]
    );

    const hasItems = items.length > 0;

    // Rendering all sub-trees at once is very render-heavy, and unnecessary when the tree is fully collapsed,
    // so we'll mock the sub items with an empty tree item unless expanded
    const displayItems = React.useMemo(
        () =>
            isExpanded && hasItems ? items.map((item) => <PathTreeItem node={item} key={item.path} />) : <TreeItem />,
        [isExpanded, items]
    );

    return (
        <TreeItem
            innerRef={treeItemRef}
            data-key={path}
            data-isSelectable={isSelectable}
            handleExpandedChange={handleExpandedChange}
        >
            {name}
            {hasItems && displayItems}
        </TreeItem>
    );
};

export const PathTree: React.FC<PathTreeProps> = (props: PathTreeProps) => {
    const { paths, onChange } = props;

    const treeItems = React.useMemo(() => arrangePathsIntoTree(paths), [paths]);

    const handleSelectedChange = React.useCallback(
        (e: TreeItemEvent) => {
            if (!onChange) {
                return;
            }

            const { target } = e;

            if (!target) {
                return;
            }

            const { selected, attributes } = target;
            const { 'data-key': dataKey } = attributes;
            const { value: key } = dataKey;

            // The tree-view onChange handler fires on both select and unselect (both happen at once when a new item is selected).
            // We only want to hook in on select.
            if (selected) {
                onChange(key);
            }
        },
        [onChange]
    );

    return (
        <>
            <TreeView handleSelectedChange={handleSelectedChange}>
                {treeItems.map((item) => (
                    <PathTreeItem node={item} key={item.path} />
                ))}
            </TreeView>
        </>
    );
};
