import { createSelector } from '@reduxjs/toolkit';
import { Status } from '../../../models/common';
import { projectAdapter } from '../../adapters/project-adapters';
import { StoreState } from '../../store/store-state';
import {
    StoreStateSelector,
    createIdArrayFromEntitySelector,
    createMapFromEntitiesSelector,
    isStatusTerminal,
} from '../common';
import { ResourceUserState } from '../display/user-state/models';

/**
 * Basic selectors
 */

export const getStatusForInitializeSingleDevCenterHome: StoreStateSelector<Status> = (state) =>
    state.subApplicationsStore.singleDevCenter.status.initializeSingleDevCenterHome;

export const getSingleDevCenterUri: StoreStateSelector<string | undefined> = (state) =>
    state.subApplicationsStore.singleDevCenter.data.devCenterUri;

export const getStatusForLoadProjectsForSingleDevCenter: StoreStateSelector<Status> = (state) =>
    state.subApplicationsStore.singleDevCenter.status.loadProjectsForSingleDevCenter;

export const getStatusForLoadResourcesForSingleDevCenter: StoreStateSelector<Status> = (state) =>
    state.subApplicationsStore.singleDevCenter.status.loadResourcesForSingleDevCenter;

/**
 * Entity state selectors
 */

const projectSelectors = projectAdapter.getSelectors<StoreState>(
    (store) => store.subApplicationsStore.singleDevCenter.data.projects
);

/**
 * Composed selectors
 */

export const getProjectsInSingleDevCenter = createMapFromEntitiesSelector(projectSelectors.selectAll);

export const getProjectIdsInSingleDevCenter = createIdArrayFromEntitySelector(projectSelectors.selectAll);

export const getCountOfProjectsInSingleDevCenter = projectSelectors.selectTotal;

export const getDevBoxUserStateForSingleDevCenterMode: StoreStateSelector<ResourceUserState> = createSelector(
    [getStatusForLoadProjectsForSingleDevCenter, getStatusForLoadResourcesForSingleDevCenter],
    (statusForLoadProjects, statusForLoadResources) => {
        // If we're in a guest account, we don't start loading projects, so need to also check load resources status
        if (!isStatusTerminal(statusForLoadProjects) && !isStatusTerminal(statusForLoadResources)) {
            return ResourceUserState.Unknown;
        }

        // We will always consider users in single devcenter mode dev box users
        // - single devcenter mode only supports dev boxes, so that is the only possible intention
        return ResourceUserState.IsUser;
    }
);
