import { ClientError } from '../../models/common';
import { UnionMap } from '../../types/union-map';
import { areStringsEquivalent, isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../string';
import { tryOrDefault } from '../try-or-default';

/**
 * # parseResourceId
 *
 * This file defines a utility function that helps parse tokens out of common Azure resource ID formats.
 *
 * It's based heavily on the ArmId.parse function, found here:
 *      https://dev.azure.com/msazure/One/_git/AzureUX-PortalFx?path=/src/SDK/Framework.ReactView/azureportal-reactview/src/ResourceManagement.ts&_a=contents&version=GBdev
 *
 * We've defined our own implementation of it here mostly so that we don't have to take a dependency on the Azure Portal
 * UX npm package for this single function. For a bit of simplicity, we also don't cover some of the cases supported by
 * Azure resource IDs, e.g. deployments, tags, as we're unlikely to run into those in our codebase.
 *
 * In essence, the parseResourceId function implements the following context-free grammar:
 *
 *      Valid resource ID formats:
 *          {provider-with-namespace}
 *          {provider-with-namespace-type-and-name}
 *          {provider-with-namespace-type-and-name}{provider-with-namespace}
 *          {provider-with-namespace-type-and-name}{type-and-name}
 *          {provider-with-namespace-type-and-name}{provider-with-namespace-type-and-name}
 *          {subscription}
 *          {subscription}{provider-with-namespace}
 *          {subscription}{provider-with-namespace-type-and-name}
 *          {subscription}{provider-with-namespace-type-and-name}{provider-with-namespace}
 *          {subscription}{provider-with-namespace-type-and-name}{type-and-name}
 *          {subscription}{provider-with-namespace-type-and-name}{provider-with-namespace-type-and-name}
 *          {subscription}{resource-group}
 *          {subscription}{resource-group}{provider-with-namespace}
 *          {subscription}{resource-group}{provider-with-namespace-type-and-name}
 *          {subscription}{resource-group}{provider-with-namespace-type-and-name}{provider-with-namespace}
 *          {subscription}{resource-group}{provider-with-namespace-type-and-name}{type-and-name}
 *          {subscription}{resource-group}{provider-with-namespace-type-and-name}{provider-with-namespace-type-and-name}
 *
 *      provider-with-namespace:
 *          /providers/[provider-namespace:string]
 *
 *      provider-with-namespace-type-and-name:
 *          /providers/[provider-namespace:string]/[type:string]/[name:string]
 *
 *      resource-group:
 *          /resourceGroups/[resource-group-name:string]
 *
 *      subscription:
 *          /subscriptions/[subscription-id:string]
 *
 *      type-and-name:
 *          /[type:string]/[name:string]
 *
 * Feel free to add to this grammar as needed! As mentioned earlier, this doesn't cover ALL variants of resource IDs,
 * and we may need to add more scenarios in future.
 */

/**
 * Types
 */

export interface ResourceComponents {
    name?: string;
    providerNamespace: string;
    type?: string;
}

export interface SubResourceComponents {
    name?: string;
    providerNamespace?: string;
    type?: string;
}

export interface ResourceIdComponents {
    resource?: ResourceComponents;
    resourceGroupName?: string;
    subResource?: SubResourceComponents;
    subscriptionId?: string;
}

interface ResourceIdTokens {
    resourceGroupName?: string;
    resourceName?: string;
    resourceProviderNamespace?: string;
    resourceType?: string;
    subResourceName?: string;
    subResourceProviderNamespace?: string;
    subResourceType?: string;
    subscriptionId?: string;
}

type ParserState =
    | 'Provider'
    | 'ProviderTypeAndName'
    | 'ResourceGroup'
    | 'Start'
    | 'SubProvider'
    | 'SubProviderTypeAndName'
    | 'Subscription'
    | 'SubTypeAndName';

const ParserState: UnionMap<ParserState> = {
    Provider: 'Provider',
    ProviderTypeAndName: 'ProviderTypeAndName',
    ResourceGroup: 'ResourceGroup',
    Start: 'Start',
    SubProvider: 'SubProvider',
    SubProviderTypeAndName: 'SubProviderTypeAndName',
    Subscription: 'Subscription',
    SubTypeAndName: 'SubTypeAndName',
};

type Termination = 'Allowed' | 'Always' | 'Never';

const Termination: UnionMap<Termination> = {
    Allowed: 'Allowed',
    Always: 'Always',
    Never: 'Never',
};

type StateChangeHandler = (
    token: string,
    value: string,
    data: ResourceIdTokens
) => [ResourceIdTokens, ParserState | undefined];

interface StateChangeConfiguration {
    canTerminate: Termination;
    transitions?: {
        [token: string]: StateChangeHandler;
    };
}

type StateChangeMap<TStates extends ParserState> = { [state in TStates]: StateChangeConfiguration };

/**
 * Constants
 */

// Note: tokens should be lower cased, as resource IDs are case-insensitive and normalized to lower case
const Tokens = {
    Any: '*',
    Providers: 'providers',
    ResourceGroups: 'resourcegroups',
    Subscriptions: 'subscriptions',
};

/**
 * State change handlers
 */

const commonHandlerFactory =
    (valueKey: keyof ResourceIdTokens, nextState: ParserState): StateChangeHandler =>
    (_token: string, value: string, data: ResourceIdTokens) =>
        [{ ...data, [valueKey]: value }, nextState];

const typeAndNameHandlerFactory =
    (tokenKey: keyof ResourceIdTokens, valueKey: keyof ResourceIdTokens, nextState: ParserState): StateChangeHandler =>
    (token: string, value: string, data: ResourceIdTokens) => {
        // Token name shouldn't overlap with any existing tokens
        if (
            areStringsEquivalent(token, Tokens.Providers, true) ||
            areStringsEquivalent(token, Tokens.ResourceGroups, true) ||
            areStringsEquivalent(token, Tokens.Subscriptions, true)
        ) {
            return [data, undefined];
        }

        return [{ ...data, [tokenKey]: token, [valueKey]: value }, nextState];
    };

const providerHandler = commonHandlerFactory('resourceProviderNamespace', ParserState.Provider);
const providerTypeAndNameHandler = typeAndNameHandlerFactory(
    'resourceType',
    'resourceName',
    ParserState.ProviderTypeAndName
);
const resourceGroupHandler = commonHandlerFactory('resourceGroupName', ParserState.ResourceGroup);
const subProviderHandler = commonHandlerFactory('subResourceProviderNamespace', ParserState.SubProvider);

const subProviderTypeAndNameHandler = typeAndNameHandlerFactory(
    'subResourceType',
    'subResourceName',
    ParserState.SubProviderTypeAndName
);

const subscriptionHandler = commonHandlerFactory('subscriptionId', ParserState.Subscription);

const subTypeAndNameHandler = typeAndNameHandlerFactory(
    'subResourceType',
    'subResourceName',
    ParserState.SubTypeAndName
);

/**
 * State machine configuration
 */

const stateChanges: StateChangeMap<ParserState> = {
    Start: {
        canTerminate: Termination.Never,
        transitions: {
            [Tokens.Providers]: providerHandler,
            [Tokens.Subscriptions]: subscriptionHandler,
        },
    },
    Provider: {
        canTerminate: Termination.Allowed,
        transitions: {
            [Tokens.Any]: providerTypeAndNameHandler,
        },
    },
    ProviderTypeAndName: {
        canTerminate: Termination.Allowed,
        transitions: {
            [Tokens.Providers]: subProviderHandler,
            [Tokens.Any]: subTypeAndNameHandler,
        },
    },
    ResourceGroup: {
        canTerminate: Termination.Allowed,
        transitions: {
            [Tokens.Providers]: providerHandler,
        },
    },
    SubProvider: {
        canTerminate: Termination.Allowed,
        transitions: {
            [Tokens.Any]: subProviderTypeAndNameHandler,
        },
    },
    SubProviderTypeAndName: {
        canTerminate: Termination.Always,
    },
    Subscription: {
        canTerminate: Termination.Allowed,
        transitions: {
            [Tokens.Providers]: providerHandler,
            [Tokens.ResourceGroups]: resourceGroupHandler,
        },
    },
    SubTypeAndName: {
        canTerminate: Termination.Always,
    },
};

/**
 * Helper functions
 */

const buildResourceComponents = (
    name: string | undefined,
    providerNamespace: string | undefined,
    type: string | undefined
): ResourceComponents | undefined => {
    if (isUndefinedOrWhiteSpace(name) && isUndefinedOrWhiteSpace(providerNamespace) && isUndefinedOrWhiteSpace(type)) {
        return undefined;
    }

    return {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        // Justification: this is a safe assertion, as lacking providerNamespace would make resourceId unparseable earlier
        providerNamespace: providerNamespace!,
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        ...(name ? { name } : {}),
        ...(type ? { type } : {}),
    };
};

const buildSubResourceComponents = (
    name: string | undefined,
    providerNamespace: string | undefined,
    type: string | undefined
): SubResourceComponents | undefined => {
    if (isUndefinedOrWhiteSpace(name) && isUndefinedOrWhiteSpace(providerNamespace) && isUndefinedOrWhiteSpace(type)) {
        return undefined;
    }

    return {
        ...(providerNamespace ? { providerNamespace } : {}),
        ...(name ? { name } : {}),
        ...(type ? { type } : {}),
    };
};

const buildResourceIdComponents = (tokens: ResourceIdTokens): ResourceIdComponents => {
    const {
        resourceGroupName,
        resourceName,
        resourceProviderNamespace,
        resourceType,
        subResourceName,
        subResourceProviderNamespace,
        subResourceType,
        subscriptionId,
    } = tokens;
    const resource = buildResourceComponents(resourceName, resourceProviderNamespace, resourceType);
    const subResource = buildSubResourceComponents(subResourceName, subResourceProviderNamespace, subResourceType);

    return {
        ...(resource ? { resource } : {}),
        ...(isNotUndefinedOrWhiteSpace(resourceGroupName) ? { resourceGroupName } : {}),
        ...(subResource ? { subResource } : {}),
        ...(isNotUndefinedOrWhiteSpace(subscriptionId) ? { subscriptionId } : {}),
    };
};

/**
 * parseResourceId
 */

export const parseResourceId = (resourceId: string): ResourceIdComponents => {
    // Perform validations that can quickly catch an invalid ID
    if (isUndefinedOrWhiteSpace(resourceId)) {
        throw new ClientError('Resource ID was undefined or white space');
    }

    if (resourceId[0] !== '/') {
        throw new ClientError('Resource ID expected to begin with leading /');
    }

    const parts = resourceId.substring(1).split('/');

    if (parts.length < 1 || parts.length % 2 !== 0) {
        throw new ClientError('Resource ID contains invalid number of segments');
    }

    // Begin parsing
    let currentState = ParserState.Start;
    let currentTokens: ResourceIdTokens = {};

    for (let i = 0; i < parts.length; i += 2) {
        // Throw if we've somehow reached a state we have no handlers for
        // (This is really an error in our own state machine, not the resource ID)
        const currentStateChanges = stateChanges[currentState];

        if (!currentStateChanges) {
            throw new ClientError('Parser reached unhandled state');
        }

        const { canTerminate, transitions } = currentStateChanges;

        // Throw if termination must occur in current state. (If we're still in this loop, that means there's more
        // input, meaning the resource ID is longer than we think)
        if (canTerminate === Termination.Always) {
            throw new ClientError('Resource ID contains more segments than expected');
        }

        const token = parts[i];
        const value = parts[i + 1];

        if (isUndefinedOrWhiteSpace(token) || isUndefinedOrWhiteSpace(value)) {
            throw new ClientError('Resource ID segment is undefined or white space');
        }

        const stateChangeHandler =
            transitions?.[token] || transitions?.[token.toLowerCase()] || transitions?.[Tokens.Any];

        // Throw if there's no handler for current token in current state
        if (!stateChangeHandler) {
            throw new ClientError('Resource ID contains an invalid or malformed segment');
        }

        const [tokens, nextState] = stateChangeHandler(token, value, currentTokens);

        // Throw immediately if we're transitioning to invalid state
        if (!nextState) {
            throw new ClientError('Resource ID contains an invalid or malformed segment');
        }

        currentState = nextState;
        currentTokens = tokens;
    }

    // At end of input, so input is invalid if current state isn't terminal
    if (stateChanges[currentState].canTerminate === Termination.Never) {
        throw new ClientError('Resource ID contains fewer segments than expected');
    }

    // Process the tokens we collected along the way during parsing
    return buildResourceIdComponents(currentTokens);
};

/**
 * parseResourceIds
 */

export const parseResourceIds = (resourceIds: string[]): ResourceIdComponents[] => resourceIds.map(parseResourceId);

/**
 * tryParseResourceId
 */

export const tryParseResourceId = tryOrDefault(parseResourceId);

/**
 * tryParseResourceIds
 */

export const tryParseResourceIds = tryOrDefault(parseResourceIds);
