import { createSelector } from 'reselect';
import { FeatureFlagName } from '../../constants/features';
import { StoreStateSelector } from '../../redux/selector/common';
import { ActionAbility } from '../../redux/selector/display/create-ability/models';
import {
    getDevBoxCreateAbilityState,
    getEnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/selectors';
import {
    getAreAllGraphDirectoryObjectsSucceeded,
    getNumberOfAllListDevBoxActionsRequests,
    getNumberOfAllListDevBoxesRequests,
    getNumberOfAllListEnvironmentDefinitionsRequests,
    getNumberOfAllListEnvironmentsRequests,
    getNumberOfAllListPoolsRequests,
    getNumberOfAllRemoteConnectionRequests,
    getNumberOfAllWarmDevCenterNameRecordRequests,
    getNumberOfSuccessfulGetProjectEnvironmentTypePermissionsRequests,
    getNumberOfSuccessfulGetProjectPermissionsRequests,
    getNumberOfSuccessfulGetScheduleRequests,
    getNumberOfSuccessfulListDevBoxActionRequests,
    getNumberOfSuccessfulListDevBoxesRequests,
    getNumberOfSuccessfulListEnvironmentDefinitionsRequests,
    getNumberOfSuccessfulListEnvironmentsRequests,
    getNumberOfSuccessfulListLocationsRequests,
    getNumberOfSuccessfulListPoolsRequests,
    getNumberOfSuccessfulListProjectEnvironmentTypesRequests,
    getNumberOfSuccessfulLoadControlPlaneResourcesRequests,
    getNumberOfSuccessfulRemoteConnectionRequests,
    getNumberOfSuccessfulWarmDevCenterNameRecordRequests,
} from '../../redux/selector/display/progress';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { ActionBarState } from './models';

const percentageString = (successful: number, total: number) =>
    `${successful} / ${total} (${total === 0 ? 0 : (successful / total) * 100.0})`;

/**
 * Application state selectors
 */

export const getActionBarState: StoreStateSelector<ActionBarState> = createSelector(
    [getDevBoxCreateAbilityState, getEnvironmentCreateAbilityState],
    (devBoxCreateAbilityState, environmentCreateAbilityState) => {
        // Until we determine createability, we're considered loading
        const { createAbility: devBoxCreateAbility } = devBoxCreateAbilityState;
        const { createAbility: environmentCreateAbility } = environmentCreateAbilityState;

        if (devBoxCreateAbility === ActionAbility.Unknown || environmentCreateAbility === ActionAbility.Unknown) {
            return ActionBarState.Loading;
        }

        if (
            devBoxCreateAbility === ActionAbility.CanPerformAction &&
            environmentCreateAbility === ActionAbility.CanPerformAction
        ) {
            return ActionBarState.CanCreateDevBoxesAndEnvironments;
        }

        if (devBoxCreateAbility === ActionAbility.CanPerformAction) {
            return ActionBarState.CanCreateOnlyDevBoxes;
        }

        if (environmentCreateAbility === ActionAbility.CanPerformAction) {
            return ActionBarState.CanCreateOnlyEnvironments;
        }

        return ActionBarState.CannotCreateDevBoxesOrEnvironments;
    }
);

export const getLoadingProgress: StoreStateSelector<number> = createSelector(
    [
        getActionBarState,

        /**
         * Successful requests
         */
        getAreAllGraphDirectoryObjectsSucceeded,
        getNumberOfSuccessfulGetProjectEnvironmentTypePermissionsRequests,
        getNumberOfSuccessfulGetProjectPermissionsRequests,
        getNumberOfSuccessfulGetScheduleRequests,
        getNumberOfSuccessfulListDevBoxActionRequests,
        getNumberOfSuccessfulListDevBoxesRequests,
        getNumberOfSuccessfulListEnvironmentDefinitionsRequests,
        getNumberOfSuccessfulListEnvironmentsRequests,
        getNumberOfSuccessfulListLocationsRequests,
        getNumberOfSuccessfulListPoolsRequests,
        getNumberOfSuccessfulListProjectEnvironmentTypesRequests,
        getNumberOfSuccessfulLoadControlPlaneResourcesRequests,
        getNumberOfSuccessfulRemoteConnectionRequests,
        getNumberOfSuccessfulWarmDevCenterNameRecordRequests,

        /**
         * Total requests
         */
        getNumberOfAllListDevBoxActionsRequests,
        getNumberOfAllListDevBoxesRequests,
        getNumberOfAllListEnvironmentDefinitionsRequests,
        getNumberOfAllListEnvironmentsRequests,
        getNumberOfAllListPoolsRequests,
        getNumberOfAllRemoteConnectionRequests,
        getNumberOfAllWarmDevCenterNameRecordRequests,
    ],
    (
        actionBarState,
        areAllGraphDirectoryObjectsSucceeded,
        successfulProjectEnvironmentTypePermissionsRequests,
        successfulProjectPermissionsRequests,
        successfulGetScheduleRequests,
        successfulListDevBoxActionsRequests,
        successfulListDevBoxesRequests,
        successfulListEnvironmentDefinitionsRequests,
        successfulListEnvironmentsRequests,
        successfulListLocationsRequests,
        successfulListPoolsRequests,
        successfulListProjectEnvironmentTypesRequests,
        successfulLoadControlPlaneResourcesRequests,
        successfulRemoteConnectionsRequests,
        successfulWarmDevCenterNameRecordRequests,
        totalListDevBoxActionsRequests,
        totalListDevBoxesRequests,
        totalListEnvironmentDefinitionsRequests,
        totalListEnvironmentsRequests,
        totalListPoolsRequests,
        totalRemoteConnectionsRequests,
        totalWarmDevCenterNameRecordRequests
    ) => {
        // If action bar is not in loading state, progress is irrelevant
        if (actionBarState === ActionBarState.NotStarted) {
            return 0;
        }

        if (actionBarState !== ActionBarState.Loading) {
            return 1;
        }

        const successfulGraphDirectoryObjectRequests = areAllGraphDirectoryObjectsSucceeded ? 1 : 0;

        // Calculate number of successful requests
        const successfulRequests =
            successfulGraphDirectoryObjectRequests +
            successfulProjectEnvironmentTypePermissionsRequests +
            successfulProjectPermissionsRequests +
            successfulGetScheduleRequests +
            successfulListDevBoxesRequests +
            successfulListEnvironmentDefinitionsRequests +
            successfulListEnvironmentsRequests +
            successfulListLocationsRequests +
            successfulListPoolsRequests +
            successfulListProjectEnvironmentTypesRequests +
            successfulLoadControlPlaneResourcesRequests +
            successfulRemoteConnectionsRequests +
            successfulListDevBoxActionsRequests +
            successfulWarmDevCenterNameRecordRequests;

        // Calculate total number of requests
        // Note: magic number 7 represent total # batched operations
        const totalRequests =
            totalListDevBoxesRequests +
            totalListEnvironmentDefinitionsRequests +
            totalListEnvironmentsRequests +
            totalListPoolsRequests +
            totalRemoteConnectionsRequests +
            totalListDevBoxActionsRequests +
            totalWarmDevCenterNameRecordRequests +
            7;

        // Log information about loading progress when feature flag is enabled
        const logVerbose = isFeatureFlagEnabled(FeatureFlagName.LogVerbose);

        if (logVerbose) {
            console.info(`getLoadingProgress: ${percentageString(successfulRequests, totalRequests)}`, {
                controlPlaneResources: percentageString(successfulLoadControlPlaneResourcesRequests, 1),
                devBoxActions: percentageString(successfulListDevBoxActionsRequests, totalListDevBoxActionsRequests),
                devBoxes: percentageString(successfulListDevBoxesRequests, totalListDevBoxesRequests),
                environmentDefinitions: percentageString(
                    successfulListEnvironmentDefinitionsRequests,
                    totalListEnvironmentDefinitionsRequests
                ),
                environments: percentageString(successfulListEnvironmentsRequests, totalListEnvironmentsRequests),
                graphDirectoryObjects: percentageString(successfulGraphDirectoryObjectRequests, 1),
                locations: percentageString(successfulListLocationsRequests, 1),
                pools: percentageString(successfulListPoolsRequests, totalListPoolsRequests),
                projectEnvironmentTypePermissions: percentageString(
                    successfulProjectEnvironmentTypePermissionsRequests,
                    1
                ),
                projectEnvironmentTypes: percentageString(successfulListProjectEnvironmentTypesRequests, 1),
                projectPermissions: percentageString(successfulProjectPermissionsRequests, 1),
                remoteConnections: percentageString(
                    successfulRemoteConnectionsRequests,
                    totalRemoteConnectionsRequests
                ),
                schedules: percentageString(successfulGetScheduleRequests, 1),
                warmDevCenterNameRecord: percentageString(
                    successfulWarmDevCenterNameRecordRequests,
                    totalWarmDevCenterNameRecordRequests
                ),
            });
        }

        return successfulRequests / totalRequests;
    }
);
