import { ApplicationModePathname } from './constants/application-mode';
import Settings from './settings/settings';

export const Index = ApplicationModePathname.AllDevCentersInTenant;
export const SingleDevCenter = ApplicationModePathname.SingleDevCenter;
export const SessionExpired = Settings.SessionExpiredRedirectPath;
export const SignIn = Settings.SignInRedirectPath;
export const SignOut = Settings.SignOutRedirectPath;

export const Routes = {
    Index,
    SessionExpired,
    SignIn,
    SignOut,
    SingleDevCenter,
};

export default Routes;
