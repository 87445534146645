import { TextField } from '@fluentui/react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDebounceErrorMessage } from '../../../hooks/use-debounce-error-message';

interface AddEnvironmentFormNameControlProps {
    environmentName: string;
    onChange: (environmentName: string) => void;
    errorMessage: string;
    isSubmitting: boolean;
}

const messages = defineMessages({
    nameTextFieldText: {
        id: 'AddEnvironmentFormNameControl_TextField_Text',
        defaultMessage: 'Name',
        description: 'Text for the name text field in the add environment panel label',
    },
    nameTextFieldAriaLabel: {
        id: 'AddEnvironmentFormNameControl_TextField_AriaLabel',
        defaultMessage: 'Name of your environment',
        description: 'Aria label for the name text field in the add environment panel',
    },
    nameTextFieldPlaceholder: {
        id: 'AddEnvironmentFormNameControl_TextField_Placeholder',
        defaultMessage: 'Enter the name of your environment',
        description: 'Placeholder for the name text field in the add environment panel',
    },
});

export const AddEnvironmentFormNameControl: React.FC<AddEnvironmentFormNameControlProps> = (
    props: AddEnvironmentFormNameControlProps
) => {
    const { environmentName, onChange, errorMessage, isSubmitting } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const environmentNameOnChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string | undefined) => {
            onChange(value ?? '');
        },
        [onChange]
    );

    const debouncedErrorMessage = useDebounceErrorMessage(errorMessage);

    return (
        <TextField
            label={formatMessage(messages.nameTextFieldText)}
            placeholder={formatMessage(messages.nameTextFieldPlaceholder)}
            ariaLabel={formatMessage(messages.nameTextFieldAriaLabel)}
            autoFocus
            value={environmentName}
            onChange={environmentNameOnChange}
            disabled={isSubmitting}
            errorMessage={debouncedErrorMessage}
            required
        />
    );
};

export default AddEnvironmentFormNameControl;
