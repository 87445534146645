import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { HibernatePreviewDialogProps } from './hibernate-preview-dialog';

export type HibernatePreviewDialogProperties = Omit<HibernatePreviewDialogProps, 'isOpen' | 'onDismiss'>;

export const HibernatePreviewDialogContext = React.createContext<
    SurfaceContextWithProperties<HibernatePreviewDialogProperties>
>({ isOpen: false });

export const HibernatePreviewDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<HibernatePreviewDialogProperties>(HibernatePreviewDialogContext);
