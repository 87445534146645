import { QuickActionName } from '../../../constants/app';
import { InitializationStatus, Status } from '../../../models/common';
import { SerializableSet } from '../../../types/serializable-set';
import { createInitializer } from '../../../utilities/initializer';
import { InitializationStatusStore } from '../initialization-status-store';
import { StatusStore } from '../status-store';

type StatusName =
    | 'loadAddDevBoxFormContent'
    | 'loadAddEnvironmentFormContent'
    | 'loadBackgroundResources'
    | 'loadControlPlaneResources'
    | 'loadDevBoxCardContent'
    | 'loadEnvironmentCardContent'
    | 'loadResources';

export interface HomeSubApplicationDataStore {
    dismissedQuickActions: SerializableSet<QuickActionName>;
}

export const HomeSubApplicationDataStore = createInitializer<HomeSubApplicationDataStore>({
    dismissedQuickActions: SerializableSet<QuickActionName>(),
});

export type HomeSubApplicationStatusStore = InitializationStatusStore & StatusStore<StatusName>;

export const HomeSubApplicationStatusStore = createInitializer<HomeSubApplicationStatusStore>({
    initialization: InitializationStatus(),
    loadAddDevBoxFormContent: Status(),
    loadAddEnvironmentFormContent: Status(),
    loadBackgroundResources: Status(),
    loadControlPlaneResources: Status(),
    loadDevBoxCardContent: Status(),
    loadEnvironmentCardContent: Status(),
    loadResources: Status(),
});

export interface HomeSubApplicationStore {
    data: HomeSubApplicationDataStore;
    status: HomeSubApplicationStatusStore;
}

export const HomeSubApplicationStore = createInitializer<HomeSubApplicationStore>({
    data: HomeSubApplicationDataStore(),
    status: HomeSubApplicationStatusStore(),
});

export interface SubApplicationsStore {
    home: HomeSubApplicationStore;
}

export const SubApplicationsStore = createInitializer<SubApplicationsStore>({
    home: HomeSubApplicationStore(),
});
