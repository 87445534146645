import { UnionMap } from '../../types/union-map';

export interface AzureDevOpsRepoContract {
    name: string;
    url: string;
    defaultBranch?: string;
}

export interface AzureDevOpsListResponseContract<TData extends Array<unknown>> {
    count: number;
    value: TData;
}

export interface AzureDevOpsBranchContract {
    name: string;
    objectId: string;
}

export interface AzureDevOpsCommitContract {
    treeId: string;
}

export type AzureDevOpsGitObjectTypeContract = 'blob' | 'tree';
export const AzureDevOpsGitObjectTypeContract: UnionMap<AzureDevOpsGitObjectTypeContract> = {
    blob: 'blob',
    tree: 'tree',
};

export interface AzureDevOpsTreeEntryContract {
    relativePath: string;
    gitObjectType: AzureDevOpsGitObjectTypeContract;
}

export interface AzureDevOpsTreeContract {
    treeEntries: AzureDevOpsTreeEntryContract[];
}
