import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { StorageErrorCode } from '../../../constants/storage';
import { getItem } from '../../../data/services/storage';
import { ClientError, FailureOperation, FailureResponse } from '../../../models/common';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    getStorageValue,
    getStorageValueError,
    getStorageValueFailed,
    getStorageValueSuccess,
} from '../../actions/storage/storage-action-creators';
import { GetStorageValueAction } from '../../actions/storage/storage-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getIsSignedIn, getUsername } from '../../selector/identity-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* getStorageValueSaga(action: GetStorageValueAction): SagaIterator {
    const { getForSignedInUser, key, storageType } = getPayload(action);

    try {
        // Fail the operation if it's being attempted for a signed-in user but no user is signed in
        const isSignedIn = yield select(getIsSignedIn);

        if (getForSignedInUser && !isSignedIn) {
            const failure = FailureResponse({
                code: StorageErrorCode.NotSignedIn,
                operation: FailureOperation.GetStorageValue,
            });

            yield put(getStorageValueFailed({ failure, getForSignedInUser, key, storageType }));
            yield resolveAction(action, failure);
            return;
        }

        // Fail the operation if it's being attempted for a signed-in user but we can't find a username
        const username = yield select(getUsername);

        if (getForSignedInUser && isUndefinedOrWhiteSpace(username)) {
            const failure = FailureResponse({
                code: StorageErrorCode.InvalidUsername,
                operation: FailureOperation.GetStorageValue,
            });

            yield put(getStorageValueFailed({ failure, getForSignedInUser, key, storageType }));
            yield resolveAction(action, failure);

            return;
        }

        const fullKey = getForSignedInUser ? `${username}_${key}` : key;
        const result: string = yield call(getItem, storageType, fullKey);
        yield put(getStorageValueSuccess({ getForSignedInUser, key, result, storageType }));
        yield resolveAction(action, { data: result, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetStorageValue);
        yield put(getStorageValueError({ error, getForSignedInUser, key, storageType }));
        yield rejectAction(action, error);
    }
}

export function* getStorageValueListenerSaga(): SagaIterator {
    yield takeEvery(getStorageValue, getStorageValueSaga);
}
