import { Method } from '../../../constants/http';
import { DataResponse, FailureOperation } from '../../../models/common';
import Settings from '../../../settings/settings';
import { RDGatewayHealthContract } from '../../contracts/rd-gateway';
import { fetchAndHandleResponse } from '../fetch-request';

export type GetRDGatewayHealthResponse = DataResponse<RDGatewayHealthContract>;

export const getRdGatewayHealth = async (): Promise<GetRDGatewayHealthResponse> => {
    const url = `${Settings.RDGatewayBaseUrl}/api/health`;

    return await fetchAndHandleResponse(url, Method.GET, FailureOperation.GetRDGatewayHealth, {
        removeCorrelationHeaders: true,
    });
};
