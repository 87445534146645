import { createDevBoxDataPlaneUri } from '../../ids/dev-box';
import { getTokensFromDevBoxOperationDataPlaneUri } from '../../ids/dev-box-operation';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxOperationAdapter } from '../adapters/dev-box-operation-adapters';
import { StoreState } from '../store/store-state';
import { createGroupedMapFromEntitiesSelector, createMapFromEntitiesSelector } from './common';

/**
 * Entity state selectors
 */

const devBoxOperationsSelectors = devBoxOperationAdapter.getSelectors<StoreState>(
    (store) => store.devBoxOperationsStore.data.operations
);

const statusesForListDevBoxOperationsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxOperationsStore.status.listDevBoxOperations
);

/**
 * Composed selectors
 */

export const getDevBoxOperations = createMapFromEntitiesSelector(devBoxOperationsSelectors.selectAll);

export const getDevBoxOperationsByDevBox = createGroupedMapFromEntitiesSelector(
    devBoxOperationsSelectors.selectAll,
    (entity) => {
        const { id } = entity;
        const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxOperationDataPlaneUri(id);

        return createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user });
    }
);

export const getStatusesForListDevBoxOperations = createMapFromEntitiesSelector(
    statusesForListDevBoxOperationsSelectors.selectAll
);
