import { createReducer } from '@reduxjs/toolkit';
import { IdentityErrorBasedExpiryCodes, IdentityErrorCode } from '../../constants/identity';
import {
    getAccessTokenError,
    getAccessTokenFailed,
    setActiveAccount,
    signIn,
    signInError,
    signInFailed,
    signInRedirecting,
    signInSuccess,
    signOut,
    signOutError,
    signOutRedirecting,
    signOutSuccess,
    tryExpireSignInComplete,
} from '../actions/identity/identity-action-creators';
import { AuthenticationState } from '../store/identity-state';
import { IdentityStore } from '../store/identity-store';
import { getPayload } from '../utilities/payload-action';

export const identityReducer = createReducer(IdentityStore(), (builder) => {
    builder
        .addCase(getAccessTokenError, (store, action) => {
            const { error } = getPayload(action);
            const { code } = error;

            store.authenticationFailure = error;

            if (IdentityErrorBasedExpiryCodes.includes(code as IdentityErrorCode)) {
                store.authenticationState = AuthenticationState.IdentityErrorBasedExpiry;
            }
        })
        .addCase(getAccessTokenFailed, (store, action) => {
            const { failure } = getPayload(action);
            const { code } = failure;

            store.authenticationFailure = failure;

            if (IdentityErrorBasedExpiryCodes.includes(code as IdentityErrorCode)) {
                store.authenticationState = AuthenticationState.IdentityErrorBasedExpiry;
            }
        })
        .addCase(setActiveAccount, (store, action) => {
            const { account } = getPayload(action);
            store.activeAccount = account;
        })
        .addCase(signIn, (store) => {
            store.authenticationState = AuthenticationState.SigningIn;
        })
        .addCase(signInError, (store, action) => {
            const { error } = getPayload(action);
            store.authenticationFailure = error;
            store.authenticationState = AuthenticationState.SignInError;
        })
        .addCase(signInFailed, (store) => {
            store.authenticationState = AuthenticationState.SignInFailed;
        })
        .addCase(signInRedirecting, (store) => {
            store.authenticationState = AuthenticationState.RedirectingForSignIn;
        })
        .addCase(signInSuccess, (store) => {
            store.authenticationState = AuthenticationState.SignedIn;
        })
        .addCase(signOut, (store) => {
            store.authenticationState = AuthenticationState.SigningOut;
        })
        .addCase(signOutError, (store, action) => {
            const { error } = getPayload(action);
            store.authenticationFailure = error;
            store.authenticationState = AuthenticationState.SignOutError;
        })
        .addCase(signOutRedirecting, (store) => {
            store.authenticationState = AuthenticationState.RedirectingForSignOut;
        })
        .addCase(signOutSuccess, (store) => {
            store.authenticationState = AuthenticationState.SignedOut;
        })
        .addCase(tryExpireSignInComplete, (store) => {
            store.authenticationState = AuthenticationState.Expiring;
        });
});
