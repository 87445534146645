import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { StorageType } from '../../../constants/storage';
import { getPreferredStorageTypeForBrowser } from '../../../data/services/storage';
import { ClientError, FailureOperation } from '../../../models/common';
import {
    initializeStorage,
    initializeStorageError,
    initializeStorageSuccess,
} from '../../actions/storage/storage-action-creators';
import { InitializeStorageAction } from '../../actions/storage/storage-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

export function* initializeStorageSaga(action: InitializeStorageAction): SagaIterator {
    try {
        // Decide which storage kind to use based on browser type + is In Private
        const storageType: StorageType = yield call(getPreferredStorageTypeForBrowser);

        yield put(initializeStorageSuccess({ result: storageType }));
        yield resolveAction(action, { data: storageType, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeStorage);
        yield put(initializeStorageError({ error }));
        yield rejectAction(action, error);
    }
}

export function* initializeStorageListenerSaga(): SagaIterator {
    yield takeLeading(initializeStorage, initializeStorageSaga);
}
