import { StorageType } from '../../constants/storage';
import { StoreStateSelector, createIsInitializedSelector } from './common';

/**
 * Basic selectors
 */

export const getStorageType: StoreStateSelector<StorageType> = (store) => store.storageStore.data.storageType;

/**
 * Composed selectors
 */

export const getIsStorageInitialized = createIsInitializedSelector((store) => store.storageStore.status.initialization);
