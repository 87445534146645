import {
    GetCustomizationGroupResponse,
    GetCustomizationTaskLogResponse,
    ListCustomizationGroupsResponse,
    ListCustomizationTaskDefinitionsResponse,
} from '../../../data/services/data-plane-api/customization';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    CreateCustomizationGroupAcceptedPayload,
    CreateCustomizationGroupPayload,
    CreateCustomizationGroupSuccessPayload,
    CustomizationActionType,
    GetCustomizationTaskLogSuccessPayload,
    ListCustomizationGroupsSuccessPayload,
    ListCustomizationTaskDefinitionsSuccessPayload,
    PollNonTerminalCustomizationGroupCompletePayload,
    PollNonTerminalCustomizationGroupPayload,
    TrackCustomizationGroupProgressPayload,
    ValidateCustomizationTasksPayload,
    ValidateCustomizationTasksSuccessPayload,
} from './customization-actions';

export const createCustomizationGroup = createActionCreator<CreateCustomizationGroupPayload>(
    CustomizationActionType.CreateCustomizationGroup,
    { startsActivity: true }
);

export const createCustomizationGroupError = createActionCreator<ErrorPayload & IndexedPayload>(
    CustomizationActionType.CreateCustomizationGroupError
);

export const createCustomizationGroupFailed = createActionCreator<FailedPayload & IndexedPayload>(
    CustomizationActionType.CreateCustomizationGroupFailed
);

export const createCustomizationGroupAccepted = createActionCreator<CreateCustomizationGroupAcceptedPayload>(
    CustomizationActionType.CreateCustomizationGroupAccepted
);

export const createCustomizationGroupSuccess = createActionCreator<CreateCustomizationGroupSuccessPayload>(
    CustomizationActionType.CreateCustomizationGroupSuccess
);

export const listCustomizationGroups = createActionCreator<IndexedPayload, ListCustomizationGroupsResponse>(
    CustomizationActionType.ListCustomizationGroups,
    { async: true, startsActivity: true }
);

export const listCustomizationGroupsError = createActionCreator<ErrorPayload & IndexedPayload>(
    CustomizationActionType.ListCustomizationGroupsError
);

export const listCustomizationGroupsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    CustomizationActionType.ListCustomizationGroupsFailed
);

export const listCustomizationGroupsSuccess = createActionCreator<ListCustomizationGroupsSuccessPayload>(
    CustomizationActionType.ListCustomizationGroupsSuccess
);

export const loadCustomizationGroups = createActionCreatorWithoutPayload<AggregatedResult>(
    CustomizationActionType.LoadCustomizationGroups,
    { async: true, startsActivity: true }
);

export const loadCustomizationGroupsError = createActionCreator<ErrorPayload>(
    CustomizationActionType.LoadCustomizationGroupsError
);

export const loadCustomizationGroupsFailed = createActionCreator<FailedPayload>(
    CustomizationActionType.LoadCustomizationGroupsFailed
);

export const loadCustomizationGroupsSuccess = createActionCreatorWithoutPayload(
    CustomizationActionType.LoadCustomizationGroupsSuccess
);

export const listCustomizationTaskDefinitions = createActionCreator<
    IndexedPayload,
    ListCustomizationTaskDefinitionsResponse
>(CustomizationActionType.ListCustomizationTaskDefinitions, { async: true, startsActivity: true });

export const listCustomizationTaskDefinitionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    CustomizationActionType.ListCustomizationTaskDefinitionsError
);

export const listCustomizationTaskDefinitionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    CustomizationActionType.ListCustomizationTaskDefinitionsFailed
);

export const listCustomizationTaskDefinitionsSuccess =
    createActionCreator<ListCustomizationTaskDefinitionsSuccessPayload>(
        CustomizationActionType.ListCustomizationTaskDefinitionsSuccess
    );

export const pollNonTerminalCustomizationGroup = createActionCreator<
    PollNonTerminalCustomizationGroupPayload,
    GetCustomizationGroupResponse
>(CustomizationActionType.PollNonTerminalCustomizationGroup, { async: true, startsActivity: true });

export const pollNonTerminalCustomizationGroupError = createActionCreator<ErrorPayload & IndexedPayload>(
    CustomizationActionType.PollNonTerminalCustomizationGroupError
);

export const pollNonTerminalCustomizationGroupFailed = createActionCreator<FailedPayload & IndexedPayload>(
    CustomizationActionType.PollNonTerminalCustomizationGroupFailed
);

export const pollNonTerminalCustomizationGroupComplete =
    createActionCreator<PollNonTerminalCustomizationGroupCompletePayload>(
        CustomizationActionType.PollNonTerminalCustomizationGroupComplete
    );

export const validateCustomizationTasks = createActionCreator<ValidateCustomizationTasksPayload>(
    CustomizationActionType.ValidateCustomizationTasks
);

export const validateCustomizationTasksError = createActionCreator<ErrorPayload>(
    CustomizationActionType.ValidateCustomizationTasksError
);

export const validateCustomizationTasksFailed = createActionCreator<FailedPayload>(
    CustomizationActionType.ValidateCustomizationTasksFailed
);

export const validateCustomizationTasksSuccess = createActionCreator<ValidateCustomizationTasksSuccessPayload>(
    CustomizationActionType.ValidateCustomizationTasksSuccess
);

export const getCustomizationTaskLog = createActionCreator<IndexedPayload, GetCustomizationTaskLogResponse>(
    CustomizationActionType.GetCustomizationTaskLog,
    { startsActivity: true, async: true }
);

export const getCustomizationTaskLogError = createActionCreator<ErrorPayload & IndexedPayload>(
    CustomizationActionType.GetCustomizationTaskLogError
);

export const getCustomizationTaskLogFailed = createActionCreator<FailedPayload & IndexedPayload>(
    CustomizationActionType.GetCustomizationTaskLogFailed
);

export const getCustomizationTaskLogSuccess = createActionCreator<GetCustomizationTaskLogSuccessPayload>(
    CustomizationActionType.GetCustomizationTaskLogSuccess
);

export const trackCustomizationGroupProgress = createActionCreator<TrackCustomizationGroupProgressPayload>(
    CustomizationActionType.TrackCustomizationGroupProgress,
    { startsActivity: true }
);

export const clearValidationResult = createActionCreatorWithoutPayload(CustomizationActionType.ClearValidationResult);
