import { UnionValueMap } from '../types/union-map';

export const AndroidClientDownloadLink = 'https://play.google.com/store/apps/details?id=com.microsoft.rdc.androidx';
export const IOSClientDownloadLink = 'https://aka.ms/rdios';
export const MacClientDownloadLink = 'https://apps.apple.com/app/microsoft-remote-desktop/id1295203466?mt=12';
export const WindowsARM64DownloadLink = 'https://go.microsoft.com/fwlink/?linkid=2098961';
export const WindowsX64DownloadLink = 'https://go.microsoft.com/fwlink/?linkid=2068602';
export const WindowsX86DownloadLink = 'https://go.microsoft.com/fwlink/?linkid=2098960';
export const MultiMonitorTutorialLink =
    'https://learn.microsoft.com/en-us/azure/dev-box/tutorial-connect-to-dev-box-with-remote-desktop-app?tabs=windows#configure-remote-desktop-to-use-multiple-monitors';

type ClientDownloadLinkName = 'Android' | 'IOS' | 'Mac' | 'WindowsARM64' | 'WindowsX64' | 'WindowsX86';

export const ClientDownloadLink: UnionValueMap<ClientDownloadLinkName, string> = {
    Android: AndroidClientDownloadLink,
    IOS: IOSClientDownloadLink,
    Mac: MacClientDownloadLink,
    WindowsARM64: WindowsARM64DownloadLink,
    WindowsX64: WindowsX64DownloadLink,
    WindowsX86: WindowsX86DownloadLink,
};

export type ConnectSearchParameter = 'env' | 'preview' | 'resourceid' | 'username' | 'version' | 'workspaceId';
type ConnectSearchParameterName = Capitalize<Exclude<ConnectSearchParameter, 'resourceid'>> | 'ResourceId';

export const ConnectSearchParameter: UnionValueMap<ConnectSearchParameterName, ConnectSearchParameter> = {
    Env: 'env',
    Preview: 'preview',
    ResourceId: 'resourceid',
    Username: 'username',
    Version: 'version',
    WorkspaceId: 'workspaceId',
};

export type EnvValue = 'avdarm' | 'avdclassic' | 'selfhostarm' | 'selfhostclassic';
type EnvValueName = 'AVDARM' | 'AVDClassic' | 'SelfHostARM' | 'SelfHostClassic';

export const EnvValue: UnionValueMap<EnvValueName, EnvValue> = {
    AVDARM: 'avdarm',
    AVDClassic: 'avdclassic',
    SelfHostARM: 'selfhostarm',
    SelfHostClassic: 'selfhostclassic',
};

export type ProtocolValue = 'ms-avd' | 'ms-rd';
type ProtocolValueName = 'MSAVD' | 'MSRD';

export const ProtocolValue: UnionValueMap<ProtocolValueName, ProtocolValue> = {
    MSAVD: 'ms-avd',
    MSRD: 'ms-rd',
};

// TODO: in future, update to have separate subscription links per cloud
export const SubscriptionLink = 'https://rdweb.wvd.microsoft.com/api/arm/feeddiscovery';

export type VersionValue = '0' | '1';
type VersionValueName = 'Version0' | 'Version1';

export const VersionValue: UnionValueMap<VersionValueName, VersionValue> = {
    Version0: '0',
    Version1: '1',
};
