import { DefaultButton, FontSizes, FontWeights, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ResourceFilterType } from '../constants/filters';
import noResourcesFoundImage from '../content/images/Search_Empty.svg';
import { useProjectFilterContext, useResourceFilterContext } from '../hooks/context/filters';
import { clearFiltersFromCurrentLocation } from '../utilities/filters';

interface NoResourcesFilterComponentProps {
    resetResourceFilter: (value: ResourceFilterType | undefined) => void;
    resetProjectFilter: (value: string | undefined) => void;
}

const messages = defineMessages({
    resetFiltersButtonAriaLabel: {
        id: 'NoResourcesFilter_ResetFiltersButton_AriaLabel',
        defaultMessage: 'Button for resetting filters',
        description: 'Aria label for resetting filters button',
    },
    resetFiltersButtonText: {
        id: 'NoResourcesFilter_ResetFiltersButton_Text',
        defaultMessage: 'Reset filters',
        description: 'Label text for the resetting filters button',
    },
    noResourcesFoundText: {
        id: 'NoResourcesFilter_NoResourcesFound_Text',
        defaultMessage: 'No resources found',
        description: 'Message informing user that there are no resources found for the current filters',
    },
    noResourcesMatchText: {
        id: 'NoResourcesFilter_NoResourcesMatch_Text',
        defaultMessage: 'No resources match the filters that are currently applied.',
        description: 'Message informing user that the filters applied resulted in no resources',
    },
    noResourcesFilterImageAlt: {
        id: 'NoResourcesFilter_NoResourcesFilterImage_Alt',
        defaultMessage: 'No resources match filters applied',
        description: 'Alt text for "No resources found" image',
    },
});

/**
 * Style Section
 */

const useNoResourcesContainerStyles = makeStyles({
    root: {
        paddingTop: 70,
    },
});

const useDescriptionContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '432px',
        textAlign: 'left',
    },
});

// Note: using paddingTop to space StackItems as Stack will override margins
const useEmptyContainerMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: 6,
    },
});

const useResetFiltersMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: `${FontWeights.semibold} !important`,
        lineHeight: '20px',
        paddingTop: 6,
    },
});

const useResetFiltersButtonContainerStyles = makeStyles({
    root: {
        paddingTop: 24,
    },
});

const containerTokens: IStackTokens = {
    childrenGap: 11,
};

const buttonStackTokens: IStackTokens = { childrenGap: 'm' };

/* END */

export const NoResourcesFilterComponent: React.FC<NoResourcesFilterComponentProps> = React.memo(
    (props: NoResourcesFilterComponentProps) => {
        const { resetResourceFilter, resetProjectFilter } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const resetFiltersMessageStyles = useResetFiltersMessageStyles();
        const resetFiltersButtonContainerStyles = useResetFiltersButtonContainerStyles();
        const descriptionContainerStyles = useDescriptionContainerStyles();
        const emptyContainerMessageStyles = useEmptyContainerMessageStyles();
        const noResourcesContainerStyles = useNoResourcesContainerStyles();

        const onReset = React.useCallback(
            () => (resetResourceFilter(undefined), resetProjectFilter(undefined), clearFiltersFromCurrentLocation()),
            [resetResourceFilter, resetProjectFilter, clearFiltersFromCurrentLocation]
        );

        return (
            <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="center"
                tokens={containerTokens}
                styles={noResourcesContainerStyles}
            >
                <Stack.Item>
                    <img alt={formatMessage(messages.noResourcesFilterImageAlt)} src={noResourcesFoundImage} />
                </Stack.Item>
                <Stack.Item>
                    <Stack verticalFill styles={descriptionContainerStyles}>
                        <Stack.Item styles={emptyContainerMessageStyles}>
                            {formatMessage(messages.noResourcesFoundText)}
                        </Stack.Item>
                        <Stack.Item styles={resetFiltersMessageStyles}>
                            {formatMessage(messages.noResourcesMatchText)}
                        </Stack.Item>
                        <Stack.Item styles={resetFiltersButtonContainerStyles}>
                            <Stack horizontal tokens={buttonStackTokens}>
                                <DefaultButton
                                    onClick={onReset}
                                    text={formatMessage(messages.resetFiltersButtonText)}
                                    ariaLabel={formatMessage(messages.resetFiltersButtonAriaLabel)}
                                />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        );
    }
);

export const NoResourcesFilterContainer: React.FC = () => {
    // Context hooks
    const { onChange: resetResourceFilter } = useResourceFilterContext();
    const { onChange: resetProjectFilter } = useProjectFilterContext();

    return (
        <NoResourcesFilterComponent resetResourceFilter={resetResourceFilter} resetProjectFilter={resetProjectFilter} />
    );
};

export default NoResourcesFilterContainer;
