import { PathTreeNode } from './path-tree';

// Adapted from https://gist.github.com/stephanbogner/4b590f992ead470658a5ebf09167b03d
export const arrangePathsIntoTree = (paths: string[]): PathTreeNode[] => {
    const tree: PathTreeNode[] = [];

    paths.forEach((path) => {
        const parts = path.split('/');

        if (parts.length === 0) {
            return;
        }

        // Start moving through the tree at the "root" node (the outermost list of nodes)
        let currentNodeItems = tree;

        // Go through each '/'-separated part of the path and move down the tree, creating new nodes where they do not exist
        parts.forEach((part, index) => {
            const existingNode = currentNodeItems.find((value: PathTreeNode) => value.name === part);

            if (existingNode) {
                // A node already exists for this path part, continue moving down the tree from that node
                currentNodeItems = existingNode.items;
            } else {
                // The key for a path part is the full path to that part (i.e. name: 'A', nodeKey: 'Letters/A')
                const nodeKey = parts.slice(0, index + 1).join('/');

                // Create a node for this part with empty child nodes
                // (A node is a full file path if it is the last part of a path)
                const newNode: PathTreeNode = {
                    name: part,
                    path: nodeKey,
                    items: [],
                    isFullFilePath: index === parts.length - 1,
                };

                // Push this new node as a child of the current node
                currentNodeItems.push(newNode);

                // Continue moving down the tree from the new node
                currentNodeItems = newNode.items;
            }
        });
    });

    return tree;
};
