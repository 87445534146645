import { createSelector } from '@reduxjs/toolkit';
import { createProjectDataPlaneUriFromEnvironmentTypeUri } from '../../ids/project-environment-type';
import { Status } from '../../models/common';
import { ProjectFromDiscoveryService } from '../../models/project';
import {
    EnableStatus,
    ProjectEnvironmentTypeAbilities,
    ProjectEnvironmentTypeFromDataPlane,
} from '../../models/project-environment-type';
import { SerializableMap } from '../../types/serializable-map';
import { getKey } from '../../utilities/key-value-pair';
import { filter, keys } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import {
    projectEnvironmentTypeAbilitiesAdapter,
    projectEnvironmentTypeFromDataPlaneAdapter,
} from '../adapters/project-environment-type-adapters';
import { StoreState } from '../store/store-state';
import {
    getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentRead,
    getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentWrite,
    getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentRead,
    getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite,
} from './abilities-selectors';
import { createGroupedMapSelector, createMapFromEntitiesSelector, StoreStateSelector } from './common';
import { getProjectsByDiscoveryServiceURI } from './project-from-discovery-service-selectors';

const createAreThereAnyProjectEnvironmentTypesWithAbilitiesSelector =
    (
        projectPredicate: (project: ProjectFromDiscoveryService) => boolean,
        projectEnvironmentTypeAbilitiesPredicate: (
            projectEnvironmentTypeAbilities: ProjectEnvironmentTypeAbilities
        ) => boolean
    ) =>
    (
        projects: SerializableMap<ProjectFromDiscoveryService>,
        projectEnvironmentTypes: SerializableMap<ProjectEnvironmentTypeFromDataPlane>,
        projectEnvironmentTypeAbilities: SerializableMap<ProjectEnvironmentTypeAbilities>
    ) =>
        keys(projectEnvironmentTypes).some((key) => {
            const projectDataplaneId = createProjectDataPlaneUriFromEnvironmentTypeUri(key);
            return (
                projectPredicate(projects[projectDataplaneId]) ||
                projectEnvironmentTypeAbilitiesPredicate(projectEnvironmentTypeAbilities[key])
            );
        });

const createFilterToProjectEnvironmentTypesWithAbilitiesSelector =
    (
        projectPredicate: (project: ProjectFromDiscoveryService) => boolean,
        projectEnvironmentTypeAbilitiesPredicate: (
            projectEnvironmentTypeAbilities: ProjectEnvironmentTypeAbilities
        ) => boolean
    ) =>
    (
        projects: SerializableMap<ProjectFromDiscoveryService>,
        projectEnvironmentTypes: SerializableMap<ProjectEnvironmentTypeFromDataPlane>,
        projectEnvironmentTypeAbilities: SerializableMap<ProjectEnvironmentTypeAbilities>
    ) =>
        filter(projectEnvironmentTypes, (projectEnvironmentType) => {
            const projectDataplaneId = createProjectDataPlaneUriFromEnvironmentTypeUri(projectEnvironmentType.uri);

            return (
                projectPredicate(projects[projectDataplaneId]) ||
                projectEnvironmentTypeAbilitiesPredicate(projectEnvironmentTypeAbilities[projectEnvironmentType.uri])
            );
        });
/**
 * Entity state selectors
 */

const projectEnvironmentTypeFromDataplaneSelectors =
    projectEnvironmentTypeFromDataPlaneAdapter.getSelectors<StoreState>(
        (store) => store.projectEnvironmentTypeStore.data.projectEnvironmentTypesFromDataplane
    );

const projectEnvironmentTypeAbilitiesFromDataplaneSelectors =
    projectEnvironmentTypeAbilitiesAdapter.getSelectors<StoreState>(
        (store) => store.projectEnvironmentTypeStore.data.projectEnvironmentTypeAbilities
    );

const statusesForListProjectEnvironmentTypesFromDataplaneSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.projectEnvironmentTypeStore.status.listProjectEnvironmentTypesFromDataplane
);

/**
 * Basic selectors
 */

export const getIsProjectEnvironmentTypeFromDataplaneEnabled = (
    projectEnvironmentTypeFromDataplane: ProjectEnvironmentTypeFromDataPlane
): boolean => projectEnvironmentTypeFromDataplane.status === EnableStatus.Enabled;

export const getStatusForDiscoverProjectEnvironmentTypesFromDataplane: StoreStateSelector<Status> = (store) =>
    store.projectEnvironmentTypeStore.status.discoverProjectEnvironmentTypesFromDataplane;

export const getStatusForDiscoverProjectEnvironmentTypesAbilitiesFromDataplane: StoreStateSelector<Status> = (store) =>
    store.projectEnvironmentTypeStore.status.discoverProjectEnvironmentTypeAbilities;

/**
 * Composed selectors
 */

export const getProjectEnvironmentTypesFromDataplane = createMapFromEntitiesSelector(
    projectEnvironmentTypeFromDataplaneSelectors.selectAll
);

export const getProjectEnvironmentTypeAbilitiesFromDataplane = createMapFromEntitiesSelector(
    projectEnvironmentTypeAbilitiesFromDataplaneSelectors.selectAll
);

export const getEnabledProjectEnvironmentTypesFromDataplane: StoreStateSelector<
    SerializableMap<ProjectEnvironmentTypeFromDataPlane>
> = createSelector([getProjectEnvironmentTypesFromDataplane], (projectEnvironmentTypesFromDataplane) =>
    filter(projectEnvironmentTypesFromDataplane, getIsProjectEnvironmentTypeFromDataplaneEnabled)
);

export const getHasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentRead: StoreStateSelector<boolean> =
    createSelector(
        [
            getProjectsByDiscoveryServiceURI,
            getProjectEnvironmentTypesFromDataplane,
            getProjectEnvironmentTypeAbilitiesFromDataplane,
        ],
        createAreThereAnyProjectEnvironmentTypesWithAbilitiesSelector(
            getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentRead,
            getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentRead
        )
    );

export const getHasProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite: StoreStateSelector<boolean> =
    createSelector(
        [
            getProjectsByDiscoveryServiceURI,
            getEnabledProjectEnvironmentTypesFromDataplane,
            getProjectEnvironmentTypeAbilitiesFromDataplane,
        ],
        createAreThereAnyProjectEnvironmentTypesWithAbilitiesSelector(
            getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite,
            getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentWrite
        )
    );

export const getProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite: StoreStateSelector<
    SerializableMap<ProjectEnvironmentTypeFromDataPlane>
> = createSelector(
    [
        getProjectsByDiscoveryServiceURI,
        getEnabledProjectEnvironmentTypesFromDataplane,
        getProjectEnvironmentTypeAbilitiesFromDataplane,
    ],
    createFilterToProjectEnvironmentTypesWithAbilitiesSelector(
        getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite,
        getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentWrite
    )
);

export const getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject = createGroupedMapSelector(
    getProjectEnvironmentTypesFromDataplaneAuthorizedForEnvironmentWrite,
    (entry) => createProjectDataPlaneUriFromEnvironmentTypeUri(getKey(entry))
);

export const getStatusesForListProjectEnvironmentTypesFromDataplane = createMapFromEntitiesSelector(
    statusesForListProjectEnvironmentTypesFromDataplaneSelectors.selectAll
);
