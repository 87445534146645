import { DevBoxOperation } from '../../../models/dev-box';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import { listDevBoxOperations, loadDevBoxOperationsForDevBoxes } from './dev-box-operation-action-creators';

/**
 * Action types
 */

type DevBoxOperationActionName =
    | ActionName<'ListDevBoxOperations', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadDevBoxOperationsForDevBoxes', 'Error' | 'Failed' | 'Success'>;

export type DevBoxOperationActionType =
    | ActionType<'LIST_DEV_BOX_OPERATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_DEV_BOX_OPERATIONS_FOR_DEV_BOXES', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const DevBoxOperationActionType: UnionValueMap<DevBoxOperationActionName, DevBoxOperationActionType> = {
    ListDevBoxOperations: 'LIST_DEV_BOX_OPERATIONS',
    ListDevBoxOperationsError: 'LIST_DEV_BOX_OPERATIONS_ERROR',
    ListDevBoxOperationsFailed: 'LIST_DEV_BOX_OPERATIONS_FAILED',
    ListDevBoxOperationsSuccess: 'LIST_DEV_BOX_OPERATIONS_SUCCESS',
    LoadDevBoxOperationsForDevBoxes: 'LOAD_DEV_BOX_OPERATIONS_FOR_DEV_BOXES',
    LoadDevBoxOperationsForDevBoxesError: 'LOAD_DEV_BOX_OPERATIONS_FOR_DEV_BOXES_ERROR',
    LoadDevBoxOperationsForDevBoxesFailed: 'LOAD_DEV_BOX_OPERATIONS_FOR_DEV_BOXES_FAILED',
    LoadDevBoxOperationsForDevBoxesSuccess: 'LOAD_DEV_BOX_OPERATIONS_FOR_DEV_BOXES_SUCCESS',
};

/**
 * Payloads
 */

export type ListDevBoxOperationsSuccessPayload = IndexedPayload & ResultPayload<DevBoxOperation[]>;

/**
 * Action shorthands
 */

export type ListDevBoxOperationsAction = ReturnType<typeof listDevBoxOperations>;
export type LoadDevBoxOperationsForDevBoxesAction = ReturnType<typeof loadDevBoxOperationsForDevBoxes>;
