import { IMessageBarProps, MessageBar, MessageBarType, makeStyles, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getSemanticColor } from '../utilities/styles';

const useInfoMessageBarStyles = makeStyles((theme) => ({
    root: {
        background: getSemanticColor(theme, 'infoMessageBarBackground'),
        color: getSemanticColor(theme, 'infoMessageBarColor'),
    },
    icon: {
        color: getSemanticColor(theme, 'infoMessageBarIconColor'),
    },
    dismissal: {
        '& .ms-Button-icon': {
            color: getSemanticColor(theme, 'infoMessageBarColor'),
        },
    },
}));

const messages = defineMessages({
    messageBarCloseButtonAriaLabel: {
        id: 'MessageBarCloseButton_AriaLabel',
        defaultMessage: 'Close message bar',
        description: 'Aria label for message bar close button',
    },
});

export const PortalMessageBar: React.FC<IMessageBarProps> = (props: IMessageBarProps) => {
    const { messageBarType, onDismiss, styles: providedStyles } = props;

    const infoMessageBarStyles = useInfoMessageBarStyles();

    const isInfoMessageBar = messageBarType === MessageBarType.info || !messageBarType;
    const styles = React.useMemo(
        () => (isInfoMessageBar ? mergeStyleSets(infoMessageBarStyles, providedStyles) : providedStyles),
        [isInfoMessageBar, infoMessageBarStyles, providedStyles]
    );

    // Intl hooks
    const { formatMessage } = useIntl();

    const dismissButtonAriaLabel = onDismiss ? formatMessage(messages.messageBarCloseButtonAriaLabel) : undefined;

    return <MessageBar {...props} styles={styles} dismissButtonAriaLabel={dismissButtonAriaLabel} />;
};
