import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { discoverPermissionsForProjectEnvironmentTypesListenerSaga } from './discover-permissions-for-project-environment-types';
import { discoverPermissionsForProjectsListenerSaga } from './discover-permissions-for-projects';
import { getProjectEnvironmentTypePermissionsListenerSaga } from './get-project-environment-type-permissions';
import { getProjectPermissionsListenerSaga } from './get-project-permissions';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverPermissionsForProjectEnvironmentTypesListenerSaga),
        fork(discoverPermissionsForProjectsListenerSaga),
        fork(getProjectEnvironmentTypePermissionsListenerSaga),
        fork(getProjectPermissionsListenerSaga),
    ]);
}
