import { UnionMap } from '../../types/union-map';

export type ActionBarState =
    | 'NotStarted'
    | 'Loading'
    | 'CannotCreateDevBoxesOrEnvironments'
    | 'CanCreateOnlyDevBoxes'
    | 'CanCreateOnlyEnvironments'
    | 'CanCreateDevBoxesAndEnvironments';

export const ActionBarState: UnionMap<ActionBarState> = {
    NotStarted: 'NotStarted',
    Loading: 'Loading',
    CannotCreateDevBoxesOrEnvironments: 'CannotCreateDevBoxesOrEnvironments',
    CanCreateOnlyDevBoxes: 'CanCreateOnlyDevBoxes',
    CanCreateOnlyEnvironments: 'CanCreateOnlyEnvironments',
    CanCreateDevBoxesAndEnvironments: 'CanCreateDevBoxesAndEnvironments',
};
