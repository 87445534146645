import { EnvironmentPatchProperties } from 'devcenter-internal-stable';
import { getTokensFromEnvironmentDataPlaneUri } from '../../../ids/environment';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { LongRunningOperationResponseContract } from '../../contracts/common';
import { EnviromentUpdateContract, EnvironmentContract, PutEnvironmentBodyContract } from '../../contracts/environment';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type CreateOrReplaceEnvironmentResponse = DataResponse<EnvironmentContract>;
export type DeleteEnvironmentResponse = DataResponse<LongRunningOperationResponseContract>;
export type GetEnvironmentResponse = DataResponse<EnvironmentContract>;
export type CustomActionEnvironmentResponse = DataResponse<LongRunningOperationResponseContract>;
export type DeployEnvironmentResponse = DataResponse<LongRunningOperationResponseContract>;
export type ListEnvironmentsResponse = DataResponse<EnvironmentContract[]>;
export type PatchEnvironmentResponse = DataResponse<EnvironmentContract>;

const createOrReplaceEnvironment = async (
    id: string,
    name: string | undefined,
    body: PutEnvironmentBodyContract,
    accessToken: string,
    activityId?: string
): Promise<CreateOrReplaceEnvironmentResponse> => {
    const { devCenter, environmentName: nameFromId, projectName, user } = getTokensFromEnvironmentDataPlaneUri(id);

    // When name is explicitly given (create case), use that to preserve casing. When it isn't (redeploy case), just use
    // name inferred from ID, since case doesn't matter.
    const environmentName = name ?? nameFromId;

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.CreateOrReplaceEnvironment,

        whenUsingBetaClient: (client) =>
            client.environments.createOrReplaceEnvironment(
                projectName,
                user,
                environmentName,
                body,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.createOrReplaceEnvironment(
                projectName,
                user,
                environmentName,
                body,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const environment: EnvironmentContract = {
        ...data,
        name: data.name!,
        uri: data.uri!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: environment, succeeded: true };
};

// Note: create and deploy are separated, despite being implemented via the same action, because create requires the
// environment name separate from its ID. (This preserves its original casing)
export const createEnvironment = async (
    id: string,
    name: string,
    body: PutEnvironmentBodyContract,
    accessToken: string,
    activityId?: string
): Promise<CreateOrReplaceEnvironmentResponse> => createOrReplaceEnvironment(id, name, body, accessToken, activityId);

export const replaceEnvironment = async (
    id: string,
    body: PutEnvironmentBodyContract,
    accessToken: string,
    activityId?: string
): Promise<CreateOrReplaceEnvironmentResponse> =>
    createOrReplaceEnvironment(id, undefined, body, accessToken, activityId);

export const getEnvironment = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetEnvironmentResponse> => {
    const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetEnvironment,

        whenUsingBetaClient: (client) =>
            client.environments.getEnvironmentByUser(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.getEnvironmentByUser(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const environment: EnvironmentContract = {
        ...data,
        name: data.name!,
        uri: data.uri!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: environment, succeeded: true };
};

export const deleteEnvironment = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<DeleteEnvironmentResponse> => {
    const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.DeleteEnvironment,

        whenUsingBetaClient: (client) =>
            client.environments.deleteEnvironment(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.deleteEnvironment(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listEnvironments = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListEnvironmentsResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListEnvironments,

        whenUsingBetaClient: (client) =>
            client.environments.listEnvironments(projectName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.environments.listEnvironments(projectName, getCommonOptions(accessToken, activityId)),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const environments = data.map<EnvironmentContract>((value) => ({
        ...value,
        name: value.name!,
        uri: value.uri!,
    }));
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: environments, succeeded: true };
};

export const updateEnvironment = async (
    id: string,
    body: EnviromentUpdateContract,
    accessToken: string,
    activityId?: string
): Promise<PatchEnvironmentResponse> => {
    const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentDataPlaneUri(id);

    //type cast to EnvironmentPatchProperties since generated TypeScript SDK does not accept null as value for expirationDate
    const updateBody = body as EnvironmentPatchProperties;

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.UpdateEnvironment,

        whenUsingBetaClient: (client) =>
            client.environments.patchEnvironment(
                projectName,
                user,
                environmentName,
                updateBody,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.patchEnvironment(
                projectName,
                user,
                environmentName,
                updateBody,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const environment: EnvironmentContract = {
        ...data,
        name: data.name!,
        uri: data.uri!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: environment, succeeded: true };
};
