import { SagaIterator } from 'redux-saga';
import { call, CallEffect } from 'redux-saga/effects';
import { ClientError } from '../../models/common';

type TryCallResult<T> = SagaIterator<T | ClientError>;
type CallSignature<T> = (saga: () => SagaIterator<T>) => TryCallResult<T>;

export function* tryCallSaga<T>(saga: () => SagaIterator<T>): TryCallResult<T> {
    try {
        return yield call(saga);
    } catch (err) {
        return err instanceof ClientError ? err : new ClientError(err);
    }
}

export const tryCall = <T>(saga: () => SagaIterator<T>): CallEffect<T | ClientError> =>
    call<CallSignature<T>>(tryCallSaga, saga);
