import { EntityState } from '@reduxjs/toolkit';
import { AzureDevOpsBranch, AzureDevOpsRepo, AzureDevOpsTreeEntry } from '../../models/azure-dev-ops';
import { Entity } from '../../models/common';
import { createInitializer } from '../../utilities/initializer';
import {
    azureDevOpsBranchesAdapter,
    azureDevOpsRepoAdapter,
    azureDevOpsRepoContentsAdapter,
} from '../adapters/azure-dev-ops-adapters';
import { statusAdapter } from '../adapters/common/status-adapter';
import { stringAdapter } from '../adapters/common/string-adapter';
import { IndexedStatusStore } from './indexed-status-store';

type IndexedStatusName = 'getRepoByCloneUrl' | 'loadRepoResources' | 'listBranches' | 'getRepoContents' | 'getRepoItem';

export interface AzureDevOpsDataStore {
    /** Indexed by repo clone url */
    repos: EntityState<Entity<AzureDevOpsRepo>>;
    /** Indexed by repo `url` property */
    branches: EntityState<Entity<AzureDevOpsBranch[]>>;
    /** Indexed by branch key */
    repoContents: EntityState<Entity<AzureDevOpsTreeEntry[]>>;
    /** Indexed by repo item identifier (see `getAzureDevOpsRepoItemId`) */
    repoItems: EntityState<Entity<string>>;
}

export const AzureDevOpsDataStore = createInitializer<AzureDevOpsDataStore>({
    repos: azureDevOpsRepoAdapter.getInitialState(),
    branches: azureDevOpsBranchesAdapter.getInitialState(),
    repoContents: azureDevOpsRepoContentsAdapter.getInitialState(),
    repoItems: stringAdapter.getInitialState(),
});

export type AzureDevOpsStatusStore = IndexedStatusStore<IndexedStatusName>;

export const AzureDevOpsStatusStore = createInitializer<AzureDevOpsStatusStore>({
    getRepoByCloneUrl: statusAdapter.getInitialState(),
    loadRepoResources: statusAdapter.getInitialState(),
    listBranches: statusAdapter.getInitialState(),
    getRepoContents: statusAdapter.getInitialState(),
    getRepoItem: statusAdapter.getInitialState(),
});

export interface AzureDevOpsStore {
    data: AzureDevOpsDataStore;
    status: AzureDevOpsStatusStore;
}

export const AzureDevOpsStore = createInitializer<AzureDevOpsStore>({
    data: AzureDevOpsDataStore(),
    status: AzureDevOpsStatusStore(),
});
