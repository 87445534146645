import { makeStyles } from '@fluentui/react';
import * as React from 'react';

/**
 * Styles
 */

const remoteApplicationIconStyles = makeStyles({
    // Note: currentColor will allow the SVG to inherit the color of the parent <i /> element.
    path: {
        fill: 'currentColor',
    },
    root: {
        color: 'currentColor',
    },
});

/**
 * END Styles
 */

const RemoteApplicationIcon: React.FC = () => {
    // Style hooks
    const classNames = remoteApplicationIconStyles();

    return (
        <svg className={classNames.root} viewBox="0 0 24 23" xmlns="http://www.w3.org/2000/svg">
            <path
                className={classNames.path}
                d="M0 0.5H24V8H22.5V6.5H1.5V18.5H12V20H0V0.5ZM1.5 5H22.5V2H1.5V5ZM13.7227 15.2773L14.7773 14.2227L19.0664 18.5L14.7773 22.7773L13.7227 21.7227L16.9336 18.5L13.7227 15.2773ZM22.7227 9.72266L23.7773 10.7773L20.5664 14L23.7773 17.2227L22.7227 18.2773L18.4336 14L22.7227 9.72266Z"
            />
        </svg>
    );
};

export default RemoteApplicationIcon;
