import { DistributedTracingModes, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';
import Settings from '../../settings/settings';

const config: IConfiguration & IConfig = {
    disableAjaxTracking: false,
    // NOTE: changing disableCookiesUsage to false will complicate privacy review and require us to show a cookie banner
    disableCookiesUsage: true,
    disableExceptionTracking: false,
    disableFetchTracking: false,
    distributedTracingMode: DistributedTracingModes.W3C,
    emitLineDelimitedJson: true,
    enableAutoRouteTracking: false,
    enableCorsCorrelation: false,
    enableDebug: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableSessionStorageBuffer: true,
    instrumentationKey: Settings.ApplicationInsights.InstrumentationKey,
    isBeaconApiDisabled: false,
    isBrowserLinkTrackingEnabled: false,
    isRetryDisabled: false,
    isStorageUseDisabled: false,

    /**
     * maxAjaxCallsPerView: once we exceed this amount of AJAX/fetch dependencies per page view, Application Insights
     * will stop logging those types of dependencies. This is problematic for long-running sessions, or users who have
     * access to a large number of resources. Setting this to 5000 per page view, which is 10x the default.
     */
    maxAjaxCallsPerView: 5000,

    /*
        The telemetry channel does not actually "batch" requests how we would expect it to.
        As soon as there is a pause in request traffic, the channel flushes all telemetry in its batch size,
        which by default is 100000 bytes (effectively all of the telemetry in the channel).
        This is not actually one batched request: it is a single request per event.
        This high request volume blocks our ability to make concurrent requests, and the telemetry channel does not stop
        sending requests until it has completed its batch.
        By setting the batch size to a much smaller number of bytes (5KB), the channel sends a smaller number of requests
        at a time before yielding to other pending requests, and therefore only fills actual idle time with telemetry
        requests, rather than delaying discovery requests.
    **/
    maxBatchSizeInBytes: 5120,
};

export default config;
