import { createReducer } from '@reduxjs/toolkit';
import { initializeMocksError, initializeMocksSuccess } from '../actions/mocks/mocks-action-creators';
import { MocksState } from '../store/mocks-state';
import { MocksStore } from '../store/mocks-store';
import { getPayload } from '../utilities/payload-action';

export const mocksReducer = createReducer(MocksStore(), (builder) => {
    builder.addCase(initializeMocksError, (store) => {
        store.mocksState = MocksState.ConfigurationError;
    });

    builder.addCase(initializeMocksSuccess, (store, action) => {
        const { noScenariosConfigured } = getPayload(action);

        store.mocksState = noScenariosConfigured
            ? MocksState.ConfiguredWithNoScenarios
            : MocksState.ConfiguredWithScenarios;
    });
});
