import { ActionReducerMapBuilder, EntityState, PayloadAction, createReducer } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { Entity, Status } from '../../models/common';
import { ActionCreator, GroupableActionCreator } from '../actions/core-action-creators';
import { ErrorPayload, FailedPayload, GroupablePayload, IndexedPayload } from '../actions/core-actions';
import { statusAdapter } from '../adapters/common/status-adapter';
import { AsyncState } from '../store/common-state';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getPayload } from '../utilities/payload-action';

interface CreateIndexedStatusReducerOptions<
    TErrorPayload extends ErrorPayload & IndexedPayload = ErrorPayload & IndexedPayload,
    TFailedPayload extends FailedPayload & IndexedPayload = FailedPayload & IndexedPayload,
    TInProgressPayload extends IndexedPayload = IndexedPayload,
    TResetPayload extends IndexedPayload = IndexedPayload,
    TSuccessPayload extends IndexedPayload = IndexedPayload
> {
    additionalCases?: (builder: ActionReducerMapBuilder<EntityState<Entity<Status>>>) => void;
    error?: ActionCreator<TErrorPayload> | GroupableActionCreator<TErrorPayload>;
    failed?: ActionCreator<TFailedPayload> | GroupableActionCreator<TFailedPayload>;
    inProgress?: ActionCreator<TInProgressPayload> | GroupableActionCreator<TInProgressPayload>;
    reset?: ActionCreator<TResetPayload> | GroupableActionCreator<TResetPayload>;
    success?: ActionCreator<TSuccessPayload> | GroupableActionCreator<TSuccessPayload>;
}

interface CreateIndexedStatusReducerWithCustomIdOptions<
    /* eslint-disable @typescript-eslint/ban-types */
    // Justification: usage is purposeful - need to indicate that SOME type is provided
    TCorePayload extends {},
    /* eslint-enable @typescript-eslint/ban-types */
    TErrorPayload extends ErrorPayload & TCorePayload = ErrorPayload & TCorePayload,
    TFailedPayload extends FailedPayload & TCorePayload = FailedPayload & TCorePayload,
    TInProgressPayload = TCorePayload,
    TResetPayload = TCorePayload,
    TSuccessPayload = TCorePayload
> {
    additionalCases?: (builder: ActionReducerMapBuilder<EntityState<Entity<Status>>>) => void;
    error?: ActionCreator<TErrorPayload> | GroupableActionCreator<TErrorPayload>;
    failed?: ActionCreator<TFailedPayload> | GroupableActionCreator<TFailedPayload>;
    getId: (action: PayloadAction<TCorePayload>) => string;
    inProgress?: ActionCreator<TInProgressPayload> | GroupableActionCreator<TInProgressPayload>;
    reset?: ActionCreator<TResetPayload> | GroupableActionCreator<TResetPayload>;
    success?: ActionCreator<TSuccessPayload> | GroupableActionCreator<TSuccessPayload>;
}

export type IndexedStatusReducer = (
    store: EntityState<Entity<Status>> | undefined,
    action: Action
) => EntityState<Entity<Status>>;

const defaultGetId = (action: PayloadAction<IndexedPayload>) => action.payload.id;

const getEntityForErrorAction = <TErrorPayload extends ErrorPayload>(
    action: PayloadAction<TErrorPayload>,
    getId: (action: PayloadAction<TErrorPayload>) => string
): Entity<Status> => {
    const { error } = getPayload(action);
    const id = getId(action);

    const status = Status({
        failure: error,
        state: AsyncState.Error,
    });

    return Entity(id, status);
};

const getEntityForFailedAction = <TFailedPayload extends FailedPayload>(
    action: PayloadAction<TFailedPayload>,
    getId: (action: PayloadAction<TFailedPayload>) => string
): Entity<Status> => {
    const { failure } = getPayload(action);
    const id = getId(action);

    const status = Status({
        failure,
        state: AsyncState.Failed,
    });

    return Entity(id, status);
};

const getEntityForInProgressAction = <TInProgressPayload>(
    action: PayloadAction<TInProgressPayload>,
    getId: (action: PayloadAction<TInProgressPayload>) => string
): Entity<Status> => {
    const id = getId(action);

    return Entity(id, Status({ state: AsyncState.InProgress }));
};

const getEntityForResetAction = <TResetPayload>(
    action: PayloadAction<TResetPayload>,
    getId: (action: PayloadAction<TResetPayload>) => string
): Entity<Status> => {
    const id = getId(action);

    return Entity(id, Status());
};

const getEntityForSuccessAction = <TSuccessPayload>(
    action: PayloadAction<TSuccessPayload>,
    getId: (action: PayloadAction<TSuccessPayload>) => string
): Entity<Status> => {
    const id = getId(action);

    return Entity(id, Status({ state: AsyncState.Success }));
};

const createErrorReducer =
    <TCorePayload>(getId: (action: PayloadAction<TCorePayload>) => string) =>
    (store: EntityState<Entity<Status>>, action: PayloadAction<GroupablePayload<ErrorPayload & TCorePayload>>) => {
        const actions = getActionsInGroup(action);
        const entities = actions.map((action) => getEntityForErrorAction(action, getId));
        statusAdapter.setMany(store, entities);
    };

const createFailedReducer =
    <TCorePayload>(getId: (action: PayloadAction<TCorePayload>) => string) =>
    (store: EntityState<Entity<Status>>, action: PayloadAction<GroupablePayload<FailedPayload & TCorePayload>>) => {
        const actions = getActionsInGroup(action);
        const entities = actions.map((action) => getEntityForFailedAction(action, getId));
        statusAdapter.setMany(store, entities);
    };

const createInProgressReducer =
    <TCorePayload>(getId: (action: PayloadAction<TCorePayload>) => string) =>
    (store: EntityState<Entity<Status>>, action: PayloadAction<GroupablePayload<TCorePayload>>) => {
        const actions = getActionsInGroup(action);
        const entities = actions.map((action) => getEntityForInProgressAction(action, getId));
        statusAdapter.setMany(store, entities);
    };

const createResetReducer =
    <TCorePayload>(getId: (action: PayloadAction<TCorePayload>) => string) =>
    (store: EntityState<Entity<Status>>, action: PayloadAction<GroupablePayload<TCorePayload>>) => {
        const actions = getActionsInGroup(action);
        const entities = actions.map((action) => getEntityForResetAction(action, getId));
        statusAdapter.setMany(store, entities);
    };

const createSuccessReducer =
    <TCorePayload>(getId: (action: PayloadAction<TCorePayload>) => string) =>
    (store: EntityState<Entity<Status>>, action: PayloadAction<GroupablePayload<TCorePayload>>) => {
        const actions = getActionsInGroup(action);
        const entities = actions.map((action) => getEntityForSuccessAction(action, getId));
        statusAdapter.setMany(store, entities);
    };

export const createIndexedStatusReducer = <
    TErrorPayload extends ErrorPayload & IndexedPayload = ErrorPayload & IndexedPayload,
    TFailedPayload extends FailedPayload & IndexedPayload = FailedPayload & IndexedPayload,
    TInProgressPayload extends IndexedPayload = IndexedPayload,
    TResetPayload extends IndexedPayload = IndexedPayload,
    TSuccessPayload extends IndexedPayload = IndexedPayload
>(
    options: CreateIndexedStatusReducerOptions<
        TErrorPayload,
        TFailedPayload,
        TInProgressPayload,
        TResetPayload,
        TSuccessPayload
    >
): IndexedStatusReducer =>
    createReducer(statusAdapter.getInitialState(), (builder) => {
        const { additionalCases, error, failed, inProgress, reset, success } = options;

        if (error) {
            builder.addCase(error, createErrorReducer(defaultGetId));
        }

        if (failed) {
            builder.addCase(failed, createFailedReducer(defaultGetId));
        }

        if (inProgress) {
            builder.addCase(inProgress, createInProgressReducer(defaultGetId));
        }

        if (reset) {
            builder.addCase(reset, createResetReducer(defaultGetId));
        }

        if (success) {
            builder.addCase(success, createSuccessReducer(defaultGetId));
        }

        if (additionalCases) {
            additionalCases(builder);
        }
    });

export const createIndexedStatusReducerWithCustomId = <
    /* eslint-disable @typescript-eslint/ban-types */
    // Justification: usage is purposeful - need to indicate that SOME type is provided
    TCorePayload extends {},
    /* eslint-enable @typescript-eslint/ban-types */
    TErrorPayload extends ErrorPayload & TCorePayload = ErrorPayload & TCorePayload,
    TFailedPayload extends FailedPayload & TCorePayload = FailedPayload & TCorePayload,
    TInProgressPayload extends TCorePayload = TCorePayload,
    TResetPayload extends TCorePayload = TCorePayload,
    TSuccessPayload extends TCorePayload = TCorePayload
>(
    options: CreateIndexedStatusReducerWithCustomIdOptions<
        TCorePayload,
        TErrorPayload,
        TFailedPayload,
        TInProgressPayload,
        TResetPayload,
        TSuccessPayload
    >
): IndexedStatusReducer =>
    createReducer(statusAdapter.getInitialState(), (builder) => {
        const { additionalCases, error, failed, getId, inProgress, reset, success } = options;

        if (error) {
            builder.addCase(error, createErrorReducer(getId));
        }

        if (failed) {
            builder.addCase(failed, createFailedReducer(getId));
        }

        if (inProgress) {
            builder.addCase(inProgress, createInProgressReducer(getId));
        }

        if (reset) {
            builder.addCase(reset, createResetReducer(getId));
        }

        if (success) {
            builder.addCase(success, createSuccessReducer(getId));
        }

        if (additionalCases) {
            additionalCases(builder);
        }
    });
