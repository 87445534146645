import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../../models/common';
import { Project } from '../../../models/project';
import { createInitializer } from '../../../utilities/initializer';
import { projectAdapter } from '../../adapters/project-adapters';
import { StatusStore } from '../status-store';

type StatusName =
    | 'initializeSingleDevCenterHome'
    | 'loadResourcesForSingleDevCenter'
    | 'loadProjectsForSingleDevCenter'
    | 'loadDevBoxesForSingleDevCenter';

export interface SingleDevCenterSubApplicationDataStore {
    devCenterUri?: string;
    projects: EntityState<Entity<Project>>;
}

export const SingleDevCenterSubApplicationDataStore = createInitializer<SingleDevCenterSubApplicationDataStore>({
    projects: projectAdapter.getInitialState(),
});

export type SingleDevCenterSubApplicationStatusStore = StatusStore<StatusName>;

export const SingleDevCenterSubApplicationStatusStore = createInitializer<SingleDevCenterSubApplicationStatusStore>({
    initializeSingleDevCenterHome: Status(),
    loadResourcesForSingleDevCenter: Status(),
    loadProjectsForSingleDevCenter: Status(),
    loadDevBoxesForSingleDevCenter: Status(),
});

export interface SingleDevCenterSubApplicationStore {
    data: SingleDevCenterSubApplicationDataStore;
    status: SingleDevCenterSubApplicationStatusStore;
}

export const SingleDevCenterSubApplicationStore = createInitializer<SingleDevCenterSubApplicationStore>({
    data: SingleDevCenterSubApplicationDataStore(),
    status: SingleDevCenterSubApplicationStatusStore(),
});
