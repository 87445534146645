import * as React from 'react';
import { ResourceFilterType } from '../../../constants/filters';
import { FilterContext } from '../../../types/contexts';
import { getResourceFilterFromCurrentLocation, updateResourceFilterParameter } from '../../../utilities/filters';
import { createFilterContextProvider } from '../filters-context-provider';

export const ResourceFilterContext = React.createContext<FilterContext<ResourceFilterType>>({
    value: undefined,
    onChange: (_value: ResourceFilterType | undefined) => void {},
});

export const ResourceFilterContextProvider = createFilterContextProvider<ResourceFilterType>(
    ResourceFilterContext,
    updateResourceFilterParameter,
    getResourceFilterFromCurrentLocation
);
