import {
    FontSizes,
    GroupedList,
    IGroup,
    IGroupHeaderProps,
    IGroupRenderProps,
    IStackTokens,
    SelectionMode,
    Stack,
    makeStyles,
} from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Status } from '../../../../models/common';
import {
    CustomizationTaskListValidationError,
    CustomizationTaskListValidationStatus,
} from '../../../../models/customization';
import { isStatusInProgress, isStatusNotStarted, isStatusUnsuccessful } from '../../../../redux/selector/common';
import { getSemanticColor } from '../../../../utilities/styles';
import Separator from '../../../common/form/separator';
import { ValidationResultSection } from '../models';
import CustomizationValidationSectionDetailsContainer, {
    ValidationSectionIcon,
} from './customization-validation-section-details';

export interface AddDevBoxFormCustomizationValidationSectionProps {
    statusForValidateCustomizationTasks: Status;
    validationResult: CustomizationTaskListValidationStatus | undefined;
    errors?: CustomizationTaskListValidationError[];
}

interface CustomizationValidationErrorProps {
    error: CustomizationTaskListValidationError;
}

interface CustomizationValidationTextProps {
    value: string;
    statusForValidateCustomizationTasks: Status;
    validationResult: CustomizationTaskListValidationStatus | undefined;
}

interface CustomizationValidationErrorDetailsProps {
    onRenderErrorDetails: (errors: CustomizationTaskListValidationError[]) => void;
    item?: ValidationResultSection;
}

interface CustomizationValidationErrorCodeAndDetailsProps {
    code: string;
    message: string;
}

const useGroupListStyles = makeStyles((theme) => ({
    expandedValidationResult: {
        backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
        color: getSemanticColor(theme, 'expandedCustomizationTaskText'),
        padding: '4px 8px 10px 32px',
    },
    validationResultDetails: {
        fontSize: FontSizes.size12,
        wordBreak: 'break-all',
    },
}));

const messages = defineMessages({
    addDevBoxPanelCustomizationsValidatingTasksText: {
        id: 'AddDevBoxPanelCustomizationsValidationSection_ValidatingTasks_Text',
        defaultMessage: 'Validating customization tasks. Please wait...',
        description: 'Text for when customization tasks validation is in progress',
    },
    addDevBoxPanelCustomizationsValidationCompleteSuccessText: {
        id: 'AddDevBoxPanelCustomizationsValidationSection_ValidationCompleteSuccess_Text',
        defaultMessage: 'Customization task validation success',
        description: 'Text for customization task validation complete and successfully passing validation',
    },
    addDevBoxPanelCustomizationsValidationCompleteErrorText: {
        id: 'AddDevBoxPanelCustomizationsValidationSection_ValidationCompleteError_Text',
        defaultMessage: 'Customization task validation failed',
        description: 'Text for customization task validation complete with validation errors',
    },
    addDevBoxPanelCustomizationsValidationFailedText: {
        id: 'AddDevBoxPanelCustomizationsValidationSection_ValidationFailed_Text',
        defaultMessage:
            'Validation failed for customization tasks. You can create this dev box, but expect customization issues.',
        description: 'Text for customization task validation failure',
    },
});

const containerTokens: IStackTokens = { childrenGap: 16 };
const detailsTokens: IStackTokens = { childrenGap: 8 };

const CustomizationValidationText: React.FC<CustomizationValidationTextProps> = (
    props: CustomizationValidationTextProps
) => {
    const { value, statusForValidateCustomizationTasks, validationResult } = props;

    return (
        <Stack tokens={containerTokens}>
            <Separator />
            <Stack horizontal tokens={containerTokens}>
                <Stack.Item>
                    <ValidationSectionIcon
                        statusForValidateCustomizations={statusForValidateCustomizationTasks}
                        validationStatus={validationResult}
                    />
                </Stack.Item>
                <Stack.Item>{value}</Stack.Item>
            </Stack>
        </Stack>
    );
};

const CustomizationValidationErrorCodeAndDetails: React.FC<CustomizationValidationErrorCodeAndDetailsProps> = (
    props: CustomizationValidationErrorCodeAndDetailsProps
) => {
    const { code, message } = props;

    const codeAndMessageValues = React.useMemo(
        () => ({
            code,
            message,
        }),
        [code, message]
    );

    return (
        <div>
            <FormattedMessage
                id="AddDevBoxPanelCustomizationsValidationSection_ErrorDetailsCodeAndMessage_Text"
                defaultMessage="{code}: {message}"
                description="Error code and message for customization task that failed. {code} and {message} should not be localized."
                values={codeAndMessageValues}
            />
        </div>
    );
};

export const CustomizationValidationError: React.FC<CustomizationValidationErrorProps> = (
    props: CustomizationValidationErrorProps
) => {
    const { error } = props;

    // Style hooks
    const groupListStyles = useGroupListStyles();

    const errorTargetValues = React.useMemo(
        () => ({
            taskName: error.target.name,
        }),
        [error]
    );

    return (
        <Stack tokens={detailsTokens} className={groupListStyles.validationResultDetails}>
            <Stack.Item>
                <FormattedMessage
                    id="AddDevBoxPanelCustomizationsValidationSection_ErrorTarget_Text"
                    defaultMessage="- target: {taskName}"
                    description="Task name for a customization task that failed. {taskName} should not be localized."
                    values={errorTargetValues}
                />
                <Stack.Item>
                    <FormattedMessage
                        id="AddDevBoxPanelCustomizationsValidationSection_ErrorDetails_Text"
                        defaultMessage="- details:"
                        description="Text to preface details of customization tasks that failed validation."
                    />
                </Stack.Item>
                <Stack tokens={detailsTokens} className={groupListStyles.expandedValidationResult}>
                    {error.details.map((detail, key) => (
                        <CustomizationValidationErrorCodeAndDetails
                            key={key}
                            code={detail.code}
                            message={detail.message}
                        />
                    ))}
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export const CustomizationValidationErrorDetails: React.FC<CustomizationValidationErrorDetailsProps> = (
    props: CustomizationValidationErrorDetailsProps
) => {
    const { item, onRenderErrorDetails } = props;

    // Style hooks
    const groupListStyles = useGroupListStyles();

    if (item?.errors) {
        return <div className={groupListStyles.expandedValidationResult}>{onRenderErrorDetails(item.errors)}</div>;
    }

    return <></>;
};

export const AddDevBoxFormCustomizationValidationSection: React.FC<AddDevBoxFormCustomizationValidationSectionProps> = (
    props: AddDevBoxFormCustomizationValidationSectionProps
) => {
    const { statusForValidateCustomizationTasks, validationResult, errors } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const title = React.useMemo(() => {
        if (isStatusNotStarted(statusForValidateCustomizationTasks)) {
            return '';
        }

        if (isStatusInProgress(statusForValidateCustomizationTasks)) {
            return formatMessage(messages.addDevBoxPanelCustomizationsValidatingTasksText);
        }

        if (isStatusUnsuccessful(statusForValidateCustomizationTasks)) {
            return formatMessage(messages.addDevBoxPanelCustomizationsValidationFailedText);
        }

        return validationResult === CustomizationTaskListValidationStatus.Failed
            ? formatMessage(messages.addDevBoxPanelCustomizationsValidationCompleteErrorText)
            : formatMessage(messages.addDevBoxPanelCustomizationsValidationCompleteSuccessText);
    }, [formatMessage, statusForValidateCustomizationTasks, validationResult]);

    const customizationsValidationResult: ValidationResultSection = React.useMemo(() => {
        return { title, errors };
    }, [title, errors]);

    /* NOTE: 
        This "items" array will always be an array of exactly one item since GroupedList only takes 
        in an array but we only have one item we want to display
    */
    const items: ValidationResultSection[] = React.useMemo(
        () => [customizationsValidationResult],
        [customizationsValidationResult]
    );

    const validationResultSection: IGroup[] = React.useMemo(() => {
        return items.map((item, index) => ({
            count: 1,
            key: item.title,
            name: item.title,
            startIndex: index,
            isCollapsed: true,
        }));
    }, [items]);

    const onRenderErrorDetails = React.useCallback((errors: CustomizationTaskListValidationError[]) => {
        return (
            <>
                {errors.map((error, index) => (
                    <CustomizationValidationError key={index} error={error} />
                ))}
            </>
        );
    }, []);

    const onRenderCell = React.useCallback(
        (_nestingDepth?: number, item?: ValidationResultSection) => {
            return <CustomizationValidationErrorDetails item={item} onRenderErrorDetails={onRenderErrorDetails} />;
        },
        [onRenderErrorDetails]
    );

    const onRenderHeader = React.useCallback(
        (props?: IGroupHeaderProps): JSX.Element => (
            <CustomizationValidationSectionDetailsContainer
                groupHeaderProps={props}
                customizationsValidation={customizationsValidationResult}
            />
        ),
        [customizationsValidationResult]
    );

    const groupProps: IGroupRenderProps = React.useMemo(() => {
        return { onRenderHeader };
    }, [onRenderHeader]);

    if (isStatusNotStarted(statusForValidateCustomizationTasks)) {
        return <></>;
    }

    if (validationResult !== CustomizationTaskListValidationStatus.Failed) {
        return (
            <CustomizationValidationText
                value={title}
                statusForValidateCustomizationTasks={statusForValidateCustomizationTasks}
                validationResult={validationResult}
            />
        );
    }

    return (
        <Stack tokens={containerTokens}>
            <Separator />
            <GroupedList
                items={items}
                onRenderCell={onRenderCell}
                groups={validationResultSection}
                compact
                selectionMode={SelectionMode.none}
                groupProps={groupProps}
            />
        </Stack>
    );
};

export default AddDevBoxFormCustomizationValidationSection;
