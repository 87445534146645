import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListProjectEnvironmentTypesResponse } from '../../../data/services/resource-manager/project-environment-type';
import {
    AggregatedSuccess,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverProjectEnvironmentTypesAbilities,
    discoverProjectEnvironmentTypesAbilitiesError,
    discoverProjectEnvironmentTypesAbilitiesFailed,
    discoverProjectEnvironmentTypesAbilitiesSuccess,
    getProjectEnvironmentTypeAbilities,
} from '../../actions/project-environment-type-abilities/project-environment-type-abilities-action-creators';
import { DiscoverProjectEnvironmentTypesAbilitiesAction } from '../../actions/project-environment-type-abilities/project-environment-type-abilities-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getIdsForProjectEnvironmentTypesFromDataplane } from './selectors';

export function* discoverProjectEnvironmentTypesAbilitiesSaga(
    action: DiscoverProjectEnvironmentTypesAbilitiesAction
): SagaIterator {
    const startTime = new Date();

    try {
        const projectEnvironmentTypeIds: string[] = yield select(getIdsForProjectEnvironmentTypesFromDataplane);

        // Exit immediately if no resource IDs are available
        if (projectEnvironmentTypeIds.length < 1) {
            yield put(discoverProjectEnvironmentTypesAbilitiesSuccess());
            yield resolveAction(action, AggregatedSuccess());
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DiscoverProjectEnvironmentTypesAbilities,
                startTime,
                AsyncOutcome.Success
            );
            return;
        }

        const responses: ListProjectEnvironmentTypesResponse[] = yield all(
            projectEnvironmentTypeIds.map((id) =>
                putAndAwait(
                    getProjectEnvironmentTypeAbilities({
                        id,
                    })
                )
            )
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(
                FailureOperation.DiscoverProjectEnvironmentTypesAbilities,
                ...failures
            );
            yield put(discoverProjectEnvironmentTypesAbilitiesFailed({ failure }));
        } else {
            yield put(discoverProjectEnvironmentTypesAbilitiesSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverProjectEnvironmentTypesAbilities,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.DiscoverProjectEnvironmentTypesAbilities
        );
        yield put(discoverProjectEnvironmentTypesAbilitiesError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverProjectEnvironmentTypesAbilities,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverProjectEnvironmentTypesAbilitiesListenerSaga(): SagaIterator {
    yield takeLeading(discoverProjectEnvironmentTypesAbilities, discoverProjectEnvironmentTypesAbilitiesSaga);
}
