import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListEnvironmentDefinitionsResponse } from '../../../data/services/data-plane-api/environment-definition';
import {
    AggregatedPartialSuccess,
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses, createFailureResponseFromThrown } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverEnvironmentDefinitionsForProjects,
    discoverEnvironmentDefinitionsForProjectsError,
    discoverEnvironmentDefinitionsForProjectsFailed,
    discoverEnvironmentDefinitionsForProjectsSuccess,
    listEnvironmentDefinitions,
} from '../../actions/environment-definition/environment-definition-action-creators';
import { DiscoverEnvironmentDefinitionsForProjectsAction } from '../../actions/environment-definition/environment-definition-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getHasEnvironmentDefinitions } from '../../selector/environment-definition-selectors';
import { AsyncOutcome } from '../../store/common-state';
import { getIdsForProjectsRequiringEnvironmentDefinitions } from './selectors';

// Note: this sub-operation pattern allows us to conditionally throw based on whether we have any environment definitions
export function* performDiscoverEnvironmentDefinitionsForProjects(): SagaIterator<AggregatedResult> {
    try {
        const environmentDefinitionsResult: AggregatedResult = yield putAndAwait(
            discoverEnvironmentDefinitionsForProjects()
        );

        return environmentDefinitionsResult;
    } catch (err) {
        // If we have already successfully loaded some environment definitions during card content discovery,
        // we don't want to fail discovery for environment definitions for projects even if we throw an internal error.
        const hasEnvironmentDefinitionsForEnvironments: boolean = yield select(getHasEnvironmentDefinitions);

        if (hasEnvironmentDefinitionsForEnvironments) {
            const failure = createFailureResponseFromThrown(
                err,
                FailureOperation.DiscoverEnvironmentDefinitionsForProjects
            );

            return AggregatedPartialSuccess(failure);
        }

        throw err;
    }
}

export function* discoverEnvironmentDefinitionsForProjectsSaga(
    action: DiscoverEnvironmentDefinitionsForProjectsAction
): SagaIterator {
    const startTime = new Date();

    try {
        // Figure out what projects we need to list environment definitions for (only one project per dev center)
        const ids: string[] = yield select(getIdsForProjectsRequiringEnvironmentDefinitions);

        const responses: ListEnvironmentDefinitionsResponse[] = yield all(
            ids.map((id) => putAndAwait(listEnvironmentDefinitions({ id })))
        );

        const completeResult = combineResults(responses);

        // If we have already successfully loaded some environment definitions during card content discovery,
        // we don't want to fail discovery for environment definitions for projects even if all requests fail.
        const hasEnvironmentDefinitionsForEnvironments: boolean = yield select(getHasEnvironmentDefinitions);

        if (!hasEnvironmentDefinitionsForEnvironments && isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(
                FailureOperation.DiscoverEnvironmentDefinitionsForProjects,
                ...failures
            );

            yield put(discoverEnvironmentDefinitionsForProjectsFailed({ failure }));
        } else {
            yield put(discoverEnvironmentDefinitionsForProjectsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverEnvironmentDefinitionsForProjects,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.DiscoverEnvironmentDefinitionsForProjects
        );

        yield put(discoverEnvironmentDefinitionsForProjectsError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverEnvironmentDefinitionsForProjects,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverEnvironmentDefinitionsForProjectsListenerSaga(): SagaIterator {
    yield takeLeading(discoverEnvironmentDefinitionsForProjects, discoverEnvironmentDefinitionsForProjectsSaga);
}
