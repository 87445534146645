import { CommandBarButton, DirectionalHint, IContextualMenuProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FeatureFlagName } from '../../../constants/features';
import { useProjectFilterContext } from '../../../hooks/context/filters';
import { ProjectResourceMap } from '../../../models/project';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { size } from '../../../utilities/serializable-map';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    getProjectPickerItems,
    getProjectsFromDiscoveryServiceWithAtLeastOneResource,
    getProjectsWithAtLeastOneResource,
    getSelectedProjectName,
} from './selectors';

interface ProjectPickerComponentProps {
    ariaLabel: string;
    projects: ProjectResourceMap;
    selectedProject: string | undefined;
    onChange: (project: string | undefined) => void;
}

const messages = defineMessages({
    buttonDefaultText: {
        id: 'ProjectPicker_Button_DefaultText',
        defaultMessage: 'All projects',
        description: 'Button text used when all projects is currently selected.',
    },
});

/**
 * Styles
 */

const useProjectPickerStyles = makeStyles({
    root: {
        padding: '0 13px',
    },
});

const useProjectPickerMenuItemStyles = makeStyles({
    root: {
        padding: '0 8px',
    },
});

/**
 * END Styles
 */

export const ProjectPickerComponent: React.FC<ProjectPickerComponentProps> = React.memo(
    (props: ProjectPickerComponentProps) => {
        const { projects, ariaLabel, onChange, selectedProject } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Styles
        const projectPickerStyles = useProjectPickerStyles();
        const projectPickerMenuItemStyles = useProjectPickerMenuItemStyles();

        // Memoized data
        const selectedProjectName = React.useMemo(
            () =>
                isNotUndefinedOrWhiteSpace(selectedProject)
                    ? getSelectedProjectName(projects, selectedProject)
                    : undefined,
            [projects, selectedProject]
        );

        const selectedProjectText = selectedProjectName ?? formatMessage(messages.buttonDefaultText);

        const menuProps = React.useMemo<IContextualMenuProps>(
            () => ({
                directionalHint: DirectionalHint.bottomRightEdge,
                items: getProjectPickerItems(projectPickerMenuItemStyles, onChange, projects, formatMessage),
            }),
            [onChange, projectPickerMenuItemStyles, projects, formatMessage]
        );

        return (
            <CommandBarButton
                menuProps={menuProps}
                styles={projectPickerStyles}
                text={selectedProjectText}
                aria-label={ariaLabel}
            />
        );
    }
);

interface ProjectPickerContainerProps {
    ariaLabel: string;
}

export const ProjectPickerContainer: React.FC<ProjectPickerContainerProps> = (props: ProjectPickerContainerProps) => {
    const { ariaLabel } = props;

    const { value, onChange } = useProjectFilterContext();

    // Application state hooks
    const projects = useSelector(getProjectsWithAtLeastOneResource);
    const projectsFromDiscoveryService = useSelector(getProjectsFromDiscoveryServiceWithAtLeastOneResource);

    const projectsToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
        ? projectsFromDiscoveryService
        : projects;

    // If a user has only one project or less with resources, project filter should not show up
    if (size(projectsToUse) <= 1) {
        return <></>;
    }

    return (
        <ProjectPickerComponent projects={projects} ariaLabel={ariaLabel} selectedProject={value} onChange={onChange} />
    );
};

export default ProjectPickerContainer;
