import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    getRemoteConnection,
    getRemoteConnectionError,
    getRemoteConnectionFailed,
    getRemoteConnectionSuccess,
    loadRemoteConnectionsForDevBoxes,
    loadRemoteConnectionsForDevBoxesError,
    loadRemoteConnectionsForDevBoxesFailed,
    loadRemoteConnectionsForDevBoxesSuccess,
} from '../actions/remote-connection/remote-connection-action-creators';
import { remoteConnectionAdapter } from '../adapters/remote-connection-adapters';
import {
    RemoteConnectionDataStore,
    RemoteConnectionStatusStore,
    RemoteConnectionStore,
} from '../store/remote-connection-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const remoteConnectionReducer = combineReducers<RemoteConnectionStore>({
    data: createReducer(RemoteConnectionDataStore(), (builder) => {
        builder.addCase(getRemoteConnectionSuccess, (store, action) => {
            const { id, result } = getPayload(action);

            remoteConnectionAdapter.setOne(store.remoteConnections, Entity(id, result));
        });
    }),

    status: combineReducers<RemoteConnectionStatusStore>({
        getRemoteConnection: createIndexedStatusReducer({
            inProgress: getRemoteConnection,
            error: getRemoteConnectionError,
            failed: getRemoteConnectionFailed,
            success: getRemoteConnectionSuccess,
        }),

        loadRemoteConnectionsForDevBoxes: createStatusReducer({
            inProgress: loadRemoteConnectionsForDevBoxes,
            error: loadRemoteConnectionsForDevBoxesError,
            failed: loadRemoteConnectionsForDevBoxesFailed,
            success: loadRemoteConnectionsForDevBoxesSuccess,
        }),
    }),
});
