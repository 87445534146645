import { ProjectContract, ProjectResourceContractProperties } from '../data/contracts/project';
import { SerializableMap } from '../types/serializable-map';
import { UnionMap } from '../types/union-map';
import { Resource, ResourceProvisioningState } from './resource-manager';

/**
 * Application models
 */

export type Project = ProjectContract;

export type ProjectResourceProperties = Omit<ProjectResourceContractProperties, 'provisioningState'> & {
    provisioningState: ResourceProvisioningState;
};

export type ProjectResource = Resource<ProjectResourceProperties>;

/**
 * Map types
 */

export type ProjectDataPlaneIdMap = SerializableMap<string>;
export type ProjectResourceMap = SerializableMap<ProjectResource>;
export type ProjectToDevCenterNameMap = SerializableMap<string>;

// TODO: add arguments to function a la serializable-map if they're ever needed
export const ProjectDataPlaneIdMap = (): ProjectDataPlaneIdMap => ({});
export const ProjectResourceMap = (): ProjectResourceMap => ({});
export const ProjectToDevCenterNameMap = (): ProjectToDevCenterNameMap => ({});

/**
 * Type guards
 */

export const isProjectResourceProperties = (value: unknown): value is ProjectResourceProperties => {
    const properties = value as ProjectResourceProperties;

    return (
        properties.description !== undefined &&
        properties.devCenterId !== undefined &&
        properties.devCenterUri !== undefined &&
        properties.provisioningState !== undefined
    );
};

export type ProjectFromDiscoveryService = {
    abilitiesAsAdmin: ProjectAbilityAsAdmin[];
    abilitiesAsDeveloper: ProjectAbilityAsDeveloper[];
    description: string;
    devCenterUri: string;
    displayName: string;
    name: string;
    uri: string;
    maxDevBoxesPerUser?: number;
};

type ProjectAbilityAsDeveloper =
    | 'CustomizeDevBoxes'
    | 'DeleteDevBoxes'
    | 'DeleteEnvironments'
    | 'ManageDevBoxActions'
    | 'ManageEnvironmentActions'
    | 'ReadDevBoxActions'
    | 'ReadDevBoxes'
    | 'ReadEnvironmentActions'
    | 'ReadEnvironmentOutputs'
    | 'ReadEnvironments'
    | 'ReadRemoteConnections'
    | 'StartDevBoxes'
    | 'StopDevBoxes'
    | 'WriteDevBoxes'
    | 'WriteEnvironments';

type ProjectAbilityAsAdmin =
    | 'DeleteDevBoxes'
    | 'DeleteEnvironments'
    | 'ManageEnvironmentActions'
    | 'ReadDevBoxes'
    | 'ReadEnvironmentActions'
    | 'ReadEnvironmentOutputs'
    | 'ReadEnvironments'
    | 'StartDevBoxes'
    | 'StopDevBoxes'
    | 'WriteDevBoxes'
    | 'WriteEnvironments';

export const ProjectAbilityAsDeveloper: UnionMap<ProjectAbilityAsDeveloper> = {
    CustomizeDevBoxes: 'CustomizeDevBoxes',
    DeleteDevBoxes: 'DeleteDevBoxes',
    DeleteEnvironments: 'DeleteEnvironments',
    ManageDevBoxActions: 'ManageDevBoxActions',
    ManageEnvironmentActions: 'ManageEnvironmentActions',
    ReadDevBoxActions: 'ReadDevBoxActions',
    ReadDevBoxes: 'ReadDevBoxes',
    ReadEnvironmentActions: 'ReadEnvironmentActions',
    ReadEnvironmentOutputs: 'ReadEnvironmentOutputs',
    ReadEnvironments: 'ReadEnvironments',
    ReadRemoteConnections: 'ReadRemoteConnections',
    StartDevBoxes: 'StartDevBoxes',
    StopDevBoxes: 'StopDevBoxes',
    WriteDevBoxes: 'WriteDevBoxes',
    WriteEnvironments: 'WriteEnvironments',
};

export const ProjectAbilityAsAdmin: UnionMap<ProjectAbilityAsAdmin> = {
    DeleteDevBoxes: 'DeleteDevBoxes',
    DeleteEnvironments: 'DeleteEnvironments',
    ManageEnvironmentActions: 'ManageEnvironmentActions',
    ReadDevBoxes: 'ReadDevBoxes',
    ReadEnvironmentActions: 'ReadEnvironmentActions',
    ReadEnvironmentOutputs: 'ReadEnvironmentOutputs',
    ReadEnvironments: 'ReadEnvironments',
    StartDevBoxes: 'StartDevBoxes',
    StopDevBoxes: 'StopDevBoxes',
    WriteDevBoxes: 'WriteDevBoxes',
    WriteEnvironments: 'WriteEnvironments',
};
