import { ContextualMenuItemType, DirectionalHint, IContextualMenuProps, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ClientDownloadLink } from '../../../constants/avd';
import { OperatingSystemFamily } from '../../../constants/browser';
import { openInNewWindow, openInSameWindow } from '../../../utilities/browser';
import {
    getContextualMenuItem,
    getDefaultDownloadLink,
    getIconPropsForDownloadPrimaryButton,
    getMessageDescriptorForDownloadPrimaryButton,
    getMessageDescriptorForDownloadPrimaryButtonAriaLabel,
} from './selectors';

interface DownloadRemoteDesktopButtonProps {
    operatingSystemFamily: OperatingSystemFamily;
}

const messages = defineMessages({
    downloadAndroidOption: {
        id: 'DownloadRemoteDesktopButton_DownloadAndroidOption_Text',
        defaultMessage: 'Remote Desktop for Android',
        description: 'Text for dropdown button option to download a client for Android',
    },
    downloadAndroidOptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadAndroidOption_AriaLabel',
        defaultMessage: 'Remote Desktop for Android',
        description: 'Aria label for dropdown button option to download a client for Android',
    },
    downloadIOSOption: {
        id: 'DownloadRemoteDesktopButton_DownloadIOSOption_Text',
        defaultMessage: 'Remote Desktop for iOS',
        description: 'Text for dropdown button option to download a client for iOS',
    },
    downloadIOSOptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadIOSOption_AriaLabel',
        defaultMessage: 'Remote Desktop for iOS',
        description: 'Aria label for dropdown button option to download a client for iOS',
    },
    downloadMacOption: {
        id: 'DownloadRemoteDesktopButton_DownloadMacOption_Text',
        defaultMessage: 'Remote Desktop for Mac',
        description: 'Text for dropdown button option to download a client for Mac',
    },
    downloadMacOptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadMacOption_AriaLabel',
        defaultMessage: 'Remote Desktop for Mac',
        description: 'Aria label for dropdown button option to download a client for Mac',
    },
    downloadWindowsARM64Option: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsARM64Option_Text',
        defaultMessage: 'Download Windows ARM 64',
        description: 'Text for dropdown button option to download a client for Windows ARM 64',
    },
    downloadWindowsARM64OptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsARM64Option_AriaLabel',
        defaultMessage: 'Download Windows ARM 64',
        description: 'Aria label for dropdown button option to download a client for Windows ARM 64',
    },
    downloadWindowsX64Option: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsX64Option_Text',
        defaultMessage: 'Download Windows 64 bit',
        description: 'Text for dropdown button option to download a client for Windows 64 bit (x64)',
    },
    downloadWindowsX64OptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsX64Option_AriaLabel',
        defaultMessage: 'Download Windows 64 bit',
        description: 'Aria label for dropdown button option to download a client for Windows 64 bit (x64)',
    },
    downloadWindowsX86Option: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsX86Option_Text',
        defaultMessage: 'Download Windows 32 bit',
        description: 'Text for dropdown button option to download a client for Windows 32 bit (x86)',
    },
    downloadWindowsX86OptionAriaLabel: {
        id: 'DownloadRemoteDesktopButton_DownloadWindowsX86Option_AriaLabel',
        defaultMessage: 'Download Windows 32 bit',
        description: 'Aria label for dropdown button option to download a client for Windows 32 bit (x86)',
    },
    splitButtonAriaLabel: {
        id: 'DownloadRemoteDesktopButton_SplitButton_AriaLabel',
        defaultMessage: 'See more options',
        description: 'Aria label for dropdown button in open split button for dev box cards',
    },
});

// Windows client download links are opened in same window because they're direct file downloads
const onDownloadWindowsARM64Clicked = (): void => openInSameWindow(ClientDownloadLink.WindowsARM64);
const onDownloadWindowsX64Clicked = (): void => openInSameWindow(ClientDownloadLink.WindowsX64);
const onDownloadWindowsX86Clicked = (): void => openInSameWindow(ClientDownloadLink.WindowsX86);

// Non-windows download links are opened in separate windows because they render a different page
const onDownloadAndroidClicked = (): void => openInNewWindow(ClientDownloadLink.Android);
const onDownloadIOSClicked = (): void => openInNewWindow(ClientDownloadLink.IOS);
const onDownloadMacClicked = (): void => openInNewWindow(ClientDownloadLink.Mac);

export const DownloadRemoteDesktopButton: React.FC<DownloadRemoteDesktopButtonProps> = (
    props: DownloadRemoteDesktopButtonProps
) => {
    const { operatingSystemFamily } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    const defaultDownloadLink = React.useMemo(
        () => getDefaultDownloadLink(operatingSystemFamily),
        [operatingSystemFamily]
    );

    const iconProps = React.useMemo(
        () => getIconPropsForDownloadPrimaryButton(operatingSystemFamily),
        [operatingSystemFamily]
    );

    const menuProps = React.useMemo<IContextualMenuProps>(() => {
        const androidOption = getContextualMenuItem(
            formatMessage(messages.downloadAndroidOptionAriaLabel),
            'download-android',
            onDownloadAndroidClicked,
            formatMessage(messages.downloadAndroidOption)
        );

        const iOSOption = getContextualMenuItem(
            formatMessage(messages.downloadIOSOptionAriaLabel),
            'download-ios',
            onDownloadIOSClicked,
            formatMessage(messages.downloadIOSOption)
        );

        const macOption = getContextualMenuItem(
            formatMessage(messages.downloadMacOptionAriaLabel),
            'download-mac',
            onDownloadMacClicked,
            formatMessage(messages.downloadMacOption)
        );

        const windowsARM64Option = getContextualMenuItem(
            formatMessage(messages.downloadWindowsARM64OptionAriaLabel),
            'download-windows-arm64',
            onDownloadWindowsARM64Clicked,
            formatMessage(messages.downloadWindowsARM64Option)
        );

        const windowsX64Option = getContextualMenuItem(
            formatMessage(messages.downloadWindowsX64OptionAriaLabel),
            'download-windows-x64',
            onDownloadWindowsX64Clicked,
            formatMessage(messages.downloadWindowsX64Option)
        );

        const windowsX86Option = getContextualMenuItem(
            formatMessage(messages.downloadWindowsX86OptionAriaLabel),
            'download-windows-x86',
            onDownloadWindowsX86Clicked,
            formatMessage(messages.downloadWindowsX86Option)
        );

        return {
            directionalHint: DirectionalHint.bottomRightEdge,
            items: [
                windowsX64Option,
                windowsX86Option,
                windowsARM64Option,
                {
                    key: 'divider',
                    itemType: ContextualMenuItemType.Divider,
                    text: '-',
                },
                macOption,
                iOSOption,
                androidOption,
            ],
        };
    }, [formatMessage]);

    const primaryButtonAriaLabelMessageDescriptor = React.useMemo(
        () => getMessageDescriptorForDownloadPrimaryButtonAriaLabel(operatingSystemFamily),
        [operatingSystemFamily]
    );

    const primaryButtonMessageDescriptor = React.useMemo(
        () => getMessageDescriptorForDownloadPrimaryButton(operatingSystemFamily),
        [operatingSystemFamily]
    );

    // Callback hooks
    const onPrimaryButtonClicked = React.useCallback(() => {
        openInSameWindow(defaultDownloadLink);
    }, [defaultDownloadLink]);

    return (
        <PrimaryButton
            ariaLabel={formatMessage(primaryButtonAriaLabelMessageDescriptor)}
            iconProps={iconProps}
            menuProps={menuProps}
            onClick={onPrimaryButtonClicked}
            split
            splitButtonAriaLabel={formatMessage(messages.splitButtonAriaLabel)}
            text={formatMessage(primaryButtonMessageDescriptor)}
        />
    );
};
