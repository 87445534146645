import {
    FontSizes,
    FontWeights,
    IContextualMenuItem,
    IStackTokens,
    makeStyles,
    Stack,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDynamicMakeStyles } from '../../hooks/styling';
import { CssSelector } from '../../themes/constants/css-selectors';
import { getSemanticColor } from '../../utilities/styles';
import MetadataList, { ShimmeredMetadataList } from '../common/metadata/metadata-list';
import { MetadataItemViewModel, ShimmeredMetadataItemViewModel } from '../common/metadata/models';
import { CardBanner } from './card-banner';
import { ResourceCardBannerViewModel } from './models';
import PrimaryActionsButton from './primary-actions-button';
import SecondaryActionsButton from './secondary-actions-button';

interface ResourceCardProps {
    arePrimaryActionsDisabled?: boolean;
    areSecondaryActionsDisabled?: boolean;
    metadata?: MetadataItemViewModel[];
    name: string;
    stateElement: React.ReactNode;
    statusImageElement?: React.ReactNode;
    previewImageSrc: string;
    projectName: string;
    primaryActions?: IContextualMenuItem[];
    secondaryActions?: IContextualMenuItem[];
    useTranslucentPreviewImage?: boolean;
    displayStateAtTheBottom?: boolean;
    isShimmered?: boolean;
    shimmeredMetadata?: ShimmeredMetadataItemViewModel[];
    cardBanner?: ResourceCardBannerViewModel;
}

interface ResourceCardPreviewImageProps {
    name: string;
    previewImageSrc: string;
    useTranslucentPreviewImage?: boolean;
    statusImageElement?: React.ReactNode;
}

interface ResourceCardNameDisplayProps {
    name: string;
    projectName: string;
}

interface ResourceCardFooterProps {
    arePrimaryActionsDisabled?: boolean;
    stateElement: React.ReactNode;
    primaryActions?: IContextualMenuItem[];
    displayStateAtTheBottom?: boolean;
}

interface ResourceCardBannerProps {
    useTranslucentPreviewImage?: boolean;
    cardBanner?: ResourceCardBannerViewModel;
}

const messages = defineMessages({
    previewImageAlt: {
        id: 'ResourceCard_PreviewImage_Alt',
        defaultMessage: 'Preview for "{name}"',
        description: 'Alt text for preview image. {name} should not be localized.',
    },
});

/**
 * Style Section
 */

const resourceCardStylesFactory = (useTranslucentPreviewImage?: boolean) =>
    makeStyles((theme) => ({
        bannerContainer: {
            position: 'absolute',
            textAlign: 'left',
            width: '100%',
            zIndex: 1,
        },
        previewImage: {
            height: '100%',
            // objectFit: 'cover' downscales image without warping aspect ratio
            objectFit: 'cover',
            opacity: useTranslucentPreviewImage ? '50%' : '100%',
            width: '100%',
        },
        root: {
            backgroundColor: getSemanticColor(theme, 'resourceCardBackground'),
            borderRadius: 4,
            // Using hidden overflow will ensure preview image doesn't cover rounded corners
            overflow: 'hidden',
            position: 'relative',
            height: 392,
            width: 256,

            [CssSelector.ScreenSizeSmallAndBelow]: {
                width: '100%',
            },
        },
        statusImageContainer: {
            bottom: 6,
            left: 24,
            position: 'absolute',
        },
    }));

const stateContainerStylesFactory = (useFooterOffset?: boolean) =>
    makeStyles({
        root: {
            fontSize: FontSizes.size12,
            lineHeight: 16,
            ...(useFooterOffset ? { marginBottom: 10 } : {}),
        },
    });

const useContentStyles = makeStyles({
    root: {
        padding: '14px 8px 12px 24px',
        textAlign: 'left',
    },
});

const useHeaderContainerStyles = makeStyles({
    root: {
        marginBottom: '8px',
    },
});

const useHeaderStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const useNameColumnStyles = makeStyles({
    root: {
        // Bug #1548954: setting a min width value for this element allows it to shrink. Without this value, the element
        // will ALWAYS be the width of the unwrapped contained text, so long dev box names won't be truncated with ...
        //      https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1548954
        minWidth: 0,
    },
});

const useNameStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: 22,
    },
});

const useNameTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const usePreviewStyles = makeStyles({
    root: {
        height: 134,
        // Explicitly assigning relative positioning so status can overlay it
        position: 'relative',
        width: '100%',
    },
});

const useProjectNameStyles = makeStyles({
    root: {
        fontSize: FontSizes.size10,
        fontWeight: FontWeights.semibold,
        lineHeight: 14,
    },
});

/**
 * END Styles
 */

const contentTokens: IStackTokens = {
    childrenGap: 8,
};

const footerTokens: IStackTokens = {
    childrenGap: 15,
};

const headerTokens: IStackTokens = {
    childrenGap: 8,
};

const nameColumnTokens: IStackTokens = {
    childrenGap: 2,
};

const ResourceCardPreviewImage: React.FC<ResourceCardPreviewImageProps> = React.memo(
    (props: ResourceCardPreviewImageProps) => {
        const { name, previewImageSrc, useTranslucentPreviewImage, statusImageElement } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const previewStyles = usePreviewStyles();
        const useResourceCardStyles = useDynamicMakeStyles(resourceCardStylesFactory, useTranslucentPreviewImage);
        const resourceCardStyles = useResourceCardStyles();

        return (
            <Stack.Item styles={previewStyles}>
                <img
                    alt={formatMessage(messages.previewImageAlt, { name })}
                    className={resourceCardStyles.previewImage}
                    src={previewImageSrc}
                />

                {statusImageElement && (
                    <span className={resourceCardStyles.statusImageContainer}>{statusImageElement}</span>
                )}
            </Stack.Item>
        );
    }
);

const ResourceCardNameDisplay: React.FC<ResourceCardNameDisplayProps> = React.memo(
    (props: ResourceCardNameDisplayProps) => {
        const { name, projectName } = props;

        // Style hooks
        const nameColumnStyles = useNameColumnStyles();
        const nameStyles = useNameStyles();
        const nameTooltipHostStyles = useNameTooltipHostStyles();
        const projectNameStyles = useProjectNameStyles();

        return (
            <Stack.Item grow={1} styles={nameColumnStyles}>
                <Stack tokens={nameColumnTokens}>
                    <Stack.Item styles={nameStyles}>
                        <TooltipHost
                            content={name}
                            overflowMode={TooltipOverflowMode.Self}
                            styles={nameTooltipHostStyles}
                        >
                            {name}
                        </TooltipHost>
                    </Stack.Item>

                    <Stack.Item styles={projectNameStyles}>{projectName}</Stack.Item>
                </Stack>
            </Stack.Item>
        );
    }
);

const ResourceCardHeader: React.FC<React.PropsWithChildren<unknown>> = React.memo(
    (props: React.PropsWithChildren<unknown>) => {
        const { children } = props;

        const headerContainerStyles = useHeaderContainerStyles();
        const headerStyles = useHeaderStyles();

        return (
            <Stack.Item styles={headerContainerStyles}>
                <Stack horizontal styles={headerStyles} tokens={headerTokens} verticalAlign="start">
                    {children}
                </Stack>
            </Stack.Item>
        );
    }
);

const ResourceCardContent: React.FC<React.PropsWithChildren<unknown>> = React.memo(
    (props: React.PropsWithChildren<unknown>) => {
        const { children } = props;

        const contentStyles = useContentStyles();

        return (
            <Stack.Item grow={1}>
                <Stack styles={contentStyles} tokens={contentTokens} verticalFill>
                    {children}
                </Stack>
            </Stack.Item>
        );
    }
);

export const ResourceCardState: React.FC<ResourceCardFooterProps> = React.memo((props: ResourceCardFooterProps) => {
    const { displayStateAtTheBottom, stateElement, primaryActions, arePrimaryActionsDisabled } = props;

    const showPrimaryActionsButton = primaryActions && primaryActions.length > 0;

    const useStateContainerStyles = useDynamicMakeStyles(stateContainerStylesFactory, !showPrimaryActionsButton);
    const stateContainerStyles = useStateContainerStyles();

    return (
        <>
            {!displayStateAtTheBottom && <Stack.Item styles={stateContainerStyles}>{stateElement}</Stack.Item>}

            {/* 
                NOTE: primary actions in this separate stack allows for auto-spacing that
                pushes these elements to the bottom of the card.
            */}
            <Stack.Item grow={1}>
                <Stack tokens={footerTokens} verticalAlign="end" verticalFill>
                    {displayStateAtTheBottom && <Stack.Item styles={stateContainerStyles}>{stateElement}</Stack.Item>}

                    {showPrimaryActionsButton && (
                        <Stack.Item align="end">
                            <PrimaryActionsButton actions={primaryActions} disabled={arePrimaryActionsDisabled} />
                        </Stack.Item>
                    )}
                </Stack>
            </Stack.Item>
        </>
    );
});

export const ResourceCardBanner: React.FC<ResourceCardBannerProps> = React.memo((props: ResourceCardBannerProps) => {
    const { useTranslucentPreviewImage, cardBanner } = props;

    const useResourceCardStyles = useDynamicMakeStyles(resourceCardStylesFactory, useTranslucentPreviewImage);
    const resourceCardStyles = useResourceCardStyles();

    if (cardBanner) {
        return (
            <div className={resourceCardStyles.bannerContainer}>
                <CardBanner cardBanner={cardBanner} />
            </div>
        );
    }

    return <></>;
});

export const ResourceCard: React.FC<ResourceCardProps> = React.memo((props: ResourceCardProps) => {
    const {
        areSecondaryActionsDisabled,
        metadata,
        name,
        statusImageElement,
        previewImageSrc,
        projectName,
        secondaryActions,
        useTranslucentPreviewImage,
        isShimmered,
        shimmeredMetadata,
    } = props;

    const showSecondaryActionsButton = secondaryActions && secondaryActions.length > 0;

    // Style hooks
    const useResourceCardStyles = useDynamicMakeStyles(resourceCardStylesFactory, useTranslucentPreviewImage);
    const resourceCardStyles = useResourceCardStyles();

    return (
        <div className={resourceCardStyles.root}>
            {!isShimmered && <ResourceCardBanner {...props} />}

            <Stack verticalFill>
                <ResourceCardPreviewImage
                    name={name}
                    previewImageSrc={previewImageSrc}
                    useTranslucentPreviewImage={useTranslucentPreviewImage}
                    statusImageElement={statusImageElement}
                />

                <ResourceCardContent>
                    <ResourceCardHeader>
                        <ResourceCardNameDisplay name={name} projectName={projectName} />

                        {!isShimmered && showSecondaryActionsButton && (
                            <Stack.Item>
                                <SecondaryActionsButton
                                    disabled={areSecondaryActionsDisabled}
                                    actions={secondaryActions}
                                    resourceName={name}
                                />
                            </Stack.Item>
                        )}
                    </ResourceCardHeader>

                    {isShimmered ? (
                        <ShimmeredMetadataList metadataList={shimmeredMetadata} />
                    ) : (
                        <MetadataList metadataList={metadata} containerTokens={8} />
                    )}

                    {!isShimmered && <ResourceCardState {...props} />}
                </ResourceCardContent>
            </Stack>
        </div>
    );
});

export default ResourceCard;
