import { createReducer } from '@reduxjs/toolkit';
import {
    setPartialUserSettings,
    setUserSettings,
    syncUserSettingsSuccess,
} from '../actions/user-settings/user-settings-action-creator';
import { UserSettingsStore } from '../store/user-settings-store';
import { getPayload } from '../utilities/payload-action';

export const userSettingsReducer = createReducer(UserSettingsStore(), (builder) => {
    builder.addCase(setUserSettings, (store, action) => {
        const userSettings = getPayload(action);
        const { themeMode, useMultiMonitor, preferredRemoteApp } = userSettings;

        store.themeMode = themeMode;
        store.useMultiMonitor = useMultiMonitor;
        store.preferredRemoteApp = preferredRemoteApp;
    });

    builder.addCase(setPartialUserSettings, (store, action) => {
        const { preferredRemoteApp, useMultiMonitor, themeMode } = getPayload(action);
        if (useMultiMonitor !== undefined) {
            store.useMultiMonitor = useMultiMonitor;
        }

        if (preferredRemoteApp) {
            store.preferredRemoteApp = preferredRemoteApp;
        }

        if (themeMode) {
            store.themeMode = themeMode;
        }
    });

    builder.addCase(syncUserSettingsSuccess, (store, action) => {
        const { result } = getPayload(action);

        if (!result) {
            return;
        }

        const { themeMode, useMultiMonitor, preferredRemoteApp } = result;

        store.themeMode = themeMode;
        store.useMultiMonitor = useMultiMonitor;
        store.preferredRemoteApp = preferredRemoteApp;
    });
});
