import { createSelector } from 'reselect';
import { FeatureFlagName } from '../../../constants/features';
import { getDevCenterFromDataPlaneUri } from '../../../ids/data-plane';
import { Status } from '../../../models/common';
import { ProjectFromDiscoveryService, ProjectResource } from '../../../models/project';
import { SerializableMap } from '../../../types/serializable-map';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { get, map, some } from '../../../utilities/serializable-map';
import {
    IndexedStoreStateSelector,
    StoreStateSelector,
    forwardIdToSelector,
    isStatusTerminal,
    isStatusUnsuccessful,
} from '../common';
import { getFailedToWarmDevCenterEndpoints } from '../dev-center-selectors';
import { getStatusesForListEnvironmentDefinitions } from '../environment-definition-selectors';
import { getStatusesForListPools } from '../pool-selectors';
import { getStatusesForListProjectEnvironmentTypesFromDataplane } from '../project-environment-type-from-dataplane-selectors';
import { getStatusesForListProjectEnvironmentTypes } from '../project-environment-type-selectors';
import { getProjectsByDiscoveryServiceURI } from '../project-from-discovery-service-selectors';
import { getProjectsByDataPlaneId } from '../project-selectors';
import { getStatusForLoadBackgroundResources } from '../sub-applications/home-selectors';

const isFailedToLoadDevBoxCreateResourcesState = (loadedState: CreateResourceLoadedState): boolean =>
    isStatusUnsuccessful(loadedState.poolsState);

const isFailedToLoadEnvironmentCreateResourcesState = (loadedState: CreateResourceLoadedState): boolean =>
    isStatusUnsuccessful(loadedState.environmentDefinitionsState) ||
    isStatusUnsuccessful(loadedState.environmentTypesState);

/**
 * Types
 */

export type CreateResourceLoadedState = {
    poolsState: Status | undefined;
    environmentDefinitionsState: Status | undefined;
    environmentTypesState: Status | undefined;
};

/**
 * Composed selectors
 */

/** Gets the loading states of project sub-resources by project for a completely loaded dev portal. Returns {} if background resource load has not completed. */
export const getCreateResourceLoadedStatesByProject: StoreStateSelector<SerializableMap<CreateResourceLoadedState>> =
    createSelector(
        [
            getStatusForLoadBackgroundResources,
            getProjectsByDataPlaneId,
            getProjectsByDiscoveryServiceURI,
            getStatusesForListPools,
            getStatusesForListEnvironmentDefinitions,
            getStatusesForListProjectEnvironmentTypes,
            getStatusesForListProjectEnvironmentTypesFromDataplane,
        ],
        (
            statusForLoadBackgroundResources,
            projects,
            projectsFromDiscoveryService,
            statusesForListPools,
            statusesForListEnvironmentDefinitions,
            statusesForListEnvironmentTypes,
            statusesForListEnvironmentTypesFromDataplane
        ) => {
            if (!isStatusTerminal(statusForLoadBackgroundResources)) {
                return SerializableMap<CreateResourceLoadedState>();
            }

            const isDiscoveryServiceEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
                ? projectsFromDiscoveryService
                : projects;

            if (isDiscoveryServiceEnabled) {
                return map(
                    projectsFromDiscoveryService,
                    (value: ProjectFromDiscoveryService): CreateResourceLoadedState => {
                        const { uri } = value;
                        const poolsState = get(statusesForListPools, uri);
                        const environmentDefinitionsState = get(statusesForListEnvironmentDefinitions, uri);
                        const environmentTypesState = get(statusesForListEnvironmentTypesFromDataplane, uri);

                        return { poolsState, environmentDefinitionsState, environmentTypesState };
                    }
                );
            }

            const loadingStates = map(
                projects,
                (value: ProjectResource, projectDataPlaneId: string): CreateResourceLoadedState => {
                    const { id: projectControlPlaneId } = value;

                    const poolsState = get(statusesForListPools, projectDataPlaneId);
                    const environmentDefinitionsState = get(statusesForListEnvironmentDefinitions, projectDataPlaneId);
                    const environmentTypesState = get(statusesForListEnvironmentTypes, projectControlPlaneId);

                    return { poolsState, environmentDefinitionsState, environmentTypesState };
                }
            );

            return loadingStates;
        }
    );

export const getDidSomeProjectFailToLoadDevBoxCreateResources: StoreStateSelector<boolean> = createSelector(
    [getCreateResourceLoadedStatesByProject],
    (projectResourceLoadedStates) => some(projectResourceLoadedStates, isFailedToLoadDevBoxCreateResourcesState)
);

export const getDidSomeProjectFailToLoadEnvironmentCreateResources: StoreStateSelector<boolean> = createSelector(
    [getCreateResourceLoadedStatesByProject],
    (projectResourceLoadedStates) => some(projectResourceLoadedStates, isFailedToLoadEnvironmentCreateResourcesState)
);

export const getDidDevCenterForProjectFailToWarm: IndexedStoreStateSelector<boolean> = createSelector(
    [getFailedToWarmDevCenterEndpoints, forwardIdToSelector],
    (endpoints, id) => endpoints.includes(getDevCenterFromDataPlaneUri(id))
);

export const getDidProjectFailToLoadDevBoxCreateResources: IndexedStoreStateSelector<boolean> = createSelector(
    [getCreateResourceLoadedStatesByProject, forwardIdToSelector],
    (projectResourceLoadedStates, id) => {
        const loadedState = get(projectResourceLoadedStates, id);
        return !loadedState || isFailedToLoadDevBoxCreateResourcesState(loadedState);
    }
);

export const getDidProjectFailToLoadEnvironmentCreateResources: IndexedStoreStateSelector<boolean> = createSelector(
    [getCreateResourceLoadedStatesByProject, forwardIdToSelector],
    (projectResourceLoadedStates, id) => {
        const loadedState = get(projectResourceLoadedStates, id);
        return !loadedState || isFailedToLoadEnvironmentCreateResourcesState(loadedState);
    }
);
