import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
    ListEnvironmentTypesResponse,
    listEnvironmentTypes,
} from '../../../data/services/data-plane-api/environment-type';
import { ClientError, FailureOperation, isSuccessResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    listProjectEnvironmentTypesFromDataplane,
    listProjectEnvironmentTypesFromDataplaneError,
    listProjectEnvironmentTypesFromDataplaneFailed,
    listProjectEnvironmentTypesFromDataplaneSuccess,
} from '../../actions/project-environment-type/project-environment-type-action-creators';
import { ListProjectEnvironmentTypesFromDataplaneAction } from '../../actions/project-environment-type/project-environment-type-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listProjectEnvironmentTypesFromDataplaneSaga(
    action: ListProjectEnvironmentTypesFromDataplaneAction
): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListEnvironmentTypesResponse = yield call(listEnvironmentTypes, id, accessToken, activityId);

        if (isSuccessResponse(response)) {
            yield put(listProjectEnvironmentTypesFromDataplaneSuccess({ id, result: response.data }, meta));
        } else {
            yield put(listProjectEnvironmentTypesFromDataplaneFailed({ failure: response, id }, meta));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.ListProjectEnvironmentTypesFromDataPlane
        );

        yield put(listProjectEnvironmentTypesFromDataplaneError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listProjectEnvironmentTypesFromDataplaneListenerSaga(): SagaIterator {
    yield takeEvery(listProjectEnvironmentTypesFromDataplane, listProjectEnvironmentTypesFromDataplaneSaga);
}
