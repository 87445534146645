import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ProjectViewModel } from '../../../models';
import ResourceDropdown, { ResourceDropdownWrapperProps } from './resource-dropdown';
import { getFinalDisplayNameForProject, getOptionKeyForIndexedModel } from './selectors';

export type DropdownProps = ResourceDropdownWrapperProps<ProjectViewModel>;

const messages = defineMessages({
    addEnvironmentFormProjectFieldDropdownDuplicateDisplayName: {
        id: 'AddEnvironmentFormProjectControl_Dropdown_DuplicateDisplayName',
        defaultMessage: '{displayName} ({resourceName})',
        description:
            'Dropdown option text to show duplicate display name. Do not localize {name}. Do not localize {resourceName}.',
    },
});

export const ProjectDropdown: React.FC<DropdownProps> = (props) => {
    // Intl hooks
    const { formatMessage } = useIntl();

    const getOptionText = React.useCallback(
        (project: ProjectViewModel) => {
            const { name: resourceName, displayName, isDisplayNameDuplicate } = project;

            const duplicateValues = { displayName, resourceName };

            const name = getFinalDisplayNameForProject(
                displayName,
                isDisplayNameDuplicate,
                resourceName,
                formatMessage(messages.addEnvironmentFormProjectFieldDropdownDuplicateDisplayName, duplicateValues)
            );
            return name;
        },
        [formatMessage]
    );

    return (
        <ResourceDropdown<ProjectViewModel>
            {...props}
            getOptionKey={getOptionKeyForIndexedModel}
            getOptionText={getOptionText}
        />
    );
};

export default ProjectDropdown;
