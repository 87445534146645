import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ClientError, FailureOperation } from '../../../models/common';
import { FeatureFlagSet } from '../../../models/features';
import { applyFeaturesToCurrentLocation } from '../../../utilities/features';
import { trackException } from '../../../utilities/telemetry/channel';
import { replaceCurrentLocation } from '../../../utilities/url';
import { setFeatures } from '../../actions/features/features-action-creators';
import { SetFeaturesAction } from '../../actions/features/features-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { takeEvery } from '../../effects/take';
import { getFrozenFeatures } from '../../selector/features-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* setFeaturesSaga(action: SetFeaturesAction): SagaIterator {
    const { features } = getPayload(action);

    try {
        // Sync location: update search string with new feature flag list (or remove if no flags left)
        const frozenFeatures: FeatureFlagSet = yield select(getFrozenFeatures);
        const newUrl: URL = yield call(applyFeaturesToCurrentLocation, features, frozenFeatures);

        yield call(replaceCurrentLocation, newUrl);
    } catch (err) {
        // No error actions for updating features - we just log the exception
        const error: ClientError = yield createSagaError(err, FailureOperation.SetFeatures);
        yield call(trackException, error);
    }
}

export function* setFeaturesListenerSaga(): SagaIterator {
    yield takeEvery(setFeatures, setFeaturesSaga);
}
