import {
    DefaultButton,
    DocumentCard,
    DocumentCardTitle,
    FontSizes,
    FontWeights,
    Link,
    makeStyles,
    Panel,
    PanelType,
    Stack,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { OperatingSystemFamily } from '../../constants/browser';
import { Language, SupportedMarket } from '../../constants/localization';
import { SupportSectionLinks } from '../../constants/support-section-links';
import { Metric, Property } from '../../constants/telemetry';
import { useConfigureRemoteDesktopDialogContext } from '../../hooks/context/dialogs';
import { useHelpMenuPanelContext } from '../../hooks/context/panels';
import messages from '../../language/messages';
import { getLanguage, getMarket } from '../../redux/selector/localization-selectors';
import { getIsUserSignedIntoMicrosoftTenant } from '../../redux/selector/tenant-selector';
import { getRemoteAppOptionsSelector } from '../../redux/selector/user-settings-selector';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { RemoteAppOption } from '../../types/user-settings';
import { getOperatingSystemFamily } from '../../utilities/browser';
import { getSemanticColor } from '../../utilities/styles';
import { getSessionID, trackMetric } from '../../utilities/telemetry/channel';
import { AriaLiveWrapper } from '../common/accessibility/aria-live-wrapper';
import { WindowsAppLink } from '../user-settings/connect-via-app-dialog/connect-via-app-download-button';

interface HelpMenuPanelProps {
    currentLanguage: Language;
    currentMarket: string;
    isOpen: boolean;
    onDismiss: () => void;
}

interface PrivacyAndTermsProps {
    currentLanguage: Language;
    currentMarket: string;
}

const helpMenuMessages = defineMessages({
    helpMenuPanelTitle: {
        id: 'HelpMenu_Panel_Title',
        defaultMessage: 'Help & feedback',
        description: 'Aria label for help menu panel title',
    },
    helpMenuPanelCloseButtonLabel: {
        id: 'HelpMenu_Panel_Close_Label',
        defaultMessage: 'Close help panel',
        description: 'Aria label for the help menu panel close button label',
    },
    helpMenuPanelNeedHelpTitle: {
        id: 'HelpMenu_Panel_NeedHelp_Submenu_Title',
        defaultMessage: 'Connect to a dev box',
        description: 'Title for the help menu panel to connect to a dev box',
    },
    helpMenuPanelFeedbackTitle: {
        id: 'HelpMenu_Panel_Feedback_Submenu_Title',
        defaultMessage: 'Have a feature suggestion?',
        description: 'Aria label for the help menu panel feedback submenu title',
    },
    helpMenuPanelSupportTitle: {
        id: 'HelpMenu_Panel_Support_Submenu_Title',
        defaultMessage: 'Need Support?',
        description: 'Title for the help menu panel support submenu title',
    },
    helpMenuInternalTitle: {
        id: 'HelpMenu_Panel_Internal_Title',
        defaultMessage: 'Microsoft internal',
        description: 'Title for the Microsoft internal help panel submenu',
    },
    copyButtonText: {
        id: 'DevBoxHelpMenu_Panel_CopyButton_Text',
        defaultMessage: 'Copy issue ID',
        description: 'Text for the "Copy" button in the "Dev Box Help Menu" panel',
    },
    copyButtonAriaLabel: {
        id: 'DevBoxHelpMenu_Panel_CopyButton_AriaLabel',
        defaultMessage: 'Copy issue ID',
        description: 'Aria label for the "Copy" button in the "Dev Box Support" panel',
    },
});

const handleTroubleshootLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelTroubleShootLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.TroubleshootAndRepair,
        },
    });
};

const handleInternalSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelInternalSupportLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.InternalSupportLink,
        },
    });
};

const handleExternalSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelExternalSupportLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.ExternalSupportLink,
        },
    });
};

const handleDevHomeSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelDevHomeLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.DevHomeLink,
        },
    });
};

const handleMultiMonitorSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelMultiMonitorLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.MultipleMonitorsLink,
        },
    });
};

const handleDevBoxFeedbackSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelDevBoxFeedbackLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.DevBoxFeedback,
        },
    });
};

const handleAdeFeedbackSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelAdeFeedbackLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.AdeFeedback,
        },
    });
};

const handlePrivacySupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelPrivacyLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.Privacy,
        },
    });
};

const handleProductTermsSupportLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelProductTermsLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.ProductTerms,
        },
    });
};

const handleOneESDevBoxFaqLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelOneESDevBoxFaqLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.OneESDevBoxFaq,
        },
    });
};

const handleWindowsAppLinkClick = () => {
    trackMetric(Metric.HelpMenuPanelWindowsAppLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsAppLink,
        },
    });
};

const getFormattedMessageValues = (href: string, onClick: () => void, target = '_blank') => {
    const values = {
        Link: (chunks: string) => (
            <Link href={href} target={target} onClick={onClick}>
                {chunks}
            </Link>
        ),
    };
    return values;
};

const internalSupportLinkValues = getFormattedMessageValues(
    SupportSectionLinks.InternalSupportLink,
    handleInternalSupportLinkClick
);
const externalSupportLinkValues = getFormattedMessageValues(
    SupportSectionLinks.ExternalSupportLink,
    handleExternalSupportLinkClick
);

/**
 * Style Section
 */
const useHelpMenuPanelStyles = makeStyles((theme) => ({
    overlay: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
    },
    main: {
        marginTop: '48px',
    },
    header: {
        marginBottom: '24px',
    },
}));

const useNeedHelpSectionStyles = makeStyles({
    root: {
        boxShadow: '0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);',
        borderRadius: '2px',
        padding: '22px',
        border: 'none',
        marginBottom: '15px',
        userSelect: 'text',
    },
    documentCardTitle: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
        lineHeight: '20px',
        padding: '0',
        height: 'auto',
    },
    documentCardLink: {
        fontSize: FontSizes.size14,
        lineHeight: '20px',
        paddingTop: '13px',
    },
    sessionIDStack: {
        paddingTop: '15px',
        fontSize: FontSizes.size14,
        lineHeight: '20px',
    },
    sessionID: {
        fontWeight: FontWeights.semibold,
    },
    copyButton: {
        margin: '8px',
    },
});

const usePrivacyTermsOfUseStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '15px',
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        color: getSemanticColor(theme, 'helpMenuPanelPrivacyTermsText'),
    },
    container: {
        textAlign: 'center',
    },
    privacyAndTermsDividerColor: {
        color: getSemanticColor(theme, 'helpMenuPanelPrivacyTermsDivider'),
    },
}));

/* END */

const NeedHelpSection: React.FC = () => {
    const { openSurface: onOpenConfigureRemoteDesktopDialog } = useConfigureRemoteDesktopDialogContext();

    const userRemoteAppOption = useSelector(getRemoteAppOptionsSelector);

    // Intl hooks
    const intl = useIntl();

    // Style
    const classNames = useNeedHelpSectionStyles();

    const isWindows = getOperatingSystemFamily() === OperatingSystemFamily.Windows;

    const isAvdPreference = userRemoteAppOption === RemoteAppOption.useAvd;

    return (
        <AriaLiveWrapper>
            <DocumentCard
                aria-label={intl.formatMessage(helpMenuMessages.helpMenuPanelTitle)}
                className={classNames.root}
            >
                <DocumentCardTitle
                    title={intl.formatMessage(helpMenuMessages.helpMenuPanelNeedHelpTitle)}
                    className={classNames.documentCardTitle}
                />

                <Stack className={classNames.documentCardLink}>
                    <Stack.Item>
                        <Link
                            href={SupportSectionLinks.DevHomeLink}
                            target="_blank"
                            onClick={handleDevHomeSupportLinkClick}
                        >
                            <FormattedMessage
                                id="HelpMenuPanel_NeedHelp_DevHomeLink"
                                defaultMessage="Use Dev Home to manage dev boxes"
                                description="Link to getting dev home information."
                            />
                        </Link>
                    </Stack.Item>
                    {isWindows && !isAvdPreference ? (
                        <Stack.Item>
                            <Link href={WindowsAppLink} target="_blank" onClick={handleWindowsAppLinkClick}>
                                <FormattedMessage
                                    id="HelpMenuPanel_NeedHelp_DownloadWindowsAppLink"
                                    defaultMessage="Download Windows App"
                                    description="Link to download windows app."
                                />
                            </Link>
                        </Stack.Item>
                    ) : (
                        <Stack.Item>
                            <Link onClick={onOpenConfigureRemoteDesktopDialog}>
                                <FormattedMessage
                                    id="HelpMenuPanel_NeedHelp_ConfigureRemoteDesktopLink"
                                    defaultMessage="Configure Remote Desktop"
                                    description="Link to opening the Configure Remote Desktop Modal."
                                />
                            </Link>
                        </Stack.Item>
                    )}

                    <Stack.Item>
                        <Link
                            href={SupportSectionLinks.MultipleMonitorsLink}
                            target="_blank"
                            onClick={handleMultiMonitorSupportLinkClick}
                        >
                            <FormattedMessage
                                id="HelpMenuPanel_NeedHelp_MultipleMonitorsLink"
                                defaultMessage="Use multiple monitors with your dev box"
                                description="Link to getting information about multiple monitors."
                            />
                        </Link>
                    </Stack.Item>
                </Stack>
            </DocumentCard>
        </AriaLiveWrapper>
    );
};

interface SupportSectionProps {
    isInMicrosoftTenant: boolean;
}

const SupportSection: React.FC<SupportSectionProps> = (props: SupportSectionProps) => {
    const { isInMicrosoftTenant } = props;

    // Intl hooks
    const intl = useIntl();
    const { formatMessage } = intl;

    // Style
    const classNames = useNeedHelpSectionStyles();

    // We do this within the FC because session ID isn't stable / set when the scripts first load.
    const sessionID = React.useMemo(() => getSessionID(), []);

    const supportSectionValues = React.useMemo(() => {
        const values = {
            issueID: sessionID,
            Bold: (chunks: string) => <span className={classNames.sessionID}>{chunks}</span>,
            Link: (chunks: string) => (
                <Link
                    href={SupportSectionLinks.TroubleshootAndRepair}
                    onClick={handleTroubleshootLinkClick}
                    target="_blank"
                >
                    {chunks}
                </Link>
            ),
        };
        return values;
    }, [sessionID, classNames.sessionID]);

    // Callback hooks
    const onCopyClickedHandler = React.useCallback(() => {
        navigator.clipboard.writeText(sessionID);
    }, [sessionID]);

    return (
        <DocumentCard
            aria-label={formatMessage(helpMenuMessages.helpMenuPanelSupportTitle)}
            className={classNames.root}
        >
            <DocumentCardTitle
                title={formatMessage(helpMenuMessages.helpMenuPanelSupportTitle)}
                className={classNames.documentCardTitle}
            />
            <Stack className={classNames.documentCardLink}>
                <Stack.Item>
                    <FormattedMessage
                        id="HelpMenuPanel_Support_DevBoxTroubleshootLink"
                        defaultMessage="If you're encountering technical issues, please <Link>troubleshoot your dev box</Link>. If that does not resolve the problem, contact your admin and provide the issue ID <Bold>{issueID}</Bold>."
                        description="Link to troubleshoot devbox and get more information. <Link> and </Link> should not be localized, but text between them should."
                        values={supportSectionValues}
                    />
                </Stack.Item>
                <Stack.Item className={classNames.copyButton}>
                    <DefaultButton
                        ariaLabel={formatMessage(helpMenuMessages.copyButtonAriaLabel)}
                        onClick={onCopyClickedHandler}
                        text={formatMessage(helpMenuMessages.copyButtonText)}
                    />
                </Stack.Item>
                {isInMicrosoftTenant ? (
                    <Stack.Item>
                        <FormattedMessage
                            id="HelpMenuPanel_Support_InternalSupportLink"
                            defaultMessage="If the issue persists and you cannot create or connect to a dev box, <Link>get support on TechWeb</Link>"
                            description="Link to get support for internal customers. <Link> and </Link> should not be localized, but text between them should."
                            values={internalSupportLinkValues}
                        />
                    </Stack.Item>
                ) : (
                    <Stack.Item>
                        <FormattedMessage
                            id="HelpMenuPanel_Support_ExternalSupportLink"
                            defaultMessage="If the issue persists and you cannot create or connect to a dev box,<Link>contact Azure help + support</Link>"
                            description="Link to get support for external customers. <Link> and </Link> should not be localized, but text between them should."
                            values={externalSupportLinkValues}
                        />
                    </Stack.Item>
                )}
            </Stack>
        </DocumentCard>
    );
};

const FeedbackSection: React.FC = () => {
    // Intl hooks
    const intl = useIntl();

    // Style
    const classNames = useNeedHelpSectionStyles();

    return (
        <DocumentCard
            aria-label={intl.formatMessage(helpMenuMessages.helpMenuPanelFeedbackTitle)}
            className={classNames.root}
        >
            <DocumentCardTitle
                title={intl.formatMessage(helpMenuMessages.helpMenuPanelFeedbackTitle)}
                className={classNames.documentCardTitle}
            />
            <Stack className={classNames.documentCardLink}>
                <Stack.Item>
                    <Link
                        href={SupportSectionLinks.DevBoxFeedback}
                        target="_blank"
                        onClick={handleDevBoxFeedbackSupportLinkClick}
                    >
                        <FormattedMessage
                            id="HelpMenuPanel_Feedback_DevBoxFeedbackLink"
                            defaultMessage="Suggest a Dev Box feature"
                            description="Link to suggest a Dev Box feature."
                        />
                    </Link>
                </Stack.Item>
                <Stack.Item>
                    <Link
                        href={SupportSectionLinks.AdeFeedback}
                        target="_blank"
                        onClick={handleAdeFeedbackSupportLinkClick}
                    >
                        <FormattedMessage
                            id="HelpMenuPanel_Feedback_AdeFeedbackLink"
                            defaultMessage="Suggest a Deployment Environments feature"
                            description="Link to suggest a ADE feature."
                        />
                    </Link>
                </Stack.Item>
            </Stack>
        </DocumentCard>
    );
};

const PrivacyAndTerms: React.FC<PrivacyAndTermsProps> = (props: PrivacyAndTermsProps) => {
    const { currentLanguage, currentMarket } = props;

    // Style
    const classNames = usePrivacyTermsOfUseStyles();

    // For Korean language and market, we have to a different and very specific string for this link.
    // More info here: https://liquid.microsoft.com/Web/Object/Read/MS.Privacy.MPS/Requirements/03.03.01.03
    const privacyLinkMessageDescriptor =
        currentLanguage === Language.KO || currentMarket === SupportedMarket.KR
            ? messages.privacyKoreanLanguageLinkText
            : messages.privacyLinkText;

    return (
        <div className={classNames.container}>
            <span className={classNames.root}>
                <Link href={SupportSectionLinks.Privacy} target="_blank" onClick={handlePrivacySupportLinkClick}>
                    <FormattedMessage {...privacyLinkMessageDescriptor} />
                </Link>
                <span className={classNames.privacyAndTermsDividerColor}> | </span>
                <Link
                    href={SupportSectionLinks.ProductTerms}
                    target="_blank"
                    onClick={handleProductTermsSupportLinkClick}
                >
                    <FormattedMessage {...messages.productTermsLinkText} />
                </Link>
            </span>
        </div>
    );
};

const InternalSection: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Style
    const classNames = useNeedHelpSectionStyles();

    return (
        <DocumentCard aria-label={formatMessage(helpMenuMessages.helpMenuInternalTitle)} className={classNames.root}>
            <DocumentCardTitle
                title={formatMessage(helpMenuMessages.helpMenuInternalTitle)}
                className={classNames.documentCardTitle}
            />
            <Stack className={classNames.documentCardLink}>
                <Stack.Item>
                    <Link
                        href={SupportSectionLinks.OneESDevBoxFaq}
                        target="_blank"
                        onClick={handleOneESDevBoxFaqLinkClick}
                    >
                        <FormattedMessage
                            id="HelpMenuPanel_OneESDevBoxFaq_Link"
                            defaultMessage="1ES Dev Box FAQ"
                            description="Link to 1ES internal FAQ page."
                        />
                    </Link>
                </Stack.Item>
            </Stack>
        </DocumentCard>
    );
};

export const HelpMenuPanelComponent: React.FC<HelpMenuPanelProps> = (props: HelpMenuPanelProps) => {
    const { currentLanguage, currentMarket, isOpen, onDismiss } = props;

    // Intl hooks
    const intl = useIntl();

    // Style hooks
    const helpMenuPanelStyles = useHelpMenuPanelStyles();

    // Selector hooks
    const isInMicrosoftTenant = useSelector(getIsUserSignedIntoMicrosoftTenant);

    return (
        <Panel
            customWidth={'369px'}
            type={PanelType.custom}
            isOpen={isOpen}
            headerText={intl.formatMessage(helpMenuMessages.helpMenuPanelTitle)}
            closeButtonAriaLabel={intl.formatMessage(helpMenuMessages.helpMenuPanelCloseButtonLabel)}
            onDismiss={onDismiss}
            styles={helpMenuPanelStyles}
            isLightDismiss
        >
            <NeedHelpSection />
            <SupportSection isInMicrosoftTenant={isInMicrosoftTenant} />
            {isInMicrosoftTenant && <InternalSection />}
            <FeedbackSection />
            <PrivacyAndTerms currentLanguage={currentLanguage} currentMarket={currentMarket} />
        </Panel>
    );
};

export const HelpMenuPanelContextWrapper: React.FC = () => {
    // Application state hooks
    const language = useSelector(getLanguage);
    const market = useSelector(getMarket);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useHelpMenuPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return (
        <HelpMenuPanelComponent
            currentLanguage={language}
            currentMarket={market}
            isOpen={isOpen}
            onDismiss={closePanel}
        />
    );
};

export default HelpMenuPanelContextWrapper;
