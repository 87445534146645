import { IComboBox, TimePicker } from '@fluentui/react';
import React from 'react';
import { getIs12HourTimeFormat, getTimeStringFromDate } from '../../../utilities/time';

export interface Props {
    value?: Date;
    locale: string;
    ariaLabel?: string;
    onChange: (value: Date | undefined) => void;
    placeholder: string;
    disabled?: boolean;
    label: string;
}

export const ExpirationTimePicker: React.FC<Props> = (props: Props) => {
    const { locale, placeholder, onChange, disabled, value, label, ariaLabel } = props;

    const isLocaleIn12HourFormat = React.useMemo(() => getIs12HourTimeFormat(locale), [locale]);

    const onFormatTimePicker = React.useCallback(
        (date: Date) => {
            return getTimeStringFromDate(date, locale) ?? '';
        },
        [locale]
    );

    const onChangeHandler = React.useCallback(
        (_event: React.FormEvent<IComboBox>, time: Date) => {
            onChange(time);
        },
        [onChange]
    );
    return (
        <TimePicker
            increments={15}
            allowFreeform={false}
            autoComplete="on"
            useHour12={isLocaleIn12HourFormat}
            value={value}
            onFormatDate={onFormatTimePicker}
            useComboBoxAsMenuWidth
            onChange={onChangeHandler}
            placeholder={placeholder}
            disabled={disabled}
            ariaLabel={ariaLabel ?? label}
            label={label}
        />
    );
};

export default ExpirationTimePicker;
