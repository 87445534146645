import {
    ProjectEnvironmentTypeAbilities,
    ProjectEnvironmentTypeFromDataPlane,
    ProjectEnvironmentTypeResource,
} from '../../models/project-environment-type';
import { createStringIdAdapter } from './common/string-id-adapter';

export const projectEnvironmentTypeResourceAdapter = createStringIdAdapter<ProjectEnvironmentTypeResource>();

export const projectEnvironmentTypeFromDataPlaneAdapter = createStringIdAdapter<ProjectEnvironmentTypeFromDataPlane>();

export const projectEnvironmentTypeAbilitiesAdapter = createStringIdAdapter<ProjectEnvironmentTypeAbilities>();
