import { UnionMap } from '../../../types/union-map';

export type EnvironmentNameFieldErrorType = 'AlreadyExists' | 'InvalidFormat' | 'None' | 'TooLong' | 'TooShort';

export const EnvironmentNameFieldErrorType: UnionMap<EnvironmentNameFieldErrorType> = {
    AlreadyExists: 'AlreadyExists',
    InvalidFormat: 'InvalidFormat',
    None: 'None',
    TooLong: 'TooLong',
    TooShort: 'TooShort',
};

export type EnvironmentExpirationFieldErrorType = 'TimeRequired' | 'DateRequired' | 'Invalid' | 'None';

export const EnvironmentExpirationFieldErrorType: UnionMap<EnvironmentExpirationFieldErrorType> = {
    TimeRequired: 'TimeRequired',
    DateRequired: 'DateRequired',
    Invalid: 'Invalid',
    None: 'None',
};
