import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { RedeployEnvironmentDialogProps } from './redeploy-environment-dialog';

export type RedeployEnvironmentDialogProperties = Omit<RedeployEnvironmentDialogProps, 'hidden' | 'onDismiss'>;

export const RedeployEnvironmentDialogContext = React.createContext<
    SurfaceContextWithProperties<RedeployEnvironmentDialogProperties>
>({ isOpen: false });

export const RedeployEnvironmentDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<RedeployEnvironmentDialogProperties>(RedeployEnvironmentDialogContext);
