import { ListLocationsResponse } from '../../../data/services/resource-manager/subscription';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import { ListLocationsSuccessPayload, SubscriptionActionType } from './subscription-actions';

/**
 * Action types
 */

export const discoverLocations = createActionCreatorWithoutPayload<AggregatedResult>(
    SubscriptionActionType.DiscoverLocations,
    { async: true }
);

export const discoverLocationsError = createActionCreator<ErrorPayload>(SubscriptionActionType.DiscoverLocationsError);

export const discoverLocationsFailed = createActionCreator<FailedPayload>(
    SubscriptionActionType.DiscoverLocationsFailed
);

export const discoverLocationsSuccess = createActionCreatorWithoutPayload(
    SubscriptionActionType.DiscoverLocationsSuccess
);

export const listLocations = createActionCreator<IndexedPayload, ListLocationsResponse>(
    SubscriptionActionType.ListLocations,
    { async: true, groupable: true, startsActivity: true }
);

export const listLocationsError = createActionCreator<ErrorPayload & IndexedPayload>(
    SubscriptionActionType.ListLocationsError,
    { groupable: true }
);

export const listLocationsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    SubscriptionActionType.ListLocationsFailed,
    { groupable: true }
);

export const listLocationsSuccess = createActionCreator<ListLocationsSuccessPayload>(
    SubscriptionActionType.ListLocationsSuccess,
    { groupable: true }
);
