import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../../redux/selector/localization-selectors';
import { ExpirationDatePicker } from '../date-picker';
import { getCurrentStartDate } from './selectors';

interface AddOrUpdateExpirationFormDateControlContainerProps {
    value?: Date;
    onChange: (date: Date | undefined) => void;
    isSubmitting: boolean;
    selectedTimeZone: string | undefined;
}

interface AddOrUpdateExpirationFormDateControlProps extends AddOrUpdateExpirationFormDateControlContainerProps {
    locale: string;
}

const messages = defineMessages({
    selectDeletionDate: {
        id: 'AddOrUpdateExpirationFormDateControl_Label',
        defaultMessage: 'Deletion date',
        description: 'Label for the date picker',
    },
    selectDeletionDateAriaLabel: {
        id: 'AddOrUpdateExpirationFormDateControl_AriaLabel',
        defaultMessage: 'Deletion date',
        description: 'Aria label for the date picker',
    },
    selectDeletionDatePlaceholder: {
        id: 'AddOrUpdateExpirationFormDateControl_Placeholder',
        defaultMessage: 'Select date',
        description: 'Placeholder for the date picker',
    },
});

export const AddOrUpdateExpirationFormDateControl: React.FC<AddOrUpdateExpirationFormDateControlProps> = (
    props: AddOrUpdateExpirationFormDateControlProps
) => {
    const { value, onChange, locale, isSubmitting, selectedTimeZone } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const minDate = React.useMemo(() => getCurrentStartDate(selectedTimeZone), [selectedTimeZone]);

    return (
        <ExpirationDatePicker
            label={formatMessage(messages.selectDeletionDate)}
            onChange={onChange}
            value={value}
            ariaLabel={formatMessage(messages.selectDeletionDateAriaLabel)}
            locale={locale}
            disabled={isSubmitting}
            minDate={minDate}
            placeholder={formatMessage(messages.selectDeletionDatePlaceholder)}
        />
    );
};

export const AddOrUpdateExpirationFormDateControlContainer: React.FC<
    AddOrUpdateExpirationFormDateControlContainerProps
> = (props: AddOrUpdateExpirationFormDateControlContainerProps) => {
    //Application state hooks
    const locale = useSelector(getLocale);

    return <AddOrUpdateExpirationFormDateControl {...props} locale={locale} />;
};

export default AddOrUpdateExpirationFormDateControlContainerProps;
