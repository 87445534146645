import { createSelector } from '@reduxjs/toolkit';
import { Status } from '../../models/common';
import { EnvironmentDefinition } from '../../models/environment-definition';
import { ProjectFromDiscoveryService } from '../../models/project';
import { ProjectEnvironmentTypeFromDataPlane } from '../../models/project-environment-type';
import { SerializableMap } from '../../types/serializable-map';
import { filter, get, keys, size } from '../../utilities/serializable-map';
import { projectsFromDiscoveryServiceAdapter } from '../adapters/project-adapters';
import { StoreState } from '../store/store-state';
import {
    getDoesProjectFromDiscoveryServiceHaveAuthorizationForAnyDevResource,
    getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCreate,
    getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCustomize,
    getIsProjectFromDiscoveryServiceAuthorizedForDevBoxRead,
    getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentRead,
    getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite,
} from './abilities-selectors';
import { StoreStateSelector, createMapFromEntitiesSelector, createSelectorEndingOnTrue } from './common';

const createAreThereAnyProjectsWithAbilitiesSelector =
    (predicate: (project: ProjectFromDiscoveryService) => boolean) =>
    (projects: SerializableMap<ProjectFromDiscoveryService>) =>
        keys(projects).some((key) => predicate(projects[key]));

/**
 * Basic selectors
 */

export const getStatusForListProjectsFromDiscoveryService: StoreStateSelector<Status> = (store) =>
    store.projectStore.status.listProjectsFromDiscoveryService;

export const getDoesProjectFromDiscoveryServiceHaveAtLeastOneEnvironmentDefinition = (
    projectId: string,
    environmentDefinitionsByProject: SerializableMap<SerializableMap<EnvironmentDefinition>>
): boolean => {
    const environmentDefinitions = get(environmentDefinitionsByProject, projectId);
    return !!environmentDefinitions && size(environmentDefinitions) > 0;
};

export const getDoesProjectFromDiscoveryServiceHaveAtLeastOneProjectEnvironmentType = (
    projectResourceId: string,
    projectEnvironmentTypes: SerializableMap<SerializableMap<ProjectEnvironmentTypeFromDataPlane>>
): boolean => {
    const projectEnvironmentTypesInProject = get(projectEnvironmentTypes, projectResourceId);
    return !!projectEnvironmentTypesInProject && size(projectEnvironmentTypesInProject) > 0;
};

export const getProjectsFromDiscoveryServiceWithAtLeastOneProjectEnvironmentType = (
    projects: SerializableMap<ProjectFromDiscoveryService>,
    projectEnvironmentTypes: SerializableMap<SerializableMap<ProjectEnvironmentTypeFromDataPlane>>
): SerializableMap<ProjectFromDiscoveryService> =>
    filter(projects, (project) => {
        const projectEnvironmentTypesInProject = get(projectEnvironmentTypes, project.uri);
        return !!projectEnvironmentTypesInProject && size(projectEnvironmentTypesInProject) > 0;
    });

/**
 * Entity state selectors
 */

const projectSelectors = projectsFromDiscoveryServiceAdapter.getSelectors<StoreState>(
    (store) => store.projectStore.data.projectsFromDiscoveryService
);

export const getCountOfProjectsFromDiscoveryService: StoreStateSelector<number> = projectSelectors.selectTotal;

/**
 * Composed selectors
 */

export const getProjectsByDiscoveryServiceURI = createMapFromEntitiesSelector(projectSelectors.selectAll);

export const getProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector([getProjectsByDiscoveryServiceURI], (projectsFromDiscoveryService) =>
    filter(projectsFromDiscoveryService, getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCreate)
);

export const getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector([getProjectsByDiscoveryServiceURI], (projectsFromDiscoveryService) =>
    filter(projectsFromDiscoveryService, getIsProjectFromDiscoveryServiceAuthorizedForDevBoxRead)
);

export const getProjectsFromDiscoveryServiceAuthorizedForDevBoxCustomize: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector([getProjectsByDiscoveryServiceURI], (projectsFromDiscoveryService) =>
    filter(projectsFromDiscoveryService, getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCustomize)
);

export const getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate: StoreStateSelector<boolean> =
    createSelectorEndingOnTrue(
        [getProjectsByDiscoveryServiceURI],
        createAreThereAnyProjectsWithAbilitiesSelector(getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCreate)
    );

export const getProjectsFromDiscoveryServiceAuthorizedForEnvironmentRead: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector([getProjectsByDiscoveryServiceURI], (projectsFromDiscoveryService) =>
    filter(projectsFromDiscoveryService, getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentRead)
);

export const getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite: StoreStateSelector<boolean> =
    createSelectorEndingOnTrue(
        [getProjectsByDiscoveryServiceURI],
        createAreThereAnyProjectsWithAbilitiesSelector(getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite)
    );

export const getProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector([getProjectsByDiscoveryServiceURI], (projectsFromDiscoveryService) =>
    filter(projectsFromDiscoveryService, getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite)
);

export const getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector([getProjectsByDiscoveryServiceURI], (projectsFromDiscoveryService) =>
    filter(projectsFromDiscoveryService, getDoesProjectFromDiscoveryServiceHaveAuthorizationForAnyDevResource)
);
