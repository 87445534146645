import { FeatureFlagName } from '../../constants/features';
import { FeatureFlagSet } from '../../models/features';
import { StoreState } from '../store/store-state';
import { StoreStateSelector } from './common';

/**
 * Basic selectors
 */

export const getEnabledFeatures: StoreStateSelector<FeatureFlagSet> = (store) =>
    store.featuresStore.data.enabledFeatures;

export const getFrozenFeatures: StoreStateSelector<FeatureFlagSet> = (store) => store.featuresStore.data.frozenFeatures;

export const getIsFeatureEnabled = (storeState: StoreState, feature: FeatureFlagName): boolean =>
    storeState.featuresStore.data.enabledFeatures[feature];

export const getEnabledFeaturesSetToTrue = (
    storeState: StoreState,
    enabledFeatures: FeatureFlagSet
): FeatureFlagName[] => {
    return Object.keys(enabledFeatures).filter((feature) =>
        getIsFeatureEnabled(storeState, feature as FeatureFlagName)
    ) as FeatureFlagName[];
};
