import {
    CloudErrorBody,
    FailureCode,
    FailureOperation,
    FailureResponse,
    OperationStatusError,
    hasClientErrorLikeCode,
    hasClientErrorLikeDetails,
    isCloudErrorBody,
} from '../models/common';
import { getMessageFromThrown } from './error';
import { tryOrDefault } from './try-or-default';

export const aggregateFailureResponses = (
    operation: FailureOperation,
    ...failures: FailureResponse[]
): FailureResponse =>
    FailureResponse({
        code: FailureCode.MultipleFailures,
        details: failures,
        operation,
    });

export const createFailureResponseFromCloudErrorBody = (
    errorBody: CloudErrorBody,
    operation?: FailureOperation,
    statusCode?: number,
    headers?: Headers
): FailureResponse => {
    const { code, details, message } = errorBody;

    return FailureResponse({
        code,
        details: details?.filter(isCloudErrorBody)?.map((error) => createFailureResponseFromCloudErrorBody(error)),
        headers,
        message,
        operation,
        statusCode,
    });
};

export const createFailureResponseFromCloudErrorBodyOrDefault = (
    errorBody: CloudErrorBody | undefined,
    operation?: FailureOperation,
    statusCode?: number
): FailureResponse => {
    if (!errorBody) {
        return FailureResponse({ operation, statusCode });
    }

    return createFailureResponseFromCloudErrorBody(errorBody, operation, statusCode);
};

export const createFailureResponseFromThrown = (
    error: unknown,
    operation: FailureOperation = FailureOperation.Unknown
): FailureResponse =>
    FailureResponse({
        code: hasClientErrorLikeCode(error) ? error.code : undefined,
        details: hasClientErrorLikeDetails(error) ? error.details : undefined,
        message: getMessageFromThrown(error),
        operation,
    });

export const parseErrorResponse = async (response: Response): Promise<CloudErrorBody> => {
    const errorResponse = await response.json();
    return errorResponse;
};

export const tryParseErrorResponse = tryOrDefault(parseErrorResponse);

export const createFailureResponseFromOperationStatusErrorOrDefault = (
    error: OperationStatusError | undefined,
    operation: FailureOperation,
    statusCode?: number
): FailureResponse =>
    FailureResponse({
        operation,
        code: error?.code,
        message: error?.message,
        statusCode,
    });
