import { createSelector } from '@reduxjs/toolkit';
import { Status } from 'src/models/common';
import { DevBoxProvisioningState } from '../../constants/dev-box';
import { getTokensFromDevBoxDataPlaneUri } from '../../ids/dev-box';
import { createProjectDataPlaneUri } from '../../ids/project';
import { DevBox, FailureOnDevBox } from '../../models/dev-box';
import { SerializableMap } from '../../types/serializable-map';
import { map, set, size } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxAdapter, devBoxStateAdapter } from '../adapters/dev-box-adapters';
import { StoreState } from '../store/store-state';
import {
    StoreStateSelector,
    createArrayFromEntitiesSelector,
    createGroupedMapFromEntitiesSelector,
    createMapFromEntitiesSelector,
    getDataById,
    getStringIdsSelector,
} from './common';

/**
 * Basic selectors
 */

const getFailuresFromOperations = (store: StoreState) => store.devBoxStore.data.failuresFromOperations;
const getFailuresFromResources = (store: StoreState) => store.devBoxStore.data.failuresFromResources;

export const getStatusForAddDevBox: StoreStateSelector<Status> = (store) => store.devBoxStore.status.addDevBox;

export const getIsDevBoxInUsableProvisioningState = (devBox: DevBox): boolean =>
    devBox.provisioningState === DevBoxProvisioningState.Succeeded;

/**
 * Entity state selectors
 */

const devBoxSelectors = devBoxAdapter.getSelectors<StoreState>((store) => store.devBoxStore.data.devBoxes);

const pendingStatesSelectors = devBoxStateAdapter.getSelectors<StoreState>(
    (store) => store.devBoxStore.data.pendingStates
);

const statusesForListDevBoxes = statusAdapter.getSelectors<StoreState>(
    (store) => store.devBoxStore.status.listDevBoxes
);

export const getDevBoxIds = getStringIdsSelector(devBoxAdapter, (store) => store.devBoxStore.data.devBoxes);

/**
 * Composed selectors
 */

export const getDevBoxes = createArrayFromEntitiesSelector(devBoxSelectors.selectAll);

export const getDevBoxesGroupedByProject = createGroupedMapFromEntitiesSelector(devBoxSelectors.selectAll, (entity) => {
    const { id } = entity;
    const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(id);

    return createProjectDataPlaneUri({ devCenter, projectName });
});

export const getDevBoxCountByProject: StoreStateSelector<SerializableMap<number>> = createSelector(
    [getDevBoxesGroupedByProject],
    (devBoxes) => map(devBoxes, (value) => size(value))
);

export const getFailuresOnDevBoxesById: StoreStateSelector<SerializableMap<FailureOnDevBox>> = createSelector(
    [getDevBoxIds, getFailuresFromOperations, getFailuresFromResources],
    (ids, failuresFromOperations, failuresFromResources) => {
        let failuresOnDevBoxes = SerializableMap<FailureOnDevBox>();

        ids.forEach((id) => {
            const failureFromOperations = getDataById(failuresFromOperations, id);
            const failureFromResource = getDataById(failuresFromResources, id);

            // For now, all operation failures take precedence over provisioning failures.
            if (failureFromOperations) {
                failuresOnDevBoxes = set(failuresOnDevBoxes, id, failureFromOperations);
            }

            if (failureFromResource) {
                failuresOnDevBoxes = set(failuresOnDevBoxes, id, failureFromResource);
            }
        });

        return failuresOnDevBoxes;
    }
);

export const getHasDevBoxes: StoreStateSelector<boolean> = createSelector(
    [devBoxSelectors.selectTotal],
    (total) => total > 0
);

export const getPendingStatesForDevBoxesById = createMapFromEntitiesSelector(pendingStatesSelectors.selectAll);

export const getStatusesForListDevBoxesByProjectId = createMapFromEntitiesSelector(statusesForListDevBoxes.selectAll);
