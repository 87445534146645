import { DefaultButton, FontSizes, FontWeights, IButtonProps, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import noDevBoxesImage from '../content/images/Empty_DevBoxEnvironment.svg';
import { useAddDevBoxPanelContext, useAddEnvironmentPanelContext } from '../hooks/context/panels';

interface NoResourcesComponentProps extends NoResourcesProps {
    onOpenAddDevBoxPanel: () => void;
    onOpenAddEnvironmentPanel: () => void;
}

const messages = defineMessages({
    addDevBoxButtonAriaLabel: {
        id: 'NoDevBoxes_AddDevBoxButton_AriaLabel',
        defaultMessage: 'Button for adding a dev box',
        description: 'Aria label for adding a dev box button',
    },
    addDevBoxButtonText: {
        id: 'NoDevBoxes_AddDevBoxButton_Text',
        defaultMessage: 'Add a dev box',
        description: 'Label text for the add a dev box button',
    },
    getStartedButtonAriaLabel: {
        id: 'NoDevBoxes_GetStartedButton_AriaLabel',
        defaultMessage: 'Get started',
        description: 'Aria label for the "Get started" button',
    },
    getStartedButtonText: {
        id: 'NoDevBoxes_GetStartedButton_Text',
        defaultMessage: 'Get started',
        description: 'Text for the "Get started" button',
    },
    noDevBoxesImageAlt: {
        id: 'NoDevBoxes_NoDevBoxesImage_Alt',
        defaultMessage: 'No dev boxes created',
        description: 'Alt text for "no dev boxes" image',
    },
    noDevBoxesText: {
        id: 'NoDevBoxes_EmptyMultiple_Text',
        defaultMessage: 'No dev boxes created',
        description: "Message informing user that they don't have any dev boxes in their project",
    },
    addDevBoxText: {
        id: 'NoDevBoxes_AddDevBoxMultiple_Text',
        defaultMessage: 'Add your first dev box to begin developing in the cloud!',
        description:
            'Message informing user that they can add a dev box to get started, implying they can create more in the future',
    },
    noEnvironmentsText: {
        id: 'NoResources_NoEnvironments_Text',
        defaultMessage: 'No environments created',
        description: "Message informing user that they don't have any environments in their project",
    },
    addEnvironmentText: {
        id: 'NoResources_AddEnvironment_Text',
        defaultMessage: 'Add your first environment to begin developing in the cloud!',
        description:
            'Message informing user that they can add an environment to get started, implying they can create more in the future',
    },
    addEnvironmentButtonAriaLabel: {
        id: 'NoResources_AddEnvironmentButton_AriaLabel',
        defaultMessage: 'Button for adding an environment',
        description: 'Aria label for add environment button',
    },
    addEnvironmentButtonText: {
        id: 'NoResources_AddEnvironmentButton_Text',
        defaultMessage: 'Add an environment',
        description: 'Label text for the add environment button',
    },
    noResourcesText: {
        id: 'NoResources_NoResources_Text',
        defaultMessage: 'No resources created',
        description: "Message informing user that they don't have any resources in their project",
    },
    addResourceText: {
        id: 'NoResources_AddResources_Text',
        defaultMessage: 'Add your first dev box or deployment environment to begin developing in the cloud!',
        description:
            'Message informing user that they can add an environment or dev box to get started, implying they can create more in the future',
    },
});

/**
 * Style Section
 */

const useNoDevBoxesContainerStyles = makeStyles({
    root: {
        paddingTop: 70,
    },
});

const useDescriptionContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '432px',
        textAlign: 'left',
    },
});

// Note: using paddingTop to space StackItems as Stack will override margins
const useEmptyContainerMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: 6,
    },
});

const useAddResourcesMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: `${FontWeights.semibold} !important`,
        lineHeight: '20px',
        paddingTop: 6,
    },
});

const useAddResourceButtonContainerStyles = makeStyles({
    root: {
        paddingTop: 24,
    },
});

const containerTokens: IStackTokens = {
    childrenGap: 11,
};

const buttonStackTokens: IStackTokens = { childrenGap: 'm' };

/* END */

export const NoResourcesComponent: React.FC<NoResourcesComponentProps> = React.memo(
    (props: NoResourcesComponentProps) => {
        const {
            onOpenAddDevBoxPanel,
            onOpenAddEnvironmentPanel,
            isDevBoxUser,
            isEnvironmentUser,
            canWriteDevBoxes,
            canWriteEnvironments,
        } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const addResourcesMessageStyles = useAddResourcesMessageStyles();
        const addResourceButtonContainerStyles = useAddResourceButtonContainerStyles();
        const descriptionContainerStyles = useDescriptionContainerStyles();
        const emptyContainerMessageStyles = useEmptyContainerMessageStyles();
        const noDevBoxesContainerStyles = useNoDevBoxesContainerStyles();

        // Memoized data
        const headerText = React.useMemo(() => {
            if (isDevBoxUser && isEnvironmentUser) {
                return formatMessage(messages.noResourcesText);
            }
            if (isDevBoxUser) {
                return formatMessage(messages.noDevBoxesText);
            }
            if (isEnvironmentUser) {
                return formatMessage(messages.noEnvironmentsText);
            }

            return formatMessage(messages.noResourcesText);
        }, [isDevBoxUser, isEnvironmentUser, formatMessage]);

        const descriptionText = React.useMemo(() => {
            if (isDevBoxUser && isEnvironmentUser) {
                if (canWriteDevBoxes && canWriteEnvironments) {
                    return formatMessage(messages.addResourceText);
                }

                if (canWriteDevBoxes) {
                    return formatMessage(messages.addDevBoxText);
                }

                if (canWriteEnvironments) {
                    return formatMessage(messages.addEnvironmentText);
                }

                return formatMessage(messages.addResourceText);
            }
            if (isDevBoxUser) {
                return formatMessage(messages.addDevBoxText);
            }
            if (isEnvironmentUser) {
                return formatMessage(messages.addEnvironmentText);
            }

            return formatMessage(messages.addResourceText);
        }, [isDevBoxUser, isEnvironmentUser, canWriteDevBoxes, canWriteEnvironments, formatMessage]);

        const addDevBoxButtonProps = React.useMemo((): IButtonProps => {
            if (isEnvironmentUser) {
                return {
                    text: formatMessage(messages.addDevBoxButtonText),
                    ariaLabel: formatMessage(messages.addDevBoxButtonAriaLabel),
                };
            }

            return {
                text: formatMessage(messages.getStartedButtonText),
                ariaLabel: formatMessage(messages.getStartedButtonAriaLabel),
            };
        }, [isEnvironmentUser, formatMessage]);

        const addEnvironmentButtonProps = React.useMemo((): IButtonProps => {
            if (isDevBoxUser) {
                return {
                    text: formatMessage(messages.addEnvironmentButtonText),
                    ariaLabel: formatMessage(messages.addEnvironmentButtonAriaLabel),
                };
            }

            return {
                text: formatMessage(messages.getStartedButtonText),
                ariaLabel: formatMessage(messages.getStartedButtonAriaLabel),
            };
        }, [isDevBoxUser, canWriteDevBoxes, formatMessage]);

        return (
            <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="center"
                tokens={containerTokens}
                styles={noDevBoxesContainerStyles}
            >
                <Stack.Item>
                    <img alt={formatMessage(messages.noDevBoxesImageAlt)} src={noDevBoxesImage} />
                </Stack.Item>
                <Stack.Item>
                    <Stack verticalFill styles={descriptionContainerStyles}>
                        <Stack.Item styles={emptyContainerMessageStyles}>{headerText}</Stack.Item>
                        <Stack.Item styles={addResourcesMessageStyles}>{descriptionText}</Stack.Item>
                        <Stack.Item styles={addResourceButtonContainerStyles}>
                            <Stack horizontal tokens={buttonStackTokens}>
                                {isDevBoxUser && canWriteDevBoxes && (
                                    <DefaultButton onClick={onOpenAddDevBoxPanel} {...addDevBoxButtonProps} />
                                )}
                                {isEnvironmentUser && canWriteEnvironments && (
                                    <DefaultButton onClick={onOpenAddEnvironmentPanel} {...addEnvironmentButtonProps} />
                                )}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        );
    }
);

export interface NoResourcesProps {
    isDevBoxUser: boolean;
    isEnvironmentUser: boolean;
    canWriteDevBoxes: boolean;
    canWriteEnvironments: boolean;
}

export const NoResourcesContainer: React.FC<NoResourcesProps> = (props: NoResourcesProps) => {
    // Context hooks
    const { openSurface: openAddDevBoxPanel } = useAddDevBoxPanelContext();
    const { openSurface: openAddEnvironmentPanel } = useAddEnvironmentPanelContext();

    return (
        <NoResourcesComponent
            onOpenAddDevBoxPanel={openAddDevBoxPanel}
            onOpenAddEnvironmentPanel={openAddEnvironmentPanel}
            {...props}
        />
    );
};

export default NoResourcesContainer;
