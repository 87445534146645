import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { discoverProjectEnvironmentTypesFromDataplaneListenerSaga } from './discover-project-environment-types-from-dataplane';
import { listProjectEnvironmentTypesFromDataplaneListenerSaga } from './list-project-environment-types-from-dataplane';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverProjectEnvironmentTypesFromDataplaneListenerSaga),
        fork(listProjectEnvironmentTypesFromDataplaneListenerSaga),
    ]);
}
