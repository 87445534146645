import { IIconProps, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { PropsWithChildren } from '../../types/props-with-children';
import InfoBannerActions from './info-banner-actions';

type InfoBannerProps = PropsWithChildren<{ iconProps?: IIconProps; onDismiss?: () => void; onSeeDetails?: () => void }>;

export const InfoBanner: React.FC<InfoBannerProps> = (props: InfoBannerProps) => {
    const { children, iconProps, onDismiss, onSeeDetails } = props;

    return (
        <MessageBar
            actions={<InfoBannerActions onSeeDetails={onSeeDetails} />}
            delayedRender={false}
            messageBarIconProps={iconProps}
            messageBarType={MessageBarType.info}
            onDismiss={onDismiss}
        >
            {children}
        </MessageBar>
    );
};

export default InfoBanner;
