import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import createEnvironmentStub from './create-environment-stub.json';
import createEnvironmentWithExpiration from './create-environment-with-expiration-stub.json';
import createOrReplaceEnvironmentFail from './create-or-replace-environment-fail.json';
import deleteEnvironmentError from './delete-environment-error.json';
import deleteEnvironment from './delete-environment.json';
import deployEnvironment from './deploy-environment.json';
import getEnvironmentFailedState from './get-environment-failed-state.json';
import getEnvironmentPollingError from './get-environment-polling-error.json';
import getEnvironment from './get-environment.json';
import listEnvironmentsError from './list-environments-error.json';
import listEnvironments from './list-environments.json';
import runCustomCommandOnEnvironment from './run-custom-command-on-environment.json';
import updateEnvironment from './update-environment-stub.json';

export type DataPlaneApiEnvironmentScenarioName =
    | 'CreateEnvironmentStub'
    | 'RunCustomCommandOnEnvironment'
    | 'DeleteEnvironment'
    | 'DeleteEnvironmentError'
    | 'DeployEnvironment'
    | 'GetEnvironment'
    | 'GetEnvironmentPollingError'
    | 'ListEnvironments'
    | 'GetEnvironmentFailedState'
    | 'CreateOrReplaceEnvironmentFail'
    | 'ListEnvironmentsError'
    | 'CreateEnvironmentWithExpiration'
    | 'UpdateEnvironment';

export const DataPlaneApiEnvironmentScenarioName: UnionMap<DataPlaneApiEnvironmentScenarioName> = {
    CreateEnvironmentStub: 'CreateEnvironmentStub',
    RunCustomCommandOnEnvironment: 'RunCustomCommandOnEnvironment',
    DeleteEnvironment: 'DeleteEnvironment',
    DeleteEnvironmentError: 'DeleteEnvironmentError',
    DeployEnvironment: 'DeployEnvironment',
    GetEnvironment: 'GetEnvironment',
    GetEnvironmentPollingError: 'GetEnvironmentPollingError',
    ListEnvironments: 'ListEnvironments',
    ListEnvironmentsError: 'ListEnvironmentsError',
    GetEnvironmentFailedState: 'GetEnvironmentFailedState',
    CreateOrReplaceEnvironmentFail: 'CreateOrReplaceEnvironmentFail',
    CreateEnvironmentWithExpiration: 'CreateEnvironmentWithExpiration',
    UpdateEnvironment: 'UpdateEnvironment',
};

export const DataPlaneApiEnvironmentScenarios: UnionValueMap<
    DataPlaneApiEnvironmentScenarioName,
    MockScenarioConfiguration
> = {
    CreateEnvironmentStub: createEnvironmentStub as MockScenarioConfiguration,
    RunCustomCommandOnEnvironment: runCustomCommandOnEnvironment as MockScenarioConfiguration,
    DeleteEnvironment: deleteEnvironment as MockScenarioConfiguration,
    DeleteEnvironmentError: deleteEnvironmentError as MockScenarioConfiguration,
    DeployEnvironment: deployEnvironment as MockScenarioConfiguration,
    GetEnvironment: getEnvironment as MockScenarioConfiguration,
    GetEnvironmentPollingError: getEnvironmentPollingError as MockScenarioConfiguration,
    ListEnvironments: listEnvironments as MockScenarioConfiguration,
    ListEnvironmentsError: listEnvironmentsError as MockScenarioConfiguration,
    GetEnvironmentFailedState: getEnvironmentFailedState as MockScenarioConfiguration,
    CreateOrReplaceEnvironmentFail: createOrReplaceEnvironmentFail as MockScenarioConfiguration,
    CreateEnvironmentWithExpiration: createEnvironmentWithExpiration as MockScenarioConfiguration,
    UpdateEnvironment: updateEnvironment as MockScenarioConfiguration,
};
