import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListPoolsResponse } from '../../../data/services/data-plane-api/pool';
import {
    AggregatedPartialSuccess,
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses, createFailureResponseFromThrown } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverPoolsForProjects,
    discoverPoolsForProjectsError,
    discoverPoolsForProjectsFailed,
    discoverPoolsForProjectsSuccess,
    listPools,
} from '../../actions/pool/pool-action-creators';
import { DiscoverPoolsForProjectsAction } from '../../actions/pool/pool-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getHasPools } from '../../selector/pool-selectors';
import { AsyncOutcome } from '../../store/common-state';
import { createTryCallSaga } from '../../utilities/try-call';
import { getIdsForProjectsRequiringPools } from './selectors';

// Note: this sub-operation pattern allows us to conditionally throw based on whether we have any pools
export function* performDiscoverPoolsForProjects(): SagaIterator<AggregatedResult> {
    try {
        const poolsResult: AggregatedResult = yield putAndAwait(discoverPoolsForProjects());

        return poolsResult;
    } catch (err) {
        // If we have already successfully loaded some pools during card content discovery,
        // we don't want to fail discovery for pools for projects even if we throw an internal error.
        const hasPoolsForDevBoxes = yield select(getHasPools);

        if (hasPoolsForDevBoxes) {
            const failure = createFailureResponseFromThrown(err, FailureOperation.DiscoverPoolsForProjects);
            return AggregatedPartialSuccess(failure);
        }

        throw err;
    }
}

export const tryPerformDiscoverPoolsForProjects = createTryCallSaga(performDiscoverPoolsForProjects);

export function* discoverPoolsForProjectsSaga(action: DiscoverPoolsForProjectsAction): SagaIterator {
    const startTime = new Date();

    try {
        const ids: string[] = yield select(getIdsForProjectsRequiringPools);
        const responses: ListPoolsResponse[] = yield all(ids.map((id) => putAndAwait(listPools({ id }))));

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.DiscoverPoolsForProjects, ...failures);
            yield put(discoverPoolsForProjectsFailed({ failure }));
        } else {
            yield put(discoverPoolsForProjectsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPoolsForProjects,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DiscoverPoolsForProjects);
        yield put(discoverPoolsForProjectsError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPoolsForProjects,
            startTime,
            AsyncOutcome.Error,
            {
                errorCodes: [error.code],
            }
        );
    }
}

export function* discoverPoolsForProjectsListenerSaga(): SagaIterator {
    yield takeLeading(discoverPoolsForProjects, discoverPoolsForProjectsSaga);
}
