//$schema=https://aka.ms/configuration-dsc-schema/0.2

export const WingetConfigurationSchemaVersion = '0.2.0';

export const wingetBase64ParameterName = 'inlineConfigurationBase64';

export type WingetConfigurationYamlContract = {
    properties: {
        configurationVersion: string;
        assertions?: WingetResourceItem[];
        resources: WingetResourceItem[];
    };
};

export type WingetResourceItem = {
    resource: string;
    directives?: {
        description?: string;
        allowPrerelease?: boolean;
        module?: string;
    };
    settings?: {
        [parameterName: string]: string;
    };
    id?: string;
    dependsOn?: string[];
};
