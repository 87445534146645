import { Method } from '../../constants/http';
import { DataResponse, isFailureResponse } from '../../models/common';
import { isNotUndefinedOrWhiteSpace } from '../../utilities/string';
import { PagedResultContract } from '../contracts/resource-manager';
import { ResourceRequestOptions, resourceRequest } from './resource-request';

// TODO: Task 2088728: Devportal: Modify requests methods in DataLayer to consume URL instead of base and path seperately
export const paginatedResourceRequest = async <TValue>(
    path: string,
    method: Method,
    accessToken: string,
    options?: ResourceRequestOptions,
    baseURL?: string,
    isErrorResponse?: (response: Response) => boolean
): Promise<DataResponse<TValue[]>> => {
    let data: TValue[] = [];
    let pathToPage: string | undefined = path;

    while (isNotUndefinedOrWhiteSpace(pathToPage)) {
        const response = await resourceRequest(pathToPage, method, accessToken, options, baseURL, isErrorResponse);

        // Immediately return any error
        if (isFailureResponse(response)) {
            return response;
        }

        const { nextLink, value } = (await response.data.json()) as PagedResultContract<TValue>;

        data = [...data, ...value];

        // Extract next path from URL
        // Note: we here assume Azure wouldn't send us back a link with a different hostname. That would be kind of a
        // weird thing to do anyway...
        pathToPage = isNotUndefinedOrWhiteSpace(nextLink) ? new URL(nextLink).pathname : undefined;
    }

    return { data, succeeded: true };
};
