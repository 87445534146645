import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../../models/common';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { getIsSingleDevCenterMode } from '../../../../utilities/single-dev-center';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { loadCustomizationGroups } from '../../../actions/customization/customization-action-creators';
import { loadDevBoxActionsForDevBoxes } from '../../../actions/dev-box-action/dev-box-action-action-creators';
import { loadDevBoxOperationsForDevBoxes } from '../../../actions/dev-box-operation/dev-box-operation-action-creators';
import { loadRemoteConnectionsForDevBoxes } from '../../../actions/remote-connection/remote-connection-action-creators';
import { discoverSchedulesForDevBoxes } from '../../../actions/schedule/schedule-action-creators';
import {
    loadDevBoxCardContent,
    loadDevBoxCardContentError,
    loadDevBoxCardContentFailed,
    loadDevBoxCardContentSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadDevBoxCardContentAction } from '../../../actions/sub-applications/home/home-actions';
import { discoverLocations } from '../../../actions/subscription/subscription-action-creators';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';

export function* loadDevBoxCardContentSaga(action: LoadDevBoxCardContentAction): SagaIterator {
    const startTime = new Date();

    try {
        const isSingleDevCenterMode: boolean = yield call(getIsSingleDevCenterMode);

        const effects = [
            putAndAwait(discoverSchedulesForDevBoxes()),
            putAndAwait(loadDevBoxActionsForDevBoxes()),
            putAndAwait(loadRemoteConnectionsForDevBoxes()),
            putAndAwait(loadDevBoxOperationsForDevBoxes()),
            putAndAwait(loadCustomizationGroups()),
        ];

        if (!isSingleDevCenterMode) {
            effects.push(putAndAwait(discoverLocations()));
        }

        const results: AggregatedResult[] = yield all(effects);

        const completeResult = combineResults(results);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadDevBoxCardContent, ...failures);
            yield put(loadDevBoxCardContentFailed({ failure }));
        } else {
            yield put(loadDevBoxCardContentSuccess());
        }

        yield resolveAction(action, completeResult);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadDevBoxCardContent,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadDevBoxCardContent);
        yield put(loadDevBoxCardContentError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadDevBoxCardContent,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadDevBoxCardContentListenerSaga(): SagaIterator {
    yield takeLeading(loadDevBoxCardContent, loadDevBoxCardContentSaga);
}
