import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { getGraphDirectoryObjectListenerSaga } from './directory-objects';
import { initializeOrganizationMetadataListenerSaga, initializeUserMetadataListenerSaga } from './initialize';
import { getBannerLogoForOrganizationListenerSaga, getOrganizationListenerSaga } from './organization';
import { getPhotoForSignedInUserListenerSaga, getSignedInUserListenerSaga } from './signed-in-user';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(getBannerLogoForOrganizationListenerSaga),
        fork(getOrganizationListenerSaga),
        fork(getPhotoForSignedInUserListenerSaga),
        fork(getSignedInUserListenerSaga),
        fork(initializeOrganizationMetadataListenerSaga),
        fork(initializeUserMetadataListenerSaga),
        fork(getGraphDirectoryObjectListenerSaga),
    ]);
}
