import { EnvironmentParameters } from '../../../data/contracts/environment';
import { Environment, EnvironmentUpdateProperties } from '../../../models/environment';
import { UnionValueMap } from '../../../types/union-map';
import {
    ActionName,
    ActionType,
    ErrorPayload,
    FailedPayload,
    IndexedPayload,
    OptionalResultPayload,
    ResultPayload,
} from '../core-actions';
import {
    addEnvironment,
    addEnvironmentAccepted,
    deleteEnvironment,
    deleteEnvironmentAccepted,
    deployEnvironment,
    deployEnvironmentAccepted,
    discoverEnvironmentsInTenant,
    listEnvironments,
    pollNonTerminalEnvironment,
    updateEnvironment,
} from './environment-action-creators';

/**
 * Action types
 */

type EnvironmentActionName =
    | ActionName<'AddEnvironment', 'Accepted' | 'Error' | 'Failed'>
    | ActionName<'AddEnvironmentOperation', 'Error' | 'Failed' | 'Success', false>
    | 'ClearAddEnvironmentFailure'
    | 'ClearDeployEnvironmentFailure'
    | ActionName<'DeleteEnvironment', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'DeployEnvironment', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'DeployEnvironmentOperation', 'Error' | 'Failed', false>
    | ActionName<'DiscoverEnvironmentsInTenant', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListEnvironments', 'Error' | 'Failed' | 'Success'>
    | ActionName<'PollNonTerminalEnvironment', 'Complete' | 'Error'>
    | ActionName<'UpdateEnvironment', 'Error' | 'Failed' | 'Success'>;

export type EnvironmentActionType =
    | ActionType<'ADD_ENVIRONMENT', '_ACCEPTED' | '_ERROR' | '_FAILED'>
    | ActionType<'ADD_ENVIRONMENT_OPERATION', '_ERROR' | '_FAILED' | '_SUCCESS', false>
    | 'CLEAR_ADD_ENVIRONMENT_FAILURE'
    | 'CLEAR_DEPLOY_ENVIRONMENT_FAILURE'
    | ActionType<'DELETE_ENVIRONMENT', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DEPLOY_ENVIRONMENT', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DEPLOY_ENVIRONMENT_OPERATION', '_ERROR' | '_FAILED', false>
    | ActionType<'DISCOVER_ENVIRONMENTS_IN_TENANT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_ENVIRONMENTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'POLL_NON_TERMINAL_ENVIRONMENT', '_COMPLETE' | '_ERROR'>
    | ActionType<'UPDATE_ENVIRONMENT', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const EnvironmentActionType: UnionValueMap<EnvironmentActionName, EnvironmentActionType> = {
    AddEnvironment: 'ADD_ENVIRONMENT',
    AddEnvironmentAccepted: 'ADD_ENVIRONMENT_ACCEPTED',
    AddEnvironmentError: 'ADD_ENVIRONMENT_ERROR',
    AddEnvironmentFailed: 'ADD_ENVIRONMENT_FAILED',
    AddEnvironmentOperationError: 'ADD_ENVIRONMENT_OPERATION_ERROR',
    AddEnvironmentOperationFailed: 'ADD_ENVIRONMENT_OPERATION_FAILED',
    AddEnvironmentOperationSuccess: 'ADD_ENVIRONMENT_OPERATION_SUCCESS',
    ClearAddEnvironmentFailure: 'CLEAR_ADD_ENVIRONMENT_FAILURE',
    ClearDeployEnvironmentFailure: 'CLEAR_DEPLOY_ENVIRONMENT_FAILURE',
    DeleteEnvironment: 'DELETE_ENVIRONMENT',
    DeleteEnvironmentAccepted: 'DELETE_ENVIRONMENT_ACCEPTED',
    DeleteEnvironmentError: 'DELETE_ENVIRONMENT_ERROR',
    DeleteEnvironmentFailed: 'DELETE_ENVIRONMENT_FAILED',
    DeleteEnvironmentSuccess: 'DELETE_ENVIRONMENT_SUCCESS',
    DeployEnvironment: 'DEPLOY_ENVIRONMENT',
    DeployEnvironmentAccepted: 'DEPLOY_ENVIRONMENT_ACCEPTED',
    DeployEnvironmentError: 'DEPLOY_ENVIRONMENT_ERROR',
    DeployEnvironmentFailed: 'DEPLOY_ENVIRONMENT_FAILED',
    DeployEnvironmentOperationError: 'DEPLOY_ENVIRONMENT_OPERATION_ERROR',
    DeployEnvironmentOperationFailed: 'DEPLOY_ENVIRONMENT_OPERATION_FAILED',
    DeployEnvironmentSuccess: 'DEPLOY_ENVIRONMENT_SUCCESS',
    DiscoverEnvironmentsInTenant: 'DISCOVER_ENVIRONMENTS_IN_TENANT',
    DiscoverEnvironmentsInTenantError: 'DISCOVER_ENVIRONMENTS_IN_TENANT_ERROR',
    DiscoverEnvironmentsInTenantFailed: 'DISCOVER_ENVIRONMENTS_IN_TENANT_FAILED',
    DiscoverEnvironmentsInTenantSuccess: 'DISCOVER_ENVIRONMENTS_IN_TENANT_SUCCESS',
    ListEnvironments: 'LIST_ENVIRONMENTS',
    ListEnvironmentsError: 'LIST_ENVIRONMENTS_ERROR',
    ListEnvironmentsFailed: 'LIST_ENVIRONMENTS_FAILED',
    ListEnvironmentsSuccess: 'LIST_ENVIRONMENTS_SUCCESS',
    PollNonTerminalEnvironment: 'POLL_NON_TERMINAL_ENVIRONMENT',
    PollNonTerminalEnvironmentComplete: 'POLL_NON_TERMINAL_ENVIRONMENT_COMPLETE',
    PollNonTerminalEnvironmentError: 'POLL_NON_TERMINAL_ENVIRONMENT_ERROR',
    UpdateEnvironment: 'UPDATE_ENVIRONMENT',
    UpdateEnvironmentError: 'UPDATE_ENVIRONMENT_ERROR',
    UpdateEnvironmentFailed: 'UPDATE_ENVIRONMENT_FAILED',
    UpdateEnvironmentSuccess: 'UPDATE_ENVIRONMENT_SUCCESS',
};

/**
 * Payloads
 */

export interface AddEnvironmentPayload extends IndexedPayload {
    catalog: string;
    environmentDefinition: string;
    environmentType: string;
    expirationDate?: Date;
    name: string;
    parameters: EnvironmentParameters;
}

export type AddEnvironmentOperationFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<Environment>;

export type DeleteEnvironmentFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<Environment>;

export interface DeployEnvironmentPayload extends IndexedPayload {
    environment: Environment;
    parameters?: EnvironmentParameters;
    showPutErrorOnResource: boolean;
    expirationDate?: Date;
}

export type DeployEnvironmentErrorPayload = ErrorPayload & IndexedPayload & { showPutErrorOnResource: boolean };
export type DeployEnvironmentFailedPayload = FailedPayload & IndexedPayload & { showPutErrorOnResource: boolean };

export type DeployEnvironmentOperationFailedPayload = FailedPayload &
    IndexedPayload &
    OptionalResultPayload<Environment>;

export type ListEnvironmentsSuccessPayload = IndexedPayload & ResultPayload<Environment[]>;

export type PollNonTerminalEnvironmentCompletePayload = IndexedPayload & OptionalResultPayload<Environment>;

export type UpdateEnvironmentPayload = EnvironmentUpdateProperties & IndexedPayload;
export type UpdateEnvironmentSuccessPaylaod = IndexedPayload & ResultPayload<Environment>;

/**
 * Action shorthands
 */

export type AddEnvironmentAction = ReturnType<typeof addEnvironment>;
export type AddEnvironmentAcceptedAction = ReturnType<typeof addEnvironmentAccepted>;
export type DeleteEnvironmentAction = ReturnType<typeof deleteEnvironment>;
export type DeleteEnvironmentAcceptedAction = ReturnType<typeof deleteEnvironmentAccepted>;
export type DeployEnvironmentAction = ReturnType<typeof deployEnvironment>;
export type DeployEnvironmentAcceptedAction = ReturnType<typeof deployEnvironmentAccepted>;
export type DiscoverEnvironmentsInTenantAction = ReturnType<typeof discoverEnvironmentsInTenant>;
export type ListEnvironmentsAction = ReturnType<typeof listEnvironments>;
export type PollNonTerminalEnvironmentAction = ReturnType<typeof pollNonTerminalEnvironment>;
export type UpdateEnvironmentAction = ReturnType<typeof updateEnvironment>;
