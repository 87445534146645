import { EntityState } from '@reduxjs/toolkit';
import { Entity } from '../../models/common';
import { EnvironmentOperation } from '../../models/environment';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { environmentOperationAdapter } from '../adapters/environment-operation-adapter';
import { IndexedStatusStore } from './indexed-status-store';

type IndexedStatusName = 'listEnvironmentOperations';

export interface EnvironmentOperationDataStore {
    operations: EntityState<Entity<EnvironmentOperation>>;
}

export const EnvironmentOperationDataStore = createInitializer<EnvironmentOperationDataStore>({
    operations: environmentOperationAdapter.getInitialState(),
});

export type EnvironmentOperationStatusStore = IndexedStatusStore<IndexedStatusName>;

export const EnvironmentOperationStatusStore = createInitializer<EnvironmentOperationStatusStore>({
    listEnvironmentOperations: statusAdapter.getInitialState(),
});

export interface EnvironmentOperationsStore {
    data: EnvironmentOperationDataStore;
    status: EnvironmentOperationStatusStore;
}

export const EnvironmentOperationsStore = createInitializer<EnvironmentOperationsStore>({
    data: EnvironmentOperationDataStore(),
    status: EnvironmentOperationStatusStore(),
});
