import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { DismissableContentName } from '../../../constants/app';
import { useActionCreator } from '../../../hooks/action-creator';
import { dismissContent as dismissContentActionCreator } from '../../../redux/actions/application/application-action-creators';
import {
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
} from '../../../redux/selector/display/create-ability/models';
import {
    getDevBoxCreateAbilityState,
    getEnvironmentCreateAbilityState,
} from '../../../redux/selector/display/create-ability/selectors';
import { ResourceUserState } from '../../../redux/selector/display/user-state/models';
import { getDevBoxUserState, getEnvironmentUserState } from '../../../redux/selector/display/user-state/selectors';
import { getOrganizationDisplayName } from '../../../redux/selector/graph-selectors';
import { getDisplayTime } from '../../../redux/selector/time-selectors';
import DevBoxCreateInfoMessageBar from '../message-bars/dev-box-create-info-message-bar';
import EnvironmentCreateInfoMessageBar from '../message-bars/environment-create-info-message-bar';
import { CombinedInfoMessageKind } from '../models';
import { getCombinedInfoMessageKind, getIsPortalSurveyBannerDismissed } from '../selectors';
import { CombinedInfoMessageBar } from './combined-info-message-bar';
import PortalSurveyMessageBar from './portal-survey-message-bar';

interface InfoMessageBarProps {
    devBoxUserState: ResourceUserState;
    environmentUserState: ResourceUserState;
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    organizationDisplayName: string;
    isPortalSurveyDismissed: boolean;
    displayDateTime: Date;
    onDismissMessageBar: () => void;
}

const surveyExpirationDate = DateTime.fromObject(
    {
        year: 2024,
        month: 2,
        day: 19,
        hour: 17,
    },
    { zone: 'America/Los_Angeles' }
);

const InfoMessageBarComponent: React.FC<InfoMessageBarProps> = React.memo((props: InfoMessageBarProps) => {
    const {
        devBoxUserState,
        devBoxCreateAbilityState,
        environmentUserState,
        environmentCreateAbilityState,
        organizationDisplayName,
        isPortalSurveyDismissed,
        displayDateTime,
        onDismissMessageBar,
    } = props;

    // Memoized data
    const combinedInfoMessageKind = React.useMemo(
        () =>
            getCombinedInfoMessageKind(
                devBoxCreateAbilityState,
                devBoxUserState,
                environmentCreateAbilityState,
                environmentUserState
            ),
        [devBoxCreateAbilityState, devBoxUserState, environmentCreateAbilityState, environmentUserState]
    );

    // Removed depencency on displayDateTime, to prevent re-rendering on minute changes.
    const isPastExpirationDate = React.useMemo(() => displayDateTime >= surveyExpirationDate.toJSDate(), []);

    const useCombinedMessage = combinedInfoMessageKind !== CombinedInfoMessageKind.None;

    // If we have a combined message to use, display that. Otherwise, use the individual resource type message bars (which will return empty if no misconfiguration is found)
    if (useCombinedMessage) {
        return <CombinedInfoMessageBar kind={combinedInfoMessageKind} />;
    }

    return (
        <>
            <DevBoxCreateInfoMessageBar
                devBoxCreateAbilityState={devBoxCreateAbilityState}
                organizationDisplayName={organizationDisplayName}
            />
            <EnvironmentCreateInfoMessageBar environmentCreateAbilityState={environmentCreateAbilityState} />
            <PortalSurveyMessageBar
                isPortalSurveyDismissed={isPortalSurveyDismissed}
                isPastExpirationDate={isPastExpirationDate}
                onDismissMessageBar={onDismissMessageBar}
            />
        </>
    );
});

export const InfoMessageBar: React.FC = () => {
    // Application state hooks
    const devBoxUserState = useSelector(getDevBoxUserState);
    const environmentUserState = useSelector(getEnvironmentUserState);
    const devBoxCreateAbilityState = useSelector(getDevBoxCreateAbilityState);
    const environmentCreateAbilityState = useSelector(getEnvironmentCreateAbilityState);
    const organizationDisplayName = useSelector(getOrganizationDisplayName);
    const isPortalSurveyDismissed = useSelector(getIsPortalSurveyBannerDismissed);
    const displayDateTime = useSelector(getDisplayTime);

    const dismissContent = useActionCreator(dismissContentActionCreator);

    const onDismissMessageBar = React.useCallback(() => {
        dismissContent({ content: DismissableContentName.PortalSurveyInfoBanner });
    }, [dismissContent]);

    return (
        <InfoMessageBarComponent
            devBoxUserState={devBoxUserState}
            environmentUserState={environmentUserState}
            devBoxCreateAbilityState={devBoxCreateAbilityState}
            environmentCreateAbilityState={environmentCreateAbilityState}
            organizationDisplayName={organizationDisplayName}
            isPortalSurveyDismissed={isPortalSurveyDismissed}
            displayDateTime={displayDateTime}
            onDismissMessageBar={onDismissMessageBar}
        />
    );
};
