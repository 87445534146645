import { EntityAdapter, EntityState, createSelector } from '@reduxjs/toolkit';
import { createSelectorCreator } from 'reselect';
import { Entity, InitializationState, InitializationStatus, Nothing, Status } from '../../models/common';
import { AsyncState } from '../../redux/store/common-state';
import { FunctionThatReturns } from '../../types/function-that-returns';
import { KeyValuePair } from '../../types/key-value-pair';
import { SerializableMap } from '../../types/serializable-map';
import { groupAndSelectIntoMap, groupIntoMap } from '../../utilities/array';
import { entries, get, keys, values } from '../../utilities/serializable-map';
import { StoreState } from '../store/store-state';

const areParametersUnequivalent = (parametersA: unknown[], parametersB: unknown[]) => {
    if (parametersA.length !== parametersB.length) {
        return true;
    }

    return parametersA.some((param, index) => param !== parametersB[index]);
};

const isEntityState = <TData>(
    value: EntityState<Entity<TData>> | SerializableMap<TData>
): value is EntityState<Entity<TData>> =>
    (value as EntityState<Entity<TData>>).entities !== undefined &&
    (value as EntityState<Entity<TData>>).ids !== undefined;

/**
 * Types
 */

interface CachedValue<TReturnType> {
    parameters: unknown[];
    value: TReturnType;
}

type Cache<TReturnType> = CachedValue<TReturnType> | Nothing;

export interface Memoized<TReturnType> {
    (...parameters: unknown[]): TReturnType;
    clearCache: () => void;
}

export type Memoizer<TReturnType> = (fn: (...parameters: unknown[]) => TReturnType) => Memoized<TReturnType>;

export type IndexedStoreStateSelector<T> = (store: StoreState, id: string) => T;
export type StoreStateSelector<T> = (store: StoreState) => T;

/**
 * Memoizers
 */

export const createTerminatingMemoizer =
    <TReturnType>(shouldTerminate: (value: TReturnType) => boolean): Memoizer<TReturnType> =>
    (fn) => {
        let cache: Cache<TReturnType> = Nothing;

        const memoized = (...parameters: unknown[]): TReturnType => {
            // If a result hasn't been calculated yet, calculate it!
            if (cache === Nothing) {
                const value = fn(...parameters);
                cache = { parameters, value };
                return value;
            }

            // If cached result makes "should terminate" true, return it immediately
            const { parameters: cachedParameters, value: cachedValue } = cache;

            if (shouldTerminate(cachedValue)) {
                return cachedValue;
            }

            // If any of the parameters differ, recalculate the value
            if (areParametersUnequivalent(parameters, cachedParameters)) {
                const value = fn(...parameters);
                cache = { parameters, value };
                return value;
            }

            return cachedValue;
        };

        memoized.clearCache = () => {
            cache = Nothing;
        };

        return memoized;
    };

/**
 * Selectors
 */

export const createArrayFromEntitiesSelector = <TData>(
    selectState: StoreStateSelector<Entity<TData>[]>
): StoreStateSelector<TData[]> => createSelector([selectState], (entities) => entities.map((entity) => entity.data));

export const createIdArrayFromEntitySelector = <TData>(
    selectState: StoreStateSelector<Entity<TData>[]>
): StoreStateSelector<string[]> => createSelector([selectState], (entities) => entities.map((entity) => entity.id));

export const createGroupedIntoMapSelector = <TValue>(
    selectState: StoreStateSelector<TValue[]>,
    key: (value: TValue, index: number) => string
): StoreStateSelector<SerializableMap<TValue[]>> =>
    createSelector([selectState], (values) => groupIntoMap(values, key));

export const createGroupedMapFromEntitiesSelector = <TData>(
    selectState: StoreStateSelector<Entity<TData>[]>,
    key: (entity: Entity<TData>) => string
): StoreStateSelector<SerializableMap<SerializableMap<TData>>> =>
    createSelector([selectState], (entities) =>
        groupAndSelectIntoMap(entities, key, (group) =>
            // Convert each group (at first a list of entities) into a map
            SerializableMap(
                group.map((entity) => {
                    const { data, id } = entity;
                    return KeyValuePair(id, data);
                })
            )
        )
    );

export const createGroupedMapSelector = <TData>(
    selectState: StoreStateSelector<SerializableMap<TData>>,
    key: (entry: KeyValuePair<string, TData>) => string
): StoreStateSelector<SerializableMap<SerializableMap<TData>>> =>
    createSelector([selectState], (map) => groupAndSelectIntoMap(entries(map), key, (group) => SerializableMap(group)));

export const createIsInitializedSelector = (
    selectState: StoreStateSelector<InitializationStatus>
): StoreStateSelector<boolean> =>
    createSelector([selectState], (status) => {
        switch (status.state) {
            case InitializationState.Error:
            case InitializationState.Failed:
            case InitializationState.Success:
                return true;

            default:
                return false;
        }
    });

export const createIsInitializingSelector = (
    selectState: StoreStateSelector<InitializationStatus>
): StoreStateSelector<boolean> =>
    createSelector([selectState], (status) => {
        switch (status.state) {
            case InitializationState.Initializing:
            case InitializationState.NotStarted:
                return true;

            default:
                return false;
        }
    });

export const createKeysSelector = (
    selectState: StoreStateSelector<SerializableMap<unknown>>
): StoreStateSelector<string[]> => createSelector([selectState], keys);

export const createMapFromEntitiesSelector = <TData>(
    selectState: StoreStateSelector<Entity<TData>[]>
): StoreStateSelector<SerializableMap<TData>> =>
    createSelector([selectState], (entities) =>
        SerializableMap(
            entities.map((entity) => {
                const { data, id } = entity;
                return KeyValuePair(id, data);
            })
        )
    );

export const createSelectorEndingOnSuccessStatus = createSelectorCreator<FunctionThatReturns<Status>, Memoizer<Status>>(
    createTerminatingMemoizer<Status>((value) => isStatusSuccessful(value))
);

export const createSelectorEndingOnTerminalStatus = createSelectorCreator<
    FunctionThatReturns<Status>,
    Memoizer<Status>
>(createTerminatingMemoizer<Status>((value) => isStatusTerminal(value)));

export const createSelectorEndingOnTrue = createSelectorCreator<FunctionThatReturns<boolean>, Memoizer<boolean>>(
    createTerminatingMemoizer<boolean>((value) => value)
);

export const createValuesSelector = <TValue>(
    selectState: StoreStateSelector<SerializableMap<TValue>>
): StoreStateSelector<TValue[]> => createSelector([selectState], values);

// NOTE: bit goofy, but this allows indexed selectors to receive the id argument as a parameter
export const forwardIdToSelector: IndexedStoreStateSelector<string> = (_: StoreState, id: string) => id;

export const getDataById = <TData>(
    store: EntityState<Entity<TData>> | SerializableMap<TData>,
    id: string
): TData | undefined => (isEntityState(store) ? store.entities[id]?.data : get(store, id));

// This is mainly a convenience method to get around RTK not letting us be more specific about the ID type.
export const getStringIdsSelector = <TEntity extends Entity<unknown>>(
    adapter: EntityAdapter<TEntity>,
    selectState: StoreStateSelector<EntityState<TEntity>>
): StoreStateSelector<string[]> => adapter.getSelectors(selectState).selectIds as StoreStateSelector<string[]>;

export const isAsyncStateSuccess = (loadingState: AsyncState | undefined): boolean =>
    !!loadingState && loadingState === AsyncState.Success;

export const isStatusInProgress = (status: Status | undefined): boolean => status?.state === AsyncState.InProgress;

// Intentionally returning true if status is not defined - an operation with an undefined state has not started
export const isStatusNotStarted = (status: Status | undefined): boolean =>
    status?.state === AsyncState.NotStarted || status === undefined;

export const isStatusSuccessful = (status: Status | undefined): boolean => isAsyncStateSuccess(status?.state);

export const isStatusTerminal = (status: Status | undefined): boolean => isTerminalLoadingState(status?.state);

export const isStatusUnsuccessful = (status: Status | undefined): boolean =>
    status?.state === AsyncState.Error || status?.state === AsyncState.Failed;

export const isTerminalLoadingState = (loadingState: AsyncState | undefined): boolean =>
    !!loadingState &&
    (loadingState === AsyncState.Success || loadingState === AsyncState.Error || loadingState === AsyncState.Failed);

export const didStatusFailWithCode = (status: Status | undefined, code: string): boolean =>
    !!status && isStatusUnsuccessful(status) && status.failure?.code === code;
