import { IStackTokens, Stack } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import FailureMessage from '../../common/failure-message/failure-message';
import { FailureMessageSeverity, FailureMessageSize } from '../../common/failure-message/models';
import DevBoxProjectDropdown from '../../common/form/dropdown/data-controls/dev-box-project-dropdown';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { AddDevBoxFormProjectViewModel } from '../models';

interface AddDevBoxFormProjectControlsProps {
    projects: AddDevBoxFormProjectViewModel[];
    disabled: boolean;
    selectedProject: AddDevBoxFormProjectViewModel | undefined;
    errorMessage: string;
    onChange: (value: AddDevBoxFormProjectViewModel | undefined) => void;
    showFailedToLoadWarning: boolean;
}

const messages = defineMessages({
    projectDropdownText: {
        id: 'AddDevBoxFormProjectControls_Dropdown_Text',
        defaultMessage: 'Project',
        description: 'Text for the project dropdown in the add dev box panel label',
    },
    projectDropdownAriaLabel: {
        id: 'AddDevBoxFormProjectControls_Dropdown_AriaLabel',
        defaultMessage: 'Project for your dev box',
        description: 'Aria label for the project dropdown in the add dev box panel',
    },
    projectDropdownPlaceholder: {
        id: 'AddDevBoxFormProjectControls_Dropdown_Placeholder',
        defaultMessage: 'Select a project',
        description: 'Placeholder text for the project dropdown in the add dev box panel',
    },
});

const projectDropdownStackTokens: IStackTokens = {
    childrenGap: 8,
};

export const AddDevBoxFormProjectControls: React.FC<AddDevBoxFormProjectControlsProps> = (
    props: AddDevBoxFormProjectControlsProps
) => {
    const { projects, disabled, selectedProject, errorMessage, onChange, showFailedToLoadWarning } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <Stack tokens={projectDropdownStackTokens}>
            <Stack.Item>
                <DevBoxProjectDropdown
                    options={projects}
                    value={selectedProject}
                    label={formatMessage(messages.projectDropdownText)}
                    ariaLabel={formatMessage(messages.projectDropdownAriaLabel)}
                    placeholder={formatMessage(messages.projectDropdownPlaceholder)}
                    onChange={onChange}
                    disabled={disabled}
                    errorMessage={errorMessage}
                    required
                    autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                />
            </Stack.Item>
            {showFailedToLoadWarning && (
                <Stack.Item>
                    <FailureMessage severity={FailureMessageSeverity.Warning} size={FailureMessageSize.Compact}>
                        <FormattedMessage
                            id="AddDevBoxForm_ProjectControl_FailedToLoad"
                            defaultMessage="We encountered an error loading some projects, so they may not appear here. Please retry, or contact your admin if this problem persists."
                            description="Text informing users that some projects failed to load resources and cannot be used for dev box creation."
                        />
                    </FailureMessage>
                </Stack.Item>
            )}
        </Stack>
    );
};

export default AddDevBoxFormProjectControls;
