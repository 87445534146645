import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { getGraphDirectoryObject } from './directory-objects-action-creators';

/**
 * Action types
 */

type GraphDirectoryObjectsActionName = ActionName<'GetGraphDirectoryObject', 'Error' | 'Failed' | 'Success'>;

export type GraphDirectoryObjectsActionType = ActionType<
    'GET_GRAPH_DIRECTORY_OBJECT',
    '_ERROR' | '_FAILED' | '_SUCCESS'
>;

export const GraphDirectoryObjectsActionType: UnionValueMap<
    GraphDirectoryObjectsActionName,
    GraphDirectoryObjectsActionType
> = {
    GetGraphDirectoryObject: 'GET_GRAPH_DIRECTORY_OBJECT',
    GetGraphDirectoryObjectError: 'GET_GRAPH_DIRECTORY_OBJECT_ERROR',
    GetGraphDirectoryObjectFailed: 'GET_GRAPH_DIRECTORY_OBJECT_FAILED',
    GetGraphDirectoryObjectSuccess: 'GET_GRAPH_DIRECTORY_OBJECT_SUCCESS',
};

/**
 * Action shorthands
 */

export type GetGraphDirectoryObjectAction = ReturnType<typeof getGraphDirectoryObject>;
