import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    IModalProps,
    makeStyles,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useRedeployEnvironmentDialogContext } from '../../../hooks/context/dialogs';
import { usePrevious } from '../../../hooks/use-previous';
import { Status } from '../../../models/common';
import { Environment } from '../../../models/environment';
import { deployEnvironment } from '../../../redux/actions/environment/environment-action-creators';
import { isStatusInProgress, isStatusTerminal } from '../../../redux/selector/common';
import { getStatusForDeployEnvironment } from '../../../redux/selector/environment-selectors';
import { ReturnVoid } from '../../../types/return-void';
import SubmitButton from '../../common/submit-button';

export interface RedeployEnvironmentDialogComponentProps extends RedeployEnvironmentDialogProps {
    onDeployEnvironment: ReturnVoid<typeof deployEnvironment>;
    statusForDeployEnvironment: Status;
}

const messages = defineMessages({
    redeployDialogPrimaryButtonText: {
        id: 'EnvironmentCard_RedeployDialogPrimaryButton_Text',
        defaultMessage: 'Redeploy',
        description: 'Confirmation button to redeploy the environment',
    },
    redeployDialogSubText: {
        id: 'EnvironmentCard_RedeployDialogSubText_Text',
        defaultMessage:
            'The environment definition {environmentDefinitionName} has no parameters, and will be redeployed immediately.',
        description:
            'Informs the user that their environment will be redeployed. {environmentDefinitionName} should not be localized, it is the name of the environment definition.',
    },
    redeployDialogTitle: {
        id: 'EnvironmentCard_RedeployDialogTitle_Text',
        defaultMessage: 'Redeploy {environmentName}',
        description:
            'Header text for environment redeploy dialog. {environmentName} should not be localized, it is the name of the environment to be redeployed.',
    },
    closeButtonAriaLabel: {
        id: 'RedeployEnvironmentDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss dialog',
        description: "Aria label for the redeploy dialog's close button",
    },
    cancelButtonText: {
        id: 'RedeployEnvironmentDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the redeploy dialog and perform no operation',
    },
});

/**
 * Styles
 */

// Not able to find a work around from using !important here. The model automatically sets the width of the dialog.
const modalWidth = '560px !important';

const useModalStyles = makeStyles({
    main: {
        maxWidth: modalWidth,
        width: modalWidth,
    },
});

/**
 * END Styles
 */

export const RedeployEnvironmentDialogComponent: React.FC<RedeployEnvironmentDialogComponentProps> = (
    props: RedeployEnvironmentDialogComponentProps
) => {
    const { environment, hidden, onDeployEnvironment, onDismiss, statusForDeployEnvironment } = props;
    const { environmentDefinitionName, name, uri } = environment;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const modalStyles = useModalStyles();

    // detect when we should close ourselves
    const previousStatusForDeployEnvironment = usePrevious(statusForDeployEnvironment);
    React.useEffect(() => {
        if (isStatusInProgress(previousStatusForDeployEnvironment) && isStatusTerminal(statusForDeployEnvironment)) {
            onDismiss();
        }
    }, [previousStatusForDeployEnvironment, statusForDeployEnvironment, onDismiss]);

    // Memoized data
    const isSubmitting = isStatusInProgress(statusForDeployEnvironment);

    const subTextValues = React.useMemo(() => ({ environmentDefinitionName }), [environmentDefinitionName]);
    const titleValues = React.useMemo(() => ({ environmentName: name }), [name]);

    const dialogContentProps: IDialogContentProps = React.useMemo(
        () => ({
            subText: formatMessage(messages.redeployDialogSubText, subTextValues),
            title: formatMessage(messages.redeployDialogTitle, titleValues),
            type: DialogType.normal,
        }),
        [formatMessage, subTextValues, titleValues]
    );

    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    const onPrimaryButtonClicked = React.useCallback(() => {
        onDeployEnvironment({
            environment,
            id: uri,
            // Note: dialog will close on initial request completion, regardless of error, failure, or success.
            // Therefore, we want to make sure any error/failure is shown on the resource card.
            showPutErrorOnResource: true,
        });
    }, [onDeployEnvironment, uri, environment]);

    return (
        <Dialog
            closeButtonAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
            dialogContentProps={dialogContentProps}
            hidden={hidden}
            modalProps={modalProps}
            onDismiss={isSubmitting ? undefined : onDismiss}
        >
            <DialogFooter>
                <SubmitButton
                    ariaLabel={formatMessage(messages.redeployDialogPrimaryButtonText)}
                    onClick={onPrimaryButtonClicked}
                    text={formatMessage(messages.redeployDialogPrimaryButtonText)}
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                />

                <DefaultButton
                    ariaLabel={formatMessage(messages.cancelButtonText)}
                    onClick={onDismiss}
                    text={formatMessage(messages.cancelButtonText)}
                    disabled={isSubmitting}
                />
            </DialogFooter>
        </Dialog>
    );
};

export interface RedeployEnvironmentDialogProps {
    hidden?: boolean;
    onDismiss: () => void;
    environment: Environment;
}

export const RedeployEnvironmentDialogContainer: React.FC<RedeployEnvironmentDialogProps> = (
    props: RedeployEnvironmentDialogProps
) => {
    const statusForDeployEnvironment = useSelector(getStatusForDeployEnvironment);

    // Action hooks
    const onDeployEnvironment = useActionCreator(deployEnvironment);

    return (
        <RedeployEnvironmentDialogComponent
            statusForDeployEnvironment={statusForDeployEnvironment}
            onDeployEnvironment={onDeployEnvironment}
            {...props}
        />
    );
};

export const RedeployEnvironmentDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useRedeployEnvironmentDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <RedeployEnvironmentDialogContainer {...properties} hidden={!isOpen} onDismiss={closeSurface} />;
};
