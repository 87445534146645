import { ListProjectsResponse } from '../../../../data/services/data-plane-api/project';
import { AggregatedResult } from '../../../../models/common';
import { Project } from '../../../../models/project';
import { createActionCreator, createActionCreatorWithoutPayload } from '../../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../../core-actions';
import { DevCenterUriPayload, SingleDevCenterActionType } from './single-dev-center-actions';

/**
 * Set uri on store
 */

export const setSingleDevCenterUri = createActionCreator<DevCenterUriPayload>(
    SingleDevCenterActionType.SetSingleDevCenterUri
);

/**
 * Set uri from form value
 */

export const setSingleDevCenterUriFromInput = createActionCreator<DevCenterUriPayload>(
    SingleDevCenterActionType.SetSingleDevCenterUriFromInput
);

export const setSingleDevCenterUriFromInputError = createActionCreator<ErrorPayload>(
    SingleDevCenterActionType.SetSingleDevCenterUriFromInputError
);

/**
 * Initialize uri from location query param
 */

/** An action creator to initialize the devcenter uri to the location query param */
export const initializeSingleDevCenterHome = createActionCreatorWithoutPayload(
    SingleDevCenterActionType.InitializeSingleDevCenterHome
);

export const initializeSingleDevCenterHomeError = createActionCreator<ErrorPayload>(
    SingleDevCenterActionType.InitializeSingleDevCenterHomeError
);

export const initializeSingleDevCenterHomeFailed = createActionCreator<FailedPayload>(
    SingleDevCenterActionType.InitializeSingleDevCenterHomeFailed
);

export const initializeSingleDevCenterHomeSuccess = createActionCreatorWithoutPayload(
    SingleDevCenterActionType.InitializeSingleDevCenterHomeSuccess
);

/**
 * Load data for new uri
 */

export const loadResourcesForSingleDevCenter = createActionCreatorWithoutPayload(
    SingleDevCenterActionType.LoadResourcesForSingleDevCenter
);

export const loadResourcesForSingleDevCenterError = createActionCreator<ErrorPayload>(
    SingleDevCenterActionType.LoadResourcesForSingleDevCenterError
);

export const loadResourcesForSingleDevCenterFailed = createActionCreator<FailedPayload>(
    SingleDevCenterActionType.LoadResourcesForSingleDevCenterFailed
);

export const loadResourcesForSingleDevCenterSuccess = createActionCreatorWithoutPayload(
    SingleDevCenterActionType.LoadResourcesForSingleDevCenterSuccess
);

/**
 * Load projects
 */

export const loadProjectsForDevCenter = createActionCreatorWithoutPayload<ListProjectsResponse>(
    SingleDevCenterActionType.LoadProjectsForSingleDevCenter,
    { async: true }
);

export const loadProjectsForDevCenterError = createActionCreator<ErrorPayload>(
    SingleDevCenterActionType.LoadProjectsForSingleDevCenterError
);

export const loadProjectsForDevCenterFailed = createActionCreator<FailedPayload>(
    SingleDevCenterActionType.LoadProjectsForSingleDevCenterFailed
);

export const loadProjectsForDevCenterSuccess = createActionCreator<ResultPayload<Project[]>>(
    SingleDevCenterActionType.LoadProjectsForSingleDevCenterSuccess
);

/**
 * Load dev boxes
 */

export const loadDevBoxesForSingleDevCenter = createActionCreatorWithoutPayload<AggregatedResult>(
    SingleDevCenterActionType.LoadDevBoxesForSingleDevCenter,
    { async: true }
);

export const loadDevBoxesForSingleDevCenterError = createActionCreator<ErrorPayload>(
    SingleDevCenterActionType.LoadDevBoxesForSingleDevCenterError
);

export const loadDevBoxesForSingleDevCenterFailed = createActionCreator<FailedPayload>(
    SingleDevCenterActionType.LoadDevBoxesForSingleDevCenterFailed
);

export const loadDevBoxesForSingleDevCenterSuccess = createActionCreatorWithoutPayload(
    SingleDevCenterActionType.LoadDevBoxesForSingleDevCenterSuccess
);
