import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SearchParameter } from '../../../constants/app';
import { clearMocks, configureMocksByScenarioName } from '../../../mocks/configure';
import { isScenarioName } from '../../../mocks/scenarios/all-scenarios';
import { ClientError, FailureOperation } from '../../../models/common';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    initializeMocks,
    initializeMocksError,
    initializeMocksSuccess,
} from '../../actions/mocks/mocks-action-creators';
import { InitializeMocksAction } from '../../actions/mocks/mocks-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLatest } from '../../effects/take';

export function* initializeMocksSaga(action: InitializeMocksAction): SagaIterator {
    try {
        const searchParams = new URLSearchParams(window.location.search);
        const scenariosValue = searchParams.get(SearchParameter.MockScenarios) ?? undefined;
        const scenarios = isNotUndefinedOrWhiteSpace(scenariosValue)
            ? scenariosValue
                  .split(',')
                  .map((s) => s.trim())
                  .filter(isScenarioName)
            : [];

        yield call(clearMocks);

        if (scenarios.length < 1) {
            yield put(initializeMocksSuccess({ noScenariosConfigured: true }));
            yield resolveAction(action, { succeeded: true });
            return;
        }

        yield call(configureMocksByScenarioName, scenarios);
        yield put(initializeMocksSuccess({ noScenariosConfigured: false }));
        yield resolveAction(action, { succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeMocks);
        yield put(initializeMocksError({ error }));
        yield rejectAction(action, error);
    }
}

export function* initializeMocksListenerSaga(): SagaIterator {
    yield takeLatest(initializeMocks, initializeMocksSaga);
}
