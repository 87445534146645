import { Organization } from '@microsoft/microsoft-graph-types';
import { ContentType, Method } from '../../../constants/http';
import { DataResponse, FailureOperation, FailureResponse } from '../../../models/common';
import { GraphErrorContract } from '../../contracts/graph';
import { graphRequest } from './graph-request';

export type GetBannerLogoForOrganizationResponse = DataResponse<Blob>;
export type GetOrganizationResponse = DataResponse<Organization>;

// TODO: allow specification of locale
export const getBannerLogoForOrganization = async (
    organizationId: string,
    accessToken: string,
    activityId?: string
): Promise<GetBannerLogoForOrganizationResponse> => {
    const path = `/organization/${organizationId}/branding/localizations/default/bannerLogo`;
    const result = await graphRequest(path, Method.GET, { accept: ContentType.ImageAll, activityId, accessToken });

    if (result.status !== 200) {
        const graphError: GraphErrorContract = await result.json();

        return FailureResponse({
            code: graphError?.error?.code,
            message: graphError?.error?.message,
            operation: FailureOperation.GetBannerLogoForOrganization,
            statusCode: result.status,
        });
    }

    return {
        data: await result.blob(),
        succeeded: true,
    };
};

export const getOrganization = async (accessToken: string, activityId?: string): Promise<GetOrganizationResponse> => {
    const path = '/organization';

    const result = await graphRequest(path, Method.GET, {
        accept: ContentType.ApplicationJson,
        activityId,
        accessToken,
    });

    if (result.status !== 200) {
        const graphError: GraphErrorContract = await result.json();

        return FailureResponse({
            code: graphError?.error?.code,
            message: graphError?.error?.message,
            operation: FailureOperation.GetOrganization,
            statusCode: result.status,
        });
    }

    const organizations: { value: Organization[] } = await result.json();

    return {
        data: organizations.value[0],
        succeeded: true,
    };
};
