import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { DataResponse, FailureOperation } from '../../../models/common';
import { ProjectContract } from '../../contracts/project';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetProjectResponse = DataResponse<ProjectContract>;
export type ListProjectsResponse = DataResponse<ProjectContract[]>;

export const getProject = async (id: string, accessToken: string, activityId?: string): Promise<GetProjectResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetProject,

        whenUsingBetaClient: (client) =>
            client.devCenter.getProject(projectName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devCenter.getProject(projectName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const listProjectsByDevCenter = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListProjectsResponse> => {
    const { origin: devCenter } = new URL(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListProjectsByDevCenter,
        whenUsingBetaClient: (client) => client.devCenter.listProjects(getCommonOptions(accessToken, activityId)),
        whenUsingStableClient: (client) => client.devCenter.listProjects(getCommonOptions(accessToken, activityId)),
    });

    return result;
};
