import { createReducer } from '@reduxjs/toolkit';
import {
    initializeLocalizationError,
    initializeLocalizationSuccess,
} from '../actions/localization/localization-action-creators';
import { LocalizationDataState, LocalizationSettingState } from '../store/localization-state';
import { LocalizationStore } from '../store/localization-store';
import { getPayload } from '../utilities/payload-action';

export const localizationReducer = createReducer(LocalizationStore(), (builder) => {
    builder.addCase(initializeLocalizationError, (store) => {
        store.localizationDataState = LocalizationDataState.Error;
    });

    builder.addCase(initializeLocalizationSuccess, (store, action) => {
        const { result } = getPayload(action);
        const { language, locale, market, messages, usingDefaultAsFallback } = result;

        store.language = language;
        store.locale = locale;
        store.localizationDataState = LocalizationDataState.Configured;
        store.localizationSettingState = usingDefaultAsFallback
            ? LocalizationSettingState.SetToFallback
            : LocalizationSettingState.Set;
        store.market = market;
        store.messages = messages;
    });
});
