import { Organization } from '@microsoft/microsoft-graph-types';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { GraphErrorCode } from '../../../constants/graph';
import {
    getBannerLogoForOrganization,
    getOrganization as getOrganizationApiCall,
} from '../../../data/services/graph/organization';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    FailureResponse,
    isFailureResponse,
} from '../../../models/common';
import { GraphDirectoryObject } from '../../../models/graph';
import { createObjectUrlForBlob } from '../../../utilities/data';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    getBannerLogoForOrganization as getBannerLogoForOrganizationActionCreator,
    getBannerLogoForOrganizationError,
    getBannerLogoForOrganizationFailed,
    getBannerLogoForOrganizationSuccess,
    getOrganization,
    getOrganizationError,
    getOrganizationFailed,
    getOrganizationSuccess,
} from '../../actions/graph/organization-action-creators';
import { GetBannerLogoForOrganizationAction, GetOrganizationAction } from '../../actions/graph/organization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForGraphPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLatest } from '../../effects/take';

export function* getBannerLogoForOrganizationSaga(action: GetBannerLogoForOrganizationAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForGraphPayload(), meta));
        const response: DataResponse<Blob> = yield call(getBannerLogoForOrganization, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(getBannerLogoForOrganizationFailed({ failure: response, id }, meta));
            return;
        }

        const { data } = response;
        const bannerLogoUrl = yield call(createObjectUrlForBlob, data);
        yield put(getBannerLogoForOrganizationSuccess({ bannerLogoUrl, id }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetBannerLogoForOrganization);
        yield put(getBannerLogoForOrganizationError({ error, id }, meta));
    }
}

export function* getBannerLogoForOrganizationListenerSaga(): SagaIterator {
    yield takeLatest(getBannerLogoForOrganizationActionCreator, getBannerLogoForOrganizationSaga);
}

export function* getOrganizationSaga(action: GetOrganizationAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForGraphPayload(), meta));
        const response: DataResponse<Organization> = yield call(getOrganizationApiCall, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(getOrganizationFailed({ failure: response }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { displayName, id } = response.data;

        // Fail if no ID was found (won't be able to do anything with falsy ID in subsequent requests)
        if (isUndefinedOrWhiteSpace(id)) {
            const failure = FailureResponse({
                code: GraphErrorCode.OrganizationHasNoId,
                operation: FailureOperation.GetOrganization,
            });

            yield put(getOrganizationFailed({ failure }, meta));
            yield resolveAction(action, failure);
            return;
        }

        // Fail if no display name was found
        // Note: doing seemingly redundant falsy check first to "unpack" NullableOption type around displayName
        if (!displayName || isUndefinedOrWhiteSpace(displayName)) {
            const failure = FailureResponse({
                code: GraphErrorCode.OrganizationHasNoDisplayName,
                operation: FailureOperation.GetOrganization,
            });

            yield put(getOrganizationFailed({ failure }, meta));
            yield resolveAction(action, failure);
            return;
        }

        const organization: GraphDirectoryObject = { displayName, id };

        yield put(getOrganizationSuccess({ result: organization }, meta));
        yield resolveAction(action, { data: organization, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetOrganization);
        yield put(getOrganizationError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* getOrganizationListenerSaga(): SagaIterator {
    yield takeLatest(getOrganization, getOrganizationSaga);
}
