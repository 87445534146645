import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { initializeMocks } from './mocks-action-creators';

/**
 * Action types
 */

type MocksActionName = ActionName<'InitializeMocks', 'Error' | 'Success'>;

export type MocksActionType = ActionType<'INITIALIZE_MOCKS', '_ERROR' | '_SUCCESS'>;

export const MocksActionType: UnionValueMap<MocksActionName, MocksActionType> = {
    InitializeMocks: 'INITIALIZE_MOCKS',
    InitializeMocksError: 'INITIALIZE_MOCKS_ERROR',
    InitializeMocksSuccess: 'INITIALIZE_MOCKS_SUCCESS',
};

/**
 * Payloads
 */

export interface InitializeMocksSuccessPayload {
    noScenariosConfigured?: boolean;
}

/**
 * Action shorthands
 */

export type InitializeMocksAction = ReturnType<typeof initializeMocks>;
