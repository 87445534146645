import { AzureDevOpsBranch } from '../../models/azure-dev-ops';
import { PutCustomizationTask, UploadedFileContent } from '../../models/customization';
import { HibernateSupport } from '../../models/pool';
import { SerializableMap } from '../../types/serializable-map';
import { CapitalizedUnionMap, UnionMap, UnionValueMap } from '../../types/union-map';
import { ProjectViewModel } from '../common/models';

export interface CustomizationData {
    taskList?: PutCustomizationTask[];
    cloneUrl?: string;
    branch?: AzureDevOpsBranch;
    contents?: string[];
    repoFiles?: string[];
    files?: UploadedFileContent[];
}

export interface AddDevBoxFormData {
    devBoxName: string;
    selectedProject: AddDevBoxFormProjectViewModel | undefined;
    selectedPool: PoolViewModel | undefined;
    applyCustomizationsEnabled: boolean;
    uploadFileEnabled: boolean;
    fileFromRepoEnabled: boolean;
    fileCustomizations: CustomizationData | undefined;
    repoCustomizations: CustomizationData | undefined;
}

export interface AddDevBoxFormErrorsByField {
    devBoxName?: string;
    selectedProject?: string;
    selectedPool?: string;
    repoUri?: string;
}

export interface AddDevBoxFormProjectViewModel extends ProjectViewModel {
    usedDevBoxes: number;
}

export const CustomizationGroupName = 'Initial';

export type CustomizationFileSchemaVersion = 'V1';

export const CustomizationFileSchemaVersion: UnionValueMap<CustomizationFileSchemaVersion, string> = {
    V1: '1.0',
};

export type CustomizationPanels = 'None' | 'CustomizationFiles' | 'Summary';

export const CustomizationPanels: UnionMap<CustomizationPanels> = {
    None: 'None',
    CustomizationFiles: 'CustomizationFiles',
    Summary: 'Summary',
};

export type CustomizationTaskParameter = { [parameterName: string]: string };

export type CustomizationTaskContract = { task: string; inputs?: CustomizationTaskParameter };

export interface PoolViewModel {
    id: string;
    name: string;
    cpuCount: number;
    memoryInGb: number;
    diskSizeInGb: number;
    region: string;
    imageName?: string;
    imageVersion?: string;
    lastUpdated?: Date;
    osType: string;
    supportsHibernate: boolean;
    hibernateSupport: HibernateSupport;
    displayName: string;
}

export type PoolKey = Exclude<keyof PoolViewModel, 'id' | 'osType' | 'hibernateSupport' | 'displayName'>;

export const PoolKey: CapitalizedUnionMap<PoolKey> = {
    CpuCount: 'cpuCount',
    DiskSizeInGb: 'diskSizeInGb',
    ImageName: 'imageName',
    ImageVersion: 'imageVersion',
    LastUpdated: 'lastUpdated',
    MemoryInGb: 'memoryInGb',
    Name: 'name',
    Region: 'region',
    SupportsHibernate: 'supportsHibernate',
};

export type DevBoxDataPlaneIdMap = SerializableMap<string>;
export type ProjectToDevBoxDataPlaneIdMap = SerializableMap<DevBoxDataPlaneIdMap>;
export type ProjectToPoolViewModelMap = SerializableMap<PoolViewModel[]>;
export type PoolViewModelMap = SerializableMap<PoolViewModel>;

// TODO: add arguments to function a la serializable-map if they're ever needed
export const DevBoxDataPlaneIdMap = (): DevBoxDataPlaneIdMap => ({});
export const ProjectToPoolViewModelMap = (): ProjectToPoolViewModelMap => ({});
export const ProjectToDevBoxDataPlaneIdMap = (): ProjectToDevBoxDataPlaneIdMap => ({});
export const PoolViewModelMap = (): PoolViewModelMap => ({});

export type AddDevBoxFormField =
    | 'devBoxName'
    | 'selectedProject'
    | 'selectedPool'
    | 'applyCustomizationsEnabled'
    | 'uploadFileEnabled'
    | 'fileFromRepoEnabled'
    | 'fileCustomizations'
    | 'repoCustomizations';

export const AddDevBoxFormField: CapitalizedUnionMap<AddDevBoxFormField> = {
    DevBoxName: 'devBoxName',
    SelectedProject: 'selectedProject',
    SelectedPool: 'selectedPool',
    ApplyCustomizationsEnabled: 'applyCustomizationsEnabled',
    UploadFileEnabled: 'uploadFileEnabled',
    FileFromRepoEnabled: 'fileFromRepoEnabled',
    FileCustomizations: 'fileCustomizations',
    RepoCustomizations: 'repoCustomizations',
};

export interface ScheduleViewModel {
    displayFrequency: string;
    displayTime: string;
}
