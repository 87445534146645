import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListLocationsResponse } from '../../../data/services/resource-manager/subscription';
import {
    AggregatedSuccess,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { OneOrMany } from '../../../types/one-or-many';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { toMany } from '../../../utilities/one-or-many';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverLocations,
    discoverLocationsError,
    discoverLocationsFailed,
    discoverLocationsSuccess,
    listLocations,
} from '../../actions/subscription/subscription-action-creators';
import { DiscoverLocationsAction } from '../../actions/subscription/subscription-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getSubscriptionIdsForProjectsAuthorizedForDevBoxRead } from './selectors';

export function* discoverLocationsSaga(action: DiscoverLocationsAction): SagaIterator {
    const startTime = new Date();

    try {
        // Get subscription IDs for all projects authorized for dev box read
        // Note: only checking projects with dev box read because locations are currently only needed for pools
        const subscriptionIds: string[] = yield select(getSubscriptionIdsForProjectsAuthorizedForDevBoxRead);

        // Exit immediately if no resource IDs are available
        if (subscriptionIds.length < 1) {
            yield put(discoverLocationsSuccess());
            yield resolveAction(action, AggregatedSuccess());
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DiscoverLocations,
                startTime,
                AsyncOutcome.Success
            );
            return;
        }

        // Request all batches concurrently. If the volume acts as a bottleneck on other requests, we can instead send
        // a handful (3-4) batches at once.
        const responses: OneOrMany<ListLocationsResponse> = yield putAndAwait(
            listLocations(subscriptionIds.map((id) => ({ id })))
        );

        const completeResult = combineResults(toMany(responses));

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.DiscoverLocations, ...failures);
            yield put(discoverLocationsFailed({ failure }));
        } else {
            yield put(discoverLocationsSuccess());
        }

        yield resolveAction(action, completeResult);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverLocations,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DiscoverLocations);
        yield put(discoverLocationsError({ error }));
        yield rejectAction(action, error);
        yield call(trackTimedPerformanceMetric, PerformanceMetric.DiscoverLocations, startTime, AsyncOutcome.Error, {
            errorCodes: [error.code],
        });
    }
}

export function* discoverLocationsListenerSaga(): SagaIterator {
    yield takeLeading(discoverLocations, discoverLocationsSaga);
}
