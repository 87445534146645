import { DefaultButton, DirectionalHint, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

interface PrimaryActionsButtonProps {
    actions: IContextualMenuItem[];
    disabled?: boolean;
}

const messages = defineMessages({
    splitButtonAriaLabel: {
        id: 'PrimaryActionsButton_SplitButton_AriaLabel',
        defaultMessage: 'See more actions',
        description: 'Aria label for dropdown button in primary actions split button for dev box cards',
    },
});

export const PrimaryActionsButton: React.FC<PrimaryActionsButtonProps> = React.memo(
    (props: PrimaryActionsButtonProps) => {
        const { actions, disabled } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Memoized data
        const menuItems = React.useMemo(() => {
            const [, ...menuItems] = actions;
            return menuItems;
        }, [actions, formatMessage]);

        const menuProps = React.useMemo<IContextualMenuProps | undefined>(() => {
            if (menuItems.length < 1) {
                return undefined;
            }

            return {
                directionalHint: DirectionalHint.bottomRightEdge,
                items: menuItems,
            };
        }, [menuItems]);

        const {
            ariaLabel,
            iconProps,
            onClick: onClickPrimaryAction,
            text,
        } = React.useMemo(() => actions[0], [actions]);

        // Callback hooks
        // HACK: event types are incompatible b/w onClick for DefaultButton and IContextualMenuItem, but this is fixed with
        // an explicit type declaration in the handler passed to DefaultButton.
        const onClick = React.useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                if (onClickPrimaryAction) {
                    onClickPrimaryAction(e);
                }
            },
            [onClickPrimaryAction]
        );

        return (
            <DefaultButton
                ariaLabel={ariaLabel}
                disabled={disabled}
                iconProps={iconProps}
                menuProps={menuProps}
                onClick={onClick}
                split={menuItems.length > 0}
                splitButtonAriaLabel={formatMessage(messages.splitButtonAriaLabel)}
                text={text}
            />
        );
    }
);

export default PrimaryActionsButton;
