import createSagaMiddleware from '@redux-saga/core';
import { configureStore, isPlain, Middleware } from '@reduxjs/toolkit';
import { Action, Store } from 'redux';
import { ClientError, FailureOperation, isClientError } from '../models/common';
import { flush, trackException } from '../utilities/telemetry/channel';
import { tryOrDefault } from '../utilities/try-or-default';
import actionLoggerMiddleware, { ignoreActions } from './middleware/action-logger-middleware';
import { crashReporterMiddleware } from './middleware/crash-reporter-middleware';
import combinedReducer from './reducers/combined-reducer';
import { rootSaga } from './sagas/root-saga';
import { StoreState } from './store/store-state';

interface CreateStoreOptions {
    /* eslint-disable @typescript-eslint/ban-types */
    // Justification: Redux is providing us with a bad type def, unfortunately, which forces us to use {}.
    middleware?: Middleware<{}, StoreState>[];
    /* eslint-enable @typescript-eslint/ban-types */
    preloadedState?: Partial<StoreState>;
}

// NOTE: This is not an exported typedef form the redux saga types for some reason, so
// it is duplicated below.
interface SagaErrorInfo {
    sagaStack: string;
}

// Module's private state
let isInitialized = false;
let store: Store<StoreState, Action> | undefined = undefined;

const onSagaError = (error: Error, errorInfo: SagaErrorInfo): void => {
    const sagaError = new ClientError(error, FailureOperation.UnhandledSagaError);
    trackException(sagaError, {
        properties: {
            errorInfo: JSON.stringify(errorInfo || {}),
        },
    });
    flush();
};

export const createStore = (options?: CreateStoreOptions): Store<StoreState, Action> => {
    const { middleware, preloadedState } = options ?? {};

    return configureStore({
        devTools: {
            maxAge: 150,
            actionsDenylist: ignoreActions,
        },
        middleware: (getDefaultMiddleware) => {
            const defaultMiddleware = getDefaultMiddleware({
                // Serializable check: disabling specific action paths and values to reduce spam in console.
                // See https://redux-toolkit.js.org/api/serializabilityMiddleware for further options.
                serializableCheck: {
                    // Ignore the following on actions:
                    //      - Promises, rejects, and resolves on awaitable actions
                    ignoredActionPaths: [
                        'async.promise',
                        'async.reject',
                        'async.resolve',
                        'promiseContract.promise',
                        'promiseContract.reject',
                        'promiseContract.resolve',
                    ],

                    // Middleware says following types aren't serializable, but they're actually fine:
                    //      - ClientError
                    //      - Date
                    isSerializable: (value: unknown) =>
                        isClientError(value) || value instanceof Date ? true : isPlain(value),
                },

                // Thunk: disabling since we don't make use of this currently.
                thunk: false,
            });

            return [...defaultMiddleware, ...(middleware ?? [])];
        },
        preloadedState,
        reducer: combinedReducer(),
    });
};

export const getStore = (): Store<StoreState, Action> => {
    if (!isInitialized) {
        throw new ClientError('Store state module must be initialized before use.');
    }

    if (store === undefined) {
        throw new ClientError('Unexpected state: store state module is initialized, but store is not defined.');
    }

    return store;
};

export const getStoreState = (): StoreState => {
    if (!isInitialized) {
        throw new ClientError('Store state module must be initialized before use.');
    }

    if (store === undefined) {
        throw new ClientError('Unexpected state: store state module is initialized, but store is not defined.');
    }

    return store.getState();
};

export const tryGetStoreState = tryOrDefault(getStoreState);

export const initialize = (): Store<StoreState, Action> => {
    if (isInitialized) {
        return getStore();
    }

    // Create redux-saga middleware
    const sagaMiddleware = createSagaMiddleware({ onError: onSagaError });

    store = createStore({
        middleware: [crashReporterMiddleware, sagaMiddleware, actionLoggerMiddleware],
    });

    // Start running saga middleware (must happen after applying middleware)
    sagaMiddleware.run(rootSaga);

    isInitialized = true;
    return store;
};
