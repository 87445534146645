import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Severity } from '../../constants/telemetry';
import { Entity } from '../../models/common';
import { ProjectEnvironmentTypeResource } from '../../models/project-environment-type';
import { compact } from '../../utilities/array';
import { tryNormalizeResource } from '../../utilities/resource-manager/normalize-resource';
import { trackTrace } from '../../utilities/telemetry/channel';
import {
    discoverProjectEnvironmentTypesAbilities,
    discoverProjectEnvironmentTypesAbilitiesError,
    discoverProjectEnvironmentTypesAbilitiesFailed,
    discoverProjectEnvironmentTypesAbilitiesSuccess,
    getProjectEnvironmentTypeAbilities,
    getProjectEnvironmentTypeAbilitiesError,
    getProjectEnvironmentTypeAbilitiesFailed,
    getProjectEnvironmentTypeAbilitiesSuccess,
} from '../actions/project-environment-type-abilities/project-environment-type-abilities-action-creators';
import {
    discoverProjectEnvironmentTypes,
    discoverProjectEnvironmentTypesError,
    discoverProjectEnvironmentTypesFailed,
    discoverProjectEnvironmentTypesFromDataplane,
    discoverProjectEnvironmentTypesFromDataplaneError,
    discoverProjectEnvironmentTypesFromDataplaneFailed,
    discoverProjectEnvironmentTypesFromDataplaneSuccess,
    discoverProjectEnvironmentTypesSuccess,
    listProjectEnvironmentTypes,
    listProjectEnvironmentTypesError,
    listProjectEnvironmentTypesFailed,
    listProjectEnvironmentTypesFromDataplane,
    listProjectEnvironmentTypesFromDataplaneError,
    listProjectEnvironmentTypesFromDataplaneFailed,
    listProjectEnvironmentTypesFromDataplaneSuccess,
    listProjectEnvironmentTypesSuccess,
} from '../actions/project-environment-type/project-environment-type-action-creators';
import {
    projectEnvironmentTypeAbilitiesAdapter,
    projectEnvironmentTypeFromDataPlaneAdapter,
    projectEnvironmentTypeResourceAdapter,
} from '../adapters/project-environment-type-adapters';
import {
    ProjectEnvironmentTypeDataStore,
    ProjectEnvironmentTypeStatusStore,
    ProjectEnvironmentTypeStore,
} from '../store/project-environment-type-store';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

const filterToUsableProjectEnvironmentTypes = (projectEnvironmentTypes: ProjectEnvironmentTypeResource[]) => {
    const normalizedProjectEnvironmentTypes = projectEnvironmentTypes.map((projectEnvironmentType) => {
        const data = tryNormalizeResource(projectEnvironmentType);

        if (data === undefined) {
            trackTrace('Unexpected state: an error occurred when normalizing project environment type resource.', {
                properties: { projectEnvironmentType: JSON.stringify(projectEnvironmentType) },
                severity: Severity.Warning,
            });
        }

        return data;
    });

    return compact(normalizedProjectEnvironmentTypes);
};

export const projectEnvironmentTypeReducer = combineReducers<ProjectEnvironmentTypeStore>({
    data: createReducer(ProjectEnvironmentTypeDataStore(), (builder) => {
        builder.addCase(listProjectEnvironmentTypesSuccess, (store, action) => {
            const actions = getActionsInGroup(action);
            const projectEnvironmentTypes = actions.flatMap((action) => action.payload.result);

            // Normalize and filter to valid project environment types
            const validProjectEnvironmentTypes = filterToUsableProjectEnvironmentTypes(projectEnvironmentTypes);

            projectEnvironmentTypeResourceAdapter.setMany(
                store.projectEnvironmentTypes,
                validProjectEnvironmentTypes.map((projectEnvironmentType) => {
                    const { id } = projectEnvironmentType;
                    return Entity(id, projectEnvironmentType);
                })
            );
        });
        builder.addCase(listProjectEnvironmentTypesFromDataplaneSuccess, (store, action) => {
            const { result } = getPayload(action);

            projectEnvironmentTypeFromDataPlaneAdapter.setMany(
                store.projectEnvironmentTypesFromDataplane,
                result.map((environmentType) => Entity(environmentType.uri, environmentType))
            );
        });

        builder.addCase(getProjectEnvironmentTypeAbilitiesSuccess, (store, action) => {
            const { id, result } = getPayload(action);

            projectEnvironmentTypeAbilitiesAdapter.setOne(store.projectEnvironmentTypeAbilities, Entity(id, result));
        });
    }),

    status: combineReducers<ProjectEnvironmentTypeStatusStore>({
        discoverProjectEnvironmentTypes: createStatusReducer({
            inProgress: discoverProjectEnvironmentTypes,
            error: discoverProjectEnvironmentTypesError,
            failed: discoverProjectEnvironmentTypesFailed,
            success: discoverProjectEnvironmentTypesSuccess,
        }),

        listProjectEnvironmentTypes: createIndexedStatusReducer({
            inProgress: listProjectEnvironmentTypes,
            error: listProjectEnvironmentTypesError,
            failed: listProjectEnvironmentTypesFailed,
            success: listProjectEnvironmentTypesSuccess,
        }),

        discoverProjectEnvironmentTypesFromDataplane: createStatusReducer({
            inProgress: discoverProjectEnvironmentTypesFromDataplane,
            error: discoverProjectEnvironmentTypesFromDataplaneError,
            failed: discoverProjectEnvironmentTypesFromDataplaneFailed,
            success: discoverProjectEnvironmentTypesFromDataplaneSuccess,
        }),
        listProjectEnvironmentTypesFromDataplane: createIndexedStatusReducer({
            inProgress: listProjectEnvironmentTypesFromDataplane,
            error: listProjectEnvironmentTypesFromDataplaneError,
            failed: listProjectEnvironmentTypesFromDataplaneFailed,
            success: listProjectEnvironmentTypesFromDataplaneSuccess,
        }),

        discoverProjectEnvironmentTypeAbilities: createStatusReducer({
            inProgress: discoverProjectEnvironmentTypesAbilities,
            error: discoverProjectEnvironmentTypesAbilitiesError,
            failed: discoverProjectEnvironmentTypesAbilitiesFailed,
            success: discoverProjectEnvironmentTypesAbilitiesSuccess,
        }),

        getProjectEnvironmentTypeAbilities: createIndexedStatusReducer({
            inProgress: getProjectEnvironmentTypeAbilities,
            error: getProjectEnvironmentTypeAbilitiesError,
            failed: getProjectEnvironmentTypeAbilitiesFailed,
            success: getProjectEnvironmentTypeAbilitiesSuccess,
        }),
    }),
});
