import { defineMessages } from 'react-intl';

export const selectEnvironmentDefinitionDetailsListMessages = defineMessages({
    filterInputPlaceholder: {
        id: 'SelectEnvironmentDefinitionDetailsList_FilterInput_Placeholder',
        defaultMessage: 'Filter for any field...',
        description: 'Text for the select environment definition details list filter input placeholder',
    },
    filterInputAriaLabel: {
        id: 'SelectEnvironmentDefinitionDetailsList_FilterInput_AriaLabel',
        defaultMessage: 'This is the input to filter out the environment definitions for any field',
        description: 'Aria label for the select environment definition details list filter input',
    },
    nameColumnHeaderText: {
        id: 'SelectEnvironmentDefinitionDetailsList_NameColumnHeader_Text',
        defaultMessage: 'Name',
        description: 'Text for the select environment definition details list name column',
    },
    nameColumnHeaderAriaLabel: {
        id: 'SelectEnvironmentDefinitionDetailsList_NameColumnHeader_AriaLabel',
        defaultMessage: 'This is the header for the name column in the details list',
        description: 'Aria label for the select environment definition details list name column',
    },
    descriptionColumnHeaderText: {
        id: 'SelectEnvironmentDefinitionDetailsList_DescriptionColumnHeader_Text',
        defaultMessage: 'Description',
        description: 'Text for the select environment definition details list description column',
    },
    descriptionColumnHeaderAriaLabel: {
        id: 'SelectEnvironmentDefinitionDetailsList_DescriptionColumnHeader_AriaLabel',
        defaultMessage: 'This is the header for the description column in the details list',
        description: 'Aria label for the select environment definition details list description column',
    },
    sortAscendingOrderAriaLabel: {
        id: 'SelectEnvironmentDefinitionDetailsList_SortAscendingOrder_AriaLabel',
        defaultMessage: 'Sorted from A to Z',
        description: 'Aria Label for the select environment definition details list sorting ascending order',
    },
    sortDescendingOrderAriaLabel: {
        id: 'SelectEnvironmentDefinitionDetailsList_SortDescendingOrder_AriaLabel',
        defaultMessage: 'Sorted from Z to A',
        description: 'Aria Label for the select environment definition details list sorting descending order',
    },
    catalogColumnHeaderText: {
        id: 'SelectEnvironmentDefinitionDetailsList_CatalogColumnHeader_Text',
        defaultMessage: 'Catalog',
        description: 'Text for the select environment definition details list catalog column',
    },
    catalogColumnHeaderAriaLabel: {
        id: 'SelectEnvironmentDefinitionDetailsList_CatalogColumnHeader_AriaLabel',
        defaultMessage: 'This is the header for the catalog column in the details list',
        description: 'Aria label for the select environment definition details list catalog column',
    },
    environmentResourcesFilterText: {
        id: 'EnvironmentDefinitions_Filter_Text',
        defaultMessage: '{itemCount} environment definitions found',
        description: 'Filter text for search results for screen reader.',
    },
});
