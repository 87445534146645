import { FontSizes, FontWeights, makeStyles, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import notSignedInImage from '../content/images/Home.svg';
import { useActionCreator } from '../hooks/action-creator';
import commonMessages from '../language/messages';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { signIn } from '../redux/actions/identity/identity-action-creators';
import { ReturnVoid } from '../types/return-void';
import { isDevPortalEnvironment } from '../utilities/app';

interface UnauthenticatedPageComponentProps {
    onSignInSubmitted: ReturnVoid<typeof signIn>;
}

const messages = defineMessages({
    signedOutImageAlt: {
        id: 'UnauthenticatedPage_SignedOutImage_Alt',
        defaultMessage: 'Not signed in',
        description: 'Alt text for "not signed in" image',
    },
    signInButtonLabel: {
        id: 'UnauthenticatedPage_SignInButton_Label',
        defaultMessage: 'Sign in',
        description: 'Aria label for sign in button',
    },
    signInButtonText: {
        id: 'UnauthenticatedPage_SignInButton_Text',
        defaultMessage: 'Sign in',
        description: 'Text for sign in button',
    },
});

/**
 * Style Section
 */
const useSignInButtonContainerStyles = makeStyles({
    // Note: using paddingTop to space StackItems as Stack will override margins
    root: {
        lineHeight: '20px',
        paddingTop: 20,
    },
});

const useSignInMessageContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        paddingTop: 6,
    },
});

const useWelcomeMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: 6,
    },
});

/* END */

const welcomeMessage = isDevPortalEnvironment
    ? commonMessages.welcomeMessageDevPortalText
    : commonMessages.welcomeMessageText;

export const UnauthenticatedPageComponent: React.FC<UnauthenticatedPageComponentProps> = (
    props: UnauthenticatedPageComponentProps
) => {
    const { onSignInSubmitted } = props;

    // Callback hooks
    // Note: this useCallback prevents onClick event args from being sent to redux, which will cause errors
    const onSignInButtonClicked = React.useCallback(() => onSignInSubmitted({}), [onSignInSubmitted]);

    // Style hooks
    const signInButtonContainerStyles = useSignInButtonContainerStyles();
    const signInMessageContainerStyles = useSignInMessageContainerStyles();
    const welcomeMessageContainerStyles = useWelcomeMessageContainerStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <>
            <CenteredPortalLayout
                contentPaddingBottom={40}
                contentPaddingTop={40}
                id="unauthenticated"
                verticalFill
                showFooter
            >
                <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                    <Stack.Item>
                        <img alt={formatMessage(messages.signedOutImageAlt)} src={notSignedInImage} />
                    </Stack.Item>
                    <Stack.Item styles={welcomeMessageContainerStyles}>
                        <FormattedMessage {...welcomeMessage} />
                    </Stack.Item>

                    <Stack.Item styles={signInMessageContainerStyles}>
                        <FormattedMessage
                            id="UnauthenticatedPage_SignInMessage_Text"
                            defaultMessage="Sign in to access or manage your dev boxes"
                            description="Message requesting that the user sign in to make use of the portal"
                        />
                    </Stack.Item>

                    <Stack.Item styles={signInButtonContainerStyles}>
                        <PrimaryButton
                            ariaLabel={formatMessage(messages.signInButtonLabel)}
                            id="UnauthenticatedPage_SignInButton"
                            onClick={onSignInButtonClicked}
                            text={formatMessage(messages.signInButtonText)}
                        />
                    </Stack.Item>
                </Stack>
            </CenteredPortalLayout>
        </>
    );
};

export const UnauthenticatedPageContainer: React.FC = () => {
    // Action hooks
    const onSignInSubmitted = useActionCreator(signIn);

    return <UnauthenticatedPageComponent onSignInSubmitted={onSignInSubmitted} />;
};

export default UnauthenticatedPageContainer;
