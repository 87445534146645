import { TraceparentVersion } from '../constants/tracing';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from './string';

interface TraceparentParts {
    flags?: number;
    spanId: string;
    traceId: string;
    version: TraceparentVersion;
}

// Enabling flag as this is what Application Insights does by default
const DefaultTraceparentFlags = 1;

export const formatTraceparentHeader = (
    traceId: string,
    spanId: string,
    version: TraceparentVersion = TraceparentVersion.Version00,
    flags = DefaultTraceparentFlags
): string => {
    const flagsString = flags.toString(16);

    return `${version}-${traceId}-${spanId}-${flagsString.length <= 1 ? '0' : ''}${flagsString}`;
};

export const parseTraceparentHeader = (traceparent: string | undefined): TraceparentParts | undefined => {
    if (isUndefinedOrWhiteSpace(traceparent)) {
        return undefined;
    }

    const parts = traceparent.split('-');

    if (parts.length < 4) {
        return undefined;
    }

    const [versionString, traceId, spanId, flagsString] = parts;

    return {
        flags: isNotUndefinedOrWhiteSpace(flagsString) ? parseInt(flagsString, 16) : undefined,
        spanId,
        traceId,
        version: versionString as TraceparentVersion,
    };
};

export const parseClientRequestId = (clientRequestId: string | undefined): string | undefined => {
    if (isUndefinedOrWhiteSpace(clientRequestId)) {
        return undefined;
    }

    return clientRequestId;
};
