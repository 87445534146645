import * as React from 'react';
import ErrorBanner from './error-banner';
import InfoBanner from './info-banner';
import { ResourceCardBannerViewModel, isErrorBannerViewModel, isInfoBannerViewModel } from './models';
import SuccessBanner from './success-banner';

interface CardBannerProps {
    cardBanner: ResourceCardBannerViewModel;
}

export const CardBanner: React.FC<CardBannerProps> = React.memo((props: CardBannerProps) => {
    const { cardBanner } = props;

    if (isErrorBannerViewModel(cardBanner)) {
        const {
            error,
            isDismissable,
            additionalErrorBannerActions,
            onRetryFailedOperation,
            onSeeErrorDetails,
            onDismiss,
        } = cardBanner;

        return (
            <ErrorBanner
                onRetry={onRetryFailedOperation}
                onSeeDetails={onSeeErrorDetails}
                retryable={error.retryable}
                severity={error.severity}
                iconProps={error.iconProps}
                additionalActions={additionalErrorBannerActions}
                onDismiss={isDismissable ? onDismiss : undefined}
            >
                {error.message}
            </ErrorBanner>
        );
    }

    if (isInfoBannerViewModel(cardBanner)) {
        const { iconProps, infoBannerMessage, isDismissable, onDismiss, onSeeDetails } = cardBanner;

        return (
            <InfoBanner
                iconProps={iconProps}
                onDismiss={isDismissable ? onDismiss : undefined}
                onSeeDetails={onSeeDetails}
            >
                {infoBannerMessage}
            </InfoBanner>
        );
    }

    const { successBannerMessage, isDismissable, onDismiss } = cardBanner;

    return <SuccessBanner onDismiss={isDismissable ? onDismiss : undefined}>{successBannerMessage}</SuccessBanner>;
});
