import { DataResponse } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload } from '../core-actions';
import { InitializeMocksSuccessPayload, MocksActionType } from './mocks-actions';

/**
 * Action creators
 */

export const initializeMocks = createActionCreatorWithoutPayload<DataResponse>(MocksActionType.InitializeMocks, {
    async: true,
});

export const initializeMocksError = createActionCreator<ErrorPayload>(MocksActionType.InitializeMocksError);

export const initializeMocksSuccess = createActionCreator<InitializeMocksSuccessPayload>(
    MocksActionType.InitializeMocksSuccess
);
