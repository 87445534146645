import { MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { PropsWithChildren } from '../../types/props-with-children';

type SuccessBannerProps = PropsWithChildren<{ onDismiss?: () => void }>;

export const SuccessBanner: React.FC<SuccessBannerProps> = (props: SuccessBannerProps) => {
    const { children, onDismiss } = props;

    return (
        <MessageBar delayedRender={false} messageBarType={MessageBarType.success} onDismiss={onDismiss}>
            {children}
        </MessageBar>
    );
};

export default SuccessBanner;
