import { tryOrDefault } from '../try-or-default';
import { createResourceId } from './create-resource-id';
import { getResourceIdComponents } from './get-resource-id-components';

export const getParentResourceId = (resourceId: string): string => {
    const [subscriptionId, resourceGroupName, providerNamespace, type, name] = getResourceIdComponents(
        resourceId,
        'subscriptionId',
        'resourceGroupName',
        'resourceProviderNamespace',
        'resourceType',
        'resourceName'
    );

    return createResourceId(subscriptionId, resourceGroupName, { name, providerNamespace, type });
};

export const tryGetParentResourceId = tryOrDefault(getParentResourceId);
