import { UnionMap, UnionValueMap } from '../types/union-map';

export type WindowsStoreLinkName = 'WindowsApp' | 'RemoteDesktop';

export const WindowsStoreLinkName: UnionMap<WindowsStoreLinkName> = {
    WindowsApp: 'WindowsApp',
    RemoteDesktop: 'RemoteDesktop',
};

export const WindowsAppLink = 'https://aka.ms/devcenter/devportal/install-windows-app';
export const RemoteDesktopLink = 'https://aka.ms/devcenter/devportal/install-rd-client';

export const WindowsStoreLinks: UnionValueMap<WindowsStoreLinkName, string> = {
    WindowsApp: WindowsAppLink,
    RemoteDesktop: RemoteDesktopLink,
};
