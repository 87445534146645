import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
    ListEnvironmentDefinitionsResponse,
    listEnvironmentDefinitionsByProject,
} from '../../../data/services/data-plane-api/environment-definition';
import { ClientError, FailureOperation, isSuccessResponse } from '../../../models/common';
import {
    listEnvironmentDefinitions,
    listEnvironmentDefinitionsError,
    listEnvironmentDefinitionsFailed,
    listEnvironmentDefinitionsSuccess,
} from '../../actions/environment-definition/environment-definition-action-creators';
import { ListEnvironmentDefinitionsAction } from '../../actions/environment-definition/environment-definition-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listEnvironmentDefinitionsSaga(action: ListEnvironmentDefinitionsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListEnvironmentDefinitionsResponse = yield call(
            listEnvironmentDefinitionsByProject,
            id,
            accessToken,
            activityId
        );

        if (isSuccessResponse(response)) {
            yield put(listEnvironmentDefinitionsSuccess({ id, result: response.data }, meta));
        } else {
            yield put(listEnvironmentDefinitionsFailed({ failure: response, id }, meta));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListEnvironmentDefinitionsByProject);

        yield put(listEnvironmentDefinitionsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listEnvironmentDefinitionsListenerSaga(): SagaIterator {
    yield takeEvery(listEnvironmentDefinitions, listEnvironmentDefinitionsSaga);
}
