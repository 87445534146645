import { FontSizes, Icon, IStackTokens, ITheme, makeStyles, Shimmer, Stack } from '@fluentui/react';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { getSemanticColor } from '../../../utilities/styles';

interface MetadataItemProps {
    content: React.ReactNode | (() => React.ReactNode);
    highlight?: boolean;
    iconAriaLabel: string;
    iconName: string;
    marginBottom?: number;
    marginTop?: number;
}

export const ShimmeredMetadataItemWidth = {
    XSmall: 60,
    Small: 90,
    Medium: 140,
    Large: 208,
};

export type ShimmeredMetadataItemWidth = typeof ShimmeredMetadataItemWidth[keyof typeof ShimmeredMetadataItemWidth];

export interface ShimmeredMetadataItemProps {
    /** If no width is provided, defaults to ShimmeredMetadataItemWidth.Large */
    width?: ShimmeredMetadataItemWidth;
}

const isRenderFunction = (value: React.ReactNode | (() => React.ReactNode)): value is () => React.ReactNode =>
    typeof value === 'function';

/**
 * Styles
 */

const metadataItemStylesFactory = (marginBottom?: number, marginTop?: number, textColor?: boolean) =>
    makeStyles((theme: ITheme) => ({
        content: {
            fontSize: FontSizes.size12,
            lineHeight: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        icon: {
            fontSize: FontSizes.size16,
            height: 16,
            lineHeight: 16,
            verticalAlign: 'bottom',
            width: 16,
        },
        root: {
            height: 16,
            ...(marginBottom !== undefined ? { marginBottom } : {}),
            ...(marginTop !== undefined ? { marginTop } : {}),
            ...(textColor ? { color: getSemanticColor(theme, 'metadataItemHighlightedTextColor') } : {}),
        },
    }));

const shimmerStylesFactory = (width = ShimmeredMetadataItemWidth.Large) =>
    makeStyles({
        root: {
            width: `${width}px`,
        },
    });

/**
 * END styles
 */

const rootStackTokens: IStackTokens = {
    childrenGap: '8px',
};

export const ShimmeredMetadataItem: React.FC<ShimmeredMetadataItemProps> = (props: ShimmeredMetadataItemProps) => {
    const { width } = props;

    const useMetadataItemStyles = useDynamicMakeStyles(metadataItemStylesFactory);
    const metadataItemStyles = useMetadataItemStyles();
    const useShimmerStyles = useDynamicMakeStyles(shimmerStylesFactory, width);
    const shimmerStyles = useShimmerStyles();

    return (
        <Stack horizontal className={metadataItemStyles.root} tokens={rootStackTokens}>
            <Shimmer styles={shimmerStyles} />
        </Stack>
    );
};

export const MetadataItem: React.FC<MetadataItemProps> = (props: MetadataItemProps) => {
    const { content, highlight, iconAriaLabel, iconName, marginBottom, marginTop } = props;

    // Style hooks
    const useMetadataItemStyles = useDynamicMakeStyles(metadataItemStylesFactory, marginBottom, marginTop, highlight);
    const metadataItemStyles = useMetadataItemStyles();

    return (
        <Stack horizontal className={metadataItemStyles.root} tokens={rootStackTokens}>
            <Icon aria-label={iconAriaLabel} className={metadataItemStyles.icon} iconName={iconName} />
            <span className={metadataItemStyles.content}>{isRenderFunction(content) ? content() : content}</span>
        </Stack>
    );
};

export default MetadataItem;
