import { StorageType } from '../../../constants/storage';
import { DataResponse } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, ResultPayload } from '../core-actions';
import {
    GetStorageValueErrorPayload,
    GetStorageValueFailedPayload,
    GetStorageValuePayload,
    GetStorageValueSuccessPayload,
    SetStorageValueErrorPayload,
    SetStorageValueFailedPayload,
    SetStorageValuePayload,
    SetStorageValueSuccessPayload,
    StorageActionType,
} from './storage-actions';

/**
 * Action creators
 */

export const getStorageValue = createActionCreator<GetStorageValuePayload, DataResponse<string>>(
    StorageActionType.GetStorageValue,
    { async: true }
);

export const getStorageValueError = createActionCreator<GetStorageValueErrorPayload>(
    StorageActionType.GetStorageValueError
);

export const getStorageValueFailed = createActionCreator<GetStorageValueFailedPayload>(
    StorageActionType.GetStorageValueFailed
);

export const getStorageValueSuccess = createActionCreator<GetStorageValueSuccessPayload>(
    StorageActionType.GetStorageValueSuccess
);

export const initializeStorage = createActionCreatorWithoutPayload<DataResponse<StorageType>>(
    StorageActionType.InitializeStorage,
    { async: true }
);

export const initializeStorageError = createActionCreator<ErrorPayload>(StorageActionType.InitializeStorageError);

export const initializeStorageSuccess = createActionCreator<ResultPayload<StorageType>>(
    StorageActionType.InitializeStorageSuccess
);

export const setStorageValue = createActionCreator<SetStorageValuePayload, DataResponse>(
    StorageActionType.SetStorageValue,
    { async: true }
);

export const setStorageValueError = createActionCreator<SetStorageValueErrorPayload>(
    StorageActionType.SetStorageValueError
);

export const setStorageValueFailed = createActionCreator<SetStorageValueFailedPayload>(
    StorageActionType.SetStorageValueFailed
);

export const setStorageValueSuccess = createActionCreator<SetStorageValueSuccessPayload>(
    StorageActionType.SetStorageValueSuccess
);
