import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { PhotoSize } from '../../../constants/graph';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { GraphDirectoryObject, GraphUser } from '../../../models/graph';
import {
    getBannerLogoForOrganization,
    getOrganization,
    initializeOrganizationMetadata,
    initializeOrganizationMetadataError,
    initializeOrganizationMetadataFailed,
    initializeOrganizationMetadataSuccess,
} from '../../actions/graph/organization-action-creators';
import {
    getPhotoForSignedInUser,
    getSignedInUser,
    initializeUserMetadata,
    initializeUserMetadataError,
    initializeUserMetadataFailed,
    initializeUserMetadataSuccess,
} from '../../actions/graph/signed-in-user-action-creators';
import { InitializeUserMetadataAction } from '../../actions/graph/signed-in-user-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

export function* initializeOrganizationMetadataSaga(): SagaIterator {
    try {
        const response: DataResponse<GraphDirectoryObject> = yield putAndAwait(getOrganization());

        if (isFailureResponse(response)) {
            yield put(initializeOrganizationMetadataFailed({ failure: response }));
            return;
        }

        // Fetch branding of the current organization
        yield put(getBannerLogoForOrganization({ id: response.data.id }));

        // Succeed; request for banner is fire-and-forget
        yield put(initializeOrganizationMetadataSuccess());
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeOrganizationMetadata);
        yield put(initializeOrganizationMetadataError({ error }));
    }
}

export function* initializeOrganizationMetadataListenerSaga(): SagaIterator {
    yield takeLeading(initializeOrganizationMetadata, initializeOrganizationMetadataSaga);
}

export function* initializeUserMetadataSaga(action: InitializeUserMetadataAction): SagaIterator {
    try {
        // Initiate request to get profile picture (fire-and-forget)
        yield put(getPhotoForSignedInUser({ photoSize: PhotoSize.Square48 }));

        // Get signed-in user metadata
        const response: DataResponse<GraphUser> = yield putAndAwait(getSignedInUser());

        if (isFailureResponse(response)) {
            yield put(initializeUserMetadataFailed({ failure: response }));
            yield resolveAction(action, response);
            return;
        }

        yield put(initializeUserMetadataSuccess());
        yield resolveAction(action, { succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeUserMetadata);
        yield put(initializeUserMetadataError({ error }));
        yield rejectAction(action, error);
    }
}

export function* initializeUserMetadataListenerSaga(): SagaIterator {
    yield takeLeading(initializeUserMetadata, initializeUserMetadataSaga);
}
