import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import { getDayOfTheWeek, getTimeStringFromDate } from '../../../utilities/time';

export const getScheduleMetadataMessageValues = (
    scheduledTime: Date,
    locale: string
): { displayTime: string | undefined; dayOfTheWeek: string } => {
    const upcomingActionDisplayTime = getTimeStringFromDate(scheduledTime, locale);

    //If there is no upcoming action, we do not display anything on the card.
    const displayTime = isNotUndefinedOrWhiteSpace(upcomingActionDisplayTime) ? upcomingActionDisplayTime : undefined;

    const dayOfTheWeek = getDayOfTheWeek(scheduledTime as Date);

    return { displayTime, dayOfTheWeek };
};
