import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ListProjectsResponse, listProjects } from '../../../data/services/discovery-service/list-projects';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    listProjects as listProjectsActionCreator,
    listProjectsError,
    listProjectsFailed,
    listProjectsSuccess,
} from '../../actions/project/project-action-creators';
import { ListProjectsAction } from '../../actions/project/project-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLatest } from '../../effects/take';
import { getTenantId } from '../../selector/identity-selectors';

export function* listProjectsSaga(action: ListProjectsAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const tenantId: string = yield select(getTenantId);

        const response: ListProjectsResponse = yield call(listProjects, accessToken, tenantId, activityId);

        if (isFailureResponse(response)) {
            yield put(listProjectsFailed({ failure: response }, meta));
        } else {
            const { data } = response;
            yield put(listProjectsSuccess({ result: data }, meta));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListProjects);
        yield put(listProjectsError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* listProjectsListenerSaga(): SagaIterator {
    yield takeLatest(listProjectsActionCreator, listProjectsSaga);
}
