// Justification: This file contains the wrapper effects we want to use instead of the redux-saga effects
/* eslint-disable no-restricted-imports */
import {
    ForkEffect,
    takeEvery as reduxSagaTakeEvery,
    takeLatest as reduxSagaTakeLatest,
    takeLeading as reduxSagaTakeLeading,
} from 'redux-saga/effects';
/* eslint-enable no-restricted-imports */
import { ActionCreatorPattern } from '../actions/core-action-creators';
import { Action } from '../actions/core-actions';

type ActionWorker<P, Meta, Awaited, TType extends string> = (action: Action<P, Meta, Awaited, TType>) => unknown;

const getActionPattern = <P, Meta, Awaited, TType extends string, TActionPayload>(
    actionCreatorPattern: ActionCreatorPattern<P, Meta, Awaited, TType, TActionPayload>
): string | string[] =>
    Array.isArray(actionCreatorPattern)
        ? actionCreatorPattern.map((actionCreator) => actionCreator.type)
        : actionCreatorPattern.type;

export const takeLeading = <
    P,
    Meta,
    Awaited,
    TType extends string,
    TActionPayload,
    TWorker extends ActionWorker<TActionPayload, Meta, Awaited, TType>
>(
    actionCreatorPattern: ActionCreatorPattern<P, Meta, Awaited, TType, TActionPayload>,
    worker: TWorker
): ForkEffect<never> => reduxSagaTakeLeading(getActionPattern(actionCreatorPattern), worker);

export const takeEvery = <
    P,
    Meta,
    Awaited,
    TType extends string,
    TActionPayload,
    TWorker extends ActionWorker<TActionPayload, Meta, Awaited, TType>
>(
    actionCreatorPattern: ActionCreatorPattern<P, Meta, Awaited, TType, TActionPayload>,
    worker: TWorker
): ForkEffect<never> => reduxSagaTakeEvery(getActionPattern(actionCreatorPattern), worker);

export const takeLatest = <
    P,
    Meta,
    Awaited,
    TType extends string,
    TActionPayload,
    TWorker extends ActionWorker<TActionPayload, Meta, Awaited, TType>
>(
    actionCreatorPattern: ActionCreatorPattern<P, Meta, Awaited, TType, TActionPayload>,
    worker: TWorker
): ForkEffect<never> => reduxSagaTakeLatest(getActionPattern(actionCreatorPattern), worker);
