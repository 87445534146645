import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { EventName } from '../../constants/telemetry';
import unauthorizedImage from '../../content/images/Blocked.svg';
import {
    CannotCreateDevBoxReason,
    CannotCreateEnvironmentReason,
} from '../../redux/selector/display/create-ability/models';
import { trackEvent } from '../../utilities/telemetry/channel';
import { MisconfiguredState } from './misconfigured-state';

export interface CannotCreateResourcesProps {
    isEnvironmentUser: boolean;
    isDevBoxUser: boolean;
    cannotCreateEnvironmentReason?: CannotCreateEnvironmentReason;
    cannotCreateDevBoxReason?: CannotCreateDevBoxReason;
}

const messages = defineMessages({
    unauthorizedImageAlt: {
        id: 'CannotCreateDevBoxesElement_UnauthorizedImage_Alt',
        defaultMessage: 'Cannot create dev boxes',
        description: 'Alt text for "cannot create dev boxes" image',
    },
    cannotCreateDevBoxesTitle: {
        id: 'CannotCreateDevBoxesElement_CannotCreateDevBoxesTitle_Text',
        defaultMessage: 'Cannot create dev boxes',
        description: "Title of message informing user that they aren't authorized to create dev boxes",
    },
    notAuthorizedToCreateDevBoxesDescription: {
        id: 'CannotCreateDevBoxesElement_NoPermissionsToCreateDevBoxes_Text',
        defaultMessage:
            'Your account does not have access to create dev boxes in any of the projects in this organization. Please contact your administrator to gain access.',
        description: "Content of message informing user that they aren't authorized to create dev boxes",
    },
    noPoolsToCreateDevBoxesTitle: {
        id: 'NoDevBoxPoolsAvailable_NoDevBoxPoolsAvailableTitle_Text',
        defaultMessage: 'No dev box pools available',
        description: 'Title of message informing user that no dev box pools are available to them',
    },
    noPoolsToCreateDevBoxesDescription: {
        id: 'NoDevBoxPoolsAvailable_NoDevBoxPoolsAvailableDescription_Text',
        defaultMessage:
            'Your administrator has not created any dev box pools to enable dev box creation. Please contact your administrator to request an update, or try again later.',
        description: 'Content of message informing user that no dev box pools are available to them',
    },
    cannotCreateEnvironmentsTitle: {
        id: 'CannotCreateResourcesElement_CannotCreateEnvironmentsTitle_Text',
        defaultMessage: 'Cannot create environments',
        description: "Title of message informing user that they aren't authorized to create environments",
    },
    notAuthorizedToCreateEnvironmentsDescription: {
        id: 'CannotCreateResourcesElement_NoPermissionsToCreateEnvironments_Text',
        defaultMessage:
            'Your account does not have access to create environments in any of the projects in this organization. Please contact your administrator to gain access.',
        description: "Content of message informing user that they aren't authorized to create environments",
    },
    noResourcesToCreateEnvironmentsDescription: {
        id: 'CannotCreateResourcesElement_NoEnvironmentCreateResourcesAvailable_Text',
        defaultMessage:
            'Your administrator has not configured resources to enable environment creation. Please contact your administrator to request an update, or try again later.',
        description: 'Content of message informing user that no resources for environment create are available to them',
    },
    cannotCreateResourcesTitle: {
        id: 'CannotCreateResourcesElement_CannotCreateResourcesTitle_Text',
        defaultMessage: 'Cannot create resources',
        description: "Title of message informing user that they aren't authorized to create resources",
    },
    notAuthorizedToCreateResourcesDescription: {
        id: 'CannotCreateResourcesElement_NoPermissionsToCreateResources_Text',
        defaultMessage:
            'Your account does not have access to create dev boxes or environments in any of the projects in this organization. Please contact your administrator to gain access.',
        description:
            "Content of message informing user that they aren't authorized to create dev boxes or environments",
    },
    noResourcesToCreateResourcesDescription: {
        id: 'CannotCreateResourcesElement_NoCreateResourcesAvailable_Text',
        defaultMessage:
            'Your administrator has not configured resources to enable dev box or environment creation. Please contact your administrator to request an update, or try again later.',
        description:
            'Content of message informing user that no resources for dev box or environment create are available to them',
    },
    notAuthorizedToCreateDevBoxesNoResourcesToCreateEnvironmentsDescription: {
        id: 'CannotCreateResourcesElement_DevBoxNotAuthorizedEnvironmentHasNoResources_Text',
        defaultMessage:
            'Your account does not have access to create dev boxes, and your administrator has not configured resources to enable environment creation. Please contact your administrator to request an update, or try again later.',
        description:
            'Content of message informing user that they are not authorized to create dev boxes, and no resources for environment create are available to them',
    },
    notAuthorizedToCreateEnvironmentsNoResourcesToCreateDevBoxesDescription: {
        id: 'CannotCreateResourcesElement_EnvironmentNotAuthorizedDevBoxHasNoResources_Text',
        defaultMessage:
            'Your account does not have access to create environments, and your administrator has not configured resources to enable dev box creation. Please contact your administrator to request an update, or try again later.',
        description:
            'Content of message informing user that they are not authorized to create environments, and no resources for dev box create are available to them',
    },
});

export const CannotCreateResourcesElement: React.FC<CannotCreateResourcesProps> = React.memo(
    (props: CannotCreateResourcesProps) => {
        const { isEnvironmentUser, isDevBoxUser, cannotCreateDevBoxReason, cannotCreateEnvironmentReason } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Effect hooks
        const isDevBoxUserWhoCannotCreateDevBoxes = isDevBoxUser && !!cannotCreateDevBoxReason;
        const isEnvironmentUserWhoCannotCreateEnvironments = isEnvironmentUser && !!cannotCreateEnvironmentReason;

        React.useEffect(() => {
            if (!isDevBoxUserWhoCannotCreateDevBoxes && !isEnvironmentUserWhoCannotCreateEnvironments) {
                // We have called this component erroneously, log this information
                trackEvent(EventName.CannotCreateResourcesElementIncorrectlyInvoked, {
                    properties: {
                        isEnvironmentUser: `${isEnvironmentUser}`,
                        isDevBoxUser: `${isDevBoxUser}`,
                        cannotCreateDevBoxReason: `${cannotCreateDevBoxReason}`,
                        cannotCreateEnvironmentReason: `${cannotCreateEnvironmentReason}`,
                    },
                });
            }
        }, [isDevBoxUserWhoCannotCreateDevBoxes, isEnvironmentUserWhoCannotCreateEnvironments]);

        // Memoized data
        const title = React.useMemo((): string => {
            if (isDevBoxUser && !isEnvironmentUser) {
                return formatMessage(messages.cannotCreateDevBoxesTitle);
            }
            if (isEnvironmentUser && !isDevBoxUser) {
                return formatMessage(messages.cannotCreateEnvironmentsTitle);
            }

            return formatMessage(messages.cannotCreateResourcesTitle);
        }, [formatMessage, isDevBoxUser, isEnvironmentUser]);

        const description = React.useMemo((): string => {
            // User is only dev box user
            if (isDevBoxUser && !isEnvironmentUser) {
                switch (cannotCreateDevBoxReason) {
                    case CannotCreateDevBoxReason.NoAuthorizedProjectsHavePools:
                        return formatMessage(messages.noPoolsToCreateDevBoxesDescription);
                    case CannotCreateDevBoxReason.HasNoAuthorizedProjects:
                    default:
                        return formatMessage(messages.notAuthorizedToCreateDevBoxesDescription);
                }
            }

            // User is only environment user
            if (isEnvironmentUser && !isDevBoxUser) {
                switch (cannotCreateEnvironmentReason) {
                    case CannotCreateEnvironmentReason.NoAuthorizedProjectsHaveResources:
                        return formatMessage(messages.noResourcesToCreateEnvironmentsDescription);
                    case CannotCreateEnvironmentReason.HasNoAuthorizedProjects:
                    default:
                        return formatMessage(messages.notAuthorizedToCreateEnvironmentsDescription);
                }
            }

            // User is dev box and environment user
            if (
                cannotCreateDevBoxReason === CannotCreateDevBoxReason.HasNoAuthorizedProjects &&
                cannotCreateEnvironmentReason === CannotCreateEnvironmentReason.HasNoAuthorizedProjects
            ) {
                return formatMessage(messages.notAuthorizedToCreateResourcesDescription);
            }

            if (
                cannotCreateDevBoxReason === CannotCreateDevBoxReason.NoAuthorizedProjectsHavePools &&
                cannotCreateEnvironmentReason === CannotCreateEnvironmentReason.NoAuthorizedProjectsHaveResources
            ) {
                return formatMessage(messages.noResourcesToCreateResourcesDescription);
            }

            if (
                cannotCreateDevBoxReason === CannotCreateDevBoxReason.HasNoAuthorizedProjects &&
                cannotCreateEnvironmentReason === CannotCreateEnvironmentReason.NoAuthorizedProjectsHaveResources
            ) {
                return formatMessage(messages.notAuthorizedToCreateDevBoxesNoResourcesToCreateEnvironmentsDescription);
            }

            if (
                cannotCreateDevBoxReason === CannotCreateDevBoxReason.NoAuthorizedProjectsHavePools &&
                cannotCreateEnvironmentReason === CannotCreateEnvironmentReason.HasNoAuthorizedProjects
            ) {
                return formatMessage(messages.notAuthorizedToCreateEnvironmentsNoResourcesToCreateDevBoxesDescription);
            }

            // We should never get here, but if we do, return a generic resources description
            return formatMessage(messages.notAuthorizedToCreateResourcesDescription);
        }, [isDevBoxUser, isEnvironmentUser, cannotCreateDevBoxReason, cannotCreateEnvironmentReason]);

        return (
            <MisconfiguredState
                title={title}
                description={description}
                imageSrc={unauthorizedImage}
                imageAlt={formatMessage(messages.unauthorizedImageAlt)}
            />
        );
    }
);
