import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload } from '../core-actions';
import {
    getBannerLogoForOrganization,
    getOrganization,
    initializeOrganizationMetadata,
} from './organization-action-creators';

/**
 * Action types
 */

type OrganizationActionName =
    | ActionName<'GetBannerLogoForOrganization', 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetOrganization', 'Error' | 'Failed' | 'Success'>
    | ActionName<'InitializeOrganizationMetadata', 'Error' | 'Failed' | 'Success'>;

export type OrganizationActionType =
    | ActionType<'GET_BANNER_LOGO_FOR_ORGANIZATION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_ORGANIZATION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'INITIALIZE_ORGANIZATION_METADATA', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const OrganizationActionType: UnionValueMap<OrganizationActionName, OrganizationActionType> = {
    GetBannerLogoForOrganization: 'GET_BANNER_LOGO_FOR_ORGANIZATION',
    GetBannerLogoForOrganizationError: 'GET_BANNER_LOGO_FOR_ORGANIZATION_ERROR',
    GetBannerLogoForOrganizationFailed: 'GET_BANNER_LOGO_FOR_ORGANIZATION_FAILED',
    GetBannerLogoForOrganizationSuccess: 'GET_BANNER_LOGO_FOR_ORGANIZATION_SUCCESS',
    GetOrganization: 'GET_ORGANIZATION',
    GetOrganizationError: 'GET_ORGANIZATION_ERROR',
    GetOrganizationFailed: 'GET_ORGANIZATION_FAILED',
    GetOrganizationSuccess: 'GET_ORGANIZATION_SUCCESS',
    InitializeOrganizationMetadata: 'INITIALIZE_ORGANIZATION_METADATA',
    InitializeOrganizationMetadataError: 'INITIALIZE_ORGANIZATION_METADATA_ERROR',
    InitializeOrganizationMetadataFailed: 'INITIALIZE_ORGANIZATION_METADATA_FAILED',
    InitializeOrganizationMetadataSuccess: 'INITIALIZE_ORGANIZATION_METADATA_SUCCESS',
};

/**
 * Payloads
 */

export interface GetBannerLogoForOrganizationSuccessPayload extends IndexedPayload {
    bannerLogoUrl: string;
}

/**
 * Action shorthands
 */

export type GetBannerLogoForOrganizationAction = ReturnType<typeof getBannerLogoForOrganization>;
export type GetOrganizationAction = ReturnType<typeof getOrganization>;
export type InitializeOrganizationMetadataAction = ReturnType<typeof initializeOrganizationMetadata>;
