import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { GetRDGatewayHealthResponse, getRdGatewayHealth } from '../../../data/services/rd-gateway/rd-gateway-health';
import { ClientError, FailureOperation, FailureResponse, isFailureResponse } from '../../../models/common';
import { getRegionFromRDGatewayUrl } from '../../../utilities/rd-gateway-url';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    getClosestRDGatewayRegion,
    getClosestRDGatewayRegionError,
    getClosestRDGatewayRegionFailed,
    getClosestRDGatewayRegionSuccess,
} from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-action-creators';
import { GetClosestRDGatewayRegionAction } from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

function* getClosestRDGatewayRegionSaga(action: GetClosestRDGatewayRegionAction): SagaIterator {
    try {
        const rdGatewayHealthResponse: GetRDGatewayHealthResponse = yield call(getRdGatewayHealth);

        if (isFailureResponse(rdGatewayHealthResponse)) {
            yield put(getClosestRDGatewayRegionFailed({ failure: rdGatewayHealthResponse }));
            yield resolveAction(action, rdGatewayHealthResponse);

            return;
        }

        const regionUrl = rdGatewayHealthResponse.data.RegionUrl;
        const region: string | undefined = yield call(getRegionFromRDGatewayUrl, regionUrl);

        if (isUndefinedOrWhiteSpace(region)) {
            const failure = FailureResponse({
                code: 'CouldNotParseRegion',
                message: `Could not parse region from RD Gateway URL: ${regionUrl}`,
                operation: FailureOperation.GetClosestRDGatewayRegion,
            });

            yield put(getClosestRDGatewayRegionFailed({ failure }));
            yield resolveAction(action, failure);

            return;
        }

        yield put(getClosestRDGatewayRegionSuccess({ result: region }));
        yield resolveAction(action, { data: region, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetClosestRDGatewayRegion);
        yield put(getClosestRDGatewayRegionError({ error }));
        yield rejectAction(action, error);
    }
}

export function* getClosestRDGatewayRegionListenerSaga(): SagaIterator {
    yield takeLeading(getClosestRDGatewayRegion, getClosestRDGatewayRegionSaga);
}
