import { SagaIterator } from '@redux-saga/types';
import { call, put } from 'redux-saga/effects';
import {
    ListCustomizationTaskDefinitionsResponse,
    listCustomizationTaskDefinitions,
} from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    listCustomizationTaskDefinitions as listCustomizationTaskDefinitionsActionCreator,
    listCustomizationTaskDefinitionsError,
    listCustomizationTaskDefinitionsFailed,
    listCustomizationTaskDefinitionsSuccess,
} from '../../actions/customization/customization-action-creators';
import { ListCustomizationTaskDefinitionsAction } from '../../actions/customization/customization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listCustomizationTaskDefinitionsSaga(action: ListCustomizationTaskDefinitionsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListCustomizationTaskDefinitionsResponse = yield call(
            listCustomizationTaskDefinitions,
            id,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(listCustomizationTaskDefinitionsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;

        yield put(listCustomizationTaskDefinitionsSuccess({ id, result: data }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListCustomizationTaskDefinitions);
        yield put(listCustomizationTaskDefinitionsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listCustomizationTaskDefinitionsListenerSaga(): SagaIterator {
    yield takeEvery(listCustomizationTaskDefinitionsActionCreator, listCustomizationTaskDefinitionsSaga);
}
