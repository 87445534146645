import { createSelector } from 'reselect';
import { FeatureFlagName } from '../../../../constants/features';
import { isFeatureFlagEnabled } from '../../../../utilities/features';
import { size, some } from '../../../../utilities/serializable-map';
import { getIsSingleDevCenterMode } from '../../../../utilities/single-dev-center';
import { ActiveAccountState } from '../../../store/identity-state';
import { StoreStateSelector, isStatusTerminal, isStatusUnsuccessful } from '../../common';
import { getEnvironmentDefinitionsByProject } from '../../environment-definition-selectors';
import { getActiveAccountState } from '../../identity-selectors';
import { getPoolsByProject } from '../../pool-selectors';
import { getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject } from '../../project-environment-type-from-dataplane-selectors';
import { getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject } from '../../project-environment-type-selectors';
import {
    getDoesProjectFromDiscoveryServiceHaveAtLeastOneEnvironmentDefinition,
    getDoesProjectFromDiscoveryServiceHaveAtLeastOneProjectEnvironmentType,
    getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
    getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
    getProjectsByDiscoveryServiceURI,
} from '../../project-from-discovery-service-selectors';
import {
    getDoesProjectHaveAtLeastOneEnvironmentDefinition,
    getDoesProjectHaveAtLeastOneProjectEnvironmentType,
    getHasProjectsAuthorizedForDevBoxCreate,
    getHasProjectsAuthorizedForEnvironmentWrite,
    getProjectsByDataPlaneId,
} from '../../project-selectors';
import {
    getStatusForLoadAddDevBoxFormContent,
    getStatusForLoadAddEnvironmentFormContent,
} from '../../sub-applications/home-selectors';
import { getCountOfProjectsInSingleDevCenter } from '../../sub-applications/single-dev-center-selectors';
import {
    getDidSomeProjectFailToLoadDevBoxCreateResources,
    getDidSomeProjectFailToLoadEnvironmentCreateResources,
} from '../load-state';
import { ResourceUserState } from '../user-state/models';
import { getDevBoxUserState, getEnvironmentUserState } from '../user-state/selectors';
import {
    ActionAbility,
    CannotCreateDevBoxReason,
    CannotCreateEnvironmentReason,
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
    ResourcesToWriteDevBoxesState,
    ResourcesToWriteEnvironmentsState,
    isResourcesToWriteDevBoxesMisconfiguredState,
    isResourcesToWriteEnvironmentsMisconfiguredState,
} from './models';

/**
 * Composed selectors
 */

export const getResourcesToWriteDevBoxesState: StoreStateSelector<ResourcesToWriteDevBoxesState> = createSelector(
    [
        getStatusForLoadAddDevBoxFormContent,
        getHasProjectsAuthorizedForDevBoxCreate,
        getHasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        getPoolsByProject,
        getDidSomeProjectFailToLoadDevBoxCreateResources,
        getIsSingleDevCenterMode,
        getCountOfProjectsInSingleDevCenter,
    ],
    (
        statusForLoadAddDevBoxFormContent,
        hasProjectsAuthorizedForDevBoxCreate,
        hasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        poolsByProject,
        didSomeProjectFailToLoadDevBoxCreateResources,
        isSingleDevCenterMode,
        countOfProjectsInSingleDevCenter
    ) => {
        if (isStatusUnsuccessful(statusForLoadAddDevBoxFormContent)) {
            return ResourcesToWriteDevBoxesState.FailedToLoad;
        }

        if (!isStatusTerminal(statusForLoadAddDevBoxFormContent)) {
            return ResourcesToWriteDevBoxesState.Unknown;
        }

        // Determine if we are authorized to write
        // (in single dev center mode, we can't load permissions, so all projects are considered authorized)
        const authorizedForDevBoxCreate = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? hasProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate
            : hasProjectsAuthorizedForDevBoxCreate;

        if (
            (!isSingleDevCenterMode && !authorizedForDevBoxCreate) ||
            (isSingleDevCenterMode && countOfProjectsInSingleDevCenter === 0)
        ) {
            return ResourcesToWriteDevBoxesState.HasNoAuthorizedProjects;
        }

        // Determine if we have resources to write
        // (We only load pools for authorized projects, so checking if any project has pools is sufficient)
        const someAuthorizedProjectHasPool = some(poolsByProject, (pools) => size(pools) > 0);

        if (someAuthorizedProjectHasPool) {
            return ResourcesToWriteDevBoxesState.HasAtLeastOneAuthorizedProjectWithPools;
        }

        // If we don't have resources to write, did we fail to load create resources for some project?
        if (didSomeProjectFailToLoadDevBoxCreateResources) {
            return ResourcesToWriteDevBoxesState.FailedToLoad;
        }

        // We don't have resources to write and did not fail to load, this is a true no-resource state
        return ResourcesToWriteDevBoxesState.NoAuthorizedProjectsHavePools;
    }
);

export const getDevBoxCreateAbilityState: StoreStateSelector<DevBoxCreateAbilityState> = createSelector(
    [getDevBoxUserState, getActiveAccountState, getResourcesToWriteDevBoxesState],
    (
        devBoxUserState: ResourceUserState,
        activeAccountState: ActiveAccountState,
        hasResourcesToWriteDevBoxesState: ResourcesToWriteDevBoxesState
    ) => {
        // Is signed in?
        if (
            activeAccountState === ActiveAccountState.NotSignedIn ||
            activeAccountState === ActiveAccountState.Unknown
        ) {
            return { createAbility: ActionAbility.Unknown };
        }

        // Is dev box user?
        if (devBoxUserState === ResourceUserState.IsNotUser) {
            return {
                createAbility: ActionAbility.CannotPerformAction,
                reason: CannotCreateDevBoxReason.IsNotDevBoxUser,
            };
        }

        // Is in guest account?
        if (
            activeAccountState === ActiveAccountState.GuestAccount &&
            !isFeatureFlagEnabled(FeatureFlagName.EnableGuestUser)
        ) {
            return { createAbility: ActionAbility.CannotPerformAction, reason: CannotCreateDevBoxReason.GuestAccount };
        }

        // Determine if we have resources to write
        if (isResourcesToWriteDevBoxesMisconfiguredState(hasResourcesToWriteDevBoxesState)) {
            return { createAbility: ActionAbility.CannotPerformAction, reason: hasResourcesToWriteDevBoxesState };
        }

        switch (hasResourcesToWriteDevBoxesState) {
            case ResourcesToWriteDevBoxesState.Unknown:
                return { createAbility: ActionAbility.Unknown };
            case ResourcesToWriteDevBoxesState.FailedToLoad:
                return { createAbility: ActionAbility.FailedToLoad };
            case ResourcesToWriteDevBoxesState.HasAtLeastOneAuthorizedProjectWithPools:
            default:
                return { createAbility: ActionAbility.CanPerformAction };
        }
    }
);

export const getResourcesToWriteEnvironmentsState: StoreStateSelector<ResourcesToWriteEnvironmentsState> =
    createSelector(
        [
            getStatusForLoadAddEnvironmentFormContent,
            getHasProjectsAuthorizedForEnvironmentWrite,
            getProjectsByDataPlaneId,
            getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject,
            getEnvironmentDefinitionsByProject,
            getDidSomeProjectFailToLoadEnvironmentCreateResources,
            getProjectsByDiscoveryServiceURI,
            getHasProjectsFromDiscoveryServiceAuthorizedForEnvironmentWrite,
            getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject,
        ],
        (
            statusForLoadAddEnvironmentFormContent,
            hasProjectsAuthorizedForEnvironmentCreate,
            projects,
            projectEnvironmentTypesAuthorizedForEnvironmentCreate,
            environmentDefinitionsByProject,
            didSomeProjectFailToLoadEnvironmentCreateResources,
            projectsFromDiscoveryService,
            hasProjectsFromDiscoveryServiceAuthorizedForEnvironmentCreate,
            projectEnvironmentTypesAuthorizedForEnvironmentCreateFromDataplane
        ) => {
            if (isStatusUnsuccessful(statusForLoadAddEnvironmentFormContent)) {
                return ResourcesToWriteEnvironmentsState.FailedToLoad;
            }

            if (!isStatusTerminal(statusForLoadAddEnvironmentFormContent)) {
                return ResourcesToWriteEnvironmentsState.Unknown;
            }

            const isDiscoveryServiceEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

            const authorizedForEnvironmentCreate = isDiscoveryServiceEnabled
                ? hasProjectsFromDiscoveryServiceAuthorizedForEnvironmentCreate
                : hasProjectsAuthorizedForEnvironmentCreate;
            // Determine if we are authorized to write
            if (!authorizedForEnvironmentCreate) {
                return ResourcesToWriteEnvironmentsState.HasNoAuthorizedProjects;
            }

            const someProjectHasResources = isDiscoveryServiceEnabled
                ? some(projectsFromDiscoveryService, (_, id) => {
                      return (
                          getDoesProjectFromDiscoveryServiceHaveAtLeastOneProjectEnvironmentType(
                              id,
                              projectEnvironmentTypesAuthorizedForEnvironmentCreateFromDataplane
                          ) &&
                          getDoesProjectFromDiscoveryServiceHaveAtLeastOneEnvironmentDefinition(
                              id,
                              environmentDefinitionsByProject
                          )
                      );
                  })
                : some(projects, (project, id) => {
                      const { id: resourceId } = project;

                      return (
                          getDoesProjectHaveAtLeastOneProjectEnvironmentType(
                              resourceId,
                              projectEnvironmentTypesAuthorizedForEnvironmentCreate
                          ) && getDoesProjectHaveAtLeastOneEnvironmentDefinition(id, environmentDefinitionsByProject)
                      );
                  });

            if (someProjectHasResources) {
                return ResourcesToWriteEnvironmentsState.HasAtLeastOneAuthorizedProjectWithResources;
            }

            // If we don't have resources to write, did we fail to load data for any projects?
            if (didSomeProjectFailToLoadEnvironmentCreateResources) {
                return ResourcesToWriteEnvironmentsState.FailedToLoad;
            }

            // We don't have resources to write and did not fail to load, this is a true no-resource state
            return ResourcesToWriteEnvironmentsState.NoAuthorizedProjectsHaveResources;
        }
    );

export const getEnvironmentCreateAbilityState: StoreStateSelector<EnvironmentCreateAbilityState> = createSelector(
    [getEnvironmentUserState, getResourcesToWriteEnvironmentsState],
    (environmentUserState, hasResourcesToWriteEnvironmentsState) => {
        // Is environment user?
        if (environmentUserState === ResourceUserState.IsNotUser) {
            return {
                createAbility: ActionAbility.CannotPerformAction,
                reason: CannotCreateEnvironmentReason.IsNotEnvironmentUser,
            };
        }

        // Determine if we have resources to write
        if (isResourcesToWriteEnvironmentsMisconfiguredState(hasResourcesToWriteEnvironmentsState)) {
            return {
                createAbility: ActionAbility.CannotPerformAction,
                reason: hasResourcesToWriteEnvironmentsState,
            };
        }

        switch (hasResourcesToWriteEnvironmentsState) {
            case ResourcesToWriteEnvironmentsState.Unknown:
                return { createAbility: ActionAbility.Unknown };
            case ResourcesToWriteEnvironmentsState.FailedToLoad:
                return { createAbility: ActionAbility.FailedToLoad };
            case ResourcesToWriteEnvironmentsState.HasAtLeastOneAuthorizedProjectWithResources:
            default:
                return { createAbility: ActionAbility.CanPerformAction };
        }
    }
);
