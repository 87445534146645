import { DefaultButton, IIconProps } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Metric, Property } from '../../../constants/telemetry';
import { RemoteAppOption } from '../../../types/user-settings';
import { openInNewWindow } from '../../../utilities/browser';
import { trackMetric } from '../../../utilities/telemetry/channel';

type ConnectViaAppDownloadButtonProps = {
    remoteAppOption?: RemoteAppOption;
};

// TODO when PR Pull Request 570719: Task 2199301: Add links to windows app and avd app from windows store to settings menu is merged, update the links
export const WindowsAppLink = 'https://www.microsoft.com/store/productId/9N1F85V9T8BN?ocid=pdpshare';
export const RemoteDesktopLink = 'https://apps.microsoft.com/detail/9wzdncrfj3ps';

const messages = defineMessages({
    downloadWindowsAppButton: {
        id: 'DownloadWindowsAppButton_Button_Text',
        defaultMessage: 'Get the Windows app',
        description: 'Text for primary action of "Download Windows app" button',
    },
    downloadWindowsAppButtonAriaLabel: {
        id: 'DownloadWindowsAppButton_Button_AriaLabel',
        defaultMessage: 'Get the Windows app',
        description: 'Aria label for primary action of "download windows app" button',
    },
    downloadRemoteDesktopButton: {
        id: 'DownloadRemoteDesktop_Button_Text',
        defaultMessage: 'Get Remote Desktop',
        description: 'Text for "get remote desktop" button',
    },
    downloadRemoteDesktopButtonAriaLabel: {
        id: 'DownloadRemoteDesktop_Button_AriaLabel',
        defaultMessage: 'Get Remote Desktop',
        description: 'Aria label for Text for "get remote desktop" button',
    },
});

const onWindowsAppOptionClicked = (): void => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsAppLink,
        },
    });

    return openInNewWindow(WindowsAppLink);
};

const onRemoteDesktopOptionClicked = (): void => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: RemoteDesktopLink,
        },
    });

    return openInNewWindow(RemoteDesktopLink);
};

export const ConnectViaAppDownloadButton: React.FC<ConnectViaAppDownloadButtonProps> = (
    props: ConnectViaAppDownloadButtonProps
) => {
    // Intl hooks
    const { formatMessage } = useIntl();
    const storeLogoMed20: IIconProps = { iconName: 'StoreLogoMed20' };
    const { remoteAppOption } = props;

    const useWindowsApp = React.useMemo(() => {
        return remoteAppOption === RemoteAppOption.useWindowsApp;
    }, [remoteAppOption]);

    return (
        <DefaultButton
            text={
                useWindowsApp
                    ? formatMessage(messages.downloadWindowsAppButton)
                    : formatMessage(messages.downloadRemoteDesktopButton)
            }
            ariaLabel={
                useWindowsApp
                    ? formatMessage(messages.downloadWindowsAppButtonAriaLabel)
                    : formatMessage(messages.downloadRemoteDesktopButtonAriaLabel)
            }
            iconProps={storeLogoMed20}
            onClick={useWindowsApp ? onWindowsAppOptionClicked : onRemoteDesktopOptionClicked}
        />
    );
};

export default ConnectViaAppDownloadButton;
