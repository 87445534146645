import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { Action } from 'redux';
import { Environment } from '../../constants/app';
import { Header } from '../../constants/http';
import { DependencyType, PerformanceMetric, Property, Severity, TelemetryType } from '../../constants/telemetry';
import { ClientError } from '../../models/common';
import {
    SanitizedTelemetryItem,
    TelemetryFilteringActionTargetForScrub,
    TelemetryMeasurements,
    TelemetryProperties,
} from '../../models/telemetry';
import { tryGetStoreState } from '../../redux/store';
import { AsyncOutcome } from '../../redux/store/common-state';
import { currentEnvironment } from '../app';
import { getMillisecondsBetween } from '../time';
import { parseClientRequestId, parseTraceparentHeader } from '../tracing';
import { tryParseHostnameFromUrlString, tryParsePathnameFromUrlString, tryParseSearchFromUrlString } from '../url';
import config from './configuration';
import { getDependencyKindOrDefault, getSeverityLevelForSeverity, scrubValue } from './helpers';
import rules from './rules';
import { sanitizeTelemetryItem } from './sanitize';

const logToConsole = currentEnvironment !== Environment.Public;
const service = 'fidalgo-devportal';
const version = process.env.REACT_APP_VERSION;

let appInsights!: ApplicationInsights;
let isInitialized = false;

interface OptionsWithActivityId {
    activityId?: string;
}

interface OptionsWithMeasurements {
    measurements?: TelemetryMeasurements;
}

interface OptionsWithProperties {
    properties?: TelemetryProperties;
}

interface OptionsWithSeverity {
    severity?: Severity;
}

export interface TrackDependencyOptions extends OptionsWithActivityId, OptionsWithMeasurements, OptionsWithProperties {
    correlationContext?: string;
    data?: string;
    id?: string;
    target?: string;
}

export type TrackEventOptions = OptionsWithActivityId &
    OptionsWithMeasurements &
    OptionsWithProperties &
    OptionsWithSeverity;

export interface TrackExceptionOptions
    extends OptionsWithActivityId,
        OptionsWithMeasurements,
        OptionsWithProperties,
        OptionsWithSeverity {
    id?: string;
}

export interface TrackMetricOptions extends OptionsWithActivityId, OptionsWithProperties {
    max?: number;
    min?: number;
    sampleCount?: number;
}

export type TrackReduxActionOptions = OptionsWithActivityId &
    OptionsWithMeasurements &
    OptionsWithProperties &
    OptionsWithSeverity;

export interface TrackTimedPerformanceMetricOptions extends OptionsWithActivityId, OptionsWithProperties {
    errorCodes?: string[];
}

export type TrackTimeSinceOptions = OptionsWithActivityId & OptionsWithProperties;
export type TrackTraceOptions = OptionsWithActivityId & OptionsWithProperties & OptionsWithSeverity;

const addPropertyToBaseData = (item: ITelemetryItem, property: string, value: unknown) => {
    if (!item.baseData) {
        item.baseData = {};
    }

    if (!item.baseData['properties']) {
        item.baseData['properties'] = {};
    }

    item.baseData['properties'][property] = value;
};

const addPropertyToData = (item: ITelemetryItem, property: string, value: unknown) => {
    if (!item.data) {
        item.data = {};
    }

    item.data[property] = value;
};

const addPropertyToTelemetryItem = (item: ITelemetryItem, property: string, value: unknown) => {
    // Metrics don't have a property bag in baseData. Instead, properties are posted directly to data.
    if (item.baseType === TelemetryType.Metric) {
        addPropertyToData(item, property, value);
        return;
    }

    addPropertyToBaseData(item, property, value);
};

const getSeverityForOutcome = (outcome: AsyncOutcome): Severity => {
    switch (outcome) {
        case AsyncOutcome.Error:
            return Severity.Critical;
        case AsyncOutcome.Failed:
            return Severity.Error;
        case AsyncOutcome.PartialSuccess:
            return Severity.Warning;
        case AsyncOutcome.Success:
        default:
            return Severity.Information;
    }
};

const getSeverityOrDefault = (severity: Severity | undefined) => severity ?? Severity.Information;

const mergePropertyBags = (
    first: { [key: string]: unknown } | undefined,
    second: { [key: string]: unknown } | undefined
) => {
    if (first === undefined && second === undefined) {
        return undefined;
    }

    return {
        ...(first ?? {}),
        ...(second ?? {}),
    };
};

const performTelemetryOperation = (operation: () => void, onLogToConsole?: () => void): void => {
    try {
        if (!isInitialized) {
            return;
        }

        if (logToConsole && onLogToConsole) {
            onLogToConsole();
        }

        operation();
    } catch (e) {
        console.error(`Telemetry error: ${e || {}}`);
    }
};

const postProcessDependency = (item: SanitizedTelemetryItem) => {
    // Bug #1540141: we provide our own traceparent to backend requests to avoid imposing Application Insights's
    // opinionated data model onto the server-side telemetry. However, we still want to be able to correlate client
    // and server. So for dependency telemetry specifically, add our trace-id as a custom property.
    // https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1540141
    const requestHeaders = item.baseData?.['properties']?.['requestHeaders'];
    const traceparent = parseTraceparentHeader(requestHeaders?.[Header.Traceparent]);
    const clientRequestId = parseClientRequestId(requestHeaders?.[Header.ClientRequestId]);

    if (!!traceparent) {
        const { traceId } = traceparent;
        addPropertyToTelemetryItem(item, Property.ServerOperationId, traceId);
    }
    if (!!clientRequestId) {
        addPropertyToTelemetryItem(item, Property.ClientRequestId, clientRequestId);
    }

    // For fetch requests, parse some details here to make analyzing the telemetry on the backend easier
    if (item.baseData['type'] === DependencyType.BatchedFetch || item.baseData['type'] === DependencyType.Fetch) {
        // TODO #1834837: add logic to ensure we don't log user IDs via dependency names
        const name: string = item.baseData['name'];
        const kind = getDependencyKindOrDefault(item);
        const activityId = requestHeaders?.[Header.ActivityId];

        // Name format for fetch: '[method] [full-url]'
        const [method, url] = name.split(' ');
        const hostname = tryParseHostnameFromUrlString(url);
        const pathname = tryParsePathnameFromUrlString(url);
        const search = tryParseSearchFromUrlString(url);

        if (activityId) {
            addPropertyToTelemetryItem(item, Property.ActivityId, activityId);
        }

        if (hostname) {
            addPropertyToTelemetryItem(item, Property.Hostname, hostname);
        }

        if (kind) {
            addPropertyToTelemetryItem(item, Property.Kind, kind);
        }

        if (method) {
            addPropertyToTelemetryItem(item, Property.Method, method);
        }

        if (pathname) {
            const scrubbedPathname = scrubValue(TelemetryFilteringActionTargetForScrub.UserIdInPath, pathname);
            addPropertyToTelemetryItem(item, Property.Pathname, scrubbedPathname);
        }

        if (search) {
            addPropertyToTelemetryItem(item, Property.Search, search);
        }
    }
};

const postProcessTelemetryItem = (item: SanitizedTelemetryItem) => {
    // Set "fixed" properties on the telemetry item
    addPropertyToTelemetryItem(item, Property.EnvironmentType, currentEnvironment);
    addPropertyToTelemetryItem(item, Property.Service, service);
    addPropertyToTelemetryItem(item, Property.Version, version);
};

const prepareTelemetryItem = (item: ITelemetryItem): void => {
    try {
        // If there are any global properties currently configured, set them on the item
        const globalProperties = tryGetStoreState()?.telemetryStore?.globalProperties;

        if (globalProperties) {
            Object.keys(globalProperties).forEach((name) => {
                const value = globalProperties[name];
                addPropertyToTelemetryItem(item, name, value);
            });
        }

        // Sanitize the telemetry item per our filtering rules
        const sanitizedItem = sanitizeTelemetryItem(item, rules);

        // Apply general and type-specific post-processing to telemetry item
        postProcessTelemetryItem(sanitizedItem);

        if (sanitizedItem.baseType === TelemetryType.Dependency) {
            postProcessDependency(sanitizedItem);
        }

        // Copy the filtered props onto original item
        item.baseData = sanitizedItem.baseData;
        item.baseType = sanitizedItem.baseType;
        item.data = sanitizedItem.data;
        item.ext = sanitizedItem.ext;
        item.iKey = sanitizedItem.iKey;
        item.name = sanitizedItem.name;
        item.tags = sanitizedItem.tags;
        item.time = sanitizedItem.time;
        item.ver = sanitizedItem.ver;
    } catch (err) {
        // Not logging to AI here since we don't want this to cause a logging loop.
        // Only adding the try - catch to prevent the client from crashing due
        // to telemetry bugs.
        if (logToConsole) {
            console.error('prepareTelemetryItem', err);
        }
    }
};

export const initialize = (): void => {
    if (isInitialized) {
        return;
    }

    appInsights = new ApplicationInsights({ config });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(prepareTelemetryItem);
    isInitialized = true;
};

export const flush = (): void => {
    // HACK: working around Application Insight's terrible API design. Rather than providing sync and async variants for
    // flush, the flush function instead is either a () => void or () => Promise<void> depending on what arguments you
    // pass to it. Passing false will make it sync.
    appInsights.flush(false);
};

export const getSessionID = (): string => appInsights?.context?.getSessionId() ?? '';

export const trackDependency = (
    responseCode: number,
    name: string,
    duration: number,
    success: boolean,
    type: string,
    options?: TrackDependencyOptions
): void =>
    performTelemetryOperation(() => {
        // We treat id as an option, but it needs to be provided to SDK, so give it a default value
        const { activityId, id, properties: propertiesOption, ...otherOptions } = options ?? {};
        const properties = mergePropertyBags({ [Property.ActivityId]: activityId }, propertiesOption);

        appInsights.trackDependencyData({
            id: id ?? '',
            responseCode,
            name,
            duration,
            success,
            type,
            properties,
            ...(otherOptions ?? {}),
        });
    });

export const trackEvent = (name: string, options?: TrackEventOptions): void =>
    performTelemetryOperation(
        () => {
            const { activityId, measurements, properties: propertiesOption, severity: severityOption } = options ?? {};
            const severity = getSeverityOrDefault(severityOption);

            const properties = mergePropertyBags(
                {
                    [Property.ActivityId]: activityId,
                    [Property.Severity]: severity,
                    [Property.SeverityLevel]: getSeverityLevelForSeverity(severity),
                },
                propertiesOption
            );

            appInsights.trackEvent({
                name,
                properties,
                measurements,
            });
        },
        () => console.info(`trackEvent: name = "${name}", severity = "${getSeverityOrDefault(options?.severity)}"`)
    );

export const trackException = (exception: ClientError, options?: TrackExceptionOptions): void =>
    performTelemetryOperation(
        () => {
            const error = exception as ClientError;

            const {
                activityId,
                measurements,
                properties: propertiesOption,
                severity: severityOption,
                ...otherOptions
            } = options ?? {};

            const severity = getSeverityOrDefault(severityOption);

            const properties = mergePropertyBags(
                {
                    [Property.ActivityId]: activityId,
                    [Property.ErrorCode]: error.code || '',
                    [Property.InnerStack]: error.innerStack || '',
                    [Property.Severity]: severity,
                    [Property.SeverityLevel]: getSeverityLevelForSeverity(severity),
                },
                propertiesOption
            );

            appInsights.trackException({
                exception,
                severityLevel: getSeverityLevelForSeverity(severity),
                properties,
                measurements,
                ...(otherOptions ?? {}),
            });
        },
        () => {
            const severity = getSeverityOrDefault(options?.severity);
            console.error(`trackException: severity = "${severity}"`, exception?.stack ? exception.stack : undefined);

            if (exception?.innerStack) {
                console.error(`trackException: severity = "${severity}", inner exception`, exception.innerStack);
            }
        }
    );

export const trackMetric = (name: string, average: number, options?: TrackMetricOptions): void =>
    performTelemetryOperation(
        () => {
            const { activityId, properties: propertiesOption, ...otherOptions } = options ?? {};
            const properties = mergePropertyBags({ [Property.ActivityId]: activityId }, propertiesOption);
            appInsights.trackMetric({ name, average, ...(otherOptions ?? {}) }, properties);
        },
        () => console.info(`trackMetric: name = "${name}", average = "${average}"`)
    );

export const trackReduxAction = (action: Action, options?: TrackReduxActionOptions): void =>
    performTelemetryOperation(
        () => {
            const { activityId, measurements, properties: propertiesOption, severity: severityOption } = options ?? {};
            const severity = getSeverityOrDefault(severityOption);

            // Create a deep copy of the action, so we don't modify references in-place when sanitizing telemetry
            const actionCopy = JSON.parse(JSON.stringify(action));

            // Copy action payload contents over to properties with a prefix
            const payloadProperties: TelemetryProperties = {};

            Object.keys(actionCopy).forEach((property) => {
                payloadProperties[`actionDispatched-${property}`] = actionCopy[property];
            });

            const mergedProperties = {
                [Property.ActivityId]: activityId,
                [Property.Severity]: severity,
                [Property.SeverityLevel]: getSeverityLevelForSeverity(severity),
                ...payloadProperties,
                ...propertiesOption,
            };

            appInsights.trackEvent({ name: 'ActionDispatched', properties: mergedProperties, measurements });
        },
        () => console.info(`trackReduxAction: severity = "${getSeverityOrDefault(options?.severity)}"`, action)
    );

// Note: not including a logger for this function - letting trackTimeSince handle the logging.
export const trackTimedPerformanceMetric = (
    metric: PerformanceMetric,
    startTime: Date,
    outcome: AsyncOutcome,
    options?: TrackTimedPerformanceMetricOptions
): void =>
    performTelemetryOperation(() => {
        const { activityId, errorCodes, properties: propertiesOptions } = options ?? {};

        // Note: setting severity here rather than taking it as an option because severity derived from outcome is
        // particular to this helper. Severity doesn't make sense for most other time-since metrics.
        const severity = getSeverityForOutcome(outcome);
        const severityLevel = getSeverityLevelForSeverity(severity);

        const properties = mergePropertyBags(
            {
                [Property.Outcome]: outcome,
                [Property.Severity]: severity,
                [Property.SeverityLevel]: `${severityLevel}`,
                ...(errorCodes && errorCodes.length > 0 ? { [Property.ErrorCodes]: errorCodes } : {}),
            },
            propertiesOptions
        );

        trackTimeSince(metric, startTime, { activityId, properties });
    });

export const trackTimeSince = (name: string, startTime: Date, options?: TrackTimeSinceOptions): void => {
    const duration = getMillisecondsBetween(startTime, new Date());

    performTelemetryOperation(
        () => {
            const { activityId, properties: propertiesOption } = options ?? {};
            const properties = mergePropertyBags({ [Property.ActivityId]: activityId }, propertiesOption);

            appInsights.trackMetric(
                {
                    name,
                    average: duration,
                },
                properties
            );
        },
        () => console.info(`trackTimeSince: name = "${name}", duration (ms) = "${duration}""`)
    );
};

export const trackTrace = (message: string, options?: TrackTraceOptions): void =>
    performTelemetryOperation(
        () => {
            const { activityId, properties: propertiesOption, severity: severityOption } = options ?? {};
            const severity = getSeverityOrDefault(severityOption);
            const properties = mergePropertyBags(
                {
                    [Property.ActivityId]: activityId,
                    [Property.Severity]: severity,
                    [Property.SeverityLevel]: getSeverityLevelForSeverity(severity),
                },
                propertiesOption
            );

            appInsights.trackTrace({
                message,
                properties,
                severityLevel: getSeverityLevelForSeverity(severity),
            });
        },
        () =>
            console.info(`trackTrace: message = "${message}", severity = "${getSeverityOrDefault(options?.severity)}"`)
    );
