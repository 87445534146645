import {
    IShimmerStyleProps,
    IShimmerStyles,
    IStyleFunctionOrObject,
    ITextFieldProps,
    Shimmer,
    TextField,
} from '@fluentui/react';
import * as React from 'react';

export const shimmerStyles: IStyleFunctionOrObject<IShimmerStyleProps, IShimmerStyles> = {
    root: {
        height: '100%',
        width: '100%',
    },
    shimmerWrapper: {
        height: '100%',
    },
};

const onRenderInput = () => {
    return <InputShimmer />;
};

export const InputShimmer: React.FC = () => <Shimmer styles={shimmerStyles} />;

export type ShimmeredInputProps = Omit<ITextFieldProps, 'onChange' | 'value' | 'onRenderInput'>;

export const ShimmeredInput: React.FC<ShimmeredInputProps> = (props: ShimmeredInputProps) => (
    <TextField {...props} onRenderInput={onRenderInput} />
);
