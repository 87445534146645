import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
    ListAzureDevOpsBranchesResponse,
    listAzureDevOpsBranches,
} from '../../../data/services/azure-dev-ops/vso-code';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getAzureDevOpsBranchNameFromRefName } from '../../../utilities/azure-dev-ops';
import {
    listAzureDevOpsBranches as listAzureDevOpsBranchesActionCreator,
    listAzureDevOpsBranchesError,
    listAzureDevOpsBranchesFailed,
    listAzureDevOpsBranchesSuccess,
} from '../../actions/azure-dev-ops/azure-dev-ops-action-creators';
import { ListAzureDevOpsBranchesAction } from '../../actions/azure-dev-ops/azure-dev-ops-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureDevOpsPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listAzureDevOpsBranchesSaga(action: ListAzureDevOpsBranchesAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { repoUrl } = payload;

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForAzureDevOpsPayload(), meta));

        const response: ListAzureDevOpsBranchesResponse = yield call(
            listAzureDevOpsBranches,
            repoUrl,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield resolveAction(action, response);
            yield put(listAzureDevOpsBranchesFailed({ failure: response, repoUrl }, meta));
            return;
        }

        const branches = response.data.map((branch) => ({
            ...branch,
            name: getAzureDevOpsBranchNameFromRefName(branch.name),
        }));

        yield resolveAction(action, { ...response, data: branches });
        yield put(listAzureDevOpsBranchesSuccess({ repoUrl, result: branches }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetAzureDevOpsRepoForCloneUri);
        yield put(listAzureDevOpsBranchesError({ error, repoUrl }, meta));
        yield rejectAction(action, error);
    }
}

export function* listAzureDevOpsBranchesListenerSaga(): SagaIterator {
    yield takeEvery(listAzureDevOpsBranchesActionCreator, listAzureDevOpsBranchesSaga);
}
