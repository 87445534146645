import { UnionValueMap } from '../../../types/union-map';
import { updateDisplayTime } from './time-action-creators';

/**
 * Action types
 */

type TimeActionName = 'UpdateDisplayTime';

export type TimeActionType = 'UPDATE_DISPLAY_TIME';

export const TimeActionType: UnionValueMap<TimeActionName, TimeActionType> = {
    UpdateDisplayTime: 'UPDATE_DISPLAY_TIME',
};

/**
 * Payloads
 */

export interface UpdateDisplayTimePayload {
    time: Date;
}

/**
 * Action shorthands
 */

export type UpdateDisplayTimeAction = ReturnType<typeof updateDisplayTime>;
