import { Stack } from '@fluentui/react';
import * as React from 'react';
import MetadataItem, { ShimmeredMetadataItem } from './metadata-item';
import { MetadataItemViewModel, ShimmeredMetadataItemViewModel } from './models';

export interface MetadataProps {
    metadataList?: MetadataItemViewModel[];
    containerTokens?: number;
}

export interface ShimmeredMetadataProps {
    metadataList?: ShimmeredMetadataItemViewModel[];
}

const useContainerTokens = (tokens?: number) => ({ childrenGap: tokens ?? 0 });

const shimmerTokens = { childrenGap: 8 };

export const ShimmeredMetadataList: React.FC<ShimmeredMetadataProps> = React.memo((props: ShimmeredMetadataProps) => {
    const { metadataList } = props;

    if (!metadataList) {
        return <></>;
    }

    return (
        <Stack tokens={shimmerTokens}>
            {metadataList.map((metadata) => {
                const { key, width } = metadata;

                return (
                    <Stack.Item key={key}>
                        <ShimmeredMetadataItem width={width} />
                    </Stack.Item>
                );
            })}
        </Stack>
    );
});

export const MetadataList: React.FC<MetadataProps> = React.memo((props: MetadataProps) => {
    const { metadataList, containerTokens } = props;

    const tokens = useContainerTokens(containerTokens);

    return (
        <Stack tokens={tokens}>
            {(metadataList ?? []).map((metadata) => {
                const { description, icon, key, primary, value } = metadata;

                return (
                    <Stack.Item key={key}>
                        <MetadataItem content={value} highlight={primary} iconAriaLabel={description} iconName={icon} />
                    </Stack.Item>
                );
            })}
        </Stack>
    );
});

export default MetadataList;
