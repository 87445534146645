import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagName } from '../../../constants/features';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { get, keys } from '../../../utilities/serializable-map';
import { StoreStateSelector, isStatusTerminal } from '../../selector/common';
import { getStatusesForListEnvironmentDefinitions } from '../../selector/environment-definition-selectors';
import { getProjectIdsFromEnvironments } from '../../selector/environment-selectors';
import { getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject } from '../../selector/project-environment-type-from-dataplane-selectors';
import { getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject } from '../../selector/project-environment-type-selectors';
import {
    getProjectsByDiscoveryServiceURI,
    getProjectsFromDiscoveryServiceWithAtLeastOneProjectEnvironmentType,
} from '../../selector/project-from-discovery-service-selectors';
import {
    getProjectsByDataPlaneId,
    getProjectsWithAtLeastOneProjectEnvironmentType,
} from '../../selector/project-selectors';

/**
 * Application state selectors
 */

export const getIdsForProjectsRequiringEnvironmentDefinitions: StoreStateSelector<string[]> = createSelector(
    [
        getProjectsByDataPlaneId,
        getProjectsByDiscoveryServiceURI,
        getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject,
        getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject,
        getStatusesForListEnvironmentDefinitions,
    ],
    (
        projects,
        projectsFromDiscoveryService,
        projectEnvironmentTypes,
        projectEnvironmentTypesFromDataplane,
        statusesForListEnvironmentDefinitions
    ) => {
        const isDiscoveryServiceEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        const idsOfProjectsWithAuthorizedProjectEnvironmentTypes = keys(
            getProjectsWithAtLeastOneProjectEnvironmentType(projects, projectEnvironmentTypes)
        );

        const idsOfProjectsWithAuthorizedProjectEnvironmentTypesFromDataplane = keys(
            getProjectsFromDiscoveryServiceWithAtLeastOneProjectEnvironmentType(
                projectsFromDiscoveryService,
                projectEnvironmentTypesFromDataplane
            )
        );

        const authorizedIdsToUse = isDiscoveryServiceEnabled
            ? idsOfProjectsWithAuthorizedProjectEnvironmentTypesFromDataplane
            : idsOfProjectsWithAuthorizedProjectEnvironmentTypes;

        // Filter to projects whose requests to list environment definitions aren't in terminal states
        const idsOfProjectsAwaitingEnvironmentDefinitions = authorizedIdsToUse.filter(
            (id) => !isStatusTerminal(get(statusesForListEnvironmentDefinitions, id))
        );

        // Return all projects whose requests to list environment definitions aren't in terminal states
        return idsOfProjectsAwaitingEnvironmentDefinitions;
    }
);

export const getProjectIdsForEnvironmentsRequiringEnvironmentDefinitions: StoreStateSelector<string[]> = createSelector(
    [getProjectIdsFromEnvironments, getStatusesForListEnvironmentDefinitions],
    (projectIds, statusesForListEnvironmentDefinitions) =>
        projectIds.filter((id) => !isStatusTerminal(get(statusesForListEnvironmentDefinitions, id)))
);
