import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../models/permission';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import {
    projectEnvironmentTypePermissionRecordAdapter,
    projectPermissionRecordAdapter,
} from '../adapters/permission-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'discoverProjectEnvironmentTypePermissions' | 'discoverProjectPermissions';
type IndexedStatusName = 'getProjectEnvironmentTypePermissions' | 'getProjectPermissions';

export interface PermissionDataStore {
    projectEnvironmentTypePermissions: EntityState<Entity<ProjectEnvironmentTypePermissionRecord>>;
    projectPermissions: EntityState<Entity<ProjectPermissionRecord>>;
}

export const PermissionDataStore = createInitializer<PermissionDataStore>({
    projectEnvironmentTypePermissions: projectEnvironmentTypePermissionRecordAdapter.getInitialState(),
    projectPermissions: projectPermissionRecordAdapter.getInitialState(),
});

export type PermissionStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const PermissionStatusStore = createInitializer<PermissionStatusStore>({
    discoverProjectEnvironmentTypePermissions: Status(),
    discoverProjectPermissions: Status(),
    getProjectEnvironmentTypePermissions: statusAdapter.getInitialState(),
    getProjectPermissions: statusAdapter.getInitialState(),
});

export interface PermissionStore {
    data: PermissionDataStore;
    status: PermissionStatusStore;
}

export const PermissionStore = createInitializer<PermissionStore>({
    data: PermissionDataStore(),
    status: PermissionStatusStore(),
});
