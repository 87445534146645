import { IStackTokens, Label, Link, makeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { get } from '../../../utilities/serializable-map';
import { PoolDropdown } from '../../common/form/dropdown/data-controls/pool/pool-dropdown';
import PoolDropdownItem from '../../common/form/dropdown/data-controls/pool/pool-dropdown-item';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { PoolViewModel, ProjectToPoolViewModelMap } from '../models';
import SelectDevBoxTypeDialog from '../select-dev-box-pool-dialog/select-dev-box-pool-dialog';

interface AddDevBoxFormPoolControlsProps {
    disabled: boolean;
    poolsByProject: ProjectToPoolViewModelMap;
    selectedProjectId: string | undefined;
    selectedPool: PoolViewModel | undefined;
    errorMessage: string;
    onChange: (event: PoolViewModel | undefined) => void;
}

const messages = defineMessages({
    addDevBoxFormPoolFieldDropdownText: {
        id: 'AddDevBoxFormPoolControls_Dropdown_Text',
        defaultMessage: 'Dev box pool',
        description: 'Text for the pool dropdown in the add dev box panel label',
    },
    addDevBoxFormPoolFieldDropdownAriaLabel: {
        id: 'AddDevBoxFormPoolControls_Dropdown_AriaLabel',
        defaultMessage: 'Pool for your dev box',
        description: 'Aria label for the pool dropdown in the add dev box panel',
    },
    addDevBoxFormPoolFieldDropdownPlaceholder: {
        id: 'AddDevBoxFormPoolControls_Dropdown_Placeholder',
        defaultMessage: 'Please select a pool closest to your physical location for optimal performance.',
        description: 'Placeholder text for the pool dropdown in the add dev box panel',
    },
});

/**
 * Style Section
 */

const usePoolDropdownOptionStyles = makeStyles({
    title: {
        height: 58,
        lineHeight: 20,
    },
    dropdownItem: {
        height: 62,
    },
    dropdownItemSelected: {
        height: 62,
    },
});

const useReadOnlyOptionStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',
        paddingRight: 8,
        paddingBottom: 6,
    },
});

const poolReadOnlyOptionContainerTokens: IStackTokens = {
    childrenGap: 5,
};

const poolSelectionContainerTokens: IStackTokens = {
    childrenGap: 8,
};

/* END */

export const AddDevBoxFormPoolControls: React.FC<AddDevBoxFormPoolControlsProps> = (
    props: AddDevBoxFormPoolControlsProps
) => {
    const { disabled, poolsByProject, selectedProjectId, selectedPool, errorMessage, onChange } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const poolDropdownOptionStyles = usePoolDropdownOptionStyles();
    const readOnlyOptionStyles = useReadOnlyOptionStyles();

    // State hooks
    const [showSelectDevBoxPoolDialog, setShowSelectDevBoxPoolDialog] = React.useState(false);

    // Memo hooks
    const pools: PoolViewModel[] = React.useMemo(
        () => (!!selectedProjectId ? get(poolsByProject, selectedProjectId) ?? [] : []),
        [poolsByProject, selectedProjectId]
    );

    const hasMultiplePools = pools.length > 1;
    const hasNoPools = pools.length < 1;

    const selectDevBoxPoolModalOnDismiss = React.useCallback(() => setShowSelectDevBoxPoolDialog(false), []);
    const onSeeAllTypesButtonClick = React.useCallback(() => setShowSelectDevBoxPoolDialog(true), []);

    // pre-select the first pool if we only have one
    React.useEffect(() => {
        if (pools.length === 1) {
            onChange(pools[0]);
        }
    }, [pools]);

    if (hasMultiplePools) {
        return (
            <Stack tokens={poolSelectionContainerTokens}>
                <Stack.Item>
                    <PoolDropdown
                        value={selectedPool}
                        label={formatMessage(messages.addDevBoxFormPoolFieldDropdownText)}
                        ariaLabel={formatMessage(messages.addDevBoxFormPoolFieldDropdownAriaLabel)}
                        placeholder={formatMessage(messages.addDevBoxFormPoolFieldDropdownPlaceholder)}
                        options={pools}
                        onChange={onChange}
                        styles={poolDropdownOptionStyles}
                        disabled={disabled}
                        errorMessage={errorMessage}
                        autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                        required
                    />
                </Stack.Item>
                <Stack.Item>
                    <Link onClick={onSeeAllTypesButtonClick} disabled={disabled}>
                        <FormattedMessage
                            id="AddDevBoxFormPoolControls_SeeAllTypesLink_Text"
                            defaultMessage="See all pools"
                            description="Text for the see all pools link for the add dev box pool form field"
                        />
                    </Link>
                    <SelectDevBoxTypeDialog
                        pools={pools}
                        onDismiss={selectDevBoxPoolModalOnDismiss}
                        showDialog={showSelectDevBoxPoolDialog}
                        onChange={onChange}
                        selectedPool={selectedPool}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    if (!selectedPool || hasNoPools) {
        return <></>;
    }

    return (
        <Stack tokens={poolReadOnlyOptionContainerTokens}>
            <Stack.Item>
                <Label>
                    <FormattedMessage
                        id="AddDevBoxFormPoolControls_PoolReadOnly_Text"
                        defaultMessage="Dev box pool"
                        description="Text for the pool read only field in the add dev box panel label"
                    />
                </Label>
            </Stack.Item>
            <Stack.Item styles={readOnlyOptionStyles}>
                <PoolDropdownItem pool={selectedPool} />
            </Stack.Item>
        </Stack>
    );
};

export default AddDevBoxFormPoolControls;
