import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { OperatingSystemFamily } from '../../../constants/browser';
import androidMonochrome from '../../../content/images/android-monochrome.svg';
import appleMonochrome from '../../../content/images/apple-monochrome.svg';
import iosMonochrome from '../../../content/images/ios-monochrome.svg';
import windowsMonochrome from '../../../content/images/windows-monochrome.svg';

const messages = defineMessages({
    andriodImageAlt: {
        id: 'Andriod_Image_Alt',
        defaultMessage: 'Andriod logo',
        description: 'Alt text for andriod logo',
    },
    iosImageAlt: {
        id: 'IOS_Image_Alt',
        defaultMessage: 'IOS logo',
        description: 'Alt text for IOS logo',
    },
    macOSImageAlt: {
        id: 'MacOS_Image_Alt',
        defaultMessage: 'Mac OS logo',
        description: 'Alt text for MacOS logo',
    },
    windowsImageAlt: {
        id: 'Windows_Image_Alt',
        defaultMessage: 'Windows logo',
        description: 'Alt text for Windows logo',
    },
});

interface OperatingSystemFamilyImageProps {
    className?: string;
    operatingSystemFamily: OperatingSystemFamily;
}

export const OperatingSystemFamilyImage: React.FC<OperatingSystemFamilyImageProps> = (
    props: OperatingSystemFamilyImageProps
) => {
    const { className, operatingSystemFamily } = props;
    const { formatMessage } = useIntl();

    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Android:
            return <img className={className} src={androidMonochrome} alt={formatMessage(messages.andriodImageAlt)} />;
        case OperatingSystemFamily.IOS:
            return <img className={className} src={iosMonochrome} alt={formatMessage(messages.iosImageAlt)} />;
        case OperatingSystemFamily.MacOS:
            return <img className={className} src={appleMonochrome} alt={formatMessage(messages.macOSImageAlt)} />;
        case OperatingSystemFamily.Windows:
        default:
            return <img className={className} src={windowsMonochrome} alt={formatMessage(messages.windowsImageAlt)} />;
    }
};

export default OperatingSystemFamilyImage;
