import { IStackTokens, Link, makeStyles, Panel, PanelType, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useErrorDetailsPanelContext } from '../../hooks/context/panels';
import { Failure } from '../../models/common';
import { DevBoxRepairOperationResult } from '../../models/dev-box';
import { isUndefinedOrWhiteSpace } from '../../utilities/string';
import FailureCodeAndMessage from '../common/failure-message/failure-code-and-message';

export interface ErrorDetailsPanelProps {
    failure?: Failure;
    repairOperationResult?: DevBoxRepairOperationResult;
    devBoxName?: string;
    isOpen: boolean;
    linkHref?: string;
    linkText?: string;
    onDismiss: () => void;
}

const errorDetailsPanelMessages = defineMessages({
    closeButtonAriaLabel: {
        id: 'ErrorDetailsPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the close button in the "Error details" panel',
    },
    titleText: {
        id: 'ErrorDetailsPanel_Title_Text',
        defaultMessage: 'Error details',
        description: 'Title of the "Error details" panel',
    },
    devBoxNameTitleText: {
        id: 'ErrorDetailsPanel_DevBoxNameTitle_Text',
        defaultMessage: '{devBoxName} connection status',
        description: 'Title of "Error details" panel with dev box name. {devBoxName} should not be localized.',
    },
});

/**
 * Styles
 */

const usePanelStyles = makeStyles({
    header: {
        marginBottom: '33px',
    },
});

/**
 * END Styles
 */

const bodyTokens: IStackTokens = {
    childrenGap: 20,
};

const ErrorDetailsPanelComponent: React.FC<ErrorDetailsPanelProps> = (props: ErrorDetailsPanelProps) => {
    const { failure, repairOperationResult, devBoxName, isOpen, linkHref, linkText, onDismiss } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const panelStyles = usePanelStyles();

    const titleText = React.useMemo(
        () =>
            repairOperationResult
                ? formatMessage(errorDetailsPanelMessages.devBoxNameTitleText, { devBoxName })
                : formatMessage(errorDetailsPanelMessages.titleText),
        [repairOperationResult, devBoxName, formatMessage]
    );

    return (
        <Panel
            closeButtonAriaLabel={formatMessage(errorDetailsPanelMessages.closeButtonAriaLabel)}
            customWidth="369px"
            headerText={titleText}
            isLightDismiss
            isOpen={isOpen}
            onDismiss={onDismiss}
            styles={panelStyles}
            type={PanelType.custom}
        >
            <Stack tokens={bodyTokens}>
                <Stack.Item>
                    <FailureCodeAndMessage failure={failure} repairOperationResult={repairOperationResult} />
                </Stack.Item>

                {!isUndefinedOrWhiteSpace(linkHref) && (
                    <Stack.Item>
                        <Link href={linkHref} target="_blank">
                            {!isUndefinedOrWhiteSpace(linkText) ? linkText : linkHref}
                        </Link>
                    </Stack.Item>
                )}
            </Stack>
        </Panel>
    );
};

const ErrorDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useErrorDetailsPanelContext();

    return <ErrorDetailsPanelComponent isOpen={isOpen} onDismiss={closePanel} {...properties} />;
};

export const ErrorDetailsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useErrorDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <ErrorDetailsPanelContainer />;
};

export default ErrorDetailsPanelContextWrapper;
