import { UnionValueMap } from '../types/union-map';

export const AzureDevOpsGitRepositoryEvaluationId = '2e9eb7ed-3c0a-47d4-87c1-0ffdd275fd87';
export const AzureDevOpsGitRepositoryReadPermission = 2;

type AdoPathPart = 'Apis' | 'Git' | 'Repositories' | 'Refs' | 'Commits' | 'Trees' | 'Items';
export const AdoPathPart: UnionValueMap<AdoPathPart, string> = {
    Apis: '_apis',
    Git: 'git',
    Repositories: 'repositories',
    Refs: 'refs',
    Commits: 'commits',
    Trees: 'trees',
    Items: 'items',
};

type AdoQueryParam = 'ApiVersion' | 'Recursive' | 'Path' | 'Version' | 'VersionType';
export const AdoQueryParam: UnionValueMap<AdoQueryParam, string> = {
    ApiVersion: 'api-version',
    Recursive: 'recursive',
    Path: 'path',
    Version: 'versionDescriptor.version',
    VersionType: 'versionDescriptor.versionType',
};

type AdoQueryParamValue = 'Branch';
export const AdoQueryParamValue: UnionValueMap<AdoQueryParamValue, string> = {
    Branch: 'branch',
};
