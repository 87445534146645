import { DataResponse } from '../../../models/common';
import { LocaleData } from '../../../models/localization';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, ResultPayload } from '../core-actions';
import { LocalizationActionType } from './localization-actions';

/**
 * Action creators
 */

export const initializeLocalization = createActionCreatorWithoutPayload<DataResponse<LocaleData>>(
    LocalizationActionType.InitializeLocalization,
    { async: true }
);

export const initializeLocalizationError = createActionCreator<ErrorPayload>(
    LocalizationActionType.InitializeLocalizationError
);

export const initializeLocalizationSuccess = createActionCreator<ResultPayload<LocaleData>>(
    LocalizationActionType.InitializeLocalizationSuccess
);
