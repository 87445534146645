import { UnionMap, UnionValueMap } from '../../types/union-map';

export type FontStyle = 'fontFamily';

export const FontStyle: UnionMap<FontStyle> = {
    fontFamily: 'fontFamily',
};

export const fontFamily =
    'Segoe UI, "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",sans-serif';

export const FontStyles: UnionValueMap<FontStyle, string> = {
    fontFamily: fontFamily,
};
