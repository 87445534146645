import { DataResponse } from '../../../models/common';
import {
    GetAccessTokenErrorPayload,
    GetAccessTokenFailedPayload,
    GetAccessTokenPayload,
    GetAccessTokenRedirectingPayload,
    GetAccessTokenSkippedPayload,
    GetAccessTokenSuccessPayload,
    IdentityActionType,
    InitializeAuthenticationStatePayload,
    SetActiveAccountPayload,
    SignInPayload,
} from '../../../redux/actions/identity/identity-actions';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload } from '../core-actions';

/**
 * Action creators
 */

export const getAccessToken = createActionCreator<GetAccessTokenPayload, string>(IdentityActionType.GetAccessToken, {
    async: true,
});

export const getAccessTokenError = createActionCreator<GetAccessTokenErrorPayload>(
    IdentityActionType.GetAccessTokenError
);

export const getAccessTokenFailed = createActionCreator<GetAccessTokenFailedPayload>(
    IdentityActionType.GetAccessTokenFailed
);

export const getAccessTokenRedirecting = createActionCreator<GetAccessTokenRedirectingPayload>(
    IdentityActionType.GetAccessTokenRedirecting
);

export const getAccessTokenSkipped = createActionCreator<GetAccessTokenSkippedPayload>(
    IdentityActionType.GetAccessTokenSkipped
);

export const getAccessTokenSuccess = createActionCreator<GetAccessTokenSuccessPayload>(
    IdentityActionType.GetAccessTokenSuccess
);

export const initializeAuthenticationState = createActionCreator<InitializeAuthenticationStatePayload, DataResponse>(
    IdentityActionType.InitializeAuthenticationState,
    { async: true }
);

export const initializeAuthenticationStateError = createActionCreator<ErrorPayload>(
    IdentityActionType.InitializeAuthenticationStateError
);

export const initializeAuthenticationStateSuccess = createActionCreatorWithoutPayload(
    IdentityActionType.InitializeAuthenticationStateSuccess
);

export const setActiveAccount = createActionCreator<SetActiveAccountPayload>(IdentityActionType.SetActiveAccount);

export const signIn = createActionCreator<SignInPayload>(IdentityActionType.SignIn);
export const signInError = createActionCreator<ErrorPayload>(IdentityActionType.SignInError);
export const signInFailed = createActionCreator<FailedPayload>(IdentityActionType.SignInFailed);
export const signInRedirecting = createActionCreatorWithoutPayload(IdentityActionType.SignInRedirecting);
export const signInSuccess = createActionCreatorWithoutPayload(IdentityActionType.SignInSuccess);

export const signOut = createActionCreatorWithoutPayload(IdentityActionType.SignOut);
export const signOutError = createActionCreator<ErrorPayload>(IdentityActionType.SignOutError);
export const signOutRedirecting = createActionCreatorWithoutPayload(IdentityActionType.SignOutRedirecting);
export const signOutSuccess = createActionCreatorWithoutPayload(IdentityActionType.SignOutSuccess);

export const tryExpireSignIn = createActionCreatorWithoutPayload(IdentityActionType.TryExpireSignIn);
export const tryExpireSignInComplete = createActionCreatorWithoutPayload(IdentityActionType.TryExpireSignInComplete);
export const tryExpireSignInError = createActionCreator<ErrorPayload>(IdentityActionType.TryExpireSignInError);
