import { createReducer } from '@reduxjs/toolkit';
import { updateDisplayTime } from '../actions/time/time-action-creators';
import { TimeStore } from '../store/time-store';
import { getPayload } from '../utilities/payload-action';

export const timeReducer = createReducer(TimeStore(), (builder) => {
    builder.addCase(updateDisplayTime, (store, action) => {
        const { time } = getPayload(action);
        store.displayTime = time;
    });
});
