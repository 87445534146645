import { IStackTokens, Stack } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import FailureMessage from '../../common/failure-message/failure-message';
import { FailureMessageSeverity, FailureMessageSize } from '../../common/failure-message/models';
import ProjectDropdown from '../../common/form/dropdown/data-controls/project-dropdown';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { ProjectViewModel } from '../../common/models';

interface AddEnvironmentFormProjectControlProps {
    projects: ProjectViewModel[];
    isSubmitting: boolean;
    selectedProject: ProjectViewModel | undefined;
    errorMessage: string;
    onChange: (value: ProjectViewModel | undefined) => void;
    showFailedToLoadWarning: boolean;
}

const messages = defineMessages({
    projectDropdownText: {
        id: 'AddEnvironmentFormProjectControl_Dropdown_Text',
        defaultMessage: 'Project',
        description: 'Text for the project dropdown in the add environment panel label',
    },
    projectDropdownAriaLabel: {
        id: 'AddEnvironmentFormProjectControl_Dropdown_AriaLabel',
        defaultMessage: 'Project for your environment',
        description: 'Aria label for the project dropdown in the add environment panel',
    },
    projectDropdownPlaceholder: {
        id: 'AddEnvironmentFormProjectControl_Placeholder',
        defaultMessage: 'Select a project',
        description: 'Placeholder text for the select project field on the environment create form.',
    },
});

const projectDropdownStackTokens: IStackTokens = {
    childrenGap: 8,
};

export const AddEnvironmentFormProjectControl: React.FC<AddEnvironmentFormProjectControlProps> = (
    props: AddEnvironmentFormProjectControlProps
) => {
    const { projects, isSubmitting, selectedProject, errorMessage, onChange, showFailedToLoadWarning } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <Stack tokens={projectDropdownStackTokens}>
            <Stack.Item>
                <ProjectDropdown
                    options={projects}
                    value={selectedProject}
                    label={formatMessage(messages.projectDropdownText)}
                    ariaLabel={formatMessage(messages.projectDropdownAriaLabel)}
                    onChange={onChange}
                    disabled={isSubmitting}
                    errorMessage={errorMessage}
                    placeholder={formatMessage(messages.projectDropdownPlaceholder)}
                    autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                    required
                />
            </Stack.Item>
            <Stack.Item>
                {showFailedToLoadWarning && (
                    <Stack.Item>
                        <FailureMessage severity={FailureMessageSeverity.Warning} size={FailureMessageSize.Compact}>
                            <FormattedMessage
                                id="AddEnvironmentForm_ProjectControl_FailedToLoad"
                                defaultMessage="We encountered an error loading some projects, so they may not appear here. Please retry, or contact your admin if this problem persists."
                                description="Text informing users that some projects failed to load resources and cannot be used for environment creation."
                            />
                        </FailureMessage>
                    </Stack.Item>
                )}
            </Stack.Item>
        </Stack>
    );
};

export default AddEnvironmentFormProjectControl;
