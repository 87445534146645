import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { GetDevBoxResponse, getDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getDevBoxState, isDevBoxInTerminalState } from '../../../utilities/dev-box';
import {
    pollNonTerminalDevBox,
    pollNonTerminalDevBoxComplete,
    pollNonTerminalDevBoxError,
} from '../../actions/dev-box/dev-box-action-creators';
import { PollNonTerminalDevBoxAction } from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getPollingInitialDelayMs, getPollingIntervalMs } from '../../utilities/poll-action';

export function* pollNonTerminalDevBoxSaga(action: PollNonTerminalDevBoxAction): SagaIterator {
    let shouldPoll = true;
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        yield delay(getPollingInitialDelayMs(action));

        const pollingInterval = getPollingIntervalMs(action);

        while (shouldPoll) {
            const accessToken: string = yield putAndAwait(
                getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
            );

            const response: GetDevBoxResponse = yield call(getDevBox, id, accessToken, activityId);

            if (isFailureResponse(response)) {
                // should we ignore / re-try under certain conditions or error codes?
                yield put(pollNonTerminalDevBoxComplete({ id }, meta));
                yield resolveAction(action, response);
                return;
            }

            const { data } = response;
            const state = getDevBoxState(data);

            shouldPoll = !isDevBoxInTerminalState(state);

            if (shouldPoll) {
                yield delay(pollingInterval);
            } else {
                yield put(pollNonTerminalDevBoxComplete({ id, result: data }, meta));
                yield resolveAction(action, response);
            }
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.PollNonTerminalDevBox);
        yield put(pollNonTerminalDevBoxError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* pollNonTerminalDevBoxListenerSaga(): SagaIterator {
    yield takeEvery(pollNonTerminalDevBox, pollNonTerminalDevBoxSaga);
}
