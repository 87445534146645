import {
    EnvironmentFailureReason,
    EnvironmentProvisioningState,
    environmentProvisioningStates,
    terminalEnvironmentProvisioningStates,
} from '../constants/environment';
import { Environment, FailureOnEnvironment } from '../models/environment';
import { createFailureResponseFromCloudErrorBody } from './failure';

export const getFailureOnEnvironmentFromEnvironment = (environment: Environment): FailureOnEnvironment | undefined => {
    const { error, provisioningState } = environment;

    // No failure occurred if there's no error on the environment
    if (!error) {
        return undefined;
    }

    switch (provisioningState) {
        case EnvironmentProvisioningState.Canceled:
            return {
                failure: createFailureResponseFromCloudErrorBody(error),
                reason: EnvironmentFailureReason.ProvisioningCanceled,
            };

        case EnvironmentProvisioningState.Failed:
            return {
                failure: createFailureResponseFromCloudErrorBody(error),
                reason: EnvironmentFailureReason.ProvisioningFailed,
            };

        default:
            return undefined;
    }
};

export const getEnvironmentPendingProvisioningState = (state: string | undefined): EnvironmentProvisioningState => {
    if (!!state && environmentProvisioningStates.indexOf(state as EnvironmentProvisioningState) !== -1) {
        return state as EnvironmentProvisioningState;
    }

    return EnvironmentProvisioningState.Failed;
};

export const isEnvironmentInTerminalState = (state: string | undefined): boolean =>
    !!state && terminalEnvironmentProvisioningStates.indexOf(state as EnvironmentProvisioningState) !== -1;
