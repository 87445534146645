import { Icon, IIconStyles, ITooltipHostProps, makeStyles, mergeStyleSets, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { getSemanticColor } from '../../utilities/styles';

export interface InfoTooltipProps extends ITooltipHostProps {
    iconStyles?: IIconStyles;
    iconTabIndex?: number;
    disabled?: boolean;
}

/**
 * Style Section
 */

const useDefaultTooltipHostStyles = makeStyles({
    root: {
        display: 'flex',
    },
});

const defaultIconStylesFactory = (disabled: boolean | undefined) =>
    makeStyles((theme) => ({
        root: {
            color: disabled ? getSemanticColor(theme, AppSemanticColor.disabledText) : undefined,
            cursor: 'default',
        },
    }));

/* END */

export const InfoTooltip: React.FC<InfoTooltipProps> = (props: InfoTooltipProps) => {
    const {
        iconStyles: providedIconStyles,
        iconTabIndex,
        styles: providedTooltipHostStyles,
        disabled,
        content: providedContent,
    } = props;

    // Style hooks
    const useDefaultIconStyles = defaultIconStylesFactory(disabled);
    const defaultIconStyles = useDefaultIconStyles();
    const defaultTooltipHostStyles = useDefaultTooltipHostStyles();

    // Memoized data
    const iconStyles = React.useMemo(
        () => mergeStyleSets(defaultIconStyles, providedIconStyles),
        [defaultIconStyles, providedIconStyles]
    );

    const tooltipHostStyles = React.useMemo(
        () => mergeStyleSets(providedTooltipHostStyles, defaultTooltipHostStyles),
        [defaultTooltipHostStyles, providedTooltipHostStyles]
    );

    const content = React.useMemo(() => (disabled ? undefined : providedContent), [disabled, providedContent]);

    return (
        <TooltipHost {...props} content={content} styles={tooltipHostStyles}>
            <Icon aria-hidden="false" iconName="Info" styles={iconStyles} tabIndex={iconTabIndex || 0} />
        </TooltipHost>
    );
};

export default InfoTooltip;
