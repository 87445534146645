import {
    GraphDirectoryObjectContract,
    GraphDirectoryObjectTypeContract,
    GraphUserContract,
} from '../data/contracts/graph';
import { UnionMap } from '../types/union-map';

export type GraphDirectoryObject = GraphDirectoryObjectContract;

export type GraphDirectoryObjectType = GraphDirectoryObjectTypeContract;

export const GraphDirectoryObjectType: UnionMap<GraphDirectoryObjectType> = {
    user: 'user',
    servicePrincipal: 'servicePrincipal',
};

export type GraphUser = GraphUserContract;
