import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { addEnvironmentAcceptedListenerSaga, addEnvironmentListenerSaga } from './add-environment';
import { deleteEnvironmentAcceptedListenerSaga, deleteEnvironmentListenerSaga } from './delete-environment';
import { deployEnvironmentAcceptedListenerSaga, deployEnvironmentListenerSaga } from './deploy-environment';
import { discoverEnvironmentsInTenantListenerSaga } from './discover-environments-in-tenant';
import { listEnvironmentsListenerSaga } from './list-environments';
import { pollNonTerminalEnvironmentListenerSaga } from './poll-non-terminal-environment';
import { updateEnvironmentListenerSaga } from './update-environment';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(addEnvironmentListenerSaga),
        fork(addEnvironmentAcceptedListenerSaga),
        fork(deleteEnvironmentListenerSaga),
        fork(deleteEnvironmentAcceptedListenerSaga),
        fork(deployEnvironmentListenerSaga),
        fork(deployEnvironmentAcceptedListenerSaga),
        fork(listEnvironmentsListenerSaga),
        fork(discoverEnvironmentsInTenantListenerSaga),
        fork(pollNonTerminalEnvironmentListenerSaga),
        fork(updateEnvironmentListenerSaga),
    ]);
}
