import { ListAzureDevOpsBranchesResponse } from '../../../data/services/azure-dev-ops/vso-code';
import { AzureDevOpsBranch, AzureDevOpsRepo, AzureDevOpsTreeEntry } from '../../../models/azure-dev-ops';
import { Nothing } from '../../../models/common';
import { createActionCreator } from '../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import {
    AzureDevOpsActionType,
    BranchObjectIdIndexedPayload,
    GetAzureDevOpsRepoByCloneUriResponse,
    GetAzureDevOpsRepoContentsPayload,
    GetAzureDevOpsRepoContentsResponse,
    LoadAzureDevOpsRepoSubResourcesPayload,
    RepoCloneUrlIndexedPayload,
    RepoItemPathIdentifierIndexedPayload,
    RepoUrlIndexedPayload,
} from './azure-dev-ops-actions';

export const loadAzureDevOpsRepoResources = createActionCreator<LoadAzureDevOpsRepoSubResourcesPayload>(
    AzureDevOpsActionType.LoadAzureDevOpsRepoResources,
    { startsActivity: true }
);

export const loadAzureDevOpsRepoResourcesError = createActionCreator<ErrorPayload & RepoUrlIndexedPayload>(
    AzureDevOpsActionType.LoadAzureDevOpsRepoResourcesError
);

export const loadAzureDevOpsRepoResourcesFailed = createActionCreator<FailedPayload & RepoUrlIndexedPayload>(
    AzureDevOpsActionType.LoadAzureDevOpsRepoResourcesFailed
);

export const loadAzureDevOpsRepoResourcesSuccess = createActionCreator<RepoUrlIndexedPayload>(
    AzureDevOpsActionType.LoadAzureDevOpsRepoResourcesSuccess
);

export const getAzureDevOpsRepoByCloneUri = createActionCreator<
    RepoCloneUrlIndexedPayload,
    GetAzureDevOpsRepoByCloneUriResponse
>(AzureDevOpsActionType.GetAzureDevOpsRepoByCloneUri, { async: true });

export const getAzureDevOpsRepoByCloneUriError = createActionCreator<ErrorPayload & RepoCloneUrlIndexedPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoByCloneUriError
);

export const getAzureDevOpsRepoByCloneUriFailed = createActionCreator<FailedPayload & RepoCloneUrlIndexedPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoByCloneUriFailed
);

export const getAzureDevOpsRepoByCloneUriSuccess = createActionCreator<
    RepoCloneUrlIndexedPayload & ResultPayload<AzureDevOpsRepo | Nothing>
>(AzureDevOpsActionType.GetAzureDevOpsRepoByCloneUriSuccess);

export const listAzureDevOpsBranches = createActionCreator<RepoUrlIndexedPayload, ListAzureDevOpsBranchesResponse>(
    AzureDevOpsActionType.ListAzureDevOpsBranches,
    { async: true }
);

export const listAzureDevOpsBranchesError = createActionCreator<ErrorPayload & RepoUrlIndexedPayload>(
    AzureDevOpsActionType.ListAzureDevOpsBranchesError
);

export const listAzureDevOpsBranchesFailed = createActionCreator<FailedPayload & RepoUrlIndexedPayload>(
    AzureDevOpsActionType.ListAzureDevOpsBranchesFailed
);

export const listAzureDevOpsBranchesSuccess = createActionCreator<
    RepoUrlIndexedPayload & ResultPayload<AzureDevOpsBranch[]>
>(AzureDevOpsActionType.ListAzureDevOpsBranchesSuccess);

export const getAzureDevOpsRepoContents = createActionCreator<
    GetAzureDevOpsRepoContentsPayload,
    GetAzureDevOpsRepoContentsResponse
>(AzureDevOpsActionType.GetAzureDevOpsRepoContents, { async: true });

export const getAzureDevOpsRepoContentsError = createActionCreator<ErrorPayload & BranchObjectIdIndexedPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoContentsError
);

export const getAzureDevOpsRepoContentsFailed = createActionCreator<FailedPayload & BranchObjectIdIndexedPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoContentsFailed
);

export const getAzureDevOpsRepoContentsSuccess = createActionCreator<
    BranchObjectIdIndexedPayload & ResultPayload<AzureDevOpsTreeEntry[]>
>(AzureDevOpsActionType.GetAzureDevOpsRepoContentsSuccess);

/** Indexed by repo item identifier (see `getAzureDevOpsRepoItemId`) */
export const getAzureDevOpsRepoItem = createActionCreator<RepoItemPathIdentifierIndexedPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoItem
);

export const getAzureDevOpsRepoItemError = createActionCreator<RepoItemPathIdentifierIndexedPayload & ErrorPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoItemError
);

export const getAzureDevOpsRepoItemFailed = createActionCreator<RepoItemPathIdentifierIndexedPayload & FailedPayload>(
    AzureDevOpsActionType.GetAzureDevOpsRepoItemFailed
);

export const getAzureDevOpsRepoItemSuccess = createActionCreator<
    RepoItemPathIdentifierIndexedPayload & ResultPayload<string>
>(AzureDevOpsActionType.GetAzureDevOpsRepoItemSuccess);
