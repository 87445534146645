import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import getCustomizationTaskDefinition from './get-customization-task-definition.json';
import listCustomizationTaskDefinition from './list-customization-task-definitions.json';
import validateCustomizationTasksFailure from './validate-customization-tasks-failure.json';
import validateCustomizationTasksSuccess from './validate-customization-tasks-success.json';

export type DataPlaneApiProjectScenarioName =
    | 'GetCustomizationTaskDefinition'
    | 'ListCustomizationTaskDefinition'
    | 'ValidateCustomizationTasksFailure'
    | 'ValidateCustomizationTasksSuccess';

export const DataPlaneApiProjectScenarioName: UnionMap<DataPlaneApiProjectScenarioName> = {
    GetCustomizationTaskDefinition: 'GetCustomizationTaskDefinition',
    ListCustomizationTaskDefinition: 'ListCustomizationTaskDefinition',
    ValidateCustomizationTasksFailure: 'ValidateCustomizationTasksFailure',
    ValidateCustomizationTasksSuccess: 'ValidateCustomizationTasksSuccess',
};

export const DataPlaneApiProjectScenarios: UnionValueMap<DataPlaneApiProjectScenarioName, MockScenarioConfiguration> = {
    GetCustomizationTaskDefinition: getCustomizationTaskDefinition as MockScenarioConfiguration,
    ListCustomizationTaskDefinition: listCustomizationTaskDefinition as MockScenarioConfiguration,
    ValidateCustomizationTasksFailure: validateCustomizationTasksFailure as MockScenarioConfiguration,
    ValidateCustomizationTasksSuccess: validateCustomizationTasksSuccess as MockScenarioConfiguration,
};
