import { createSelector } from '@reduxjs/toolkit';
import { EnvironmentDefinition } from '../../models/environment-definition';
import { ProjectPermissionRecord } from '../../models/permission';
import { ProjectResource } from '../../models/project';
import { ProjectEnvironmentTypeResource } from '../../models/project-environment-type';
import { SerializableMap } from '../../types/serializable-map';
import { filter, get, keys, mapKeys, size } from '../../utilities/serializable-map';
import { stringAdapter } from '../adapters/common/string-adapter';
import { projectResourceAdapter } from '../adapters/project-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector, createSelectorEndingOnTrue } from './common';
import {
    getDoesProjectHaveAuthorizationForAnyDevResource,
    getIsProjectAuthorizedForDevBoxCreate,
    getIsProjectAuthorizedForDevBoxCustomize,
    getIsProjectAuthorizedForDevBoxRead,
    getIsProjectAuthorizedForEnvironmentRead,
    getIsProjectAuthorizedForEnvironmentWrite,
    getIsProjectAuthorizedForProjectEnvironmentTypeRead,
    getPermissionsForProjects,
} from './permission-selectors';

const createAreThereAnyProjectsWithPermissionsSelector =
    (predicate: (permissions: SerializableMap<ProjectPermissionRecord>, id: string) => boolean) =>
    (projects: SerializableMap<ProjectResource>, permissions: SerializableMap<ProjectPermissionRecord>) =>
        keys(projects).some((id) => predicate(permissions, id));

const createFilterToProjectsWithPermissionsSelector =
    (predicate: (permissions: SerializableMap<ProjectPermissionRecord>, id: string) => boolean) =>
    (projects: SerializableMap<ProjectResource>, permissions: SerializableMap<ProjectPermissionRecord>) =>
        filter(projects, (_, id) => predicate(permissions, id));

const mapProjectResourceIdsToDataPlaneIds = (
    projects: SerializableMap<ProjectResource>,
    dataPlaneIds: SerializableMap<string>
) => mapKeys(projects, (_, id) => get(dataPlaneIds, id) as string);

/**
 * Basic selectors
 */

export const getDoesProjectHaveAtLeastOneEnvironmentDefinition = (
    projectId: string,
    environmentDefinitionsByProject: SerializableMap<SerializableMap<EnvironmentDefinition>>
): boolean => {
    const environmentDefinitions = get(environmentDefinitionsByProject, projectId);
    return !!environmentDefinitions && size(environmentDefinitions) > 0;
};

export const getDoesProjectHaveAtLeastOneProjectEnvironmentType = (
    projectResourceId: string,
    projectEnvironmentTypes: SerializableMap<SerializableMap<ProjectEnvironmentTypeResource>>
): boolean => {
    const projectEnvironmentTypesInProject = get(projectEnvironmentTypes, projectResourceId);
    return !!projectEnvironmentTypesInProject && size(projectEnvironmentTypesInProject) > 0;
};

export const getProjectsWithAtLeastOneProjectEnvironmentType = (
    projects: SerializableMap<ProjectResource>,
    projectEnvironmentTypes: SerializableMap<SerializableMap<ProjectEnvironmentTypeResource>>
): SerializableMap<ProjectResource> =>
    filter(projects, (project) => {
        const projectEnvironmentTypesInProject = get(projectEnvironmentTypes, project.id);
        return !!projectEnvironmentTypesInProject && size(projectEnvironmentTypesInProject) > 0;
    });

/**
 * Entity state selectors
 */

const projectSelectors = projectResourceAdapter.getSelectors<StoreState>((store) => store.projectStore.data.projects);

export const getCountOfProjects: StoreStateSelector<number> = projectSelectors.selectTotal;

// Note: type assertion is here because selectIds always allows ID to be a number for some silly reason
export const getProjectResourceIds = projectSelectors.selectIds as StoreStateSelector<string[]>;

const resourceIdToDataPlaneIdSelectors = stringAdapter.getSelectors<StoreState>(
    (store) => store.projectStore.data.resourceIdsToDataPlaneIds
);

/**
 * Composed selectors
 */

export const getProjectDataPlaneIdsByResourceId = createMapFromEntitiesSelector(
    resourceIdToDataPlaneIdSelectors.selectAll
);

export const getProjectsByResourceId = createMapFromEntitiesSelector(projectSelectors.selectAll);

export const getProjectsByDataPlaneId: StoreStateSelector<SerializableMap<ProjectResource>> = createSelector(
    [getProjectsByResourceId, getProjectDataPlaneIdsByResourceId],
    mapProjectResourceIdsToDataPlaneIds
);

export const getHasProjectsAuthorizedForDevBoxCreate: StoreStateSelector<boolean> = createSelectorEndingOnTrue(
    [getProjectsByResourceId, getPermissionsForProjects],
    createAreThereAnyProjectsWithPermissionsSelector(getIsProjectAuthorizedForDevBoxCreate)
);

export const getHasProjectsAuthorizedForEnvironmentWrite: StoreStateSelector<boolean> = createSelectorEndingOnTrue(
    [getProjectsByResourceId, getPermissionsForProjects],
    createAreThereAnyProjectsWithPermissionsSelector(getIsProjectAuthorizedForEnvironmentWrite)
);

export const getProjectsAuthorizedForDevBoxCreateByResourceId: StoreStateSelector<SerializableMap<ProjectResource>> =
    createSelector(
        [getProjectsByResourceId, getPermissionsForProjects],
        createFilterToProjectsWithPermissionsSelector(getIsProjectAuthorizedForDevBoxCreate)
    );

export const getProjectsAuthorizedForDevBoxCreateByDataPlaneId: StoreStateSelector<SerializableMap<ProjectResource>> =
    createSelector(
        [getProjectsAuthorizedForDevBoxCreateByResourceId, getProjectDataPlaneIdsByResourceId],
        mapProjectResourceIdsToDataPlaneIds
    );

export const getProjectsAuthorizedForDevBoxReadByResourceId: StoreStateSelector<SerializableMap<ProjectResource>> =
    createSelector(
        [getProjectsByResourceId, getPermissionsForProjects],
        createFilterToProjectsWithPermissionsSelector(getIsProjectAuthorizedForDevBoxRead)
    );

export const getProjectsAuthorizedForDevBoxReadByDataPlaneId: StoreStateSelector<SerializableMap<ProjectResource>> =
    createSelector(
        [getProjectsAuthorizedForDevBoxReadByResourceId, getProjectDataPlaneIdsByResourceId],
        mapProjectResourceIdsToDataPlaneIds
    );

export const getProjectsAuthorizedForDevBoxCustomizeByResourceId: StoreStateSelector<SerializableMap<ProjectResource>> =
    createSelector(
        [getProjectsByResourceId, getPermissionsForProjects],
        createFilterToProjectsWithPermissionsSelector(getIsProjectAuthorizedForDevBoxCustomize)
    );

export const getProjectsAuthorizedForDevBoxCustomizeByDataPlaneId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsAuthorizedForDevBoxCustomizeByResourceId, getProjectDataPlaneIdsByResourceId],
    mapProjectResourceIdsToDataPlaneIds
);

export const getProjectsAuthorizedForEnvironmentReadByResourceId: StoreStateSelector<SerializableMap<ProjectResource>> =
    createSelector(
        [getProjectsByResourceId, getPermissionsForProjects],
        createFilterToProjectsWithPermissionsSelector(getIsProjectAuthorizedForEnvironmentRead)
    );

export const getProjectsAuthorizedForEnvironmentReadByDataPlaneId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsAuthorizedForEnvironmentReadByResourceId, getProjectDataPlaneIdsByResourceId],
    mapProjectResourceIdsToDataPlaneIds
);

export const getProjectsAuthorizedForEnvironmentWriteByResourceId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsByResourceId, getPermissionsForProjects],
    createFilterToProjectsWithPermissionsSelector(getIsProjectAuthorizedForEnvironmentWrite)
);

export const getProjectsAuthorizedForEnvironmentWriteByDataPlaneId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsAuthorizedForEnvironmentWriteByResourceId, getProjectDataPlaneIdsByResourceId],
    mapProjectResourceIdsToDataPlaneIds
);

export const getProjectsAuthorizedForProjectEnvironmentTypeReadByResourceId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsByResourceId, getPermissionsForProjects],
    createFilterToProjectsWithPermissionsSelector(getIsProjectAuthorizedForProjectEnvironmentTypeRead)
);

export const getProjectsAuthorizedForProjectEnvironmentTypeReadByDataPlaneId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsAuthorizedForProjectEnvironmentTypeReadByResourceId, getProjectDataPlaneIdsByResourceId],
    mapProjectResourceIdsToDataPlaneIds
);

export const getProjectsWithAnyDevResourceAuthorizationByResourceId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsByResourceId, getPermissionsForProjects],
    createFilterToProjectsWithPermissionsSelector(getDoesProjectHaveAuthorizationForAnyDevResource)
);

export const getProjectsWithAnyDevResourceAuthorizationByDataPlaneId: StoreStateSelector<
    SerializableMap<ProjectResource>
> = createSelector(
    [getProjectsWithAnyDevResourceAuthorizationByResourceId, getProjectDataPlaneIdsByResourceId],
    mapProjectResourceIdsToDataPlaneIds
);
