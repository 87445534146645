import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { Schedule } from '../../models/schedule';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { scheduleAdapter } from '../adapters/schedule-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'discoverSchedulesForDevBoxes' | 'discoverSchedulesForPools';
type IndexedStatusName = 'listSchedulesByProject';

export interface ScheduleDataStore {
    schedules: EntityState<Entity<Schedule>>;
}

export const ScheduleDataStore = createInitializer<ScheduleDataStore>({
    schedules: scheduleAdapter.getInitialState(),
});

export type ScheduleStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const ScheduleStatusStore = createInitializer<ScheduleStatusStore>({
    discoverSchedulesForDevBoxes: Status(),
    discoverSchedulesForPools: Status(),
    listSchedulesByProject: statusAdapter.getInitialState(),
});

export interface ScheduleStore {
    data: ScheduleDataStore;
    status: ScheduleStatusStore;
}

export const ScheduleStore = createInitializer<ScheduleStore>({
    data: ScheduleDataStore(),
    status: ScheduleStatusStore(),
});
