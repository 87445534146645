import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListSchedulesByProjectResponse } from '../../../data/services/data-plane-api/schedule';
import { ClientError, FailureOperation, isAggregatedFailure, isAggregatedSuccess } from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverSchedulesForDevBoxes,
    discoverSchedulesForDevBoxesError,
    discoverSchedulesForDevBoxesFailed,
    discoverSchedulesForDevBoxesSuccess,
    listSchedulesByProject,
} from '../../actions/schedule/schedule-action-creators';
import { DiscoverSchedulesForDevBoxesAction } from '../../actions/schedule/schedule-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getProjectIdsForDevBoxesRequiringSchedules } from './selectors';

export function* discoverSchedulesForDevBoxesSaga(action: DiscoverSchedulesForDevBoxesAction): SagaIterator {
    const startTime = new Date();

    try {
        const ids: string[] = yield select(getProjectIdsForDevBoxesRequiringSchedules);

        const responses: ListSchedulesByProjectResponse[] = yield all(
            ids.map((id) => putAndAwait(listSchedulesByProject({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            // All requests failed
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadSchedulesUsedByDevBoxes, ...failures);
            yield put(discoverSchedulesForDevBoxesFailed({ failure }));
        } else {
            yield put(discoverSchedulesForDevBoxesSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverSchedulesForDevBoxes,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadSchedulesUsedByDevBoxes);
        yield put(discoverSchedulesForDevBoxesError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverSchedulesForDevBoxes,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverSchedulesForDevBoxesListenerSaga(): SagaIterator {
    yield takeLeading(discoverSchedulesForDevBoxes, discoverSchedulesForDevBoxesSaga);
}
