import { createSelector } from '@reduxjs/toolkit';
import { TenantId } from '../../constants/tenant';
import { Status } from '../../models/common';
import { Tenant } from '../../models/resource-manager';
import { tenantAdapter } from '../adapters/tenant-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createArrayFromEntitiesSelector } from './common';
import { getActiveAccount } from './identity-selectors';

/**
 * Basic selectors
 */

export const getStatusForListTenants: StoreStateSelector<Status> = (store) => store.tenantStore.status.listTenants;

/**
 * Entity state selectors
 */

const tenantsSelectors = tenantAdapter.getSelectors<StoreState>((store) => store.tenantStore.data.tenants);

/**
 * Composed selectors
 */

export const getHasMultipleTenants: StoreStateSelector<boolean> = createSelector(
    [tenantsSelectors.selectTotal],
    (total) => total > 1
);

export const getTenants = createArrayFromEntitiesSelector(tenantsSelectors.selectAll);

export const getSelectedTenant: StoreStateSelector<Tenant | undefined> = createSelector(
    [getTenants, getActiveAccount],
    (tenants, activeAccount) => {
        // If no account is active or tenant ID hasn't been set, return undefined
        if (activeAccount === undefined || tenants.length < 1) {
            return undefined;
        }

        return tenants.find((tenant) => tenant.tenantId === activeAccount.tenantId);
    }
);

export const getIsUserSignedIntoMicrosoftTenant: StoreStateSelector<boolean> = createSelector(
    [getSelectedTenant],
    (tenant) => tenant?.tenantId === TenantId.Microsoft
);
