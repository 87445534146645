import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import {
    ProjectEnvironmentTypeAbilities,
    ProjectEnvironmentTypeFromDataPlane,
    ProjectEnvironmentTypeResource,
} from '../../models/project-environment-type';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import {
    projectEnvironmentTypeAbilitiesAdapter,
    projectEnvironmentTypeFromDataPlaneAdapter,
    projectEnvironmentTypeResourceAdapter,
} from '../adapters/project-environment-type-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName =
    | 'discoverProjectEnvironmentTypes'
    | 'discoverProjectEnvironmentTypesFromDataplane'
    | 'discoverProjectEnvironmentTypeAbilities';
type IndexedStatusName =
    | 'listProjectEnvironmentTypes'
    | 'listProjectEnvironmentTypesFromDataplane'
    | 'getProjectEnvironmentTypeAbilities';

export interface ProjectEnvironmentTypeDataStore {
    projectEnvironmentTypes: EntityState<Entity<ProjectEnvironmentTypeResource>>;
    projectEnvironmentTypesFromDataplane: EntityState<Entity<ProjectEnvironmentTypeFromDataPlane>>;
    projectEnvironmentTypeAbilities: EntityState<Entity<ProjectEnvironmentTypeAbilities>>;
}

export const ProjectEnvironmentTypeDataStore = createInitializer<ProjectEnvironmentTypeDataStore>({
    projectEnvironmentTypes: projectEnvironmentTypeResourceAdapter.getInitialState(),
    projectEnvironmentTypesFromDataplane: projectEnvironmentTypeFromDataPlaneAdapter.getInitialState(),
    projectEnvironmentTypeAbilities: projectEnvironmentTypeAbilitiesAdapter.getInitialState(),
});

export type ProjectEnvironmentTypeStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const ProjectEnvironmentTypeStatusStore = createInitializer<ProjectEnvironmentTypeStatusStore>({
    discoverProjectEnvironmentTypes: Status(),
    listProjectEnvironmentTypes: statusAdapter.getInitialState(),
    discoverProjectEnvironmentTypesFromDataplane: Status(),
    listProjectEnvironmentTypesFromDataplane: statusAdapter.getInitialState(),
    discoverProjectEnvironmentTypeAbilities: Status(),
    getProjectEnvironmentTypeAbilities: statusAdapter.getInitialState(),
});

export interface ProjectEnvironmentTypeStore {
    data: ProjectEnvironmentTypeDataStore;
    status: ProjectEnvironmentTypeStatusStore;
}

export const ProjectEnvironmentTypeStore = createInitializer<ProjectEnvironmentTypeStore>({
    data: ProjectEnvironmentTypeDataStore(),
    status: ProjectEnvironmentTypeStatusStore(),
});
