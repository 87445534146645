import {
    IContextualMenuItemStyleProps,
    IContextualMenuItemStyles,
    IContextualMenuStyleProps,
    IContextualMenuStyles,
} from '@fluentui/react';

const ContextualMenuItemStyles = (props: IContextualMenuItemStyleProps): Partial<IContextualMenuItemStyles> => {
    const { theme } = props;
    const { semanticColors } = theme;

    return {
        divider: {
            backgroundColor: semanticColors.menuDivider,
        },
        icon: {
            color: semanticColors.link,
        },
    };
};

export const ContextualMenuStyles = (_props: IContextualMenuStyleProps): Partial<IContextualMenuStyles> => {
    return {
        subComponentStyles: {
            // Note: content is required by IContextualMenuStyles...
            callout: {},
            menuItem: ContextualMenuItemStyles,
        },
    };
};
