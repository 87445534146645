import * as React from 'react';
import { ClientError, FailureOperation } from '../models/common';
import ErrorPage from '../pages/error';
import { trackException } from '../utilities/telemetry/channel';

interface Props {
    outsideOfProviders?: boolean;
}

interface State {
    hasError: boolean;
}

// Using Component instead of functional component to use componentDidCatch.
export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        const clientError = new ClientError(error, FailureOperation.ErrorBoundary);
        trackException(clientError, {
            properties: {
                errorInfo: errorInfo?.componentStack ?? '',
            },
        });
    }

    render(): React.ReactNode {
        const { children, outsideOfProviders } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            // Disable localized content if this is boundary is outside of providers. In such situations, we won't have
            // access to Redux state and therefore the current locale. (As well as theme)
            return <ErrorPage disableLocalizedContent={outsideOfProviders} />;
        }

        return children;
    }
}

export default ErrorBoundary;
