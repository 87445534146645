import { IStackTokens, Link, Stack } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import EnvironmentDefinitionDropdown from '../../common/form/dropdown/data-controls/environment-definition-dropdown';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { EnvironmentDefinitionViewModel } from '../models';
import SelectEnvironmentDefinitionDialog from '../select-environment-definition-dialog/select-environment-definition-dialog';

interface AddEnvironmentFormEnvironmentDefinitionControlProps {
    isSubmitting: boolean;
    environmentDefinitions: EnvironmentDefinitionViewModel[];
    selectedEnvironmentDefinition: EnvironmentDefinitionViewModel | undefined;
    errorMessage: string;
    onChange: (event: EnvironmentDefinitionViewModel | undefined) => void;
}

const messages = defineMessages({
    addEnvironmentFormEnvironmentDefinitionFieldDropdownText: {
        id: 'AddEnvironmentFormEnvironmentDefinitionControl_Dropdown_Text',
        defaultMessage: 'Definition',
        description: 'Text for the definition dropdown in the add environment panel label',
    },
    addEnvironmentFormEnvironmentDefinitionFieldDropdownAriaLabel: {
        id: 'AddEnvironmentFormEnvironmentDefinitionControl_Dropdown_AriaLabel',
        defaultMessage: 'Definition',
        description: 'Aria label for the environment definition dropdown in the add environment panel',
    },
    addEnvironmentFormEnvironmentDefinitionFieldDropdownDisambiguatedName: {
        id: 'AddEnvironmentFormEnvironmentDefinitionControl_Dropdown_DisambiguatedName',
        defaultMessage: '{name} ({catalog})',
        description:
            'Dropdown option text to disambiguate between environment definitions with duplicate names. Do not localize {name}. Do not localize {catalog}',
    },
    addEnvironmentFormEnvironmentDefinitionFieldPlaceholderText: {
        id: 'AddEnvironmentFormEnvironmentDefinitionControl_PlaceholderText',
        defaultMessage: 'Select an environment definition',
        description: 'Placeholder text for the select environment definition field on the environment create form.',
    },
});

/**
 * Style Section
 */

const environmentDefinitionSelectionContainerTokens: IStackTokens = {
    childrenGap: 8,
};

/* END */

export const AddEnvironmentFormEnvironmentDefinitionControl: React.FC<
    AddEnvironmentFormEnvironmentDefinitionControlProps
> = (props) => {
    const { isSubmitting, environmentDefinitions, selectedEnvironmentDefinition, errorMessage, onChange } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // State hooks
    const [showSeeAllDialog, setShowSeeAllDialog] = React.useState(false);
    const linkRef = React.useRef<HTMLAnchorElement>(null);

    // Memo hooks
    const hasMultipleOptions = environmentDefinitions.length > 1;

    const onSeeAllDismiss = React.useCallback(() => {
        setShowSeeAllDialog(false);
        if (linkRef.current) {
            linkRef.current.focus();
        }
    }, [linkRef.current]);
    const onSeeAllClick = React.useCallback(() => setShowSeeAllDialog(true), []);

    return (
        <Stack tokens={environmentDefinitionSelectionContainerTokens}>
            <Stack.Item>
                <EnvironmentDefinitionDropdown
                    value={selectedEnvironmentDefinition}
                    label={formatMessage(messages.addEnvironmentFormEnvironmentDefinitionFieldDropdownText)}
                    ariaLabel={formatMessage(messages.addEnvironmentFormEnvironmentDefinitionFieldDropdownAriaLabel)}
                    options={environmentDefinitions}
                    onChange={onChange}
                    disabled={isSubmitting}
                    errorMessage={errorMessage}
                    placeholder={formatMessage(messages.addEnvironmentFormEnvironmentDefinitionFieldPlaceholderText)}
                    autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                    required
                />
            </Stack.Item>
            {hasMultipleOptions && (
                <Stack.Item>
                    <Link onClick={onSeeAllClick} ref={linkRef}>
                        <FormattedMessage
                            id="AddEnvironmentFormEnvironmentDefinitionControl_SeeAllEnvironmentDefinitionsLink_Text"
                            defaultMessage="See all definitions"
                            description="Text for the see all definitions link for the add environment definition field"
                        />
                    </Link>
                    <SelectEnvironmentDefinitionDialog
                        environmentDefinitions={environmentDefinitions}
                        onDismiss={onSeeAllDismiss}
                        showDialog={showSeeAllDialog}
                        onChange={onChange}
                        selectedEnvironmentDefinition={selectedEnvironmentDefinition}
                    />
                </Stack.Item>
            )}
        </Stack>
    );
};

export default AddEnvironmentFormEnvironmentDefinitionControl;
