import { SearchParameter } from '../constants/app';
import { ResourceFilterType, isResourceFilter } from '../constants/filters';
import { isNotUndefinedOrWhiteSpace } from './string';
import {
    clearSearchParameterFromCurrentLocation,
    getSearchParameterFromCurrentLocation,
    replaceCurrentLocation,
    setSearchParameterOnCurrentLocation,
} from './url';

export const updateLocationParameter = (param: SearchParameter, value: string | undefined): void => {
    const location = isNotUndefinedOrWhiteSpace(value)
        ? setSearchParameterOnCurrentLocation(param, value)
        : clearSearchParameterFromCurrentLocation(param);

    replaceCurrentLocation(location);
};

export const updateResourceFilterParameter = (value: ResourceFilterType | undefined): void =>
    updateLocationParameter(SearchParameter.Resource, value ?? undefined);

export const updateProjectFilterParameter = (value: string | undefined): void =>
    updateLocationParameter(SearchParameter.Project, value ? value : undefined);

export const getResourceFilterFromCurrentLocation = (): ResourceFilterType | undefined => {
    const value = getSearchParameterFromCurrentLocation(SearchParameter.Resource);

    if (!isResourceFilter(value)) {
        return undefined;
    }

    return value;
};

export const getProjectFilterFromCurrentLocation = (): string | undefined => {
    const value = getSearchParameterFromCurrentLocation(SearchParameter.Project);

    const id = value ? value : undefined;

    return id;
};

export const clearFilterFromCurrentLocation = (param: SearchParameter): void => {
    const newUrl: URL = clearSearchParameterFromCurrentLocation(param);

    replaceCurrentLocation(newUrl);
};

export const clearFiltersFromCurrentLocation = (): void => {
    clearFilterFromCurrentLocation(SearchParameter.Resource);
    clearFilterFromCurrentLocation(SearchParameter.Project);
};
