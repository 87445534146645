import { DataResponse } from '../../../models/common';
import { FeatureFlagSet } from '../../../models/features';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, ResultPayload } from '../core-actions';
import {
    DisableFeaturesPayload,
    EnableFeaturesPayload,
    FeaturesActionType,
    FreezeFeaturesPayload,
    SetFeaturesPayload,
    ToggleFeaturesPayload,
} from './features-actions';

/**
 * Action creators
 */

export const clearFeatures = createActionCreatorWithoutPayload(FeaturesActionType.ClearFeatures);
export const disableFeatures = createActionCreator<DisableFeaturesPayload>(FeaturesActionType.DisableFeatures);
export const enableFeatures = createActionCreator<EnableFeaturesPayload>(FeaturesActionType.EnableFeatures);
export const freezeFeatures = createActionCreator<FreezeFeaturesPayload>(FeaturesActionType.FreezeFeatures);

export const initializeFeatures = createActionCreatorWithoutPayload<DataResponse<FeatureFlagSet>>(
    FeaturesActionType.InitializeFeatures,
    { async: true }
);

export const initializeFeaturesError = createActionCreator<ErrorPayload>(FeaturesActionType.InitializeFeaturesError);

export const initializeFeaturesSuccess = createActionCreator<ResultPayload<FeatureFlagSet>>(
    FeaturesActionType.InitializeFeaturesSuccess
);

export const setFeatures = createActionCreator<SetFeaturesPayload>(FeaturesActionType.SetFeatures);
export const toggleFeatures = createActionCreator<ToggleFeaturesPayload>(FeaturesActionType.ToggleFeatures);
