import { createActionCreator } from '../core-action-creators';
import { DeleteGlobalPropertyPayload, SetGlobalPropertyPayload, TelemetryActionType } from './telemetry-actions';

/**
 * Action creators
 */

export const deleteGlobalProperty = createActionCreator<DeleteGlobalPropertyPayload>(
    TelemetryActionType.DeleteGlobalProperty
);

export const setGlobalProperty = createActionCreator<SetGlobalPropertyPayload>(TelemetryActionType.SetGlobalProperty);
