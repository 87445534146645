import { FontSizes, FontWeights, Icon, IFontWeight, IStackTokens, makeStyles, Stack, Theme } from '@fluentui/react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Failure } from '../../../models/common';
import { PropsWithChildren } from '../../../types/props-with-children';
import { getSemanticColor } from '../../../utilities/styles';
import { AriaLiveWrapper } from '../accessibility/aria-live-wrapper';
import { FluentIconNames } from '../fluent-icon-names';
import FailureCodeAndMessage from './failure-code-and-message';
import { FailureMessageSeverity, FailureMessageSize } from './models';

const messages = defineMessages({
    failureMessageIconAriaLabel: {
        id: 'FailureMessage_Icon_AriaLabel',
        defaultMessage: 'Error',
        description: 'Aria label for the failure message icon',
    },
    failureMessageWarningIconAriaLabel: {
        id: 'FailureMessage_Warning_Icon_AriaLabel',
        defaultMessage: 'Warning',
        description: 'Aria label for the warning message icon',
    },
});

const getMessageFontWeight = (severity: FailureMessageSeverity | undefined): IFontWeight => {
    switch (severity) {
        case FailureMessageSeverity.Warning:
            return FontWeights.regular;
        case FailureMessageSeverity.Error:
        default:
            return FontWeights.semibold;
    }
};

const getMessageColor = (theme: Theme, severity: FailureMessageSeverity | undefined): string => {
    switch (severity) {
        case FailureMessageSeverity.Warning:
            return getSemanticColor(theme, 'warningMessageText');
        case FailureMessageSeverity.Error:
        default:
            return getSemanticColor(theme, 'errorMessageText');
    }
};

const getMessageFontSize = (size: FailureMessageSize | undefined): string => {
    switch (size) {
        case FailureMessageSize.Compact:
            return FontSizes.size12;
        case FailureMessageSize.Default:
        default:
            return FontSizes.size14;
    }
};

const getMessageIconColor = (theme: Theme, severity: FailureMessageSeverity | undefined): string => {
    switch (severity) {
        case FailureMessageSeverity.Warning:
            return getSemanticColor(theme, 'warningMessageIcon');
        case FailureMessageSeverity.Error:
        default:
            return getSemanticColor(theme, 'errorMessageIcon');
    }
};

const failureMessageStylesFactory = (
    severity: FailureMessageSeverity | undefined,
    size: FailureMessageSize | undefined
) =>
    makeStyles((theme) => ({
        root: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 19,
            fontSize: getMessageFontSize(size),
            fontWeight: getMessageFontWeight(severity),
            color: getMessageColor(theme, severity),
        },
    }));

const failureMessageIconStylesFactory = (
    severity: FailureMessageSeverity | undefined,
    size: FailureMessageSize | undefined
) =>
    makeStyles((theme) => ({
        root: {
            fontSize: getMessageFontSize(size),
            color: getMessageIconColor(theme, severity),
        },
    }));

const footerMessageContainerToken: IStackTokens = {
    childrenGap: 8,
};

type FailureMessageProps = PropsWithChildren<{
    failure?: Failure;
    /** Defaults to `FailureMessageSeverity.Error` */
    severity?: FailureMessageSeverity;
    size?: FailureMessageSize;
}>;

const FailureMessage: React.FC<FailureMessageProps> = React.memo((props: FailureMessageProps) => {
    const { children, failure, severity, size } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const useFailureMessageStyles = failureMessageStylesFactory(severity, size);
    const failureMessageStyles = useFailureMessageStyles();
    const useFailureMessageIconStyles = failureMessageIconStylesFactory(severity, size);
    const failureMessageIconStyles = useFailureMessageIconStyles();

    // Memoized data
    const iconAriaLabel = React.useMemo(() => {
        switch (severity) {
            case FailureMessageSeverity.Warning:
                return formatMessage(messages.failureMessageWarningIconAriaLabel);
            case FailureMessageSeverity.Error:
            default:
                return formatMessage(messages.failureMessageIconAriaLabel);
        }
    }, [severity, formatMessage]);

    const iconName = React.useMemo(() => {
        switch (severity) {
            case FailureMessageSeverity.Warning:
                return FluentIconNames.WarningSolid;
            case FailureMessageSeverity.Error:
            default:
                return FluentIconNames.ErrorBadge;
        }
    }, [severity]);

    return (
        <Stack horizontal styles={failureMessageStyles} tokens={footerMessageContainerToken}>
            <Stack.Item styles={failureMessageIconStyles}>
                <Icon aria-label={iconAriaLabel} iconName={iconName} />
            </Stack.Item>
            <Stack.Item styles={failureMessageStyles}>
                <AriaLiveWrapper>
                    {children}
                    <FailureCodeAndMessage failure={failure} />
                </AriaLiveWrapper>
            </Stack.Item>
        </Stack>
    );
});

export default FailureMessage;
