import { FontSizes, Stack, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { stringify } from 'yaml';
import { parseWingetYaml } from './selectors';

interface WingetConfigurationDetailsProps {
    style?: string;
    content: string;
}

/**
 * Style Section
 */
const usePrettyYamlStyle = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
    },
});

export const WingetConfigurationDetails: React.FC<WingetConfigurationDetailsProps> = (
    props: WingetConfigurationDetailsProps
) => {
    const { style, content } = props;

    // Style hooks
    const prettyYamlStyle = usePrettyYamlStyle();

    const prettyWingetConfig = React.useMemo(() => stringify(parseWingetYaml(content)), [content]);

    return (
        <Stack className={prettyYamlStyle.root}>
            <Stack.Item className={style}>{prettyWingetConfig}</Stack.Item>
        </Stack>
    );
};

export default WingetConfigurationDetails;
