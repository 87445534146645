import { Dialog, DialogType, IDialogContentProps, IModalProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDelayShutdownDialogContext } from '../../../hooks/context/dialogs';
import { AsyncState } from '../../../redux/store/common-state';
import DelayShutdownDialogForm from './delay-shutdown-dialog-form';

export interface DelayShutdownDialogComponentProps {
    devBoxId: string;
    hidden?: boolean;
    locale: string;
    onDismiss: () => void;
    onDelaySubmitted: (devBoxId: string, delayUntil: Date) => void;
    onSkipSubmitted: (devBoxId: string) => void;
    hasHibernateSupport: boolean;
    originalScheduledTime: Date;
    nextScheduledTime: Date;
    skipState: AsyncState;
    delayState: AsyncState;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'DelayShutdownDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for "close" button in the "Delay shutdown" dialog',
    },
    shutdownHeader: {
        id: 'DelayShutdownDialog_Header_Text',
        defaultMessage: 'Delay shutdown',
        description: 'Title of the "Delay shutdown" dialog',
    },
    hibernateHeader: {
        id: 'DelayShutdownDialog_HibernateHeader_Text',
        defaultMessage: 'Delay hibernate',
        description: 'Title of the "Delay hibernate" dialog',
    },
});

/**
 * Styles
 */

// Not able to find a work around from using !important here. The model automatically sets the width of the dialog.
const modalWidth = '409px !important';

const useModalStyles = makeStyles({
    main: {
        maxWidth: modalWidth,
        width: modalWidth,
    },
});

const useDialogStyles = makeStyles({
    inner: {
        padding: '26px 24px 24px 24px',
    },
});

/**
 * END Styles
 */

const DelayShutdownDialog: React.FC<DelayShutdownDialogComponentProps> = (props: DelayShutdownDialogComponentProps) => {
    const {
        hidden,
        devBoxId,
        locale,
        onDismiss,
        onSkipSubmitted,
        onDelaySubmitted,
        hasHibernateSupport,
        nextScheduledTime,
        originalScheduledTime,
        skipState,
        delayState,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const dialogStyles = useDialogStyles();
    const modalStyles = useModalStyles();

    // Memoized data
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    const headerMessage = messages.shutdownHeader;
    const dialogContentProps = React.useMemo<IDialogContentProps>(
        () => ({
            styles: dialogStyles,
            title: formatMessage(headerMessage),
            type: DialogType.normal,
        }),
        [dialogStyles, formatMessage]
    );

    return (
        <Dialog
            closeButtonAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
            dialogContentProps={dialogContentProps}
            hidden={hidden}
            maxWidth={modalWidth}
            modalProps={modalProps}
            onDismiss={onDismiss}
        >
            <DelayShutdownDialogForm
                devBoxId={devBoxId}
                locale={locale}
                originalScheduledTime={originalScheduledTime}
                nextScheduledTime={nextScheduledTime}
                onDelaySubmitted={onDelaySubmitted}
                onSkipSubmitted={onSkipSubmitted}
                onDismiss={onDismiss}
                skipState={skipState}
                delayState={delayState}
                hasHibernateSupport={hasHibernateSupport}
            />
        </Dialog>
    );
};

export const DelayShutdownDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useDelayShutdownDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <DelayShutdownDialog {...properties} hidden={!isOpen} onDismiss={closeSurface} />;
};

export default DelayShutdownDialogContextWrapper;
