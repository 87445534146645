import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListEnvironmentDefinitionsResponse } from '../../../data/services/data-plane-api/environment-definition';
import { ClientError, FailureOperation, isAggregatedFailure, isAggregatedSuccess } from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverEnvironmentDefinitionsForEnvironments,
    discoverEnvironmentDefinitionsForEnvironmentsError,
    discoverEnvironmentDefinitionsForEnvironmentsFailed,
    discoverEnvironmentDefinitionsForEnvironmentsSuccess,
    listEnvironmentDefinitions,
} from '../../actions/environment-definition/environment-definition-action-creators';
import { DiscoverEnvironmentDefinitionsForEnvironmentsAction } from '../../actions/environment-definition/environment-definition-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getProjectIdsForEnvironmentsRequiringEnvironmentDefinitions } from './selectors';

export function* discoverEnvironmentDefinitionsForEnvironmentsSaga(
    action: DiscoverEnvironmentDefinitionsForEnvironmentsAction
): SagaIterator {
    const startTime = new Date();

    try {
        // Figure out what projects we need to list environment definitions for (only one project per dev center)
        const ids: string[] = yield select(getProjectIdsForEnvironmentsRequiringEnvironmentDefinitions);

        const responses: ListEnvironmentDefinitionsResponse[] = yield all(
            ids.map((id) => putAndAwait(listEnvironmentDefinitions({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(
                FailureOperation.DiscoverEnvironmentDefinitionsForEnvironments,
                ...failures
            );

            yield put(discoverEnvironmentDefinitionsForEnvironmentsFailed({ failure }));
        } else {
            yield put(discoverEnvironmentDefinitionsForEnvironmentsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverEnvironmentDefinitionsForEnvironments,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.DiscoverEnvironmentDefinitionsForEnvironments
        );

        yield put(discoverEnvironmentDefinitionsForEnvironmentsError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverEnvironmentDefinitionsForEnvironments,
            startTime,
            AsyncOutcome.Failed,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverEnvironmentDefinitionsForEnvironmentsListenerSaga(): SagaIterator {
    yield takeLeading(discoverEnvironmentDefinitionsForEnvironments, discoverEnvironmentDefinitionsForEnvironmentsSaga);
}
