import { DateTime } from 'luxon';
import { areStringsEquivalent } from '../../../../utilities/string';
import { getDefaultTimeZone } from '../../../../utilities/time';
import { getExpirationDate } from '../../../add-environment-panel/selectors';
import { EnvironmentExpirationViewModel } from './models';

export const getCurrentStartDate = (selectedTimeZone: string | undefined): Date => {
    const minDate = new Date();

    const timeZone = selectedTimeZone ?? getDefaultTimeZone();
    const minDateTime = DateTime.fromJSDate(minDate, { zone: timeZone });

    return new Date(minDateTime.year, minDateTime.month - 1, minDateTime.day); //JS Date is 0 index and DateTime is 1 index for months
};

export const areExpirationDatesEqual = (
    initialEnvironmentExpiration: EnvironmentExpirationViewModel,
    newEnvironmentExpiration: EnvironmentExpirationViewModel
): boolean => {
    if (initialEnvironmentExpiration === undefined && newEnvironmentExpiration === undefined) {
        return true;
    }

    if (initialEnvironmentExpiration === undefined || newEnvironmentExpiration === undefined) {
        return false;
    }

    const { expirationTimeZone: initialTimeZone } = initialEnvironmentExpiration;

    const { expirationTimeZone: newTimeZone } = newEnvironmentExpiration;

    if (!areStringsEquivalent(initialTimeZone, newTimeZone, true)) {
        return false;
    }

    const newExpirationDate = getExpirationDate(newEnvironmentExpiration);
    const initialExpirationDate = getExpirationDate(initialEnvironmentExpiration);

    if (newExpirationDate === undefined && initialExpirationDate === undefined) {
        return true;
    }

    if (newExpirationDate === undefined || initialExpirationDate === undefined) {
        return false;
    }

    if (newExpirationDate.getTime() === initialExpirationDate.getTime()) {
        return true;
    }

    return false;
};
