import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AzureErrorCode, FullResourceType } from '../../../../constants/azure';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    ListResourcesInTenantResponse,
    listResourcesInTenant,
} from '../../../../data/services/resource-manager/resource-graph';
import { ClientError, FailureOperation, isFailureResponse } from '../../../../models/common';
import { ProjectResource } from '../../../../models/project';
import { ResourceProvisioningState } from '../../../../models/resource-manager';
import { areStringsEquivalent } from '../../../../utilities/string';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { getAccessToken } from '../../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureResourceManagerPayload } from '../../../actions/identity/identity-actions';
import {
    loadControlPlaneResourcesForHome,
    loadControlPlaneResourcesForHomeError,
    loadControlPlaneResourcesForHomeFailed,
    loadControlPlaneResourcesForHomeSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadControlPlaneResourcesForHomeAction } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';

export function* loadControlPlaneResourcesForHomeSaga(action: LoadControlPlaneResourcesForHomeAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};
    const startTime = new Date();

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForAzureResourceManagerPayload(), meta)
        );

        const response: ListResourcesInTenantResponse = yield call(
            listResourcesInTenant,
            [FullResourceType.Projects],
            accessToken,
            [ResourceProvisioningState.Succeeded],
            activityId
        );

        if (isFailureResponse(response)) {
            const { code } = response;

            // If we receive AccessDenied, this means the user doesn't have access to any resources. Implicitly this
            // also means they don't have access to any pools. Treat this as a success with an empty list.
            if (code === AzureErrorCode.AccessDenied) {
                yield put(loadControlPlaneResourcesForHomeSuccess({ result: [] }, meta));
                yield resolveAction(action, { data: [], succeeded: true });

                yield call(
                    trackTimedPerformanceMetric,
                    PerformanceMetric.LoadControlPlaneResourcesForHome,
                    startTime,
                    AsyncOutcome.Success,
                    { activityId }
                );

                return;
            }

            yield put(loadControlPlaneResourcesForHomeFailed({ failure: response }, meta));
            yield resolveAction(action, response);

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.LoadControlPlaneResourcesForHome,
                startTime,
                AsyncOutcome.Failed,
                { activityId, errorCodes: [code] }
            );

            return;
        }

        const { data } = response;

        const projects = data.filter((resource) =>
            areStringsEquivalent(resource.type, FullResourceType.Projects, true)
        ) as ProjectResource[];

        yield put(loadControlPlaneResourcesForHomeSuccess({ result: projects }, meta));
        yield resolveAction(action, { data: projects, succeeded: true });

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadControlPlaneResourcesForHome,
            startTime,
            AsyncOutcome.Success,
            { activityId }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadControlPlaneResourcesForHome);
        yield put(loadControlPlaneResourcesForHomeError({ error }, meta));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadControlPlaneResourcesForHome,
            startTime,
            AsyncOutcome.Error,
            { activityId, errorCodes: [error.code] }
        );
    }
}

export function* loadControlPlaneResourcesForHomeListenerSaga(): SagaIterator {
    yield takeLeading(loadControlPlaneResourcesForHome, loadControlPlaneResourcesForHomeSaga);
}
