import { SagaIterator } from 'redux-saga';
import { ActionPattern, cancel, fork, join, take } from 'redux-saga/effects';
import { ClientError } from '../../models/common';
import { trackException } from '../../utilities/telemetry/channel';
import { Action } from '../actions/core-actions';

export type CancelableSignatureWorker<TPayload, TAdditionalMeta, TAwaited, TType extends string> = (
    action: Action<TPayload, TAdditionalMeta, TAwaited, TType>
) => SagaIterator;

export const createCancelableSaga = <TPayload, TAdditionalMeta, TAwaited, TType extends string>(
    worker: CancelableSignatureWorker<TPayload, TAdditionalMeta, TAwaited, TType>,
    cancelPattern: ActionPattern
) => {
    return function* (action: Action<TPayload, TAdditionalMeta, TAwaited, TType>): SagaIterator {
        try {
            const task = yield fork(worker, action);

            // Listen for the first instance of cancel pattern, and cancel the task when it occurs.
            // This is fairly similar to takeLeading, except we stop listening after receiving after cancel occurs.
            yield fork(function* () {
                yield take(cancelPattern);
                yield cancel(task);
            });

            // Wait for the worker to finish or be cancelled
            yield join(task);
        } catch (err) {
            const error = new ClientError(err);
            trackException(error);
        }
    };
};
