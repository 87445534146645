import {
    FontWeights,
    GroupHeader,
    IGroupHeaderProps,
    IStackTokens,
    Icon,
    Link,
    Spinner,
    SpinnerSize,
    Stack,
    makeStyles,
} from '@fluentui/react';
import * as React from 'react';
import { FormattedNumber, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDynamicMakeStyles } from '../../hooks/styling';
import { getTokensFromCustomizationGroupDataPlaneUri } from '../../ids/customization-group';
import { CustomizationTask, CustomizationTaskStatus } from '../../models/customization';
import { getCustomizationTaskLogs } from '../../redux/selector/customization-selectors';
import { SerializableMap } from '../../types/serializable-map';
import { get } from '../../utilities/serializable-map';
import { getSemanticColor } from '../../utilities/styles';
import { getMillisecondsBetween } from '../../utilities/time';
import { FluentIconNames } from '../common/fluent-icon-names';

interface CustomizationTaskDetailsContainerProps {
    groupHeaderProps?: IGroupHeaderProps;
    task?: CustomizationTask;
    customizationGroupUri: string;
}

interface CustomizationTaskDetailsProps extends CustomizationTaskDetailsContainerProps {
    taskLogs: SerializableMap<string>;
}

interface TaskIconProps {
    status?: CustomizationTaskStatus;
}

const messages = defineMessages({
    customizationTaskLogFileName: {
        id: 'CustomizationTaskDetails_CustomizationTaskLogFile_Name',
        defaultMessage: '{devBoxName}_{customizationGroupName}_{taskName}',
        description:
            'Name for the downloaded customization task log file. {devBoxName}, {customizationGroupName} and {taskName} should not be localized.',
    },
});

/**
 * Styles
 */

const useTimeStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'customizationTaskTimeText'),
        paddingRight: '2px',
    },
}));

const groupHeaderStylesFactory = (isCollapsed: boolean, hasParameters: boolean) =>
    makeStyles((theme) => ({
        expand: {
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            display: hasParameters ? '' : 'none',
        },
        expandIsCollapsed: {
            color: isCollapsed ? getSemanticColor(theme, 'expandIcon') : getSemanticColor(theme, 'activeExpandIcon'),
        },
        groupHeaderContainer: {
            backgroundColor: isCollapsed
                ? getSemanticColor(theme, 'collapsedCustomizationTaskBackground')
                : getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
            margin: isCollapsed ? '6px 0' : '6px 0 0 0',
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            padding: hasParameters ? '0' : '0 0 0 35px',
        },
    }));

const customizationTaskNameStyles = (isCollapsed: boolean) =>
    makeStyles({
        root: {
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
        },
    });

const useSuccessIconStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'successMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useErrorIconStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'errorMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useQueuedIconStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'queuedIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useSkippedIconStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'skippedIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useDownloadButtonStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'downloadButtonText'),
        selectors: {
            ':hover': {
                textDecoration: 'none',
            },
        },
    },
}));

const useDownloadIconStyles = makeStyles(() => ({
    root: {
        padding: '0 8px 0 0',
    },
}));

const customizationTaskTokens: IStackTokens = { childrenGap: 8 };

const TaskIcon: React.FC<TaskIconProps> = React.memo((props: TaskIconProps) => {
    const { status } = props;

    const successIconStyles = useSuccessIconStyles();
    const errorIconStyles = useErrorIconStyles();
    const queuedIconStyles = useQueuedIconStyles();
    const skippedIconStyles = useSkippedIconStyles();

    switch (status) {
        case CustomizationTaskStatus.Running:
            return <Spinner ariaLabel={'spinner'} size={SpinnerSize.small} />;
        case CustomizationTaskStatus.Succeeded:
            return <Icon iconName={FluentIconNames.Accept} styles={successIconStyles} />;
        case CustomizationTaskStatus.FailedValidation:
        case CustomizationTaskStatus.TimedOut:
        case CustomizationTaskStatus.Failed:
            return <Icon iconName={FluentIconNames.Cancel} styles={errorIconStyles} />;
        case CustomizationTaskStatus.Skipped:
            return <Icon iconName={FluentIconNames.Blocked} styles={skippedIconStyles} />;
        case CustomizationTaskStatus.NotStarted:
        default:
            return <Icon iconName={FluentIconNames.CircleRing} styles={queuedIconStyles} />;
    }
});

export const CustomizationTaskDetails: React.FC<CustomizationTaskDetailsProps> = React.memo(
    (props: CustomizationTaskDetailsProps) => {
        const { groupHeaderProps, task, customizationGroupUri, taskLogs } = props;
        const { group } = groupHeaderProps ?? {};
        const { status, endTime, startTime, logUri, parameters } = task ?? {};
        const { devBoxName, customizationGroupName } =
            getTokensFromCustomizationGroupDataPlaneUri(customizationGroupUri);

        // Intl hooks
        const { formatMessage } = useIntl();

        const hasParameters = React.useMemo(() => {
            if (parameters) {
                return Object.keys(parameters).length > 0;
            }

            return false;
        }, [parameters]);

        // Style hooks
        const timeStyles = useTimeStyles();
        const useGroupHeaderStyles = useDynamicMakeStyles(
            groupHeaderStylesFactory,
            group?.isCollapsed ?? true,
            hasParameters
        );
        const groupHeaderStyles = useGroupHeaderStyles();
        const useCustomizationTaskNameStyles = useDynamicMakeStyles(
            customizationTaskNameStyles,
            group?.isCollapsed ?? true
        );
        const taskNameStyles = useCustomizationTaskNameStyles();
        const downloadButtonStyles = useDownloadButtonStyles();
        const downloadIconStyles = useDownloadIconStyles();

        const timeTaken = React.useMemo(
            () => (!!endTime && !!startTime ? getMillisecondsBetween(startTime, endTime) / 1000 : 0),
            [startTime, endTime]
        );

        const taskLogUrl = React.useMemo(() => get(taskLogs, logUri), [taskLogs, logUri]);

        const getTaskLogFileName = React.useMemo(
            () =>
                formatMessage(messages.customizationTaskLogFileName, {
                    devBoxName,
                    customizationGroupName,
                    taskName: task?.name,
                }),
            [task, formatMessage]
        );

        const onRenderName = React.useCallback(
            (props?: IGroupHeaderProps): JSX.Element => {
                return (
                    <Stack tokens={customizationTaskTokens} grow horizontal verticalAlign="center">
                        <Stack.Item>
                            <TaskIcon status={status} />
                        </Stack.Item>
                        <Stack.Item styles={taskNameStyles} grow>
                            {props?.group?.name}
                        </Stack.Item>
                        {startTime && endTime && (
                            <Stack.Item styles={timeStyles}>
                                <FormattedNumber value={timeTaken} style="unit" unit="second" unitDisplay="narrow" />
                            </Stack.Item>
                        )}
                        {startTime && endTime && (
                            <Stack.Item>
                                <Link
                                    href={taskLogUrl}
                                    download={getTaskLogFileName}
                                    target="_blank"
                                    rel="noreferrer"
                                    styles={downloadButtonStyles}
                                >
                                    <Icon iconName={FluentIconNames.Download} styles={downloadIconStyles} />
                                </Link>
                            </Stack.Item>
                        )}
                    </Stack>
                );
            },
            [status, timeStyles, startTime, endTime, timeTaken, taskNameStyles]
        );

        return <GroupHeader onRenderTitle={onRenderName} styles={groupHeaderStyles} {...groupHeaderProps} />;
    }
);

export const CustomizationTaskDetailsContainer: React.FC<CustomizationTaskDetailsContainerProps> = (
    props: CustomizationTaskDetailsContainerProps
) => {
    const { task, customizationGroupUri, groupHeaderProps } = props;

    const taskLogs = useSelector(getCustomizationTaskLogs);

    return (
        <CustomizationTaskDetails
            taskLogs={taskLogs}
            task={task}
            customizationGroupUri={customizationGroupUri}
            groupHeaderProps={groupHeaderProps}
        />
    );
};

export default CustomizationTaskDetailsContainer;
