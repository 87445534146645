import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../../models/common';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import {
    listDevBoxActions,
    loadDevBoxActionsForDevBoxes,
    loadDevBoxActionsForDevBoxesError,
    loadDevBoxActionsForDevBoxesFailed,
    loadDevBoxActionsForDevBoxesSuccess,
} from '../../../actions/dev-box-action/dev-box-action-action-creators';
import { LoadDevBoxActionsForDevBoxesAction } from '../../../actions/dev-box-action/dev-box-action-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';
import { getIdsForDevBoxesRequiringDevBoxActions } from './selectors';

export function* loadDevBoxActionsForDevBoxesSaga(action: LoadDevBoxActionsForDevBoxesAction): SagaIterator {
    const startTime = new Date();

    try {
        const ids: string[] = yield select(getIdsForDevBoxesRequiringDevBoxActions);
        const responses: DataResponse[] = yield all(ids.map((id) => putAndAwait(listDevBoxActions({ id }))));

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadDevBoxActionsForDevBoxes, ...failures);
            yield put(loadDevBoxActionsForDevBoxesFailed({ failure }));
        } else {
            yield put(loadDevBoxActionsForDevBoxesSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadDevBoxActionsForDevBoxes,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadDevBoxActionsForDevBoxes);
        yield put(loadDevBoxActionsForDevBoxesError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadDevBoxActionsForDevBoxes,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadDevBoxActionsForDevBoxesListenerSaga(): SagaIterator {
    yield takeLeading(loadDevBoxActionsForDevBoxes, loadDevBoxActionsForDevBoxesSaga);
}
