import {
    HardwareProfileContract,
    HibernateSupportContract,
    ImageReferenceContract,
    LocalAdminStatusContract,
    PoolContract,
    PoolHealthStatusContract,
    PoolResourceContractProperties,
    StorageProfileContract,
} from '../data/contracts/pool';
import { SerializableMap } from '../types/serializable-map';
import { UnionMap } from '../types/union-map';
import { Resource, ResourceProvisioningState } from './resource-manager';

/**
 * Application models
 */

export type HardwareProfile = HardwareProfileContract;
export type HibernateSupport = HibernateSupportContract;

export const HibernateSupport: UnionMap<HibernateSupport> = {
    Disabled: 'Disabled',
    Enabled: 'Enabled',
    OsUnsupported: 'OsUnsupported',
};

export type ImageReference = ImageReferenceContract;
export type LocalAdminStatus = LocalAdminStatusContract;

export const LocalAdminStatus: UnionMap<LocalAdminStatus> = {
    Disabled: 'Disabled',
    Enabled: 'Enabled',
};

export type Pool = PoolContract;

export type PoolHealthStatus = PoolHealthStatusContract;

export const PoolHealthStatus: UnionMap<PoolHealthStatus> = {
    Healthy: 'Healthy',
    Pending: 'Pending',
    Unhealthy: 'Unhealthy',
    Unknown: 'Unknown',
    Warning: 'Warning',
};

export type PoolResource = Resource<PoolResourceProperties>;

export type PoolResourceProperties = Omit<PoolResourceContractProperties, 'provisioningState'> & {
    provisioningState: ResourceProvisioningState;
};

export type StorageProfile = StorageProfileContract;

/**
 * Map types
 */

export type PoolDataMap = SerializableMap<Pool>;
export type PoolDataPlaneIdMap = SerializableMap<string>;
export type ProjectToPoolMap = SerializableMap<PoolDataMap>;
export type PoolResourceMap = SerializableMap<PoolResource>;
export type ProjectToPoolResourceMap = SerializableMap<SerializableMap<PoolResource>>;

// TODO: add arguments to function a la serializable-map if they're ever needed
export const PoolDataMap = (): PoolDataMap => ({});
export const PoolDataPlaneIdMap = (): PoolDataPlaneIdMap => ({});
export const ProjectToPoolMap = (): ProjectToPoolMap => ({});
export const PoolResourceMap = (): PoolResourceMap => ({});
export const ProjectToPoolResourceMap = (): ProjectToPoolResourceMap => ({});

/**
 * Type guards
 */

export const isPoolResourceProperties = (value: unknown): value is PoolResourceProperties => {
    const properties = value as PoolResourceProperties;

    return (
        properties.machineDefinitionId !== undefined &&
        properties.networkSettingsId !== undefined &&
        properties.provisioningState !== undefined &&
        properties.sku !== undefined &&
        properties.sku.name !== undefined
    );
};
