import { SagaIterator } from 'redux-saga';
import { all, put } from 'redux-saga/effects';
import { Severity } from '../../../constants/telemetry';
import { ClientError } from '../../../models/common';
import { trackException } from '../../../utilities/telemetry/channel';
import {
    fatalError,
    initializeApplicationError,
    initializeApplicationFailed,
} from '../../actions/application/application-action-creators';
import { ActionCreator } from '../../actions/core-action-creators';
import {
    ErrorOrFailedPayload,
    ErrorPayload,
    FailedPayload,
    PayloadAction,
    isErrorPayload,
    isPayloadAction,
} from '../../actions/core-actions';
import { initializeHomeError, initializeHomeFailed } from '../../actions/sub-applications/home/home-action-creators';
import {
    initializeSingleDevCenterHomeError,
    loadResourcesForSingleDevCenterError,
    loadResourcesForSingleDevCenterFailed,
} from '../../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { takeEvery } from '../../effects/take';
import { getFailure } from '../../utilities/error-or-failed-action';

// Rather than requiring that a saga dispatch fatalError any time such an error occurs, we instead allow certain error
// actions to be designated as "always fatal." We then listen for any action of one of those types and dispatch a fatal
// error when they occur. The below list specifies which actions are considered "always fatal."
const fatalErrorPattern: ActionCreator<ErrorPayload>[] = [
    initializeApplicationError,
    initializeHomeError,
    // `InitializeSingleDevCenterHomeError` is fatal, `InitializeSingleDevCenterHomeFailed` is not - failures in this saga are expected (malformed user input)
    initializeSingleDevCenterHomeError,
    loadResourcesForSingleDevCenterError,
];

const fatalFailurePattern: ActionCreator<FailedPayload>[] = [
    initializeApplicationFailed,
    initializeHomeFailed,
    loadResourcesForSingleDevCenterFailed,
];

const logExceptionOnErrorAction = (action: PayloadAction<ErrorOrFailedPayload>): void => {
    let error: ClientError | undefined = undefined;

    // Handle payload-style actions
    if (isPayloadAction(action) && isErrorPayload(action.payload)) {
        error = action.payload.error;
    }

    if (error) {
        trackException(error, { severity: Severity.Critical });
    }
};

export function* fatalErrorSaga(action: PayloadAction<ErrorOrFailedPayload>): SagaIterator {
    const failure = getFailure(action);
    yield put(fatalError({ failure }));

    // Log telemetry if this happens to be an error-flavor action
    logExceptionOnErrorAction(action);
}

export function* fatalErrorListenerSaga(): SagaIterator {
    yield all([takeEvery(fatalErrorPattern, fatalErrorSaga), takeEvery(fatalFailurePattern, fatalErrorSaga)]);
}
