import { ListDevBoxOperationsResponse } from '../../../data/services/data-plane-api/dev-box-operation';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import { DevBoxOperationActionType, ListDevBoxOperationsSuccessPayload } from './dev-box-operation-actions';

/**
 * Action creators
 */

export const listDevBoxOperations = createActionCreator<IndexedPayload, ListDevBoxOperationsResponse>(
    DevBoxOperationActionType.ListDevBoxOperations,
    { async: true, startsActivity: true }
);

export const listDevBoxOperationsError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevBoxOperationActionType.ListDevBoxOperationsError
);

export const listDevBoxOperationsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    DevBoxOperationActionType.ListDevBoxOperationsFailed
);

export const listDevBoxOperationsSuccess = createActionCreator<ListDevBoxOperationsSuccessPayload>(
    DevBoxOperationActionType.ListDevBoxOperationsSuccess
);

export const loadDevBoxOperationsForDevBoxes = createActionCreatorWithoutPayload<AggregatedResult>(
    DevBoxOperationActionType.LoadDevBoxOperationsForDevBoxes,
    { async: true }
);

export const loadDevBoxOperationsForDevBoxesError = createActionCreator<ErrorPayload>(
    DevBoxOperationActionType.LoadDevBoxOperationsForDevBoxesError
);

export const loadDevBoxOperationsForDevBoxesFailed = createActionCreator<FailedPayload>(
    DevBoxOperationActionType.LoadDevBoxOperationsForDevBoxesFailed
);

export const loadDevBoxOperationsForDevBoxesSuccess = createActionCreatorWithoutPayload(
    DevBoxOperationActionType.LoadDevBoxOperationsForDevBoxesSuccess
);
