import { IStackTokens, Label, Panel, PanelType, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedDate, FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDetailsPanelStyles } from 'src/themes/styles/details-panel-styles';
import { useEnvironmentDetailsPanelContext } from '../../../hooks/context/panels';

export interface EnvironmentDetailsPanelProps {
    isOpen: boolean;
    name: string;
    devCenterName: string;
    projectName: string;
    catalogName: string;
    environmentDefinitionName: string;
    expirationDate?: Date;
    onDismiss: () => void;
}

const environmentDetailsPanelMessages = defineMessages({
    environmentDetailsPanelCloseButtonLabel: {
        id: 'EnvironmentDetails_Panel_Close_Label',
        defaultMessage: 'Close environment details panel',
        description: 'Aria label for the environment details panel close button label',
    },
    environmentDetailsPanelHeaderText: {
        id: 'EnvironmentDetails_Panel_Header_Text',
        defaultMessage: '{name} details',
        description: 'Text for the environment details panel. Do not localize {name}. Do not localize {name}.',
    },
});

const contentTokens: IStackTokens = {
    childrenGap: 31,
};

const itemTokens: IStackTokens = {
    childrenGap: 11,
};

/* END */

const EnvironmentDetailsPanelComponent: React.FC<EnvironmentDetailsPanelProps> = (
    props: EnvironmentDetailsPanelProps
) => {
    const {
        isOpen,
        onDismiss,
        name,
        devCenterName,
        projectName,
        catalogName,
        environmentDefinitionName,
        expirationDate,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const panelStyles = useDetailsPanelStyles();

    const headerText = React.useMemo(
        () => formatMessage(environmentDetailsPanelMessages.environmentDetailsPanelHeaderText, { name }),
        [name]
    );

    return (
        <Panel
            customWidth={'369px'}
            type={PanelType.custom}
            isOpen={isOpen}
            headerText={headerText}
            closeButtonAriaLabel={formatMessage(
                environmentDetailsPanelMessages.environmentDetailsPanelCloseButtonLabel
            )}
            onDismiss={onDismiss}
            isLightDismiss
            styles={panelStyles}
        >
            <Stack tokens={contentTokens}>
                {expirationDate && (
                    <Stack.Item>
                        <Stack tokens={itemTokens}>
                            <Stack.Item>
                                <Label>
                                    <FormattedMessage
                                        id="EnvironmentDetails_Panel_Expiration_Submenu_Title"
                                        defaultMessage="Expiration"
                                        description="Text for the environment details panel created on submenu title"
                                    />
                                </Label>
                            </Stack.Item>
                            <Stack.Item>
                                <FormattedDate
                                    value={expirationDate}
                                    year="numeric"
                                    month="long"
                                    day="numeric"
                                    hour="numeric"
                                    minute="numeric"
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <Stack tokens={itemTokens}>
                        <Stack.Item>
                            <Label>
                                <FormattedMessage
                                    id="EnvironmentDetails_Panel_Catalog_Submenu_Title"
                                    defaultMessage="Catalog"
                                    description="Text for the environment details panel catalog submenu title"
                                />
                            </Label>
                        </Stack.Item>
                        <Stack.Item>{catalogName}</Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={itemTokens}>
                        <Stack.Item>
                            <Label>
                                <FormattedMessage
                                    id="EnvironmentDetails_Panel_Definition_Submenu_Title"
                                    defaultMessage="Definition"
                                    description="Text for the environment details panel definition submenu title"
                                />
                            </Label>
                        </Stack.Item>
                        <Stack.Item>{environmentDefinitionName}</Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={itemTokens}>
                        <Stack.Item>
                            <Label>
                                <FormattedMessage
                                    id="EnvironmentDetails_Panel_DevCenter_Submenu_Title"
                                    defaultMessage="Dev center"
                                    description="Text for the environment details panel dev center submenu title"
                                />
                            </Label>
                        </Stack.Item>
                        <Stack.Item>{devCenterName}</Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={itemTokens}>
                        <Stack.Item>
                            <Label>
                                <FormattedMessage
                                    id="EnvironmentDetails_Panel_Project_Submenu_Title"
                                    defaultMessage="Project"
                                    description="Text for the environment details panel project submenu title"
                                />
                            </Label>
                        </Stack.Item>
                        <Stack.Item>{projectName}</Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Panel>
    );
};

const EnvironmentDetailsPanel: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useEnvironmentDetailsPanelContext();

    const onDismiss = React.useCallback(() => closePanel(), [closePanel]);

    return <EnvironmentDetailsPanelComponent {...properties} isOpen={isOpen} onDismiss={onDismiss} />;
};

export const EnvironmentDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { isOpen } = useEnvironmentDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <EnvironmentDetailsPanel />;
};

export default EnvironmentDetailsPanelContainer;
