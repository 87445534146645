import { AccountInfo } from '@azure/msal-browser';
import { Scopes } from '../../../constants/identity';
import { UnionValueMap } from '../../../types/union-map';
import { createInitializer } from '../../../utilities/initializer';
import { ActionName, ActionType, ErrorPayload, FailedPayload } from '../core-actions';
import {
    getAccessToken,
    initializeAuthenticationState,
    setActiveAccount,
    signIn,
    signOut,
    tryExpireSignIn,
} from './identity-action-creators';

/**
 * Action types
 */

type IdentityActionName =
    | ActionName<'GetAccessToken', 'Error' | 'Failed' | 'Redirecting' | 'Skipped' | 'Success'>
    | ActionName<'InitializeAuthenticationState', 'Error' | 'Success'>
    | 'SetActiveAccount'
    | ActionName<'SignIn', 'Error' | 'Failed' | 'Redirecting' | 'Success'>
    | ActionName<'SignOut', 'Error' | 'Redirecting' | 'Success'>
    | ActionName<'TryExpireSignIn', 'Complete' | 'Error'>;

export type IdentityActionType =
    | ActionType<'GET_ACCESS_TOKEN', '_ERROR' | '_FAILED' | '_REDIRECTING' | '_SKIPPED' | '_SUCCESS'>
    | ActionType<'INITIALIZE_AUTHENTICATION_STATE', '_ERROR' | '_SUCCESS'>
    | 'SET_ACTIVE_ACCOUNT'
    | ActionType<'SIGN_IN', '_ERROR' | '_FAILED' | '_REDIRECTING' | '_SUCCESS'>
    | ActionType<'SIGN_OUT', '_ERROR' | '_REDIRECTING' | '_SUCCESS'>
    | ActionType<'TRY_EXPIRE_SIGN_IN', '_COMPLETE' | '_ERROR'>;

export const IdentityActionType: UnionValueMap<IdentityActionName, IdentityActionType> = {
    GetAccessToken: 'GET_ACCESS_TOKEN',
    GetAccessTokenError: 'GET_ACCESS_TOKEN_ERROR',
    GetAccessTokenFailed: 'GET_ACCESS_TOKEN_FAILED',
    GetAccessTokenRedirecting: 'GET_ACCESS_TOKEN_REDIRECTING',
    GetAccessTokenSkipped: 'GET_ACCESS_TOKEN_SKIPPED',
    GetAccessTokenSuccess: 'GET_ACCESS_TOKEN_SUCCESS',
    InitializeAuthenticationState: 'INITIALIZE_AUTHENTICATION_STATE',
    InitializeAuthenticationStateError: 'INITIALIZE_AUTHENTICATION_STATE_ERROR',
    InitializeAuthenticationStateSuccess: 'INITIALIZE_AUTHENTICATION_STATE_SUCCESS',
    SetActiveAccount: 'SET_ACTIVE_ACCOUNT',
    SignIn: 'SIGN_IN',
    SignInError: 'SIGN_IN_ERROR',
    SignInFailed: 'SIGN_IN_FAILED',
    SignInRedirecting: 'SIGN_IN_REDIRECTING',
    SignInSuccess: 'SIGN_IN_SUCCESS',
    SignOut: 'SIGN_OUT',
    SignOutError: 'SIGN_OUT_ERROR',
    SignOutRedirecting: 'SIGN_OUT_REDIRECTING',
    SignOutSuccess: 'SIGN_OUT_SUCCESS',
    TryExpireSignIn: 'TRY_EXPIRE_SIGN_IN',
    TryExpireSignInComplete: 'TRY_EXPIRE_SIGN_IN_COMPLETE',
    TryExpireSignInError: 'TRY_EXPIRE_SIGN_IN_ERROR',
};

/**
 * Payloads
 */

type CoreGetAccessTokenPayload = { scopes: string[] };

export type GetAccessTokenPayload = CoreGetAccessTokenPayload & { onlyUseSilentRequests?: boolean };
export type GetAccessTokenErrorPayload = CoreGetAccessTokenPayload & ErrorPayload;
export type GetAccessTokenFailedPayload = CoreGetAccessTokenPayload & FailedPayload;
export type GetAccessTokenRedirectingPayload = CoreGetAccessTokenPayload;
export type GetAccessTokenSkippedPayload = CoreGetAccessTokenPayload & { reason: string };
export type GetAccessTokenSuccessPayload = CoreGetAccessTokenPayload;

export type InitializeAuthenticationStatePayload = { useSilentSso?: boolean };

export type SetActiveAccountPayload = { account: AccountInfo };

export type SignInPayload = { postSignInPath?: string };

/**
 * Initializers
 */

export const GetAccessTokenForAzureDevOpsPayload = createInitializer<GetAccessTokenPayload>({
    scopes: [Scopes.AzureDevOps],
});

export const GetAccessTokenForAzureResourceManagerPayload = createInitializer<GetAccessTokenPayload>({
    scopes: [Scopes.AzureResourceManager],
});

export const GetAccessTokenForDevCenterDataPlanePayload = createInitializer<GetAccessTokenPayload>({
    scopes: [Scopes.DevCenterDataPlane],
});

export const GetAccessTokenForGraphPayload = createInitializer<GetAccessTokenPayload>({
    scopes: [Scopes.Graph],
});

/**
 * Action shorthands
 */

export type GetAccessTokenAction = ReturnType<typeof getAccessToken>;
export type InitializeAuthenticationStateAction = ReturnType<typeof initializeAuthenticationState>;
export type SetActiveAccountAction = ReturnType<typeof setActiveAccount>;
export type SignInAction = ReturnType<typeof signIn>;
export type SignOutAction = ReturnType<typeof signOut>;
export type TryExpireSignInAction = ReturnType<typeof tryExpireSignIn>;
