import * as React from 'react';
import { Severity } from '../../../../constants/telemetry';
import { trackTrace } from '../../../../utilities/telemetry/channel';
import { StringDropdown } from '../../../common/form/dropdown/type-controls/string-dropdown';
import { ReadOnlyControl } from '../../../common/form/read-only-control';
import TextInput from '../../../common/form/text-input/text-input';
import {
    EnvironmentDefinitionArrayParameterViewModel,
    EnvironmentDefinitionObjectParameterViewModel,
    EnvironmentDefinitionStringParameterViewModel,
} from '../models';
import { ParameterTypeControlProps } from './models';

export interface StringParameterControlProps extends ParameterTypeControlProps<string> {
    parameter:
        | EnvironmentDefinitionStringParameterViewModel
        | EnvironmentDefinitionArrayParameterViewModel
        | EnvironmentDefinitionObjectParameterViewModel;
}

export const StringParameterControl: React.FC<StringParameterControlProps> = (props: StringParameterControlProps) => {
    const { value, parameter, onChange, ariaLabel, error, disabled } = props;
    const { allowed, required, readOnly, name } = parameter;

    const isDropdownControl = !!allowed;

    const onChangeCallback = React.useCallback(
        (value: string | undefined) => {
            if (value === undefined) {
                trackTrace(`Create environment ${typeof parameter} control onChange received undefined value`, {
                    severity: Severity.Warning,
                });
                return;
            }

            onChange(value);
        },
        [onChange]
    );

    if (readOnly) {
        return (
            <ReadOnlyControl label={name} value={value} required={required} ariaLabel={ariaLabel} disabled={disabled} />
        );
    }

    if (isDropdownControl) {
        return (
            <StringDropdown
                label={name}
                value={value}
                options={allowed}
                required={required}
                onChange={onChangeCallback}
                ariaLabel={ariaLabel}
                errorMessage={error}
                disabled={disabled}
            />
        );
    }

    return (
        <TextInput
            label={name}
            value={value}
            required={required}
            onChange={onChangeCallback}
            ariaLabel={ariaLabel}
            errorMessage={error}
            disabled={disabled}
        />
    );
};

export default StringParameterControl;
