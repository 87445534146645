import { FontSizes, FontWeights, Link, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import errorImage from '../content/images/error.svg';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { CenteredSimpleLayout } from '../layouts/centered-simple-layout';
import { getSessionID } from '../utilities/telemetry/channel';

interface ErrorPageProps {
    disableLocalizedContent?: boolean;
}

const messages = defineMessages({
    errorImageAlt: {
        id: 'ErrorPage_ErrorImage_Alt',
        defaultMessage: 'An error occurred',
        description: 'Alt text for "error" image',
    },
});

/**
 * Style section
 */

const useMessageContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: 344,
        paddingTop: 6,
    },
});

const useSessionIdStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: 344,
        paddingTop: 6,
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        maxWidth: 344,
        paddingTop: 6,
    },
});

/**
 * END style section
 */

// Note: NOT dispatching action to do this, as Redux may be unavailable at this point.
const onRefreshClicked = () => location.reload();

export const ErrorPageLocalizedComponent: React.FC = () => {
    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    const errorMessageValues = React.useMemo(
        () => ({
            Link: (chunks: string) => <Link onClick={onRefreshClicked}>{chunks}</Link>,
        }),
        [onRefreshClicked]
    );

    const sessionIdValues = React.useMemo(() => ({ sessionId: getSessionID() }), []);

    // Style hooks
    const messageContainerStyles = useMessageContainerStyles();
    const sessionIdStyles = useSessionIdStyles();
    const titleContainerStyles = useTitleContainerStyles();

    return (
        <CenteredPortalLayout contentPaddingBottom={40} contentPaddingTop={40} id="error" verticalFill>
            <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                <Stack.Item>
                    <img alt={formatMessage(messages.errorImageAlt)} src={errorImage} />
                </Stack.Item>

                <Stack.Item styles={titleContainerStyles}>
                    <FormattedMessage
                        id="ErrorPage_ErrorTitle_Text"
                        defaultMessage="Oops, something went wrong."
                        description="Text alerting the user that an error has occurred and they have to reload the page."
                    />
                </Stack.Item>

                <Stack.Item styles={messageContainerStyles}>
                    <FormattedMessage
                        id="ErrorPage_ErrorMessage_Text"
                        defaultMessage="Please <Link>refresh</Link>, or contact your administrator if this problem continues."
                        description="Message prompting the user to refresh the page. <Link> and </Link> should not be localized."
                        values={errorMessageValues}
                    />
                </Stack.Item>

                <Stack.Item styles={sessionIdStyles}>
                    <FormattedMessage
                        id="ErrorPage_SessionId_Text"
                        defaultMessage="Session ID: {sessionId}"
                        description="Message containing session ID for debugging purposes. Do not localize {sessionId}."
                        values={sessionIdValues}
                    />
                </Stack.Item>
            </Stack>
        </CenteredPortalLayout>
    );
};

export const ErrorPageNonLocalizedComponent: React.FC = () => {
    // Style hooks
    const messageContainerStyles = useMessageContainerStyles();
    const sessionIdStyles = useSessionIdStyles();
    const titleContainerStyles = useTitleContainerStyles();

    return (
        <CenteredSimpleLayout id="error-non-localized" verticalFill>
            <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                <Stack.Item>
                    <img alt="An error occurred" src={errorImage} />
                </Stack.Item>

                <Stack.Item styles={titleContainerStyles}>Oops, something went wrong.</Stack.Item>

                <Stack.Item styles={messageContainerStyles}>
                    Please <Link onClick={onRefreshClicked}>refresh</Link>, or contact your administrator if this
                    problem continues.
                </Stack.Item>

                <Stack.Item styles={sessionIdStyles}>Session ID: {getSessionID()}</Stack.Item>
            </Stack>
        </CenteredSimpleLayout>
    );
};

export const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
    const { disableLocalizedContent } = props;

    return disableLocalizedContent ? <ErrorPageNonLocalizedComponent /> : <ErrorPageLocalizedComponent />;
};

export default ErrorPage;
