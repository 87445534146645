import { createSelector } from '@reduxjs/toolkit';
import { getParentResourceId } from '../../../utilities/resource-manager/get-parent-resource-id';
import { keys } from '../../../utilities/serializable-map';
import { StoreStateSelector } from '../../selector/common';
import {
    getIsProjectAuthorizedForEnvironmentWrite,
    getPermissionsForProjects,
} from '../../selector/permission-selectors';
import { getEnabledProjectEnvironmentTypes } from '../../selector/project-environment-type-selectors';

/**
 * Application state selectors
 */

// NOTE: We only make permissions calls for project environment types that do not have environment read/write permissions at the project level.
// While technically the user could have a custom role on a project environment type that has environment create as a not-action,
// we are going to optimize for the vast majority of cases where permissions on the project indicate permissions on the project environment type.
// If the user has this not-action custom role, environment creation with that environment type will still fail, we just don't need to give up
// this performance enhancement for this very unlikely scenario.
export const getResourceIdsForProjectEnvironmentTypesRequiringPermissions: StoreStateSelector<string[]> =
    createSelector(
        [getEnabledProjectEnvironmentTypes, getPermissionsForProjects],
        (enabledProjectEnvironmentTypes, projectPermissions) =>
            keys(enabledProjectEnvironmentTypes).filter((id) => {
                const projectResourceId = getParentResourceId(id);
                return !getIsProjectAuthorizedForEnvironmentWrite(projectPermissions, projectResourceId);
            })
    );
