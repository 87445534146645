import { UnionMap, UnionValueMap } from '../../types/union-map';
import { MockScenarioConfiguration } from '../types';
import { DataPlaneApiScenarioName, DataPlaneApiScenarios } from './data-plane-api/data-plane-api-scenarios';
import { DiscoveryServiceScenarioName, DiscoveryServiceScenarios } from './discovery-service/list-projects-scenarios';
import { GraphScenarioName, GraphScenarios } from './graph/graph-scenarios';
import { IdentityScenarioName, IdentityScenarios } from './identity/identity-scenarios';
import { ResourceManagerScenarioName, ResourceManagerScenarios } from './resource-manager/resource-manager-scenarios';

export type ScenarioName =
    | DataPlaneApiScenarioName
    | GraphScenarioName
    | IdentityScenarioName
    | ResourceManagerScenarioName
    | DiscoveryServiceScenarioName;

export const ScenarioName: UnionMap<ScenarioName> = {
    ...DataPlaneApiScenarioName,
    ...GraphScenarioName,
    ...IdentityScenarioName,
    ...ResourceManagerScenarioName,
    ...DiscoveryServiceScenarioName,
};

export const Scenarios: UnionValueMap<ScenarioName, MockScenarioConfiguration> = {
    ...DataPlaneApiScenarios,
    ...GraphScenarios,
    ...IdentityScenarios,
    ...ResourceManagerScenarios,
    ...DiscoveryServiceScenarios,
};

export const isScenarioName = (scenarioName: string): scenarioName is ScenarioName => {
    return ScenarioName[scenarioName as ScenarioName] !== undefined;
};
