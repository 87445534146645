import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { CustomizationOperationName } from '../../constants/customization';
import { Entity } from '../../models/common';
import { CustomizationFailure } from '../../models/customization';
import {
    clearValidationResult,
    createCustomizationGroup,
    createCustomizationGroupAccepted,
    createCustomizationGroupError,
    createCustomizationGroupFailed,
    createCustomizationGroupSuccess,
    getCustomizationTaskLog,
    getCustomizationTaskLogError,
    getCustomizationTaskLogFailed,
    getCustomizationTaskLogSuccess,
    listCustomizationGroups,
    listCustomizationGroupsError,
    listCustomizationGroupsFailed,
    listCustomizationGroupsSuccess,
    listCustomizationTaskDefinitions,
    listCustomizationTaskDefinitionsError,
    listCustomizationTaskDefinitionsFailed,
    listCustomizationTaskDefinitionsSuccess,
    loadCustomizationGroups,
    loadCustomizationGroupsError,
    loadCustomizationGroupsFailed,
    loadCustomizationGroupsSuccess,
    trackCustomizationGroupProgress,
    validateCustomizationTasks,
    validateCustomizationTasksError,
    validateCustomizationTasksFailed,
    validateCustomizationTasksSuccess,
} from '../actions/customization/customization-action-creators';
import { stringAdapter } from '../adapters/common/string-adapter';
import {
    customizationFailureAdapter,
    customizationGroupAdapter,
    customizationTaskDefinitionAdapter,
} from '../adapters/customization-adapters';
import { CustomizationDataStore, CustomizationStatusStore, CustomizationStore } from '../store/customization-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const customizationReducer = combineReducers<CustomizationStore>({
    data: createReducer(CustomizationDataStore(), (builder) => {
        builder
            .addCase(listCustomizationGroupsSuccess, (store, action) => {
                const { result } = getPayload(action);

                customizationGroupAdapter.setMany(
                    store.customizationGroups,
                    result.map((data) => Entity(data.uri, data))
                );
            })
            .addCase(createCustomizationGroupSuccess, (store, action) => {
                const { id, result } = getPayload(action);

                customizationGroupAdapter.setOne(store.customizationGroups, Entity(id, result));
            })
            .addCase(createCustomizationGroupAccepted, (store, action) => {
                const { id, result } = getPayload(action);

                customizationGroupAdapter.setOne(store.customizationGroups, Entity(id, result));
            })
            .addCase(createCustomizationGroupError, (store, action) => {
                const { id, error } = getPayload(action);

                const customizationError: CustomizationFailure = {
                    operationName: CustomizationOperationName.Create,
                    failure: error,
                };

                customizationFailureAdapter.setOne(store.customizationFailures, Entity(id, customizationError));
            })
            .addCase(createCustomizationGroupFailed, (store, action) => {
                const { id, failure } = getPayload(action);

                const customizationError: CustomizationFailure = {
                    operationName: CustomizationOperationName.Create,
                    failure,
                };

                customizationFailureAdapter.setOne(store.customizationFailures, Entity(id, customizationError));
            })
            .addCase(listCustomizationTaskDefinitionsSuccess, (store, action) => {
                const { id, result } = getPayload(action);

                customizationTaskDefinitionAdapter.setOne(store.customizationTaskDefinitions, Entity(id, result));
            })
            .addCase(getCustomizationTaskLogSuccess, (store, action) => {
                const { id, result } = getPayload(action);

                stringAdapter.setOne(store.downloadCustomizationTaskLogUrls, Entity(id, result));
            })
            .addCase(trackCustomizationGroupProgress, (store, action) => {
                const { id, result } = getPayload(action);

                customizationGroupAdapter.setOne(store.customizationGroups, Entity(id, result));
            })
            .addCase(validateCustomizationTasksSuccess, (store, action) => {
                const { result } = getPayload(action);

                store.customizationValidationResult = result;
            })
            .addCase(clearValidationResult, (store) => {
                store.customizationValidationResult = undefined;
            });
    }),

    status: combineReducers<CustomizationStatusStore>({
        createCustomizationGroup: createStatusReducer({
            inProgress: createCustomizationGroup,
            error: createCustomizationGroupError,
            failed: createCustomizationGroupFailed,
            success: createCustomizationGroupSuccess,
        }),

        loadCustomizationGroups: createStatusReducer({
            inProgress: loadCustomizationGroups,
            error: loadCustomizationGroupsError,
            failed: loadCustomizationGroupsFailed,
            success: loadCustomizationGroupsSuccess,
        }),

        listCustomizationGroups: createIndexedStatusReducer({
            inProgress: listCustomizationGroups,
            error: listCustomizationGroupsError,
            failed: listCustomizationGroupsFailed,
            success: listCustomizationGroupsSuccess,
        }),

        listCustomizationTaskDefinitions: createStatusReducer({
            inProgress: listCustomizationTaskDefinitions,
            error: listCustomizationTaskDefinitionsError,
            failed: listCustomizationTaskDefinitionsFailed,
            success: listCustomizationTaskDefinitionsSuccess,
        }),

        validateCustomizationTasks: createStatusReducer({
            inProgress: validateCustomizationTasks,
            error: validateCustomizationTasksError,
            failed: validateCustomizationTasksFailed,
            success: validateCustomizationTasksSuccess,
            reset: clearValidationResult,
        }),

        getCustomizationTaskLog: createIndexedStatusReducer({
            inProgress: getCustomizationTaskLog,
            error: getCustomizationTaskLogError,
            failed: getCustomizationTaskLogFailed,
            success: getCustomizationTaskLogSuccess,
        }),

        trackCustomizationGroupProgress: createStatusReducer({
            inProgress: trackCustomizationGroupProgress,
        }),
    }),
});
