import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { signOut } from '../../../data/services/identity';
import { ClientError, FailureOperation } from '../../../models/common';
import Settings from '../../../settings/settings';
import {
    tryExpireSignIn,
    tryExpireSignInComplete,
    tryExpireSignInError,
} from '../../actions/identity/identity-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { takeLeading } from '../../effects/take';
import { getIsSignedIn } from '../../selector/identity-selectors';

export function* tryExpireSignInSaga(): SagaIterator {
    try {
        const isSignedIn = yield select(getIsSignedIn);

        if (isSignedIn) {
            const postLogoutRedirectUri = `https://${window.location.host}${Settings.SessionExpiredRedirectPath}${window.location.search}`;
            yield put(tryExpireSignInComplete());
            yield call(signOut, false, { postLogoutRedirectUri });
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.TryExpireSignIn);
        yield put(tryExpireSignInError({ error }));
    }
}

export function* tryExpireSignInListenerSaga(): SagaIterator {
    yield takeLeading(tryExpireSignIn, tryExpireSignInSaga);
}
