import { PayloadAction } from '@reduxjs/toolkit';
import { SerializableMap } from '../../types/serializable-map';
import { GroupablePayload, IndexedPayload, UngroupedPayload, isGroupedPayload } from '../actions/core-actions';
import { getPayload } from './payload-action';

export const getActionsInGroup = <TPayload>(
    action: PayloadAction<GroupablePayload<TPayload>>
): PayloadAction<TPayload>[] => {
    const { payload } = action;

    // HACK: way to avoid this cast?
    return (isGroupedPayload(payload) ? payload.actions : [action]) as PayloadAction<TPayload>[];
};

export const getActionsInGroupById = <TPayload extends GroupablePayload<IndexedPayload>>(
    action: PayloadAction<TPayload>
): SerializableMap<PayloadAction<UngroupedPayload<TPayload>>> => {
    const actions = getActionsInGroup(action);

    return SerializableMap(
        actions.map((action) => {
            const { id } = getPayload(action);
            return [id, action] as [string, PayloadAction<UngroupedPayload<TPayload>>];
        })
    );
};
