import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
    isClientError,
} from '../../../../models/common';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import {
    loadAddDevBoxFormContent,
    loadAddEnvironmentFormContent,
    loadBackgroundResourcesForHome,
    loadBackgroundResourcesForHomeError,
    loadBackgroundResourcesForHomeFailed,
    loadBackgroundResourcesForHomeSuccess,
    loadDevBoxCardContent,
    loadEnvironmentCardContent,
    loadSecondaryDevBoxCardContent,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadBackgroundResourcesForHomeAction } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';
import { cleanUpDiscovery } from './clean-up-discovery';

// Note: this sub-operation pattern allows us to continue with form content load despite unhandled errors.
function* loadCardContentSaga(): SagaIterator<AggregatedResult | ClientError> {
    try {
        const results: AggregatedResult[] = yield all([
            putAndAwait(loadDevBoxCardContent()),
            putAndAwait(loadEnvironmentCardContent()),
        ]);

        const secondaryResult = yield putAndAwait(loadSecondaryDevBoxCardContent());

        results.push(secondaryResult);

        return combineResults(results);
    } catch (err) {
        return yield createSagaError(err, FailureOperation.LoadBackgroundResourcesForHome);
    }
}

function* loadFormContentSaga(): SagaIterator<AggregatedResult | ClientError> {
    try {
        const results: AggregatedResult[] = yield all([
            putAndAwait(loadAddDevBoxFormContent()),
            putAndAwait(loadAddEnvironmentFormContent()),
        ]);

        return combineResults(results);
    } catch (err) {
        return yield createSagaError(err, FailureOperation.LoadBackgroundResourcesForHome);
    }
}

export function* loadBackgroundResourcesForHomeSaga(action: LoadBackgroundResourcesForHomeAction): SagaIterator {
    const startTime = new Date();

    try {
        // Discover data for card content
        const cardContentResult: AggregatedResult | ClientError = yield call(loadCardContentSaga);

        // Discover data for create forms
        const formContentResult: AggregatedResult | ClientError = yield call(loadFormContentSaga);

        // Perform all post-discovery operations
        yield call(cleanUpDiscovery);

        // If we received runtime errors from either phase, issue a failed action immediately
        if (isClientError(cardContentResult)) {
            yield put(loadBackgroundResourcesForHomeError({ error: cardContentResult }));
            yield rejectAction(action, cardContentResult);

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.LoadBackgroundResourcesForHome,
                startTime,
                AsyncOutcome.Error,
                { errorCodes: [cardContentResult.code] }
            );

            return;
        }

        if (isClientError(formContentResult)) {
            yield put(loadBackgroundResourcesForHomeError({ error: formContentResult }));
            yield rejectAction(action, formContentResult);

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.LoadBackgroundResourcesForHome,
                startTime,
                AsyncOutcome.Error,
                { errorCodes: [formContentResult.code] }
            );

            return;
        }

        const completeResult = combineResults([cardContentResult, formContentResult]);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadBackgroundResourcesForHome, ...failures);
            yield put(loadBackgroundResourcesForHomeFailed({ failure }));
        } else {
            yield put(loadBackgroundResourcesForHomeSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadBackgroundResourcesForHome,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadBackgroundResourcesForHome);
        yield put(loadBackgroundResourcesForHomeError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadBackgroundResourcesForHome,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadBackgroundResourcesForHomeListenerSaga(): SagaIterator {
    yield takeLeading(loadBackgroundResourcesForHome, loadBackgroundResourcesForHomeSaga);
}
