import { UnionValueMap } from '../../types/union-map';
import {
    ScreenSize,
    ScreenSizeAboveExclusive,
    ScreenSizeAboveInclusive,
    ScreenSizeBelowExclusive,
    ScreenSizeBelowInclusive,
    ScreenSizeBetween,
    ScreenSizeMediaQueries,
} from './responsive';

export type CssSelector = `ScreenSize${
    | ScreenSize
    | ScreenSizeAboveExclusive
    | ScreenSizeAboveInclusive
    | ScreenSizeBelowExclusive
    | ScreenSizeBelowInclusive
    | ScreenSizeBetween}`;

export const CssSelector: UnionValueMap<CssSelector, string> = {
    // Exact screen sizes
    ScreenSizeLarge: ScreenSizeMediaQueries.Large,
    ScreenSizeMedium: ScreenSizeMediaQueries.Medium,
    ScreenSizeSmall: ScreenSizeMediaQueries.Small,
    ScreenSizeXLarge: ScreenSizeMediaQueries.XLarge,
    ScreenSizeXSmall: ScreenSizeMediaQueries.XSmall,
    ScreenSizeXXLarge: ScreenSizeMediaQueries.XXLarge,

    // Screen sizes above (exclusive)
    ScreenSizeAboveLarge: ScreenSizeMediaQueries.AboveLarge,
    ScreenSizeAboveMedium: ScreenSizeMediaQueries.AboveMedium,
    ScreenSizeAboveSmall: ScreenSizeMediaQueries.AboveSmall,
    ScreenSizeAboveXLarge: ScreenSizeMediaQueries.AboveXLarge,
    ScreenSizeAboveXSmall: ScreenSizeMediaQueries.AboveXSmall,

    // Screen sizes below (exclusive)
    ScreenSizeBelowLarge: ScreenSizeMediaQueries.BelowLarge,
    ScreenSizeBelowMedium: ScreenSizeMediaQueries.BelowMedium,
    ScreenSizeBelowSmall: ScreenSizeMediaQueries.BelowSmall,
    ScreenSizeBelowXLarge: ScreenSizeMediaQueries.BelowXLarge,
    ScreenSizeBelowXXLarge: ScreenSizeMediaQueries.BelowXXLarge,

    // Screen sizes above (inclusive)
    ScreenSizeLargeAndAbove: ScreenSizeMediaQueries.LargeAndAbove,
    ScreenSizeMediumAndAbove: ScreenSizeMediaQueries.MediumAndAbove,
    ScreenSizeSmallAndAbove: ScreenSizeMediaQueries.SmallAndAbove,
    ScreenSizeXLargeAndAbove: ScreenSizeMediaQueries.XLargeAndAbove,
    ScreenSizeXXLargeAndAbove: ScreenSizeMediaQueries.XXLargeAndAbove,

    // Screen sizes below (inclusive)
    ScreenSizeLargeAndBelow: ScreenSizeMediaQueries.LargeAndBelow,
    ScreenSizeMediumAndBelow: ScreenSizeMediaQueries.MediumAndBelow,
    ScreenSizeSmallAndBelow: ScreenSizeMediaQueries.SmallAndBelow,
    ScreenSizeXLargeAndBelow: ScreenSizeMediaQueries.XLargeAndBelow,
    ScreenSizeXSmallAndBelow: ScreenSizeMediaQueries.XSmallAndBelow,

    // Screen sizes between
    ScreenSizeBetweenLargeAndXLarge: ScreenSizeMediaQueries.BetweenLargeAndXLarge,
    ScreenSizeBetweenMediumAndLarge: ScreenSizeMediaQueries.BetweenMediumAndLarge,
    ScreenSizeBetweenMediumAndXLarge: ScreenSizeMediaQueries.BetweenMediumAndXLarge,
    ScreenSizeBetweenSmallAndLarge: ScreenSizeMediaQueries.BetweenSmallAndLarge,
    ScreenSizeBetweenSmallAndMedium: ScreenSizeMediaQueries.BetweenSmallAndMedium,
    ScreenSizeBetweenSmallAndXLarge: ScreenSizeMediaQueries.BetweenSmallAndXLarge,
};
