import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TelemetryTypeName } from '../constants/telemetry';
import { UnionMap } from '../types/union-map';

/**
 * Application models
 */

export type SanitizedTelemetryItem = Omit<ITelemetryItem, 'baseData' | 'data' | 'tags'> &
    Required<Pick<ITelemetryItem, 'baseData' | 'data' | 'tags'>>;

export type TelemetryFilteringAction = 'Allow' | 'Block' | 'Count' | 'Scrub';

export const TelemetryFilteringAction: UnionMap<TelemetryFilteringAction> = {
    Allow: 'Allow',
    Block: 'Block',
    Count: 'Count',
    Scrub: 'Scrub',
};

export interface TelemetryFilteringActionAndTarget {
    action: TelemetryFilteringAction;
    target: TelemetryFilteringActionTarget;
}

export type TelemetryFilteringActionSet = {
    [name: string]: TelemetryFilteringAction | TelemetryFilteringActionAndTarget | TelemetryFilteringActionSet;
};

export type TelemetryFilteringActionTargetForScrub = 'UserIdInPath';

export const TelemetryFilteringActionTargetForScrub: UnionMap<TelemetryFilteringActionTargetForScrub> = {
    UserIdInPath: 'UserIdInPath',
};

export type TelemetryFilteringActionTarget = TelemetryFilteringActionTargetForScrub;

export const TelemetryFilteringActionTarget: UnionMap<TelemetryFilteringActionTarget> = {
    ...TelemetryFilteringActionTargetForScrub,
};

export interface TelemetryFilteringRule {
    baseData?: TelemetryFilteringActionSet;
    data?: TelemetryFilteringActionSet;
    name?: string | string[] | RegExp;
}

export interface ReduxActionTelemetryFilteringRule extends TelemetryFilteringRule {
    reduxActionType?: string | string[] | RegExp;
}

export type TelemetryFilteringRuleSet = {
    all?: TelemetryFilteringRule[];
    event?: ReduxActionTelemetryFilteringRule[];
} & {
    [key in Uncapitalize<Exclude<TelemetryTypeName, 'Event'>>]?: TelemetryFilteringRule[];
};

export type TelemetryMeasurements = { [name: string]: number };
export type TelemetryProperties = { [name: string]: unknown };

/**
 * Type guards
 */

export const isTelemetryFilteringAction = (value: unknown): value is TelemetryFilteringAction =>
    value === TelemetryFilteringAction.Allow ||
    value === TelemetryFilteringAction.Block ||
    value === TelemetryFilteringAction.Count ||
    value === TelemetryFilteringAction.Scrub;

export const isTelemetryFilteringActionAndTarget = (
    value: TelemetryFilteringAction | TelemetryFilteringActionAndTarget | TelemetryFilteringActionSet
): value is TelemetryFilteringActionAndTarget => {
    const valueAsActionAndTarget = value as TelemetryFilteringActionAndTarget;

    return (
        isTelemetryFilteringAction(valueAsActionAndTarget.action) &&
        isTelemetryFilteringActionTarget(valueAsActionAndTarget.target)
    );
};

export const isTelemetryFilteringActionTarget = (value: unknown): value is TelemetryFilteringActionTarget =>
    value === TelemetryFilteringActionTarget.UserIdInPath;

export const isTelemetryFilteringActionTargetForScrub = (
    value: TelemetryFilteringActionTarget
): value is TelemetryFilteringActionTargetForScrub => value === TelemetryFilteringActionTargetForScrub.UserIdInPath;
