import { Separator as FluentSeparator, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { getSemanticColor } from '../../../utilities/styles';

/**
 * Styles
 */

const useSeparatorStyles = makeStyles((theme) => ({
    root: {
        maxHeight: 1,
        selectors: {
            '::before': {
                background: getSemanticColor(theme, 'separator'),
            },
        },
    },
}));

/**
 * END Styles
 */

export const Separator: React.FC = () => {
    // Style hooks
    const separatorStyles = useSeparatorStyles();

    return <FluentSeparator styles={separatorStyles} />;
};

export default Separator;
