import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { getRemoteConnection, loadRemoteConnectionsForDevBoxes } from './remote-connection-action-creators';

/**
 * Action types
 */

type RemoteConnectionActionName =
    | ActionName<'GetRemoteConnection', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadRemoteConnectionsForDevBoxes', 'Error' | 'Failed' | 'Success'>;

export type RemoteConnectionActionType =
    | ActionType<'GET_REMOTE_CONNECTION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_REMOTE_CONNECTIONS_FOR_DEV_BOXES', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const RemoteConnectionActionType: UnionValueMap<RemoteConnectionActionName, RemoteConnectionActionType> = {
    GetRemoteConnection: 'GET_REMOTE_CONNECTION',
    GetRemoteConnectionError: 'GET_REMOTE_CONNECTION_ERROR',
    GetRemoteConnectionFailed: 'GET_REMOTE_CONNECTION_FAILED',
    GetRemoteConnectionSuccess: 'GET_REMOTE_CONNECTION_SUCCESS',
    LoadRemoteConnectionsForDevBoxes: 'LOAD_REMOTE_CONNECTIONS_FOR_DEV_BOXES',
    LoadRemoteConnectionsForDevBoxesError: 'LOAD_REMOTE_CONNECTIONS_FOR_DEV_BOXES_ERROR',
    LoadRemoteConnectionsForDevBoxesFailed: 'LOAD_REMOTE_CONNECTIONS_FOR_DEV_BOXES_FAILED',
    LoadRemoteConnectionsForDevBoxesSuccess: 'LOAD_REMOTE_CONNECTIONS_FOR_DEV_BOXES_SUCCESS',
};

/**
 * Action shorthands
 */

export type GetRemoteConnectionAction = ReturnType<typeof getRemoteConnection>;
export type LoadRemoteConnectionsForDevBoxesAction = ReturnType<typeof loadRemoteConnectionsForDevBoxes>;
