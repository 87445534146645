import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import getOrganizationEmptyOnce from './get-organization-empty-once.json';
import getOrganizationEmpty from './get-organization-empty.json';
import getOrganizationGrabBag from './get-organization-grab-bag.json';

export type GraphOrganizationScenarioName =
    | 'GetOrganizationEmpty'
    | 'GetOrganizationEmptyOnce'
    | 'GetOrganizationGrabBag';

export const GraphOrganizationScenarioName: UnionMap<GraphOrganizationScenarioName> = {
    GetOrganizationEmpty: 'GetOrganizationEmpty',
    GetOrganizationEmptyOnce: 'GetOrganizationEmptyOnce',
    GetOrganizationGrabBag: 'GetOrganizationGrabBag',
};

export const GraphOrganizationScenarios: UnionValueMap<GraphOrganizationScenarioName, MockScenarioConfiguration> = {
    GetOrganizationEmpty: getOrganizationEmpty as MockScenarioConfiguration,
    GetOrganizationEmptyOnce: getOrganizationEmptyOnce as MockScenarioConfiguration,
    GetOrganizationGrabBag: getOrganizationGrabBag as MockScenarioConfiguration,
};
