import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { SearchParameter } from '../../../../constants/app';
import { ClientError, FailureOperation } from '../../../../models/common';
import { replaceCurrentLocation, setSearchParameterOnCurrentLocation } from '../../../../utilities/url';
import {
    loadResourcesForSingleDevCenter,
    setSingleDevCenterUri,
    setSingleDevCenterUriFromInput,
    setSingleDevCenterUriFromInputError,
} from '../../../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { SetSingleDevCenterUriAction } from '../../../actions/sub-applications/single-dev-center/single-dev-center-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { takeLeading } from '../../../effects/take';

export function* setSingleDevCenterUriFromInputSaga(action: SetSingleDevCenterUriAction): SagaIterator {
    const { payload, meta } = action;
    const { devCenterUri } = payload;

    try {
        // Set uri on store
        yield put(setSingleDevCenterUri({ devCenterUri }, meta));

        // Set uri on query param
        const newUrl: URL = yield call(setSearchParameterOnCurrentLocation, SearchParameter.DevCenterUri, devCenterUri);
        yield call(replaceCurrentLocation, newUrl);

        // Load resources
        yield put(loadResourcesForSingleDevCenter(meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SetSingleDevCenterUri);
        yield put(setSingleDevCenterUriFromInputError({ error }, meta));
    }
}

export function* setSingleDevCenterUriFromInputListenerSaga(): SagaIterator {
    yield takeLeading(setSingleDevCenterUriFromInput, setSingleDevCenterUriFromInputSaga);
}
