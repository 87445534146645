import { isBoolean } from '@microsoft/applicationinsights-core-js';
import { ClientError } from '../models/common';
import { ThemeMode } from '../themes/themes';
import { createInitializer } from '../utilities/initializer';
import { isObject } from '../utilities/object';
import { isString } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { UnionMap } from './union-map';

export type RemoteAppOption = 'useWindowsApp' | 'useAvd';

export const RemoteAppOption: UnionMap<RemoteAppOption> = {
    useWindowsApp: 'useWindowsApp',
    useAvd: 'useAvd',
};

export type UserSettings = {
    themeMode: ThemeMode;
    useMultiMonitor: boolean;
    preferredRemoteApp?: RemoteAppOption;
};

export const UserSettingsName: UnionMap<keyof UserSettings> = {
    themeMode: 'themeMode',
    useMultiMonitor: 'useMultiMonitor',
    preferredRemoteApp: 'preferredRemoteApp',
};

export const isUserSettings = (obj: UserSettings): obj is UserSettings =>
    isObject(obj) && isString(obj.themeMode) && isBoolean(obj.useMultiMonitor) && isString(obj.preferredRemoteApp);

export const convertUserSettingsToString = (maybeUserSettings: UserSettings): string => {
    if (!isUserSettings(maybeUserSettings)) {
        throw new ClientError(
            'User settings could not be converted to string. Because they are not user settings type'
        );
    }

    return JSON.stringify(maybeUserSettings);
};

export const convertStringToUserSettings = (settings: string): UserSettings => {
    const maybeUserSettings = JSON.parse(settings);

    if (!isUserSettings(maybeUserSettings)) {
        throw new ClientError('String could not be parsed as user settings.');
    }

    return maybeUserSettings;
};

export const tryConvertStringToUserSettings = tryOrDefault(convertStringToUserSettings);

export const UserSettings = createInitializer<UserSettings>({
    themeMode: ThemeMode.DarkMode,
    useMultiMonitor: false,
    preferredRemoteApp: RemoteAppOption.useWindowsApp,
});
