import {
    AggregatedFailure,
    AggregatedPartialSuccess,
    AggregatedResult,
    AggregatedSuccess,
    DataResponse,
    FailureResponse,
    isAggregatedSuccess,
    isDataResponse,
} from '../models/common';
import { AsyncOutcome } from '../redux/store/common-state';

export const combineResults = (
    results: (AggregatedResult | DataResponse)[],
    failureResponsePredicate?: <TData>(response: DataResponse<TData>) => response is FailureResponse
): AggregatedResult =>
    results.reduce<AggregatedResult>((previous, current, currentIndex) => {
        // Normalize current to AggregatedResult
        const currentResult = isDataResponse(current) ? AggregatedResult(current, failureResponsePredicate) : current;

        // HACK: don't use actual initial value for reduce. We're pigeon-holed into the initial value form due to type
        // defs for reduce. Instead, just return current result.
        if (currentIndex === 0) {
            return currentResult;
        }

        const { outcome: previousOutcome } = previous;
        const { outcome: currentOutcome } = currentResult;
        const previousFailures = isAggregatedSuccess(previous) ? [] : previous.failures;
        const currentFailures = isAggregatedSuccess(currentResult) ? [] : currentResult.failures;

        if (previousOutcome === AsyncOutcome.Failed && currentOutcome === AsyncOutcome.Failed) {
            return AggregatedFailure(...previousFailures, ...currentFailures);
        }

        if (previousOutcome === AsyncOutcome.Success && currentOutcome === AsyncOutcome.Success) {
            return AggregatedSuccess();
        }

        return AggregatedPartialSuccess(...previousFailures, ...currentFailures);
    }, AggregatedSuccess());

export const getErrorCodes = (
    result: AggregatedFailure | AggregatedPartialSuccess | FailureResponse | FailureResponse[]
): string[] => {
    // If is array: must be array of FailureResponse
    if (Array.isArray(result)) {
        return result.map((response) => response.code);
    }

    // If is DataResponse: must be FailureResponse
    if (isDataResponse(result)) {
        return [result.code];
    }

    // Otherwise: use codes field
    return result.codes;
};
