import { ListEnvironmentOperationsResponse } from '../../../data/services/data-plane-api/environment-operation';
import { createActionCreator } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    EnvironmentOperationActionType,
    ListEnvironmentOperationsSuccessPayload,
} from './environment-operation-actions';

/**
 * Action creators
 */

export const listEnvironmentOperations = createActionCreator<IndexedPayload, ListEnvironmentOperationsResponse>(
    EnvironmentOperationActionType.ListEnvironmentOperations,
    { async: true, startsActivity: true }
);

export const listEnvironmentOperationsError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsError
);

export const listEnvironmentOperationsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsFailed
);

export const listEnvironmentOperationsSuccess = createActionCreator<ListEnvironmentOperationsSuccessPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsSuccess
);
