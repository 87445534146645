import {
    CustomizationTask as GeneratedCustomizationTaskBeta,
    CustomizationTaskListValidationError as GeneratedCustomizationTaskListValidationErrorBeta,
    CustomizationTaskListValidationResult as GeneratedCustomizationTaskListValidationResultBeta,
    CustomizationTaskStatus as GeneratedCustomizationTaskStatusBeta,
    ProjectsValidateCustomizationTasksResponse as GeneratedProjectsValidateCustomizationTasksResponseBeta,
    KnownCustomizationTaskExecutionAccount,
} from 'devcenter-internal-beta';
import {
    CustomizationGroup as GeneratedCustomizationGroupStable,
    CustomizationGroupStatus as GeneratedCustomizationGroupStatusStable,
    CustomizationTaskDefinitionParameter as GeneratedCustomizationTaskDefinitionParameterStable,
    CustomizationTaskDefinitionParameterType as GeneratedCustomizationTaskDefinitionParameterTypeStable,
    CustomizationTaskDefinition as GeneratedCustomizationTaskDefinitionStable,
    CustomizationTaskList as GeneratedCustomizationTaskListStable,
    CustomizationTaskListValidationStatus as GeneratedCustomizationTaskListValidationStatusStable,
    CustomizationTask as GeneratedCustomizationTaskStable,
    CustomizationTaskStatus as GeneratedCustomizationTaskStatusStable,
} from 'devcenter-internal-stable';
import { RequiredProperty } from '../../types/required-property';
import { UnionAndPartialIntersection } from '../../types/union-and-partial-intersection';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

// We'll use this contract for any response back from the API representing a customization task definition
// (For now, this only specifies `uri`, but other property names can be added if we're certain they'll be defined)
export type CustomizationTaskDefinitionContract = RequiredProperty<GeneratedCustomizationTaskDefinitionStable, 'uri'>;

export type CustomizationTaskDefinitionParameterContract = GeneratedCustomizationTaskDefinitionParameterStable;
export type CustomizationTaskDefinitionParameterTypeContract = GeneratedCustomizationTaskDefinitionParameterTypeStable;

export const CustomizationTaskDefinitionParameterTypeContract: UnionMap<CustomizationTaskDefinitionParameterTypeContract> =
    {
        string: 'string',
        number: 'number',
        boolean: 'boolean',
    };

// Note: making a distinction here between request and response contracts, which are treated as the same by code gen.
//      - CustomizationGroupContract: response contract, in which readonly props (e.g. name, uri) are always available
//      - PutCustomizationGroupBodyContract: PUT request contract, in which readonly props aren't required
export type CustomizationGroupContract = Omit<
    RequiredProperty<GeneratedCustomizationGroupStable, 'uri' | 'name'>,
    'tasks'
> & { tasks?: CustomizationTaskContract[] };

export type PutCustomizationGroupBodyContract = Omit<GeneratedCustomizationGroupStable, 'tasks'> & {
    tasks?: PutCustomizationTaskBodyContract[];
};

// Note:CustomizationGroupContractFromDataPlane is solely for compatibility purposes b/w 2023-10-01-preview and
// 2024-05-01-preview. It can be removed in forthcoming versions.
export type CustomizationGroupContractFromDataPlane = Omit<GeneratedCustomizationGroupStable, 'tasks'> & {
    tasks?: CustomizationTaskContractFromDataPlane[];
};

export type CustomizationGroupStatusContract = GeneratedCustomizationGroupStatusStable;

export const CustomizationGroupStatusContract: UnionMap<CustomizationGroupStatusContract> = {
    NotStarted: 'NotStarted',
    Running: 'Running',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    ValidationFailed: 'ValidationFailed',
};

// Note: CustomizationTaskContractFromDataPlane is solely for compatibility purposes b/w 2023-10-01-preview and
// 2024-05-01-preview. It can be removed in forthcoming versions.
export type CustomizationTaskContractFromDataPlane = GeneratedCustomizationTaskBeta;

// Note: making a distinction here between request and response contracts, which are treated as the same by code gen.
//      - CustomizationTaskContract: response contract, in which readonly props (e.g. logUri, id) are always available
//      - PutCustomizationTaskBodyContract: PUT request contract, in which readonly props aren't required
export type CustomizationTaskContract = RequiredProperty<CustomizationTaskContractFromDataPlane, 'logUri' | 'id'>;
export type PutCustomizationTaskBodyContract = UnionAndPartialIntersection<
    Pick<GeneratedCustomizationTaskStable, 'displayName' | 'name' | 'parameters'>,
    Pick<GeneratedCustomizationTaskBeta, 'displayName' | 'name' | 'parameters' | 'runAs'>
>;

export type StableCustomizationTaskStatusContract = GeneratedCustomizationTaskStatusStable;
export type BetaCustomizationTaskStatusContract = GeneratedCustomizationTaskStatusBeta;

export type CustomizationTaskStatusContract =
    | BetaCustomizationTaskStatusContract
    | StableCustomizationTaskStatusContract;

export const CustomizationTaskStatusContract: UnionMap<CustomizationTaskStatusContract> = {
    NotStarted: 'NotStarted',
    Running: 'Running',
    Succeeded: 'Succeeded',
    FailedValidation: 'FailedValidation',
    Skipped: 'Skipped',
    TimedOut: 'TimedOut',
    Failed: 'Failed',
    WaitingForUserInputUac: 'WaitingForUserInputUac',
    WaitingForUserSession: 'WaitingForUserSession',
};

export type CustomizationTaskListContract = Omit<GeneratedCustomizationTaskListStable, 'tasks'> & {
    tasks: PutCustomizationTaskBodyContract[];
};

// Note: CustomizationTaskListValidationOperationResultContractFromDataPlane is solely for compatibility purposes b/w
// 2023-10-01-preview and 2024-05-01-preview. It can be removed in forthcoming versions.
export type CustomizationTaskListValidationOperationResultContractFromDataPlane =
    GeneratedProjectsValidateCustomizationTasksResponseBeta;

export type CustomizationTaskListValidationOperationResultContract = RequiredProperty<
    GeneratedProjectsValidateCustomizationTasksResponseBeta,
    'operationLocation'
>;

export type CustomizationTaskListValidationResultContract = GeneratedCustomizationTaskListValidationResultBeta;
export type CustomizationTaskListValidationStatusContract = GeneratedCustomizationTaskListValidationStatusStable;

export const CustomizationTaskListValidationStatusContract: UnionMap<CustomizationTaskListValidationStatusContract> = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
};

export type CustomizationTaskListValidationErrorContract = GeneratedCustomizationTaskListValidationErrorBeta;

export type KnownCustomizationTaskExecutionAccountContract = KnownCustomizationTaskExecutionAccount;

export const KnownCustomizationTaskExecutionAccountContract: UnionMap<KnownCustomizationTaskExecutionAccountContract> =
    {
        System: KnownCustomizationTaskExecutionAccount.System,
        User: KnownCustomizationTaskExecutionAccount.User,
    };

/**
 * Type guards
 */

export const isBetaCustomizationTaskStatus = (
    status: CustomizationTaskStatusContract
): status is BetaCustomizationTaskStatusContract =>
    status === CustomizationTaskStatusContract.Failed ||
    status === CustomizationTaskStatusContract.FailedValidation ||
    status === CustomizationTaskStatusContract.NotStarted ||
    status === CustomizationTaskStatusContract.Running ||
    status === CustomizationTaskStatusContract.Succeeded ||
    status === CustomizationTaskStatusContract.Skipped ||
    status === CustomizationTaskStatusContract.TimedOut ||
    status === CustomizationTaskStatusContract.WaitingForUserInputUac ||
    status === CustomizationTaskStatusContract.WaitingForUserSession;

export const isStableCustomizationTaskStatus = (
    status: CustomizationTaskStatusContract
): status is StableCustomizationTaskStatusContract =>
    status === CustomizationTaskStatusContract.Failed ||
    status === CustomizationTaskStatusContract.FailedValidation ||
    status === CustomizationTaskStatusContract.NotStarted ||
    status === CustomizationTaskStatusContract.Running ||
    status === CustomizationTaskStatusContract.Succeeded ||
    status === CustomizationTaskStatusContract.Skipped ||
    status === CustomizationTaskStatusContract.TimedOut;
