import * as React from 'react';
import { FilterContext } from '../../../types/contexts';
import { getProjectFilterFromCurrentLocation, updateProjectFilterParameter } from '../../../utilities/filters';
import { createFilterContextProvider } from '../filters-context-provider';

export const ProjectFilterContext = React.createContext<FilterContext<string>>({
    value: undefined,
    onChange: (_value: string | undefined) => void {},
});

export const ProjectFilterContextProvider = createFilterContextProvider<string>(
    ProjectFilterContext,
    updateProjectFilterParameter,
    getProjectFilterFromCurrentLocation
);
