import { ComponentsStyles, ITheme } from '@fluentui/react';
import { CommandBarButtonStyles } from './styles/command-bar-button-styles';
import { ContextualMenuStyles } from './styles/contextual-menu-styles';
import { DialogStyles } from './styles/dialog-styles';
import { DocumentCardStyles } from './styles/document-card-styles';
import { DropdownStyles } from './styles/dropdown-styles';
import { IconButtonStyles } from './styles/icon-button-styles';
import { MessageBarButtonStyles } from './styles/message-bar-button-styles';
import { PanelStyles } from './styles/panel-styles';
import { PersonaCoinStyles } from './styles/persona-coin-styles';
import { TextFieldStyles } from './styles/textfield-styles';

/**
 * Create custom colors and styles per component in fluent ui.
 * Have a few of them defined here as a sample.
 * @param theme sent as a parameter if necessary which determines the custom colors per theme
 * @returns
 */
export const DefaultStyleSettings = (_theme: ITheme): ComponentsStyles => {
    const CommandBarButton = {
        styles: CommandBarButtonStyles,
    };

    const ContextualMenu = {
        styles: ContextualMenuStyles,
    };

    const DocumentCard = {
        styles: DocumentCardStyles,
    };

    const IconButton = {
        styles: IconButtonStyles,
    };

    const Panel = {
        styles: PanelStyles,
    };

    const PersonaCoin = {
        styles: PersonaCoinStyles,
    };

    const Dialog = {
        styles: DialogStyles,
    };

    const Dropdown = {
        styles: DropdownStyles,
    };

    const MessageBarButton = {
        styles: MessageBarButtonStyles,
    };

    const TextField = {
        styles: TextFieldStyles,
    };

    return {
        CommandBarButton,
        ContextualMenu,
        DocumentCard,
        IconButton,
        Panel,
        PersonaCoin,
        Dialog,
        Dropdown,
        MessageBarButton,
        TextField,
    };
};
