import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useActionCreator } from '../../hooks/action-creator';
import HomeIndexPage from '../../pages/home/index';
import LoadingPage from '../../pages/loading';
import { initializeHome } from '../../redux/actions/sub-applications/home/home-action-creators';
import { getIsHomeInitialized } from '../../redux/selector/sub-applications/home-selectors';
import { getIsLoading } from './selectors';

export const HomeSubApplication: React.FC = () => {
    // Application state hooks
    const isInitialized = useSelector(getIsHomeInitialized);
    const isLoading = useSelector(getIsLoading);

    // Action hooks
    const initialize = useActionCreator(initializeHome);

    // Effect hooks
    React.useEffect(() => {
        if (!isInitialized) {
            initialize();
        }
    }, [isInitialized]);

    if (isLoading) {
        return (
            <Routes>
                <Route path="*" element={<LoadingPage />} />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="*" element={<HomeIndexPage />} />
        </Routes>
    );
};

export default HomeSubApplication;
