import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { DevBox, FailureOnDevBox } from '../../models/dev-box';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxAdapter, devBoxStateAdapter, failureOnDevBoxAdapter } from '../adapters/dev-box-adapters';
import { DevBoxState } from './dev-box-state';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'addDevBox' | 'discoverDevBoxesInTenant';
type IndexedStatusName = 'listDevBoxes';

export interface DevBoxDataStore {
    devBoxes: EntityState<Entity<DevBox>>;
    failuresFromOperations: EntityState<Entity<FailureOnDevBox>>;
    failuresFromResources: EntityState<Entity<FailureOnDevBox>>;
    pendingStates: EntityState<Entity<DevBoxState>>;
}

export const DevBoxDataStore = createInitializer<DevBoxDataStore>({
    devBoxes: devBoxAdapter.getInitialState(),
    failuresFromOperations: failureOnDevBoxAdapter.getInitialState(),
    failuresFromResources: failureOnDevBoxAdapter.getInitialState(),
    pendingStates: devBoxStateAdapter.getInitialState(),
});

export type DevBoxStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const DevBoxStatusStore = createInitializer<DevBoxStatusStore>({
    addDevBox: Status(),
    discoverDevBoxesInTenant: Status(),
    listDevBoxes: statusAdapter.getInitialState(),
});

export interface DevBoxStore {
    data: DevBoxDataStore;
    status: DevBoxStatusStore;
}

export const DevBoxStore = createInitializer<DevBoxStore>({
    data: DevBoxDataStore(),
    status: DevBoxStatusStore(),
});
