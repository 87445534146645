import { makeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { EnvironmentProvisioningState } from '../../../constants/environment';
import environmentCardIcon from '../../../content/images/environment-card-icon.svg';
import { getShowStatusImage } from './selectors';

interface EnvironmentStatusImageProps {
    name: string;
    state: EnvironmentProvisioningState;
}

const messages = defineMessages({
    imageAlt: {
        id: 'EnvironmentStatusImage_Image_Alt',
        defaultMessage: 'Environment "{name}" is currently created and deployed',
        description:
            'Alt text for status image, when environment is created and deployed. {name} should not be localized.',
    },
});

/**
 * Styles
 */

const useStyles = makeStyles({
    root: {
        height: 40,
        width: 41,
    },
});

/**
 * END Styles
 */

export const EnvironmentStatusImage: React.FC<EnvironmentStatusImageProps> = React.memo(
    (props: EnvironmentStatusImageProps) => {
        const { name, state } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const styles = useStyles();

        // Memoized data
        const imageAltTextValues = React.useMemo(() => ({ name }), [name]);
        const showStatusImage = React.useMemo(() => getShowStatusImage(state), [state]);

        if (!showStatusImage) {
            return <></>;
        }

        return (
            <img
                alt={formatMessage(messages.imageAlt, imageAltTextValues)}
                className={styles.root}
                src={environmentCardIcon}
            />
        );
    }
);

export default EnvironmentStatusImage;
