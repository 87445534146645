import { DataResponse } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import { DevBoxRegionRecommendationActionType } from './dev-box-region-recommendation-actions';

/**
 * RD Gateway region
 */

export const getClosestRDGatewayRegion = createActionCreatorWithoutPayload<DataResponse<string>>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegion,
    { async: true }
);

export const getClosestRDGatewayRegionError = createActionCreator<ErrorPayload>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegionError
);

export const getClosestRDGatewayRegionFailed = createActionCreator<FailedPayload>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegionFailed
);

export const getClosestRDGatewayRegionSuccess = createActionCreator<ResultPayload<string>>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegionSuccess
);

/**
 * Load region recommendations
 */

export const loadDevBoxRegionRecommendations = createActionCreatorWithoutPayload<DataResponse>(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendations,
    { async: true }
);

export const loadDevBoxRegionRecommendationsError = createActionCreator<ErrorPayload>(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendationsError
);

export const loadDevBoxRegionRecommendationsFailed = createActionCreator<FailedPayload>(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendationsFailed
);

export const loadDevBoxRegionRecommendationsComplete = createActionCreatorWithoutPayload(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendationsComplete
);
