import { createSelector } from 'reselect';
import { FeatureFlagName } from '../../../constants/features';
import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { createProjectDataPlaneUri } from '../../../ids/project';
import { unique } from '../../../utilities/array';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { get } from '../../../utilities/serializable-map';
import { getIsSingleDevCenterMode } from '../../../utilities/single-dev-center';
import { StoreStateSelector, createKeysSelector, isStatusTerminal } from '../../selector/common';
import { getDevBoxes } from '../../selector/dev-box-selectors';
import { getStatusesForListPools } from '../../selector/pool-selectors';
import { getProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate } from '../../selector/project-from-discovery-service-selectors';
import { getProjectsAuthorizedForDevBoxCreateByDataPlaneId } from '../../selector/project-selectors';
import { getProjectIdsInSingleDevCenter } from '../../selector/sub-applications/single-dev-center-selectors';

/**
 * Composed selectors
 */

const getIdsForProjectsAuthorizedForDevBoxCreate = createKeysSelector(
    getProjectsAuthorizedForDevBoxCreateByDataPlaneId
);

const getIdsForProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate = createKeysSelector(
    getProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate
);

export const getIdsForProjectsRequiringPools: StoreStateSelector<string[]> = createSelector(
    [
        getIdsForProjectsAuthorizedForDevBoxCreate,
        getIdsForProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        getStatusesForListPools,
        getProjectIdsInSingleDevCenter,
        getIsSingleDevCenterMode,
    ],
    (
        idsForProjectsAuthorizedForDevBoxCreate,
        getIdsForProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate,
        statusesForListPoolsByProject,
        projectIdsInSingleDevCenter,
        isSingleDevCenterMode
    ) => {
        const projectIdsToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? getIdsForProjectsFromDiscoveryServiceAuthorizedForDevBoxCreate
            : idsForProjectsAuthorizedForDevBoxCreate;
        const projectIds = isSingleDevCenterMode ? projectIdsInSingleDevCenter : projectIdsToUse;
        return projectIds.filter((id) => !isStatusTerminal(get(statusesForListPoolsByProject, id)));
    }
);

export const getProjectIdsForDevBoxesRequiringPools = createSelector(
    [getDevBoxes, getStatusesForListPools],
    (devBoxes, statusesForListPoolsByProject) => {
        const projectIds = unique(
            devBoxes.map((devBox) => {
                const { uri } = devBox;
                const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(uri);

                return createProjectDataPlaneUri({ devCenter, projectName });
            })
        );

        return projectIds.filter((id) => !isStatusTerminal(get(statusesForListPoolsByProject, id)));
    }
);
