import { createSelector } from 'reselect';
import { StoreStateSelector } from '../../redux/selector/common';
import { DisplayState, getDisplayState } from '../../redux/selector/display/display-state';
import { getIsHomeInitializing } from '../../redux/selector/sub-applications/home-selectors';

/**
 * Composed selectors
 */

export const getIsLoading: StoreStateSelector<boolean> = createSelector(
    [getIsHomeInitializing, getDisplayState],
    (isHomeSubApplicationInitializing, displayState) =>
        isHomeSubApplicationInitializing || displayState === DisplayState.Loading
);
