import { ListProjectsResponse } from '../../../data/services/discovery-service/list-projects';
import { ProjectFromDiscoveryService } from '../../../models/project';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import { ProjectActionType } from './project-actions';

export const listProjects = createActionCreatorWithoutPayload<ListProjectsResponse>(ProjectActionType.ListProjects, {
    async: true,
    startsActivity: true,
});

export const listProjectsError = createActionCreator<ErrorPayload>(ProjectActionType.ListProjectsError);
export const listProjectsFailed = createActionCreator<FailedPayload>(ProjectActionType.ListProjectsFailed);
export const listProjectsSuccess = createActionCreator<ResultPayload<ProjectFromDiscoveryService[]>>(
    ProjectActionType.ListProjectsSuccess
);
