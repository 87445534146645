import { FontSizes, IIconProps, IconButton, Stack, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { AppSemanticColor } from '../../../../themes/app-semantic-colors';
import { getSemanticColor } from '../../../../utilities/styles';
import { FluentIconNames } from '../../../common/fluent-icon-names';

export interface CustomizationFileDisplayProps {
    name: string;
    onRemove: () => void;
    disabled: boolean;
}

const useRemoveStyles = makeStyles((theme) => ({
    root: {
        fontSize: FontSizes.size14,
        color: getSemanticColor(theme, AppSemanticColor.expandIcon),
    },
}));

const useFileStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.actionBarBorder),
        display: 'inline-flex',
        padding: '0 2px 0 8px',
        borderRadius: '3px',
        lineHeight: 'initial',
    },
}));

export const CustomizationFileDisplay: React.FC<CustomizationFileDisplayProps> = React.memo(
    (props: CustomizationFileDisplayProps) => {
        const { name, onRemove, disabled } = props;

        const fileStyles = useFileStyles();
        const removeStyles = useRemoveStyles();

        const clearIcon: IIconProps = React.useMemo(
            () => ({ iconName: FluentIconNames.Clear, styles: removeStyles }),
            [removeStyles]
        );

        return (
            <Stack horizontal verticalAlign="center" styles={fileStyles}>
                <Stack.Item>{name}</Stack.Item>
                <Stack.Item>
                    <IconButton styles={removeStyles} iconProps={clearIcon} onClick={onRemove} disabled={disabled} />
                </Stack.Item>
            </Stack>
        );
    }
);
