import { Status } from '../../models/common';
import { ProjectAbilityAsAdmin, ProjectAbilityAsDeveloper, ProjectFromDiscoveryService } from '../../models/project';
import {
    ProjectEnvironmentTypeAbilities,
    ProjectEnvironmentTypeAbilityAsAdmin,
    ProjectEnvironmentTypeAbilityAsDeveloper,
} from '../../models/project-environment-type';
import { StoreStateSelector } from './common';

export const getDoesProjectFromDiscoveryServiceHaveAuthorizationForAnyDevResource = (
    project: ProjectFromDiscoveryService
): boolean => {
    const { abilitiesAsDeveloper, abilitiesAsAdmin } = project ?? {};

    if (!abilitiesAsDeveloper && !abilitiesAsAdmin) {
        return false;
    }

    const hasDevBoxReadPermission = abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.ReadDevBoxes);
    const hasDevBoxWritePermission = abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.WriteDevBoxes);
    const hasEnvironmentReadPermission =
        abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.ReadEnvironments) ||
        abilitiesAsAdmin?.includes(ProjectAbilityAsAdmin.ReadEnvironments);
    const hasEnvironmentWritePermission =
        abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.WriteEnvironments) ||
        abilitiesAsAdmin?.includes(ProjectAbilityAsAdmin.WriteEnvironments);

    return (
        hasDevBoxReadPermission ||
        hasDevBoxWritePermission ||
        hasEnvironmentReadPermission ||
        hasEnvironmentWritePermission
    );
};

export const getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCreate = (
    project: ProjectFromDiscoveryService
): boolean => {
    return (
        getIsProjectFromDiscoveryServiceAuthorizedForDevBoxRead(project) &&
        getIsProjectFromDiscoveryServiceAuthorizedForDevBoxWrite(project)
    );
};

export const getIsProjectFromDiscoveryServiceAuthorizedForDevBoxRead = (
    project: ProjectFromDiscoveryService
): boolean => {
    const { abilitiesAsDeveloper } = project ?? {};
    const hasDevBoxReadPermission = abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.ReadDevBoxes);
    return hasDevBoxReadPermission ?? false;
};

export const getIsProjectFromDiscoveryServiceAuthorizedForDevBoxWrite = (
    project: ProjectFromDiscoveryService
): boolean => {
    const { abilitiesAsDeveloper } = project ?? {};
    const hasDevBoxWritePermission = abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.WriteDevBoxes);
    return hasDevBoxWritePermission ?? false;
};

export const getIsProjectFromDiscoveryServiceAuthorizedForDevBoxCustomize = (
    project: ProjectFromDiscoveryService
): boolean => {
    const { abilitiesAsDeveloper } = project ?? {};
    const hasDevBoxCustomizePermission = abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.CustomizeDevBoxes);
    return hasDevBoxCustomizePermission ?? false;
};

export const getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentRead = (
    project: ProjectFromDiscoveryService
): boolean => {
    const { abilitiesAsDeveloper, abilitiesAsAdmin } = project ?? {};
    const hasEnvironmentReadPermission =
        abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.ReadEnvironments) ||
        abilitiesAsAdmin?.includes(ProjectAbilityAsAdmin.ReadEnvironments);
    return hasEnvironmentReadPermission ?? false;
};

export const getIsProjectFromDiscoveryServiceAuthorizedForEnvironmentWrite = (
    project: ProjectFromDiscoveryService
): boolean => {
    const { abilitiesAsDeveloper, abilitiesAsAdmin } = project ?? {};
    const hasEnvironmentWritePermission =
        abilitiesAsDeveloper?.includes(ProjectAbilityAsDeveloper.WriteEnvironments) ||
        abilitiesAsAdmin?.includes(ProjectAbilityAsAdmin.WriteEnvironments);
    return hasEnvironmentWritePermission ?? false;
};

export const getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentRead = (
    projectEnvironmentTypeAbilities: ProjectEnvironmentTypeAbilities
): boolean => {
    const { abilitiesAsDeveloper, abilitiesAsAdmin } = projectEnvironmentTypeAbilities ?? {};
    const hasEnvironmentReadPermission =
        abilitiesAsDeveloper?.includes(ProjectEnvironmentTypeAbilityAsDeveloper.ReadEnvironments) ||
        abilitiesAsAdmin?.includes(ProjectEnvironmentTypeAbilityAsAdmin.ReadEnvironments);
    return hasEnvironmentReadPermission ?? false;
};

export const getIsProjectEnvironmentTypeFromDataplaneAuthorizedForEnvironmentWrite = (
    projectEnvironmentTypeAbilities: ProjectEnvironmentTypeAbilities
): boolean => {
    const { abilitiesAsDeveloper, abilitiesAsAdmin } = projectEnvironmentTypeAbilities ?? {};
    const hasEnvironmentWritePermission =
        abilitiesAsDeveloper?.includes(ProjectEnvironmentTypeAbilityAsDeveloper.WriteEnvironments) ||
        abilitiesAsAdmin?.includes(ProjectEnvironmentTypeAbilityAsAdmin.WriteEnvironments);
    return hasEnvironmentWritePermission ?? false;
};

export const getStatusForDiscoverProjectEnvironmentTypeAbilities: StoreStateSelector<Status> = (store) =>
    store.projectEnvironmentTypeStore.status.discoverProjectEnvironmentTypeAbilities;
