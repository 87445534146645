import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { StoreStateSelector } from '../../redux/selector/common';
import { getEnvironmentDefinitionsByProject } from '../../redux/selector/environment-definition-selectors';
import { getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject } from '../../redux/selector/project-environment-type-from-dataplane-selectors';
import { getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject } from '../../redux/selector/project-environment-type-selectors';
import { getProjectsByDiscoveryServiceURI } from '../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsByDataPlaneId } from '../../redux/selector/project-selectors';
import { SerializableMap } from '../../types/serializable-map';
import { compact, sortByInPlace } from '../../utilities/array';
import { filter, forEach, has, map, values } from '../../utilities/serializable-map';
import { compareStrings, isString, isUndefinedOrEmpty, isUndefinedOrWhiteSpace } from '../../utilities/string';
import { ProjectViewModel } from '../common/models';
import { EnvironmentExpirationViewModel } from '../environment/expiration-date/add-or-update-expiration-form-field-group/models';
import {
    EnvironmentDefinitionParameterValue,
    EnvironmentDefinitionParameterViewModel,
} from '../environment/parameters-field/models';
import { getEnvironmentDefinitionParameterViewModels } from '../environment/selectors';
import {
    EnvironmentDefinitionViewModel,
    ProjectToEnvironmentDefinitionViewModelMap,
    ProjectToProjectEnvironmentTypeViewModelMap,
} from './models';

/**
 * Application state selectors
 */

export const getEnvironmentDefinitionViewModels: StoreStateSelector<ProjectToEnvironmentDefinitionViewModelMap> =
    createSelector([getEnvironmentDefinitionsByProject], (environmentDefinitionsByProject) =>
        map(environmentDefinitionsByProject, (environmentDefinitions) => {
            const environmentDefinitionNameIsDuplicateMap = new Map<string, boolean>();

            forEach(environmentDefinitions, (environmentDefinition) => {
                const { name } = environmentDefinition;

                if (isUndefinedOrWhiteSpace(name)) {
                    return;
                }

                if (environmentDefinitionNameIsDuplicateMap.has(name)) {
                    environmentDefinitionNameIsDuplicateMap.set(name, true);
                } else {
                    environmentDefinitionNameIsDuplicateMap.set(name, false);
                }
            });

            const environmentDefinitionsForProject = map(
                environmentDefinitions,
                (environmentDefinition, id): EnvironmentDefinitionViewModel => {
                    const { name, catalogName, description, parameters } = environmentDefinition;

                    const parameterViewModels = getEnvironmentDefinitionParameterViewModels(parameters);

                    return {
                        id,
                        name,
                        catalogName,
                        isDuplicateName: environmentDefinitionNameIsDuplicateMap.get(name) ?? false,
                        description,
                        parameters: parameterViewModels,
                    };
                }
            );

            return sortByInPlace(
                compact(values(environmentDefinitionsForProject)),
                (option) => option.name,
                (a, b) => compareStrings(a, b, true)
            );
        })
    );

export const getProjectEnvironmentTypeViewModels: StoreStateSelector<ProjectToProjectEnvironmentTypeViewModelMap> =
    createSelector(
        [getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject],
        (authorizedProjectEnvironmentTypes) => map(authorizedProjectEnvironmentTypes, (value) => values(value))
    );

export const getProjectEnvironmentTypeFromDataplaneViewModels: StoreStateSelector<ProjectToProjectEnvironmentTypeViewModelMap> =
    createSelector(
        [getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject],
        (authorizedProjectEnvironmentTypes) => {
            const modifiedEnvironmentTypesById = map(authorizedProjectEnvironmentTypes, (environmentTypes) => {
                return map(environmentTypes, (environmentType) => {
                    const { uri, name } = environmentType;
                    return { id: uri, name };
                });
            });

            return map(modifiedEnvironmentTypesById, (value) => values(value));
        }
    );

export const getProjectViewModels: StoreStateSelector<ProjectViewModel[]> = createSelector(
    [
        getProjectsByDataPlaneId,
        getProjectEnvironmentTypesAuthorizedForEnvironmentWriteByProject,
        getEnvironmentDefinitionsByProject,
    ],
    (projects, authorizedProjectEnvironmentTypes, environmentDefinitionsByProject) => {
        // Filter out projects that the user does not have authorized environment types and authorized env defs on
        const filteredProjects = filter(projects, (project, id) => {
            const { id: resourceId } = project;
            if (!has(authorizedProjectEnvironmentTypes, resourceId) || !has(environmentDefinitionsByProject, id)) {
                return false;
            }
            return true;
        });

        // Create a map of project display names to check for duplicates
        const projectDisplayNameIsDuplicateMap = new Map<string, boolean>();

        forEach(filteredProjects, (project) => {
            const { properties } = project;
            const { displayName } = properties;

            if (isUndefinedOrWhiteSpace(displayName)) {
                return;
            }

            if (projectDisplayNameIsDuplicateMap.has(displayName)) {
                projectDisplayNameIsDuplicateMap.set(displayName, true);
            } else {
                projectDisplayNameIsDuplicateMap.set(displayName, false);
            }
        });

        const projectViewModels = map(filteredProjects, (project, id) => {
            const { id: resourceId, name, properties } = project;

            const { displayName } = properties;

            const viewModel: ProjectViewModel = {
                id,
                name,
                resourceId,
                displayName,
                isDisplayNameDuplicate: displayName
                    ? projectDisplayNameIsDuplicateMap.get(displayName)
                    : undefined ?? false,
            };

            return viewModel;
        });

        return sortByInPlace(
            compact(values(projectViewModels)),
            (option) => option.displayName ?? option.name,
            (a, b) => compareStrings(a, b, true)
        );
    }
);

export const getProjectFromDiscoveryServiceViewModelsForEnvironments: StoreStateSelector<ProjectViewModel[]> =
    createSelector(
        [
            getProjectsByDiscoveryServiceURI,
            getProjectEnvironmentTypesFromDataPlaneAuthorizedForEnvironmentWriteByProject,
            getEnvironmentDefinitionsByProject,
        ],
        (projects, authorizedProjectEnvironmentTypes, environmentDefinitionsByProject) => {
            // Filter out projects that the user does not have authorized environment types and authorized env defs on
            const filteredProjects = filter(projects, (project, id) => {
                const { uri: resourceId } = project;
                if (!has(authorizedProjectEnvironmentTypes, resourceId) || !has(environmentDefinitionsByProject, id)) {
                    return false;
                }
                return true;
            });

            // Create a map of project display names to check for duplicates
            const projectDisplayNameIsDuplicateMap = new Map<string, boolean>();

            forEach(filteredProjects, (project) => {
                const { displayName } = project;

                if (isUndefinedOrWhiteSpace(displayName)) {
                    return;
                }

                if (projectDisplayNameIsDuplicateMap.has(displayName)) {
                    projectDisplayNameIsDuplicateMap.set(displayName, true);
                } else {
                    projectDisplayNameIsDuplicateMap.set(displayName, false);
                }
            });

            const projectViewModels = map(filteredProjects, (project, id) => {
                const { uri: resourceId, name, displayName } = project;

                const viewModel: ProjectViewModel = {
                    id,
                    name,
                    resourceId,
                    displayName,
                    isDisplayNameDuplicate: displayName
                        ? projectDisplayNameIsDuplicateMap.get(displayName)
                        : undefined ?? false,
                };

                return viewModel;
            });

            return sortByInPlace(
                compact(values(projectViewModels)),
                (option) => option.displayName ?? option.name,
                (a, b) => compareStrings(a, b, true)
            );
        }
    );

/**
 * Other selectors
 */

export const getProcessedParameterValues = (
    parameterValues: SerializableMap<EnvironmentDefinitionParameterValue | undefined>,
    parameters: EnvironmentDefinitionParameterViewModel[] | undefined
): SerializableMap<EnvironmentDefinitionParameterValue> => {
    const filteredValues = filter(parameterValues, (value, key) => {
        // Filter out undefined values
        if (value === undefined) {
            return false;
        }

        // Filter out empty strings
        if (isString(value) && isUndefinedOrEmpty(value)) {
            return false;
        }

        // If the parameter is set to the default value and is not required, omit it from parameters
        const parameter = parameters?.find((param) => param.id === key);
        if (!!parameter && !parameter.required && !!parameter.default && parameter.default === value) {
            return false;
        }

        return true;
    });

    const processedValues = map(filteredValues, (value, key) => {
        if (!isString(value)) {
            return value;
        }

        const parameter = parameters?.find((param) => param.id === key);
        switch (parameter?.type) {
            case 'object':
            case 'array':
                return JSON.parse(value);

            default:
                return value;
        }
    });

    return processedValues;
};

export const getProjectDataplaneKey = (project: ProjectViewModel): string => project.id;

export const getExpirationDate = (
    environmentExpiration: EnvironmentExpirationViewModel | undefined
): Date | undefined => {
    const { expirationCalendarDate, expirationTime, expirationTimeZone } = environmentExpiration ?? {};

    if (
        expirationCalendarDate === undefined ||
        expirationTime === undefined ||
        isUndefinedOrWhiteSpace(expirationTimeZone)
    ) {
        return undefined;
    }

    const expirationDateTime = DateTime.fromObject(
        {
            year: expirationCalendarDate.getFullYear(),
            month: expirationCalendarDate.getMonth() + 1, //need to add one because month index starts at 0
            day: expirationCalendarDate.getDate(),
            hour: expirationTime.getHours(),
            minute: expirationTime.getMinutes(),
        },
        { zone: expirationTimeZone }
    );

    return expirationDateTime.toJSDate();
};
