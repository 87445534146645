import { SagaIterator } from '@redux-saga/types';
import { call, put } from 'redux-saga/effects';
import { GetRemoteConnectionResponse, getRemoteConnection } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    getRemoteConnection as getRemoteConnectionActionCreator,
    getRemoteConnectionError,
    getRemoteConnectionFailed,
    getRemoteConnectionSuccess,
} from '../../actions/remote-connection/remote-connection-action-creators';
import { GetRemoteConnectionAction } from '../../actions/remote-connection/remote-connection-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* getRemoteConnectionSaga(action: GetRemoteConnectionAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: GetRemoteConnectionResponse = yield call(getRemoteConnection, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(getRemoteConnectionFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;

        yield put(getRemoteConnectionSuccess({ id, result: data }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetRemoteConnection);
        yield put(getRemoteConnectionError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* getRemoteConnectionListenerSaga(): SagaIterator {
    yield takeEvery(getRemoteConnectionActionCreator, getRemoteConnectionSaga);
}
