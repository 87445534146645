import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import {
    DeleteEnvironmentResponse,
    GetEnvironmentResponse,
    deleteEnvironment,
} from '../../../data/services/data-plane-api/environment';
import { ClientError, FailureOperation, FailureResponse, isFailureResponse } from '../../../models/common';
import { createFailureResponseFromCloudErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    deleteEnvironmentAccepted,
    deleteEnvironment as deleteEnvironmentActionCreator,
    deleteEnvironmentError,
    deleteEnvironmentFailed,
    deleteEnvironmentSuccess,
    pollNonTerminalEnvironment,
} from '../../actions/environment/environment-action-creators';
import {
    DeleteEnvironmentAcceptedAction,
    DeleteEnvironmentAction,
} from '../../actions/environment/environment-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

export function* deleteEnvironmentSaga(action: DeleteEnvironmentAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: DeleteEnvironmentResponse = yield call(deleteEnvironment, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(deleteEnvironmentFailed({ failure: response, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DeleteEnvironment,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );

            return;
        }

        yield put(deleteEnvironmentAccepted({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DeleteEnvironment,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DeleteEnvironment);
        yield put(deleteEnvironmentError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DeleteEnvironment,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* deleteEnvironmentAcceptedSaga(action: DeleteEnvironmentAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetEnvironmentResponse = yield putAndAwait(pollNonTerminalEnvironment({ id }, meta));

        if (isFailureResponse(response)) {
            // Note: the data layer will call the operation GetEnvironment. Adjust that here to make it consistent with
            // the other failures that get reported in this saga.
            const failure = FailureResponse({ ...response, operation: FailureOperation.DeleteEnvironment });

            const action =
                failure.statusCode === 404
                    ? deleteEnvironmentSuccess({ id }, meta)
                    : deleteEnvironmentFailed({ failure, id }, meta);

            yield put(action);
            return;
        }

        const { data } = response;

        // Unexpected state: log cases where we're falling back on the default failure message. This means we're
        // in a failed state, but there are no error details.
        if (!data.error) {
            trackEvent(EventName.EnvironmentInFailedStateWithNoError, {
                activityId,
                properties: {
                    actionType: action.type,
                    id,
                    provisioningState: data.provisioningState,
                },
            });
        }

        const failure = createFailureResponseFromCloudErrorBodyOrDefault(
            data.error,
            FailureOperation.DeleteEnvironment
        );

        yield put(deleteEnvironmentFailed({ failure, id, result: data }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DeleteEnvironment);
        yield put(deleteEnvironmentError({ error, id }, meta));
    }
}

export function* deleteEnvironmentListenerSaga(): SagaIterator {
    yield takeLeading(deleteEnvironmentActionCreator, deleteEnvironmentSaga);
}

export function* deleteEnvironmentAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(deleteEnvironmentAccepted, deleteEnvironmentAcceptedSaga);
}
