import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDayOfTheWeek, getTimeStringFromDate, isDayAfterNextDay, isNextDay } from '../../../utilities/time';
import { DelayShutdownDropdownOption } from './model';

interface DateDropdownItemProps {
    date: DelayShutdownDropdownOption;
}

export const DelayShutdownDropdownItem: React.FC<DateDropdownItemProps> = (props: DateDropdownItemProps) => {
    const { date } = props;

    const { dateValue, locale, isSkip } = date;

    const currentTime = new Date();

    const dayOfTheWeek = React.useMemo(() => getDayOfTheWeek(dateValue), [dateValue]);

    const isTomorrow = React.useMemo(() => isNextDay(currentTime, dateValue), [dateValue, currentTime]);
    const isTwoDaysLater = React.useMemo(() => isDayAfterNextDay(currentTime, dateValue), [currentTime, dateValue]);
    const timeString = React.useMemo(() => getTimeStringFromDate(dateValue, locale), [dateValue, locale]) ?? '';

    const valuesForTimeAndDay = React.useMemo(() => ({ timeString, dayOfTheWeek }), [timeString, dayOfTheWeek]);

    const valuesForTime = React.useMemo(() => ({ timeString }), [timeString]);

    if (isTwoDaysLater && isSkip) {
        return (
            <FormattedMessage
                id="DelayShutdownDropdown_TwoDaysLaterAndSkip_Text"
                defaultMessage="{timeString} {dayOfTheWeek} (skip)"
                description="Text for timeString format if the selection goes to the next day. Do not localize {timeString}"
                values={valuesForTimeAndDay}
            />
        );
    }
    if (isTwoDaysLater) {
        return (
            <FormattedMessage
                id="DelayShutdownDropdown_TwoDaysLater_Text"
                defaultMessage="{timeString} {dayOfTheWeek}"
                description="Text for timeString format if the selection goes to the next day. Do not localize {timeString}"
                values={valuesForTimeAndDay}
            />
        );
    }
    if (isSkip) {
        return (
            <FormattedMessage
                id="DelayShutdownDropdown_Skip_Text"
                defaultMessage="{timeString} tomorrow (skip)"
                description="Text for timeString format if the selection goes to the next day. Do not localize {timeString}"
                values={valuesForTime}
            />
        );
    }
    if (isTomorrow) {
        return (
            <FormattedMessage
                id="DelayShutdownDropdown_Tomorrow_Text"
                defaultMessage="{timeString} tomorrow"
                description="Text for timeString format if the selection goes to the next day. Do not localize {timeString}"
                values={valuesForTime}
            />
        );
    }

    return (
        <FormattedMessage
            id="DelayShutdownDropdown_TimeString_Text"
            defaultMessage="{timeString}"
            description="Text for timeString format. Do not localize {timeString}"
            values={valuesForTime}
        />
    );
};

export default DelayShutdownDropdownItem;
