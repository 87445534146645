import { QuickActionName } from '../../../constants/app';
import { Status } from '../../../models/common';
import { SerializableSet } from '../../../types/serializable-set';
import { StoreStateSelector, createIsInitializedSelector, createIsInitializingSelector } from '../common';

/**
 * Basic selectors
 */

export const getDismissedQuickActionsForHome: StoreStateSelector<SerializableSet<QuickActionName>> = (store) =>
    store.subApplicationsStore.home.data.dismissedQuickActions;

export const getStatusForLoadAddDevBoxFormContent: StoreStateSelector<Status> = (store) =>
    store.subApplicationsStore.home.status.loadAddDevBoxFormContent;

export const getStatusForLoadAddEnvironmentFormContent: StoreStateSelector<Status> = (store) =>
    store.subApplicationsStore.home.status.loadAddEnvironmentFormContent;

export const getStatusForLoadBackgroundResources: StoreStateSelector<Status> = (store) =>
    store.subApplicationsStore.home.status.loadBackgroundResources;

export const getStatusForLoadControlPlaneResources: StoreStateSelector<Status> = (store) =>
    store.subApplicationsStore.home.status.loadControlPlaneResources;

export const getStatusForLoadDevBoxCardContent: StoreStateSelector<Status> = (store) =>
    store.subApplicationsStore.home.status.loadDevBoxCardContent;

export const getStatusForLoadEnvironmentCardContent: StoreStateSelector<Status> = (store) =>
    store.subApplicationsStore.home.status.loadEnvironmentCardContent;

/**
 * Composed selectors
 */

export const getIsHomeInitialized = createIsInitializedSelector(
    (store) => store.subApplicationsStore.home.status.initialization
);

export const getIsHomeInitializing = createIsInitializingSelector(
    (store) => store.subApplicationsStore.home.status.initialization
);
