import { defineMessages } from 'react-intl';

export const selectDevBoxPoolDetailsListMessages = defineMessages({
    filterInputPlaceholder: {
        id: 'SelectDevBoxPoolDetailsList_FilterInput_Placeholder',
        defaultMessage: 'Filter for any field...',
        description: 'Text for the select dev box pool details list filter input placeholder',
    },
    filterInputAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_FilterInput_AriaLabel',
        defaultMessage: 'This is the input to filter out the dev box pools for any field',
        description: 'Aria label for the select dev box pool details list filter input',
    },
    nameColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_NameColumnHeader_Text',
        defaultMessage: 'Name',
        description: 'Text for the select dev box pool details list name column',
    },
    nameColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_NameColumnHeader_AriaLabel',
        defaultMessage: 'This is the header for the name column in the details list',
        description: 'Aria label for the select dev box pool details list name column',
    },
    cpuColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_CpuColumnHeader_Text',
        defaultMessage: 'CPU',
        description: 'Text for the select dev box pool details list cpu column',
    },
    cpuColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_CpuColumnHeader_AriaLabel',
        defaultMessage: 'This is the header for the cpu column in the details list',
        description: 'Aria label for the select dev box pool details list cpu column',
    },
    ramColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_RamColumnHeader_Text',
        defaultMessage: 'RAM',
        description: 'Text for the select dev box pool details list ram column',
    },
    ramColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_RamColumnHeader_AriaLabel',
        defaultMessage: 'This is the header for the RAM column in the details list',
        description: 'Aria label for the select dev box pool details list ram column',
    },
    storageColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_StorageColumnHeader_Text',
        defaultMessage: 'Storage',
        description: 'Text for the select dev box pool details list storage column',
    },
    storageColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_StorageColumnHeader_AriaLabel',
        defaultMessage: 'Header for the storage column in the details list',
        description: 'Aria Label for the select dev box pool details list storage column',
    },
    sourceImageColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_SourceImageColumnHeader_Text',
        defaultMessage: 'Source image',
        description: 'Text for the select dev box pool details list source image column',
    },
    sourceImageColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_SourceImageColumnHeader_AriaLabel',
        defaultMessage: 'Header for the source image column in the details list',
        description: 'Aria Label for the select dev box pool details list source image column',
    },
    versionColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_VersionColumnHeader_Text',
        defaultMessage: 'Version',
        description: 'Text for the "select dev box pool" details list source image version column',
    },
    versionColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_VersionColumnHeader_AriaLabel',
        defaultMessage: 'Header for the source image version column in the details list',
        description: 'Aria label for the "select dev box pool" details list source image version column',
    },
    regionColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_RegionColumnHeader_Text',
        defaultMessage: 'Region',
        description: 'Text for the select dev box pool details list region column',
    },
    regionColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_RegionColumnHeader_AriaLabel',
        defaultMessage: 'Header for the region column in the details list',
        description: 'Aria Label for the select dev box pool details list region column',
    },
    lastImageUpdateColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_LastImageUpdateColumnHeader_Text',
        defaultMessage: 'Last image update',
        description: 'Text for the select dev box pool details list last image update column',
    },
    lastImageUpdateColumnHeaderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_LastImageUpdateColumnHeader_AriaLabel',
        defaultMessage: 'Header for the last image update column in the details list',
        description: 'Aria Label for the select dev box pool details list last image update column',
    },
    sortAscendingOrderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_SortAscendingOrder_AriaLabel',
        defaultMessage: 'Sorted from A to Z',
        description: 'Aria Label for the select dev box pool details list sorting ascending order',
    },
    sortDescendingOrderAriaLabel: {
        id: 'SelectDevBoxPoolDetailsList_SortDescendingOrder_AriaLabel',
        defaultMessage: 'Sorted from Z to A',
        description: 'Aria Label for the select dev box pool details list sorting descending order',
    },
    cpuFormattedText: {
        id: 'SelectDevBoxPoolDetailsList_CpuFormattedValue_Text',
        defaultMessage: '{cpuCount} vCPU',
        description:
            'Text informing user of how many cores are assigned to a dev box. {cpuCount} should not be localized.',
    },
    diskSizeInGbFormattedText: {
        id: 'SelectDevBoxPoolDetailsList_DiskSizeInGbFormattedValue_Text',
        defaultMessage: '{diskSizeInGb} SSD',
        description: 'Text informing user of how much memory a dev box has. {diskSizeInGb} should not be localized.',
    },
    hibernationColumnHeaderText: {
        id: 'SelectDevBoxPoolDetailsList_HibernationColumnHeader_Text',
        defaultMessage: 'Hibernation',
        description: 'Text for the select dev box pool details list hibernation column',
    },
    hibernationSupportedText: {
        id: 'SelectDevBoxPoolDetailsList_HibernationSupported',
        defaultMessage: 'Supported',
        description: 'Text indicating that the selected pool supports hibernation',
    },
    hibernationNotSupportedText: {
        id: 'SelectDevBoxPoolDetailsList_HibernationNotSupported',
        defaultMessage: 'Not supported',
        description: 'Text indicating that the selected pool does not support hibernation',
    },
});
