import { Failure } from '../../../models/common';
import { RequiredProperty } from '../../../types/required-property';
import { first } from '../../../utilities/array';

export const getMessageAndCodeFromDetails = (
    failure: RequiredProperty<Failure, 'details'>
): { message: string | undefined; code: string | undefined } => {
    const { details } = failure;
    const { code, message } = first(details) ?? {};
    return { message, code };
};
