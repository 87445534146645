import { GetEnvironmentTypeAbilitiesResponse } from '../../../data/services/data-plane-api/environment-type';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    GetProjectEnvironmentTypeAbilitiesSuccessPayload,
    ProjectEnvironmentTypeAbilitiesActionType,
} from './project-environment-type-abilities-actions';

export const discoverProjectEnvironmentTypesAbilities = createActionCreatorWithoutPayload<AggregatedResult>(
    ProjectEnvironmentTypeAbilitiesActionType.DiscoverProjectEnvironmentTypesAbilities,
    { async: true }
);

export const discoverProjectEnvironmentTypesAbilitiesError = createActionCreator<ErrorPayload>(
    ProjectEnvironmentTypeAbilitiesActionType.DiscoverProjectEnvironmentTypesAbilitiesError
);

export const discoverProjectEnvironmentTypesAbilitiesFailed = createActionCreator<FailedPayload>(
    ProjectEnvironmentTypeAbilitiesActionType.DiscoverProjectEnvironmentTypesAbilitiesFailed
);

export const discoverProjectEnvironmentTypesAbilitiesSuccess = createActionCreatorWithoutPayload(
    ProjectEnvironmentTypeAbilitiesActionType.DiscoverProjectEnvironmentTypesAbilitiesSuccess
);

export const getProjectEnvironmentTypeAbilities = createActionCreator<
    IndexedPayload,
    GetEnvironmentTypeAbilitiesResponse
>(ProjectEnvironmentTypeAbilitiesActionType.GetProjectEnvironmentTypeAbilities, {
    async: true,
    startsActivity: true,
});

export const getProjectEnvironmentTypeAbilitiesError = createActionCreator<ErrorPayload & IndexedPayload>(
    ProjectEnvironmentTypeAbilitiesActionType.GetProjectEnvironmentTypeAbilitiesError
);

export const getProjectEnvironmentTypeAbilitiesFailed = createActionCreator<FailedPayload & IndexedPayload>(
    ProjectEnvironmentTypeAbilitiesActionType.GetProjectEnvironmentTypeAbilitiesFailed
);

export const getProjectEnvironmentTypeAbilitiesSuccess =
    createActionCreator<GetProjectEnvironmentTypeAbilitiesSuccessPayload>(
        ProjectEnvironmentTypeAbilitiesActionType.GetProjectEnvironmentTypeAbilitiesSuccess
    );
