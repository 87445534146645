import { IIconProps } from '@fluentui/react';
import { Failure } from '../../models/common';
import { UnionMap } from '../../types/union-map';
import { ErrorBannerActionProps } from './error-banner-actions';

export interface ResourceCardError {
    message: React.ReactNode;
    failure?: Failure;
    retryable?: boolean;
    severity: ResourceCardErrorSeverity;
    iconProps?: IIconProps;
}

export type ResourceCardErrorSeverity = 'High' | 'Med' | 'Low';

export const ResourceCardErrorSeverity: UnionMap<ResourceCardErrorSeverity> = {
    High: 'High',
    Med: 'Med',
    Low: 'Low',
};

export type ResourceCardBannerKind = 'Error' | 'Info' | 'Success';

export const ResourceCardBannerKind: UnionMap<ResourceCardBannerKind> = {
    Error: 'Error',
    Info: 'Info',
    Success: 'Success',
};

export type ResourceCardBannerViewModel =
    | ResourceCardErrorBannerViewModel
    | ResourceCardInfoBannerViewModel
    | ResourceCardSuccessBannerViewModel;

export interface ResourceCardErrorBannerViewModel {
    kind: ResourceCardBannerKind;
    error: ResourceCardError;
    isDismissable: boolean;
    additionalErrorBannerActions?: ErrorBannerActionProps[];
    onRetryFailedOperation?: () => void;
    onSeeErrorDetails?: () => void;
    onDismiss?: () => void;
}

export interface ResourceCardInfoBannerViewModel {
    kind: ResourceCardBannerKind;
    isDismissable: boolean;
    infoBannerMessage: JSX.Element;
    onDismiss?: () => void;
    onSeeDetails?: () => void;
    iconProps?: IIconProps;
}

export interface ResourceCardSuccessBannerViewModel {
    kind: ResourceCardBannerKind;
    isDismissable: boolean;
    successBannerMessage: JSX.Element;
    onDismiss?: () => void;
}

/**
 * Type guards
 */

export const isErrorBannerViewModel = (
    viewModel: ResourceCardBannerViewModel
): viewModel is ResourceCardErrorBannerViewModel => viewModel.kind === ResourceCardBannerKind.Error;

export const isInfoBannerViewModel = (
    viewModel: ResourceCardBannerViewModel
): viewModel is ResourceCardInfoBannerViewModel => viewModel.kind === ResourceCardBannerKind.Info;

export const isSuccessBannerViewModel = (
    viewModel: ResourceCardBannerViewModel
): viewModel is ResourceCardSuccessBannerViewModel => viewModel.kind === ResourceCardBannerKind.Success;
