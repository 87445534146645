import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from './tag';

export const PreviewTag: React.FC = React.memo(() => (
    <Tag>
        <FormattedMessage
            id="PreviewTag"
            defaultMessage="preview"
            description="Tag used to indicate that a feature is in preview"
        />
    </Tag>
));
