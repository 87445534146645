import { PhotoSize } from '../../../constants/graph';
import { ContentType, Method } from '../../../constants/http';
import { DataResponse, FailureOperation, FailureResponse } from '../../../models/common';
import { GraphErrorContract, GraphUserContract } from '../../contracts/graph';
import { graphRequest } from './graph-request';

export type GetPhotoForSignedInUserResponse = DataResponse<Blob>;
export type GetSignedInUserResponse = DataResponse<GraphUserContract>;

// API documentation found here: https://docs.microsoft.com/en-us/graph/api/profilephoto-get?view=graph-rest-1.0
export const getPhotoForSignedInUser = async (
    accessToken: string,
    photoSize: PhotoSize,
    activityId?: string
): Promise<GetPhotoForSignedInUserResponse> => {
    const path = photoSize !== PhotoSize.Original ? `/me/photos/${photoSize}/$value` : '/me/photo/$value';
    const result = await graphRequest(path, Method.GET, { accessToken, accept: ContentType.ImageAll, activityId });

    if (result.status !== 200) {
        const graphError: GraphErrorContract = await result.json();

        return FailureResponse({
            code: graphError?.error?.code,
            message: graphError?.error?.message,
            operation: FailureOperation.GetPhotoForSignedInUser,
            statusCode: result.status,
        });
    }

    return {
        data: await result.blob(),
        succeeded: true,
    };
};

// API documentation found here: https://docs.microsoft.com/en-us/graph/api/user-get?view=graph-rest-1.0
// Available "User" object properties here: https://docs.microsoft.com/en-us/graph/api/resources/user?view=graph-rest-1.0#properties
export const getSignedInUser = async (accessToken: string, activityId?: string): Promise<GetSignedInUserResponse> => {
    const result = await graphRequest('/me', Method.GET, { accessToken, activityId });

    if (result.status !== 200) {
        const graphError: GraphErrorContract = await result.json();

        return FailureResponse({
            code: graphError?.error?.code,
            message: graphError?.error?.message,
            operation: FailureOperation.GetSignedInUser,
            statusCode: result.status,
        });
    }

    return {
        data: (await result.json()) as GraphUserContract,
        succeeded: true,
    };
};
