import { StorageType } from '../../../constants/storage';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import { getStorageValue, initializeStorage, setStorageValue } from './storage-action-creators';

/**
 * Action types
 */

type StorageActionName =
    | ActionName<'GetStorageValue', 'Error' | 'Failed' | 'Success'>
    | ActionName<'InitializeStorage', 'Error' | 'Success'>
    | ActionName<'SetStorageValue', 'Error' | 'Failed' | 'Success'>;

export type StorageActionType =
    | ActionType<'GET_STORAGE_VALUE', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'INITIALIZE_STORAGE', '_ERROR' | '_SUCCESS'>
    | ActionType<'SET_STORAGE_VALUE', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const StorageActionType: UnionValueMap<StorageActionName, StorageActionType> = {
    GetStorageValue: 'GET_STORAGE_VALUE',
    GetStorageValueError: 'GET_STORAGE_VALUE_ERROR',
    GetStorageValueFailed: 'GET_STORAGE_VALUE_FAILED',
    GetStorageValueSuccess: 'GET_STORAGE_VALUE_SUCCESS',
    InitializeStorage: 'INITIALIZE_STORAGE',
    InitializeStorageError: 'INITIALIZE_STORAGE_ERROR',
    InitializeStorageSuccess: 'INITIALIZE_STORAGE_SUCCESS',
    SetStorageValue: 'SET_STORAGE_VALUE',
    SetStorageValueError: 'SET_STORAGE_VALUE_ERROR',
    SetStorageValueFailed: 'SET_STORAGE_VALUE_FAILED',
    SetStorageValueSuccess: 'SET_STORAGE_VALUE_SUCCESS',
};

/**
 * Payloads
 */

interface CoreGetStorageValuePayload {
    getForSignedInUser?: boolean;
    key: string;
    storageType: StorageType;
}

interface CoreSetStorageValuePayload {
    key: string;
    setForSignedInUser?: boolean;
    storageType: StorageType;
}

export type GetStorageValuePayload = CoreGetStorageValuePayload;
export type GetStorageValueErrorPayload = CoreGetStorageValuePayload & ErrorPayload;
export type GetStorageValueFailedPayload = CoreGetStorageValuePayload & FailedPayload;
export type GetStorageValueSuccessPayload = CoreGetStorageValuePayload & ResultPayload<string>;

export type SetStorageValuePayload = CoreSetStorageValuePayload & { value: string };
export type SetStorageValueErrorPayload = CoreSetStorageValuePayload & ErrorPayload;
export type SetStorageValueFailedPayload = CoreSetStorageValuePayload & FailedPayload;
export type SetStorageValueSuccessPayload = CoreSetStorageValuePayload;

/**
 * Action shorthands
 */

export type GetStorageValueAction = ReturnType<typeof getStorageValue>;
export type InitializeStorageAction = ReturnType<typeof initializeStorage>;
export type SetStorageValueAction = ReturnType<typeof setStorageValue>;
