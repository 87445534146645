import React from 'react';
import { useSelector } from 'react-redux';
import { DisplayState, getDisplayState } from '../../redux/selector/display/display-state';
import { InfoMessageBar as InfoMessageBarContainer } from './message-bars/info-message-bar';
import { shouldHideInfoMessageBar } from './selectors';

interface InfoMessageBarProps {
    displayState: DisplayState;
}

const InfoMessageBarComponent: React.FC<InfoMessageBarProps> = React.memo((props: InfoMessageBarProps) => {
    const { displayState } = props;

    const hideInfoMessageBar = React.useMemo(() => shouldHideInfoMessageBar(displayState), [displayState]);

    if (hideInfoMessageBar) {
        return <></>;
    }

    return <InfoMessageBarContainer />;
});

export const InfoMessageBar: React.FC = () => {
    // Application state hooks
    const displayState = useSelector(getDisplayState);

    return <InfoMessageBarComponent displayState={displayState} />;
};
