import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
    ListGraphDirectoryObjectsResponse,
    listDirectoryObjectsByIds,
} from '../../../data/services/graph/directory-objects';
import {
    ClientError,
    FailureOperation,
    FailureResponse,
    Nothing,
    SuccessResponse,
    isFailureResponse,
} from '../../../models/common';
import { GraphDirectoryObject, GraphDirectoryObjectType } from '../../../models/graph';
import { SerializableMap } from '../../../types/serializable-map';
import { get } from '../../../utilities/serializable-map';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../../actions/core-actions';
import {
    getGraphDirectoryObject,
    getGraphDirectoryObjectError,
    getGraphDirectoryObjectFailed,
    getGraphDirectoryObjectSuccess,
} from '../../actions/graph/directory-objects-action-creators';
import { GetGraphDirectoryObjectAction } from '../../actions/graph/directory-objects-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForGraphPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getActionsInGroup } from '../../utilities/groupable-action';

const applyErrorToIds = (ids: string[], error: ClientError) =>
    ids.map<ErrorPayload & IndexedPayload>((id) => ({ error, id }));

const applyFailureToIds = (ids: string[], failure: FailureResponse) =>
    ids.map<FailedPayload & IndexedPayload>((id) => ({ failure, id }));

export function* getGraphDirectoryObjectSaga(action: GetGraphDirectoryObjectAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};
    const actions = getActionsInGroup(action);
    const ids = actions.map((action) => action.payload.id);

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForGraphPayload(), meta));

        const response: ListGraphDirectoryObjectsResponse = yield call(
            listDirectoryObjectsByIds,
            ids,
            [GraphDirectoryObjectType.user, GraphDirectoryObjectType.servicePrincipal],
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            const responses = actions.map(() => response);
            yield put(getGraphDirectoryObjectFailed(applyFailureToIds(ids, response), meta));
            yield resolveAction(action, responses);
            return;
        }

        // TODO #1887921: replace with KeyValuePair in forthcoming pull request
        const data = SerializableMap(
            response.data.map(
                (directoryObject) => [directoryObject.id, directoryObject] as [string, GraphDirectoryObject]
            )
        );

        // If there was no corresponding document from the query for an ID, use Nothing
        const payloads = ids.map((id) => ({
            id,
            result: get(data, id) ?? Nothing,
        }));

        const responses = ids.map<SuccessResponse<GraphDirectoryObject | Nothing>>((id) => ({
            data: get(data, id) ?? Nothing,
            succeeded: true,
        }));

        yield put(getGraphDirectoryObjectSuccess(payloads));
        yield resolveAction(action, responses);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetGraphDirectoryObject);
        yield put(getGraphDirectoryObjectError(applyErrorToIds(ids, error), meta));
        yield rejectAction(action, error);
    }
}

export function* getGraphDirectoryObjectListenerSaga(): SagaIterator {
    yield takeEvery(getGraphDirectoryObject, getGraphDirectoryObjectSaga);
}
