import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { isValidDataPlaneUri, parseDataPlanePath } from './data-plane';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const catalogPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.Catalog}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createCatalogDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'catalogName' | 'devCenter' | 'projectName'>
): string => {
    const { catalogName, devCenter, projectName } = tokens;

    if (isUndefinedOrWhiteSpace(catalogName)) {
        throw new ClientError('Catalog name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.Catalog,
        catalogName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromCatalogDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'catalogName' | 'devCenter' | 'projectName'> => {
    if (!isValidCatalogDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a catalog');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , catalogName] = parseDataPlanePath(pathname);

    return {
        catalogName,
        devCenter: origin,
        projectName,
    };
};

export const isValidCatalogDataPlaneUri = (uri: string): boolean => isValidDataPlaneUri(uri, catalogPathPattern);

export const tryCreateCatalogDataPlaneUri = tryOrDefault(createCatalogDataPlaneUri);
export const tryGetTokensFromCatalogDataPlaneUri = tryOrDefault(getTokensFromCatalogDataPlaneUri);
