import * as React from 'react';
import { PropsWithChildren } from '../types/props-with-children';

export type SimpleLayoutProps = PropsWithChildren<{
    id: string;
}>;

export const SimpleLayout: React.FC<SimpleLayoutProps> = (props: SimpleLayoutProps) => {
    const { children, id } = props;

    return (
        <main className="simple-layout" id={id}>
            {children}
        </main>
    );
};

export default SimpleLayout;
