import { createSelector } from '@reduxjs/toolkit';
import { Status } from '../../models/common';
import { statusAdapter } from '../adapters/common/status-adapter';
import { scheduleAdapter } from '../adapters/schedule-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector } from './common';

/**
 * Basic selectors
 */

export const getStatusForDiscoverSchedulesForPools: StoreStateSelector<Status> = (store) =>
    store.scheduleStore.status.discoverSchedulesForPools;

/**
 * Entity state selectors
 */

const scheduleSelectors = scheduleAdapter.getSelectors<StoreState>((store) => store.scheduleStore.data.schedules);

const statusesForListSchedulesByProjectSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.scheduleStore.status.listSchedulesByProject
);

/**
 * Composed selectors
 */

export const getHasSchedules: StoreStateSelector<boolean> = createSelector(
    [scheduleSelectors.selectTotal],
    (count) => count > 0
);

export const getSchedules = createMapFromEntitiesSelector(scheduleSelectors.selectAll);

export const getStatusesForListSchedulesByProject = createMapFromEntitiesSelector(
    statusesForListSchedulesByProjectSelectors.selectAll
);
