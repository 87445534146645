import { Browser, OperatingSystem as DetectBrowserOperatingSystem } from 'detect-browser';
import { UnionValueMap } from '../types/union-map';

export type BrowserName = Browser | 'bot' | 'node' | 'react-native' | 'unknown';
export type OperatingSystem = DetectBrowserOperatingSystem | NodeJS.Platform | 'unknown';

type OperatingSystemFamilyName = 'Android' | 'IOS' | 'MacOS' | 'Unknown' | 'Windows';

export type OperatingSystemFamily = 'Android' | 'iOS' | 'MacOS' | 'Unknown' | 'Windows';

export const OperatingSystemFamily: UnionValueMap<OperatingSystemFamilyName, OperatingSystemFamily> = {
    Android: 'Android',
    IOS: 'iOS',
    MacOS: 'MacOS',
    Unknown: 'Unknown',
    Windows: 'Windows',
};
