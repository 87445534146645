import { RemoteAppOption, UserSettings } from '../../types/user-settings';
import { UserSettingsFormData } from './models';

export const getUserSettingsPanelInitialValues = (incomingUserSettings: UserSettings): UserSettingsFormData =>
    UserSettingsFormData({ ...incomingUserSettings });

export const getUserSettingsSetFromFormData = (formData: UserSettingsFormData): UserSettings => {
    const { useMultiMonitor, themeMode, preferredRemoteApp } = formData;

    const settings: UserSettings = {
        themeMode: themeMode,
        useMultiMonitor: !!useMultiMonitor,
        preferredRemoteApp: preferredRemoteApp ?? RemoteAppOption.useWindowsApp,
    };

    return settings;
};
