import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import listGraphDirectoryObjectsByIds from './list-graph-directory-objects-by-ids.json';

export type GraphDirectoryObjectScenarioName = 'ListGraphDirectoryObjectsByIds';

export const GraphDirectoryObjectScenarioName: UnionMap<GraphDirectoryObjectScenarioName> = {
    ListGraphDirectoryObjectsByIds: 'ListGraphDirectoryObjectsByIds',
};

export const GraphDirectoryObjectScenarios: UnionValueMap<GraphDirectoryObjectScenarioName, MockScenarioConfiguration> =
    {
        ListGraphDirectoryObjectsByIds: listGraphDirectoryObjectsByIds as MockScenarioConfiguration,
    };
