import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    discoverPermissionsForProjectEnvironmentTypes,
    discoverPermissionsForProjectEnvironmentTypesError,
    discoverPermissionsForProjectEnvironmentTypesFailed,
    discoverPermissionsForProjectEnvironmentTypesSuccess,
    discoverPermissionsForProjects,
    discoverPermissionsForProjectsError,
    discoverPermissionsForProjectsFailed,
    discoverPermissionsForProjectsSuccess,
    getProjectEnvironmentTypePermissions,
    getProjectEnvironmentTypePermissionsError,
    getProjectEnvironmentTypePermissionsFailed,
    getProjectEnvironmentTypePermissionsSuccess,
    getProjectPermissions,
    getProjectPermissionsError,
    getProjectPermissionsFailed,
    getProjectPermissionsSuccess,
} from '../actions/permission/permission-action-creators';
import {
    projectEnvironmentTypePermissionRecordAdapter,
    projectPermissionRecordAdapter,
} from '../adapters/permission-adapters';
import { PermissionDataStore, PermissionStatusStore, PermissionStore } from '../store/permission-store';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const permissionReducer = combineReducers<PermissionStore>({
    data: createReducer(PermissionDataStore(), (builder) => {
        builder
            .addCase(getProjectEnvironmentTypePermissionsSuccess, (store, action) => {
                const actions = getActionsInGroup(action);

                projectEnvironmentTypePermissionRecordAdapter.setMany(
                    store.projectEnvironmentTypePermissions,
                    actions.map((action) => {
                        const { id, result } = getPayload(action);
                        return Entity(id, result);
                    })
                );
            })
            .addCase(getProjectPermissionsSuccess, (store, action) => {
                const actions = getActionsInGroup(action);

                projectPermissionRecordAdapter.setMany(
                    store.projectPermissions,
                    actions.map((action) => {
                        const { id, result } = getPayload(action);
                        return Entity(id, result);
                    })
                );
            });
    }),

    status: combineReducers<PermissionStatusStore>({
        discoverProjectEnvironmentTypePermissions: createStatusReducer({
            inProgress: discoverPermissionsForProjectEnvironmentTypes,
            error: discoverPermissionsForProjectEnvironmentTypesError,
            failed: discoverPermissionsForProjectEnvironmentTypesFailed,
            success: discoverPermissionsForProjectEnvironmentTypesSuccess,
        }),

        discoverProjectPermissions: createStatusReducer({
            inProgress: discoverPermissionsForProjects,
            error: discoverPermissionsForProjectsError,
            failed: discoverPermissionsForProjectsFailed,
            success: discoverPermissionsForProjectsSuccess,
        }),

        getProjectEnvironmentTypePermissions: createIndexedStatusReducer({
            inProgress: getProjectEnvironmentTypePermissions,
            error: getProjectEnvironmentTypePermissionsError,
            failed: getProjectEnvironmentTypePermissionsFailed,
            success: getProjectEnvironmentTypePermissionsSuccess,
        }),

        getProjectPermissions: createIndexedStatusReducer({
            inProgress: getProjectPermissions,
            error: getProjectPermissionsError,
            failed: getProjectPermissionsFailed,
            success: getProjectPermissionsSuccess,
        }),
    }),
});
