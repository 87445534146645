import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import {
    createCustomizationGroupAcceptedListenerSaga,
    createCustomizationGroupListenerSaga,
} from './create-customization-group';
import { getCustomizationTaskLogListenerSaga } from './get-customization-task-log';
import { listCustomizationGroupsListenerSaga } from './list-customization-groups';
import { listCustomizationTaskDefinitionsListenerSaga } from './list-customization-task-definitions';
import { loadCustomizationGroupsListenerSaga } from './load-customization-groups';
import { pollNonTerminalCustomizationGroupListenerSaga } from './poll-non-terminal-customization-group';
import { validateCustomizationTasksListenerSaga } from './validate-customization-tasks';

export function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        fork(listCustomizationGroupsListenerSaga),
        fork(createCustomizationGroupListenerSaga),
        fork(createCustomizationGroupAcceptedListenerSaga),
        fork(listCustomizationTaskDefinitionsListenerSaga),
        fork(loadCustomizationGroupsListenerSaga),
        fork(pollNonTerminalCustomizationGroupListenerSaga),
        fork(validateCustomizationTasksListenerSaga),
        fork(getCustomizationTaskLogListenerSaga),
    ]);
}
