import { SagaIterator } from 'redux-saga';
import { call, CallEffect } from 'redux-saga/effects';
import { ClientError } from '../../models/common';
import { AsyncAction } from '../actions/core-actions';

/**
 * Types
 */

export type RejectActionSignature<TAwaited> = (action: AsyncAction<TAwaited>, error: ClientError) => SagaIterator<void>;

/**
 * Effects
 */

export function* rejectActionSaga<TAwaited>(action: AsyncAction<TAwaited>, error: ClientError): SagaIterator<void> {
    const { async } = action;
    const { reject } = async;

    yield call(reject, error);
}

export const rejectAction = <TAwaited>(action: AsyncAction<TAwaited>, error: ClientError): CallEffect<void> =>
    call<RejectActionSignature<TAwaited>>(rejectActionSaga, action, error);
