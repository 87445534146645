import { Stack } from '@fluentui/react';
import React from 'react';
import { Field } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { SerializableMap } from '../../../types/serializable-map';
import { hasNoValue } from '../../../utilities/object';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import ReadOnlyControl from '../../common/form/read-only-control';
import { FieldProps } from '../../common/form/types';
import { EnvironmentDefinitionParameterValue, EnvironmentDefinitionParameterViewModel, ParameterType } from './models';
import { EnvironmentParameterControl } from './parameter-control';
import {
    getParameterError,
    getParameterValue,
    isArray,
    isValidJson,
    removeParameterValue,
    setParameterError,
    setParameterValue,
} from './selectors';

const messages = defineMessages({
    environmentParameterAriaLabel: {
        id: 'EnvironmentParametersField_Parameter_AriaLabel',
        defaultMessage: 'Parameter for your chosen environment definition',
        description: 'Aria label for the parameters in the environment parameters field',
    },
    environmentParameterDropdownPlaceholderText: {
        id: 'EnvironmentParametersField_DropdownParameter_PlaceholderText',
        defaultMessage: 'Choose parameter option',
        description: 'Text for placeholder for dropdown parameters',
    },
    environmentParameterTextFieldPlaceholderText: {
        id: 'EnvironmentParametersField_TextFieldParameter_PlaceholderText',
        defaultMessage: 'Enter parameter option',
        description: 'Text for placeholder for textfield parameters',
    },
    parameterRequiredError: {
        id: 'EnvironmentParametersField_ParameterRequiredError',
        defaultMessage: 'Required',
        description: 'Error text indicating that a required parameter in the parameters field is empty',
    },
    parameterMustBeIntegerError: {
        id: 'EnvironmentParametersField_ParameterMustBeIntegerError',
        defaultMessage: 'Enter an integer value',
        description: 'Error text indicating that an integer parameter is not an integer',
    },
    parameterMustBeValidJsonError: {
        id: 'EnvironmentParametersField_ParameterMustBeValidJsonError',
        defaultMessage: 'Invalid JSON',
        description: 'Error text indicating that an object parameter is not valid JSON',
    },
    parameterMustBeArrayError: {
        id: 'EnvironmentParametersField_ParameterMustBeArrayError',
        defaultMessage: 'Invalid array',
        description: 'Error text indicating that an array parameter is not a valid array',
    },
    environmentDefinitionFieldLabel: {
        id: 'EnvironmentParametersField_EnvironmentDefinitionFieldLabel',
        defaultMessage: 'Environment definition',
        description:
            'The label for the readonly field indicating which environment definition these parameters belong to.',
    },
});

export interface ParametersFieldProps extends FieldProps<SerializableMap<EnvironmentDefinitionParameterValue>> {
    isSubmitting: boolean;
    parameters: EnvironmentDefinitionParameterViewModel[] | undefined;
}

const containerTokens = { childrenGap: 16 };

export const EnvironmentParametersField: React.FC<ParametersFieldProps> = (props: ParametersFieldProps) => {
    const { isSubmitting, parameters } = props;

    const { formatMessage } = useIntl();

    const validateParameters = React.useCallback(
        (value: SerializableMap<EnvironmentDefinitionParameterValue>): SerializableMap<string> => {
            if (!parameters) {
                return SerializableMap<string>();
            }

            let errors = SerializableMap<string>();

            parameters.forEach((parameter) => {
                const { id } = parameter;

                const parameterValue = getParameterValue(value, id);

                if (hasNoValue(parameterValue) && parameter.required) {
                    errors = setParameterError(errors, id, formatMessage(messages.parameterRequiredError));
                    return;
                }

                if (hasNoValue(parameterValue)) {
                    return;
                }

                // Param-type-specific validation
                switch (parameter.type) {
                    case ParameterType.integer:
                        if (!Number.isInteger(parameterValue)) {
                            errors = setParameterError(errors, id, formatMessage(messages.parameterMustBeIntegerError));
                        }
                        break;
                    case ParameterType.array:
                        if (!isArray(parameterValue as string)) {
                            errors = setParameterError(errors, id, formatMessage(messages.parameterMustBeArrayError));
                        }
                        break;
                    case ParameterType.object:
                        if (!isValidJson(parameterValue as string)) {
                            errors = setParameterError(
                                errors,
                                id,
                                formatMessage(messages.parameterMustBeValidJsonError)
                            );
                        }
                        break;
                    default:
                        break;
                }
            });

            return errors;
        },
        [formatMessage, parameters]
    );

    return (
        <Field<SerializableMap<EnvironmentDefinitionParameterValue>> {...props} validate={validateParameters}>
            {(fieldProps) => {
                const { meta, input } = fieldProps;
                const { value: selectedParameters, onChange } = input;
                const { error } = meta;

                const parameterOnChange = React.useCallback(
                    (parameterId: string, value: EnvironmentDefinitionParameterValue | undefined) => {
                        const updatedSelectedParameters =
                            value !== undefined
                                ? setParameterValue(selectedParameters, parameterId, value)
                                : removeParameterValue(selectedParameters, parameterId);

                        onChange(updatedSelectedParameters);
                    },
                    [onChange, selectedParameters]
                );

                return (
                    <Stack tokens={containerTokens}>
                        {parameters?.map((param) => (
                            <EnvironmentParameterControl
                                key={param.id}
                                parameter={param}
                                onChange={parameterOnChange}
                                value={getParameterValue(selectedParameters, param.id)}
                                error={getParameterError(error, param.id)}
                                disabled={isSubmitting}
                                ariaLabel={formatMessage(messages.environmentParameterAriaLabel)}
                            />
                        ))}
                    </Stack>
                );
            }}
        </Field>
    );
};

export interface EnvironmentParametersFieldComponentProps extends ParametersFieldProps {
    description?: string;
    environmentDefinitionName?: string;
}

export const EnvironmentParametersPanelContent: React.FC<EnvironmentParametersFieldComponentProps> = (
    props: EnvironmentParametersFieldComponentProps
) => {
    const { description, environmentDefinitionName, isSubmitting } = props;

    const { formatMessage } = useIntl();

    const hasDescription = !isUndefinedOrWhiteSpace(description);
    const hasEnvironmentDefinitionName = !isUndefinedOrWhiteSpace(environmentDefinitionName);

    return (
        <Stack tokens={containerTokens}>
            {hasDescription && <Stack.Item>{description}</Stack.Item>}
            {hasEnvironmentDefinitionName && (
                <Stack.Item>
                    <ReadOnlyControl
                        label={formatMessage(messages.environmentDefinitionFieldLabel)}
                        value={environmentDefinitionName}
                        ariaLabel={formatMessage(messages.environmentDefinitionFieldLabel)}
                        disabled={isSubmitting}
                    />
                </Stack.Item>
            )}
            <Stack.Item>
                <EnvironmentParametersField {...props} />
            </Stack.Item>
        </Stack>
    );
};
