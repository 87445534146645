import { makeStyles, Panel, PanelType } from '@fluentui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Metric, Property } from '../../../constants/telemetry';
import { useActionCreator } from '../../../hooks/action-creator';
import { useUserSettingsPanelContext } from '../../../hooks/context/panels';
import { setUserSettings } from '../../../redux/actions/user-settings/user-settings-action-creator';
import { getUserSettingsSelector } from '../../../redux/selector/user-settings-selector';
import { ReturnVoid } from '../../../types/return-void';
import { UserSettings } from '../../../types/user-settings';
import { trackMetric } from '../../../utilities/telemetry/channel';
import { UserSettingsFormData } from '../models';
import { getUserSettingsPanelInitialValues, getUserSettingsSetFromFormData } from '../selectors';
import { UserSettingsFormFieldGroup } from './user-settings-form-field-group';
import { UserSettingsPanelFooter } from './user-settings-panel-footer';

type UserSettingsPanelComponentProps = {
    currentUserSettings: UserSettings;
    isOpen: boolean;
    onDismiss: () => void;
    onSubmit: ReturnVoid<typeof setUserSettings>;
};

const messages = defineMessages({
    userSettingsPanelHeaderText: {
        id: 'UserSettingsPanel_Header_Text',
        defaultMessage: 'User settings',
        description: 'Header for the user settings menu',
    },
    userSettingsPanelCloseButtonAriaLabel: {
        id: 'UserSettingsPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for user settings panel close button',
    },
});

/**
 * Styles
 */

const usePanelStyles = makeStyles({
    header: {
        marginBottom: '24px',
    },
    main: {
        marginTop: '48px',
    },
    overlay: {
        backgroundColor: 'transparent',
    },
});

/**
 * END Styles
 */

const UserSettingsPanelComponent: React.FC<UserSettingsPanelComponentProps> = (
    props: UserSettingsPanelComponentProps
) => {
    const { currentUserSettings, isOpen, onDismiss, onSubmit } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const panelStyles = usePanelStyles();

    // Callback hooks
    const onSubmitClicked = React.useCallback(
        (data: UserSettingsFormData) => {
            const desiredSettingChanges: UserSettings = getUserSettingsSetFromFormData(data);
            const { useMultiMonitor, preferredRemoteApp } = desiredSettingChanges;
            trackMetric(Metric.UserSettingsPanelSubmitted, 1, {
                properties: {
                    [Property.MultiMonitor]: useMultiMonitor,
                    [Property.PreferredRemoteApp]: preferredRemoteApp,
                },
            });

            onSubmit(desiredSettingChanges);
            onDismiss();
        },
        [onDismiss, onSubmit]
    );

    // Memoized data
    const initialValues = React.useMemo(
        () => getUserSettingsPanelInitialValues(currentUserSettings),
        [currentUserSettings]
    );

    return (
        <Form<UserSettingsFormData> initialValues={initialValues} onSubmit={onSubmitClicked}>
            {(formProps) => {
                const { form, pristine, valid } = formProps;
                const { reset, submit } = form;

                // Callback hooks
                const onPanelDismissed = () => {
                    onDismiss();
                    reset();
                };

                const onRenderFooterContent = () => (
                    <UserSettingsPanelFooter
                        isSubmitDisabled={pristine || !valid}
                        onCancelClicked={onPanelDismissed}
                        onSubmitClicked={submit}
                    />
                );

                return (
                    <Panel
                        closeButtonAriaLabel={formatMessage(messages.userSettingsPanelCloseButtonAriaLabel)}
                        headerText={formatMessage(messages.userSettingsPanelHeaderText)}
                        isFooterAtBottom
                        isLightDismiss
                        isOpen={isOpen}
                        onDismiss={onPanelDismissed}
                        onRenderFooterContent={onRenderFooterContent}
                        styles={panelStyles}
                        type={PanelType.smallFixedFar}
                    >
                        <UserSettingsFormFieldGroup currentUserSettings={initialValues} />
                    </Panel>
                );
            }}
        </Form>
    );
};

const UserSettingsPanelContainer: React.FC = () => {
    // Application state hooks
    const currentUserSettings = useSelector(getUserSettingsSelector);

    // Action hooks
    const onSubmit = useActionCreator(setUserSettings);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useUserSettingsPanelContext();

    return (
        <UserSettingsPanelComponent
            currentUserSettings={currentUserSettings}
            isOpen={isOpen}
            onDismiss={closePanel}
            onSubmit={onSubmit}
        />
    );
};

export const UserSettingsPanelContextWrapper: React.FC = () => {
    const { isOpen } = useUserSettingsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <UserSettingsPanelContainer />;
};

export default UserSettingsPanelContextWrapper;
