import { SerializableMap } from '../../../../types/serializable-map';
import { CapitalizedObjectNameMap } from '../../../../types/union-map';

export interface AddOrUpdateExpirationFormData {
    enableExpiration: boolean;
    environmentExpiration: EnvironmentExpirationViewModel | undefined;
}

export interface EnvironmentExpirationViewModel {
    expirationTime: Date | undefined;
    expirationCalendarDate: Date | undefined;
    expirationTimeZone: string | undefined;
}

export const AddOrUpdateExpirationFormFieldName: CapitalizedObjectNameMap<AddOrUpdateExpirationFormData> = {
    EnableExpiration: 'enableExpiration',
    EnvironmentExpiration: 'environmentExpiration',
};

export type AddOrUpdateExpirationFormErrorsByField = {
    [key in keyof AddOrUpdateExpirationFormData]?: string | SerializableMap<string> | boolean;
};
