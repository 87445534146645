import { Theme } from '@fluentui/react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getThemeModeSelector } from '../redux/selector/user-settings-selector';
import { CustomThemes, ThemeMode } from '../themes/themes';

export const useCurrentThemeMode = (): Theme => {
    const currentThemeMode = useSelector(getThemeModeSelector);

    // This checks if the state is changed before returning the theme.
    return useMemo(() => {
        switch (currentThemeMode) {
            case ThemeMode.LightMode:
                return CustomThemes.LightMode;
            case ThemeMode.DarkMode:
            default:
                return CustomThemes.DarkMode; // this is if the dark theme is not the default.
        }
    }, [currentThemeMode]);
};

export const useDynamicMakeStyles = <TFunc extends (...args: never[]) => unknown>(
    styleFunction: TFunc,
    ...args: Parameters<TFunc>
): ReturnType<TFunc> => {
    return React.useMemo(() => {
        return styleFunction(...args) as ReturnType<TFunc>;
    }, [styleFunction, ...args]);
};
