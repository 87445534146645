import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { StorageErrorCode } from '../../../constants/storage';
import { setItem } from '../../../data/services/storage';
import { ClientError, FailureOperation, FailureResponse } from '../../../models/common';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import {
    setStorageValue,
    setStorageValueError,
    setStorageValueFailed,
    setStorageValueSuccess,
} from '../../actions/storage/storage-action-creators';
import { SetStorageValueAction } from '../../actions/storage/storage-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getIsSignedIn, getUsername } from '../../selector/identity-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* setStorageValueSaga(action: SetStorageValueAction): SagaIterator {
    const { key, setForSignedInUser, storageType, value } = getPayload(action);

    try {
        // Fail the operation if it's being attempted for a signed-in user but no user is signed in
        const isSignedIn = yield select(getIsSignedIn);

        if (setForSignedInUser && !isSignedIn) {
            const failure = FailureResponse({
                code: StorageErrorCode.NotSignedIn,
                operation: FailureOperation.SetStorageValue,
            });

            yield put(setStorageValueFailed({ failure, key, setForSignedInUser, storageType }));
            yield resolveAction(action, failure);

            return;
        }

        // Fail the operation if it's being attempted for a signed-in user but we can't find a username
        const username = yield select(getUsername);

        if (setForSignedInUser && isUndefinedOrWhiteSpace(username)) {
            const failure = FailureResponse({
                code: StorageErrorCode.InvalidUsername,
                operation: FailureOperation.SetStorageValue,
            });

            yield put(setStorageValueFailed({ failure, key, setForSignedInUser, storageType }));
            yield resolveAction(action, failure);

            return;
        }

        const fullKey = setForSignedInUser ? `${username}_${key}` : key;
        yield call(setItem, storageType, fullKey, value);
        yield put(setStorageValueSuccess({ key, setForSignedInUser, storageType }));
        yield resolveAction(action, { succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SetStorageValue);
        yield put(setStorageValueError({ error, key, setForSignedInUser, storageType }));
        yield rejectAction(action, error);
    }
}

export function* setStorageValueListenerSaga(): SagaIterator {
    yield takeEvery(setStorageValue, setStorageValueSaga);
}
