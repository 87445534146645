import { ContentType, Method } from '../../../constants/http';
import { DataResponse, FailureOperation } from '../../../models/common';
import { GraphDirectoryObjectContract, GraphDirectoryObjectTypeContract } from '../../contracts/graph';
import { graphListRequest } from './graph-request';

export type ListGraphDirectoryObjectsResponse = DataResponse<GraphDirectoryObjectContract[]>;

export const listDirectoryObjectsByIds = async (
    ids: string[],
    types: GraphDirectoryObjectTypeContract[],
    accessToken: string,
    activityId?: string
): Promise<ListGraphDirectoryObjectsResponse> => {
    const path = '/directoryObjects/getByIds';
    const body = JSON.stringify({ ids, types });

    const result = await graphListRequest<GraphDirectoryObjectContract>(path, Method.POST, {
        accept: ContentType.ApplicationJson,
        activityId,
        body,
        contentType: ContentType.ApplicationJson,
        operation: FailureOperation.GetGraphDirectoryObject,
        accessToken,
    });

    return result;
};
