import { SearchParameter } from '../constants/app';
import { ApplicationMode } from '../constants/application-mode';
import { FeatureFlagName } from '../constants/features';
import { tryGetTenantIdFromDevCenterUri } from '../ids/data-plane';
import { getApplicationMode } from './app';
import { isFeatureFlagEnabled } from './features';
import { getSearchParameterFromCurrentLocation } from './url';

export const getSingleDevCenterUriFromCurrentLocation = (): string | undefined =>
    getSearchParameterFromCurrentLocation(SearchParameter.DevCenterUri);

export const getIsSingleDevCenterMode = (): boolean =>
    isFeatureFlagEnabled(FeatureFlagName.SingleDevCenterMode) &&
    getApplicationMode() === ApplicationMode.SingleDevCenter;

/** Returns the tenant id from the requested dev center uri in single dev center mode. Returns `undefined` if not in single dev center mode. */
export const getSingleDevCenterTenantIdFromCurrentLocation = (): string | undefined => {
    const isSingleDevCenterMode = getIsSingleDevCenterMode();

    if (!isSingleDevCenterMode) {
        return undefined;
    }

    const singleDevCenterUri = getSingleDevCenterUriFromCurrentLocation();

    if (!singleDevCenterUri) {
        return undefined;
    }

    return tryGetTenantIdFromDevCenterUri(singleDevCenterUri);
};
