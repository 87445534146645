import { UnionValueMap } from '../types/union-map';

type BatchRequestShortName = 'GetPermissions' | 'ListLocations' | 'ListProjectEnvironmentTypes';

export type BatchRequestName = `DevCenter-DevPortal-${BatchRequestShortName}`;

export const BatchRequestName: UnionValueMap<BatchRequestShortName, BatchRequestName> = {
    GetPermissions: 'DevCenter-DevPortal-GetPermissions',
    ListLocations: 'DevCenter-DevPortal-ListLocations',
    ListProjectEnvironmentTypes: 'DevCenter-DevPortal-ListProjectEnvironmentTypes',
};
