import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { DevBoxSupportPanelProps } from './dev-box-support-panel';

export type DevBoxSupportPanelContextProperties = Omit<
    DevBoxSupportPanelProps,
    'isOpen' | 'onDismiss' | 'statusForDiscoverPoolsForDevBoxes' | 'statusesForListPools'
>;

export const DevBoxSupportPanelContext = React.createContext<
    SurfaceContextWithProperties<DevBoxSupportPanelContextProperties>
>({ isOpen: false });

export const DevBoxSupportPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<DevBoxSupportPanelContextProperties>(DevBoxSupportPanelContext);
