import { isNotUndefinedOrWhiteSpace } from '../../../../../utilities/string';

export const getOptionKeyForIndexedModel = (model: { id: string }): string => model.id;

export const getFinalDisplayNameForProject = (
    displayName: string | undefined,
    isDisplayNameDuplicate: boolean | undefined,
    resourceName: string,
    duplicateMessage: string
): string => {
    let name: string;

    if (isNotUndefinedOrWhiteSpace(displayName) && isDisplayNameDuplicate) {
        name = duplicateMessage;
    } else if (isNotUndefinedOrWhiteSpace(displayName)) {
        name = displayName;
    } else {
        name = resourceName;
    }

    return name;
};
