import { IContextualMenuItem, IIconProps } from '@fluentui/react';
import { MessageDescriptor } from 'react-intl';
import { ClientDownloadLink } from '../../../constants/avd';
import { OperatingSystemFamily } from '../../../constants/browser';
import { sharedMessages } from './messages';

export const getContextualMenuItem = (
    ariaLabel: string,
    key: string,
    onClick: () => void,
    text: string
): IContextualMenuItem => ({
    ariaLabel,
    key,
    onClick,
    text,
});

export const getDefaultDownloadLink = (operatingSystemFamily: OperatingSystemFamily): string => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Android:
            return ClientDownloadLink.Android;
        case OperatingSystemFamily.IOS:
            return ClientDownloadLink.IOS;
        case OperatingSystemFamily.MacOS:
            return ClientDownloadLink.Mac;
        case OperatingSystemFamily.Windows:
        default:
            return ClientDownloadLink.WindowsX64;
    }
};

export const getIconPropsForDownloadPrimaryButton = (
    operatingSystemFamily: OperatingSystemFamily
): IIconProps | undefined => {
    // No icon is used when the OS family is Windows
    if (operatingSystemFamily === OperatingSystemFamily.Windows) {
        return undefined;
    }

    return {
        iconName: 'OpenInNewTab',
    };
};

export const getMessageDescriptorForDownloadPrimaryButton = (
    operatingSystemFamily: OperatingSystemFamily
): MessageDescriptor => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Windows:
            return sharedMessages.windowsPrimaryButton;
        default:
            return sharedMessages.nonWindowsPrimaryButton;
    }
};

export const getMessageDescriptorForDownloadPrimaryButtonAriaLabel = (
    operatingSystemFamily: OperatingSystemFamily
): MessageDescriptor => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Windows:
            return sharedMessages.windowsPrimaryButtonAriaLabel;
        default:
            return sharedMessages.nonWindowsPrimaryButtonAriaLabel;
    }
};

export const getMessageDescriptorForTitle = (operatingSystemFamily: OperatingSystemFamily): MessageDescriptor => {
    switch (operatingSystemFamily) {
        case OperatingSystemFamily.Android:
            return sharedMessages.androidRemoteDesktopTitle;
        case OperatingSystemFamily.IOS:
            return sharedMessages.iOSRemoteDesktopTitle;
        case OperatingSystemFamily.MacOS:
            return sharedMessages.macRemoteDesktopTitle;
        case OperatingSystemFamily.Windows:
        default:
            return sharedMessages.windowsRemoteDesktopTitle;
    }
};
