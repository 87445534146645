import { ActionReducerMapBuilder, PayloadAction, createReducer } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { Status } from '../../models/common';
import { ActionCreator } from '../actions/core-action-creators';
import { ErrorPayload, FailedPayload } from '../actions/core-actions';
import { AsyncState } from '../store/common-state';

interface CreateStatusReducerOptions<
    TErrorPayload extends ErrorPayload = ErrorPayload,
    TFailedPayload extends FailedPayload = FailedPayload,
    TInProgressPayload = void,
    TResetPayload = void,
    TSuccessPayload = void
> {
    additionalCases?: (builder: ActionReducerMapBuilder<Status>) => void;
    error?: ActionCreator<TErrorPayload>;
    failed?: ActionCreator<TFailedPayload>;
    inProgress?: ActionCreator<TInProgressPayload>;
    reset?: ActionCreator<TResetPayload>;
    success?: ActionCreator<TSuccessPayload>;
}

export type StatusReducer = (store: Status | undefined, action: Action) => Status;

const errorReducer = (entry: Status, action: PayloadAction<ErrorPayload>) => {
    entry.failure = action.payload.error;
    entry.state = AsyncState.Error;
};

const failedReducer = (entry: Status, action: PayloadAction<FailedPayload>) => {
    entry.failure = action.payload.failure;
    entry.state = AsyncState.Failed;
};

const inProgressReducer = (entry: Status) => {
    entry.failure = undefined;
    entry.state = AsyncState.InProgress;
};

const resetReducer = (entry: Status) => {
    entry.failure = undefined;
    entry.state = AsyncState.NotStarted;
};

const successReducer = (entry: Status) => {
    entry.failure = undefined;
    entry.state = AsyncState.Success;
};

export const createStatusReducer = <
    TErrorPayload extends ErrorPayload = ErrorPayload,
    TFailedPayload extends FailedPayload = FailedPayload,
    TInProgressPayload = void,
    TResetPayload = void,
    TSuccessPayload = void
>(
    options: CreateStatusReducerOptions<
        TErrorPayload,
        TFailedPayload,
        TInProgressPayload,
        TResetPayload,
        TSuccessPayload
    >
): StatusReducer =>
    createReducer(Status(), (builder) => {
        const { additionalCases, error, failed, inProgress, reset, success } = options;

        if (error) {
            builder.addCase(error, errorReducer);
        }

        if (failed) {
            builder.addCase(failed, failedReducer);
        }

        if (inProgress) {
            builder.addCase(inProgress, inProgressReducer);
        }

        if (reset) {
            builder.addCase(reset, resetReducer);
        }

        if (success) {
            builder.addCase(success, successReducer);
        }

        if (additionalCases) {
            additionalCases(builder);
        }
    });
