import { initializeIcons } from '@fluentui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import ErrorBoundary from './components/error-boundary';
import { initialize as initializeAuthenticationModule } from './data/services/identity';
import { initializeExtendedIcons } from './icons/initialize-extended-icons';
import './index.css';
import { ClientError, FailureOperation } from './models/common';
import { initialize as initializeStore } from './redux/store';
import { isInIframe } from './utilities/browser';
import { initialize as initializeTelemetryChannel, trackException, trackTrace } from './utilities/telemetry/channel';

const initInsideAuthenticationIframe = async (): Promise<void> => {
    await initializeAuthenticationModule();
};

const init = (): void => {
    try {
        initializeTelemetryChannel(); // initializing telemetry.
    } catch (e) {
        console.warn(`Telemetry failed to initialize and will not be available. Error: ${JSON.stringify(e || {})}`);
    }

    // Initialize application store module
    const store = initializeStore();

    // If we're within an iframe, we are currently handling an authentication redirect. Initialize storage + auth code
    // and exit immediately so that we don't attempt to SSO/request a token within the iframe.
    // Note: auth is the only case for now, but we may have other within-iframe scenarios in future. At that time, we
    // may want to more specifically detect that this is the auth iframe.
    if (isInIframe()) {
        trackTrace('Detected load within iframe; initialize storage, auth, and exit.');
        initInsideAuthenticationIframe();
        return;
    }

    // Initialize icons. Recover from but log any errors that occur.
    try {
        initializeIcons();
        initializeExtendedIcons();
    } catch (e) {
        trackException(new ClientError(e, FailureOperation.InitializeIcons));
    }

    const root = document.getElementById('root');

    const content = (
        <ErrorBoundary outsideOfProviders>
            <BrowserRouter>
                <HelmetProvider>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </HelmetProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );

    if (process.env.NODE_ENV === 'development') {
        const searchParams = new URLSearchParams(window.location.search);
        const enableAccessibilityPlugin = searchParams.get('axePlugin') === 'true';
        if (enableAccessibilityPlugin) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const axe = require('@axe-core/react').default;
                axe(React, ReactDOM, 1000);
            } catch (e) {
                console.warn('Unable to load the @axe-core/react plugin.');
            }

            // can't use the axe plugin with React.StrictMode on
            ReactDOM.render(<>{content}</>, root);
            return;
        }
    }

    ReactDOM.render(<React.StrictMode>{content}</React.StrictMode>, root);
};

init();
