import { getTokensFromPoolDataPlaneUri } from '../../../ids/pool';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { DataResponse, FailureOperation } from '../../../models/common';
import { PoolContract } from '../../contracts/pool';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetPoolResponse = DataResponse<PoolContract>;
export type ListPoolsResponse = DataResponse<PoolContract[]>;

export const getPool = async (id: string, accessToken: string, activityId?: string): Promise<GetPoolResponse> => {
    const { devCenter, poolName, projectName } = getTokensFromPoolDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetPool,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getPool(poolName, projectName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.getPool(poolName, projectName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const listPools = async (id: string, accessToken: string, activityId?: string): Promise<ListPoolsResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListPools,

        whenUsingBetaClient: (client) =>
            client.devBoxes.listPools(projectName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.listPools(projectName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};
