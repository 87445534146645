import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';

export type OpenConnectViaAppDialogProperties = {
    remoteDesktopUrl: string | undefined;
    cloudPcConnectionUrl: string | undefined;
};

export const ConnectViaAppDialogContext = React.createContext<
    SurfaceContextWithProperties<OpenConnectViaAppDialogProperties>
>({ isOpen: false });

export const ConnectViaAppDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<OpenConnectViaAppDialogProperties>(ConnectViaAppDialogContext);
