import {
    IContextualMenuItem,
    IContextualMenuItemProps,
    IContextualMenuItemRenderFunctions,
    MessageBarType,
} from '@fluentui/react';
import { ResourceCardErrorSeverity } from './models';

export const getMessageBarTypeForSeverity = (severity: ResourceCardErrorSeverity): number => {
    switch (severity) {
        case ResourceCardErrorSeverity.High:
            return MessageBarType.severeWarning;
        case ResourceCardErrorSeverity.Med:
            return MessageBarType.error;

        case ResourceCardErrorSeverity.Low:
        default:
            return MessageBarType.warning;
    }
};

export const getResourceCardAction = (
    ariaLabel: string,
    iconName: string,
    key: string,
    onClick: () => void,
    text: string,
    onRenderContent?: (
        props: IContextualMenuItemProps,
        defaultRenders: IContextualMenuItemRenderFunctions
    ) => React.ReactNode
): IContextualMenuItem => ({
    ariaLabel,
    iconProps: {
        iconName,
    },
    key,
    onClick,
    text,
    onRenderContent,
});
