import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { clearFeaturesListenerSaga } from './clear-features';
import { disableFeaturesListenerSaga } from './disable-features';
import { enableFeaturesListenerSaga } from './enable-features';
import { initializeFeaturesListenerSaga } from './initialize';
import { setFeaturesListenerSaga } from './set-features';
import { toggleFeaturesListenerSaga } from './toggle-features';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(clearFeaturesListenerSaga),
        fork(disableFeaturesListenerSaga),
        fork(enableFeaturesListenerSaga),
        fork(initializeFeaturesListenerSaga),
        fork(setFeaturesListenerSaga),
        fork(toggleFeaturesListenerSaga),
    ]);
}
