import { ClientError } from '../models/common';
import { isUndefinedOrWhiteSpace } from './string';
import { tryOrDefault } from './try-or-default';

export interface HomeAccountIdParts {
    tenantId: string;
    userObjectId: string;
}

export const parseHomeAccountId = (homeAccountId: string): HomeAccountIdParts => {
    if (isUndefinedOrWhiteSpace(homeAccountId)) {
        throw new ClientError('Home account ID value cannot be undefined or white space');
    }

    const segments = homeAccountId.split('.');

    if (segments.length < 2) {
        throw new ClientError('Home account ID was not in expected format of {tenant-id}.{user-object-id}');
    }

    const [userObjectId, tenantId] = segments;

    if (isUndefinedOrWhiteSpace(tenantId)) {
        throw new ClientError('Tenant ID component of home account ID cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(userObjectId)) {
        throw new ClientError('User object ID component of home account ID cannot be undefined or white space');
    }

    return { tenantId, userObjectId };
};

export const tryParseHomeAccountId = tryOrDefault(parseHomeAccountId);
