import * as React from 'react';
import NumberDropdown from '../../../common/form/dropdown/type-controls/number-dropdown';
import ReadOnlyControl from '../../../common/form/read-only-control';
import NumberTextInput from '../../../common/form/text-input/number-text-input';
import { EnvironmentDefinitionNumberParameterViewModel } from '../models';
import { ParameterTypeControlProps } from './models';

export interface NumberParameterControlProps extends ParameterTypeControlProps<number> {
    parameter: EnvironmentDefinitionNumberParameterViewModel;
}

export const NumberParameterControl: React.FC<NumberParameterControlProps> = (props: NumberParameterControlProps) => {
    const { value, parameter, onChange, ariaLabel, error, disabled } = props;
    const { allowed, required, readOnly, name } = parameter;

    const isDropdownControl = !!allowed;

    const onChangeCallback = React.useCallback(
        (value: number | undefined) => {
            onChange(value);
        },
        [onChange]
    );

    const readOnlyValue = React.useMemo(() => value?.toString(), [value]);

    if (readOnly) {
        return <ReadOnlyControl label={name} value={readOnlyValue} required={required} ariaLabel={ariaLabel} />;
    }

    if (isDropdownControl) {
        return (
            <NumberDropdown
                label={name}
                value={value}
                options={allowed}
                required={required}
                onChange={onChangeCallback}
                ariaLabel={ariaLabel}
                errorMessage={error}
                disabled={disabled}
            />
        );
    }

    return (
        <NumberTextInput
            value={value}
            label={name}
            required={required}
            onChange={onChangeCallback}
            ariaLabel={ariaLabel}
            errorMessage={error}
            disabled={disabled}
        />
    );
};

export default NumberParameterControl;
