import { DefaultButton, FontWeights, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Failure } from '../../models/common';
import FailureMessage from '../common/failure-message/failure-message';
import SubmitButton from '../common/submit-button';

const messages = defineMessages({
    redeployEnvironmentPanelFooterCancelButtonText: {
        id: 'RedeployEnvironmentPanelFooter_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the redeploy environment panel cancel button label',
    },
    redeployEnvironmentPanelFooterCancelButtonAriaLabel: {
        id: 'RedeployEnvironmentPanelFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel button to close the redeploy environment panel',
        description: 'Aria label for the redeploy environment panel cancel button',
    },
    redeployEnvironmentPanelFooterSubmitButtonText: {
        id: 'RedeployEnvironmentPanelFooter_SubmitButton_Text',
        defaultMessage: 'Redeploy',
        description: 'Text for the redeploy environment panel submit button label',
    },
    redeployEnvironmentPanelFooterSubmitButtonAriaLabel: {
        id: 'RedeployEnvironmentPanelFooter_SubmitButton_AriaLabel',
        defaultMessage: 'Redeploy button to submit redeployment',
        description: 'Aria label for the redeploy environment panel submit button',
    },
});

/**
 * Style Section
 */

const useCancelButtonStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
    },
});

const useSubmitButtonStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
        minWidth: 67,
    },
});

const footerStackContainerToken: IStackTokens = {
    childrenGap: 30,
};

const buttonContainerTokens: IStackTokens = {
    childrenGap: 8,
};

/* END */

interface RedeployEnvironmentPanelFooterProps {
    isErrorOrFailedState: boolean;
    isSubmitting: boolean;
    isValid: boolean;
    failure?: Failure;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
}

interface RedeployEnvironmentFailureMessageProps {
    failure: Failure | undefined;
}

const RedeployEnvironmentFailureMessage: React.FC<RedeployEnvironmentFailureMessageProps> = (
    props: RedeployEnvironmentFailureMessageProps
) => {
    const { failure } = props;

    return (
        <Stack>
            <FailureMessage failure={failure}>
                <FormattedMessage
                    id="RedeployEnvironmentPanelFooter_GeneralErrorMessage_Text"
                    defaultMessage="Failed to redeploy environment."
                    description="Text informing users that redeploying their environment failed."
                />
            </FailureMessage>
        </Stack>
    );
};

export const RedeployEnvironmentPanelFooter: React.FC<RedeployEnvironmentPanelFooterProps> = (
    props: RedeployEnvironmentPanelFooterProps
) => {
    const { failure, isErrorOrFailedState, isSubmitting, isValid, onCancelClicked, onSubmitClicked } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const cancelButtonStyles = useCancelButtonStyles();
    const submitButtonStyles = useSubmitButtonStyles();

    return (
        <Stack verticalFill tokens={footerStackContainerToken}>
            {isErrorOrFailedState && failure && (
                <Stack.Item>
                    <RedeployEnvironmentFailureMessage failure={failure} />
                </Stack.Item>
            )}

            <Stack.Item>
                <Stack horizontal tokens={buttonContainerTokens} verticalFill verticalAlign="center">
                    <Stack.Item grow={0}>
                        <SubmitButton
                            text={formatMessage(messages.redeployEnvironmentPanelFooterSubmitButtonText)}
                            ariaLabel={formatMessage(messages.redeployEnvironmentPanelFooterSubmitButtonAriaLabel)}
                            styles={submitButtonStyles}
                            isSubmitting={isSubmitting}
                            onClick={onSubmitClicked}
                            disabled={!isValid || isSubmitting}
                        />
                    </Stack.Item>

                    <Stack.Item grow={0}>
                        <DefaultButton
                            text={formatMessage(messages.redeployEnvironmentPanelFooterCancelButtonText)}
                            ariaLabel={formatMessage(messages.redeployEnvironmentPanelFooterCancelButtonAriaLabel)}
                            styles={cancelButtonStyles}
                            onClick={onCancelClicked}
                            disabled={isSubmitting}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default RedeployEnvironmentPanelFooter;
