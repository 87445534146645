import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Severity } from '../../constants/telemetry';
import { createProjectDataPlaneUri, tryGetDevCenterDataPlaneUriFromProject } from '../../ids/project';
import { Entity } from '../../models/common';
import { ProjectResource } from '../../models/project';
import { KeyValuePair } from '../../types/key-value-pair';
import { compact } from '../../utilities/array';
import { getKey, getValue } from '../../utilities/key-value-pair';
import { tryNormalizeResource } from '../../utilities/resource-manager/normalize-resource';
import { trackTrace } from '../../utilities/telemetry/channel';
import {
    listProjects,
    listProjectsError,
    listProjectsFailed,
    listProjectsSuccess,
} from '../actions/project/project-action-creators';
import { loadControlPlaneResourcesForHomeSuccess } from '../actions/sub-applications/home/home-action-creators';
import { stringAdapter } from '../adapters/common/string-adapter';
import { projectResourceAdapter, projectsFromDiscoveryServiceAdapter } from '../adapters/project-adapters';
import { ProjectDataStore, ProjectStatusStore, ProjectStore } from '../store/project-store';
import { getPayload } from '../utilities/payload-action';
import { createStatusReducer } from './status-reducer';

const filterToUsableProjects = (projects: ProjectResource[]): KeyValuePair<string, Entity<ProjectResource>>[] => {
    const normalizedProjectEntities = projects.map((project) => {
        const data = tryNormalizeResource(project);

        if (data === undefined) {
            trackTrace('Unexpected state: an error occurred when normalizing project.', {
                properties: { resource: JSON.stringify(project) },
                severity: Severity.Warning,
            });

            return undefined;
        }

        // Calculate Data Plane ID for dev center from project
        const devCenter = tryGetDevCenterDataPlaneUriFromProject(data);

        if (!devCenter) {
            trackTrace('Unexpected state: an error occurred when calculating dev center URI for project resource.', {
                properties: { project: JSON.stringify(project) },
                severity: Severity.Warning,
            });

            return undefined;
        }

        const { name } = data;
        const id = createProjectDataPlaneUri({ devCenter, projectName: name });

        // Key of KV pair -> project's Data Plane ID
        // ID in entity -> project's Control Plane ID
        return KeyValuePair(id, Entity(data.id, data));
    });

    // Filter out any undefined results
    return compact(normalizedProjectEntities);
};

export const projectReducer = combineReducers<ProjectStore>({
    data: createReducer(ProjectDataStore(), (builder) => {
        builder.addCase(loadControlPlaneResourcesForHomeSuccess, (store, action) => {
            const { result } = getPayload(action);

            // Normalize and filter to valid projects
            const projectPairs = filterToUsableProjects(result);

            const idPairs = projectPairs.map((entity) => {
                const [dataPlaneId, project] = entity;
                const { id: resourceId } = project;

                return KeyValuePair(resourceId, dataPlaneId);
            });

            // Update data for projects
            projectResourceAdapter.setMany(store.projects, projectPairs.map(getValue));

            // Update resource ID <-> Data Plane ID mappings
            stringAdapter.setMany(
                store.dataPlaneIdsToResourceIds,
                idPairs.map((pair) => Entity(getValue(pair), getKey(pair)))
            );

            stringAdapter.setMany(
                store.resourceIdsToDataPlaneIds,
                idPairs.map((pair) => Entity(getKey(pair), getValue(pair)))
            );
        });

        builder.addCase(listProjectsSuccess, (store, action) => {
            const { result } = getPayload(action);

            projectsFromDiscoveryServiceAdapter.setMany(
                store.projectsFromDiscoveryService,
                result.map((project) => Entity(project.uri, project))
            );
        });
    }),

    status: combineReducers<ProjectStatusStore>({
        listProjectsFromDiscoveryService: createStatusReducer({
            inProgress: listProjects,
            error: listProjectsError,
            failed: listProjectsFailed,
            success: listProjectsSuccess,
        }),
    }),
});
