import { makeStyles } from '@fluentui/react';
import * as React from 'react';

/**
 * Style Section
 */
const useMicrosoftLogoIconStyles = makeStyles({
    // Note: currentColor will allow the SVG to inherit the color of the parent <i /> element.
    path: {
        fill: 'currentColor',
    },
    root: {
        color: 'currentColor',
    },
});

const MicrosoftLogoIcon: React.FC = () => {
    // Style hooks
    const classNames = useMicrosoftLogoIconStyles();
    return (
        <svg className={classNames.root} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <path className={classNames.path} d="M7 7H0V0H7V7ZM15 7H8V0H15V7ZM7 15H0V8H7V15ZM15 15H8V8H15V15Z" />
        </svg>
    );
};

export default MicrosoftLogoIcon;
