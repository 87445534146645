import { createTheme, FontSizes, NeutralColors, Theme } from '@fluentui/react';
import { AppPalette } from '../app-palette';
import { IAppSemanticColors } from '../app-semantic-colors';
import { FontStyles } from '../constants/font-styles';
import { IExtendedSemanticColors } from '../extended-semantic-colors';
import { DefaultStyleSettings } from '../style-settings';

// Refer to the readme before making changes to this file for more details on semantic slots and how they are setup.
// Note: Palette colors are what make up a theme, they applied to different semantic slots. These should not be changed.
//      This palette comes from the standard fluent dark theme: https://github.com/microsoft/fluentui/blob/789a3733b128569190319fce3fe2b46900b24896/packages/theme-samples/src/DarkCustomizations/DarkCustomizations.ts
const darkThemePalette: AppPalette = {
    black: NeutralColors.white,
    neutralDark: NeutralColors.gray10,
    neutralLight: NeutralColors.gray170,
    neutralLighter: NeutralColors.gray180,
    neutralLighterAlt: NeutralColors.gray190,
    neutralPrimary: NeutralColors.gray20,
    neutralPrimaryAlt: NeutralColors.gray60,
    neutralQuaternary: NeutralColors.gray150,
    neutralQuaternaryAlt: NeutralColors.gray160,
    neutralSecondary: NeutralColors.gray90,
    neutralSecondaryAlt: '#979693',
    neutralTertiary: NeutralColors.gray120,
    neutralTertiaryAlt: NeutralColors.gray140,
    redDark: '#F1707B',
    greenDark: '#92c353',
    themeDark: '#6cb8f6',
    themeDarkAlt: '#3aa0f3',
    themeLight: '#004c87',
    themeLighter: '#043862',
    themeLighterAlt: '#092c47',
    themePrimary: '#2899f5',
    themeSecondary: '#0078d4',
    themeTertiary: '#235a85',
    white: NeutralColors.gray200,
    themeDarker: '#82c7ff',
    yellowDark: '#FCE100',
};

// App colors for different custom semantic slots for the app
const appSemanticColors: IAppSemanticColors = {
    actionBarBorder: darkThemePalette.neutralQuaternary,
    addDevBoxPanelPoolOptionMetaDataText: darkThemePalette.neutralSecondary,
    addDevBoxPanelFooterErrorIcon: darkThemePalette.redDark,
    addDevBoxPanelFooterErrorText: darkThemePalette.redDark,
    addEnvironmentPanelFooterErrorIcon: darkThemePalette.redDark,
    addEnvironmentPanelFooterErrorText: darkThemePalette.redDark,
    commandBarButtonIcon: darkThemePalette.black,
    confirmationText: darkThemePalette.greenDark,
    downloadRemoteDesktopCardBackground: darkThemePalette.neutralLight,
    downloadRemoteDesktopCardImageBorder: darkThemePalette.black,
    errorMessageIcon: darkThemePalette.redDark,
    errorMessageText: darkThemePalette.redDark,
    successMessageIcon: '#67D48B',
    expandErrorIcon: darkThemePalette.black,
    footerBackground: darkThemePalette.neutralLight,
    footerLinkText: darkThemePalette.black,
    helpMenuPanelPrivacyTermsDivider: darkThemePalette.neutralSecondary,
    helpMenuPanelPrivacyTermsText: darkThemePalette.neutralTertiary,
    iconButtonBorderFocused: darkThemePalette.neutralSecondary,
    iconButtonText: darkThemePalette.black,
    iconButtonTextHovered: '#3AA0F3',
    iconButtonTextPressed: '#6CB8F6',
    meControlSubmenuBackground: darkThemePalette.neutralLighter,
    meControlSubmenuText: darkThemePalette.black,
    metadataItemHighlightedTextColor: darkThemePalette.themePrimary,
    openWindowsDesktopClientOptionCardBackground: darkThemePalette.neutralLight,
    openWindowsDesktopClientOptionCardBackgroundHovered: darkThemePalette.neutralQuaternaryAlt,
    openWindowsDesktopClientOptionCardIcon: darkThemePalette.themePrimary,
    panelBackground: darkThemePalette.neutralLighter,
    personaCoinInitialsColor: darkThemePalette.black,
    personaCoinInitialsColorUnknown: darkThemePalette.redDark,
    quickActionCardBackground: darkThemePalette.neutralLighterAlt,
    quickActionCardIcon: darkThemePalette.themePrimary,
    quickActionCardStripeBackground: darkThemePalette.themePrimary,
    resourceCardBackground: darkThemePalette.neutralLighterAlt,
    selectDevBoxTypeDialogBackground: darkThemePalette.neutralLighter,
    selectEnvironmentDefinitionDialogBackground: darkThemePalette.neutralLighter,
    titleBarBackground: darkThemePalette.neutralLight,
    documentCardBackground: darkThemePalette.neutralQuaternaryAlt,
    dropdownDividerBackground: darkThemePalette.neutralTertiaryAlt,
    infoMessageBarBackground: darkThemePalette.neutralQuaternary,
    infoMessageBarColor: darkThemePalette.black,
    infoMessageBarIconColor: darkThemePalette.black,
    warningMessageText: '#FFDD87',
    warningMessageIcon: '#FFDD87',
    disabledText: darkThemePalette.neutralTertiary,
    inlineErrorMessageText: darkThemePalette.redDark,
    /* Begin message bar colors */
    // Consciously breaking from dark theme palette pattern for message bar buttons styling - these sit on a "light theme" message bar and so do not use our dark theme palette.
    messageBarButton: NeutralColors.gray200,
    messageBarButtonBackground: NeutralColors.white,
    messageBarButtonBackgroundHovered: NeutralColors.gray20,
    messageBarButtonBackgroundPressed: NeutralColors.gray30,
    messageBarButtonHovered: NeutralColors.gray190,
    messageBarButtonPressed: NeutralColors.gray190,
    /* End message bar colors */
    tagBackgroundColor: '#373277',
    customizationDetailsPanelInfoText: NeutralColors.gray80,
    collapsedCustomizationTaskBackground: darkThemePalette.neutralLight,
    expandedCustomizationTaskBackground: darkThemePalette.neutralQuaternaryAlt,
    expandedCustomizationTaskText: darkThemePalette.neutralPrimaryAlt,
    expandIcon: '#919191',
    activeExpandIcon: darkThemePalette.black,
    customizationTaskTimeText: darkThemePalette.neutralTertiary,
    queuedIcon: '#404040',
    skippedIcon: '#919191',
    downloadButtonText: darkThemePalette.themeDarkAlt,
    separator: darkThemePalette.neutralQuaternary,
    transparentBackground: 'transparent',
};

// Fluent's extended semantic colors
const extendedSemanticColors: Partial<IExtendedSemanticColors> = {
    commandBarButtonBackgroundHover: '#004C87',
    commandBarButtonBackgroundSelected: NeutralColors.gray130,
    commandBarButtonBackgroundSelectedHover: NeutralColors.gray130,
    buttonText: darkThemePalette.black,
    buttonTextPressed: darkThemePalette.neutralDark,
    buttonTextHovered: darkThemePalette.neutralPrimary,
    buttonBackgroundHovered: '#3B3A39',
    buttonBackgroundPressed: darkThemePalette.neutralLight,
    disabledBackground: darkThemePalette.neutralQuaternaryAlt,
    inputBackgroundChecked: darkThemePalette.themePrimary,
    menuBackground: darkThemePalette.neutralLight,
    menuItemBackgroundHovered: darkThemePalette.neutralQuaternaryAlt,
    menuItemBackgroundPressed: darkThemePalette.neutralQuaternary,
    menuDivider: darkThemePalette.neutralTertiaryAlt,
    menuIcon: darkThemePalette.themeDarkAlt,
    menuHeader: darkThemePalette.black,
    menuItemText: darkThemePalette.neutralPrimary,
    menuItemTextHovered: darkThemePalette.neutralDark,
    link: '#3AA0F3',
};

export const DarkTheme: Theme = createTheme({
    fonts: {
        medium: {
            fontFamily: FontStyles.fontFamily,
            fontSize: FontSizes.size14,
        },
        large: {
            fontSize: FontSizes.size16,
        },
    },
    palette: darkThemePalette,
    semanticColors: { ...extendedSemanticColors, ...appSemanticColors },
});

DarkTheme.components = DefaultStyleSettings(DarkTheme);
