import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { DelayShutdownDialogComponentProps } from './delay-shutdown-dialog';

export type DelayShutdownDialogProperties = Omit<DelayShutdownDialogComponentProps, 'hidden' | 'onDismiss'>;

export const DelayShutdownDialogContext = React.createContext<
    SurfaceContextWithProperties<DelayShutdownDialogProperties>
>({ isOpen: false });

export const DelayShutdownDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<DelayShutdownDialogProperties>(DelayShutdownDialogContext);
