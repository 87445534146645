import { FontSizes, IStackTokens, Link, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Language, SupportedMarket } from '../../constants/localization';
import { SupportSectionLinks } from '../../constants/support-section-links';
import messages from '../../language/messages';
import { getLanguage, getMarket } from '../../redux/selector/localization-selectors';
import { getSemanticColor } from '../../utilities/styles';

interface FooterComponentProps {
    currentLanguage: Language;
    currentMarket: string;
}

/**
 * Style Section
 */
const useFooterStyles = makeStyles((theme) => ({
    container: {
        height: 'fit-content',
        backgroundColor: getSemanticColor(theme, 'footerBackground'),
        fontSize: FontSizes.size12,
        lineHeight: '14px',
    },
    linkContainer: {
        paddingTop: '7px',
        paddingBottom: '7px',
        marginRight: '20px',
    },
}));

const useFooterLinkStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'footerLinkText'),
    },
}));

const useMicrosoftCopyrightStyles = makeStyles({
    root: {
        marginRight: '24px',
    },
});

const stackTokens: IStackTokens = {
    childrenGap: 24,
};

/* END */

const FooterComponent: React.FC<FooterComponentProps> = React.memo((props: FooterComponentProps) => {
    const { currentLanguage, currentMarket } = props;

    // Style hooks
    const footerStyles = useFooterStyles();
    const footerLinkStyles = useFooterLinkStyles();
    const microsoftCopyrightStyles = useMicrosoftCopyrightStyles();

    // Memoization hooks
    const currentDate = React.useMemo(() => new Date(), []);

    // For Korean language and market, we have to a different and very specific string for this link.
    // More info here: https://liquid.microsoft.com/Web/Object/Read/MS.Privacy.MPS/Requirements/03.03.01.03
    const privacyLinkMessageDescriptor =
        currentLanguage === Language.KO || currentMarket === SupportedMarket.KR
            ? messages.privacyKoreanLanguageLinkText
            : messages.privacyLinkText;

    return (
        <Stack role="contentinfo" horizontal grow className={footerStyles.container} horizontalAlign="end">
            <Stack.Item align="end">
                <Stack horizontal tokens={stackTokens} className={footerStyles.linkContainer}>
                    <Stack.Item styles={microsoftCopyrightStyles}>
                        &copy;
                        <FormattedDate value={currentDate} year="numeric" /> Microsoft
                    </Stack.Item>
                    <Stack.Item>
                        <Link href={SupportSectionLinks.Privacy} target="_blank" styles={footerLinkStyles}>
                            <FormattedMessage {...privacyLinkMessageDescriptor} />
                        </Link>
                    </Stack.Item>
                    <Stack.Item>
                        <Link href={SupportSectionLinks.ProductTerms} target="_blank" styles={footerLinkStyles}>
                            <FormattedMessage {...messages.productTermsLinkText} />
                        </Link>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});

export const FooterContainer: React.FC = () => {
    // Application state hooks
    const language = useSelector(getLanguage);
    const market = useSelector(getMarket);

    return <FooterComponent currentLanguage={language} currentMarket={market} />;
};

export default FooterContainer;
