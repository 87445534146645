import { createSelector } from '@reduxjs/toolkit';
import { DismissableContentName } from '../../constants/app';
import { getDismissedMessages } from '../../redux/selector/application-selectors';
import { StoreStateSelector } from '../../redux/selector/common';
import {
    ActionAbility,
    CannotCreateDevBoxReason,
    CannotCreateEnvironmentReason,
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/models';
import { DisplayState } from '../../redux/selector/display/display-state';
import { ResourceUserState } from '../../redux/selector/display/user-state/models';
import { has } from '../../utilities/serializable-set';
import { CombinedInfoMessageKind } from './models';

export const getCombinedInfoMessageKind = (
    devBoxCreateAbilityState: DevBoxCreateAbilityState,
    devBoxUserState: ResourceUserState,
    environmentCreateAbilityState: EnvironmentCreateAbilityState,
    environmentUserState: ResourceUserState
): CombinedInfoMessageKind => {
    const cannotCreateDevBoxesOrEnvironments =
        devBoxCreateAbilityState.createAbility === ActionAbility.CannotPerformAction &&
        environmentCreateAbilityState.createAbility === ActionAbility.CannotPerformAction;

    const hasNoAuthorizedProjects =
        devBoxCreateAbilityState.reason === CannotCreateDevBoxReason.HasNoAuthorizedProjects &&
        environmentCreateAbilityState.reason === CannotCreateEnvironmentReason.HasNoAuthorizedProjects;

    const hasNoCreationResources =
        devBoxCreateAbilityState.reason === CannotCreateDevBoxReason.NoAuthorizedProjectsHavePools &&
        environmentCreateAbilityState.reason === CannotCreateEnvironmentReason.NoAuthorizedProjectsHaveResources;

    const isUser = devBoxUserState === ResourceUserState.IsUser && environmentUserState === ResourceUserState.IsUser;

    if (isUser && cannotCreateDevBoxesOrEnvironments && hasNoAuthorizedProjects) {
        return CombinedInfoMessageKind.NoEnvironmentOrDevBoxWritePermissionsMessage;
    }

    if (isUser && cannotCreateDevBoxesOrEnvironments && hasNoCreationResources) {
        return CombinedInfoMessageKind.NoCreateResourcesForDevBoxesOrEnvironmentsMessage;
    }

    return CombinedInfoMessageKind.None;
};

export const getIsPortalSurveyBannerDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.PortalSurveyInfoBanner)
);

// If we are displaying a full page misconfiguration state, this full page display will cover all errors and we should not show the message bar
// (Should also not show message bar while loading, as loading could result full page misconfiguration state)
export const shouldHideInfoMessageBar = (displayState: DisplayState): boolean =>
    displayState === DisplayState.FullScreenMisconfiguredState ||
    displayState === DisplayState.HasNoProjects ||
    displayState === DisplayState.GuestAccount ||
    displayState === DisplayState.Loading;
