import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { FeatureFlagName } from '../../../../constants/features';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedFailure,
    AggregatedResult,
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
    isFailureResponse,
} from '../../../../models/common';
import { ProjectFromDiscoveryService, ProjectResource } from '../../../../models/project';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { isFeatureFlagEnabled } from '../../../../utilities/features';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { discoverDevBoxesInTenant } from '../../../actions/dev-box/dev-box-action-creators';
import { warmAllDevCenterNameRecords } from '../../../actions/dev-center/dev-center-action-creators';
import { discoverEnvironmentsInTenant } from '../../../actions/environment/environment-action-creators';
import { discoverPermissionsForProjects } from '../../../actions/permission/permission-action-creators';
import { listProjects } from '../../../actions/project/project-action-creators';
import {
    loadControlPlaneResourcesForHome,
    loadResourcesForHome,
    loadResourcesForHomeError,
    loadResourcesForHomeFailed,
    loadResourcesForHomeSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadResourcesForHomeAction } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { putAndSettle } from '../../../effects/put-and-settle';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';

function* terminateWithFailure(
    action: LoadResourcesForHomeAction,
    aggregatedFailure: AggregatedFailure,
    startTime: Date
): SagaIterator {
    const { failures } = aggregatedFailure;
    const failure = aggregateFailureResponses(FailureOperation.LoadResourcesForHome, ...failures);
    yield put(loadResourcesForHomeFailed({ failure }));
    yield resolveAction(action, aggregatedFailure);
    yield call(trackTimedPerformanceMetric, PerformanceMetric.LoadResourcesForHome, startTime, AsyncOutcome.Failed, {
        errorCodes: getErrorCodes(aggregatedFailure),
    });
}

function* loadProjectsAndAbilities(): SagaIterator {
    // This call currently only works with mocks, as the discovery service is not yet available
    const result: DataResponse<ProjectFromDiscoveryService> = yield putAndAwait(listProjects());

    if (isFailureResponse(result)) {
        return AggregatedFailure(result);
    }

    return result;
}

export function* loadProjectsAndPermissions(): SagaIterator<AggregatedResult> {
    const projectsResult: DataResponse<ProjectResource[]> = yield putAndAwait(loadControlPlaneResourcesForHome());

    if (isFailureResponse(projectsResult)) {
        return AggregatedFailure(projectsResult);
    }

    const permissionsResult: AggregatedResult = yield putAndAwait(discoverPermissionsForProjects());

    if (isAggregatedFailure(permissionsResult)) {
        return permissionsResult;
    }

    return combineResults([projectsResult, permissionsResult]);
}

export function* loadResourcesForHomeSaga(action: LoadResourcesForHomeAction): SagaIterator {
    const startTime = new Date();

    try {
        const projectsResult: AggregatedResult = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
            ? yield call(loadProjectsAndAbilities)
            : yield call(loadProjectsAndPermissions);

        if (isAggregatedFailure(projectsResult)) {
            yield call(terminateWithFailure, action, projectsResult, startTime);
            return;
        }

        // pre-warm our dev center DNS records (projects for failed dev centers are filtered out)
        yield putAndSettle(warmAllDevCenterNameRecords());

        // Discover existing Data Plane resources that will be rendered on the foreground of the home page
        const [devBoxResult, environmentResult]: AggregatedResult[] = yield all([
            putAndAwait(discoverDevBoxesInTenant()),
            putAndAwait(discoverEnvironmentsInTenant()),
        ]);

        if (isAggregatedFailure(devBoxResult)) {
            yield call(terminateWithFailure, action, devBoxResult, startTime);
            return;
        }

        if (isAggregatedFailure(environmentResult)) {
            yield call(terminateWithFailure, action, environmentResult, startTime);
            return;
        }

        const completeResult = combineResults([projectsResult, devBoxResult, environmentResult]);

        yield put(loadResourcesForHomeSuccess());
        yield resolveAction(action, completeResult);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadResourcesForHome,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadResourcesForHome);
        yield put(loadResourcesForHomeError({ error }));
        yield rejectAction(action, error);
        yield call(trackTimedPerformanceMetric, PerformanceMetric.LoadResourcesForHome, startTime, AsyncOutcome.Error, {
            errorCodes: [error.code],
        });
    }
}

export function* loadResourcesForHomeListenerSaga(): SagaIterator {
    yield takeLeading(loadResourcesForHome, loadResourcesForHomeSaga);
}
