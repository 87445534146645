import {
    CheckboxVisibility,
    DetailsList,
    DetailsListLayoutMode,
    ICalloutProps,
    IColumn,
    IDetailsHeaderProps,
    IObjectWithKey,
    ISelection,
    IStackTokens,
    makeStyles,
    Selection,
    SelectionMode,
    Stack,
    TextField,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useFormatter } from '../../../hooks/localization';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { PoolKey, PoolViewModel } from '../models';
import { selectDevBoxPoolDetailsListMessages } from './messages';
import { PoolDetailsListItem } from './models';
import {
    formatCpuCount,
    formatDiskSizeInGb,
    formatHibernation,
    formatLastImageUpdate,
    formatMemoryInGb,
    getPoolDetailsListColumns,
    getPoolDetailsListItems,
    getPoolViewModelKey,
} from './selectors';

interface SelectDevBoxPoolDetailsListProps {
    pools: PoolViewModel[];
    selectedPool: PoolViewModel | undefined;
    setSelectedPool: (item: PoolViewModel | undefined) => void;
}

/**
 * Style Section
 */

const useFilterTextFieldStyles = makeStyles({
    root: {
        width: '320px',
    },
});

const useDetailsListContainerStyles = makeStyles({
    root: {
        minHeight: '509px',
        maxHeight: '509px',
        overflowY: 'auto',
    },
});

const useDetailsListHeaderStyles = makeStyles({
    root: {
        paddingTop: 0,
    },
});

const useTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const containerTokens: IStackTokens = {
    childrenGap: 51,
};

/* END */

const tooltipCalloutProps: ICalloutProps = { gapSpace: 0 };

export const SelectDevBoxPoolDetailsList: React.FC<SelectDevBoxPoolDetailsListProps> = (
    props: SelectDevBoxPoolDetailsListProps
) => {
    const { pools, selectedPool, setSelectedPool } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const detailsListContainerStyles = useDetailsListContainerStyles();
    const filterTextFieldStyles = useFilterTextFieldStyles();
    const headerStyles = useDetailsListHeaderStyles();
    const tooltipHostStyles = useTooltipHostStyles();

    // State hooks
    const [filterText, setFilterText] = React.useState<string>('');
    const [isSortedDescending, setIsSortedDescending] = React.useState<boolean>(false);
    const [sortKey, setSortKey] = React.useState<PoolKey>(PoolKey.Name);

    // Formatter hooks
    const cpuCountFormatter = useFormatter(formatCpuCount);
    const diskSizeInGbFormatter = useFormatter(formatDiskSizeInGb);
    const lastImageUpdateFormatter = useFormatter(formatLastImageUpdate);
    const memoryInGbFormatter = useFormatter(formatMemoryInGb);
    const hibernationFormatter = useFormatter(formatHibernation);

    // Callback hooks
    const onRenderItem = React.useCallback(
        (item: PoolDetailsListItem, _index?: number, column?: IColumn): JSX.Element | null => {
            if (!column) {
                return null;
            }

            const { key } = column;
            const { columns } = item;
            const value = columns[key as PoolKey];

            if (isUndefinedOrWhiteSpace(value)) {
                return <span>--</span>;
            }

            switch (key) {
                // Wrap longer text fields in tooltips
                case PoolKey.ImageName:
                case PoolKey.Name:
                    return (
                        <TooltipHost
                            calloutProps={tooltipCalloutProps}
                            content={value}
                            overflowMode={TooltipOverflowMode.Self}
                            styles={tooltipHostStyles}
                        >
                            <span>{value}</span>
                        </TooltipHost>
                    );
                default:
                    return <span>{value}</span>;
            }
        },
        [tooltipHostStyles]
    );

    const onFilterPoolsChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
            setFilterText(newValue ?? '');
        },
        []
    );

    const onColumnClick = React.useCallback(
        (_ev: unknown, column: IColumn): void => {
            const { key: clickedColumnKey } = column;

            // If sorted column is changing, ensure it's initially sorted ascending
            setIsSortedDescending(clickedColumnKey === sortKey ? !isSortedDescending : false);
            setSortKey(clickedColumnKey as PoolKey);
        },
        [sortKey, isSortedDescending]
    );

    const onSelectedPoolChange = React.useCallback(
        (selectedItems: PoolDetailsListItem[]): void => {
            setSelectedPool(selectedItems[0]?.value);
        },
        [setSelectedPool]
    );

    const onRenderDetailsHeader = React.useCallback(
        (
            headerProps?: IDetailsHeaderProps,
            defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
        ): JSX.Element | null => {
            if (!headerProps || !defaultRender) {
                return null;
            }

            return defaultRender({
                ...headerProps,
                styles: headerStyles,
            });
        },
        [headerStyles]
    );

    // Memo hooks

    // While hibernate is behind a feature flag, we only want to display the hibernate column if some pool has hibernate.
    // Remove this once hibernate is no longer behind a feature flag.
    // (Task #1747353 to remove feature flag when hibernate/hibernated states are implemented)
    const hasPoolWithHibernate: boolean = React.useMemo(() => pools.some((pool) => pool.supportsHibernate), [pools]);

    const columns: IColumn[] = React.useMemo(
        () =>
            getPoolDetailsListColumns(
                isSortedDescending,
                formatMessage,
                sortKey,
                onColumnClick,
                onRenderItem,
                hasPoolWithHibernate
            ),
        [sortKey, formatMessage, isSortedDescending, onColumnClick, onRenderItem, hasPoolWithHibernate]
    );

    const items: PoolDetailsListItem[] = React.useMemo(
        () =>
            getPoolDetailsListItems(
                sortKey,
                isSortedDescending,
                filterText,
                cpuCountFormatter,
                diskSizeInGbFormatter,
                lastImageUpdateFormatter,
                memoryInGbFormatter,
                hibernationFormatter,
                pools
            ),
        [
            cpuCountFormatter,
            diskSizeInGbFormatter,
            filterText,
            isSortedDescending,
            lastImageUpdateFormatter,
            memoryInGbFormatter,
            pools,
            sortKey,
            hibernationFormatter,
        ]
    );

    const selection: ISelection<PoolDetailsListItem> = React.useMemo(() => {
        return new Selection<PoolDetailsListItem>({
            onSelectionChanged: () => {
                onSelectedPoolChange(selection.getSelection());
            },
        });
    }, [onSelectedPoolChange]);

    // keeping the selection state in sync with our form state on first load
    React.useEffect(() => {
        if (selectedPool !== undefined) {
            selection.setKeySelected(getPoolViewModelKey(selectedPool), true, false);
        }
    }, []);

    return (
        <Stack tokens={containerTokens}>
            <Stack.Item>
                <TextField
                    placeholder={formatMessage(selectDevBoxPoolDetailsListMessages.filterInputPlaceholder)}
                    ariaLabel={formatMessage(selectDevBoxPoolDetailsListMessages.filterInputAriaLabel)}
                    autoFocus
                    onChange={onFilterPoolsChange}
                    styles={filterTextFieldStyles}
                />
            </Stack.Item>
            <Stack.Item styles={detailsListContainerStyles}>
                <DetailsList
                    items={items}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    setKey="single"
                    selectionPreservedOnEmptyClick={true}
                    // Need this type coercion because DetailsLists don't support generics and while a PoolDetailsListItem is an IObjectWithKey an IObjectWithyKey is not a PoolDetailsListItem
                    selection={selection as any as ISelection<IObjectWithKey>} // eslint-disable-line @typescript-eslint/no-explicit-any
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                />
            </Stack.Item>
        </Stack>
    );
};

export default SelectDevBoxPoolDetailsList;
