import {
    Callout,
    DirectionalHint,
    FocusZone,
    FontSizes,
    ICalloutPositionedInfo,
    IDropdownProps,
    IIconProps,
    ITextFieldProps,
    ITextFieldStyles,
    Label,
    Shimmer,
    TextField,
} from '@fluentui/react';
import * as React from 'react';
import { FluentIconNames } from '../../fluent-icon-names';
import { DefaultRenderFunction } from '../../types';
import { PathTree, PathTreeProps } from './path-tree';

export interface PathDropdownProps
    extends PathTreeProps,
        Pick<IDropdownProps, 'label' | 'required' | 'disabled' | 'placeholder' | 'ariaLabel'> {
    onChange: (value: string) => void;
    paths: string[];
    selectedPath?: string;
    isLoading?: boolean;
}

const textFieldStyles: Partial<ITextFieldStyles> = { icon: { fontSize: FontSizes.small, pointerEvents: 'none' } };

const stopPropagationOnLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => event.stopPropagation();

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & React.RefAttributes<HTMLInputElement>;

// Overriding the default label render to prevent default onClick handling
// (clicking on the label triggers a click event on the textfield, which breaks our callout open/close handling)
const onRenderLabel = (renderLabelProps?: ITextFieldProps, _defaultRender?: DefaultRenderFunction<ITextFieldProps>) => {
    if (!renderLabelProps?.label) {
        return <></>;
    }

    return (
        <Label
            required={renderLabelProps.required}
            disabled={renderLabelProps.disabled}
            onClick={stopPropagationOnLabelClick}
        >
            {renderLabelProps.label}
        </Label>
    );
};

export const PathDropdown: React.FC<PathDropdownProps> = (props: PathDropdownProps) => {
    const { paths, selectedPath, onChange, label, required, disabled, isLoading, placeholder, ariaLabel } = props;

    const [isCalloutVisible, setIsCalloutVisible] = React.useState<boolean>(false);

    const hideCallout = React.useCallback(() => {
        setIsCalloutVisible(false);
    }, []);

    const showCallout = React.useCallback(() => {
        setIsCalloutVisible(true);
    }, []);

    const calloutRef = React.useRef<HTMLDivElement>(null);
    const textFieldRef = React.useRef<HTMLDivElement>(null);
    const focusZoneRef = React.useRef<FocusZone>(null);

    const onPathTreeChange = React.useCallback(
        (value: string) => {
            onChange(value);
            hideCallout();
        },
        [onChange, hideCallout]
    );

    const onBlur = React.useCallback(
        (event: React.FocusEvent<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>) => {
            if (
                !calloutRef.current?.contains(event.relatedTarget as Node) &&
                !textFieldRef.current?.contains(event.relatedTarget as Node)
            ) {
                hideCallout();
                event.stopPropagation();
            }
        },
        [calloutRef.current, textFieldRef.current, hideCallout]
    );

    const iconProps = React.useMemo((): IIconProps | undefined => {
        if (isLoading) {
            return undefined;
        }

        return { iconName: FluentIconNames.ChevronDown, 'aria-hidden': true };
    }, [isLoading]);

    const onRenderInput = React.useCallback(
        (props?: InputProps, defaultRender?: DefaultRenderFunction<InputProps>) => {
            if (isLoading) {
                return <Shimmer />;
            }

            // Default render won't be undefined, but typedef says it can be, so we need to return something
            return defaultRender?.(props) ?? <></>;
        },
        [isLoading]
    );

    const onTextFieldClick = React.useCallback(
        (_event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (isCalloutVisible) {
                hideCallout();
            } else {
                showCallout();
            }
        },
        [isCalloutVisible, hideCallout, showCallout]
    );

    const onPositioned = React.useCallback(
        (_positions?: ICalloutPositionedInfo): void => {
            focusZoneRef.current?.focus();
        },
        [focusZoneRef.current]
    );

    return (
        <>
            <TextField
                readOnly
                value={selectedPath}
                elementRef={textFieldRef}
                onClick={onTextFieldClick}
                label={label}
                required={required}
                disabled={disabled || isLoading}
                onBlur={onBlur}
                autoComplete="none"
                aria-autocomplete="none"
                onRenderInput={onRenderInput}
                iconProps={iconProps}
                styles={textFieldStyles}
                onRenderLabel={onRenderLabel}
                placeholder={placeholder}
                ariaLabel={ariaLabel}
            />
            {isCalloutVisible && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={0}
                    doNotLayer={false}
                    directionalHintFixed={false}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    target={textFieldRef.current}
                    calloutWidth={textFieldRef.current?.clientWidth}
                    onBlur={onBlur}
                    ref={calloutRef}
                    dismissOnTargetClick
                    onPositioned={onPositioned}
                >
                    <FocusZone ref={focusZoneRef}>
                        <PathTree {...props} paths={paths} onChange={onPathTreeChange} />
                    </FocusZone>
                </Callout>
            )}
        </>
    );
};
