import { makeStyles } from '@fluentui/react';
import * as React from 'react';

/**
 * Style Section
 */

const useCpuIconStyles = makeStyles({
    // Note: currentColor will allow the SVG to inherit the color of the parent <i /> element.
    path: {
        fill: 'currentColor',
    },
    root: {
        color: 'currentColor',
    },
});

/* END */

const CpuIcon: React.FC = () => {
    // Style hooks
    const classNames = useCpuIconStyles();

    return (
        <svg className={classNames.root} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <path
                className={classNames.path}
                d="M4.44531 11C4.32552 11 4.22135 10.9557 4.13281 10.8672C4.04427 10.7786 4 10.6745 4 10.5547V4.44531C4 4.32552 4.04427 4.22135 4.13281 4.13281C4.22135 4.04427 4.32552 4 4.44531 4H10.5547C10.6745 4 10.7786 4.04427 10.8672 4.13281C10.9557 4.22135 11 4.32552 11 4.44531V10.5547C11 10.6745 10.9557 10.7786 10.8672 10.8672C10.7786 10.9557 10.6745 11 10.5547 11H4.44531ZM5 10H10V5H5V10ZM14 3V4H15V5H14V6H15V7H14V8H15V9H14V10H15V11H14V12H15V13H14V14H13V15H12V14H11V15H10V14H9V15H8V14H7V15H6V14H5V15H4V14H3V15H2V14H1V13H0V12H1V11H0V10H1V9H0V8H1V7H0V6H1V5H0V4H1V3H0V2H1V1H2V0H3V1H4V0H5V1H6V0H7V1H8V0H9V1H10V0H11V1H12V0H13V1H14V2H15V3H14ZM13 2H2V13H13V2Z"
            />
        </svg>
    );
};

export default CpuIcon;
