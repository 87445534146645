import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { isValidDataPlaneUri, parseDataPlanePath } from './data-plane';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const devBoxOperationPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.User}/${StringifiedPattern.Guid}/${DataPlaneResourcePathPrefix.DevBox}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.DevBoxOperation}/${StringifiedPattern.Guid}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createDevBoxOperationDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'devBoxName' | 'devBoxOperationId' | 'devCenter' | 'projectName' | 'user'>
): string => {
    const { devBoxName, devBoxOperationId, devCenter, projectName, user } = tokens;

    if (isUndefinedOrWhiteSpace(devBoxName)) {
        throw new ClientError('Dev box name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devBoxOperationId)) {
        throw new ClientError('Dev box operation ID token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(user)) {
        throw new ClientError('User token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.User,
        user.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBox,
        devBoxName.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBoxOperation,
        devBoxOperationId.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromDevBoxOperationDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'devBoxName' | 'devBoxOperationId' | 'devCenter' | 'projectName' | 'user'> => {
    if (!isValidDevBoxOperationDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a dev box operation');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , user, , devBoxName, , devBoxOperationId] = parseDataPlanePath(pathname);

    return {
        devBoxName,
        devBoxOperationId,
        devCenter: origin,
        projectName,
        user,
    };
};

export const isValidDevBoxOperationDataPlaneUri = (uri: string): boolean =>
    isValidDataPlaneUri(uri, devBoxOperationPathPattern);

export const tryCreateDevBoxOperationDataPlaneUri = tryOrDefault(createDevBoxOperationDataPlaneUri);
export const tryGetTokensFromDevBoxOperationDataPlaneUri = tryOrDefault(getTokensFromDevBoxOperationDataPlaneUri);
