import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { PatchEnvironmentResponse, updateEnvironment } from '../../../data/services/data-plane-api/environment';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { EnvironmentUpdateProperties } from '../../../models/environment';
import {
    updateEnvironment as updateEnvironmentActionCreator,
    updateEnvironmentError,
    updateEnvironmentFailed,
    updateEnvironmentSuccess,
} from '../../actions/environment/environment-action-creators';
import { UpdateEnvironmentAction } from '../../actions/environment/environment-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeLeading } from '../../effects/take';

export function* updateEnvironmentSaga(action: UpdateEnvironmentAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { expirationDate, id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const properties: EnvironmentUpdateProperties = { expirationDate };

        const response: PatchEnvironmentResponse = yield call(
            updateEnvironment,
            id,
            properties,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(updateEnvironmentFailed({ failure: response, id }, meta));
            return;
        }

        yield put(updateEnvironmentSuccess({ id, result: response.data }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.UpdateEnvironment);
        yield put(updateEnvironmentError({ error, id }, meta));
    }
}

export function* updateEnvironmentListenerSaga(): SagaIterator {
    yield takeLeading(updateEnvironmentActionCreator, updateEnvironmentSaga);
}
