import { PayloadAction, PollPayload } from '../actions/core-actions';

export const defaultPollingIntervalMs = 60000;
export const defaultPollingInitialDelayMs = 30000;

export const getPollingIntervalMs = (action: PayloadAction<PollPayload>): number =>
    action.payload.intervalMs ?? defaultPollingIntervalMs;

export const getPollingInitialDelayMs = (action: PayloadAction<PollPayload>): number =>
    action.payload.initialDelayMs ?? defaultPollingInitialDelayMs;
