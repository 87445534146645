import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import createCustomizationGroupError from './create-customization-group-error.json';
import createCustomizationGroup from './create-customization-group.json';
import createDevBoxGuestAccountError from './create-dev-box-guest-account-error.json';
import createDevBoxInternalServerCloudError from './create-dev-box-internal-server-cloud-error.json';
import createDevBoxInternalServerEmptyError from './create-dev-box-internal-server-empty-error.json';
import createDevBoxInternalServerNonjsonError from './create-dev-box-internal-server-nonjson-error.json';
import createDevBoxPoolUnhealthyState from './create-dev-box-pool-unhealthy-state.json';
import createDevBoxQuotaExceeded from './create-dev-box-quota-exceeded.json';
import createDevBoxStub from './create-dev-box-stub.json';
import customizeDevBoxError from './customize-dev-box-error.json';
import deleteDevBoxError from './delete-dev-box-error.json';
import discoverDevBoxesInTenantExceededQuota from './discover-dev-boxes-in-tenant-exceeded-quota.json';
import discoverDevBoxesInTenantManyDevBoxes from './discover-dev-boxes-in-tenant-many-dev-boxes.json';
import discoverDevBoxesInTenantNoDevBoxes from './discover-dev-boxes-in-tenant-no-dev-boxes.json';
import discoverDevBoxesInTenantOneDevBox from './discover-dev-boxes-in-tenant-one-dev-box.json';
import discoverDevBoxesInTenantOneProvisionedWithWarning from './discover-dev-boxes-in-tenant-one-provisioned-with-warning.json';
import getCustomizationGroup from './get-customization-group.json';
import getCustomizationTaskLog from './get-customization-task-log.json';
import getDevBoxPollingError from './get-dev-box-polling-error.json';
import getRemoteConnectionFail from './get-remote-connection-fail.json';
import listCustomizationGroupsWithTasks from './list-customization-groups-with-tasks.json';
import listCustomizationGroupsWithoutTasks from './list-customization-groups-without-tasks.json';
import listUpcomingActionsNotFound from './list-dev-box-actions-not-found.json';
import listDevBoxesByProjectError from './list-dev-boxes-by-project-error.json';
import listDevBoxesByProjectOneFailed from './list-dev-boxes-by-project-one-failed.json';
import validateCustomizationTasksFailed from './validate-customization-tasks-failed.json';

export type DataPlaneApiDevBoxScenarioName =
    | 'CreateDevBoxStub'
    | 'CreateDevBoxGuestAccountError'
    | 'CreateDevBoxInternalServerCloudError'
    | 'CreateDevBoxInternalServerEmptyError'
    | 'CreateDevBoxInternalServerNonjsonError'
    | 'CreateDevBoxPoolUnhealthyState'
    | 'CreateDevBoxQuotaExceeded'
    | 'CustomizeDevBoxError'
    | 'DeleteDevBoxError'
    | 'DiscoverDevBoxesInTenantExceededQuota'
    | 'DiscoverDevBoxesInTenantManyDevBoxes'
    | 'DiscoverDevBoxesInTenantNoDevBoxes'
    | 'DiscoverDevBoxesInTenantOneDevBox'
    | 'DiscoverDevBoxesInTenantOneProvisionedWithWarning'
    | 'GetDevBoxPollingError'
    | 'GetRemoteConnectionFail'
    | 'ListDevBoxActionsNotFound'
    | 'ListDevBoxesByProjectError'
    | 'ListDevBoxesByProjectOneFailed'
    | 'CreateCustomizationGroup'
    | 'CreateCustomizationGroupError'
    | 'GetCustomizationGroup'
    | 'GetCustomizationTaskLog'
    | 'ListCustomizationGroupsWithTasks'
    | 'ListCustomizationGroupsWithoutTasks'
    | 'ValidateCustomizationTasksFailed';

export const DataPlaneApiDevBoxScenarioName: UnionMap<DataPlaneApiDevBoxScenarioName> = {
    CreateDevBoxStub: 'CreateDevBoxStub',
    CreateDevBoxGuestAccountError: 'CreateDevBoxGuestAccountError',
    CreateDevBoxInternalServerCloudError: 'CreateDevBoxInternalServerCloudError',
    CreateDevBoxInternalServerEmptyError: 'CreateDevBoxInternalServerEmptyError',
    CreateDevBoxInternalServerNonjsonError: 'CreateDevBoxInternalServerNonjsonError',
    CreateDevBoxPoolUnhealthyState: 'CreateDevBoxPoolUnhealthyState',
    CreateDevBoxQuotaExceeded: 'CreateDevBoxQuotaExceeded',
    CustomizeDevBoxError: 'CustomizeDevBoxError',
    DeleteDevBoxError: 'DeleteDevBoxError',
    DiscoverDevBoxesInTenantExceededQuota: 'DiscoverDevBoxesInTenantExceededQuota',
    DiscoverDevBoxesInTenantManyDevBoxes: 'DiscoverDevBoxesInTenantManyDevBoxes',
    DiscoverDevBoxesInTenantNoDevBoxes: 'DiscoverDevBoxesInTenantNoDevBoxes',
    DiscoverDevBoxesInTenantOneDevBox: 'DiscoverDevBoxesInTenantOneDevBox',
    DiscoverDevBoxesInTenantOneProvisionedWithWarning: 'DiscoverDevBoxesInTenantOneProvisionedWithWarning',
    GetDevBoxPollingError: 'GetDevBoxPollingError',
    GetRemoteConnectionFail: 'GetRemoteConnectionFail',
    ListDevBoxActionsNotFound: 'ListDevBoxActionsNotFound',
    ListDevBoxesByProjectError: 'ListDevBoxesByProjectError',
    ListDevBoxesByProjectOneFailed: 'ListDevBoxesByProjectOneFailed',
    CreateCustomizationGroup: 'CreateCustomizationGroup',
    CreateCustomizationGroupError: 'CreateCustomizationGroupError',
    GetCustomizationGroup: 'GetCustomizationGroup',
    GetCustomizationTaskLog: 'GetCustomizationTaskLog',
    ListCustomizationGroupsWithTasks: 'ListCustomizationGroupsWithTasks',
    ListCustomizationGroupsWithoutTasks: 'ListCustomizationGroupsWithoutTasks',
    ValidateCustomizationTasksFailed: 'ValidateCustomizationTasksFailed',
};

export const DataPlaneApiDevBoxScenarios: UnionValueMap<DataPlaneApiDevBoxScenarioName, MockScenarioConfiguration> = {
    CreateDevBoxStub: createDevBoxStub as MockScenarioConfiguration,
    CreateDevBoxGuestAccountError: createDevBoxGuestAccountError as MockScenarioConfiguration,
    CreateDevBoxInternalServerCloudError: createDevBoxInternalServerCloudError as MockScenarioConfiguration,
    CreateDevBoxInternalServerEmptyError: createDevBoxInternalServerEmptyError as MockScenarioConfiguration,
    CreateDevBoxInternalServerNonjsonError: createDevBoxInternalServerNonjsonError as MockScenarioConfiguration,
    CreateDevBoxPoolUnhealthyState: createDevBoxPoolUnhealthyState as MockScenarioConfiguration,
    CreateDevBoxQuotaExceeded: createDevBoxQuotaExceeded as MockScenarioConfiguration,
    CustomizeDevBoxError: customizeDevBoxError as MockScenarioConfiguration,
    DeleteDevBoxError: deleteDevBoxError as MockScenarioConfiguration,
    DiscoverDevBoxesInTenantExceededQuota: discoverDevBoxesInTenantExceededQuota as MockScenarioConfiguration,
    DiscoverDevBoxesInTenantManyDevBoxes: discoverDevBoxesInTenantManyDevBoxes as MockScenarioConfiguration,
    DiscoverDevBoxesInTenantNoDevBoxes: discoverDevBoxesInTenantNoDevBoxes as MockScenarioConfiguration,
    DiscoverDevBoxesInTenantOneDevBox: discoverDevBoxesInTenantOneDevBox as MockScenarioConfiguration,
    DiscoverDevBoxesInTenantOneProvisionedWithWarning:
        discoverDevBoxesInTenantOneProvisionedWithWarning as MockScenarioConfiguration,
    GetDevBoxPollingError: getDevBoxPollingError as MockScenarioConfiguration,
    GetRemoteConnectionFail: getRemoteConnectionFail as MockScenarioConfiguration,
    ListDevBoxActionsNotFound: listUpcomingActionsNotFound as MockScenarioConfiguration,
    ListDevBoxesByProjectError: listDevBoxesByProjectError as MockScenarioConfiguration,
    ListDevBoxesByProjectOneFailed: listDevBoxesByProjectOneFailed as MockScenarioConfiguration,
    CreateCustomizationGroup: createCustomizationGroup as MockScenarioConfiguration,
    CreateCustomizationGroupError: createCustomizationGroupError as MockScenarioConfiguration,
    GetCustomizationGroup: getCustomizationGroup as MockScenarioConfiguration,
    GetCustomizationTaskLog: getCustomizationTaskLog as MockScenarioConfiguration,
    ListCustomizationGroupsWithTasks: listCustomizationGroupsWithTasks as MockScenarioConfiguration,
    ListCustomizationGroupsWithoutTasks: listCustomizationGroupsWithoutTasks as MockScenarioConfiguration,
    ValidateCustomizationTasksFailed: validateCustomizationTasksFailed as MockScenarioConfiguration,
};
