import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import discoverPermissionsForProjectsAllAllowed from './discover-permissions-for-projects-all-allowed.json';
import discoverPermissionsForProjectsNoneAllowed from './discover-permissions-for-projects-none-allowed.json';
import discoverPermissionsForProjectsSomeAllowed from './discover-permissions-for-projects-some-allowed.json';

export type ResourceManagerPermissionsScenarioName =
    | 'DiscoverPermissionsForProjectsAllAllowed'
    | 'DiscoverPermissionsForProjectsNoneAllowed'
    | 'DiscoverPermissionsForProjectsSomeAllowed';

export const ResourceManagerPermissionsScenarioName: UnionMap<ResourceManagerPermissionsScenarioName> = {
    DiscoverPermissionsForProjectsAllAllowed: 'DiscoverPermissionsForProjectsAllAllowed',
    DiscoverPermissionsForProjectsNoneAllowed: 'DiscoverPermissionsForProjectsNoneAllowed',
    DiscoverPermissionsForProjectsSomeAllowed: 'DiscoverPermissionsForProjectsSomeAllowed',
};

export const ResourceManagerPermissionsScenarios: UnionValueMap<
    ResourceManagerPermissionsScenarioName,
    MockScenarioConfiguration
> = {
    DiscoverPermissionsForProjectsAllAllowed: discoverPermissionsForProjectsAllAllowed as MockScenarioConfiguration,
    DiscoverPermissionsForProjectsNoneAllowed: discoverPermissionsForProjectsNoneAllowed as MockScenarioConfiguration,
    DiscoverPermissionsForProjectsSomeAllowed: discoverPermissionsForProjectsSomeAllowed as MockScenarioConfiguration,
};
