import * as React from 'react';
import { PoolViewModel } from '../../../../../add-dev-box-panel/models';
import { ValueDropdown, ValueDropdownWrapperProps } from '../../value-dropdown';
import { getOptionKeyForIndexedModel } from '../selectors';
import PoolDropdownItem from './pool-dropdown-item';
import PoolDropdownPlaceholder from './pool-dropdown-placeholder';

export type PoolDropdownProps = ValueDropdownWrapperProps<PoolViewModel>;

const onRenderOption = (pool: PoolViewModel): JSX.Element => <PoolDropdownItem pool={pool} />;
const onRenderPlaceholder = (placeholder: string): JSX.Element => <PoolDropdownPlaceholder placeholder={placeholder} />;

export const PoolDropdown: React.FC<PoolDropdownProps> = (props) => (
    <ValueDropdown<PoolViewModel>
        {...props}
        onRenderOption={onRenderOption}
        getOptionKey={getOptionKeyForIndexedModel}
        onRenderPlaceholder={onRenderPlaceholder}
    />
);
