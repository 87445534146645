import { defineMessages } from 'react-intl';

export const sharedMessages = defineMessages({
    windowsIconAriaLabel: {
        id: 'DevBoxCard_WindowsIcon_AriaLabel',
        defaultMessage: 'Windows',
        description:
            'Aria label for virtual machine image icon in dev box card. Note: "Windows" refers to the operating system, not glass windows.',
    },
    windowsNameText: {
        id: 'DevBoxCard_WindowsName_Text',
        defaultMessage: 'Windows',
        description:
            'Name of the virtual machine image used by this dev box. Note: "Windows" refers to the operating system, not glass windows.',
    },
    scheduleIconAriaLabel: {
        id: 'DevBoxCard_ScheduleIcon_AriaLabel',
        defaultMessage: 'Scheduled stop time information for the dev box',
        description: 'Aria label for the schedule icon in dev box card.',
    },
});
