import {
    IContextualMenuItem,
    IContextualMenuItemStyleProps,
    IContextualMenuItemStyles,
    IStyleFunctionOrObject,
} from '@fluentui/react';
import { createSelector } from '@reduxjs/toolkit';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { ProjectFromDiscoveryService, ProjectResource, ProjectResourceMap } from '../../../models/project';
import { StoreStateSelector } from '../../../redux/selector/common';
import { getDevBoxCountByProject } from '../../../redux/selector/dev-box-selectors';
import { getEnvironmentCountByProject } from '../../../redux/selector/environment-selectors';
import { getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization } from '../../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsWithAnyDevResourceAuthorizationByDataPlaneId } from '../../../redux/selector/project-selectors';
import { SerializableMap } from '../../../types/serializable-map';
import { filter, get, map, values } from '../../../utilities/serializable-map';

const messages = defineMessages({
    projectPickerAllProjectsText: {
        id: 'ProjectPicker_AllProjects_Text',
        defaultMessage: 'All projects',
        description: 'Text used for "All projects" option in project picker.',
    },
    projectPickerAllProjectsAriaLabel: {
        id: 'ProjectPicker_AllProjects_AriaLabel',
        defaultMessage: 'All projects',
        description: 'Aria label used for "All projects" option in project picker.',
    },
});

/**
 * Application state selectors
 */

export const getProjectsWithAtLeastOneResource: StoreStateSelector<SerializableMap<ProjectResource>> = createSelector(
    [getProjectsWithAnyDevResourceAuthorizationByDataPlaneId, getDevBoxCountByProject, getEnvironmentCountByProject],
    (projects, devBoxCountsByProject, environmentCountsByProject) =>
        filter(projects, (_project, id) => {
            const devBoxCount = get(devBoxCountsByProject, id) ?? 0;
            const environmentCount = get(environmentCountsByProject, id) ?? 0;

            return devBoxCount > 0 || environmentCount > 0;
        })
);

export const getProjectsFromDiscoveryServiceWithAtLeastOneResource: StoreStateSelector<
    SerializableMap<ProjectFromDiscoveryService>
> = createSelector(
    [
        getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization,
        getDevBoxCountByProject,
        getEnvironmentCountByProject,
    ],
    (projects, devBoxCountsByProject, environmentCountsByProject) =>
        filter(projects, (_project, id) => {
            const devBoxCount = get(devBoxCountsByProject, id) ?? 0;
            const environmentCount = get(environmentCountsByProject, id) ?? 0;

            return devBoxCount > 0 || environmentCount > 0;
        })
);

/**
 * Other selectors
 */

export const getProjectPickerItems = (
    itemStyles: IStyleFunctionOrObject<IContextualMenuItemStyleProps, IContextualMenuItemStyles>,
    onChange: (project?: string) => void,
    projects: ProjectResourceMap,
    formatMessage: (message: MessageDescriptor) => string
): IContextualMenuItem[] => {
    const authorizedProjects = map(projects, (project, id) => {
        const authorizedProject: IContextualMenuItem = {
            ariaLabel: project.name,
            key: id,
            onClick: (_ev?: unknown) => {
                onChange(id);
            },
            itemProps: {
                styles: itemStyles,
            },
            // TODO: TASK 1835522 Disambiguation for project name collisions across dev centers
            text: project.name,
        };

        return authorizedProject;
    });

    const allProjects: IContextualMenuItem = {
        ariaLabel: formatMessage(messages.projectPickerAllProjectsAriaLabel),
        key: 'allProjects',
        onClick: (_ev?: unknown) => {
            onChange();
        },
        itemProps: {
            styles: itemStyles,
        },
        text: formatMessage(messages.projectPickerAllProjectsText),
    };

    const menuItems = values(authorizedProjects);
    menuItems.unshift(allProjects);

    return menuItems;
};

export const getSelectedProjectName = (projects: ProjectResourceMap, selectedProjectId: string): string | undefined =>
    get(projects, selectedProjectId)?.name;
