import { UnionMap } from '../../types/union-map';

export type MocksState =
    | 'ConfigurationError'
    | 'ConfiguredWithNoScenarios'
    | 'ConfiguredWithScenarios'
    | 'NotConfigured';

export const MocksState: UnionMap<MocksState> = {
    ConfigurationError: 'ConfigurationError',
    ConfiguredWithNoScenarios: 'ConfiguredWithNoScenarios',
    ConfiguredWithScenarios: 'ConfiguredWithScenarios',
    NotConfigured: 'NotConfigured',
};
