import { IStackTokens, makeStyles, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { AppSemanticColor } from '../../../themes/app-semantic-colors';
import { isBoolean } from '../../../utilities/boolean';
import { isNumber } from '../../../utilities/number';
import { isString } from '../../../utilities/string';
import { getSemanticColor } from '../../../utilities/styles';
import Label from './label';

export interface ReadOnlyControlProps {
    label?: string;
    value: React.ReactNode;
    required?: boolean;
    ariaLabel?: string;
    tooltipText?: React.ReactElement | string;
    /** This prop changes the styling of the control to match the styling of a disabled input. It has no impact on the functionality of the control. */
    disabled?: boolean;
    tagText?: string;
}

const readOnlyOptionStyleFactory = (disabled: boolean | undefined) =>
    makeStyles((theme) => ({
        root: {
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            paddingRight: 8,
            paddingBottom: 6,
            color: disabled ? getSemanticColor(theme, AppSemanticColor.disabledText) : undefined,
        },
    }));

const readOnlyTextStyleFactory = (disabled: boolean | undefined) =>
    makeStyles((theme) => ({
        root: {
            color: disabled ? getSemanticColor(theme, AppSemanticColor.disabledText) : undefined,
        },
    }));

const readOnlyOptionContainerTokens: IStackTokens = {
    childrenGap: 5,
};

export const ReadOnlyControl: React.FC<ReadOnlyControlProps> = (props: ReadOnlyControlProps) => {
    const { label, value, required, ariaLabel, tooltipText, disabled, tagText } = props;

    const useReadOnlyOptionStyles = readOnlyOptionStyleFactory(disabled);
    const useReadOnlyTextStyles = readOnlyTextStyleFactory(disabled);
    const readOnlyOptionStyles = useReadOnlyOptionStyles();
    const readOnlyTextStyles = useReadOnlyTextStyles();

    const valueIsStringBooleanOrNumber = React.useMemo(
        () => isString(value) || isNumber(value) || isBoolean(value),
        [value]
    );

    return (
        <Stack tokens={readOnlyOptionContainerTokens}>
            <Stack.Item>
                <Label
                    aria-label={ariaLabel}
                    required={required}
                    tooltipValue={tooltipText}
                    disabled={disabled}
                    tagContent={tagText}
                >
                    {label}
                </Label>
            </Stack.Item>
            <Stack.Item styles={readOnlyOptionStyles}>
                {valueIsStringBooleanOrNumber ? <Text styles={readOnlyTextStyles}>{value}</Text> : value}
            </Stack.Item>
        </Stack>
    );
};

export default ReadOnlyControl;
