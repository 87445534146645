import { makeStyles } from '@fluentui/react';
import * as React from 'react';

/**
 * Style Section
 */

const useRamIconStyles = makeStyles({
    // Note: currentColor will allow the SVG to inherit the color of the parent <i /> element.
    path: {
        fill: 'currentColor',
    },
    root: {
        color: 'currentColor',
    },
});

/* END */

const RamIcon: React.FC = () => {
    // Style hooks
    const classNames = useRamIconStyles();
    return (
        <svg className={classNames.root} viewBox="0 0 15 10" xmlns="http://www.w3.org/2000/svg">
            <path
                className={classNames.path}
                d="M15 0V8H14V10H8V8.5C8 8.36458 7.95052 8.2474 7.85156 8.14844C7.7526 8.04948 7.63542 8 7.5 8C7.36458 8 7.2474 8.04948 7.14844 8.14844C7.04948 8.2474 7 8.36458 7 8.5V10H1V8H0V0H15ZM6 9C6 8.82812 6 8.66146 6 8.5C6 8.33333 6.02865 8.16667 6.08594 8H2V9H6ZM13 9V8H8.91406C8.97135 8.16667 9 8.33333 9 8.5C9 8.66146 9 8.82812 9 9H13ZM14 1H1V7H14V1ZM13 6H2V2H13V6ZM9 3V5H10V3H9ZM7 3V5H8V3H7ZM5 3V5H6V3H5ZM3 5H4V3H3V5ZM12 3H11V5H12V3Z"
            />
        </svg>
    );
};

export default RamIcon;
