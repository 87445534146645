import { SagaIterator } from 'redux-saga';
import { call, CallEffect } from 'redux-saga/effects';
import { AsyncAction } from '../actions/core-actions';

/**
 * Types
 */

export type ResolveActionSignature<TAwaited> = (action: AsyncAction<TAwaited>, result: TAwaited) => SagaIterator<void>;

/**
 * Effects
 */

export function* resolveActionSaga<TAwaited>(action: AsyncAction<TAwaited>, result: TAwaited): SagaIterator<void> {
    const { async } = action;
    const { resolve } = async;

    yield call(resolve, result);
}

export const resolveAction = <TAwaited>(action: AsyncAction<TAwaited>, result: TAwaited): CallEffect<void> =>
    call<ResolveActionSignature<TAwaited>>(resolveActionSaga, action, result);
