import { Depths, FontSizes, FontWeights, Icon, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { getSemanticColor } from '../../../utilities/styles';

// TODO: a lot of similarities with QuickActionCard, but there are enough minor style differences that trying
// to refactor would have slowed down work to get this dialog implemented. Tackle unifying these cards in a later
// refactor.

interface OpenWindowsDesktopClientOptionCardProps {
    contentFooter?: React.ReactNode;
    iconAriaLabel: string;
    iconName: string;
    paddingBottom?: number;
    subtitle: React.ReactNode;
    title: React.ReactNode;
}

/**
 * Styles
 */

const rootStylesFactory = (paddingBottom?: number) =>
    makeStyles((theme) => ({
        root: {
            backgroundColor: getSemanticColor(theme, 'openWindowsDesktopClientOptionCardBackground'),
            borderRadius: '4px',
            boxShadow: Depths.depth16,

            padding: paddingBottom !== undefined ? `32px 32px ${paddingBottom}px 32px` : '32px',
        },
    }));

const useStyles = makeStyles((theme) => ({
    icon: {
        color: getSemanticColor(theme, 'openWindowsDesktopClientOptionCardIcon'),
        fontSize: '34px',
        lineHeight: '34px',
        width: '34px',
    },
}));

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        marginBottom: '10px',
    },
});

const useTextContainerStyles = makeStyles({
    root: {
        // setting a min width value for this element allows it to shrink
        minWidth: 0,
    },
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        width: '100%',
    },
});

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
        lineHeight: '20px',
    },
});

/**
 * END Styles
 */

const contentTokens: IStackTokens = {
    childrenGap: 20,
};

const rootTokens: IStackTokens = {
    childrenGap: 24,
};

const textTokens: IStackTokens = {
    childrenGap: 2,
};

export const OpenWindowsDesktopClientOptionCard: React.FC<OpenWindowsDesktopClientOptionCardProps> = (
    props: OpenWindowsDesktopClientOptionCardProps
) => {
    const { contentFooter, iconAriaLabel, iconName, paddingBottom, subtitle, title } = props;

    // Style hooks
    const useRootStyles = useDynamicMakeStyles(rootStylesFactory, paddingBottom);

    const { icon: iconClassName } = useStyles();
    const rootStyles = useRootStyles();
    const subtitleStyles = useSubtitleStyles();
    const textContainerStyles = useTextContainerStyles();
    const textStyles = useTextStyles();
    const titleStyles = useTitleStyles();

    return (
        <Stack styles={rootStyles} tokens={rootTokens}>
            <Stack.Item>
                <Stack horizontal tokens={contentTokens} verticalAlign="start">
                    <Stack.Item>
                        <Icon aria-label={iconAriaLabel} className={iconClassName} iconName={iconName} />
                    </Stack.Item>

                    <Stack.Item grow={1} styles={textContainerStyles}>
                        <Stack styles={textStyles} tokens={textTokens}>
                            <Stack.Item styles={titleStyles}>{title}</Stack.Item>
                            <Stack.Item styles={subtitleStyles}>{subtitle}</Stack.Item>

                            {contentFooter && <Stack.Item>{contentFooter}</Stack.Item>}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default OpenWindowsDesktopClientOptionCard;
