import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FeatureFlagName } from '../constants/features';
import { getEnabledFeatures } from '../redux/selector/features-selectors';

export const useFeatureFlag = (featureFlag: FeatureFlagName): boolean => {
    const features = useSelector(getEnabledFeatures);

    return useMemo(() => features[featureFlag], [featureFlag, features]);
};

export const useFeatureFlags = (...featureFlags: FeatureFlagName[]): boolean[] => {
    const features = useSelector(getEnabledFeatures);

    return useMemo(() => featureFlags.map((flag) => features[flag]), [features, ...featureFlags]);
};
