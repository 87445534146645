import { createSelector } from '@reduxjs/toolkit';
import { keys } from '../../../utilities/serializable-map';
import { StoreStateSelector } from '../../selector/common';
import {
    getIsProjectAuthorizedForProjectEnvironmentTypeRead,
    getPermissionsForProjects,
} from '../../selector/permission-selectors';
import { getProjectsByResourceId } from '../../selector/project-selectors';

/**
 * Application state selectors
 */

export const getResourceIdsForProjectsAuthorizedForProjectEnvironmentTypeRead: StoreStateSelector<string[]> =
    createSelector([getProjectsByResourceId, getPermissionsForProjects], (projects, permissions) =>
        keys(projects).filter((id) => getIsProjectAuthorizedForProjectEnvironmentTypeRead(permissions, id))
    );
