import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { OpenWindowsDesktopClientDialogComponentProps } from './open-windows-desktop-client-dialog';

export type OpenWindowsDesktopClientDialogProperties = Omit<
    OpenWindowsDesktopClientDialogComponentProps,
    'hidden' | 'onDismiss'
>;

export const OpenWindowsDesktopClientDialogContext = React.createContext<
    SurfaceContextWithProperties<OpenWindowsDesktopClientDialogProperties>
>({ isOpen: false });

export const OpenWindowsDesktopClientDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<OpenWindowsDesktopClientDialogProperties>(
        OpenWindowsDesktopClientDialogContext
    );
