import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { EnvironmentProvisioningState } from '../../../constants/environment';
import InProgressStateMessage from '../../common/in-progress-state-message';

interface EnvironmentStateMessageProps {
    state: EnvironmentProvisioningState;
}

const messages = defineMessages({
    creatingSpinnerAriaLabel: {
        id: 'EnvironmentStateMessage_Spinner_AriaLabel',
        defaultMessage: 'Creating your environment',
        description: 'Aria label for spinner tracking progress of environment creation in environment card',
    },
    deletingSpinnerAriaLabel: {
        id: 'EnvironmentStateMessage_DeletingSpinner_AriaLabel',
        defaultMessage: 'Deleting your environment',
        description: 'Aria label for spinner tracking progress of environment deletion in environment card',
    },
    deployingSpinnerAriaLabel: {
        id: 'EnvironmentStateMessage_DeployingSpinner_AriaLabel',
        defaultMessage: 'Deploying your environment',
        description: 'Aria label for spinner tracking progress of environment deployment in environment card',
    },
    updatingSpinnerAriaLabel: {
        id: 'EnvironmentStateMessage_UpdatingSpinner_AriaLabel',
        defaultMessage: 'Updating your environment',
        description: 'Aria label for spinner tracking progress of environment updating in environment card',
    },
});

export const EnvironmentStateMessage: React.FC<EnvironmentStateMessageProps> = React.memo(
    (props: EnvironmentStateMessageProps) => {
        const { state } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        switch (state) {
            case EnvironmentProvisioningState.Creating:
            case EnvironmentProvisioningState.Preparing:
                return (
                    <InProgressStateMessage ariaLabel={formatMessage(messages.creatingSpinnerAriaLabel)}>
                        <FormattedMessage
                            id="EnvironmentStateMessage_CreatingMessage_Text"
                            defaultMessage="Creating..."
                            description="Message informing user that environment creation is in progress"
                        />
                    </InProgressStateMessage>
                );
            case EnvironmentProvisioningState.Deleting:
                return (
                    <InProgressStateMessage ariaLabel={formatMessage(messages.deletingSpinnerAriaLabel)}>
                        <FormattedMessage
                            id="EnvironmentStateMessage_DeletingState_Text"
                            defaultMessage="Deleting..."
                            description="Text informing user that environment is in the process of being deleted."
                        />
                    </InProgressStateMessage>
                );
            case EnvironmentProvisioningState.Deploying:
                return (
                    <InProgressStateMessage ariaLabel={formatMessage(messages.deployingSpinnerAriaLabel)}>
                        <FormattedMessage
                            id="EnvironmentStateMessage_RedeployingState_Text"
                            defaultMessage="Redeploying..."
                            description="Text informing user that environment is in the process of redeploying."
                        />
                    </InProgressStateMessage>
                );
            case EnvironmentProvisioningState.Updating:
                return (
                    <InProgressStateMessage ariaLabel={formatMessage(messages.updatingSpinnerAriaLabel)}>
                        <FormattedMessage
                            id="EnvironmentStateMessage_UpdatingState_Text"
                            defaultMessage="Updating..."
                            description="Text informing user that environment is in the process of updating."
                        />
                    </InProgressStateMessage>
                );
            //TODO: Figure out state when running custom command and get the correct string from Brandon to display when a custom action is submitted
            //TODO: Unknown states are not expected, but showing another state is also not accurate.
            // For now the state text is empty if state is unknown.
            default:
                return <></>;
        }
    }
);

export default EnvironmentStateMessage;
