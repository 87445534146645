import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import {
    GetCustomizationGroupResponse,
    getCustomizationGroup,
} from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { isCustomizationGroupInTerminalState } from '../../../utilities/customization-group';
import {
    pollNonTerminalCustomizationGroup,
    pollNonTerminalCustomizationGroupComplete,
    pollNonTerminalCustomizationGroupError,
    pollNonTerminalCustomizationGroupFailed,
    trackCustomizationGroupProgress,
} from '../../actions/customization/customization-action-creators';
import { PollNonTerminalCustomizationGroupAction } from '../../actions/customization/customization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getPollingInitialDelayMs, getPollingIntervalMs } from '../../utilities/poll-action';

export function* pollNonTerminalCustomizationGroupSaga(action: PollNonTerminalCustomizationGroupAction): SagaIterator {
    let shouldPoll = true;
    const { payload, meta } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        yield delay(getPollingInitialDelayMs(action));

        const pollingInterval = getPollingIntervalMs(action);

        while (shouldPoll) {
            const accessToken: string = yield putAndAwait(
                getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
            );

            const response: GetCustomizationGroupResponse = yield call(
                getCustomizationGroup,
                id,
                accessToken,
                activityId
            );

            if (isFailureResponse(response)) {
                // should we ignore / re-try under certain conditions or error codes?
                yield put(pollNonTerminalCustomizationGroupFailed({ failure: response, id }, meta));
                yield resolveAction(action, response);
                return;
            }

            const { data } = response;
            const status = data.status;

            shouldPoll = !isCustomizationGroupInTerminalState(status);

            yield put(trackCustomizationGroupProgress({ id, result: data }));

            if (shouldPoll) {
                yield delay(pollingInterval);
            } else {
                yield put(pollNonTerminalCustomizationGroupComplete({ id, result: response.data }, meta));
                yield resolveAction(action, response);
            }
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.PollNonTerminalCustomizationGroup);
        yield put(pollNonTerminalCustomizationGroupError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* pollNonTerminalCustomizationGroupListenerSaga(): SagaIterator {
    yield takeEvery(pollNonTerminalCustomizationGroup, pollNonTerminalCustomizationGroupSaga);
}
