import { createReducer } from '@reduxjs/toolkit';
import { deleteGlobalProperty, setGlobalProperty } from '../actions/telemetry/telemetry-action-creators';
import { TelemetryStore } from '../store/telemetry-store';
import { getPayload } from '../utilities/payload-action';

export const telemetryReducer = createReducer(TelemetryStore(), (builder) => {
    builder.addCase(deleteGlobalProperty, (store, action) => {
        const { propertyName } = getPayload(action);
        delete store.globalProperties[propertyName];
    });

    builder.addCase(setGlobalProperty, (store, action) => {
        const { propertyName, value } = getPayload(action);
        store.globalProperties[propertyName] = value;
    });
});
