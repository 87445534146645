import { IconButton, IContextualMenuItem, IContextualMenuProps, IIconProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

interface SecondaryActionsButtonProps {
    actions: IContextualMenuItem[];
    disabled?: boolean;
    resourceName: string;
}

const messages = defineMessages({
    buttonAriaLabel: {
        id: 'SecondaryActionsButton_Button_AriaLabel',
        defaultMessage: '{resourceName} actions',
        description: 'Aria label for actions button.',
    },
    buttonText: {
        id: 'SecondaryActionsButton_Button_Text',
        defaultMessage: 'Actions',
        description: 'Text for actions button.',
    },
});

/**
 * Styles
 */

const useRootStyles = makeStyles({
    menuIcon: {
        display: 'none',
    },
    root: {
        padding: '0 4px',
    },
    rootFocused: {
        // Offset focus border by reducing padding
        padding: '0 3px',
    },
});

/**
 * END Styles
 */

const iconProps: IIconProps = {
    iconName: 'MoreVertical',
};

export const SecondaryActionsButton: React.FC<SecondaryActionsButtonProps> = React.memo(
    (props: SecondaryActionsButtonProps) => {
        const { disabled, actions, resourceName } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const rootStyles = useRootStyles();

        // Memoized data
        const menuProps = React.useMemo<IContextualMenuProps>(
            () => ({
                gapSpace: 4,
                isBeakVisible: false,
                items: actions,
            }),
            [actions, formatMessage]
        );

        const ariaLabelValues = React.useMemo(() => ({ resourceName }), [resourceName]);

        return (
            <IconButton
                ariaLabel={formatMessage(messages.buttonAriaLabel, ariaLabelValues)}
                disabled={disabled}
                iconProps={iconProps}
                menuProps={menuProps}
                styles={rootStyles}
                title={formatMessage(messages.buttonText)}
            />
        );
    }
);

export default SecondaryActionsButton;
