import { createSelector } from '@reduxjs/toolkit';
import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { createProjectDataPlaneUri } from '../../../ids/project';
import { unique } from '../../../utilities/array';
import { get } from '../../../utilities/serializable-map';
import { isStatusTerminal } from '../../selector/common';
import { getDevBoxes } from '../../selector/dev-box-selectors';
import { getStatusesForListSchedulesByProject } from '../../selector/schedule-selectors';

/**
 * Application state selectors
 */

export const getProjectIdsForDevBoxesRequiringSchedules = createSelector(
    [getDevBoxes, getStatusesForListSchedulesByProject],
    (devBoxes, statusesForListSchedulesByProject) => {
        const projectIds = unique(
            devBoxes.map((devBox) => {
                const { uri } = devBox;
                const { devCenter, projectName } = getTokensFromDevBoxDataPlaneUri(uri);

                return createProjectDataPlaneUri({ devCenter, projectName });
            })
        );

        return projectIds.filter((id) => !isStatusTerminal(get(statusesForListSchedulesByProject, id)));
    }
);
