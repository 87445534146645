import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import {
    isNonRegionalDataPlaneUri,
    isRegionalDataPlaneUri,
    isValidDataPlaneUri,
    parseDataPlanePath,
} from '../ids/data-plane';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { ProjectResource } from '../models/project';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const projectPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createProjectDataPlaneUri = (tokens: DataPlaneIdTokenSet<'devCenter' | 'projectName'>): string => {
    const { devCenter, projectName } = tokens;

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    const path = `/${[DataPlaneResourcePathPrefix.Project, projectName.toLowerCase()].join('/')}`;
    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const createProjectDataPlaneUriFromProject = (project: ProjectResource): string => {
    const { name: projectName } = project;
    const devCenter = getDevCenterDataPlaneUriFromProject(project);
    const uri = createProjectDataPlaneUri({ devCenter, projectName });

    return uri;
};

export const getDevCenterDataPlaneUriFromProject = (project: ProjectResource): string => {
    const { location, properties } = project;
    const { devCenterUri } = properties;

    if (isUndefinedOrWhiteSpace(devCenterUri)) {
        throw new ClientError('No devCenterUri exists on project resource');
    }

    const { hostname, origin } = new URL(devCenterUri);

    // If this is already a regional Data Plane URI, return it
    if (isRegionalDataPlaneUri(devCenterUri)) {
        return origin;
    }

    // Throw if this isn't a non-regional URL (at this point, this should mean it's not a Data Plane URL at all)
    if (!isNonRegionalDataPlaneUri(devCenterUri)) {
        throw new ClientError('Given URL was not a valid Data Plane URL');
    }

    const [tenantIdAndDevCenterName, ...restOfHostname] = hostname.split('.');

    return `https://${tenantIdAndDevCenterName}.${location}.${restOfHostname.join('.')}`;
};

export const getTokensFromProjectDataPlaneUri = (uri: string): DataPlaneIdTokenSet<'devCenter' | 'projectName'> => {
    if (!isValidProjectDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a project');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName] = parseDataPlanePath(pathname);

    return {
        devCenter: origin,
        projectName,
    };
};

export const isValidProjectDataPlaneUri = (uri: string): boolean => isValidDataPlaneUri(uri, projectPathPattern);

export const tryCreateProjectDataPlaneUri = tryOrDefault(createProjectDataPlaneUri);
export const tryCreateProjectDataPlaneUriFromProject = tryOrDefault(createProjectDataPlaneUriFromProject);
export const tryGetDevCenterDataPlaneUriFromProject = tryOrDefault(getDevCenterDataPlaneUriFromProject);
export const tryGetTokensFromProjectDataPlaneUri = tryOrDefault(getTokensFromProjectDataPlaneUri);
