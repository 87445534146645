import { Text, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { AppSemanticColor } from '../../../themes/app-semantic-colors';
import { getSemanticColor } from '../../../utilities/styles';

const useTagTextStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.tagBackgroundColor),
        display: 'inline-flex',
        padding: '4px 6px',
        alignItems: 'flex-start',
        borderRadius: '2px',
        marginLeft: '5px',
        lineHeight: 'initial',
    },
}));

export const Tag: React.FC<React.PropsWithChildren<unknown>> = React.memo((props: React.PropsWithChildren<unknown>) => {
    const { children } = props;

    const textStyles = useTagTextStyles();

    return <Text styles={textStyles}>{children}</Text>;
});
