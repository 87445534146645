import { EntityState, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { EnvironmentFailureReason, EnvironmentProvisioningState } from '../../constants/environment';
import { Entity } from '../../models/common';
import { Environment, FailureOnEnvironment } from '../../models/environment';
import { compact } from '../../utilities/array';
import { getFailureOnEnvironmentFromEnvironment } from '../../utilities/environment';
import { ErrorOrFailedPayload, IndexedPayload, OptionalResultPayload, PayloadAction } from '../actions/core-actions';
import {
    addEnvironment,
    addEnvironmentAccepted,
    addEnvironmentError,
    addEnvironmentFailed,
    addEnvironmentOperationError,
    addEnvironmentOperationFailed,
    addEnvironmentOperationSuccess,
    clearAddEnvironmentFailure,
    clearDeployEnvironmentFailure,
    deleteEnvironment,
    deleteEnvironmentAccepted,
    deleteEnvironmentError,
    deleteEnvironmentFailed,
    deleteEnvironmentSuccess,
    deployEnvironment,
    deployEnvironmentAccepted,
    deployEnvironmentError,
    deployEnvironmentFailed,
    deployEnvironmentOperationError,
    deployEnvironmentOperationFailed,
    deployEnvironmentSuccess,
    discoverEnvironmentsInTenant,
    discoverEnvironmentsInTenantError,
    discoverEnvironmentsInTenantFailed,
    discoverEnvironmentsInTenantSuccess,
    listEnvironments,
    listEnvironmentsError,
    listEnvironmentsFailed,
    listEnvironmentsSuccess,
    updateEnvironment,
    updateEnvironmentError,
    updateEnvironmentFailed,
    updateEnvironmentSuccess,
} from '../actions/environment/environment-action-creators';
import {
    environmentAdapter,
    environmentProvisioningStateAdapter,
    failureOnEnvironmentAdapter,
} from '../adapters/environment-adapters';
import { EnvironmentDataStore, EnvironmentStatusStore, EnvironmentStore } from '../store/environment-store';
import { getFailure } from '../utilities/error-or-failed-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

/**
 * Helper reducers
 */

const clearPendingStateReducer = (
    store: EntityState<Entity<EnvironmentProvisioningState>>,
    action: PayloadAction<IndexedPayload>
) => {
    const { id } = getPayload(action);
    environmentProvisioningStateAdapter.removeOne(store, id);
};

const createSetFailureOnEnvironmentForReasonReducer =
    (reason: EnvironmentFailureReason) =>
    (
        store: EntityState<Entity<FailureOnEnvironment>>,
        action: PayloadAction<ErrorOrFailedPayload & IndexedPayload>
    ) => {
        const { id } = getPayload(action);
        const failure = getFailure(action);
        failureOnEnvironmentAdapter.setOne(store, Entity(id, { failure, reason }));
    };

const createSetPendingStateReducer =
    (pendingState: EnvironmentProvisioningState) =>
    (store: EntityState<Entity<EnvironmentProvisioningState>>, action: PayloadAction<IndexedPayload>) => {
        const { id } = getPayload(action);
        environmentProvisioningStateAdapter.setOne(store, Entity(id, pendingState));
    };

const removeFailureOnEnvironmentReducer = (
    store: EntityState<Entity<FailureOnEnvironment>>,
    action: PayloadAction<IndexedPayload>
) => {
    const { id } = getPayload(action);
    failureOnEnvironmentAdapter.removeOne(store, id);
};

const setEnvironmentReducer = (
    store: EntityState<Entity<Environment>>,
    action: PayloadAction<IndexedPayload & OptionalResultPayload<Environment>>
) => {
    const { id, result } = getPayload(action);

    if (result) {
        environmentAdapter.setOne(store, Entity(id, result));
    }
};

const setFailureOnEnvironmentFromCreateOperationReducer = createSetFailureOnEnvironmentForReasonReducer(
    EnvironmentFailureReason.CreateFailed
);

const setFailureOnEnvironmentFromDeleteOperationReducer = createSetFailureOnEnvironmentForReasonReducer(
    EnvironmentFailureReason.DeleteFailed
);

const setFailureOnEnvironmentFromDeployOperationReducer = createSetFailureOnEnvironmentForReasonReducer(
    EnvironmentFailureReason.DeployFailed
);

const setFailureOnEnvironmentFromUpdateOperationReducer = createSetFailureOnEnvironmentForReasonReducer(
    EnvironmentFailureReason.UpdateFailed
);

const setFailureOnEnvironmentFromEnvironmentReducer = (
    store: EntityState<Entity<FailureOnEnvironment>>,
    action: PayloadAction<IndexedPayload & OptionalResultPayload<Environment>>
) => {
    const { id, result } = getPayload(action);
    const failureOnEnvironment = result ? getFailureOnEnvironmentFromEnvironment(result) : undefined;

    failureOnEnvironment
        ? failureOnEnvironmentAdapter.setOne(store, Entity(id, failureOnEnvironment))
        : failureOnEnvironmentAdapter.removeOne(store, id.toLowerCase());
};

const setPendingStateToDeletingReducer = createSetPendingStateReducer(EnvironmentProvisioningState.Deleting);
const setPendingStateToDeployingReducer = createSetPendingStateReducer(EnvironmentProvisioningState.Deploying);
const setPendingStateToUpdatingReducer = createSetPendingStateReducer(EnvironmentProvisioningState.Updating);

/**
 * Data reducers
 */

const environmentsReducer = createReducer(EnvironmentDataStore().environments, (builder) => {
    builder
        .addCase(addEnvironmentAccepted, setEnvironmentReducer)
        .addCase(addEnvironmentOperationFailed, setEnvironmentReducer)
        .addCase(addEnvironmentOperationSuccess, setEnvironmentReducer)
        .addCase(deleteEnvironmentFailed, setEnvironmentReducer)
        .addCase(deployEnvironmentOperationFailed, setEnvironmentReducer)
        .addCase(deployEnvironmentSuccess, setEnvironmentReducer)
        .addCase(updateEnvironmentSuccess, setEnvironmentReducer);

    builder.addCase(deleteEnvironmentAccepted, (store, action) => {
        const { id } = getPayload(action);

        // Manually set state to deleting, as environment doesn't come back on the delete accepted response
        const entity = store.entities[id];

        if (entity) {
            const { data } = entity;

            environmentAdapter.upsertOne(
                store,
                Entity(id, { ...data, provisioningState: EnvironmentProvisioningState.Deleting })
            );
        }
    });

    builder.addCase(deleteEnvironmentSuccess, (store, action) => {
        const { id } = getPayload(action);
        environmentAdapter.removeOne(store, id);
    });

    builder.addCase(listEnvironmentsSuccess, (store, action) => {
        const { result } = getPayload(action);

        environmentAdapter.setMany(
            store,
            result.map((environment) => Entity(environment.uri, environment))
        );
    });
});

const failuresFromOperationsReducer = createReducer(EnvironmentDataStore().failuresFromOperations, (builder) => {
    builder
        .addCase(addEnvironmentOperationError, setFailureOnEnvironmentFromCreateOperationReducer)
        .addCase(addEnvironmentOperationFailed, setFailureOnEnvironmentFromCreateOperationReducer)
        .addCase(deleteEnvironment, removeFailureOnEnvironmentReducer)
        .addCase(deleteEnvironmentError, setFailureOnEnvironmentFromDeleteOperationReducer)
        .addCase(deleteEnvironmentFailed, setFailureOnEnvironmentFromDeleteOperationReducer)
        .addCase(deployEnvironment, removeFailureOnEnvironmentReducer)
        .addCase(deployEnvironmentOperationError, setFailureOnEnvironmentFromDeployOperationReducer)
        .addCase(deployEnvironmentOperationFailed, setFailureOnEnvironmentFromDeployOperationReducer)
        .addCase(updateEnvironment, removeFailureOnEnvironmentReducer)
        .addCase(updateEnvironmentError, setFailureOnEnvironmentFromUpdateOperationReducer)
        .addCase(updateEnvironmentFailed, setFailureOnEnvironmentFromUpdateOperationReducer);

    builder.addCase(deployEnvironmentError, (store, action) => {
        const { showPutErrorOnResource } = getPayload(action);

        if (showPutErrorOnResource) {
            setFailureOnEnvironmentFromDeployOperationReducer(store, action);
        }
    });

    builder.addCase(deployEnvironmentFailed, (store, action) => {
        const { showPutErrorOnResource } = getPayload(action);

        if (showPutErrorOnResource) {
            setFailureOnEnvironmentFromDeployOperationReducer(store, action);
        }
    });
});

const failuresFromResourcesReducer = createReducer(EnvironmentDataStore().failuresFromResources, (builder) => {
    builder
        .addCase(addEnvironmentAccepted, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(addEnvironmentOperationFailed, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(addEnvironmentOperationSuccess, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(deleteEnvironmentFailed, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(deployEnvironmentOperationFailed, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(deployEnvironmentSuccess, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(deployEnvironmentAccepted, setFailureOnEnvironmentFromEnvironmentReducer)
        .addCase(updateEnvironmentSuccess, setFailureOnEnvironmentFromEnvironmentReducer);

    builder.addCase(listEnvironmentsSuccess, (store, action) => {
        const { result } = getPayload(action);

        const failuresOnEnvironments = compact(
            result.map((environment) => {
                const { uri } = environment;
                const failureOnEnvironment = getFailureOnEnvironmentFromEnvironment(environment);

                if (!failureOnEnvironment) {
                    return undefined;
                }

                return Entity(uri, failureOnEnvironment);
            })
        );

        if (failuresOnEnvironments.length > 0) {
            failureOnEnvironmentAdapter.setMany(store, failuresOnEnvironments);
        }
    });
});

const pendingStatesReducer = createReducer(EnvironmentDataStore().pendingStates, (builder) => {
    builder
        .addCase(deleteEnvironment, setPendingStateToDeletingReducer)
        .addCase(deleteEnvironmentAccepted, clearPendingStateReducer)
        .addCase(deleteEnvironmentError, clearPendingStateReducer)
        .addCase(deleteEnvironmentFailed, clearPendingStateReducer)
        .addCase(deleteEnvironmentSuccess, clearPendingStateReducer)
        .addCase(deployEnvironmentAccepted, setPendingStateToDeployingReducer)
        .addCase(deployEnvironmentError, clearPendingStateReducer)
        .addCase(deployEnvironmentFailed, clearPendingStateReducer)
        .addCase(deployEnvironmentOperationError, clearPendingStateReducer)
        .addCase(deployEnvironmentOperationFailed, clearPendingStateReducer)
        .addCase(deployEnvironmentSuccess, clearPendingStateReducer)
        .addCase(updateEnvironment, setPendingStateToUpdatingReducer)
        .addCase(updateEnvironmentError, clearPendingStateReducer)
        .addCase(updateEnvironmentFailed, clearPendingStateReducer)
        .addCase(updateEnvironmentSuccess, clearPendingStateReducer);
});

/**
 * Core reducer
 */

export const environmentReducer = combineReducers<EnvironmentStore>({
    data: combineReducers<EnvironmentDataStore>({
        environments: environmentsReducer,
        failuresFromOperations: failuresFromOperationsReducer,
        failuresFromResources: failuresFromResourcesReducer,
        pendingStates: pendingStatesReducer,
    }),

    status: combineReducers<EnvironmentStatusStore>({
        addEnvironment: createStatusReducer({
            inProgress: addEnvironment,
            error: addEnvironmentError,
            failed: addEnvironmentFailed,
            success: addEnvironmentAccepted,
            reset: clearAddEnvironmentFailure,
        }),

        deployEnvironment: createStatusReducer({
            inProgress: deployEnvironment,
            error: deployEnvironmentError,
            failed: deployEnvironmentFailed,
            success: deployEnvironmentAccepted,
            reset: clearDeployEnvironmentFailure,
        }),

        discoverEnvironmentsInTenant: createStatusReducer({
            inProgress: discoverEnvironmentsInTenant,
            error: discoverEnvironmentsInTenantError,
            failed: discoverEnvironmentsInTenantFailed,
            success: discoverEnvironmentsInTenantSuccess,
        }),

        listEnvironments: createIndexedStatusReducer({
            inProgress: listEnvironments,
            error: listEnvironmentsError,
            failed: listEnvironmentsFailed,
            success: listEnvironmentsSuccess,
        }),

        updateEnvironment: createStatusReducer({
            inProgress: updateEnvironment,
            error: updateEnvironmentError,
            failed: updateEnvironmentFailed,
            success: updateEnvironmentSuccess,
        }),
    }),
});
