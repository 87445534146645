import { Environment } from '../constants/app';
import { ApplicationMode } from '../constants/application-mode';
import { DeepPartial } from '../types/deep-partial';
import { applicationMode, currentEnvironment } from '../utilities/app';
import devConfig from './settings.dev.json';
import baseConfig from './settings.json';
import localConfig from './settings.local.json';
import publicConfig from './settings.public.json';
import singleDevCenterConfig from './settings.single-devcenter.json';

interface ApplicationInsightsSettings {
    InstrumentationKey: string;
}

export interface Settings {
    ApplicationInsights: ApplicationInsightsSettings;
    AuthorityBaseUrl: string;
    AzureDevOpsBaseUrl: string;
    AzureResourceBaseUrl: string;
    ClientId: string;
    DataPlaneBaseHostName: string;
    GraphApiBaseUrl: string;
    SessionExpiredRedirectPath: string;
    SignInRedirectPath: string;
    SignInScopes: string[];
    SignOutRedirectPath: string;
    UnknownTenant: string;
    RDGatewayBaseUrl: string;
}

const mergeProperty = <T>(first: T | undefined, second: T | undefined) => {
    if (first === undefined && second === undefined) {
        return undefined;
    }

    if (second === undefined) {
        return first;
    }

    return second;
};

const mergeSettings = (first: DeepPartial<Settings>, second: DeepPartial<Settings>): DeepPartial<Settings> => ({
    ApplicationInsights: {
        InstrumentationKey: mergeProperty(
            first.ApplicationInsights?.InstrumentationKey,
            second.ApplicationInsights?.InstrumentationKey
        ),
    },
    AuthorityBaseUrl: mergeProperty(first.AuthorityBaseUrl, second.AuthorityBaseUrl),
    AzureDevOpsBaseUrl: mergeProperty(first.AzureDevOpsBaseUrl, second.AzureDevOpsBaseUrl),
    AzureResourceBaseUrl: mergeProperty(first.AzureResourceBaseUrl, second.AzureResourceBaseUrl),
    ClientId: mergeProperty(first.ClientId, second.ClientId),
    DataPlaneBaseHostName: mergeProperty(first.DataPlaneBaseHostName, second.DataPlaneBaseHostName),
    GraphApiBaseUrl: mergeProperty(first.GraphApiBaseUrl, second.GraphApiBaseUrl),
    SessionExpiredRedirectPath: mergeProperty(first.SessionExpiredRedirectPath, second.SessionExpiredRedirectPath),
    SignInRedirectPath: mergeProperty(first.SignInRedirectPath, second.SignInRedirectPath),
    SignInScopes: mergeProperty(first.SignInScopes, second.SignInScopes),
    SignOutRedirectPath: mergeProperty(first.SignOutRedirectPath, second.SignOutRedirectPath),
    UnknownTenant: mergeProperty(first.UnknownTenant, second.UnknownTenant),
    RDGatewayBaseUrl: mergeProperty(first.RDGatewayBaseUrl, second.RDGatewayBaseUrl),
});

const baseSettings = baseConfig as Partial<Settings>;
let developmentSettings: DeepPartial<Settings> | undefined = undefined;
let localSettings: DeepPartial<Settings> | undefined = undefined;
let publicSettings: DeepPartial<Settings> | undefined = undefined;

let singleDevCenterModeSettings: DeepPartial<Settings> | undefined = undefined;

export const getApplicationModeSettings = (applicationMode: ApplicationMode): Settings => {
    switch (applicationMode) {
        case ApplicationMode.SingleDevCenter:
            singleDevCenterModeSettings = singleDevCenterModeSettings
                ? singleDevCenterModeSettings
                : mergeSettings(baseSettings, singleDevCenterConfig);
            return singleDevCenterModeSettings as Settings;
        case ApplicationMode.AllDevCentersInTenant:
        default:
            return baseSettings as Settings;
    }
};

export const getSettings = (environment: Environment, applicationMode: ApplicationMode): Settings => {
    const applicationModeSettings = getApplicationModeSettings(applicationMode);

    switch (environment) {
        case Environment.Local:
            localSettings = localSettings
                ? localSettings
                : mergeSettings(applicationModeSettings, localConfig as DeepPartial<Settings>);
            return localSettings as Settings;
        case Environment.Development:
            developmentSettings = developmentSettings
                ? developmentSettings
                : mergeSettings(applicationModeSettings, devConfig as DeepPartial<Settings>);
            return developmentSettings as Settings;
        case Environment.Public:
        default:
            publicSettings = publicSettings
                ? publicSettings
                : mergeSettings(applicationModeSettings, publicConfig as DeepPartial<Settings>);
            return publicSettings as Settings;
    }
};

export const getSettingsForCurrentEnvironment = (): Settings => getSettings(currentEnvironment, applicationMode);

export const CurrentSettings = getSettingsForCurrentEnvironment();

export default CurrentSettings;
