import { PhotoSize } from '../../../constants/graph';
import { UnionValueMap } from '../../../types/union-map';
import { createInitializer } from '../../../utilities/initializer';
import { ActionName, ActionType } from '../core-actions';
import { getPhotoForSignedInUser, getSignedInUser, initializeUserMetadata } from './signed-in-user-action-creators';

/**
 * Action types
 */

type SignedInUserActionName =
    | ActionName<'GetPhotoForSignedInUser', 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetSignedInUser', 'Error' | 'Failed' | 'Success'>
    | ActionName<'InitializeUserMetadata', 'Error' | 'Failed' | 'Success'>;

export type SignedInUserActionType =
    | ActionType<'GET_PHOTO_FOR_SIGNED_IN_USER', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_SIGNED_IN_USER', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'INITIALIZE_USER_METADATA', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const SignedInUserActionType: UnionValueMap<SignedInUserActionName, SignedInUserActionType> = {
    GetPhotoForSignedInUser: 'GET_PHOTO_FOR_SIGNED_IN_USER',
    GetPhotoForSignedInUserError: 'GET_PHOTO_FOR_SIGNED_IN_USER_ERROR',
    GetPhotoForSignedInUserFailed: 'GET_PHOTO_FOR_SIGNED_IN_USER_FAILED',
    GetPhotoForSignedInUserSuccess: 'GET_PHOTO_FOR_SIGNED_IN_USER_SUCCESS',
    GetSignedInUser: 'GET_SIGNED_IN_USER',
    GetSignedInUserError: 'GET_SIGNED_IN_USER_ERROR',
    GetSignedInUserFailed: 'GET_SIGNED_IN_USER_FAILED',
    GetSignedInUserSuccess: 'GET_SIGNED_IN_USER_SUCCESS',
    InitializeUserMetadata: 'INITIALIZE_USER_METADATA',
    InitializeUserMetadataError: 'INITIALIZE_USER_METADATA_ERROR',
    InitializeUserMetadataFailed: 'INITIALIZE_USER_METADATA_FAILED',
    InitializeUserMetadataSuccess: 'INITIALIZE_USER_METADATA_SUCCESS',
};

/**
 * Payloads
 */

export interface GetPhotoForSignedInUserPayload {
    photoSize: PhotoSize;
}

export interface GetPhotoForSignedInUserSuccessPayload {
    photoUrl: string;
}

/**
 * Initializers
 */

export const GetPhotoForSignedInUserPayload = createInitializer<GetPhotoForSignedInUserPayload>({
    photoSize: PhotoSize.Original,
});

/**
 * Action shorthands
 */

export type GetPhotoForSignedInUserAction = ReturnType<typeof getPhotoForSignedInUser>;
export type GetSignedInUserAction = ReturnType<typeof getSignedInUser>;
export type InitializeUserMetadataAction = ReturnType<typeof initializeUserMetadata>;
