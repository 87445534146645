import { ITheme } from '@fluentui/react';
import { IAppSemanticColors } from '../themes/app-semantic-colors';
import { IExtendedSemanticColors } from '../themes/extended-semantic-colors';

export const getSemanticColor = (
    theme: ITheme,
    semanticSlot: keyof (IExtendedSemanticColors & IAppSemanticColors)
): string => (theme.semanticColors as IExtendedSemanticColors & IAppSemanticColors)[semanticSlot];

export const getSemanticColors = (
    theme: ITheme,
    ...semanticSlots: (keyof (IExtendedSemanticColors & IAppSemanticColors))[]
): string[] => {
    const semanticColors = theme.semanticColors as IExtendedSemanticColors & IAppSemanticColors;
    return semanticSlots.map((semanticSlot) => semanticColors[semanticSlot]);
};
