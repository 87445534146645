import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListSchedulesByProjectResponse } from '../../../data/services/data-plane-api/schedule';
import {
    AggregatedPartialSuccess,
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses, createFailureResponseFromThrown } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverSchedulesForPools,
    discoverSchedulesForPoolsError,
    discoverSchedulesForPoolsFailed,
    discoverSchedulesForPoolsSuccess,
    listSchedulesByProject,
} from '../../actions/schedule/schedule-action-creators';
import { DiscoverSchedulesForPoolsAction } from '../../actions/schedule/schedule-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getProjectUrisForPoolsRequiringSchedules } from '../../selector/pool-selectors';
import { getHasSchedules } from '../../selector/schedule-selectors';
import { AsyncOutcome } from '../../store/common-state';
import { createTryCallSaga } from '../../utilities/try-call';

// Note: this sub-operation pattern allows us to conditionally throw based on whether we have any schedules
export function* performDiscoverSchedulesForPools(): SagaIterator<AggregatedResult> {
    try {
        const schedulesResult: AggregatedResult = yield putAndAwait(discoverSchedulesForPools());

        return schedulesResult;
    } catch (err) {
        // If we have already successfully loaded some schedules during card content discovery,
        // we don't want to fail discovery for schedules for pools even if we throw an internal error.
        const hasSchedulesForDevBoxes = yield select(getHasSchedules);

        if (hasSchedulesForDevBoxes) {
            const failure = createFailureResponseFromThrown(err, FailureOperation.DiscoverSchedulesForPools);
            return AggregatedPartialSuccess(failure);
        }

        throw err;
    }
}

export const tryPerformDiscoverSchedulesForPools = createTryCallSaga(performDiscoverSchedulesForPools);

export function* discoverSchedulesForPoolsSaga(action: DiscoverSchedulesForPoolsAction): SagaIterator {
    const startTime = new Date();

    try {
        const uris: string[] = yield select(getProjectUrisForPoolsRequiringSchedules);

        const responses: ListSchedulesByProjectResponse[] = yield all(
            uris.map((id) => putAndAwait(listSchedulesByProject({ id })))
        );

        const completeResult = combineResults(responses);

        // If we have already successfully loaded some schedules during card content discovery,
        // we don't want to fail discovery for schedules for pools even if all requests fail.
        const hasSchedulesForDevBoxes: boolean = yield select(getHasSchedules);

        if (!hasSchedulesForDevBoxes && isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.DiscoverSchedulesForPools, ...failures);
            yield put(discoverSchedulesForPoolsFailed({ failure }));
        } else {
            yield put(discoverSchedulesForPoolsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverSchedulesForPools,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DiscoverSchedulesForPools);
        yield put(discoverSchedulesForPoolsError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverSchedulesForPools,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverSchedulesForPoolsListenerSaga(): SagaIterator {
    yield takeLeading(discoverSchedulesForPools, discoverSchedulesForPoolsSaga);
}
