import { DataResponse } from '../../../models/common';
import { Tenant } from '../../../models/resource-manager';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, ResultPayload } from '../core-actions';
import { TenantActionType } from './tenant-actions';

export const listTenants = createActionCreatorWithoutPayload<DataResponse<Tenant[]>>(TenantActionType.ListTenants, {
    async: true,
    startsActivity: true,
});

export const listTenantsError = createActionCreator<ErrorPayload>(TenantActionType.ListTenantsError);
export const listTenantsFailed = createActionCreator<FailedPayload>(TenantActionType.ListTenantsFailed);
export const listTenantsSuccess = createActionCreator<ResultPayload<Tenant[]>>(TenantActionType.ListTenantsSuccess);

export const selectTenant = createActionCreator<IndexedPayload>(TenantActionType.SelectTenant);
export const selectTenantError = createActionCreator<ErrorPayload & IndexedPayload>(TenantActionType.SelectTenantError);
export const selectTenantRedirecting = createActionCreator<IndexedPayload>(TenantActionType.SelectTenantRedirecting);
