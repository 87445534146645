import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { isValidDataPlaneUri, parseDataPlanePath } from '../ids/data-plane';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { createProjectDataPlaneUri } from './project';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const environmentTypePathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.EnvironmentType}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createEnvironmentTypeDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'devCenter' | 'environmentTypeName' | 'projectName'>
): string => {
    const { devCenter, environmentTypeName, projectName } = tokens;

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(environmentTypeName)) {
        throw new ClientError('Environment type name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.EnvironmentType,
        environmentTypeName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromEnvironmentTypeDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'devCenter' | 'environmentTypeName' | 'projectName'> => {
    if (!isValidEnvironmentTypeDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for an environment type');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , environmentTypeName] = parseDataPlanePath(pathname);

    return {
        devCenter: origin,
        environmentTypeName,
        projectName,
    };
};

export const createProjectDataPlaneUriFromEnvironmentTypeUri = (uri: string): string => {
    const { projectName, devCenter } = getTokensFromEnvironmentTypeDataPlaneUri(uri);
    const projectUri = createProjectDataPlaneUri({ devCenter, projectName });

    return projectUri;
};

export const isValidEnvironmentTypeDataPlaneUri = (uri: string): boolean =>
    isValidDataPlaneUri(uri, environmentTypePathPattern);

export const tryCreateEnvironmentTypeDataPlaneUri = tryOrDefault(createEnvironmentTypeDataPlaneUri);
export const tryGetTokensFromEnvironmentTypeDataPlaneUri = tryOrDefault(getTokensFromEnvironmentTypeDataPlaneUri);
