import { IStackTokens, Label, makeStyles, Panel, PanelType, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDevBoxDetailsPanelContext } from '../../../hooks/context/panels';
import { ExtendedIcon } from '../../../icons/initialize-extended-icons';
import { HardwareProfile, HibernateSupport, ImageReference, StorageProfile } from '../../../models/pool';
import { AzureLocation } from '../../../models/resource-manager';
import { getLocations } from '../../../redux/selector/subscription-selectors';
import { useDetailsPanelStyles } from '../../../themes/styles/details-panel-styles';
import { SerializableMap } from '../../../types/serializable-map';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import MetadataList from '../../common/metadata/metadata-list';
import { MetadataItemViewModel } from '../../common/metadata/models';
import { CPUMetadata, DiskSizeMetadata, MemoryMetadata } from '../dev-box-metadata/dev-box-metadata';
import { HibernateReadonlyField } from '../hibernate-readonly-field';

export interface DevBoxDetailsPanelProps {
    isOpen: boolean;
    name: string;
    poolName: string;
    locations: SerializableMap<AzureLocation>;
    createdOn?: Date;
    location?: string;
    imageReference?: ImageReference;
    hardwareProfile?: HardwareProfile;
    storageProfile?: StorageProfile;
    scheduledStopTime?: string;
    onDismiss: () => void;
    hibernateSupport: HibernateSupport;
}

const devBoxDetailsPanelMessages = defineMessages({
    devBoxDetailsPanelCloseButtonLabel: {
        id: 'DevBoxDetails_Panel_Close_Label',
        defaultMessage: 'Close dev box details panel',
        description: 'Aria label for the dev box details panel close button label',
    },
    devBoxDetailsPanelHeaderText: {
        id: 'DevBoxDetails_Panel_Header_Text',
        defaultMessage: '{name} details',
        description: 'Text for the dev box details panel. Do not localize {name}. Do not localize {name}.',
    },
    devBoxDetailsPanelPoolLocationText: {
        id: 'DevBoxDetails_Panel_PoolLocation_Text',
        defaultMessage: '{poolName} ({location})',
        description:
            'Text for the dev box details panel pool and location value text. Do not localize {poolName} or {location}.',
    },
    devBoxDetailsPanelPoolText: {
        id: 'DevBoxDetails_Panel_Pool_Text',
        defaultMessage: '{poolName}',
        description: 'Text for the dev box details panel image value text. Do not localize {poolName}.',
    },
    devBoxDetailsPanelImageText: {
        id: 'DevBoxDetails_Panel_Image_Text',
        defaultMessage: '{imageName}',
        description: 'Text for the dev box details panel image value text. Do not localize {imageName}.',
    },
    devBoxDetailsPanelImageWithVersionText: {
        id: 'DevBoxDetails_Panel_ImageWithVersion_Text',
        defaultMessage: '{imageName} (version {imageVersion})',
        description:
            'Text for the dev box details panel image value text when we have a version. Do not localize {imageName} and {imageVersion}.',
    },
    cpuIconAriaLabel: {
        id: 'DevBoxDetails_Panel_CpuIcon_AriaLabel',
        defaultMessage: 'Number of cores',
        description: 'Aria label for CPU icon in dev box details panel.',
    },
    diskIconAriaLabel: {
        id: 'DevBoxDetails_Panel_DiskIcon_AriaLabel',
        defaultMessage: 'Total storage in GB',
        description: 'Aria label for disk icon in dev box details panel.',
    },
    memoryIconAriaLabel: {
        id: 'DevBoxDetails_Panel_MemoryIcon_AriaLabel',
        defaultMessage: 'Total RAM in GB',
        description: 'Aria label for memory icon in dev box details panel.',
    },
});

/**
 * Style Section
 */

const useMetadataStyles = makeStyles({
    root: {
        paddingTop: '30px',
    },
});

const contentTokens: IStackTokens = {
    childrenGap: 31,
};

const itemTokens: IStackTokens = {
    childrenGap: 11,
};

/* END */

const DevBoxDetailsPanelComponent: React.FC<DevBoxDetailsPanelProps> = (props: DevBoxDetailsPanelProps) => {
    const {
        isOpen,
        onDismiss,
        name,
        createdOn,
        imageReference,
        scheduledStopTime,
        hardwareProfile,
        storageProfile,
        hibernateSupport,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const panelStyles = useDetailsPanelStyles();
    const metadataStyles = useMetadataStyles();

    const imageInformation = React.useMemo(() => {
        if (!imageReference || isUndefinedOrWhiteSpace(imageReference.name)) {
            return undefined;
        }

        const { name: imageName, version: imageVersion } = imageReference;

        return isNotUndefinedOrWhiteSpace(imageVersion)
            ? formatMessage(devBoxDetailsPanelMessages.devBoxDetailsPanelImageWithVersionText, {
                  imageName,
                  imageVersion,
              })
            : formatMessage(devBoxDetailsPanelMessages.devBoxDetailsPanelImageText, { imageName });
    }, [imageReference]);

    // Resource card metadata
    const cpuMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(devBoxDetailsPanelMessages.cpuIconAriaLabel),
            icon: ExtendedIcon.CPU,
            key: 'cpu',
            primary: false,
            value: <CPUMetadata cpuCount={hardwareProfile?.vCPUs} />,
        }),
        [hardwareProfile?.vCPUs, formatMessage]
    );

    const diskSizeMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(devBoxDetailsPanelMessages.diskIconAriaLabel),
            icon: 'OfflineStorage',
            key: 'disk-size',
            primary: false,
            value: <DiskSizeMetadata diskSizeInGb={storageProfile?.osDisk?.diskSizeGB} />,
        }),
        [storageProfile?.osDisk?.diskSizeGB, formatMessage]
    );

    const memoryMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(devBoxDetailsPanelMessages.memoryIconAriaLabel),
            icon: ExtendedIcon.RAM,
            key: 'memory',
            primary: false,
            value: <MemoryMetadata memoryInGb={hardwareProfile?.memoryGB} />,
        }),
        [hardwareProfile?.memoryGB, formatMessage]
    );

    const metadata = React.useMemo(
        () => [cpuMetadata, memoryMetadata, diskSizeMetadata],
        [cpuMetadata, diskSizeMetadata, memoryMetadata]
    );

    return (
        <Panel
            customWidth={'369px'}
            type={PanelType.custom}
            isOpen={isOpen}
            headerText={formatMessage(devBoxDetailsPanelMessages.devBoxDetailsPanelHeaderText, { name })}
            closeButtonAriaLabel={formatMessage(devBoxDetailsPanelMessages.devBoxDetailsPanelCloseButtonLabel)}
            onDismiss={onDismiss}
            isLightDismiss
            styles={panelStyles}
        >
            <Stack tokens={contentTokens}>
                {createdOn && (
                    <Stack.Item>
                        <Stack tokens={itemTokens}>
                            <Stack.Item>
                                <Label>
                                    <FormattedMessage
                                        id="DevBoxDetails_Panel_CreatedOn_Submenu_Title"
                                        defaultMessage="Created"
                                        description="Text for the dev box details panel created on submenu title"
                                    />
                                </Label>
                            </Stack.Item>
                            <Stack.Item>
                                <FormattedDate value={createdOn} year="numeric" month="long" day="numeric" />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}

                {isNotUndefinedOrWhiteSpace(imageInformation) && (
                    <Stack.Item>
                        <Stack tokens={itemTokens}>
                            <Stack.Item>
                                <Label>
                                    <FormattedMessage
                                        id="DevBoxDetails_Panel_Image_Submenu_Title"
                                        defaultMessage="Source image"
                                        description="Text for the dev box details panel image submenu title"
                                    />
                                </Label>
                            </Stack.Item>
                            <Stack.Item>{imageInformation}</Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}

                {isNotUndefinedOrWhiteSpace(scheduledStopTime) && (
                    <Stack.Item>
                        <Stack tokens={itemTokens}>
                            <Stack.Item>
                                <Label>
                                    <FormattedMessage
                                        id="DevBoxDetails_Panel_Schedule_Submenu_Title"
                                        defaultMessage="Scheduled shut down time"
                                        description="Text for the dev box details panel schedule submenu title"
                                    />
                                </Label>
                            </Stack.Item>
                            <Stack.Item>{scheduledStopTime}</Stack.Item>
                        </Stack>
                    </Stack.Item>
                )}

                <Stack.Item>
                    <Stack tokens={itemTokens}>
                        <HibernateReadonlyField hibernateSupport={hibernateSupport} />
                    </Stack>
                </Stack.Item>

                <Stack.Item styles={metadataStyles}>
                    <MetadataList metadataList={metadata} containerTokens={10} />
                </Stack.Item>
            </Stack>
        </Panel>
    );
};

const DevBoxDetailsPanel: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useDevBoxDetailsPanelContext();

    const locations = useSelector(getLocations);
    const onDismiss = React.useCallback(() => closePanel(), [closePanel]);

    return <DevBoxDetailsPanelComponent {...properties} isOpen={isOpen} onDismiss={onDismiss} locations={locations} />;
};

export const DevBoxDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { isOpen } = useDevBoxDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DevBoxDetailsPanel />;
};

export default DevBoxDetailsPanelContainer;
