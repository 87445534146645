import { createSelector } from '@reduxjs/toolkit';
import { Status } from 'src/models/common';
import { getTokensFromEnvironmentDataPlaneUri } from '../../ids/environment';
import { createProjectDataPlaneUri } from '../../ids/project';
import { FailureOnEnvironment } from '../../models/environment';
import { SerializableMap } from '../../types/serializable-map';
import { map, set, size } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import { environmentAdapter, environmentProvisioningStateAdapter } from '../adapters/environment-adapters';
import { StoreState } from '../store/store-state';
import {
    StoreStateSelector,
    createArrayFromEntitiesSelector,
    createGroupedMapFromEntitiesSelector,
    createKeysSelector,
    createMapFromEntitiesSelector,
    getDataById,
    getStringIdsSelector,
} from './common';

/**
 * Basic selectors
 */

const getFailuresFromOperations = (store: StoreState) => store.environmentStore.data.failuresFromOperations;
const getFailuresFromResources = (store: StoreState) => store.environmentStore.data.failuresFromResources;

export const getStatusForAddEnvironment: StoreStateSelector<Status> = (store) =>
    store.environmentStore.status.addEnvironment;

export const getStatusForDeployEnvironment: StoreStateSelector<Status> = (store) =>
    store.environmentStore.status.deployEnvironment;

export const getStatusForUpdateEnvironment: StoreStateSelector<Status> = (store) =>
    store.environmentStore.status.updateEnvironment;

/**
 * Entity state selectors
 */

const environmentsSelectors = environmentAdapter.getSelectors<StoreState>(
    (store) => store.environmentStore.data.environments
);

export const getEnvironmentIds = getStringIdsSelector(
    environmentAdapter,
    (store) => store.environmentStore.data.environments
);

const pendingStatesSelectors = environmentProvisioningStateAdapter.getSelectors<StoreState>(
    (store) => store.environmentStore.data.pendingStates
);

const statusesForListEnvironmentsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.environmentStore.status.listEnvironments
);

/**
 * Composed selectors
 */

export const getEnvironments = createArrayFromEntitiesSelector(environmentsSelectors.selectAll);

export const getEnvironmentsByProject = createGroupedMapFromEntitiesSelector(
    environmentsSelectors.selectAll,
    (entity) => {
        const { id } = entity;
        const { devCenter, projectName } = getTokensFromEnvironmentDataPlaneUri(id);

        return createProjectDataPlaneUri({ devCenter, projectName });
    }
);

export const getEnvironmentCountByProject: StoreStateSelector<SerializableMap<number>> = createSelector(
    [getEnvironmentsByProject],
    (environments) => map(environments, (value) => size(value))
);

export const getFailuresOnEnvironments: StoreStateSelector<SerializableMap<FailureOnEnvironment>> = createSelector(
    [getEnvironmentIds, getFailuresFromOperations, getFailuresFromResources],
    (ids, failuresFromOperations, failuresFromResources) => {
        let failuresOnEnvironments = SerializableMap<FailureOnEnvironment>();

        ids.forEach((id) => {
            const failureFromOperations = getDataById(failuresFromOperations, id);
            const failureFromResource = getDataById(failuresFromResources, id);

            // For now, all operation failures take precedence over provisioning failures.
            if (failureFromOperations) {
                failuresOnEnvironments = set(failuresOnEnvironments, id, failureFromOperations);
            } else if (failureFromResource) {
                failuresOnEnvironments = set(failuresOnEnvironments, id, failureFromResource);
            }
        });

        return failuresOnEnvironments;
    }
);

export const getHasEnvironments: StoreStateSelector<boolean> = createSelector(
    [environmentsSelectors.selectTotal],
    (total) => total > 0
);

export const getPendingStatesForEnvironments = createMapFromEntitiesSelector(pendingStatesSelectors.selectAll);

export const getProjectIdsFromEnvironments = createKeysSelector(getEnvironmentsByProject);

export const getStatusesForListEnvironments = createMapFromEntitiesSelector(
    statusesForListEnvironmentsSelectors.selectAll
);
