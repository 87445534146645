import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListCustomizationGroupsResponse } from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, isAggregatedFailure, isAggregatedSuccess } from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    listCustomizationGroups,
    loadCustomizationGroups,
    loadCustomizationGroupsError,
    loadCustomizationGroupsFailed,
    loadCustomizationGroupsSuccess,
} from '../../actions/customization/customization-action-creators';
import { LoadCustomizationGroupsAction } from '../../actions/customization/customization-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getIdsForDevBoxesRequiringCustomizationGroups } from './selectors';

export function* loadCustomizationGroupsSaga(action: LoadCustomizationGroupsAction): SagaIterator {
    const startTime = new Date();

    try {
        // TODO: update this once we get a way to only do the load for devboxes that have customization groups associated
        const ids: string[] = yield select(getIdsForDevBoxesRequiringCustomizationGroups);

        const responses: ListCustomizationGroupsResponse[] = yield all(
            ids.map((id) => putAndAwait(listCustomizationGroups({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            // All requests failed
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadCustomizationGroups, ...failures);
            yield put(loadCustomizationGroupsFailed({ failure }));
        } else {
            yield put(loadCustomizationGroupsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadCustomizationGroups,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadCustomizationGroups);
        yield put(loadCustomizationGroupsError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadCustomizationGroups,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadCustomizationGroupsListenerSaga(): SagaIterator {
    yield takeLeading(loadCustomizationGroups, loadCustomizationGroupsSaga);
}
