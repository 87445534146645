import { GetLongRunningOperationStatusResponse } from '../../../data/services/data-plane-api/long-running-operation';
import { createActionCreator } from '../core-action-creators';
import { ErrorPayload, FailedPayload } from '../core-actions';
import {
    LongRunningOperationActionType,
    PollLongRunningOperationCompletePayload,
    PollLongRunningOperationPayload,
} from './long-running-operation-actions';

export const pollOperationStatus = createActionCreator<
    PollLongRunningOperationPayload,
    GetLongRunningOperationStatusResponse
>(LongRunningOperationActionType.PollOperationStatus, { async: true, startsActivity: true });

export const pollOperationStatusError = createActionCreator<ErrorPayload>(
    LongRunningOperationActionType.PollOperationStatusError
);

export const pollOperationStatusFailed = createActionCreator<FailedPayload>(
    LongRunningOperationActionType.PollOperationStatusFailed
);

export const pollOperationStatusComplete = createActionCreator<PollLongRunningOperationCompletePayload>(
    LongRunningOperationActionType.PollOperationStatusComplete
);
