import { EventName, Metric, Property } from '../../constants/telemetry';
import {
    TelemetryFilteringAction,
    TelemetryFilteringActionTarget,
    TelemetryFilteringRuleSet,
} from '../../models/telemetry';
import { CustomizationActionType } from '../../redux/actions/customization/customization-actions';
import { DevBoxActionType } from '../../redux/actions/dev-box/dev-box-actions';
import { EnvironmentActionType } from '../../redux/actions/environment/environment-actions';
import { FeaturesActionType } from '../../redux/actions/features/features-actions';
import { LocalizationActionType } from '../../redux/actions/localization/localization-actions';
import { TelemetryActionType } from '../../redux/actions/telemetry/telemetry-actions';

const rules: TelemetryFilteringRuleSet = {
    all: [
        {
            baseData: {
                id: TelemetryFilteringAction.Allow,
                method: TelemetryFilteringAction.Allow,
                name: TelemetryFilteringAction.Allow,
                severityLevel: TelemetryFilteringAction.Allow,
                uri: TelemetryFilteringAction.Allow,

                properties: {
                    [Property.ActivityId]: TelemetryFilteringAction.Allow,
                    [Property.ClientRequestId]: TelemetryFilteringAction.Allow,
                    [Property.DevCenter]: TelemetryFilteringAction.Allow,
                    [Property.EnvironmentType]: TelemetryFilteringAction.Allow,
                    [Property.HomeTenantId]: TelemetryFilteringAction.Allow,
                    [Property.Outcome]: TelemetryFilteringAction.Allow,
                    [Property.ServerOperationId]: TelemetryFilteringAction.Allow,
                    [Property.Service]: TelemetryFilteringAction.Allow,
                    [Property.Severity]: TelemetryFilteringAction.Allow,
                    [Property.SeverityLevel]: TelemetryFilteringAction.Allow,
                    [Property.TenantId]: TelemetryFilteringAction.Allow,
                    [Property.Username]: TelemetryFilteringAction.Allow,
                    [Property.Version]: TelemetryFilteringAction.Allow,
                    [Property.FeatureFlags]: TelemetryFilteringAction.Allow,
                },
            },
        },
    ],
    dependency: [
        {
            baseData: {
                correlationContext: TelemetryFilteringAction.Allow,
                data: {
                    action: TelemetryFilteringAction.Scrub,
                    target: TelemetryFilteringActionTarget.UserIdInPath,
                },
                duration: TelemetryFilteringAction.Allow,
                name: {
                    action: TelemetryFilteringAction.Scrub,
                    target: TelemetryFilteringActionTarget.UserIdInPath,
                },
                responseCode: TelemetryFilteringAction.Allow,
                success: TelemetryFilteringAction.Allow,
                target: TelemetryFilteringAction.Allow,
                type: TelemetryFilteringAction.Allow,

                properties: {
                    [Property.Hostname]: TelemetryFilteringAction.Allow,
                    HttpMethod: TelemetryFilteringAction.Allow,
                    [Property.Kind]: TelemetryFilteringAction.Allow,
                    [Property.Method]: TelemetryFilteringAction.Allow,
                    [Property.Pathname]: TelemetryFilteringAction.Allow,
                    requestHeaders: {
                        traceparent: TelemetryFilteringAction.Allow,
                        'x-ms-client-request-id': TelemetryFilteringAction.Allow,
                        'x-ms-dev-portal-activity-id': TelemetryFilteringAction.Allow,
                    },
                    responseHeaders: {
                        'cache-control': TelemetryFilteringAction.Allow,
                        'content-length': TelemetryFilteringAction.Allow,
                        'content-type': TelemetryFilteringAction.Allow,
                        'x-ms-client-request-id': TelemetryFilteringAction.Allow,
                        'x-ms-correlation-request-id': TelemetryFilteringAction.Allow,
                    },
                    [Property.Search]: TelemetryFilteringAction.Allow,
                },
            },
        },
    ],
    event: [
        {
            baseData: {
                properties: {
                    'actionDispatched-activityId': TelemetryFilteringAction.Allow,
                    'actionDispatched-error': {
                        code: TelemetryFilteringAction.Allow,
                        name: TelemetryFilteringAction.Allow,
                        operation: TelemetryFilteringAction.Allow,
                    },
                    'actionDispatched-failure': {
                        code: TelemetryFilteringAction.Allow,
                        operation: TelemetryFilteringAction.Allow,
                        statusCode: TelemetryFilteringAction.Allow,
                    },
                    'actionDispatched-meta': {
                        activityId: TelemetryFilteringAction.Allow,
                    },
                    'actionDispatched-payload': {
                        error: {
                            code: TelemetryFilteringAction.Allow,
                            name: TelemetryFilteringAction.Allow,
                            operation: TelemetryFilteringAction.Allow,
                        },
                        failure: {
                            code: TelemetryFilteringAction.Allow,
                            operation: TelemetryFilteringAction.Allow,
                            statusCode: TelemetryFilteringAction.Allow,
                        },
                        id: {
                            action: TelemetryFilteringAction.Scrub,
                            target: TelemetryFilteringActionTarget.UserIdInPath,
                        },
                        result: TelemetryFilteringAction.Count,
                    },
                    'actionDispatched-type': TelemetryFilteringAction.Allow,
                },
            },
        },
        {
            name: EventName.ONLINE_STATUS_CHANGE,
            baseData: {
                properties: {
                    isOnline: TelemetryFilteringAction.Allow,
                    navigatorOnLine: TelemetryFilteringAction.Allow,
                },
            },
        },
        {
            name: EventName.DevBoxInFailedStateWithNoError,
            baseData: {
                properties: {
                    actionType: TelemetryFilteringAction.Allow,
                    devBoxId: TelemetryFilteringAction.Allow,
                    provisioningState: TelemetryFilteringAction.Allow,
                    devBoxState: TelemetryFilteringAction.Allow,
                },
            },
        },
        {
            name: EventName.EnvironmentInFailedStateWithNoError,
            baseData: {
                properties: {
                    actionType: TelemetryFilteringAction.Allow,
                    provisioningState: TelemetryFilteringAction.Allow,
                },
            },
        },
        {
            reduxActionType: DevBoxActionType.AddDevBox,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        customizationGroupId: TelemetryFilteringAction.Allow,
                        name: TelemetryFilteringAction.Allow,
                        poolName: TelemetryFilteringAction.Allow,
                        taskList: TelemetryFilteringAction.Count,
                    },
                },
            },
        },
        {
            reduxActionType: EnvironmentActionType.AddEnvironment,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        catalog: TelemetryFilteringAction.Allow,
                        environmentDefinition: TelemetryFilteringAction.Allow,
                        environmentType: TelemetryFilteringAction.Allow,
                        expirationDate: TelemetryFilteringAction.Allow,
                        name: TelemetryFilteringAction.Allow,
                    },
                },
            },
        },
        {
            reduxActionType: CustomizationActionType.CreateCustomizationGroup,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        tasks: TelemetryFilteringAction.Count,
                    },
                },
            },
        },
        {
            reduxActionType: [TelemetryActionType.DeleteGlobalProperty, TelemetryActionType.SetGlobalProperty],
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        propertyName: TelemetryFilteringAction.Allow,
                    },
                },
            },
        },
        {
            reduxActionType: FeaturesActionType.InitializeFeaturesSuccess,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        features: TelemetryFilteringAction.Allow,
                    },
                },
            },
        },
        {
            reduxActionType: LocalizationActionType.InitializeLocalizationSuccess,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        language: TelemetryFilteringAction.Allow,
                        locale: TelemetryFilteringAction.Allow,
                        market: TelemetryFilteringAction.Allow,
                        usingDefaultAsFallback: TelemetryFilteringAction.Allow,
                    },
                },
            },
        },
        {
            reduxActionType: CustomizationActionType.ValidateCustomizationTasks,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        tasks: TelemetryFilteringAction.Count,
                    },
                },
            },
        },
        {
            reduxActionType: /^DISMISS_MESSAGE/,
            baseData: {
                properties: {
                    'actionDispatched-message': TelemetryFilteringAction.Allow,
                },
            },
        },
        {
            reduxActionType: /^DISMISS_QUICK_ACTION/,
            baseData: {
                properties: {
                    'actionDispatched-quickAction': TelemetryFilteringAction.Allow,
                },
            },
        },
        {
            reduxActionType: /^GET_GRAPH_DIRECTORY_OBJECT/,
            baseData: {
                properties: {
                    'actionDispatched-payload': {
                        // id for these actions is potentially a user object ID
                        id: TelemetryFilteringAction.Block,
                    },
                },
            },
        },
    ],
    exception: [
        {
            baseData: {
                exceptions: TelemetryFilteringAction.Allow,
                hasFullStack: TelemetryFilteringAction.Allow,
                message: TelemetryFilteringAction.Allow,
                stack: TelemetryFilteringAction.Allow,
                typeName: TelemetryFilteringAction.Allow,

                properties: {
                    [Property.ErrorCode]: TelemetryFilteringAction.Allow,
                    [Property.InnerStack]: TelemetryFilteringAction.Allow,
                    url: TelemetryFilteringAction.Allow,
                },
            },
        },
    ],
    metric: [
        {
            baseData: {
                average: TelemetryFilteringAction.Allow,
                max: TelemetryFilteringAction.Allow,
                min: TelemetryFilteringAction.Allow,
                sampleCount: TelemetryFilteringAction.Allow,
            },
            data: {
                [Property.ActivityId]: TelemetryFilteringAction.Allow,
                [Property.ClientRequestId]: TelemetryFilteringAction.Allow,
                [Property.DevCenter]: TelemetryFilteringAction.Allow,
                [Property.HomeTenantId]: TelemetryFilteringAction.Allow,
                [Property.EnvironmentType]: TelemetryFilteringAction.Allow,
                [Property.ErrorCodes]: TelemetryFilteringAction.Allow,
                [Property.Outcome]: TelemetryFilteringAction.Allow,
                [Property.ServerOperationId]: TelemetryFilteringAction.Allow,
                [Property.Service]: TelemetryFilteringAction.Allow,
                [Property.Severity]: TelemetryFilteringAction.Allow,
                [Property.SeverityLevel]: TelemetryFilteringAction.Allow,
                [Property.TenantId]: TelemetryFilteringAction.Allow,
                [Property.Username]: TelemetryFilteringAction.Allow,
                [Property.Version]: TelemetryFilteringAction.Allow,
                [Property.PreferredRemoteApp]: TelemetryFilteringAction.Allow,
                [Property.MultiMonitor]: TelemetryFilteringAction.Allow,
                [Property.FeatureFlags]: TelemetryFilteringAction.Allow,
            },
        },
        {
            name: [
                Metric.HelpMenuPanelTroubleShootLinkClicked,
                Metric.HelpMenuPanelInternalSupportLinkClicked,
                Metric.HelpMenuPanelExternalSupportLinkClicked,
                Metric.SupportPanelExternalSupportLinkClicked,
                Metric.SupportPanelInternalSupportLinkClicked,
                Metric.SupportPanelTroubleShootLinkClicked,
                Metric.InstallRemoteClientLinkClicked,
                Metric.HelpMenuPanelAdeFeedbackLinkClicked,
                Metric.HelpMenuPanelDevBoxFeedbackLinkClicked,
                Metric.HelpMenuPanelMultiMonitorLinkClicked,
                Metric.HelpMenuPanelDevHomeLinkClicked,
                Metric.HelpMenuPanelOneESDevBoxFaqLinkClicked,
                Metric.HelpMenuPanelPrivacyLinkClicked,
                Metric.HelpMenuPanelProductTermsLinkClicked,
                Metric.HelpMenuPanelWindowsAppLinkClicked,
            ],
            data: {
                [Property.Link]: TelemetryFilteringAction.Allow,
            },
        },
    ],
    trace: [
        {
            baseData: {
                message: TelemetryFilteringAction.Allow,

                properties: {
                    truncatedProps: TelemetryFilteringAction.Allow,
                },
            },
        },
    ],
};

export default rules;
