import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    discoverSchedulesForDevBoxes,
    discoverSchedulesForDevBoxesError,
    discoverSchedulesForDevBoxesFailed,
    discoverSchedulesForDevBoxesSuccess,
    discoverSchedulesForPools,
    discoverSchedulesForPoolsError,
    discoverSchedulesForPoolsFailed,
    discoverSchedulesForPoolsSuccess,
    listSchedulesByProject,
    listSchedulesByProjectError,
    listSchedulesByProjectFailed,
    listSchedulesByProjectSuccess,
} from '../actions/schedule/schedule-action-creators';
import { scheduleAdapter } from '../adapters/schedule-adapters';
import { ScheduleDataStore, ScheduleStatusStore, ScheduleStore } from '../store/schedule-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const scheduleReducer = combineReducers<ScheduleStore>({
    data: createReducer(ScheduleDataStore(), (builder) => {
        builder.addCase(listSchedulesByProjectSuccess, (store, action) => {
            const { result } = getPayload(action);

            scheduleAdapter.setMany(
                store.schedules,
                result.map((data) => Entity(data.uri, data))
            );
        });
    }),

    status: combineReducers<ScheduleStatusStore>({
        discoverSchedulesForDevBoxes: createStatusReducer({
            inProgress: discoverSchedulesForDevBoxes,
            error: discoverSchedulesForDevBoxesError,
            failed: discoverSchedulesForDevBoxesFailed,
            success: discoverSchedulesForDevBoxesSuccess,
        }),

        discoverSchedulesForPools: createStatusReducer({
            inProgress: discoverSchedulesForPools,
            error: discoverSchedulesForPoolsError,
            failed: discoverSchedulesForPoolsFailed,
            success: discoverSchedulesForPoolsSuccess,
        }),

        listSchedulesByProject: createIndexedStatusReducer({
            inProgress: listSchedulesByProject,
            error: listSchedulesByProjectError,
            failed: listSchedulesByProjectFailed,
            success: listSchedulesByProjectSuccess,
        }),
    }),
});
