import { SagaIterator } from 'redux-saga';
import { call, CallEffect } from 'redux-saga/effects';
import { ClientError, FailureOperation, isClientError } from '../../models/common';

// We want to "propagate up" the FailureOperation if the error already has a FailureOperation more specific than
// Unknown. If the operation is Unknown, or is implicitly Unknown because error isn't a ClientError, use the default
// operation provided by the caller by wrapping the error in a ClientError. The net effect of this behavior is:
//      - The failure most easily found in telemetry (e.g. FATAL_ERROR) has the root-cause operation, (hopefully)
//        resulting in less digging needed when analyzing failures.
//      - All errors reported to telemetry have a consistent schema, i.e. ClientError
const ensureClientErrorPropagation = (thrown: unknown, operation: FailureOperation) =>
    isClientError(thrown) && thrown.operation !== FailureOperation.Unknown
        ? thrown
        : new ClientError(thrown, operation);

/**
 * Types
 */

export type CreateSagaErrorSignature = (
    thrown: unknown,
    operationWhenWrapped: FailureOperation
) => SagaIterator<ClientError>;

/**
 * Effects
 */

export function* createSagaErrorSaga(
    thrown: unknown,
    operationWhenWrapped: FailureOperation
): SagaIterator<ClientError> {
    return ensureClientErrorPropagation(thrown, operationWhenWrapped);
}

export const createSagaError = (thrown: unknown, operationWhenWrapped: FailureOperation): CallEffect<ClientError> =>
    call<CreateSagaErrorSignature>(createSagaErrorSaga, thrown, operationWhenWrapped);
