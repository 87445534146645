import { UnionMap } from '../../types/union-map';

export type AsyncOutcome = 'Error' | 'Failed' | 'PartialSuccess' | 'Success';

export const AsyncOutcome: UnionMap<AsyncOutcome> = {
    Error: 'Error',
    Failed: 'Failed',
    PartialSuccess: 'PartialSuccess',
    Success: 'Success',
};

export type AsyncState = 'NotStarted' | 'InProgress' | AsyncOutcome;

export const AsyncState: UnionMap<AsyncState> = {
    Error: 'Error',
    Failed: 'Failed',
    InProgress: 'InProgress',
    NotStarted: 'NotStarted',
    PartialSuccess: 'PartialSuccess',
    Success: 'Success',
};

export type ResourceState = 'Unknown' | 'NoResources' | 'AllFailedToLoad' | 'SomeFailedToLoad' | 'Available';

export const ResourceState: UnionMap<ResourceState> = {
    Unknown: 'Unknown',
    NoResources: 'NoResources',
    AllFailedToLoad: 'AllFailedToLoad',
    SomeFailedToLoad: 'SomeFailedToLoad',
    Available: 'Available',
};

export const isAvailableResourceState = (resourceState: ResourceState): boolean => {
    return resourceState === ResourceState.SomeFailedToLoad || resourceState === ResourceState.Available;
};
