import { ParameterType as DevCenterInternalParameterType } from 'devcenter-internal-stable';
import { EnvironmentDefinitionParameter } from '../../../models/environment-definition';
import { RequiredProperty } from '../../../types/required-property';
import { UnionMap } from '../../../types/union-map';

export type EnvironmentDefinitionParameterValue = string | number | boolean;

export type EnvironmentDefinitionParameterViewModel<
    TType extends EnvironmentDefinitionParameterValue = EnvironmentDefinitionParameterValue
> = Omit<RequiredProperty<EnvironmentDefinitionParameter, 'name'>, 'default' | 'allowed'> & {
    default?: TType;
    allowed?: TType[];
};

export type EnvironmentDefinitionArrayParameterViewModel = EnvironmentDefinitionParameterViewModel<string>;
export type EnvironmentDefinitionStringParameterViewModel = EnvironmentDefinitionParameterViewModel<string>;
export type EnvironmentDefinitionObjectParameterViewModel = EnvironmentDefinitionParameterViewModel<string>;

export type EnvironmentDefinitionNumberParameterViewModel = EnvironmentDefinitionParameterViewModel<number>;

export type EnvironmentDefinitionBooleanParameterViewModel = EnvironmentDefinitionParameterViewModel<boolean>;

export type ParameterType = Exclude<DevCenterInternalParameterType, 'null'>;
export const ParameterType: UnionMap<ParameterType> = {
    string: 'string',
    array: 'array',
    number: 'number',
    integer: 'integer',
    boolean: 'boolean',
    object: 'object',
};

export const isEnvironmentDefinitionArrayParameterViewModel = (
    value: EnvironmentDefinitionParameterViewModel
): value is EnvironmentDefinitionArrayParameterViewModel => {
    const parameter = value as EnvironmentDefinitionArrayParameterViewModel;

    return parameter !== undefined && parameter.type === 'array';
};

export const isEnvironmentDefinitionBooleanParameterViewModel = (
    value: EnvironmentDefinitionParameterViewModel
): value is EnvironmentDefinitionBooleanParameterViewModel => {
    const parameter = value as EnvironmentDefinitionBooleanParameterViewModel;

    return parameter !== undefined && parameter.type === 'boolean';
};

export const isEnvironmentDefinitionNumberParameterViewModel = (
    value: EnvironmentDefinitionParameterViewModel
): value is EnvironmentDefinitionNumberParameterViewModel => {
    const parameter = value as EnvironmentDefinitionNumberParameterViewModel;

    return parameter !== undefined && (parameter.type === 'number' || parameter.type === 'integer');
};

export const isEnvironmentDefinitionObjectParameterViewModel = (
    value: EnvironmentDefinitionParameterViewModel
): value is EnvironmentDefinitionObjectParameterViewModel => {
    const parameter = value as EnvironmentDefinitionObjectParameterViewModel;

    return parameter !== undefined && parameter.type === 'object';
};

export const isEnvironmentDefinitionStringParameterViewModel = (
    value: EnvironmentDefinitionParameterViewModel
): value is EnvironmentDefinitionStringParameterViewModel => {
    const parameter = value as EnvironmentDefinitionStringParameterViewModel;

    return parameter !== undefined && parameter.type === 'string';
};
