import { FeatureFlagName } from '../../../constants/features';
import { FeatureFlagSet } from '../../../models/features';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import {
    disableFeatures,
    enableFeatures,
    freezeFeatures,
    initializeFeatures,
    setFeatures,
    toggleFeatures,
} from './features-action-creators';

/**
 * Action types
 */

type FeaturesActionName =
    | 'ClearFeatures'
    | 'DisableFeatures'
    | 'EnableFeatures'
    | 'FreezeFeatures'
    | ActionName<'InitializeFeatures', 'Error' | 'Success'>
    | 'SetFeatures'
    | 'ToggleFeatures';

export type FeaturesActionType =
    | 'CLEAR_FEATURES'
    | 'DISABLE_FEATURES'
    | 'ENABLE_FEATURES'
    | 'FREEZE_FEATURES'
    | ActionType<'INITIALIZE_FEATURES', '_ERROR' | '_SUCCESS'>
    | 'SET_FEATURES'
    | 'TOGGLE_FEATURES';

export const FeaturesActionType: UnionValueMap<FeaturesActionName, FeaturesActionType> = {
    ClearFeatures: 'CLEAR_FEATURES',
    DisableFeatures: 'DISABLE_FEATURES',
    EnableFeatures: 'ENABLE_FEATURES',
    FreezeFeatures: 'FREEZE_FEATURES',
    InitializeFeatures: 'INITIALIZE_FEATURES',
    InitializeFeaturesError: 'INITIALIZE_FEATURES_ERROR',
    InitializeFeaturesSuccess: 'INITIALIZE_FEATURES_SUCCESS',
    SetFeatures: 'SET_FEATURES',
    ToggleFeatures: 'TOGGLE_FEATURES',
};

/**
 * Payloads
 */

export type DisableFeaturesPayload = { features: FeatureFlagName[] };
export type EnableFeaturesPayload = { features: FeatureFlagName[] };
export type FreezeFeaturesPayload = { features: FeatureFlagName[] };
export type SetFeaturesPayload = { features: FeatureFlagSet };
export type ToggleFeaturesPayload = { features: FeatureFlagName[] };

/**
 * Action shorthands
 */

export type DisableFeaturesAction = ReturnType<typeof disableFeatures>;
export type EnableFeaturesAction = ReturnType<typeof enableFeatures>;
export type FreezeFeaturesAction = ReturnType<typeof freezeFeatures>;
export type InitializeFeaturesAction = ReturnType<typeof initializeFeatures>;
export type SetFeaturesAction = ReturnType<typeof setFeatures>;
export type ToggleFeaturesAction = ReturnType<typeof toggleFeatures>;
