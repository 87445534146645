import { OperationOptions } from '@azure/core-client';
import { PagedAsyncIterableIterator } from '@azure/core-paging';
import { HttpHeaders, RestError } from '@azure/core-rest-pipeline';
import {
    DevCenterClient as BetaDevCenterClient,
    DevCenterClientOptionalParams as BetaDevCenterClientOptionalParams,
} from 'devcenter-internal-beta';
import {
    DevCenterClient as StableDevCenterClient,
    DevCenterClientOptionalParams as StableDevCenterClientOptionalParams,
} from 'devcenter-internal-stable';
import { v4 as uuidV4 } from 'uuid';
import { FeatureFlagName } from '../../../constants/features';
import { Header, StatusCode } from '../../../constants/http';
import {
    ClientError,
    DataResponse,
    FailureCode,
    FailureOperation,
    FailureResponse,
    SuccessResponse,
    isFailureResponse,
} from '../../../models/common';
import { UnionAndPartialIntersection } from '../../../types/union-and-partial-intersection';
import { createFailureResponseFromCloudErrorBody } from '../../../utilities/failure';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { tryParseJson } from '../../../utilities/resource-manager/parse-json';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackTrace } from '../../../utilities/telemetry/channel';
import { getSpanId, getTraceId } from '../../../utilities/telemetry/helpers';
import { formatTraceparentHeader } from '../../../utilities/tracing';
import { tryOrDefault } from '../../../utilities/try-or-default';
import { CloudErrorContract } from '../../contracts/common';
import { DefaultRetryOptions, RetryWrapperOptions, requestAndRetry } from '../request-and-retry';

interface CoreDevCenterClientRequestParameters {
    operation?: FailureOperation;
}

interface BetaDevCenterClientIterableRequestParameters<TResult> extends CoreDevCenterClientRequestParameters {
    whenUsingBetaClient: (client: BetaDevCenterClient) => PagedAsyncIterableIterator<TResult>;
}

interface BetaDevCenterClientRequestParameters<TResult> extends CoreDevCenterClientRequestParameters {
    whenUsingBetaClient: (client: BetaDevCenterClient) => Promise<TResult>;
}

interface StableDevCenterClientIterableRequestParameters<TResult> extends CoreDevCenterClientRequestParameters {
    whenUsingStableClient: (client: StableDevCenterClient) => PagedAsyncIterableIterator<TResult>;
}

interface StableDevCenterClientRequestParameters<TResult> extends CoreDevCenterClientRequestParameters {
    whenUsingStableClient: (client: StableDevCenterClient) => Promise<TResult>;
}

export type DevCenterClientIterableRequestParameters<TResult> = UnionAndPartialIntersection<
    StableDevCenterClientIterableRequestParameters<TResult>,
    BetaDevCenterClientIterableRequestParameters<TResult>
> &
    UnionAndPartialIntersection<StableDevCenterClientOptionalParams, BetaDevCenterClientOptionalParams>;

export type DevCenterClientRequestParameters<TResult> = UnionAndPartialIntersection<
    StableDevCenterClientRequestParameters<TResult>,
    BetaDevCenterClientRequestParameters<TResult>
> &
    UnionAndPartialIntersection<StableDevCenterClientOptionalParams, BetaDevCenterClientOptionalParams>;

export type DevCenterRetryOptions<TResult> = RetryWrapperOptions<TResult>;

export const getBetaDevCenterClient = (
    devCenter: string,
    options?: BetaDevCenterClientOptionalParams
): BetaDevCenterClient => new BetaDevCenterClient(devCenter, options);

export const getStableDevCenterClient = (
    devCenter: string,
    options?: StableDevCenterClientOptionalParams
): StableDevCenterClient => new StableDevCenterClient(devCenter, options);

export const getCommonOptions = (accessToken: string, activityId?: string): OperationOptions => {
    return {
        requestOptions: {
            customHeaders: {
                [Header.Authorization]: `Bearer ${accessToken}`,
                [Header.ClientRequestId]: uuidV4(),
                // Add custom traceparent header
                [Header.Traceparent]: formatTraceparentHeader(getTraceId(), getSpanId()),

                // Add client operation ID, if given
                ...(isNotUndefinedOrWhiteSpace(activityId)
                    ? {
                          [Header.ActivityId]: activityId,
                      }
                    : {}),
            },
        },
    };
};

const getHeadersFromHttpHeaders = (httpHeaders: HttpHeaders): Headers => new Headers(httpHeaders.toJSON());

const tryGetHeadersFromHttpHeaders = tryOrDefault(getHeadersFromHttpHeaders);

const getWaitTimeInMillisecondsFromResponse = <TResult>(response: DataResponse<TResult>) => {
    // No value is expected if the result was successful
    if (!isFailureResponse(response)) {
        return undefined;
    }

    // Try to pull the Retry-After header from the response and parse a valid integer from it
    const { headers } = response;
    const retryAfterStringValue = headers?.get(Header.RetryAfter);

    if (retryAfterStringValue === null || isUndefinedOrWhiteSpace(retryAfterStringValue)) {
        return undefined;
    }

    const retryAfter = parseInt(retryAfterStringValue);

    if (isNaN(retryAfter)) {
        return undefined;
    }

    // Retry-After is in seconds, so convert to milliseconds
    return retryAfter * 1000;
};

// We want to retry on 0 status code RestErrors (equivalent to a TypeError for regular fetch) as well as 503s
const retryOnResponsePredicate = <TResult>(response: DataResponse<TResult>) =>
    isFailureResponse(response) &&
    (!response.statusCode ||
        response.statusCode === StatusCode.ServiceUnavailable ||
        response.statusCode === StatusCode.TooManyRequests);

// We do not want to retry on exceptions since TypeErrors will come back as FailureResponses
const retryOnExceptionPredicate = (_err: unknown) => false;

const onRetry = (_err: unknown, tries: number, retries: number, waitTimeInMs: number) =>
    trackTrace(
        `Retrying failed data plane request, tries: ${tries}, retries: ${retries}, wait time (ms): ${waitTimeInMs}`
    );

export const sendRequest = <TResult>(
    devCenter: string,
    params: DevCenterClientRequestParameters<TResult>,
    retryOptions?: DevCenterRetryOptions<DataResponse<TResult>>
): Promise<DataResponse<TResult>> => {
    const { operation, whenUsingBetaClient, whenUsingStableClient, ...options } = params;
    retryOptions = retryOptions ?? DefaultRetryOptions<DataResponse<TResult>>();

    // Use beta client if feature flag is enabled
    if (isFeatureFlagEnabled(FeatureFlagName.EnableBetaDataPlane)) {
        if (!whenUsingBetaClient) {
            throw new ClientError('Beta Data Plane SDK is enabled, but operation is not supported in beta.');
        }

        const client = getBetaDevCenterClient(devCenter, options);
        return requestAndRetry(
            {
                ...retryOptions,
                getWaitTimeInMillisecondsFromResponse,
                retryOnResponsePredicate,
                retryOnExceptionPredicate,
                onRetry,
            },
            () => handleResponse(whenUsingBetaClient(client), operation)
        );
    }

    // Otherwise, use stable client and function
    if (!whenUsingStableClient) {
        throw new ClientError('Stable Data Plane SDK is enabled, but operation is not supported in stable.');
    }

    const client = getStableDevCenterClient(devCenter, options);
    return requestAndRetry(
        {
            ...retryOptions,
            getWaitTimeInMillisecondsFromResponse,
            retryOnResponsePredicate,
            retryOnExceptionPredicate,
            onRetry,
        },
        () => handleResponse(whenUsingStableClient(client), operation)
    );
};

export const sendIterableRequest = <TResult>(
    devCenter: string,
    params: DevCenterClientIterableRequestParameters<TResult>,
    retryOptions?: DevCenterRetryOptions<DataResponse<TResult[]>>
): Promise<DataResponse<TResult[]>> => {
    const { operation, whenUsingBetaClient, whenUsingStableClient, ...options } = params;
    retryOptions = retryOptions ?? DefaultRetryOptions<DataResponse<TResult[]>>();

    // Use beta client if feature flag is enabled
    if (isFeatureFlagEnabled(FeatureFlagName.EnableBetaDataPlane)) {
        if (!whenUsingBetaClient) {
            throw new ClientError('Beta Data Plane SDK is enabled, but operation is not supported in beta.');
        }

        const client = getBetaDevCenterClient(devCenter, options);
        return requestAndRetry(
            {
                ...retryOptions,
                getWaitTimeInMillisecondsFromResponse,
                retryOnResponsePredicate,
                retryOnExceptionPredicate,
                onRetry,
            },
            () => handleAsyncIterableResponse(whenUsingBetaClient(client), operation)
        );
    }

    // Otherwise, use stable client and function
    if (!whenUsingStableClient) {
        throw new ClientError('Stable Data Plane SDK is enabled, but operation is not supported in stable.');
    }

    const client = getStableDevCenterClient(devCenter, options);
    return requestAndRetry(
        {
            ...retryOptions,
            getWaitTimeInMillisecondsFromResponse,
            retryOnResponsePredicate,
            retryOnExceptionPredicate,
            onRetry,
        },
        () => handleAsyncIterableResponse(whenUsingStableClient(client), operation)
    );
};

export const isRestError = (error: unknown): error is RestError => {
    const restError = error as RestError;
    return restError !== undefined && restError.name === 'RestError';
};

const parseErrorResponse = (err: unknown, operation?: FailureOperation): FailureResponse => {
    if (!isRestError(err)) {
        throw err;
    }

    const { code, response, statusCode } = err;

    // Skipping any parsing of the response body for a 404 since we don't need it
    // and we get lots of 404s when schedules do not exist for a pool.
    if (statusCode === 404) {
        return FailureResponse({
            code: FailureCode.NotFound,
            operation,
            statusCode,
        });
    }

    const responseCode = statusCode ?? response?.status;

    if (!response || !response.bodyAsText) {
        trackTrace('Received an error response with no CloudError body.');

        return FailureResponse({
            code,
            operation,
            statusCode: responseCode,
        });
    }

    const headers = tryGetHeadersFromHttpHeaders(response.headers);
    const cloudError = tryParseJson<CloudErrorContract>(response.bodyAsText);
    const { error: errorBody } = cloudError ?? {};

    return errorBody
        ? createFailureResponseFromCloudErrorBody(errorBody, operation, responseCode, headers)
        : FailureResponse({ code, headers, operation, statusCode: responseCode });
};

/* eslint-disable @typescript-eslint/no-explicit-any */
// Justification: TypeGuard pain
const handleResponse = async <T>(promise: Promise<T>, operation?: FailureOperation): Promise<DataResponse<T>> => {
    try {
        const data = await promise;

        if (!!data) {
            return {
                data,
                succeeded: true,
            } as any as SuccessResponse<T>;
        }

        return {
            succeeded: true,
        } as any as SuccessResponse<T>;
    } catch (err) {
        return parseErrorResponse(err, operation);
    }
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const handleAsyncIterableResponse = async <T>(
    data: PagedAsyncIterableIterator<T>,
    operation?: FailureOperation
): Promise<DataResponse<T[]>> => {
    try {
        const list: T[] = [];
        for await (const item of data) {
            list.push(item);
        }

        return {
            data: list,
            succeeded: true,
        };
    } catch (err) {
        return parseErrorResponse(err, operation);
    }
};
