import { createSelector } from '@reduxjs/toolkit';
import { getTokensFromEnvironmentDefinitionDataPlaneUri } from '../../ids/environment-definition';
import { createProjectDataPlaneUri } from '../../ids/project';
import { statusAdapter } from '../adapters/common/status-adapter';
import { environmentDefinitionAdapter } from '../adapters/environment-definition-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createGroupedMapFromEntitiesSelector, createMapFromEntitiesSelector } from './common';

/**
 * Entity state selectors
 */

const environmentDefinitionSelectors = environmentDefinitionAdapter.getSelectors<StoreState>(
    (store) => store.environmentDefinitionStore.data.environmentDefinitions
);

const statusesForListEnvironmentDefinitionsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.environmentDefinitionStore.status.listEnvironmentDefinitions
);

/**
 * Composed selectors
 */

export const getEnvironmentDefinitions = createMapFromEntitiesSelector(environmentDefinitionSelectors.selectAll);

export const getEnvironmentDefinitionsByProject = createGroupedMapFromEntitiesSelector(
    environmentDefinitionSelectors.selectAll,
    (entity) => {
        const { id } = entity;
        const { devCenter, projectName } = getTokensFromEnvironmentDefinitionDataPlaneUri(id);

        return createProjectDataPlaneUri({ devCenter, projectName });
    }
);

export const getHasEnvironmentDefinitions: StoreStateSelector<boolean> = createSelector(
    [environmentDefinitionSelectors.selectTotal],
    (count) => count > 0
);

export const getStatusesForListEnvironmentDefinitions = createMapFromEntitiesSelector(
    statusesForListEnvironmentDefinitionsSelectors.selectAll
);
