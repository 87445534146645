import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EnvironmentOperationConstants } from '../../../constants/environment';
import {
    GetEnvironmentTypeAbilitiesResponse,
    getEnvironmentTypeAbilities,
} from '../../../data/services/data-plane-api/environment-type';
import { ClientError, FailureOperation, isSuccessResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    getProjectEnvironmentTypeAbilities,
    getProjectEnvironmentTypeAbilitiesError,
    getProjectEnvironmentTypeAbilitiesFailed,
    getProjectEnvironmentTypeAbilitiesSuccess,
} from '../../actions/project-environment-type-abilities/project-environment-type-abilities-action-creators';
import { GetProjectEnvironmentTypeAbilitiesAction } from '../../actions/project-environment-type-abilities/project-environment-type-abilities-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* getProjectEnvironmentTypeAbilitiesSaga(
    action: GetProjectEnvironmentTypeAbilitiesAction
): SagaIterator {
    const { payload, meta } = action;
    const { id } = payload;
    const { activityId } = meta ?? {};

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: GetEnvironmentTypeAbilitiesResponse = yield call(
            getEnvironmentTypeAbilities,
            id,
            EnvironmentOperationConstants.MeUserId,
            accessToken,
            activityId
        );

        if (isSuccessResponse(response)) {
            yield put(getProjectEnvironmentTypeAbilitiesSuccess({ id, result: response.data }, meta));
        } else {
            yield put(getProjectEnvironmentTypeAbilitiesFailed({ failure: response, id }, meta));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetProjectEnvironmentTypeAbilities);
        yield put(getProjectEnvironmentTypeAbilitiesError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* getProjectEnvironmentTypeAbilitiesListenerSaga(): SagaIterator {
    yield takeEvery(getProjectEnvironmentTypeAbilities, getProjectEnvironmentTypeAbilitiesSaga);
}
