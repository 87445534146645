import { UnionMap, UnionValueMap } from '../types/union-map';
import { isString } from '../utilities/string';

export type FeatureFlagName =
    | 'EnableBetaDataPlane'
    | 'UnderDevelopment'
    | 'UseNonPreauthorizedScopes'
    | 'LogVerbose'
    | 'EnableThemeModeToggle'
    | 'SingleDevCenterMode'
    | 'DisplayNames'
    | 'RoundTripTime'
    | 'OpenUserSettingsMenu'
    | 'ConnectViaApp'
    | 'EnableWindowsAppSettings'
    | 'EnableDiscoveryService'
    | 'EnableGuestUser';

export const FeatureFlagName: UnionMap<FeatureFlagName> = {
    EnableBetaDataPlane: 'EnableBetaDataPlane',
    UnderDevelopment: 'UnderDevelopment',
    UseNonPreauthorizedScopes: 'UseNonPreauthorizedScopes',
    LogVerbose: 'LogVerbose',
    EnableThemeModeToggle: 'EnableThemeModeToggle',
    SingleDevCenterMode: 'SingleDevCenterMode',
    DisplayNames: 'DisplayNames',
    RoundTripTime: 'RoundTripTime',
    OpenUserSettingsMenu: 'OpenUserSettingsMenu',
    ConnectViaApp: 'ConnectViaApp',
    EnableWindowsAppSettings: 'EnableWindowsAppSettings',
    EnableDiscoveryService: 'EnableDiscoveryService',
    EnableGuestUser: 'EnableGuestUser',
};

export type FeatureFlag = Lowercase<FeatureFlagName>;

export const FeatureFlag: UnionValueMap<FeatureFlagName, FeatureFlag> = {
    EnableBetaDataPlane: 'enablebetadataplane',
    UnderDevelopment: 'underdevelopment',
    UseNonPreauthorizedScopes: 'usenonpreauthorizedscopes',
    LogVerbose: 'logverbose',
    EnableThemeModeToggle: 'enablethememodetoggle',
    SingleDevCenterMode: 'singledevcentermode',
    DisplayNames: 'displaynames',
    RoundTripTime: 'roundtriptime',
    OpenUserSettingsMenu: 'openusersettingsmenu',
    ConnectViaApp: 'connectviaapp',
    EnableWindowsAppSettings: 'enablewindowsappsettings',
    EnableDiscoveryService: 'enablediscoveryservice',
    EnableGuestUser: 'enableguestuser',
};

export const NameForFeatureFlag: UnionValueMap<FeatureFlag, FeatureFlagName> = {
    enablebetadataplane: 'EnableBetaDataPlane',
    underdevelopment: 'UnderDevelopment',
    usenonpreauthorizedscopes: 'UseNonPreauthorizedScopes',
    logverbose: 'LogVerbose',
    enablethememodetoggle: 'EnableThemeModeToggle',
    singledevcentermode: 'SingleDevCenterMode',
    displaynames: 'DisplayNames',
    roundtriptime: 'RoundTripTime',
    openusersettingsmenu: 'OpenUserSettingsMenu',
    connectviaapp: 'ConnectViaApp',
    enablewindowsappsettings: 'EnableWindowsAppSettings',
    enablediscoveryservice: 'EnableDiscoveryService',
    enableguestuser: 'EnableGuestUser',
};

export const isFeatureFlag = (value: unknown): value is FeatureFlag => {
    if (!isString(value)) {
        return false;
    }

    switch (value.toLowerCase()) {
        case FeatureFlag.EnableBetaDataPlane:
        case FeatureFlag.UnderDevelopment:
        case FeatureFlag.UseNonPreauthorizedScopes:
        case FeatureFlag.LogVerbose:
        case FeatureFlag.EnableThemeModeToggle:
        case FeatureFlag.SingleDevCenterMode:
        case FeatureFlag.DisplayNames:
        case FeatureFlag.RoundTripTime:
        case FeatureFlag.OpenUserSettingsMenu:
        case FeatureFlag.ConnectViaApp:
        case FeatureFlag.EnableWindowsAppSettings:
        case FeatureFlag.EnableDiscoveryService:
        case FeatureFlag.EnableGuestUser:
            return true;
        default:
            return false;
    }
};
