import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import {
    AggregatedSuccess,
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { ProjectEnvironmentTypePermissionRecord } from '../../../models/permission';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverPermissionsForProjectEnvironmentTypes,
    discoverPermissionsForProjectEnvironmentTypesError,
    discoverPermissionsForProjectEnvironmentTypesFailed,
    discoverPermissionsForProjectEnvironmentTypesSuccess,
    getProjectEnvironmentTypePermissions,
} from '../../actions/permission/permission-action-creators';
import { DiscoverPermissionsForProjectEnvironmentTypesAction } from '../../actions/permission/permission-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getResourceIdsForProjectEnvironmentTypesRequiringPermissions } from './selectors';

export function* discoverPermissionsForProjectEnvironmentTypesSaga(
    action: DiscoverPermissionsForProjectEnvironmentTypesAction
): SagaIterator {
    const startTime = new Date();

    try {
        const resourceIds: string[] = yield select(getResourceIdsForProjectEnvironmentTypesRequiringPermissions);

        // Exit immediately if no resource IDs are available
        if (resourceIds.length < 1) {
            yield put(discoverPermissionsForProjectEnvironmentTypesSuccess());
            yield resolveAction(action, AggregatedSuccess());

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DiscoverPermissionsForProjectEnvironmentTypes,
                startTime,
                AsyncOutcome.Success
            );

            return;
        }

        const responses: DataResponse<ProjectEnvironmentTypePermissionRecord>[] = yield putAndAwait(
            getProjectEnvironmentTypePermissions(resourceIds.map((id) => ({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(
                FailureOperation.DiscoverPermissionsForProjectEnvironmentTypes,
                ...failures
            );

            yield put(discoverPermissionsForProjectEnvironmentTypesFailed({ failure }));
        } else {
            yield put(discoverPermissionsForProjectEnvironmentTypesSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPermissionsForProjectEnvironmentTypes,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.DiscoverPermissionsForProjectEnvironmentTypes
        );

        yield put(discoverPermissionsForProjectEnvironmentTypesError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPermissionsForProjectEnvironmentTypes,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverPermissionsForProjectEnvironmentTypesListenerSaga(): SagaIterator {
    yield takeLeading(discoverPermissionsForProjectEnvironmentTypes, discoverPermissionsForProjectEnvironmentTypesSaga);
}
