import { CommandBarButton, IContextualMenuProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ResourceFilterType } from '../../../constants/filters';
import { useResourceFilterContext } from '../../../hooks/context/filters';
import { getHasDevBoxes } from '../../../redux/selector/dev-box-selectors';
import { getHasEnvironments } from '../../../redux/selector/environment-selectors';

interface ResourcePickerComponentProps {
    ariaLabel: string;
    selectedResource: ResourceFilterType | undefined;
    onChange: (resource: ResourceFilterType | undefined) => void;
}

const messages = defineMessages({
    allResourcesButtonText: {
        id: 'ResourcePicker_AllResourcesButton_Text',
        defaultMessage: 'All resources',
        description: 'Text for the "all resources" button.',
    },
    allResourcesButtonAriaLabel: {
        id: 'ResourcePicker_AllResourcesButton_AriaLabel',
        defaultMessage: 'All resources',
        description: 'Aria label for the "all resources" button.',
    },
    devBoxesButtonText: {
        id: 'ResourcePicker_DevBoxesButton_Text',
        defaultMessage: 'Dev boxes',
        description: 'Text for the "dev boxes" button.',
    },
    devBoxesButtonAriaLabel: {
        id: 'ResourcePicker_DevBoxesButton_AriaLabel',
        defaultMessage: 'Dev boxes',
        description: 'Aria label for the "dev boxes" button.',
    },
    deploymentEnvironmentsButtonText: {
        id: 'ResourcePicker_DeploymentEnvironmentsButton_Text',
        defaultMessage: 'Deployment environments',
        description: 'Text for the "deployment environments" button.',
    },
    deploymentEnvironmentsButtonAriaLabel: {
        id: 'ResourcePicker_DeploymentEnvironmentsButton_AriaLabel',
        defaultMessage: 'Deployment environments',
        description: 'Aria label for the "deployment environments" button.',
    },
});

/**
 * Styles
 */
const useResourcePickerStyles = makeStyles({
    root: {
        padding: '0 13px',
    },
});
/**
 * END Styles
 */

export const ResourcePickerComponent: React.FC<ResourcePickerComponentProps> = React.memo(
    (props: ResourcePickerComponentProps) => {
        const { ariaLabel, selectedResource, onChange } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Styles
        const resourcePickerStyles = useResourcePickerStyles();

        const allResourcesButtonProps = React.useMemo(
            () => ({
                text: formatMessage(messages.allResourcesButtonText),
                ariaLabel: formatMessage(messages.allResourcesButtonAriaLabel),
                onClick: (_ev?: unknown) => {
                    onChange(undefined);
                },
            }),
            [formatMessage, onChange]
        );

        const devBoxesButtonProps = React.useMemo(
            () => ({
                text: formatMessage(messages.devBoxesButtonText),
                ariaLabel: formatMessage(messages.devBoxesButtonAriaLabel),
                onClick: (_ev?: unknown) => {
                    onChange(ResourceFilterType.DevBoxes);
                },
            }),
            [formatMessage, onChange]
        );

        const environmentsButtonProps = React.useMemo(
            () => ({
                text: formatMessage(messages.deploymentEnvironmentsButtonText),
                ariaLabel: formatMessage(messages.deploymentEnvironmentsButtonAriaLabel),
                onClick: (_ev?: unknown) => {
                    onChange(ResourceFilterType.DeploymentEnvironments);
                },
            }),
            [formatMessage, onChange]
        );

        // Memoized data
        const menuProps: IContextualMenuProps = React.useMemo(
            () => ({
                items: [
                    {
                        ...allResourcesButtonProps,
                        key: 'allResources',
                    },
                    {
                        ...devBoxesButtonProps,
                        key: 'devBoxes',
                    },
                    {
                        ...environmentsButtonProps,
                        key: 'environments',
                    },
                ],
                directionalHintFixed: true,
            }),
            [formatMessage, allResourcesButtonProps, devBoxesButtonProps, environmentsButtonProps]
        );

        const selectedResourceText = React.useMemo(() => {
            switch (selectedResource) {
                case ResourceFilterType.DevBoxes:
                    return formatMessage(messages.devBoxesButtonText);
                case ResourceFilterType.DeploymentEnvironments:
                    return formatMessage(messages.deploymentEnvironmentsButtonText);
                default:
                    return formatMessage(messages.allResourcesButtonText);
            }
        }, [selectedResource, formatMessage]);

        return (
            <CommandBarButton
                menuProps={menuProps}
                styles={resourcePickerStyles}
                text={selectedResourceText}
                aria-label={ariaLabel}
            />
        );
    }
);

interface ResourcePickerContainerProps {
    ariaLabel: string;
}

export const ResourcePickerContainer: React.FC<ResourcePickerContainerProps> = (
    props: ResourcePickerContainerProps
) => {
    const { ariaLabel } = props;

    const { value, onChange } = useResourceFilterContext();

    // Application state hooks
    const hasDevBoxes = useSelector(getHasDevBoxes);
    const hasEnvironments = useSelector(getHasEnvironments);

    const hasBothResources = hasDevBoxes && hasEnvironments;

    if (!hasBothResources) {
        return <></>;
    }

    return <ResourcePickerComponent ariaLabel={ariaLabel} selectedResource={value} onChange={onChange} />;
};

export default ResourcePickerContainer;
