import { forEach, getKey } from '../utilities/serializable-map';
import { KeyValuePair } from './key-value-pair';

export type SerializableMap<TValue> = {
    readonly [key: string]: TValue;
};

const isKeyValueArray = <TValue>(
    data: SerializableMap<TValue> | ReadonlyArray<[string, TValue]>
): data is ReadonlyArray<KeyValuePair<string, TValue>> => Array.isArray(data);

export const SerializableMap = <TValue>(
    data?: SerializableMap<TValue> | ReadonlyArray<KeyValuePair<string, TValue>>,
    useCaseSensitiveKey = false
): SerializableMap<TValue> => {
    // If argument not given, just return an empty object
    if (data === undefined) {
        return {};
    }

    const dictionary: { [key: string]: TValue } = {};

    // If array of entries is given, add the values one by one
    if (isKeyValueArray(data)) {
        data.forEach((entry) => {
            const [key, value] = entry;
            dictionary[getKey(key, useCaseSensitiveKey)] = value;
        });

        return dictionary;
    }

    // Otherwise, copy the existing dictionary
    forEach(data, (value, key) => {
        dictionary[getKey(key, useCaseSensitiveKey)] = value;
    });

    return dictionary;
};
