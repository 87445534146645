import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    discoverEnvironmentDefinitionsForEnvironments,
    discoverEnvironmentDefinitionsForEnvironmentsError,
    discoverEnvironmentDefinitionsForEnvironmentsFailed,
    discoverEnvironmentDefinitionsForEnvironmentsSuccess,
    discoverEnvironmentDefinitionsForProjects,
    discoverEnvironmentDefinitionsForProjectsError,
    discoverEnvironmentDefinitionsForProjectsFailed,
    discoverEnvironmentDefinitionsForProjectsSuccess,
    listEnvironmentDefinitions,
    listEnvironmentDefinitionsError,
    listEnvironmentDefinitionsFailed,
    listEnvironmentDefinitionsSuccess,
} from '../actions/environment-definition/environment-definition-action-creators';
import { environmentDefinitionAdapter } from '../adapters/environment-definition-adapters';
import {
    EnvironmentDefinitionDataStore,
    EnvironmentDefinitionStatusStore,
    EnvironmentDefinitionStore,
} from '../store/environment-definition-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const environmentDefinitionReducer = combineReducers<EnvironmentDefinitionStore>({
    data: createReducer(EnvironmentDefinitionDataStore(), (builder) => {
        builder.addCase(listEnvironmentDefinitionsSuccess, (store, action) => {
            const { result } = getPayload(action);

            environmentDefinitionAdapter.setMany(
                store.environmentDefinitions,
                result.map((data) => Entity(data.uri, data))
            );
        });
    }),

    status: combineReducers<EnvironmentDefinitionStatusStore>({
        discoverEnvironmentDefinitionsForEnvironments: createStatusReducer({
            inProgress: discoverEnvironmentDefinitionsForEnvironments,
            error: discoverEnvironmentDefinitionsForEnvironmentsError,
            failed: discoverEnvironmentDefinitionsForEnvironmentsFailed,
            success: discoverEnvironmentDefinitionsForEnvironmentsSuccess,
        }),

        discoverEnvironmentDefinitionsForProjects: createStatusReducer({
            inProgress: discoverEnvironmentDefinitionsForProjects,
            error: discoverEnvironmentDefinitionsForProjectsError,
            failed: discoverEnvironmentDefinitionsForProjectsFailed,
            success: discoverEnvironmentDefinitionsForProjectsSuccess,
        }),

        listEnvironmentDefinitions: createIndexedStatusReducer({
            inProgress: listEnvironmentDefinitions,
            error: listEnvironmentDefinitionsError,
            failed: listEnvironmentDefinitionsFailed,
            success: listEnvironmentDefinitionsSuccess,
        }),
    }),
});
