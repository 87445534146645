import { Failure } from '../../../models/common';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    dismissContent,
    dismissQuickAction,
    initializeApplication,
    syncDismissedContent,
} from './application-action-creators';

/**
 * Action types
 */

type ApplicationActionName =
    | ActionName<'DismissContent', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DismissQuickAction', 'Error' | 'Failed' | 'Success'>
    | 'FatalError'
    | ActionName<'InitializeApplication', 'Error' | 'Failed' | 'Success'>
    | ActionName<'SyncDismissedContent', 'Error' | 'Failed' | 'Success'>;

export type ApplicationActionType =
    | ActionType<'DISMISS_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISMISS_QUICK_ACTION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | 'FATAL_ERROR'
    | ActionType<'INITIALIZE_APPLICATION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'SYNC_DISMISSED_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const ApplicationActionType: UnionValueMap<ApplicationActionName, ApplicationActionType> = {
    DismissContent: 'DISMISS_CONTENT',
    DismissContentError: 'DISMISS_CONTENT_ERROR',
    DismissContentFailed: 'DISMISS_CONTENT_FAILED',
    DismissContentSuccess: 'DISMISS_CONTENT_SUCCESS',
    DismissQuickAction: 'DISMISS_QUICK_ACTION',
    DismissQuickActionError: 'DISMISS_QUICK_ACTION_ERROR',
    DismissQuickActionFailed: 'DISMISS_QUICK_ACTION_FAILED',
    DismissQuickActionSuccess: 'DISMISS_QUICK_ACTION_SUCCESS',
    FatalError: 'FATAL_ERROR',
    InitializeApplication: 'INITIALIZE_APPLICATION',
    InitializeApplicationError: 'INITIALIZE_APPLICATION_ERROR',
    InitializeApplicationFailed: 'INITIALIZE_APPLICATION_FAILED',
    InitializeApplicationSuccess: 'INITIALIZE_APPLICATION_SUCCESS',
    SyncDismissedContent: 'SYNC_DISMISSED_CONTENT',
    SyncDismissedContentError: 'SYNC_DISMISSED_CONTENT_ERROR',
    SyncDismissedContentFailed: 'SYNC_DISMISSED_CONTENT_FAILED',
    SyncDismissedContentSuccess: 'SYNC_DISMISSED_CONTENT_SUCCESS',
};

/**
 * Payloads
 */

export type DismissContentPayload = IndexedPayload<'content'>;
export type DismissContentErrorPayload = ErrorPayload & IndexedPayload<'content'>;
export type DismissContentFailedPayload = FailedPayload & IndexedPayload<'content'>;
export type DismissContentSuccessPayload = IndexedPayload<'content'>;

export type DismissQuickActionPayload = IndexedPayload<'quickAction'>;
export type DismissQuickActionErrorPayload = ErrorPayload & IndexedPayload<'quickAction'>;
export type DismissQuickActionFailedPayload = FailedPayload & IndexedPayload<'quickAction'>;
export type DismissQuickActionSuccessPayload = IndexedPayload<'quickAction'>;

export interface FatalErrorPayload {
    failure: Failure;
}

/**
 * Action shorthands
 */

export type DismissContentAction = ReturnType<typeof dismissContent>;
export type DismissQuickActionAction = ReturnType<typeof dismissQuickAction>;
export type InitializeApplicationAction = ReturnType<typeof initializeApplication>;
export type SyncDismissedContentAction = ReturnType<typeof syncDismissedContent>;
