import { UnionMap, UnionValueMap } from '../types/union-map';

export type GraphErrorCode = 'NoOrganization' | 'OrganizationHasNoDisplayName' | 'OrganizationHasNoId';

export const GraphErrorCode: UnionMap<GraphErrorCode> = {
    NoOrganization: 'NoOrganization',
    OrganizationHasNoDisplayName: 'OrganizationHasNoDisplayName',
    OrganizationHasNoId: 'OrganizationHasNoId',
};

export const MicrosoftGraphHostname = 'graph.microsoft.com';

type PhotoSizeName =
    | 'Original'
    | 'Square48'
    | 'Square64'
    | 'Square96'
    | 'Square120'
    | 'Square240'
    | 'Square360'
    | 'Square432'
    | 'Square504'
    | 'Square648';

export type PhotoSize =
    | 'Original'
    | '48x48'
    | '64x64'
    | '96x96'
    | '120x120'
    | '240x240'
    | '360x360'
    | '432x432'
    | '504x504'
    | '648x648';

export const PhotoSize: UnionValueMap<PhotoSizeName, PhotoSize> = {
    Original: 'Original',
    Square120: '120x120',
    Square240: '240x240',
    Square360: '360x360',
    Square432: '432x432',
    Square48: '48x48',
    Square504: '504x504',
    Square64: '64x64',
    Square648: '648x648',
    Square96: '96x96',
};
