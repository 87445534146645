import { AccountInfo } from '@azure/msal-browser';
import { Failure } from '../../models/common';
import { AuthenticationState } from '../../redux/store/identity-state';
import { createInitializer } from '../../utilities/initializer';

// TODO: eventually, coalesce the user-related data from this store and the user-related data from the graph store
// into one single UserStore.
export interface IdentityStore {
    activeAccount?: AccountInfo;
    authenticationFailure?: Failure;
    authenticationState: AuthenticationState;
}

export const IdentityStore = createInitializer<IdentityStore>({
    authenticationState: AuthenticationState.NotStarted,
});
