import { AggregatedResult, DataResponse } from '../../../models/common';
import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../../models/permission';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    GetProjectEnvironmentTypePermissionsSuccessPayload,
    GetProjectPermissionsSuccessPayload,
    PermissionActionType,
} from './permission-actions';

/**
 * Action creators
 */

export const discoverPermissionsForProjectEnvironmentTypes = createActionCreatorWithoutPayload<AggregatedResult>(
    PermissionActionType.DiscoverPermissionsForProjectEnvironmentTypes,
    { async: true }
);

export const discoverPermissionsForProjectEnvironmentTypesError = createActionCreator<ErrorPayload>(
    PermissionActionType.DiscoverPermissionsForProjectEnvironmentTypesError
);

export const discoverPermissionsForProjectEnvironmentTypesFailed = createActionCreator<FailedPayload>(
    PermissionActionType.DiscoverPermissionsForProjectEnvironmentTypesFailed
);

export const discoverPermissionsForProjectEnvironmentTypesSuccess = createActionCreatorWithoutPayload(
    PermissionActionType.DiscoverPermissionsForProjectEnvironmentTypesSuccess
);

export const discoverPermissionsForProjects = createActionCreatorWithoutPayload<AggregatedResult>(
    PermissionActionType.DiscoverPermissionsForProjects,
    { async: true }
);

export const discoverPermissionsForProjectsError = createActionCreator<ErrorPayload>(
    PermissionActionType.DiscoverPermissionsForProjectsError
);

export const discoverPermissionsForProjectsFailed = createActionCreator<FailedPayload>(
    PermissionActionType.DiscoverPermissionsForProjectsFailed
);

export const discoverPermissionsForProjectsSuccess = createActionCreatorWithoutPayload(
    PermissionActionType.DiscoverPermissionsForProjectsSuccess
);

export const getProjectEnvironmentTypePermissions = createActionCreator<
    IndexedPayload,
    DataResponse<ProjectEnvironmentTypePermissionRecord>
>(PermissionActionType.GetProjectEnvironmentTypePermissions, { async: true, groupable: true, startsActivity: true });

export const getProjectEnvironmentTypePermissionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    PermissionActionType.GetProjectEnvironmentTypePermissionsError,
    { groupable: true }
);

export const getProjectEnvironmentTypePermissionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    PermissionActionType.GetProjectEnvironmentTypePermissionsFailed,
    { groupable: true }
);

export const getProjectEnvironmentTypePermissionsSuccess =
    createActionCreator<GetProjectEnvironmentTypePermissionsSuccessPayload>(
        PermissionActionType.GetProjectEnvironmentTypePermissionsSuccess,
        { groupable: true }
    );

export const getProjectPermissions = createActionCreator<IndexedPayload, DataResponse<ProjectPermissionRecord>>(
    PermissionActionType.GetProjectPermissions,
    { async: true, groupable: true, startsActivity: true }
);

export const getProjectPermissionsError = createActionCreator<ErrorPayload & IndexedPayload>(
    PermissionActionType.GetProjectPermissionsError,
    { groupable: true }
);

export const getProjectPermissionsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    PermissionActionType.GetProjectPermissionsFailed,
    { groupable: true }
);

export const getProjectPermissionsSuccess = createActionCreator<GetProjectPermissionsSuccessPayload>(
    PermissionActionType.GetProjectPermissionsSuccess,
    { groupable: true }
);
