import { UnionMap } from '../../../types/union-map';

export type FailureMessageSeverity = 'Error' | 'Warning';

export const FailureMessageSeverity: UnionMap<FailureMessageSeverity> = {
    Error: 'Error',
    Warning: 'Warning',
};

export type FailureMessageSize = 'Default' | 'Compact';

export const FailureMessageSize: UnionMap<FailureMessageSize> = {
    Default: 'Default',
    Compact: 'Compact',
};
