import { ApiVersion, AzureSearchParameter, FullResourceType } from '../../../constants/azure';
import { Method } from '../../../constants/http';
import { BatchRequestName } from '../../../constants/resource-manager';
import { Severity } from '../../../constants/telemetry';
import { DataResponse, FailureOperation, isFailureResponse, isSuccessResponse } from '../../../models/common';
import { KeyValuePair } from '../../../types/key-value-pair';
import { SerializableMap } from '../../../types/serializable-map';
import { entries, get, set, size } from '../../../utilities/serializable-map';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackTrace } from '../../../utilities/telemetry/channel';
import { PagedResultContract, RoleContract } from '../../contracts/resource-manager';
import { paginatedResourceRequest } from '../paginated-resource-request';
import { BatchedResourceRequest, batchedResourceRequest } from './batched-resource-request';

export type GetPermissionsResponse = DataResponse<RoleContract[]>;

const createBatchedGetPermissionsRequest = (
    resourceId: string,
    operation: FailureOperation
): BatchedResourceRequest => ({
    method: Method.GET,
    name: `${BatchRequestName.GetPermissions}|${resourceId}`,
    operation,
    path: getPathForGetPermissions(resourceId),
});

const getPathForGetPermissions = (resourceId: string) =>
    `${resourceId}/providers/${FullResourceType.Permissions}?${AzureSearchParameter.ApiVersion}=${ApiVersion.Authorization}`;

const hasNextLinksTrace = (resourceCount: number) =>
    `Batched request for permissions required additional pages of data for ${resourceCount} resources.`;

const requestAdditionalPages = async (
    resourceId: string,
    nextLink: string,
    accessToken: string,
    operation: FailureOperation,
    activityId?: string
): Promise<[string, DataResponse<RoleContract[]>]> => {
    const { pathname: path } = new URL(nextLink);
    return [
        resourceId,
        await paginatedResourceRequest<RoleContract>(path, Method.GET, accessToken, {
            activityId,
            operation,
        }),
    ];
};

export const getManyPermissions = async (
    resourceIds: string[],
    accessToken: string,
    activityId?: string,
    operation = FailureOperation.GetPermissions
): Promise<KeyValuePair<string, GetPermissionsResponse>[]> => {
    // If only one resource was requested, use the unbatched function
    if (resourceIds.length === 1) {
        const [resourceId] = resourceIds;
        const result = await getPermissions(resourceId, accessToken, activityId);

        return [KeyValuePair(resourceId, result)];
    }

    // Fire off initial set of requests as one batch request
    const batchRequests = resourceIds.map((id) => createBatchedGetPermissionsRequest(id, operation));
    const responses = await batchedResourceRequest<PagedResultContract<RoleContract>>(
        batchRequests,
        accessToken,
        activityId
    );

    let dataResponses = SerializableMap<GetPermissionsResponse>();
    let nextLinks = SerializableMap<string>();

    responses.forEach((response, index) => {
        const resourceId = resourceIds[index];

        if (isFailureResponse(response)) {
            dataResponses = set(dataResponses, resourceId, response);
            return;
        }

        const { data } = response;
        const { nextLink, value } = data;

        dataResponses = set(dataResponses, resourceId, {
            data: value,
            succeeded: true,
        });

        nextLinks = isNotUndefinedOrWhiteSpace(nextLink) ? set(nextLinks, resourceId, nextLink) : nextLinks;
    });

    // If any of the batched responses had nextLinks, page those individually and wait for completion.
    // Note: not batching these because we don't anticipate this will happen very often. If that turns out not to be the
    // case, consider writing a batch helper that handles paginated responses.
    if (size(nextLinks) > 0) {
        trackTrace(hasNextLinksTrace(size(nextLinks)), { severity: Severity.Warning });
    }

    const nextLinkResults = await Promise.all(
        entries(nextLinks).map((entry) =>
            requestAdditionalPages(entry[0], entry[1], accessToken, operation, activityId)
        )
    );

    nextLinkResults.forEach((result) => {
        const [resourceId, response] = result;

        if (isFailureResponse(response)) {
            dataResponses = set(dataResponses, resourceId, response);
            return;
        }

        const { data } = response;
        const existingResponse = get(dataResponses, resourceId);
        const existingRoles = existingResponse && isSuccessResponse(existingResponse) ? existingResponse.data : [];

        dataResponses = set(dataResponses, resourceId, {
            data: [...existingRoles, ...data],
            succeeded: true,
        });
    });

    return entries(dataResponses);
};

export const getPermissions = async (
    resourceId: string,
    accessToken: string,
    activityId?: string,
    operation = FailureOperation.GetPermissions
): Promise<GetPermissionsResponse> =>
    await paginatedResourceRequest<RoleContract>(getPathForGetPermissions(resourceId), Method.GET, accessToken, {
        activityId,
        operation,
    });
