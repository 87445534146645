import { TextField } from '@fluentui/react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDebounceErrorMessage } from '../../../hooks/use-debounce-error-message';

interface AddDevBoxFormNameControlProps {
    devBoxName: string;
    onChange: (devBoxName: string) => void;
    errorMessage: string;
    disabled: boolean;
}

const messages = defineMessages({
    nameTextFieldText: {
        id: 'AddDevBoxFormNameControl_TextField_Text',
        defaultMessage: 'Name',
        description: 'Text for the name text field in the add dev box panel label',
    },
    nameTextFieldAriaLabel: {
        id: 'AddDevBoxFormNameControl_TextField_AriaLabel',
        defaultMessage: 'Name of your dev box',
        description: 'Aria label for the name text field in the add dev box panel',
    },
    nameTextFieldPlaceholder: {
        id: 'AddDevBoxFormNameControl_TextField_Placeholder',
        defaultMessage: 'Enter the name of your dev box',
        description: 'Placeholder for the name text field in the add dev box panel',
    },
});

export const AddDevBoxFormNameControl: React.FC<AddDevBoxFormNameControlProps> = (
    props: AddDevBoxFormNameControlProps
) => {
    const { devBoxName, onChange, errorMessage, disabled } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const devBoxNameOnChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
            onChange(value ?? '');
        },
        [onChange]
    );

    const debouncedErrorMessage = useDebounceErrorMessage(errorMessage);

    return (
        <TextField
            label={formatMessage(messages.nameTextFieldText)}
            placeholder={formatMessage(messages.nameTextFieldPlaceholder)}
            ariaLabel={formatMessage(messages.nameTextFieldAriaLabel)}
            value={devBoxName}
            onChange={devBoxNameOnChange}
            disabled={disabled}
            errorMessage={debouncedErrorMessage}
            required
        />
    );
};

export default AddDevBoxFormNameControl;
