import { ModuleThread, Pool, spawn } from 'threads';
import { QueuedTask } from 'threads/dist/master/pool';
import { ClientError } from '../../../models/common';
import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../../models/permission';
import { UnionMap } from '../../../types/union-map';
import { RoleContract } from '../../contracts/resource-manager';
import { PermissionWorker } from './workers/permission-worker';

export type PermissionWorkerPool = Pool<ModuleThread<PermissionWorker>>;

// Module's private state
let permissionWorkerPool: PermissionWorkerPool | undefined = undefined;
let isInitialized = false;

// This is an educated guess on the best number of results to send per thread to
// maximize concurrency and minimize time sending / receiving data.
// We should change this if we find that a different number of results per thread is quicker.
const resultsPerThread = 30;

// Likewise, these are educated guesses on the optimal number of threads to spin up and the optimal concurrency per-thread.
const defaultPoolSize = 4;
const defaultConcurrency = 2;

export const initialize = (size = defaultPoolSize, concurrency = defaultConcurrency): void => {
    if (isInitialized) {
        return;
    }

    permissionWorkerPool = Pool(
        () => spawn(new Worker(new URL('./workers/permission-worker.ts', import.meta.url)), { timeout: 20000 }),
        {
            concurrency,
            size,
        }
    );

    isInitialized = true;
};

type PermissionRecord = ProjectPermissionRecord | ProjectEnvironmentTypePermissionRecord;

type PermissionTask = keyof PermissionWorker;

const PermissionTask: UnionMap<PermissionTask> = {
    processProjectPermissions: 'processProjectPermissions',
    processProjectEnvironmentTypePermissions: 'processProjectEnvironmentTypePermissions',
};

const processPermissions = async (results: RoleContract[][], task: PermissionTask): Promise<PermissionRecord[]> => {
    if (!isInitialized) {
        throw new ClientError('Permission worker pool module must be initialized before use.');
    }

    if (!permissionWorkerPool) {
        throw new ClientError(
            'Unexpected state: Permission worker pool module is initialized, but pool is not defined.'
        );
    }

    const threadTasks: QueuedTask<ModuleThread<PermissionWorker>, PermissionRecord[]>[] = [];

    let startIndex = 0;

    // Terminate when all results have been processed
    while (startIndex < results.length) {
        // We want to queue the next chunk of {processedResultCount} results
        const chunkEndIndex = startIndex + resultsPerThread;

        // If the next chunk size exceeds the end of the result array, end the chunk at the end of the result array
        const endIndex = Math.min(chunkEndIndex, results.length);

        // Grab the next chunk of results
        const resultsToProcess = results.slice(startIndex, endIndex);

        // Queue the actual work (calling queue returns a QueuedTask, which we can later grab our processed data off of)
        const threadTask = permissionWorkerPool.queue((worker) => worker[task](resultsToProcess));

        // Push the QueuedTasks to a result list so we can maintain order
        // without having to await individual queued operations
        threadTasks.push(threadTask);

        // Increment result count
        startIndex = endIndex;
    }

    // All data is queued, we will wait here until all threads have completed their work.
    await permissionWorkerPool.completed();

    // All work is complete, and we can pull the processed data off of our ordered list of completed tasks.
    const processedPermissions: PermissionRecord[] = [];
    for (const threadTask of threadTasks) {
        await threadTask.then((value) => processedPermissions.push(...value));
    }

    return processedPermissions;
};

export const processProjectPermissions = async (results: RoleContract[][]): Promise<ProjectPermissionRecord[]> =>
    (await processPermissions(results, PermissionTask.processProjectPermissions)) as ProjectPermissionRecord[];

export const processProjectEnvironmentTypePermissions = async (
    results: RoleContract[][]
): Promise<ProjectEnvironmentTypePermissionRecord[]> =>
    (await processPermissions(
        results,
        PermissionTask.processProjectEnvironmentTypePermissions
    )) as ProjectEnvironmentTypePermissionRecord[];

export const terminatePermissionWorkerPool = async (): Promise<void> => {
    if (!isInitialized) {
        throw new ClientError('Permission worker pool module must be initialized before terminating.');
    }

    if (!permissionWorkerPool) {
        throw new ClientError(
            'Unexpected state: Permission worker pool module is initialized, but pool is not defined.'
        );
    }

    await permissionWorkerPool.terminate();
};
