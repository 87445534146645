import { UnionMap, UnionValueMap } from '../../../types/union-map';
import { MockScenarioConfiguration } from '../../types';
import {
    DataPlaneApiDevBoxScenarioName,
    DataPlaneApiDevBoxScenarios,
} from './dev-box/data-plane-api-dev-box-scenarios';
import {
    DataPlaneApiEnvironmentScenarioName,
    DataPlaneApiEnvironmentScenarios,
} from './environment/data-plane-api-environment-scenarios';
import { DataPlaneApiPoolScenarioName, DataPlaneApiPoolScenarios } from './pool/data-plane-api-pool-scenarios';
import {
    DataPlaneApiProjectScenarioName,
    DataPlaneApiProjectScenarios,
} from './projects/data-plane-api-project-scenarios';
import {
    DataPlaneApiScheduleScenarioName,
    DataPlaneApiScheduleScenarios,
} from './schedule/data-plane-api-schedule-scenarios';

export type DataPlaneApiScenarioName = DataPlaneApiDevBoxScenarioName;

export const DataPlaneApiScenarioName: UnionMap<DataPlaneApiScenarioName> = {
    ...DataPlaneApiDevBoxScenarioName,
    ...DataPlaneApiPoolScenarioName,
    ...DataPlaneApiScheduleScenarioName,
    ...DataPlaneApiEnvironmentScenarioName,
    ...DataPlaneApiProjectScenarioName,
};

export const DataPlaneApiScenarios: UnionValueMap<DataPlaneApiScenarioName, MockScenarioConfiguration> = {
    ...DataPlaneApiDevBoxScenarios,
    ...DataPlaneApiPoolScenarios,
    ...DataPlaneApiScheduleScenarios,
    ...DataPlaneApiEnvironmentScenarios,
    ...DataPlaneApiProjectScenarios,
};
