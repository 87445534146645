import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { QuickActionName } from '../../../../constants/app';
import { StorageKey } from '../../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../../models/common';
import { compact } from '../../../../utilities/array';
import { getStorageValue } from '../../../actions/storage/storage-action-creators';
import {
    syncDismissedHomeQuickActions,
    syncDismissedHomeQuickActionsError,
    syncDismissedHomeQuickActionsFailed,
    syncDismissedHomeQuickActionsSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { takeLeading } from '../../../effects/take';
import { getStorageType } from '../../../selector/storage-selectors';

export function* syncDismissedHomeQuickActionsSaga(): SagaIterator {
    try {
        const storageType = yield select(getStorageType);

        const response: DataResponse<string> = yield putAndAwait(
            getStorageValue({
                getForSignedInUser: true,
                key: StorageKey.DismissedQuickActions,
                storageType,
            })
        );

        if (isFailureResponse(response)) {
            yield put(syncDismissedHomeQuickActionsFailed({ failure: response }));
            return;
        }

        const { data } = response;

        const dismissedQuickActions = compact(
            data.split(',').map((quickAction) => {
                const rawValue = quickAction.trim();
                return Object.values(QuickActionName).includes(rawValue as QuickActionName)
                    ? (rawValue as QuickActionName)
                    : undefined;
            })
        );

        yield put(syncDismissedHomeQuickActionsSuccess({ result: dismissedQuickActions }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SyncDismissedHomeQuickActions);
        yield put(syncDismissedHomeQuickActionsError({ error }));
    }
}

export function* syncDismissedHomeQuickActionsListenerSaga(): SagaIterator {
    yield takeLeading(syncDismissedHomeQuickActions, syncDismissedHomeQuickActionsSaga);
}
