import { UnionMap, UnionValueMap } from '../types/union-map';

export type IdentityErrorCode =
    | '50033'
    | 'access_denied'
    | 'acquiretoken_progress_error'
    | 'endpoints_resolution_error'
    | 'failed'
    | 'interaction_required'
    | 'invalid_grant'
    | 'invalid_state_error'
    | 'login_required'
    | 'null_or_empty_id_token'
    | 'popup_window_error'
    | 'token_renewal_error'
    | 'unknown'
    | 'user_canceled'
    | 'user_login_error'
    | 'no_tokens_found'
    | 'no_account_error'
    | 'post_request_failed'
    | 'monitor_window_timeout';

export const AccessDenied: IdentityErrorCode = 'access_denied';
export const AcquireTokenProgressError: IdentityErrorCode = 'acquiretoken_progress_error';
export const EndpointsResolutionError: IdentityErrorCode = 'endpoints_resolution_error';
export const Failed: IdentityErrorCode = 'failed';
export const InteractionRequired: IdentityErrorCode = 'interaction_required';
export const InvalidGrant: IdentityErrorCode = 'invalid_grant';
export const InvalidStateError: IdentityErrorCode = 'invalid_state_error';
export const LoginRequired: IdentityErrorCode = 'login_required';
export const NullOrEmptyIdToken: IdentityErrorCode = 'null_or_empty_id_token';
export const PopupWindowError: IdentityErrorCode = 'popup_window_error';
export const TokenRenewalError: IdentityErrorCode = 'token_renewal_error';
export const Unknown: IdentityErrorCode = 'unknown';
export const UserCanceled: IdentityErrorCode = 'user_canceled';
export const UserLoginError: IdentityErrorCode = 'user_login_error';
export const NoTokensFound: IdentityErrorCode = 'no_tokens_found';
export const NoAccountError: IdentityErrorCode = 'no_account_error';
export const PostRequestFailed: IdentityErrorCode = 'post_request_failed';
export const RetryableError: IdentityErrorCode = '50033';
export const MonitorWindowTimeout: IdentityErrorCode = 'monitor_window_timeout';

export const IdentityErrorCode = {
    AccessDenied,
    AcquireTokenProgressError,
    EndpointsResolutionError,
    Failed,
    InteractionRequired,
    InvalidGrant,
    InvalidStateError,
    LoginRequired,
    NullOrEmptyIdToken,
    PopupWindowError,
    TokenRenewalError,
    Unknown,
    UserCanceled,
    UserLoginError,
    NoTokensFound,
    NoAccountError,
    PostRequestFailed,
    RetryableError,
    MonitorWindowTimeout,
};

export const LoginMicrosoftOnlineHostname = 'login.microsoftonline.com';

export type ScopeName = 'AzureResourceManager' | 'DevCenterDataPlane' | 'Graph' | 'AzureDevOps';

export const ScopeName: UnionMap<ScopeName> = {
    AzureResourceManager: 'AzureResourceManager',
    DevCenterDataPlane: 'DevCenterDataPlane',
    Graph: 'Graph',
    AzureDevOps: 'AzureDevOps',
};

export const AzureResourceManagerScope = 'https://management.azure.com/user_impersonation';
export const DevCenterDataPlaneScope = 'https://devcenter.azure.com/access_as_user';
export const GraphScope = 'https://graph.microsoft.com/.default';
export const AzureDevOpsScope = '499b84ac-1321-427f-aa17-267ca6975798/.default';

export const Scopes: UnionValueMap<ScopeName, string> = {
    AzureResourceManager: AzureResourceManagerScope,
    DevCenterDataPlane: DevCenterDataPlaneScope,
    Graph: GraphScope,
    AzureDevOps: AzureDevOpsScope,
};

export const IdentityErrorBasedExpiryCodes: IdentityErrorCode[] = [
    IdentityErrorCode.LoginRequired,
    IdentityErrorCode.InteractionRequired,
    IdentityErrorCode.NoAccountError,
];
