import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { warmNameRecord } from '../../../data/services/domains';
import { ClientError, DataResponse, FailureOperation, isSuccessResponse } from '../../../models/common';
import {
    warmDevCenterNameRecord,
    warmDevCenterNameRecordError,
    warmDevCenterNameRecordFailed,
    warmDevCenterNameRecordSuccess,
} from '../../actions/dev-center/dev-center-action-creators';
import { WarmDevCenterNameRecordAction } from '../../actions/dev-center/dev-center-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getPayload } from '../../utilities/payload-action';

export function* warmDevCenterNameRecordSaga(action: WarmDevCenterNameRecordAction): SagaIterator {
    const { id } = getPayload(action);

    try {
        const response: DataResponse = yield call(warmNameRecord, id);

        if (isSuccessResponse(response)) {
            yield put(warmDevCenterNameRecordSuccess({ id }));
        } else {
            yield put(warmDevCenterNameRecordFailed({ failure: response, id }));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.WarmDevCenterNameRecord);
        yield put(warmDevCenterNameRecordError({ error, id }));
        yield rejectAction(action, error);
    }
}

export function* warmDevCenterNameRecordListenerSaga(): SagaIterator {
    yield takeEvery(warmDevCenterNameRecord, warmDevCenterNameRecordSaga);
}
