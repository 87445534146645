import { waitForTimeout } from '../../utilities/wait-for-timeout';

export interface RetryOptions<TValue = undefined> {
    /* The number of retries to execute. If less than 1, code will only be executed once. Code will always run at least once regardless of the value specified here. */
    retries: number;
    /* How long in milliseconds we should wait before trying again. */
    backoff: number;
    /* This controls how much we increase our retry wait between tries. Set to 1 for it the wait to be static. */
    backoffFactor: number;
    /* Optional function for determining the Retry-After value from the response. If value is returned, that value will override backoff. */
    getWaitTimeInMillisecondsFromResponse?: (result: TValue) => number | undefined;
    /* Optional predicate function that allows for retries on specific responses that do not throw an exception. */
    retryOnResponsePredicate?: (result: TValue) => boolean;
    /* Optional predicate function that enables more fine grained control over what exceptions to retry for. If omitted, any exception type will result in a retry. */
    retryOnExceptionPredicate?: (err: unknown) => boolean;
    /* Optional function to enable consumers to log retry events. */
    onRetry?: (err: unknown, tries: number, retries: number, waitTimeInMs: number) => void;
}

export type RetryWrapperOptions<TValue = undefined> = Omit<
    RetryOptions<TValue>,
    'retryOnResponsePredicate' | 'retryOnExceptionPredicate' | 'onRetry' | 'getWaitTimeInMillisecondsFromResponse'
>;

export const DefaultRetryOptions = <TValue = undefined>(): RetryWrapperOptions<TValue> => ({
    retries: 3,
    backoff: 1000,
    backoffFactor: 2,
});

export const requestAndRetry = async <TValue, TFunc extends (...args: Parameters<TFunc>) => Promise<TValue>>(
    retryOptions: RetryOptions<TValue>,
    fn: TFunc,
    ...args: Parameters<TFunc>
): Promise<TValue> => {
    const {
        backoffFactor,
        getWaitTimeInMillisecondsFromResponse,
        retries,
        retryOnResponsePredicate,
        retryOnExceptionPredicate,
        onRetry,
    } = retryOptions;

    let { backoff } = retryOptions;

    let error: unknown;
    let tries = 0;
    let canTryAgain = false;
    let waitTimeFromResponse: number | undefined = undefined;

    do {
        tries += 1;
        canTryAgain = tries <= retries;

        if (tries > 1) {
            const waitTimeInMs = waitTimeFromResponse ?? backoff;

            if (onRetry !== undefined) {
                onRetry(error, tries, retries, waitTimeInMs);
            }

            await waitForTimeout(waitTimeInMs);
            backoff = backoff * backoffFactor;
        }

        try {
            const result = await fn(...args);
            const shouldRetry =
                canTryAgain && retryOnResponsePredicate !== undefined && retryOnResponsePredicate(result);
            waitTimeFromResponse = getWaitTimeInMillisecondsFromResponse?.(result);
            if (!shouldRetry) {
                return result;
            }
        } catch (err) {
            error = err;
            canTryAgain = canTryAgain && (retryOnExceptionPredicate === undefined || retryOnExceptionPredicate(error));
        }
    } while (canTryAgain);

    throw error;
};
