import { IColumn } from '@fluentui/react';
import { MessageDescriptor } from 'react-intl';
import { sortBy } from '../../../utilities/array';
import { compareStrings, isSubstringOfTextCaseInsensitive, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { EnvironmentDefinitionViewModel } from '../models';
import { selectEnvironmentDefinitionDetailsListMessages } from './messages';
import { EnvironmentDefinitionColumnKey, EnvironmentDefinitionDetailsListItem } from './models';

const compareEnvironmentDefinitionViewModels = (
    a: EnvironmentDefinitionDetailsListItem,
    b: EnvironmentDefinitionDetailsListItem,
    columnKey: EnvironmentDefinitionColumnKey,
    descending: boolean
): number => {
    return descending
        ? compareStrings(`${b.columns[columnKey]}`, `${a.columns[columnKey]}`, true)
        : compareStrings(`${a.columns[columnKey]}`, `${b.columns[columnKey]}`, true);
};

interface EnvironmentDefinitionDetailsListColumn extends Omit<IColumn, 'isSorted' | 'maxWidth' | 'minWidth'> {
    key: EnvironmentDefinitionColumnKey;
    sortedColumnKey: EnvironmentDefinitionColumnKey;
    width: number;
}

const createEnvironmentDefinitionDetailsListColumn = (column: EnvironmentDefinitionDetailsListColumn): IColumn => ({
    ...column,
    isSorted: column.key === column.sortedColumnKey,
    isSortedDescending: column.key === column.sortedColumnKey ? column.isSortedDescending : undefined,
    isResizable: column.isResizable ?? true,
    isPadded: column.isPadded ?? true,
    isRowHeader: column.isRowHeader ?? false,
    maxWidth: column.width,
    minWidth: column.width,
});

export const getEnvironmentDefinitionViewModelKey = (value: EnvironmentDefinitionViewModel): string => value.id;

const createEnvironmentDefinitionDetailsListItem = (
    environmentDefinitionViewModel: EnvironmentDefinitionViewModel
): EnvironmentDefinitionDetailsListItem => {
    const { name, catalogName: catalog, description } = environmentDefinitionViewModel;

    return {
        key: getEnvironmentDefinitionViewModelKey(environmentDefinitionViewModel),
        columns: {
            name,
            catalog,
            description,
        },
        value: environmentDefinitionViewModel,
    };
};

const doesEnvironmentDefinitionMatchSearchText = (
    searchText: string,
    environmentDefinition: EnvironmentDefinitionDetailsListItem
) => {
    const { columns } = environmentDefinition;
    const { name, catalog, description } = columns;

    return (
        isSubstringOfTextCaseInsensitive(name, searchText) ||
        (!!catalog && isSubstringOfTextCaseInsensitive(catalog, searchText)) ||
        (!!description && isSubstringOfTextCaseInsensitive(description, searchText))
    );
};

export const getFilteredEnvironmentDefinitionDetailsListItems = (
    filterText: string,
    items: EnvironmentDefinitionDetailsListItem[]
): EnvironmentDefinitionDetailsListItem[] =>
    isUndefinedOrWhiteSpace(filterText)
        ? items
        : items.filter((item) => doesEnvironmentDefinitionMatchSearchText(filterText, item));

export const getEnvironmentDefinitionDetailsListColumns = (
    descending: boolean,
    formatMessage: (message: MessageDescriptor) => string,
    sortedColumnKey: EnvironmentDefinitionColumnKey,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRender: (item: EnvironmentDefinitionDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null
): IColumn[] => {
    const sortAscendingAriaLabel = formatMessage(
        selectEnvironmentDefinitionDetailsListMessages.sortAscendingOrderAriaLabel
    );
    const sortDescendingAriaLabel = formatMessage(
        selectEnvironmentDefinitionDetailsListMessages.sortDescendingOrderAriaLabel
    );

    return [
        createEnvironmentDefinitionDetailsListColumn({
            ariaLabel: formatMessage(selectEnvironmentDefinitionDetailsListMessages.nameColumnHeaderAriaLabel),
            isSortedDescending: descending,
            key: EnvironmentDefinitionColumnKey.Name,
            sortedColumnKey,
            name: formatMessage(selectEnvironmentDefinitionDetailsListMessages.nameColumnHeaderText),
            onColumnClick,
            onRender,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            width: 285,
            isPadded: false,
            isRowHeader: true,
        }),
        createEnvironmentDefinitionDetailsListColumn({
            ariaLabel: formatMessage(selectEnvironmentDefinitionDetailsListMessages.catalogColumnHeaderAriaLabel),
            isSortedDescending: descending,
            key: EnvironmentDefinitionColumnKey.Catalog,
            sortedColumnKey,
            name: formatMessage(selectEnvironmentDefinitionDetailsListMessages.catalogColumnHeaderText),
            onColumnClick,
            onRender,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            width: 285,
        }),
        createEnvironmentDefinitionDetailsListColumn({
            ariaLabel: formatMessage(selectEnvironmentDefinitionDetailsListMessages.descriptionColumnHeaderAriaLabel),
            isSortedDescending: descending,
            key: EnvironmentDefinitionColumnKey.Description,
            sortedColumnKey,
            name: formatMessage(selectEnvironmentDefinitionDetailsListMessages.descriptionColumnHeaderText),
            onColumnClick,
            onRender,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            width: 694,
        }),
    ];
};

export const getFormattedEnvironmentDefinitionDetailsListItems = (
    environmentDefinitionViewModels: EnvironmentDefinitionViewModel[]
): EnvironmentDefinitionDetailsListItem[] =>
    environmentDefinitionViewModels.map((item) => createEnvironmentDefinitionDetailsListItem(item));

export const getSortedEnvironmentDefinitionDetailsListItems = (
    columnKey: EnvironmentDefinitionColumnKey,
    descending: boolean,
    environmentDefinitionDetailsListItems: EnvironmentDefinitionDetailsListItem[]
): EnvironmentDefinitionDetailsListItem[] =>
    sortBy(
        environmentDefinitionDetailsListItems,
        (item) => item,
        (a, b) => compareEnvironmentDefinitionViewModels(a, b, columnKey, descending)
    );
