import { AccountInfo } from '@azure/msal-browser';
import { createSelector } from 'reselect';
import { tryParseHomeAccountId } from '../../utilities/identity';
import { areStringsEquivalent, isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../../utilities/string';
import { ActiveAccountState, AuthenticationState } from '../store/identity-state';
import { StoreStateSelector } from './common';

/**
 * Basic selectors
 */

export const getActiveAccount: StoreStateSelector<AccountInfo | undefined> = (store) =>
    store.identityStore.activeAccount;

export const getAuthenticationState: StoreStateSelector<AuthenticationState> = (store) =>
    store.identityStore.authenticationState;

export const getIsSessionExpiring: StoreStateSelector<boolean> = (store) =>
    store.identityStore.authenticationState === AuthenticationState.Expiring;

export const getObjectId: StoreStateSelector<string> = (store) =>
    store.identityStore.activeAccount?.localAccountId ?? '';

export const getTenantId: StoreStateSelector<string | undefined> = (store) =>
    store.identityStore.activeAccount?.tenantId;

export const getUsername: StoreStateSelector<string | undefined> = (store) =>
    store.identityStore.activeAccount?.username;

/**
 * Composed selectors
 */

export const getHomeTenantId: StoreStateSelector<string | undefined> = createSelector(
    [getActiveAccount],
    (activeAccount) => {
        const { homeAccountId } = activeAccount ?? {};
        return isNotUndefinedOrWhiteSpace(homeAccountId) ? tryParseHomeAccountId(homeAccountId)?.tenantId : undefined;
    }
);

export const getHomeUserId: StoreStateSelector<string | undefined> = createSelector(
    [getActiveAccount],
    (activeAccount) => {
        const { homeAccountId } = activeAccount ?? {};

        return isNotUndefinedOrWhiteSpace(homeAccountId)
            ? tryParseHomeAccountId(homeAccountId)?.userObjectId
            : undefined;
    }
);

export const getIsSignedIn: StoreStateSelector<boolean> = createSelector(
    [getAuthenticationState],
    (authenticationState) => {
        switch (authenticationState) {
            case AuthenticationState.SignedIn:
            // Note: currently including the below states because they represent either transitory states before the user is
            // completely signed out or end states where sign out failed and the user is still, therefore, signed in
            case AuthenticationState.RedirectingForSignOut:
            case AuthenticationState.SigningOut:
            case AuthenticationState.SignOutError:
                return true;

            default:
                return false;
        }
    }
);

export const getActiveAccountState: StoreStateSelector<ActiveAccountState> = createSelector(
    [getActiveAccount, getIsSignedIn],
    (activeAccount, isSignedIn) => {
        if (!isSignedIn) {
            return ActiveAccountState.NotSignedIn;
        }

        const { homeAccountId, tenantId } = activeAccount ?? {};

        if (isUndefinedOrWhiteSpace(homeAccountId) || isUndefinedOrWhiteSpace(tenantId)) {
            return ActiveAccountState.Unknown;
        }

        const { tenantId: homeTenantId } = tryParseHomeAccountId(homeAccountId) ?? {};

        if (isUndefinedOrWhiteSpace(homeTenantId)) {
            return ActiveAccountState.Unknown;
        }

        return areStringsEquivalent(homeTenantId, tenantId, true)
            ? ActiveAccountState.HomeAccount
            : ActiveAccountState.GuestAccount;
    }
);
