import { ApiVersion, AzureSearchParameter } from '../../../constants/azure';
import { ContentType, Method } from '../../../constants/http';
import { DependencyType } from '../../../constants/telemetry';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    FailureResponse,
    SuccessResponse,
    isFailureResponse,
} from '../../../models/common';
import Settings from '../../../settings/settings';
import { trackDependency } from '../../../utilities/telemetry/channel';
import { getMillisecondsBetween } from '../../../utilities/time';
import { CloudErrorContract, isCloudErrorContract } from '../../contracts/common';
import { resourceRequest } from '../resource-request';

interface BatchRequestHeaderDetails {
    commandName: string;
}

interface BatchRequest {
    content?: unknown;
    httpMethod: Method;
    name: string;
    operation?: FailureOperation;
    requestHeaderDetails?: BatchRequestHeaderDetails;
    url: string;
}

export type BatchedResourceRequest = Omit<BatchRequest, 'content' | 'httpMethod' | 'url'> & {
    body?: unknown;
    method: Method;
    operation?: FailureOperation;
    path: string;
};

// Note: this presently assumes we'll be batching requests that return content. All our batch scenarios are currently in
// this bucket. However, that might change if we handle something like batched DELETEs, for example. If that comes up,
// we should probably add some conditional types that contain either no content or CloudError.
interface BatchResponse<TContent> {
    content: TContent | CloudErrorContract;
    contentLength: number;
    headers: { [key: string]: string };
    httpStatusCode: number;
    name?: string;
}

interface BatchResponseBody<TContent> {
    responses: BatchResponse<TContent>[];
}

export type BatchedResourceResponse<TContent> = DataResponse<TContent>[];

const maximumBatchSize = 20;
const path = `/batch?${AzureSearchParameter.ApiVersion}=${ApiVersion.Batch}`;

const createBatchRequest = (request: BatchedResourceRequest): BatchRequest => ({
    content: request.body,
    httpMethod: request.method,
    name: request.name,
    requestHeaderDetails: request.requestHeaderDetails,
    url: `${Settings.AzureResourceBaseUrl}${request.path}`,
});

const sendBatchRequest = async <TContent>(
    requests: BatchRequest[],
    accessToken: string,
    activityId?: string
): Promise<DataResponse<BatchResponseBody<TContent>>> => {
    const startTime = new Date();

    const batchResponse = await resourceRequest(path, Method.POST, accessToken, {
        activityId,
        body: JSON.stringify({ requests }),
        contentType: ContentType.ApplicationJson,
        operation: FailureOperation.BatchRequest,
    });

    // By default, Application Insights will only send dependency telemetry for the batch request. To make analysis
    // easier, we'll send a fabricated dependency for each request within the batched request.
    // NOTE: we don't send this synthetic telemetry when the entire batch request fails or throws an exception.
    const duration = getMillisecondsBetween(startTime, new Date());

    if (isFailureResponse(batchResponse)) {
        return batchResponse;
    }

    // Note: We unpack then repackage responses here because we can only read Response once and we need its content here
    // to determine the status code. We're als not doing any type checking on the response here. Better to fail fast if
    // we're getting a totally unexpected response format from the API.
    const { responses } = (await batchResponse.data.json()) as BatchResponseBody<TContent>;

    requests.forEach((request, index) => {
        const { httpMethod, url } = request;
        const { httpStatusCode } = responses[index];
        const name = `${httpMethod} ${url}`;

        // Note: definition of success comes from Application Insights here:
        // https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackdependencydata
        const success = httpStatusCode >= 200 && httpStatusCode < 300;

        trackDependency(httpStatusCode, name, duration, success, DependencyType.BatchedFetch, { activityId });
    });

    return {
        data: { responses },
        succeeded: true,
    };
};

export const batchedResourceRequest = async <TContent>(
    requests: BatchedResourceRequest[],
    accessToken: string,
    activityId?: string
): Promise<BatchedResourceResponse<TContent>> => {
    if (requests.length < 1) {
        throw new ClientError('You must provide at least one request to batch.');
    }

    const batchPromises: Promise<DataResponse<BatchResponseBody<TContent>>>[] = [];
    const batchedRequestCounts: number[] = [];
    const batchedRequestOperations: (FailureOperation | undefined)[][] = [];
    const data: DataResponse<TContent>[] = [];

    // Organize requests into batches per maximum batch size
    for (let i = 0; i < requests.length; i += maximumBatchSize) {
        const batchedRequests = requests.slice(i, i + maximumBatchSize).map(createBatchRequest);
        batchPromises.push(sendBatchRequest(batchedRequests, accessToken, activityId));

        // Keep track of # requests in a batch (so we can return correct # FailureResponses)
        batchedRequestCounts.push(batchedRequests.length);

        // Keep track of failure operations associated with requests, where relevant
        batchedRequestOperations[i] = requests.map((req) => req.operation);
    }

    // Wait for completion of all batched requests
    const results = await Promise.all(batchPromises);

    for (const index in results) {
        const result = results[index];

        // If batch request failed all-up, append failure per request
        if (isFailureResponse(result)) {
            for (let i = 0; i < batchedRequestCounts[index]; i++) {
                data.push({ ...result });
            }

            continue;
        }

        const { responses } = result.data;

        const dataResponses = responses.map<DataResponse<TContent>>((response, subIndex) => {
            const { content, httpStatusCode } = response;

            if (isCloudErrorContract(content)) {
                const { error } = content;
                const { code, message } = error;

                return FailureResponse({
                    code,
                    message,
                    operation: batchedRequestOperations[index]?.[subIndex],
                    statusCode: httpStatusCode,
                });
            }

            return {
                data: content,
                succeeded: true,
            } as SuccessResponse<TContent>;
        });

        data.push(...dataResponses);
    }

    return data;
};
