import { UnionValueMap } from '../../../../types/union-map';
import { ActionName, ActionType } from '../../core-actions';
import {
    initializeHome,
    loadAddDevBoxFormContent,
    loadAddEnvironmentFormContent,
    loadBackgroundResourcesForHome,
    loadControlPlaneResourcesForHome,
    loadDevBoxCardContent,
    loadEnvironmentCardContent,
    loadResourcesForHome,
    loadSecondaryDevBoxCardContent,
    syncDismissedHomeQuickActions,
} from './home-action-creators';

/**
 * Action types
 */

type HomeActionName =
    | ActionName<'InitializeHome', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadAddDevBoxFormContent', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadAddEnvironmentFormContent', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadBackgroundResourcesForHome', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadControlPlaneResourcesForHome', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadDevBoxCardContent', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadEnvironmentCardContent', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadResourcesForHome', 'Error' | 'Failed' | 'Success'>
    | ActionName<'SyncDismissedHomeQuickActions', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadSecondaryDevBoxCardContent', 'Error' | 'Failed' | 'Success'>;

export type HomeActionType =
    | ActionType<'INITIALIZE_HOME', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_ADD_DEV_BOX_FORM_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_ADD_ENVIRONMENT_FORM_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_BACKGROUND_RESOURCES_FOR_HOME', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_CONTROL_PLANE_RESOURCES_FOR_HOME', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_DEV_BOX_CARD_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_ENVIRONMENT_CARD_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_RESOURCES_FOR_HOME', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'SYNC_DISMISSED_HOME_QUICK_ACTIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_SECONDARY_DEV_BOX_CARD_CONTENT', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const HomeActionType: UnionValueMap<HomeActionName, HomeActionType> = {
    InitializeHome: 'INITIALIZE_HOME',
    InitializeHomeError: 'INITIALIZE_HOME_ERROR',
    InitializeHomeFailed: 'INITIALIZE_HOME_FAILED',
    InitializeHomeSuccess: 'INITIALIZE_HOME_SUCCESS',
    LoadAddDevBoxFormContent: 'LOAD_ADD_DEV_BOX_FORM_CONTENT',
    LoadAddDevBoxFormContentError: 'LOAD_ADD_DEV_BOX_FORM_CONTENT_ERROR',
    LoadAddDevBoxFormContentFailed: 'LOAD_ADD_DEV_BOX_FORM_CONTENT_FAILED',
    LoadAddDevBoxFormContentSuccess: 'LOAD_ADD_DEV_BOX_FORM_CONTENT_SUCCESS',
    LoadAddEnvironmentFormContent: 'LOAD_ADD_ENVIRONMENT_FORM_CONTENT',
    LoadAddEnvironmentFormContentError: 'LOAD_ADD_ENVIRONMENT_FORM_CONTENT_ERROR',
    LoadAddEnvironmentFormContentFailed: 'LOAD_ADD_ENVIRONMENT_FORM_CONTENT_FAILED',
    LoadAddEnvironmentFormContentSuccess: 'LOAD_ADD_ENVIRONMENT_FORM_CONTENT_SUCCESS',
    LoadBackgroundResourcesForHome: 'LOAD_BACKGROUND_RESOURCES_FOR_HOME',
    LoadBackgroundResourcesForHomeError: 'LOAD_BACKGROUND_RESOURCES_FOR_HOME_ERROR',
    LoadBackgroundResourcesForHomeFailed: 'LOAD_BACKGROUND_RESOURCES_FOR_HOME_FAILED',
    LoadBackgroundResourcesForHomeSuccess: 'LOAD_BACKGROUND_RESOURCES_FOR_HOME_SUCCESS',
    LoadControlPlaneResourcesForHome: 'LOAD_CONTROL_PLANE_RESOURCES_FOR_HOME',
    LoadControlPlaneResourcesForHomeError: 'LOAD_CONTROL_PLANE_RESOURCES_FOR_HOME_ERROR',
    LoadControlPlaneResourcesForHomeFailed: 'LOAD_CONTROL_PLANE_RESOURCES_FOR_HOME_FAILED',
    LoadControlPlaneResourcesForHomeSuccess: 'LOAD_CONTROL_PLANE_RESOURCES_FOR_HOME_SUCCESS',
    LoadDevBoxCardContent: 'LOAD_DEV_BOX_CARD_CONTENT',
    LoadDevBoxCardContentError: 'LOAD_DEV_BOX_CARD_CONTENT_ERROR',
    LoadDevBoxCardContentFailed: 'LOAD_DEV_BOX_CARD_CONTENT_FAILED',
    LoadDevBoxCardContentSuccess: 'LOAD_DEV_BOX_CARD_CONTENT_SUCCESS',
    LoadEnvironmentCardContent: 'LOAD_ENVIRONMENT_CARD_CONTENT',
    LoadEnvironmentCardContentError: 'LOAD_ENVIRONMENT_CARD_CONTENT_ERROR',
    LoadEnvironmentCardContentFailed: 'LOAD_ENVIRONMENT_CARD_CONTENT_FAILED',
    LoadEnvironmentCardContentSuccess: 'LOAD_ENVIRONMENT_CARD_CONTENT_SUCCESS',
    LoadResourcesForHome: 'LOAD_RESOURCES_FOR_HOME',
    LoadResourcesForHomeError: 'LOAD_RESOURCES_FOR_HOME_ERROR',
    LoadResourcesForHomeFailed: 'LOAD_RESOURCES_FOR_HOME_FAILED',
    LoadResourcesForHomeSuccess: 'LOAD_RESOURCES_FOR_HOME_SUCCESS',
    SyncDismissedHomeQuickActions: 'SYNC_DISMISSED_HOME_QUICK_ACTIONS',
    SyncDismissedHomeQuickActionsError: 'SYNC_DISMISSED_HOME_QUICK_ACTIONS_ERROR',
    SyncDismissedHomeQuickActionsFailed: 'SYNC_DISMISSED_HOME_QUICK_ACTIONS_FAILED',
    SyncDismissedHomeQuickActionsSuccess: 'SYNC_DISMISSED_HOME_QUICK_ACTIONS_SUCCESS',
    LoadSecondaryDevBoxCardContent: 'LOAD_SECONDARY_DEV_BOX_CARD_CONTENT',
    LoadSecondaryDevBoxCardContentError: 'LOAD_SECONDARY_DEV_BOX_CARD_CONTENT_ERROR',
    LoadSecondaryDevBoxCardContentFailed: 'LOAD_SECONDARY_DEV_BOX_CARD_CONTENT_FAILED',
    LoadSecondaryDevBoxCardContentSuccess: 'LOAD_SECONDARY_DEV_BOX_CARD_CONTENT_SUCCESS',
};

/**
 * Action shorthands
 */

export type InitializeHomeAction = ReturnType<typeof initializeHome>;
export type LoadAddDevBoxFormContentAction = ReturnType<typeof loadAddDevBoxFormContent>;
export type LoadAddEnvironmentFormContentAction = ReturnType<typeof loadAddEnvironmentFormContent>;
export type LoadBackgroundResourcesForHomeAction = ReturnType<typeof loadBackgroundResourcesForHome>;
export type LoadControlPlaneResourcesForHomeAction = ReturnType<typeof loadControlPlaneResourcesForHome>;
export type LoadDevBoxCardContentAction = ReturnType<typeof loadDevBoxCardContent>;
export type LoadEnvironmentCardContentAction = ReturnType<typeof loadEnvironmentCardContent>;
export type LoadResourcesForHomeAction = ReturnType<typeof loadResourcesForHome>;
export type SyncDismissedHomeQuickActionsAction = ReturnType<typeof syncDismissedHomeQuickActions>;
export type LoadSecondaryDevBoxCardContent = ReturnType<typeof loadSecondaryDevBoxCardContent>;
