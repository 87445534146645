import * as React from 'react';
import { EnvironmentTypeViewModel } from '../../../../add-environment-panel/models';
import ResourceDropdown, { ResourceDropdownWrapperProps } from './resource-dropdown';
import { getOptionKeyForIndexedModel } from './selectors';

export type EnvironmentTypeDropdownProps = ResourceDropdownWrapperProps<EnvironmentTypeViewModel>;

export const EnvironmentTypeDropdown: React.FC<EnvironmentTypeDropdownProps> = (props) => (
    <ResourceDropdown<EnvironmentTypeViewModel> {...props} getOptionKey={getOptionKeyForIndexedModel} />
);

export default EnvironmentTypeDropdown;
