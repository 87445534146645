import { UnionMap } from '../../types/union-map';

export type ActiveAccountState = 'GuestAccount' | 'HomeAccount' | 'NotSignedIn' | 'Unknown';

export const ActiveAccountState: UnionMap<ActiveAccountState> = {
    GuestAccount: 'GuestAccount',
    HomeAccount: 'HomeAccount',
    NotSignedIn: 'NotSignedIn',
    Unknown: 'Unknown',
};

export type AuthenticationState =
    | 'Expiring'
    | 'NotStarted'
    | 'RedirectingForSignIn'
    | 'RedirectingForSignOut'
    | 'SignedIn'
    | 'SignedOut'
    | 'SignInError'
    | 'SignInFailed'
    | 'SigningIn'
    | 'SigningOut'
    | 'SignOutError'
    | 'IdentityErrorBasedExpiry';

export const AuthenticationState: UnionMap<AuthenticationState> = {
    Expiring: 'Expiring',
    NotStarted: 'NotStarted',
    RedirectingForSignIn: 'RedirectingForSignIn',
    RedirectingForSignOut: 'RedirectingForSignOut',
    SignedIn: 'SignedIn',
    SignedOut: 'SignedOut',
    SignInError: 'SignInError',
    SignInFailed: 'SignInFailed',
    SigningIn: 'SigningIn',
    SigningOut: 'SigningOut',
    SignOutError: 'SignOutError',
    IdentityErrorBasedExpiry: 'IdentityErrorBasedExpiry',
};

export type GetAccessTokenState =
    | 'GetAccessTokenError'
    | 'GetAccessTokenFailed'
    | 'GettingAccessToken'
    | 'GotAccessToken'
    | 'NotStarted'
    | 'RedirectingForAccessToken';

export const GetAccessTokenState: UnionMap<GetAccessTokenState> = {
    GetAccessTokenError: 'GetAccessTokenError',
    GetAccessTokenFailed: 'GetAccessTokenFailed',
    GettingAccessToken: 'GettingAccessToken',
    GotAccessToken: 'GotAccessToken',
    NotStarted: 'NotStarted',
    RedirectingForAccessToken: 'RedirectingForAccessToken',
};
