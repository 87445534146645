import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { Pool } from '../../models/pool';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { poolAdapter } from '../adapters/pool-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'discoverPoolsForProjects' | 'discoverPoolsForDevBoxes';
type IndexedStatusName = 'listPools';

export interface PoolDataStore {
    pools: EntityState<Entity<Pool>>;
}

export const PoolDataStore = createInitializer<PoolDataStore>({
    pools: poolAdapter.getInitialState(),
});

export type PoolStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const PoolStatusStore = createInitializer<PoolStatusStore>({
    discoverPoolsForProjects: Status(),
    discoverPoolsForDevBoxes: Status(),
    listPools: statusAdapter.getInitialState(),
});

export interface PoolStore {
    data: PoolDataStore;
    status: PoolStatusStore;
}

export const PoolStore = createInitializer<PoolStore>({
    data: PoolDataStore(),
    status: PoolStatusStore(),
});
