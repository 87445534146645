import { ITelemetryItem, generateW3CId, newId } from '@microsoft/applicationinsights-core-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AzureResourceManagerHostname, ProviderNamespace } from '../../constants/azure';
import { DevBoxOperationConstants } from '../../constants/dev-box';
import { MicrosoftGraphHostname } from '../../constants/graph';
import { Method } from '../../constants/http';
import { LoginMicrosoftOnlineHostname } from '../../constants/identity';
import { StringifiedHostnamePattern, StringifiedPattern } from '../../constants/patterns';
import {
    AzureResourceManagerDependencyKind,
    DataPlaneDependencyKind,
    DependencyKind,
    DependencyKindValues,
    LoginMicrosoftOnlineDependencyKind,
    MicrosoftGraphDependencyKind,
    Property,
    Severity,
    TelemetryType,
} from '../../constants/telemetry';
import { tryGetDevCenterFromDataPlaneUri } from '../../ids/data-plane';
import { TelemetryFilteringActionTargetForScrub } from '../../models/telemetry';
import { UnionValueMap } from '../../types/union-map';
import { isUndefinedOrWhiteSpace } from '../string';
import { TrackTimedPerformanceMetricOptions } from './channel';

interface ScrubbingRule {
    pattern: RegExp;
    replacementValue: string;
}

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const azureResourceManagerMatchingRules: UnionValueMap<AzureResourceManagerDependencyKind, RegExp> = {
    Batch: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.AzureResourceManager}/batch\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetProjectEnvironmentTypePermissions: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.AzureResourceManager}/subscriptions/${StringifiedPattern.Guid}/resourcegroups/${StringifiedPattern.ResourceName}/providers/${ProviderNamespace.MicrosoftDevCenter}/projects/${StringifiedPattern.ResourceName}/environmenttypes/${StringifiedPattern.ResourceName}/providers/${ProviderNamespace.MicrosoftAuthorization}/permissions\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetProjectPermissions: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.AzureResourceManager}/subscriptions/${StringifiedPattern.Guid}/resourcegroups/${StringifiedPattern.ResourceName}/providers/${ProviderNamespace.MicrosoftDevCenter}/projects/${StringifiedPattern.ResourceName}/providers/${ProviderNamespace.MicrosoftAuthorization}/permissions\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListLocations: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.AzureResourceManager}/subscriptions/${StringifiedPattern.Guid}/locations\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListProjectEnvironmentTypes: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.AzureResourceManager}/subscriptions/${StringifiedPattern.Guid}/resourcegroups/${StringifiedPattern.ResourceName}/providers/${ProviderNamespace.MicrosoftDevCenter}/projects/${StringifiedPattern.ResourceName}/environmenttypes\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListTenants: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.AzureResourceManager}/tenants\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    Resources: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.AzureResourceManager}/providers/Microsoft.ResourceGraph/resources`,
        'i'
    ),
};

const dataPlaneMatchingRules: UnionValueMap<DataPlaneDependencyKind, RegExp> = {
    CreateDevBox: new RegExp(
        `^${Method.PUT} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    CreateOrReplaceEnvironment: new RegExp(
        `^${Method.PUT} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    CreateCustomizationGroup: new RegExp(
        `^${Method.PUT} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/customizationgroups/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    DelayAllDevBoxActions: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/actions:delay\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    DelayDevBoxAction: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/actions/${StringifiedPattern.ResourceName}:delay\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    DeleteDevBox: new RegExp(
        `^${Method.DELETE} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    DeleteEnvironment: new RegExp(
        `^${Method.DELETE} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetCatalog: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/catalogs/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetCustomizationGroup: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/customizationgroups/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetCustomizationTaskDefinition: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/catalogs/${StringifiedPattern.ResourceName}/customizationtasks/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetCustomizationTaskLog: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/customizationgroups/${StringifiedPattern.ResourceName}/logs/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetDevBox: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetDevBoxAction: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/actions/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetDevBoxOperation: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/operations/${StringifiedPattern.Guid}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetEnvironment: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetEnvironmentDefinition: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/catalogs/${StringifiedPattern.ResourceName}/environmentdefinitions/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetEnvironmentOperation: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}/operations/${StringifiedPattern.Guid}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetEnvironmentOperationLogs: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}/operations/${StringifiedPattern.Guid}/logs\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetPool: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/pools/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetProject: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetRemoteConnection: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/remoteconnection\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    GetSchedule: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/pools/${StringifiedPattern.ResourceName}/schedules/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    HeadDevCenter: new RegExp(`^${Method.HEAD} ${StringifiedHostnamePattern.DataPlane}`, 'i'),

    ListCatalogs: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/catalogs\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListCustomizationGroups: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/customizationgroups\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListCustomizationTaskDefinitions: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/customizationtasks\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListDevBoxActions: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/actions\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListDevBoxes: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListDevBoxOperations: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/operations\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListEnvironmentDefinitions: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/environmentdefinitions\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListEnvironmentOperations: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}/operations\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListEnvironments: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/environments\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListEnvironmentTypes: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/environmenttypes\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListPools: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/pools\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListProjects: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ListSchedulesByProject: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/schedules\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    RepairDevBox: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}:repair\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    RestartDevBox: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}:restart\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    SkipDevBoxAction: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}/actions/${StringifiedPattern.ResourceName}:skip\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    StartDevBox: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}:start\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    StopDevBox: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${DevBoxOperationConstants.UserId}/devboxes/${StringifiedPattern.ResourceName}:stop\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    UpdateEnvironment: new RegExp(
        `^${Method.PATCH} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/users/${StringifiedPattern.PossiblyAnonymizedGuid}/environments/${StringifiedPattern.ResourceName}\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),

    ValidateCustomizationTasks: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.DataPlane}/projects/${StringifiedPattern.ResourceName}/customizationtasks:validategroup\\?${StringifiedPattern.ApiVersion}`,
        'i'
    ),
};

const loginMicrosoftOnlineMatchingRules: UnionValueMap<LoginMicrosoftOnlineDependencyKind, RegExp> = {
    PostToken: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.LoginMicrosoftOnline}/(common|${StringifiedPattern.Guid})/oauth2/v2\.0/token`,
        'i'
    ),
};

const microsoftGraphMatchingRules: UnionValueMap<MicrosoftGraphDependencyKind, RegExp> = {
    GetBannerLogoForOrganization: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.MicrosoftGraph}/v1\\.0/organization/${StringifiedPattern.Guid}/branding/localizations/default/bannerlogo`,
        'i'
    ),

    GetOrganization: new RegExp(`^${Method.GET} ${StringifiedHostnamePattern.MicrosoftGraph}/v1\\.0/organization`, 'i'),

    GetPhotoForSignedInUser: new RegExp(
        `^${Method.GET} ${StringifiedHostnamePattern.MicrosoftGraph}/v1\\.0/me/(photo|photos/.+)/\\$value$`,
        'i'
    ),

    GetSignedInUser: new RegExp(`^${Method.GET} ${StringifiedHostnamePattern.MicrosoftGraph}/v1\\.0/me$`, 'i'),

    ListDirectoryObjectsByIds: new RegExp(
        `^${Method.POST} ${StringifiedHostnamePattern.MicrosoftGraph}/v1\\.0/directoryobjects/getbyids`,
        'i'
    ),
};

const scrubbingRules: UnionValueMap<TelemetryFilteringActionTargetForScrub, ScrubbingRule> = {
    UserIdInPath: {
        pattern: new RegExp(`/users/${StringifiedPattern.Guid}`, 'i'),
        replacementValue: '/users/xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
    },
};
/* eslint-enable security/detect-non-literal-regexp */

const getDependencyKindFromRules = <TKind extends DependencyKind>(
    name: string,
    rules: UnionValueMap<TKind, RegExp>
): string | undefined => {
    for (const key in rules) {
        const pattern = rules[key];

        if (pattern.test(name)) {
            return DependencyKindValues[key];
        }
    }

    return undefined;
};

export const createActivityId = (): string => newId(32);

export const createOptionsForDataPlaneResourceMetric = (
    uri: string,
    activityId?: string,
    ...errorCodes: string[]
): TrackTimedPerformanceMetricOptions => ({
    activityId,
    properties: {
        [Property.DevCenter]: tryGetDevCenterFromDataPlaneUri(uri),
    },

    // Only include errorCodes in options if any were actually given
    ...(errorCodes.length > 0 ? { errorCodes } : {}),
});

export const getDependencyKindOrDefault = (item: ITelemetryItem): string | undefined => {
    const { baseType } = item;
    const name: string | undefined = item.baseData?.['name'];

    // Return undefined immediately if name is undefined/white space or not a dependency
    if (isUndefinedOrWhiteSpace(name) || baseType !== TelemetryType.Dependency) {
        return undefined;
    }

    const [, url] = name.split(' ');

    // If url is undefined after split, we already know this dependency isn't of expected pattern "[method] [url]"
    if (isUndefinedOrWhiteSpace(url)) {
        return undefined;
    }

    // ARM hostname: check ARM rules
    if (url.startsWith(`https://${AzureResourceManagerHostname}`)) {
        return getDependencyKindFromRules(name, azureResourceManagerMatchingRules);
    }

    // Identity hostname: check identity rules
    if (url.startsWith(`https://${LoginMicrosoftOnlineHostname}`)) {
        return getDependencyKindFromRules(name, loginMicrosoftOnlineMatchingRules);
    }

    // Graph hostname: check Graph rules
    if (url.startsWith(`https://${MicrosoftGraphHostname}`)) {
        return getDependencyKindFromRules(name, microsoftGraphMatchingRules);
    }

    // Otherwise: check Data Plane rules
    return getDependencyKindFromRules(name, dataPlaneMatchingRules);
};

export const getSeverityLevelForSeverity = (severity: Severity): SeverityLevel => {
    switch (severity) {
        case Severity.Critical:
            return SeverityLevel.Critical;
        case Severity.Error:
            return SeverityLevel.Error;
        case Severity.Information:
            return SeverityLevel.Information;
        case Severity.Verbose:
            return SeverityLevel.Verbose;
        case Severity.Warning:
            return SeverityLevel.Warning;
        default:
            return SeverityLevel.Information;
    }
};

export const getSpanId = (): string => generateW3CId().substring(0, 16);

export const getTraceId = (): string => generateW3CId();

export const scrubValue = (scrubbingTarget: TelemetryFilteringActionTargetForScrub, value: string): string => {
    const { pattern, replacementValue } = scrubbingRules[scrubbingTarget];
    return value.replace(pattern, replacementValue);
};
