import { IPersonaCoinStyleProps, IPersonaCoinStyles } from '@fluentui/react';
import { getSemanticColors } from '../../utilities/styles';

export const PersonaCoinStyles = (props: IPersonaCoinStyleProps): Partial<IPersonaCoinStyles> => {
    const { theme } = props;

    const [personaCoinInitialsColor, personaCoinInitialsColorUnknown] = getSemanticColors(
        theme,
        'personaCoinInitialsColor',
        'personaCoinInitialsColorUnknown'
    );

    return {
        initials: {
            color: props.showUnknownPersonaCoin ? personaCoinInitialsColorUnknown : personaCoinInitialsColor,
        },
    };
};
