import { IButtonProps, IButtonStyles } from '@fluentui/react';
import { getSemanticColor, getSemanticColors } from '../../utilities/styles';
import { AppSemanticColor } from '../app-semantic-colors';

export const CommandBarButtonStyles = (props: IButtonProps): Partial<IButtonStyles> => {
    const { theme } = props;

    if (!theme) {
        return {};
    }

    const [
        commandBarButtonBackgroundSelected,
        commandBarButtonBackgroundHover,
        commandBarButtonBackgroundSelectedHover,
        commandBarButtonIcon,
    ] = getSemanticColors(
        theme,
        'commandBarButtonBackgroundSelected',
        'commandBarButtonBackgroundHover',
        'commandBarButtonBackgroundSelectedHover',
        'commandBarButtonIcon'
    );

    const iconCheckedColor = `${commandBarButtonIcon} !important`;

    return {
        root: {
            backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
            cursor: 'pointer',
        },
        rootChecked: {
            backgroundColor: commandBarButtonBackgroundSelected,
        },
        rootExpanded: {
            backgroundColor: commandBarButtonBackgroundSelected,
        },
        rootExpandedHovered: {
            backgroundColor: commandBarButtonBackgroundSelectedHover,
        },
        rootHovered: {
            backgroundColor: commandBarButtonBackgroundHover,
        },
        rootPressed: {
            backgroundColor: commandBarButtonBackgroundSelected,
        },
        icon: {
            color: commandBarButtonIcon,
        },
        iconPressed: {
            color: commandBarButtonIcon,
        },
        iconExpanded: {
            color: commandBarButtonIcon,
        },
        iconExpandedHovered: {
            color: commandBarButtonIcon,
        },
        iconHovered: {
            color: commandBarButtonIcon,
        },
        iconChecked: {
            color: iconCheckedColor,
        },
    };
};
