import { defineMessages } from 'react-intl';

export const tenantPickerMessages = defineMessages({
    guestTenantDisplayNameAriaLabel: {
        id: 'TenantPicker_GuestTenantDisplayName_AriaLabel',
        defaultMessage: '{tenantDisplayName} (Guest)',
        description:
            'Aria label for tenant option when the signed in user is a guest in that tenant. {tenantDisplayName} should not be localized.',
    },
    guestTenantDisplayNameText: {
        id: 'TenantPicker_GuestTenantDisplayName_Text',
        defaultMessage: '{tenantDisplayName} (Guest)',
        description:
            'Message format for tenant display name when the signed in user is a guest in that tenant. {tenantDisplayName} should not be localized.',
    },
});
