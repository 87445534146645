import { UnionMap, UnionValueMap } from '../../../types/union-map';
import { MockScenarioConfiguration } from '../../types';
import {
    GraphDirectoryObjectScenarioName,
    GraphDirectoryObjectScenarios,
} from './graph-directory-object/graph-directory-object-scenarios';
import { GraphOrganizationScenarioName, GraphOrganizationScenarios } from './organization/graph-organization-scenarios';

export type GraphScenarioName = GraphOrganizationScenarioName | GraphDirectoryObjectScenarioName;

export const GraphScenarioName: UnionMap<GraphScenarioName> = {
    ...GraphOrganizationScenarioName,
    ...GraphDirectoryObjectScenarioName,
};

export const GraphScenarios: UnionValueMap<GraphScenarioName, MockScenarioConfiguration> = {
    ...GraphOrganizationScenarios,
    ...GraphDirectoryObjectScenarios,
};
