import { createEnvironmentDataPlaneUri } from '../../../ids/environment';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { hasNoValue } from '../../../utilities/object';
import { areStringsEquivalent, isUndefinedOrEmpty } from '../../../utilities/string';
import { EnvironmentExpirationViewModel } from '../../environment/expiration-date/add-or-update-expiration-form-field-group/models';
import { getExpirationDate } from '../selectors';
import { EnvironmentExpirationFieldErrorType, EnvironmentNameFieldErrorType } from './models';

// Note: below regex is borrowed from API spec.
// https://devdiv.visualstudio.com/DefaultCollection/OnlineServices/_git/azure-devcenter?path=/src/sdk/specification/devcenter/data-plane/Microsoft.DevCenter/preview/2023-07-01-preview/environments.json&version=GBmain&line=1278&lineEnd=1278&lineStartColumn=7&lineEndColumn=54&lineStyle=plain&_a=contents
const EnvironmentNameFormat = /^[a-zA-Z0-9_][a-zA-Z0-9-_\.]*$/;
const EnvironmentNameMaximumLength = 63;
const EnvironmentNameMinimumLength = 3;

export const getEnvironmentNameFieldErrorType = (
    value: string,
    projectId: string | undefined,
    existingEnvironmentIds: string[],
    userId: string
): EnvironmentNameFieldErrorType => {
    // Note: doing this check separately and first to prevent nullrefs at runtime
    if (isUndefinedOrEmpty(value)) {
        return EnvironmentNameFieldErrorType.TooShort;
    }

    if (!EnvironmentNameFormat.test(value)) {
        return EnvironmentNameFieldErrorType.InvalidFormat;
    }

    if (value.length < EnvironmentNameMinimumLength) {
        return EnvironmentNameFieldErrorType.TooShort;
    }

    if (value.length > EnvironmentNameMaximumLength) {
        return EnvironmentNameFieldErrorType.TooLong;
    }

    if (projectId) {
        const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(projectId);

        const candidateEnvironmentId = createEnvironmentDataPlaneUri({
            devCenter,
            environmentName: value,
            projectName,
            user: userId,
        });

        if (existingEnvironmentIds.find((id) => areStringsEquivalent(id, candidateEnvironmentId, true))) {
            return EnvironmentNameFieldErrorType.AlreadyExists;
        }
    }

    return EnvironmentNameFieldErrorType.None;
};

export const getEnvironmentExpirationFieldErrorType = (
    value: EnvironmentExpirationViewModel | undefined,
    enableExpiration: boolean
): EnvironmentExpirationFieldErrorType => {
    if (value === undefined) {
        return EnvironmentExpirationFieldErrorType.None;
    }

    if (!enableExpiration) {
        return EnvironmentExpirationFieldErrorType.None;
    }

    const { expirationTime, expirationCalendarDate } = value;

    if (hasNoValue(expirationTime)) {
        return EnvironmentExpirationFieldErrorType.TimeRequired;
    }

    if (hasNoValue(expirationCalendarDate)) {
        return EnvironmentExpirationFieldErrorType.DateRequired;
    }

    const currentDate = new Date();
    const inputDate = getExpirationDate(value);

    if (inputDate !== undefined && inputDate <= currentDate) {
        return EnvironmentExpirationFieldErrorType.Invalid;
    }

    return EnvironmentExpirationFieldErrorType.None;
};
