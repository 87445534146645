import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import { DevBoxActionDelayResultStatus } from '../../../../data/contracts/dev-box-action';
import {
    DelayAllDevBoxActionsResponse,
    delayAllDevBoxActions as delayAllDevBoxActionsApiCall,
} from '../../../../data/services/data-plane-api/dev-box-action';
import { getTokensFromDevBoxDataPlaneUri } from '../../../../ids/dev-box';
import { createDevBoxActionDataPlaneUri } from '../../../../ids/dev-box-action';
import { ClientError, FailureOperation, isFailureResponse } from '../../../../models/common';
import { getErrorCodes } from '../../../../utilities/aggregated-result';
import {
    aggregateFailureResponses,
    createFailureResponseFromCloudErrorBodyOrDefault,
} from '../../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../../utilities/telemetry/helpers';
import {
    delayAllDevBoxActions,
    delayAllDevBoxActionsError,
    delayAllDevBoxActionsFailed,
    delayAllDevBoxActionsSuccess,
    delayDevBoxActionFailed,
    delayDevBoxActionSuccess,
} from '../../../actions/dev-box-action/dev-box-action-action-creators';
import {
    DelayAllDevBoxActionsAction,
    DelayDevBoxActionFailedPayload,
    DelayDevBoxActionSuccessPayload,
} from '../../../actions/dev-box-action/dev-box-action-actions';
import { getAccessToken } from '../../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../../actions/identity/identity-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { takeEvery } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';

export function* delayAllDevBoxActionsSaga(action: DelayAllDevBoxActionsAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { delayUntil, id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: DelayAllDevBoxActionsResponse = yield call(
            delayAllDevBoxActionsApiCall,
            id,
            delayUntil,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(delayAllDevBoxActionsFailed({ failure: response, id }, meta));
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DelayAllDevBoxActions,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, ...getErrorCodes(response))
            );

            return;
        }

        // Pull individual success / failure results off of the delay all response data
        const delayResults = response.data;

        const failedPayloads: DelayDevBoxActionFailedPayload[] = [];
        const succeededPayloads: DelayDevBoxActionSuccessPayload[] = [];

        delayResults.forEach((delayResult) => {
            const actionId = createDevBoxActionDataPlaneUri({
                ...getTokensFromDevBoxDataPlaneUri(id),
                devBoxActionName: delayResult.name,
            });

            if (delayResult.result === DevBoxActionDelayResultStatus.Failed) {
                const { error } = delayResult;

                const failure = createFailureResponseFromCloudErrorBodyOrDefault(
                    error,
                    FailureOperation.DelayDevBoxAction
                );

                failedPayloads.push({
                    failure,
                    id: actionId,
                });
            } else {
                succeededPayloads.push({
                    id: actionId,
                    result: delayResult.action,
                });
            }
        });

        // Dispatch actions for individual dev box action operations
        if (failedPayloads.length > 0) {
            yield put(delayDevBoxActionFailed(failedPayloads, meta));
        }

        if (succeededPayloads.length > 0) {
            yield put(delayDevBoxActionSuccess(succeededPayloads, meta));
        }

        // Dispatch actions for delay-all operations
        if (failedPayloads.length > 0 && succeededPayloads.length < 1) {
            const failures = failedPayloads.map((payload) => payload.failure);
            const failure = aggregateFailureResponses(FailureOperation.DelayAllDevBoxActions, ...failures);
            yield put(delayAllDevBoxActionsFailed({ failure, id }, meta));
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DelayAllDevBoxActions,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, ...getErrorCodes(failures))
            );

            return;
        }

        yield put(delayAllDevBoxActionsSuccess({ id }, meta));

        const outcome = failedPayloads.length > 0 ? AsyncOutcome.PartialSuccess : AsyncOutcome.Success;

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DelayAllDevBoxActions,
            startTime,
            outcome,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DelayAllDevBoxActions);
        yield put(delayAllDevBoxActionsError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DelayAllDevBoxActions,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* delayAllDevBoxActionsListenerSaga(): SagaIterator {
    yield takeEvery(delayAllDevBoxActions, delayAllDevBoxActionsSaga);
}
