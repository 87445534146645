import { EnvironmentProvisioningState } from '../../constants/environment';
import { Environment, FailureOnEnvironment } from '../../models/environment';
import { UnionMap } from '../../types/union-map';
import { EnvironmentDefinitionParameterViewModel } from './parameters-field/models';

export type EnvironmentExpirationDateOffsetUnit =
    | 'Years'
    | 'Months'
    | 'Weeks'
    | 'Days'
    | 'Hours'
    | 'Minutes'
    | 'Seconds';

export const EnvironmentExpirationDateOffsetUnit: UnionMap<EnvironmentExpirationDateOffsetUnit> = {
    Years: 'Years',
    Months: 'Months',
    Weeks: 'Weeks',
    Days: 'Days',
    Hours: 'Hours',
    Minutes: 'Minutes',
    Seconds: 'Seconds',
};

export interface EnvironmentExpirationDateOffset {
    offset: number;
    unit: EnvironmentExpirationDateOffsetUnit;
}

export interface EnvironmentViewModel {
    key: string;
    resource: Environment;
    state: EnvironmentProvisioningState;
    ownerDisplayName: string | undefined;
    ownerIsReady: boolean;
    environmentDefinition: EnvironmentDefinitionViewModel | undefined;
    isCardContentReady: boolean;
    projectName: string;
    failureOnEnvironment?: FailureOnEnvironment;
    devCenterName: string;
    expirationDateOffset?: EnvironmentExpirationDateOffset;
    projectDisplayName?: string;
}

export interface EnvironmentDefinitionViewModel {
    description: string | undefined;
    parameters: EnvironmentDefinitionParameterViewModel[];
}
