import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { GetAzureDevOpsRepoResponse, getAzureDevOpsRepo } from '../../../data/services/azure-dev-ops/vso-code';
import { AzureDevOpsRepo } from '../../../models/azure-dev-ops';
import { ClientError, FailureOperation, Nothing, isFailureResponse } from '../../../models/common';
import {
    AzureDevOpsRepoUrlParts,
    getAzureDevOpsBranchNameFromRefName,
    getAzureDevOpsRepoUrlParts,
} from '../../../utilities/azure-dev-ops';
import {
    getAzureDevOpsRepoByCloneUri,
    getAzureDevOpsRepoByCloneUriError,
    getAzureDevOpsRepoByCloneUriFailed,
    getAzureDevOpsRepoByCloneUriSuccess,
} from '../../actions/azure-dev-ops/azure-dev-ops-action-creators';
import { GetAzureDevOpsRepoByCloneUriAction } from '../../actions/azure-dev-ops/azure-dev-ops-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureDevOpsPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* getAzureDevOpsRepoByCloneUriSaga(action: GetAzureDevOpsRepoByCloneUriAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { cloneUrl } = payload;

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForAzureDevOpsPayload(), meta));

        const repoUrlParts: AzureDevOpsRepoUrlParts = yield call(getAzureDevOpsRepoUrlParts, cloneUrl);

        const { organizationName, projectName, repoName } = repoUrlParts;

        const response: GetAzureDevOpsRepoResponse = yield call(
            getAzureDevOpsRepo,
            organizationName,
            projectName,
            repoName,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            if (response.statusCode === 404) {
                yield resolveAction(action, { succeeded: true, data: Nothing });
                yield put(getAzureDevOpsRepoByCloneUriSuccess({ result: Nothing, cloneUrl }, meta));
                return;
            }

            yield resolveAction(action, response);
            yield put(getAzureDevOpsRepoByCloneUriFailed({ failure: response, cloneUrl }, meta));
            return;
        }

        const defaultBranch = response.data.defaultBranch
            ? getAzureDevOpsBranchNameFromRefName(response.data.defaultBranch)
            : undefined;
        const repo: AzureDevOpsRepo = { ...response.data, defaultBranch };

        yield resolveAction(action, { succeeded: true, data: repo });
        yield put(getAzureDevOpsRepoByCloneUriSuccess({ result: repo, cloneUrl }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetAzureDevOpsRepoForCloneUri);
        yield put(getAzureDevOpsRepoByCloneUriError({ error, cloneUrl }, meta));
        yield rejectAction(action, error);
    }
}

export function* getAzureDevOpsRepoByCloneUriListenerSaga(): SagaIterator {
    yield takeEvery(getAzureDevOpsRepoByCloneUri, getAzureDevOpsRepoByCloneUriSaga);
}
