import { UnionValueMap } from '../types/union-map';
import { AzureResourceManagerHostname } from './azure';
import { DataPlaneBaseHostname } from './data-plane';
import { MicrosoftGraphHostname } from './graph';
import { LoginMicrosoftOnlineHostname } from './identity';

/**
 * Miscellaneous patterns
 */

type PatternName = 'ApiVersion' | 'Guid' | 'PossiblyAnonymizedGuid' | 'ResourceName' | 'AzureDevOpsRepoClonePathname';

export const StringifiedPattern: UnionValueMap<PatternName, string> = {
    ApiVersion: 'api-version=\\d{4}-\\d{2}-\\d{2}(-(preview|privatepreview))?',
    Guid: '[ABCDEFabcdef0-9]{8}-[ABCDEFabcdef0-9]{4}-[ABCDEFabcdef0-9]{4}-[ABCDEFabcdef0-9]{4}-[ABCDEFabcdef0-9]{12}',
    PossiblyAnonymizedGuid:
        '([ABCDEFabcdef0-9]{8}-[ABCDEFabcdef0-9]{4}-[ABCDEFabcdef0-9]{4}-[ABCDEFabcdef0-9]{4}-[ABCDEFabcdef0-9]{12}|xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)',
    ResourceName: '[a-zA-Z0-9_][a-zA-Z0-9-_\\.]+',
    // An ADO repo clone URL path is either {org}.visualstudio.com/{collection}/{project}/_git/{repo-name}
    // or dev.azure.com/{org}/{project}/_git/{repo-name}
    AzureDevOpsRepoClonePathname: '/([^/]+)/([^/]+)/_git/([^/]+)$',
};

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
export const Pattern: UnionValueMap<PatternName, RegExp> = {
    ApiVersion: new RegExp(StringifiedPattern.ApiVersion),
    Guid: new RegExp(StringifiedPattern.Guid),
    PossiblyAnonymizedGuid: new RegExp(StringifiedPattern.PossiblyAnonymizedGuid),
    ResourceName: new RegExp(StringifiedPattern.ResourceName),
    AzureDevOpsRepoClonePathname: new RegExp(StringifiedPattern.AzureDevOpsRepoClonePathname),
};
/* eslint-enable security/detect-non-literal-regexp */

/**
 * Hostname patterns
 */

type HostnamePatternName =
    | 'AzureResourceManager'
    | 'DataPlane'
    | 'DataPlaneNonRegional'
    | 'DataPlaneRegional'
    | 'LoginMicrosoftOnline'
    | 'MicrosoftGraph'
    | 'AzureDevOps'
    | 'AzureDevOpsVisualStudio';

export const StringifiedHostnamePattern: UnionValueMap<HostnamePatternName, string> = {
    AzureResourceManager: `https://${AzureResourceManagerHostname}`,

    // Data Plane host: https://{tenant-id}-{dev-center-name}[.{region}].devcenter.azure.com
    DataPlane: `https://${StringifiedPattern.ResourceName}(\\.\\w+)?\\.${DataPlaneBaseHostname}`,

    // Data Plane non-regional host: https://{tenant-id}-{dev-center-name}.devcenter.azure.com
    DataPlaneNonRegional: `https://${StringifiedPattern.ResourceName}\\.${DataPlaneBaseHostname}`,

    // Data Plane regional host: https://{tenant-id}-{dev-center-name}.{region}.devcenter.azure.com
    DataPlaneRegional: `https://(${StringifiedPattern.Guid})-(${StringifiedPattern.ResourceName})\\.\\w+\\.${DataPlaneBaseHostname}`,

    LoginMicrosoftOnline: `https://${LoginMicrosoftOnlineHostname}`,

    MicrosoftGraph: `https://${MicrosoftGraphHostname}`,

    AzureDevOps: 'dev.azure.com',

    AzureDevOpsVisualStudio: `(${StringifiedPattern.ResourceName})\.visualstudio\.com$`,
};

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
export const HostnamePattern: UnionValueMap<HostnamePatternName, RegExp> = {
    AzureResourceManager: new RegExp(StringifiedHostnamePattern.AzureResourceManager),
    DataPlane: new RegExp(StringifiedHostnamePattern.DataPlane),
    DataPlaneNonRegional: new RegExp(StringifiedHostnamePattern.DataPlaneNonRegional),
    DataPlaneRegional: new RegExp(StringifiedHostnamePattern.DataPlaneRegional),
    LoginMicrosoftOnline: new RegExp(StringifiedHostnamePattern.LoginMicrosoftOnline),
    MicrosoftGraph: new RegExp(StringifiedHostnamePattern.MicrosoftGraph),
    AzureDevOps: new RegExp(StringifiedHostnamePattern.AzureDevOps),
    AzureDevOpsVisualStudio: new RegExp(StringifiedHostnamePattern.AzureDevOpsVisualStudio),
};
/* eslint-enable security/detect-non-literal-regexp */
