import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { warmAllDevCenterNameRecords, warmDevCenterNameRecord } from './dev-center-action-creators';

/**
 * Action types
 */

type DevCenterActionName =
    | ActionName<'WarmAllDevCenterNameRecords', 'Error' | 'Failed' | 'Success'>
    | ActionName<'WarmDevCenterNameRecord', 'Error' | 'Failed' | 'Success'>;

export type DevCenterActionType =
    | ActionType<'WARM_ALL_DEV_CENTER_NAME_RECORDS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'WARM_DEV_CENTER_NAME_RECORD', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const DevCenterActionType: UnionValueMap<DevCenterActionName, DevCenterActionType> = {
    WarmAllDevCenterNameRecords: 'WARM_ALL_DEV_CENTER_NAME_RECORDS',
    WarmAllDevCenterNameRecordsError: 'WARM_ALL_DEV_CENTER_NAME_RECORDS_ERROR',
    WarmAllDevCenterNameRecordsFailed: 'WARM_ALL_DEV_CENTER_NAME_RECORDS_FAILED',
    WarmAllDevCenterNameRecordsSuccess: 'WARM_ALL_DEV_CENTER_NAME_RECORDS_SUCCESS',
    WarmDevCenterNameRecord: 'WARM_DEV_CENTER_NAME_RECORD',
    WarmDevCenterNameRecordError: 'WARM_DEV_CENTER_NAME_RECORD_ERROR',
    WarmDevCenterNameRecordFailed: 'WARM_DEV_CENTER_NAME_RECORD_FAILED',
    WarmDevCenterNameRecordSuccess: 'WARM_DEV_CENTER_NAME_RECORD_SUCCESS',
};

/**
 * Action shorthands
 */

export type WarmAllDevCenterNameRecordsAction = ReturnType<typeof warmAllDevCenterNameRecords>;
export type WarmDevCenterNameRecordAction = ReturnType<typeof warmDevCenterNameRecord>;
