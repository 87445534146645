import { CommandBarButton, DirectionalHint, IContextualMenuProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useFormatter } from '../../../hooks/localization';
import { Tenant } from '../../../models/resource-manager';
import { selectTenant } from '../../../redux/actions/tenant/tenant-action-creators';
import { getHomeTenantId } from '../../../redux/selector/identity-selectors';
import { getSelectedTenant, getTenants } from '../../../redux/selector/tenant-selector';
import { ReturnVoid } from '../../../types/return-void';
import { formatTenantDisplayNameAriaLabel, formatTenantDisplayNameText, getTenantPickerItems } from './selectors';

interface TenantPickerComponentProps {
    homeTenantId?: string;
    ariaLabel: string;
    selectedTenant?: Tenant;
    tenants: Tenant[];
    onTenantSelected: ReturnVoid<typeof selectTenant>;
}

const messages = defineMessages({
    buttonDefaultText: {
        id: 'TenantPicker_Button_DefaultText',
        defaultMessage: 'Select an organization',
        description: 'Button text used when no tenant (organization) is currently selected.',
    },
});

/**
 * Styles
 */

const useTenantPickerStyles = makeStyles({
    root: {
        padding: '0 13px',
    },
});

const useTenantPickerMenuItemStyles = makeStyles({
    root: {
        padding: '0 8px',
    },
});

/**
 * END Styles
 */

export const TenantPickerComponent: React.FC<TenantPickerComponentProps> = React.memo(
    (props: TenantPickerComponentProps) => {
        const { homeTenantId, onTenantSelected, selectedTenant, tenants, ariaLabel } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Styles
        const tenantPickerStyles = useTenantPickerStyles();
        const tenantPickerMenuItemStyles = useTenantPickerMenuItemStyles();

        // Formatter hooks
        const tenantDisplayNameAriaLabelFormatter = useFormatter(formatTenantDisplayNameAriaLabel);
        const tenantDisplayNameTextFormatter = useFormatter(formatTenantDisplayNameText);

        // Callback hooks
        const onMenuItemClicked = React.useCallback(
            (tenant: Tenant) => {
                // Ignore if the already-selected tenant was selected again
                if (tenant.tenantId === selectedTenant?.tenantId) {
                    return;
                }

                onTenantSelected({ id: tenant.tenantId });
            },
            [onTenantSelected, selectedTenant]
        );

        // Memoized data
        const menuProps = React.useMemo<IContextualMenuProps>(
            () => ({
                directionalHint: DirectionalHint.bottomRightEdge,
                items: getTenantPickerItems(
                    tenantDisplayNameAriaLabelFormatter,
                    tenantDisplayNameTextFormatter,
                    homeTenantId,
                    tenantPickerMenuItemStyles,
                    onMenuItemClicked,
                    tenants
                ),
            }),
            [
                homeTenantId,
                onMenuItemClicked,
                tenantDisplayNameAriaLabelFormatter,
                tenantDisplayNameTextFormatter,
                tenantPickerMenuItemStyles,
                tenants,
            ]
        );

        const selectedTenantDisplayName = React.useMemo(
            () =>
                selectedTenant ? tenantDisplayNameTextFormatter({ homeTenantId, tenant: selectedTenant }) : undefined,
            [homeTenantId, selectedTenant, tenantDisplayNameTextFormatter]
        );

        // Note: while there's a fallback if there's no tenant selected, that case shouldn't be hit in current
        // implementation unless an invalid tenantId is given in the search string.
        const commandBarButtonText = React.useMemo(
            () => selectedTenantDisplayName ?? formatMessage(messages.buttonDefaultText),
            [selectedTenantDisplayName]
        );

        return (
            <CommandBarButton
                menuProps={menuProps}
                styles={tenantPickerStyles}
                text={commandBarButtonText}
                aria-label={ariaLabel}
            />
        );
    }
);

interface TenantPickerContainerProps {
    ariaLabel: string;
}

export const TenantPickerContainer: React.FC<TenantPickerContainerProps> = (props: TenantPickerContainerProps) => {
    const { ariaLabel } = props;

    // Action creator hooks
    const onTenantSelected = useActionCreator(selectTenant);

    // Application state hooks
    const homeTenantId = useSelector(getHomeTenantId);
    const selectedTenant = useSelector(getSelectedTenant);
    const tenants = useSelector(getTenants);

    return (
        <TenantPickerComponent
            homeTenantId={homeTenantId}
            onTenantSelected={onTenantSelected}
            selectedTenant={selectedTenant}
            tenants={tenants}
            ariaLabel={ariaLabel}
        />
    );
};

export default TenantPickerContainer;
