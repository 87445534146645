import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { initializeLocalization } from './localization-action-creators';

/**
 * Action types
 */

type LocalizationActionName = ActionName<'InitializeLocalization', 'Error' | 'Success'>;

export type LocalizationActionType = ActionType<'INITIALIZE_LOCALIZATION', '_ERROR' | '_SUCCESS'>;

export const LocalizationActionType: UnionValueMap<LocalizationActionName, LocalizationActionType> = {
    InitializeLocalization: 'INITIALIZE_LOCALIZATION',
    InitializeLocalizationError: 'INITIALIZE_LOCALIZATION_ERROR',
    InitializeLocalizationSuccess: 'INITIALIZE_LOCALIZATION_SUCCESS',
};

/**
 * Action shorthands
 */

export type InitializeLocalizationAction = ReturnType<typeof initializeLocalization>;
