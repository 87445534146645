import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ListDevBoxActionsResponse, listDevBoxActions } from '../../../../data/services/data-plane-api/dev-box-action';
import { ClientError, FailureOperation, isFailureResponse } from '../../../../models/common';
import {
    listDevBoxActions as listDevBoxActionsActionCreator,
    listDevBoxActionsError,
    listDevBoxActionsFailed,
    listDevBoxActionsSuccess,
} from '../../../actions/dev-box-action/dev-box-action-action-creators';
import { ListDevBoxActionsAction } from '../../../actions/dev-box-action/dev-box-action-actions';
import { getAccessToken } from '../../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../../actions/identity/identity-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { resolveAction } from '../../../effects/resolve-action';
import { takeEvery } from '../../../effects/take';

export function* listDevBoxActionsSaga(action: ListDevBoxActionsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListDevBoxActionsResponse = yield call(listDevBoxActions, id, accessToken, activityId);

        // If the request fails with a 404, treat this like it's a success with an empty result. Most of the time a 404 on
        // this operation means the user doesn't have access to read dev box actions.
        if (isFailureResponse(response) && response.statusCode === 404) {
            yield put(listDevBoxActionsSuccess({ id, result: [] }, meta));
            yield resolveAction(action, { data: [], succeeded: true });
            return;
        }

        if (isFailureResponse(response)) {
            yield put(listDevBoxActionsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        yield put(listDevBoxActionsSuccess({ id, result: response.data }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListDevBoxActions);
        yield put(listDevBoxActionsError({ error, id }, meta));
    }
}

export function* listDevBoxActionsListenerSaga(): SagaIterator {
    yield takeEvery(listDevBoxActionsActionCreator, listDevBoxActionsSaga);
}
