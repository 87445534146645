import { EnvironmentOperation } from '../../../models/environment';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import { listEnvironmentOperations } from './environment-operation-action-creators';

/**
 * Action types
 */

type EnvironmentOperationActionName = ActionName<'ListEnvironmentOperations', 'Error' | 'Failed' | 'Success'>;

export type EnvironmentOperationActionType = ActionType<
    'LIST_ENVIRONMENT_OPERATIONS',
    '_ERROR' | '_FAILED' | '_SUCCESS'
>;

export const EnvironmentOperationActionType: UnionValueMap<
    EnvironmentOperationActionName,
    EnvironmentOperationActionType
> = {
    ListEnvironmentOperations: 'LIST_ENVIRONMENT_OPERATIONS',
    ListEnvironmentOperationsError: 'LIST_ENVIRONMENT_OPERATIONS_ERROR',
    ListEnvironmentOperationsFailed: 'LIST_ENVIRONMENT_OPERATIONS_FAILED',
    ListEnvironmentOperationsSuccess: 'LIST_ENVIRONMENT_OPERATIONS_SUCCESS',
};

/**
 * Payloads
 */

export type ListEnvironmentOperationsSuccessPayload = IndexedPayload & ResultPayload<EnvironmentOperation[]>;

/**
 * Action shorthands
 */

export type ListEnvironmentOperationsAction = ReturnType<typeof listEnvironmentOperations>;
