import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { compact } from '../../../utilities/array';
import {
    dismissContent,
    dismissContentError,
    dismissContentFailed,
    dismissContentSuccess,
} from '../../actions/application/application-action-creators';
import { DismissContentAction } from '../../actions/application/application-actions';
import { getStorageValue, setStorageValue } from '../../actions/storage/storage-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* dismissContentSaga(action: DismissContentAction): SagaIterator {
    const { content } = getPayload(action);

    try {
        // Get storage type to use (based on browser environment factors)
        const storageType = yield select(getStorageType);

        // Get current value for the dismissed messages storage slot, if it exists yet
        const getStorageValueResponse: DataResponse<string> = yield putAndAwait(
            getStorageValue({
                getForSignedInUser: true,
                key: StorageKey.DismissedMessages,
                storageType,
            })
        );

        if (isFailureResponse(getStorageValueResponse)) {
            yield put(dismissContentFailed({ content, failure: getStorageValueResponse }));
            return;
        }

        // Parse dismissed messages storage value into an array
        const { data } = getStorageValueResponse;

        const dismissedContent = compact(
            data.split(',').map((message) => {
                const rawValue = message.trim();
                return rawValue;
            })
        );

        // Add message to dismiss to set, if it wasn't already there
        const newDismissedContent = dismissedContent.includes(content)
            ? dismissedContent
            : [...dismissedContent, content];

        // Update storage slot with new set
        const setStorageValueResponse: DataResponse = yield putAndAwait(
            setStorageValue({
                key: StorageKey.DismissedMessages,
                setForSignedInUser: true,
                storageType,
                value: newDismissedContent.join(','),
            })
        );

        if (isFailureResponse(setStorageValueResponse)) {
            yield put(dismissContentFailed({ content, failure: setStorageValueResponse }));
            return;
        }

        yield put(dismissContentSuccess({ content }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DismissMessage);
        yield put(dismissContentError({ content, error }));
    }
}

export function* dismissContentListenerSaga(): SagaIterator {
    yield takeEvery(dismissContent, dismissContentSaga);
}
