import { InitializationStatus } from '../../models/common';
import { createInitializer } from '../../utilities/initializer';

export interface InitializationStatusStore {
    initialization: InitializationStatus;
}

export const InitializationStatusStore = createInitializer<InitializationStatusStore>({
    initialization: InitializationStatus(),
});
