import { IButtonProps, ISpinnerStyles, makeStyles, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';

export interface SubmitButtonProps extends IButtonProps {
    isSubmitting: boolean;
    text: string;
    spinnerStyles?: ISpinnerStyles;
}

/**
 * Style Section
 */

const useSpinnerStyles = makeStyles({
    root: {
        paddingRight: '5px',
    },
    circle: {
        width: 12,
        height: 12,
    },
});

/* END */

export const SubmitButton: React.FC<SubmitButtonProps> = (props: SubmitButtonProps) => {
    const { isSubmitting, text, disabled, spinnerStyles: providedSpinnerStyles, styles: providedStyles } = props;

    // Style hooks
    const spinnerStyles = useSpinnerStyles();

    const mergedSpinnerStyles: ISpinnerStyles = {
        ...spinnerStyles,
        ...(providedSpinnerStyles ?? {}),
    };
    const mergedProps: IButtonProps = {
        ...props,
        ...{ disabled: disabled || isSubmitting, text: undefined, providedStyles },
    };

    return (
        <PrimaryButton {...mergedProps}>
            {isSubmitting ? <Spinner size={SpinnerSize.small} styles={mergedSpinnerStyles} /> : `${text}`}
        </PrimaryButton>
    );
};

export default SubmitButton;
