import { HostnamePattern, Pattern } from '../constants/patterns';
import { AzureDevOpsBranch } from '../models/azure-dev-ops';
import { ClientError, FailureOperation } from '../models/common';
import { tryOrDefault } from './try-or-default';
import { tryGetUrl } from './url';

const isAzureDevOpsAzureHostname = (hostname: string): boolean => HostnamePattern.AzureDevOps.test(hostname);
const isAzureDevOpsVisualStudioHostname = (hostname: string): boolean =>
    HostnamePattern.AzureDevOpsVisualStudio.test(hostname);
const isAzureDevOpsHostname = (hostname: string): boolean =>
    isAzureDevOpsAzureHostname(hostname) || isAzureDevOpsVisualStudioHostname(hostname);

const isAzureDevOpsRepoClonePathname = (pathname: string): boolean => {
    return Pattern.AzureDevOpsRepoClonePathname.test(pathname);
};

export const isUrlAdoRepoCloneUrl = (url: URL): boolean => {
    const { hostname, pathname } = url;

    return isAzureDevOpsHostname(hostname) && isAzureDevOpsRepoClonePathname(pathname);
};

/**
 * Returns true if the url is a valid Azure DevOps clone url, false otherwise
 *
 * (ADO clone urls follow the pattern of either `dev.azure.com/{org}/{project}/_git/{repo-name}`, or `{org}.visualstudio.com/{collection}/{project}/_git/{repo-name}`)
 */
export const isAdoRepoCloneUrl = (value: string | undefined): boolean => {
    if (!value) {
        return false;
    }

    const url = tryGetUrl(value);

    return !!url && isUrlAdoRepoCloneUrl(url);
};

/** Returns true if the url matches hostname `dev.azure.com`, false otherwise */
export const isAdoUrl = (value: string): boolean => {
    const url = tryGetUrl(value);

    return !!url && isAzureDevOpsAzureHostname(url.hostname);
};

export type AzureDevOpsRepoUrlParts = {
    organizationName: string;
    projectName: string;
    repoName: string;
};

const invalidCloneUriErrorMessage = 'URL is not a valid Azure DevOps clone url';

/** Returns a repo's organization name, project name, and repo name if the url matches the ADO repo pattern. Throws otherwise. */
export const getAzureDevOpsRepoUrlParts = (urlString: string): AzureDevOpsRepoUrlParts => {
    const url = new URL(urlString);

    if (!isUrlAdoRepoCloneUrl(url)) {
        throw new ClientError(invalidCloneUriErrorMessage, FailureOperation.GetAzureDevOpsRepoUrlParts);
    }

    const { hostname, pathname } = url;

    const pathnameRegexSearch = Pattern.AzureDevOpsRepoClonePathname.exec(pathname);

    if (!pathnameRegexSearch) {
        // Return undefined if pathname doesn't match regex
        // (this should never happen because `isUrlAdoRepoUrl` checks this, but typedef says exec can return null)
        throw new ClientError(invalidCloneUriErrorMessage, FailureOperation.GetAzureDevOpsRepoUrlParts);
    }

    if (isAzureDevOpsAzureHostname(hostname)) {
        // Hostname is `dev.azure.com`, follows the remoteUri pattern ( dev.azure.com/{org}/{project}/_git/{repo-name} )
        const [, organizationName, projectName, repoName] = pathnameRegexSearch;

        return { organizationName, projectName, repoName };
    }

    // Hostname is not the default ADO uri, check if it's a Visual Studio ADO uri
    // ( {accountName}.visualstudio.com/DefaultCollection/{projectName}/... )
    const hostnameRegexSearch = HostnamePattern.AzureDevOpsVisualStudio.exec(hostname);

    if (!hostnameRegexSearch || !isAzureDevOpsVisualStudioHostname(hostname)) {
        // Return undefined if hostname doesn't match visualstudio regex
        // (this should never happen because `isUrlAdoRepoUrl` returns false if hostname is not either azure or visualstudio hostname)
        throw new ClientError(invalidCloneUriErrorMessage, FailureOperation.GetAzureDevOpsRepoUrlParts);
    }

    const [, organizationName] = hostnameRegexSearch;
    const [, , projectName, repoName] = pathnameRegexSearch;

    return { organizationName, projectName, repoName };
};

/** Returns a repo's organization name, project name, and repo name if the url matches the ADO repo pattern. Returns undefined otherwise. */
export const tryGetAzureDevOpsRepoUrlParts = tryOrDefault(getAzureDevOpsRepoUrlParts);

// Azure DevOps refs (branches) can reference the same objectId, need to distinguish them by both name and object id
// Note: OK to use '-' as a delimiter here because `objectId` is not a GUID (contains only a-z and 0-9 characters)
export const getAzureDevOpsBranchKey = (branch: AzureDevOpsBranch): string => `${branch.name}-${branch.objectId}`;

// ADO branch names come back as 'refs/heads/{branchName}', so we will take the substring after the second '/' (at index 11)
export const getAzureDevOpsBranchNameFromRefName = (name: string): string => name.substring(11);

const repoItemIdDelimiter = '*';

export const getAzureDevOpsRepoItemId = (repoUrl: string, branchName: string, path: string): string =>
    `${repoUrl}${repoItemIdDelimiter}${branchName}${repoItemIdDelimiter}${path}`;

export interface AzureDevOpsRepoItemProperties {
    repoUrl: string;
    branchName: string;
    path: string;
}

export const parseAzureDevOpsRepoItemId = (repoItemId: string): AzureDevOpsRepoItemProperties => {
    const parts = repoItemId.split(repoItemIdDelimiter);

    if (parts.length !== 3) {
        throw new ClientError('Azure DevOps repo item id is malformed', FailureOperation.ParseAzureDevOpsRepoItemId);
    }

    const [repoUrl, branchName, path] = parts;

    return { repoUrl, branchName, path };
};
