import { ApiVersion, AzureSearchParameter } from '../../../constants/azure';
import { Method } from '../../../constants/http';
import { Language } from '../../../constants/localization';
import { getAcceptLanguageHeaderValue } from '../../../language/languages';
import { DataResponse, FailureOperation } from '../../../models/common';
import { TenantContract } from '../../contracts/resource-manager';
import { paginatedResourceRequest } from '../paginated-resource-request';

export type ListTenantsResponse = DataResponse<TenantContract[]>;

export const listTenants = async (
    accessToken: string,
    language: Language,
    locale: string,
    activityId?: string
): Promise<ListTenantsResponse> => {
    const path = `/tenants?${AzureSearchParameter.ApiVersion}=${ApiVersion.Tenants}`;

    return await paginatedResourceRequest<TenantContract>(path, Method.GET, accessToken, {
        acceptLanguage: getAcceptLanguageHeaderValue(locale, language),
        activityId,
        operation: FailureOperation.ListTenants,
    });
};
