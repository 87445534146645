import { SearchParameter } from '../constants/app';
import { FeatureFlag, FeatureFlagName, isFeatureFlag, NameForFeatureFlag } from '../constants/features';
import { FeatureFlagSet } from '../models/features';
import { getIsFeatureEnabled } from '../redux/selector/features-selectors';
import { getStoreState } from '../redux/store';
import { isNotUndefinedOrWhiteSpace } from './string';
import {
    clearSearchParameterFromCurrentLocation,
    getSearchParameterFromCurrentLocation,
    setSearchParameterOnCurrentLocation,
} from './url';

export const applyFeaturesToCurrentLocation = (
    enabledFeatures: FeatureFlagSet,
    frozenFeatures = FeatureFlagSet()
): URL => {
    const newValue = serializeFeatureFlags(enabledFeatures, frozenFeatures);

    return isNotUndefinedOrWhiteSpace(newValue)
        ? setSearchParameterOnCurrentLocation(SearchParameter.Features, newValue)
        : clearSearchParameterFromCurrentLocation(SearchParameter.Features);
};

export const deserializeFeatureFlags = (value: string): FeatureFlagSet => {
    const featureFlags = filterToValidFeatureFlags(value.split(','));

    return enableFeatureFlagsInSet(FeatureFlagSet(), ...featureFlags.map(getNameForFeatureFlag));
};

export const disableFeatureFlagsInSet = (
    featureFlags: FeatureFlagSet,
    ...features: FeatureFlagName[]
): FeatureFlagSet => {
    const newFeatureFlags = FeatureFlagSet(featureFlags);

    features.forEach((feature) => {
        newFeatureFlags[feature] = false;
    });

    return newFeatureFlags;
};

export const enableFeatureFlagsInSet = (
    featureFlags: FeatureFlagSet,
    ...features: FeatureFlagName[]
): FeatureFlagSet => {
    const newFeatureFlags = FeatureFlagSet(featureFlags);

    features.forEach((feature) => {
        newFeatureFlags[feature] = true;
    });

    return newFeatureFlags;
};

export const filterToValidFeatureFlags = (values: string[]): FeatureFlag[] =>
    values.map((feature) => feature.trim().toLowerCase()).filter(isFeatureFlag);

export const getFeatureFlagFromName = (featureFlagName: FeatureFlagName): FeatureFlag => FeatureFlag[featureFlagName];

export const getFeaturesFromCurrentLocation = (): FeatureFlagSet => {
    const value = getSearchParameterFromCurrentLocation(SearchParameter.Features);

    return deserializeFeatureFlags(value ?? '');
};

export const getNameForFeatureFlag = (featureFlag: FeatureFlag): FeatureFlagName => NameForFeatureFlag[featureFlag];

export const isFeatureFlagEnabled = (featureFlag: FeatureFlagName): boolean =>
    getIsFeatureEnabled(getStoreState(), featureFlag);

export const serializeFeatureFlags = (enabledFeatures: FeatureFlagSet, frozenFeatures: FeatureFlagSet): string => {
    const features = Object.keys(enabledFeatures) as FeatureFlagName[];

    // Don't serialize frozen features, as they'll be controlled programmatically and not by the search string
    const featuresToSerialize = features.filter((key) => enabledFeatures[key] && !frozenFeatures[key]);

    return featuresToSerialize.map(getFeatureFlagFromName).join(',');
};

export const toggleFeatureFlagsInSet = (
    featureFlags: FeatureFlagSet,
    ...features: FeatureFlagName[]
): FeatureFlagSet => {
    const newFeatureFlags = FeatureFlagSet(featureFlags);

    features.forEach((feature) => {
        newFeatureFlags[feature] = !featureFlags[feature];
    });

    return newFeatureFlags;
};
