import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { Entity } from '../../models/common';
import {
    initializeSingleDevCenterHome,
    initializeSingleDevCenterHomeError,
    initializeSingleDevCenterHomeFailed,
    initializeSingleDevCenterHomeSuccess,
    loadDevBoxesForSingleDevCenter,
    loadDevBoxesForSingleDevCenterError,
    loadDevBoxesForSingleDevCenterFailed,
    loadDevBoxesForSingleDevCenterSuccess,
    loadProjectsForDevCenter,
    loadProjectsForDevCenterError,
    loadProjectsForDevCenterFailed,
    loadProjectsForDevCenterSuccess,
    loadResourcesForSingleDevCenter,
    loadResourcesForSingleDevCenterError,
    loadResourcesForSingleDevCenterFailed,
    loadResourcesForSingleDevCenterSuccess,
    setSingleDevCenterUri,
} from '../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { projectAdapter } from '../adapters/project-adapters';
import {
    SingleDevCenterSubApplicationDataStore,
    SingleDevCenterSubApplicationStatusStore,
    SingleDevCenterSubApplicationStore,
} from '../store/sub-application/single-dev-center-sub-application-store';
import { createStatusReducer } from './status-reducer';

export const singleDevCenterSubApplicationReducer = combineReducers<SingleDevCenterSubApplicationStore>({
    data: createReducer(SingleDevCenterSubApplicationDataStore(), (builder) => {
        builder.addCase(setSingleDevCenterUri, (store, action) => {
            store.devCenterUri = action.payload.devCenterUri;
        });

        builder.addCase(loadProjectsForDevCenterSuccess, (store, action) => {
            projectAdapter.addMany(
                store.projects,
                action.payload.result.map((project) => Entity(project.uri, project))
            );
        });
    }),

    status: combineReducers<SingleDevCenterSubApplicationStatusStore>({
        initializeSingleDevCenterHome: createStatusReducer({
            inProgress: initializeSingleDevCenterHome,
            error: initializeSingleDevCenterHomeError,
            failed: initializeSingleDevCenterHomeFailed,
            success: initializeSingleDevCenterHomeSuccess,
        }),

        loadResourcesForSingleDevCenter: createStatusReducer({
            inProgress: loadResourcesForSingleDevCenter,
            error: loadResourcesForSingleDevCenterError,
            failed: loadResourcesForSingleDevCenterFailed,
            success: loadResourcesForSingleDevCenterSuccess,
        }),

        loadProjectsForSingleDevCenter: createStatusReducer({
            inProgress: loadProjectsForDevCenter,
            error: loadProjectsForDevCenterError,
            failed: loadProjectsForDevCenterFailed,
            success: loadProjectsForDevCenterSuccess,
        }),

        loadDevBoxesForSingleDevCenter: createStatusReducer({
            inProgress: loadDevBoxesForSingleDevCenter,
            error: loadDevBoxesForSingleDevCenterError,
            failed: loadDevBoxesForSingleDevCenterFailed,
            success: loadDevBoxesForSingleDevCenterSuccess,
        }),
    }),
});
