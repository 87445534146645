import { FontSizes, FontWeights, Label, Stack, makeStyles } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RepoSourcedFileContent } from '../../../models/customization';
import Separator from '../../common/form/separator';
import { CPUMetadata, DiskSizeMetadata, MemoryMetadata } from '../../dev-box/dev-box-metadata/dev-box-metadata';
import { AddDevBoxFormProjectViewModel, CustomizationData, PoolViewModel } from '../models';
import { AddDevBoxSummaryCustomizationsSection } from './add-dev-box-summary-customizations-section';

interface AddDevBoxSummaryProps {
    isSubmitting: boolean;
    name: string;
    project?: AddDevBoxFormProjectViewModel;
    pool?: PoolViewModel;
    fileCustomizations?: CustomizationData;
    repoCustomizations?: CustomizationData;
}

/**
 * Style Section
 */

const useLabelStyles = makeStyles(() => ({
    root: {
        fontWeight: FontWeights.bold,
    },
}));

const useNameStyles = makeStyles(() => ({
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size16,
        paddingBottom: 6,
    },
}));

const useProjectStyles = makeStyles(() => ({
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size14,
    },
}));

const containerTokens = { childrenGap: 18 };

const itemTokens = { childrenGap: 2 };

const metadataTokens = { childrenGap: 8 };

export const AddDevBoxSummary: React.FC<AddDevBoxSummaryProps> = (props) => {
    const { project, pool, name, fileCustomizations, repoCustomizations } = props;

    const labelStyles = useLabelStyles();
    const nameStyles = useNameStyles();
    const projectStyles = useProjectStyles();

    const poolValues = React.useMemo(
        () => ({
            poolName: pool?.name,
            poolRegion: pool?.region,
        }),
        [pool]
    );

    const repoFiles: RepoSourcedFileContent[] = React.useMemo(
        () =>
            repoCustomizations?.contents
                ? repoCustomizations.contents.map((content, index) => {
                      return { name: content, content: repoCustomizations.repoFiles?.[index] ?? '' };
                  })
                : [],
        [repoCustomizations]
    );

    return (
        <Stack tokens={containerTokens}>
            <Stack>
                <Stack.Item styles={nameStyles}>{name}</Stack.Item>
                <Stack.Item styles={projectStyles}>{project?.name}</Stack.Item>
                <Stack.Item>
                    <Stack.Item>
                        <FormattedMessage
                            id="AddDevBoxSummary_PoolNameAndRegion_Title"
                            defaultMessage="{poolName} ({poolRegion})"
                            description="Text for the add dev box summary pool name and region. Do not localize {poolName} or {poolRegion}"
                            values={poolValues}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal tokens={metadataTokens}>
                            <Stack.Item>
                                <CPUMetadata cpuCount={pool?.cpuCount} />
                            </Stack.Item>
                            <Stack.Item>
                                <MemoryMetadata memoryInGb={pool?.memoryInGb} />
                            </Stack.Item>
                            <Stack.Item>
                                <DiskSizeMetadata diskSizeInGb={pool?.diskSizeInGb} />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack.Item>
            </Stack>
            <Separator />
            {(fileCustomizations?.files || repoFiles.length > 0) && (
                <Stack.Item>
                    <Stack tokens={itemTokens}>
                        <Stack.Item>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_Customizations_Title"
                                    defaultMessage="Customizations and Tasks"
                                    description="Text for the add dev box summary customizations and tasks title"
                                />
                            </Label>
                        </Stack.Item>
                        <Stack.Item>
                            <AddDevBoxSummaryCustomizationsSection
                                customizationFiles={fileCustomizations?.files}
                                repoFiles={repoFiles}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};

export default AddDevBoxSummary;
