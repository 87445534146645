import { Dialog, DialogType, IDialogContentProps, IModalProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { Metric, Property, Severity } from '../../../constants/telemetry';
import { useActionCreator } from '../../../hooks/action-creator';
import { useConnectViaAppDialogContext } from '../../../hooks/context/dialogs';
import { setPartialUserSettings } from '../../../redux/actions/user-settings/user-settings-action-creator';
import { SetPartialUserSettingsPayload } from '../../../redux/actions/user-settings/user-settings-actions';
import { RemoteAppOption, UserSettings } from '../../../types/user-settings';
import { openInSameWindow } from '../../../utilities/browser';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackMetric, trackTrace } from '../../../utilities/telemetry/channel';
import { tryAddMultiMonitorAndSourceParameters } from '../../../utilities/url';
import { UserSettingsFormData } from '../models';
import { getUserSettingsSetFromFormData } from '../selectors';
import { DialogFooterButtons } from './connect-via-app-dialog-footer';
import { ConnectViaAppFormFieldGroup } from './connect-via-app-form-field-group';
import { OpenConnectViaAppDialogProperties } from './contexts';

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for "close" button in the "Initialize connect via app dialog"',
    },
    cancelButton: {
        id: 'InitializeConnectViaAppDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the "cancel" button in the "Initialize connect via app dialog"',
    },
    cancelButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "cancel" button in the "Initialize connect via app dialog"',
    },
    continueButton: {
        id: 'InitializeConnectViaAppDialog_ContinueButton_Text',
        defaultMessage: 'Continue',
        description: 'Text for the "continue" button in the "Initialize connect via app dialog"',
    },
    continueButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialog_ContinueButton_AriaLabel',
        defaultMessage: 'Continue',
        description: 'Aria label for the "continue" button in the "Initialize connect via app dialog"',
    },
    header: {
        id: 'InitializeConnectViaAppDialog_Header_Text',
        defaultMessage: 'Connect via app',
        description: 'Title of the "Initialize connect via app dialog"',
    },
});

/**
 * Styles
 */

const useDialogStyles = makeStyles({
    inner: {
        padding: '16px 24px 24px 24px',
    },
});

/**
 * END Styles
 */

const modalProps: IModalProps = {
    isBlocking: true,
};

type ConnectViaAppDialogProps = {
    hidden?: boolean;
    onDismiss: () => void;
    onSetPartialUserSettings: (payload: SetPartialUserSettingsPayload) => void;
    properties: OpenConnectViaAppDialogProperties;
};

const conditionConnectionUri = (
    connectionStrings: OpenConnectViaAppDialogProperties,
    userSettings: UserSettings
): string => {
    // Incoming from devbox back-end passed through the dialog context
    const { remoteDesktopUrl, cloudPcConnectionUrl } = connectionStrings;

    // Use the settings we just saved to determine the connection string
    const { preferredRemoteApp, useMultiMonitor } = userSettings;

    const useWindowsApp = preferredRemoteApp === RemoteAppOption.useWindowsApp ?? false;
    const remoteDesktopConnection = useWindowsApp ? cloudPcConnectionUrl : remoteDesktopUrl;

    if (isUndefinedOrWhiteSpace(remoteDesktopConnection)) {
        return '';
    }

    return tryAddMultiMonitorAndSourceParameters(remoteDesktopConnection, useMultiMonitor) ?? '';
};

const ConnectViaAppDialogComponent: React.FC<ConnectViaAppDialogProps> = (props: ConnectViaAppDialogProps) => {
    const { hidden, onDismiss, onSetPartialUserSettings, properties } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    //Style hooks
    const dialogStyles = useDialogStyles();

    const onSubmitClicked = React.useCallback(
        (data: UserSettingsFormData) => {
            const userSettings: UserSettings = getUserSettingsSetFromFormData(data);
            const { preferredRemoteApp, useMultiMonitor } = userSettings;
            // Store the users settings in  Browser Storage
            onSetPartialUserSettings({ preferredRemoteApp, useMultiMonitor });

            const connectionUri = conditionConnectionUri(properties, userSettings);
            if (connectionUri) {
                openInSameWindow(connectionUri);
                trackMetric(Metric.DirectLaunchStarted, 1, {
                    properties: {
                        [Property.PreferredRemoteApp]: preferredRemoteApp,
                        [Property.MultiMonitor]: useMultiMonitor,
                    },
                });
            } else {
                // If the connection URI is not defined, log a warning and close the dialog
                trackTrace('Connection URI is not defined. Unable to open connection', {
                    severity: Severity.Warning,
                });
            }

            onDismiss();
        },
        [properties, onSetPartialUserSettings]
    );

    const dialogContentProps = React.useMemo<IDialogContentProps>(
        () => ({
            title: formatMessage(messages.header),
            type: DialogType.normal,
            styles: dialogStyles,
        }),
        [dialogStyles, formatMessage]
    );

    return (
        <Form<UserSettingsFormData> onSubmit={onSubmitClicked}>
            {(formProps) => {
                const { form } = formProps;
                const { submit } = form;
                return (
                    <Dialog
                        dialogContentProps={dialogContentProps}
                        hidden={hidden}
                        modalProps={modalProps}
                        onDismiss={onDismiss}
                        maxWidth={560}
                    >
                        <ConnectViaAppFormFieldGroup />

                        <DialogFooterButtons onContinueClicked={submit} onCancelClicked={onDismiss} />
                    </Dialog>
                );
            }}
        </Form>
    );
};

export const ConnectViaAppDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useConnectViaAppDialogContext();

    const onSetPartialUserSettings = useActionCreator(setPartialUserSettings);

    if (!isOpen) {
        return <></>;
    }

    return (
        <ConnectViaAppDialogComponent
            onDismiss={closeSurface}
            onSetPartialUserSettings={onSetPartialUserSettings}
            hidden={!isOpen}
            properties={properties}
        />
    );
};

export default ConnectViaAppDialogContextWrapper;
