export const tryAndConfirm =
    <TFunc extends (...args: Parameters<TFunc>) => void>(fn: TFunc): ((...args: Parameters<TFunc>) => boolean) =>
    (...args) => {
        try {
            fn(...args);
            return true;
        } catch {
            return false;
        }
    };
