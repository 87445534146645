import { combineReducers, createReducer } from '@reduxjs/toolkit';
import { FeatureFlagSet } from '../../models/features';
import {
    clearFeatures,
    disableFeatures,
    enableFeatures,
    freezeFeatures,
    initializeFeatures,
    initializeFeaturesError,
    initializeFeaturesSuccess,
    setFeatures,
    toggleFeatures,
} from '../actions/features/features-action-creators';
import { FeaturesDataStore, FeaturesStore } from '../store/features-store';
import { InitializationStatusStore } from '../store/initialization-status-store';
import { getPayload } from '../utilities/payload-action';
import { createInitializationStatusReducer } from './initialization-status-reducer';

export const featuresReducer = combineReducers<FeaturesStore>({
    data: createReducer(FeaturesDataStore(), (builder) => {
        builder.addCase(clearFeatures, (store) => {
            store.enabledFeatures = FeatureFlagSet();
            store.frozenFeatures = FeatureFlagSet();
        });

        builder.addCase(disableFeatures, (store, action) => {
            const { features } = getPayload(action);

            features.forEach((feature) => {
                store.enabledFeatures[feature] = false;
            });
        });

        builder.addCase(enableFeatures, (store, action) => {
            const { features } = getPayload(action);

            features.forEach((feature) => {
                store.enabledFeatures[feature] = true;
            });
        });

        builder.addCase(freezeFeatures, (store, action) => {
            const { features } = getPayload(action);

            features.forEach((feature) => {
                store.frozenFeatures[feature] = true;
            });
        });

        builder.addCase(initializeFeaturesSuccess, (store, action) => {
            const { result } = getPayload(action);
            store.enabledFeatures = result;
        });

        builder.addCase(setFeatures, (store, action) => {
            const { features } = getPayload(action);

            store.enabledFeatures = {
                ...store.enabledFeatures,
                ...features,
            };
        });

        builder.addCase(toggleFeatures, (store, action) => {
            const { features } = getPayload(action);

            features.forEach((feature) => {
                store.enabledFeatures[feature] = !store.enabledFeatures[feature];
            });
        });
    }),

    status: combineReducers<InitializationStatusStore>({
        initialization: createInitializationStatusReducer({
            initializing: initializeFeatures,
            error: initializeFeaturesError,
            success: initializeFeaturesSuccess,
        }),
    }),
});
