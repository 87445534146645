import { IIconProps, MessageBar } from '@fluentui/react';
import * as React from 'react';
import { PropsWithChildren } from '../../types/props-with-children';
import ErrorBannerActions, { ErrorBannerActionProps } from './error-banner-actions';
import { ResourceCardErrorSeverity } from './models';
import { getMessageBarTypeForSeverity } from './selectors';

type ErrorBannerProps = PropsWithChildren<{
    onRetry?: () => void;
    onSeeDetails?: () => void;
    retryable?: boolean;
    severity: ResourceCardErrorSeverity;
    additionalActions?: ErrorBannerActionProps[];
    iconProps?: IIconProps;
    onDismiss?: () => void;
}>;

export const ErrorBanner: React.FC<ErrorBannerProps> = React.memo((props: ErrorBannerProps) => {
    const { children, onRetry, onSeeDetails, retryable, severity, additionalActions, iconProps, onDismiss } = props;

    // Memoized data
    const messageBarType = React.useMemo(() => getMessageBarTypeForSeverity(severity), [severity]);

    const [isBannerOpen, setIsBannerOpen] = React.useState(true);

    const onClose = React.useCallback(() => {
        if (onDismiss) {
            onDismiss();
        }

        setIsBannerOpen(false);
    }, [onDismiss]);

    if (!isBannerOpen) {
        return <></>;
    }

    return (
        <MessageBar
            actions={
                <ErrorBannerActions
                    onRetry={onRetry}
                    onSeeDetails={onSeeDetails}
                    retryable={retryable}
                    additionalActions={additionalActions}
                />
            }
            delayedRender={false}
            messageBarType={messageBarType}
            messageBarIconProps={iconProps}
            onDismiss={onDismiss ? onClose : undefined}
        >
            {children}
        </MessageBar>
    );
});

export default ErrorBanner;
