import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { getAccessTokenListenerSaga } from '../../../redux/sagas/identity/get-access-token';
import { initializeAuthenticationStateListenerSaga } from '../../../redux/sagas/identity/initialize';
import { signInListenerSaga } from '../../../redux/sagas/identity/sign-in';
import { signOutListenerSaga } from '../../../redux/sagas/identity/sign-out';
import { tryExpireSignInListenerSaga } from './try-expire-sign-in';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(getAccessTokenListenerSaga),
        fork(initializeAuthenticationStateListenerSaga),
        fork(signInListenerSaga),
        fork(signOutListenerSaga),
        fork(tryExpireSignInListenerSaga),
    ]);
}
