import { UnionMap, UnionValueMap } from '../../../types/union-map';
import { MockScenarioConfiguration } from '../../types';
import getAccessTokenInvalidGrant from './get-access-token-invalid-grant.json';

export type IdentityScenarioName = 'GetAccessTokenInvalidGrant';

export const IdentityScenarioName: UnionMap<IdentityScenarioName> = {
    GetAccessTokenInvalidGrant: 'GetAccessTokenInvalidGrant',
};

export const IdentityScenarios: UnionValueMap<IdentityScenarioName, MockScenarioConfiguration> = {
    GetAccessTokenInvalidGrant: getAccessTokenInvalidGrant as MockScenarioConfiguration,
};
