import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ClientError, FailureOperation } from '../../../models/common';
import { FeatureFlagSet } from '../../../models/features';
import {
    applyFeaturesToCurrentLocation,
    enableFeatureFlagsInSet,
    getFeaturesFromCurrentLocation,
} from '../../../utilities/features';
import { trackException } from '../../../utilities/telemetry/channel';
import { replaceCurrentLocation } from '../../../utilities/url';
import { enableFeatures } from '../../actions/features/features-action-creators';
import { EnableFeaturesAction } from '../../actions/features/features-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { takeEvery } from '../../effects/take';
import { getFrozenFeatures } from '../../selector/features-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* enableFeaturesSaga(action: EnableFeaturesAction): SagaIterator {
    const { features } = getPayload(action);

    try {
        const featureFlags: FeatureFlagSet = yield call(getFeaturesFromCurrentLocation);
        const newFeatureFlags: FeatureFlagSet = yield call(enableFeatureFlagsInSet, featureFlags, ...features);
        const frozenFeatures: FeatureFlagSet = yield select(getFrozenFeatures);
        const newUrl: URL = yield call(applyFeaturesToCurrentLocation, newFeatureFlags, frozenFeatures);

        yield call(replaceCurrentLocation, newUrl);
    } catch (err) {
        // No error actions for updating features - we just log the exception
        const error: ClientError = yield createSagaError(err, FailureOperation.EnableFeatures);
        yield call(trackException, error);
    }
}

export function* enableFeaturesListenerSaga(): SagaIterator {
    yield takeEvery(enableFeatures, enableFeaturesSaga);
}
