import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { getTokensFromEnvironmentTypeDataPlaneUri } from '../../../ids/project-environment-type';
import { DataResponse, FailureOperation } from '../../../models/common';
import { EnvironmentTypeAbilitiesContract, EnvironmentTypeContract } from '../../contracts/project-environment-type';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type ListEnvironmentTypesResponse = DataResponse<EnvironmentTypeContract[]>;

export type GetEnvironmentTypeAbilitiesResponse = DataResponse<EnvironmentTypeAbilitiesContract>;

export const listEnvironmentTypes = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListEnvironmentTypesResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListProjectEnvironmentTypes,

        whenUsingBetaClient: (client) =>
            client.environments.listEnvironmentTypes(projectName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.environments.listEnvironmentTypes(projectName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const getEnvironmentTypeAbilities = async (
    id: string,
    user: string,
    accessToken: string,
    activityId?: string
): Promise<GetEnvironmentTypeAbilitiesResponse> => {
    const { devCenter, projectName, environmentTypeName } = getTokensFromEnvironmentTypeDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetProjectEnvironmentTypeAbilities,

        whenUsingBetaClient: (client) =>
            client.environments.getEnvironmentTypeAbilities(
                projectName,
                environmentTypeName,
                user,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.getEnvironmentTypeAbilities(
                projectName,
                environmentTypeName,
                user,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};
