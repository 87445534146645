import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
    ListSchedulesByProjectResponse,
    listSchedulesByProject as listSchedulesByProjectApiCall,
} from '../../../data/services/data-plane-api/schedule';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    listSchedulesByProject,
    listSchedulesByProjectError,
    listSchedulesByProjectFailed,
    listSchedulesByProjectSuccess,
} from '../../actions/schedule/schedule-action-creators';
import { ListSchedulesByProjectAction } from '../../actions/schedule/schedule-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listSchedulesByProjectSaga(action: ListSchedulesByProjectAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListSchedulesByProjectResponse = yield call(
            listSchedulesByProjectApiCall,
            id,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(listSchedulesByProjectFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data: schedules } = response;

        yield put(listSchedulesByProjectSuccess({ id, result: schedules }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListSchedulesByProject);
        yield put(listSchedulesByProjectError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listSchedulesByProjectListenerSaga(): SagaIterator {
    yield takeEvery(listSchedulesByProject, listSchedulesByProjectSaga);
}
