import { SagaIterator } from '@redux-saga/types';
import { all, call, put } from 'redux-saga/effects';
import {
    ListCustomizationGroupsResponse,
    listCustomizationGroups,
} from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { CustomizationGroup, CustomizationGroupStatus, CustomizationTask } from '../../../models/customization';
import { Action } from '../../actions/core-actions';
import {
    createCustomizationGroupAccepted,
    getCustomizationTaskLog,
    listCustomizationGroups as listCustomizationGroupsActionCreator,
    listCustomizationGroupsError,
    listCustomizationGroupsFailed,
    listCustomizationGroupsSuccess,
} from '../../actions/customization/customization-action-creators';
import { ListCustomizationGroupsAction } from '../../actions/customization/customization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

const getPendingOperationActions = (resource: CustomizationGroup, activityId?: string): Action[] => {
    const actions = [];
    const meta = activityId ? { activityId } : undefined;
    const { status, uri } = resource;

    switch (status) {
        case CustomizationGroupStatus.NotStarted:
        case CustomizationGroupStatus.Running:
            actions.push(createCustomizationGroupAccepted({ id: uri, result: resource }, meta));
            break;
        default:
            break;
    }

    return actions;
};

export function* listCustomizationGroupsSaga(action: ListCustomizationGroupsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListCustomizationGroupsResponse = yield call(
            listCustomizationGroups,
            id,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(listCustomizationGroupsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data: customizationGroups } = response;

        yield all(
            customizationGroups.flatMap((customizationGroup) =>
                getPendingOperationActions(customizationGroup, activityId).map((action) => put(action))
            )
        );

        const tasksList: CustomizationTask[] = customizationGroups.flatMap((group) => group.tasks ?? []);

        // Loading up customization task logs
        if (tasksList.length > 0) {
            yield all(
                tasksList.map((task) =>
                    put(
                        getCustomizationTaskLog({
                            id: task.logUri,
                        })
                    )
                )
            );
        }

        yield put(listCustomizationGroupsSuccess({ id, result: customizationGroups }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListCustomizationGroups);
        yield put(listCustomizationGroupsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listCustomizationGroupsListenerSaga(): SagaIterator {
    yield takeEvery(listCustomizationGroupsActionCreator, listCustomizationGroupsSaga);
}
