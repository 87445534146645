import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { getAzureDevOpsRepoByCloneUriListenerSaga } from './get-azure-dev-ops-repo-by-clone-uri';
import { getAzureDevOpsRepoContentsListenerSaga } from './get-azure-dev-ops-repo-contents';
import { getAzureDevOpsRepoItemListenerSaga } from './get-azure-dev-ops-repo-item';
import { listAzureDevOpsBranchesListenerSaga } from './list-azure-dev-ops-branches';
import { loadAzureDevOpsRepoResourcesListenerSaga } from './load-azure-dev-ops-repo-resources';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(getAzureDevOpsRepoByCloneUriListenerSaga),
        fork(listAzureDevOpsBranchesListenerSaga),
        fork(loadAzureDevOpsRepoResourcesListenerSaga),
        fork(getAzureDevOpsRepoContentsListenerSaga),
        fork(getAzureDevOpsRepoItemListenerSaga),
    ]);
}
