import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { createCustomizationGroupDataPlaneUri } from './customization-group';
import { isValidDataPlaneUri, parseDataPlanePath } from './data-plane';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const customizationTaskLogPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.User}/${StringifiedPattern.Guid}/${DataPlaneResourcePathPrefix.DevBox}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.CustomizationGroup}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.CustomizationTaskLog}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createCustomizationTaskLogDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<
        'devBoxName' | 'devCenter' | 'projectName' | 'customizationGroupName' | 'user' | 'customizationTaskId'
    >
): string => {
    const { devBoxName, devCenter, projectName, customizationGroupName, user, customizationTaskId } = tokens;

    if (isUndefinedOrWhiteSpace(devBoxName)) {
        throw new ClientError('Dev box name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(customizationGroupName)) {
        throw new ClientError('Customization group name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(user)) {
        throw new ClientError('User token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(customizationTaskId)) {
        throw new ClientError('Customization task id cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.User,
        user.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBox,
        devBoxName.toLowerCase(),
        DataPlaneResourcePathPrefix.CustomizationGroup,
        customizationGroupName.toLowerCase(),
        DataPlaneResourcePathPrefix.CustomizationTaskLog,
        customizationTaskId.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromCustomizationTaskLogDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<
    'devBoxName' | 'devCenter' | 'projectName' | 'customizationGroupName' | 'user' | 'customizationTaskId'
> => {
    if (!isValidCustomizationTaskLogDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a customization task log');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , user, , devBoxName, , customizationGroupName, , customizationTaskId] =
        parseDataPlanePath(pathname);

    return {
        devBoxName,
        devCenter: origin,
        projectName,
        customizationGroupName,
        user,
        customizationTaskId,
    };
};

export const getCustomizationGroupUriFromTaskLogDataPlaneUri = (logUri: string | undefined): string => {
    if (!logUri) {
        throw new ClientError('URI cannot be undefined');
    }

    if (!isValidCustomizationTaskLogDataPlaneUri(logUri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a customization task log');
    }

    const { devBoxName, devCenter, projectName, user, customizationGroupName } =
        getTokensFromCustomizationTaskLogDataPlaneUri(logUri);

    return createCustomizationGroupDataPlaneUri({ devBoxName, devCenter, projectName, customizationGroupName, user });
};

export const isValidCustomizationTaskLogDataPlaneUri = (uri: string): boolean =>
    isValidDataPlaneUri(uri, customizationTaskLogPathPattern);

export const tryCreateCustomizationGroupDataPlaneUri = tryOrDefault(createCustomizationTaskLogDataPlaneUri);
export const tryGetTokensFromCustomizationGroupDataPlaneUri = tryOrDefault(
    getTokensFromCustomizationTaskLogDataPlaneUri
);
