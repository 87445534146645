import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { getDayOfTheWeek, getTimeStringFromDate } from '../../../utilities/time';
import { DevBoxActionState } from '../models';
import { DevBoxCardSuccessBannerMessageKind } from './models';

// TODO Task 1878723: Break this file out into sub components, "RepairSuccessMessages" and "DelaySkipSuccessMessages" to avoid a long convulated file
interface DevBoxBannerSuccessMessageProps {
    kind: DevBoxCardSuccessBannerMessageKind;
    locale: string;
    devBoxActionState: DevBoxActionState;
}

export const DevBoxBannerSuccessMessage: React.FC<DevBoxBannerSuccessMessageProps> = (
    props: DevBoxBannerSuccessMessageProps
) => {
    const { kind, locale, devBoxActionState } = props;
    const { nextScheduledStopTime } = devBoxActionState;

    const updatedScheduleTime = React.useMemo(
        () => ({ updatedScheduleTime: getTimeStringFromDate(nextScheduledStopTime, locale) }),
        [nextScheduledStopTime, locale]
    );
    const updatedScheduleTimeAndDayOfTheWeek = React.useMemo(
        () => ({
            updatedScheduleTime: getTimeStringFromDate(nextScheduledStopTime, locale),
            dayOfTheWeek: nextScheduledStopTime ? getDayOfTheWeek(nextScheduledStopTime) : undefined,
        }),
        [nextScheduledStopTime]
    );

    switch (kind) {
        case DevBoxCardSuccessBannerMessageKind.DelayStateNextDaySuccessMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_DelayStateSuccess_NextDay_Text"
                    defaultMessage="Shutdown delayed until {updatedScheduleTime} tomorrow."
                    description="Success notification for delay operation and is tomorrow. DO NOT localize {updatedScheduleTime}."
                    values={updatedScheduleTime}
                />
            );
        case DevBoxCardSuccessBannerMessageKind.DelayStateDayAfterNextSuccessMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_DelayStateSuccess_DayAfterNext_Text"
                    defaultMessage="Shutdown delayed until {updatedScheduleTime} on {dayOfTheWeek}."
                    description="Success notification for delay operation and is day after next. DO NOT localize {updatedScheduleTime} and {dayOfTheWeek}."
                    values={updatedScheduleTimeAndDayOfTheWeek}
                />
            );
        case DevBoxCardSuccessBannerMessageKind.DelayStateSuccessMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_DelayStateSuccess_Text"
                    defaultMessage="Shutdown delayed until {updatedScheduleTime}"
                    description="Success notification for delay operation on current day. DO NOT localize {updatedScheduleTime}."
                    values={updatedScheduleTime}
                />
            );
        case DevBoxCardSuccessBannerMessageKind.SkipStateSuccessMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_SkipStateSuccess_Text"
                    defaultMessage="Your scheduled shutdown has been skipped successfully."
                    description="Success notification for skip operation"
                />
            );
        case DevBoxCardSuccessBannerMessageKind.IssuesFixedMessage:
            return (
                <FormattedMessage
                    id="DevBoxCard_RepairOperationIssueResolved_Text"
                    defaultMessage="An issue was resolved."
                    description="Success notification that an issue was resolved for repair operation"
                />
            );
        default:
            return <></>;
    }
};

export default DevBoxBannerSuccessMessage;
