import { SerializableMap } from '../../types/serializable-map';
import { CapitalizedUnionMap } from '../../types/union-map';
import { EnvironmentDefinitionParameterValue } from '../environment/parameters-field/models';

export interface RedeployEnvironmentFormState {
    parameterValues: SerializableMap<EnvironmentDefinitionParameterValue>;
}

export const RedeployEnvironmentFormField: CapitalizedUnionMap<keyof RedeployEnvironmentFormState> = {
    ParameterValues: 'parameterValues',
};
