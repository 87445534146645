import { Action as ReduxAction } from 'redux';
import { ClientError, FailureResponse } from '../../models/common';
import { IsVoid } from '../../types/is-void';
import { Suffix } from '../../types/suffix';

/**
 * Action types
 */

type CommonActionNameSuffix = 'Accepted' | 'Complete' | 'Error' | 'Failed' | 'Redirecting' | 'Skipped' | 'Success';

type CommonActionTypeSuffix =
    | '_ACCEPTED'
    | '_COMPLETE'
    | '_ERROR'
    | '_FAILED'
    | '_REDIRECTING'
    | '_SKIPPED'
    | '_SUCCESS';

/**
 * Helper type which helps generate common names for Redux actions.
 */
export type ActionName<
    TName extends string,
    TSuffix extends CommonActionNameSuffix,
    TIncludeBase extends boolean = true
> = Suffix<TName, TSuffix, TIncludeBase>;

/**
 * Helper type which helps generate common action types for Redux actions.
 */
export type ActionType<
    TType extends string,
    TSuffix extends CommonActionTypeSuffix,
    TIncludeBase extends boolean = true
> = Suffix<TType, TSuffix, TIncludeBase>;

/**
 * Actions
 */

/**
 * A Redux action implementing the standards outlined in this doc: https://dev.azure.com/devdiv/OnlineServices/_wiki/wikis/OnlineServices.wiki/35235/Proposal-for-unified-approach-to-our-Redux-logic
 *
 * @param TPayload `payload` type. If void, `payload` is not included.
 * @param TAdditionalMeta additional properties to add to `meta`. If void, the defaults specified by {@link Metadata} are used.
 * @param TAwaited type of result sent back by the action when awaited. If void, `async` is not included.
 * @param TType type of `type`. Defaults to string.
 */
export type Action<TPayload = void, TAdditionalMeta = void, TAwaited = void, TType extends string = string> = {
    type: TType;
} & IsVoid<
    TAwaited,
    IsVoid<TPayload, { meta?: Metadata<TAdditionalMeta> }, { meta?: Metadata<TAdditionalMeta>; payload: TPayload }>,
    IsVoid<
        TPayload,
        { async: AsyncContent<TAwaited>; meta?: Metadata<TAdditionalMeta> },
        { async: AsyncContent<TAwaited>; meta?: Metadata<TAdditionalMeta>; payload: TPayload }
    >
>;

/**
 * Properties guaranteed to be present on an asynchronous action.
 *
 * @param TAwaited type of result sent back by the action when awaited.
 * @param TType type of `type`. Defaults to string.
 */
export interface AsyncAction<TAwaited, TType extends string = string> {
    async: AsyncContent<TAwaited>;
    type: TType;
}

/**
 * Properties guaranteed to be present on an action that may have metadata.
 *
 * @param TMeta `meta` type.
 * @param TType type of `type`. Defaults to string.
 */
export interface MetadataAction<TMeta, TType extends string = string> {
    meta?: TMeta;
    type: TType;
}

/**
 * Properties guaranteed to be present on an action with a payload
 *
 * @param TPayload `payload` type.
 * @param TType type of `type`. Defaults to string.
 */
export interface PayloadAction<TPayload, TType extends string = string> {
    payload: TPayload;
    type: TType;
}

/**
 * Async content
 */

/**
 * Data included on a Redux action which allows the action to be awaited, resolved, and rejected similarly to a promise.
 * This enables a pattern where sagas can dispatch an action, wait for that action's saga to complete, then resume with
 * the result from the other saga.
 */
export interface AsyncContent<T> {
    /**
     * Promise.
     */
    promise: Promise<T>;

    /**
     * Reject function for promise.
     * @param error Error
     */
    reject: (error: ClientError) => void;

    /**
     * Resolve function for promise.
     * @param result Result
     * @returns
     */
    resolve: (result: T) => void;
}

/**
 * Utility type for unpacking the type of the result of {@link AsyncContent}.
 */
export type AsyncResult<T> = T extends AsyncContent<infer TResult> ? TResult : never;

/**
 * Metadata
 */

/**
 * Core metadata for all Redux actions. By default, this includes everything in {@link TrackableMetadata}. If additional
 * metadata is given, the type is extended with it.
 *
 * @param TAdditionalMeta additional metadata to include. Defaults to void.
 */
export type Metadata<TAdditionalMeta = void> = Partial<
    IsVoid<TAdditionalMeta, TrackableMetadata, TAdditionalMeta & TrackableMetadata>
>;

/**
 * Metadata allowing actions to bear a telemetry correlation ID. This can be viewed in console logs and also used in
 * Application Insights to correlate Redux actions with dependencies.
 */
export interface TrackableMetadata {
    /**
     * Telemetry correlation ID.
     */
    activityId: string;
}

/**
 * Payloads
 */

/**
 * Payload containing a {@link ClientError}. Most frequently used for actions dispatched when a saga didn't complete due
 * to an unexpected error.
 */
export interface ErrorPayload {
    /**
     * Error.
     */
    error: ClientError;
}

/**
 * Payload containing a {@link FailureResponse}. Most frequently used for actions dispatched when a saga completed with
 * a result indicating a failure.
 */
export interface FailedPayload {
    /**
     * Failure.
     */
    failure: FailureResponse;
}

/**
 * Payload that is either {@link ErrorPayload} or {@link FailedPayload}.
 */
export type ErrorOrFailedPayload = ErrorPayload | FailedPayload;

/**
 * Payload that is either a single instance of `TPayload` or many actions containing payloads of `TPayload`.
 *
 * @param TPayload type of payload.
 * @param TAdditionalMeta additional properties available in `meta` of a grouped payload. If void, the defaults
 * specified by {@link Metadata} are used.
 * @param TType type of `type`. Defaults to string.
 */
export type GroupablePayload<TPayload, TAdditionalMeta = void, TType extends string = string> =
    | TPayload
    | GroupedPayload<TPayload, TAdditionalMeta, TType>;

/**
 * Payload that is many actions containing payloads of `TPayload`.
 *
 * @param TPayload type of payload.
 * @param TAdditionalMeta additional properties available in `meta` of a grouped payload. If void, the defaults
 * specified by {@link Metadata} are used.
 * @param TType type of `type`. Defaults to string.
 */
export interface GroupedPayload<TPayload, TAdditionalMeta = void, TType extends string = string> {
    /**
     * Actions in the group.
     */
    actions: Action<TPayload, TAdditionalMeta, void, TType>[];
}

/**
 * Payload containing an identifier.
 */
export type IndexedPayload<TIdName extends string = 'id', TIdValue extends string = string> = {
    [id in TIdName]: TIdValue;
};

/**
 * Payload containing a result of type `TResult`.
 *
 * @param TResult `result` type.
 */
export interface ResultPayload<TResult> {
    /**
     * Result.
     */
    result: TResult;
}

/**
 * Payload which may contain a result of type `TResult`.
 *
 * @param TResult `result` type.
 */
export type OptionalResultPayload<TResult> = Partial<ResultPayload<TResult>>;

/**
 * Utility type for unpacking the `TPayload` type given to {@link GroupablePayload}.
 */
export type UngroupedPayload<TGroupablePayload> = TGroupablePayload extends GroupablePayload<infer TPayload>
    ? TPayload
    : never;

/**
 * Payload allowing configuration of standard polling options.
 */
export interface PollPayload {
    /**
     * Time in milliseconds between polls.
     */
    intervalMs?: number;

    /**
     * Time in milliseconds to wait before issuing the first poll after action dispatch.
     */
    initialDelayMs?: number;
}

/**
 * Initializers
 */

export const AsyncContent = <T>(): AsyncContent<T> => {
    let reject: (error: ClientError) => void;
    let resolve: (result: T) => void;

    const promise = new Promise<T>((res: (result: T) => void, rej: (error: ClientError) => void) => {
        reject = rej;
        resolve = res;
    });

    // Justification: Promise's constructor pigeon-holes us into this awkward declaration format
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { promise, reject: reject!, resolve: resolve! };
};

/**
 * Type guards
 */

export const isErrorPayload = (payload: ErrorOrFailedPayload): payload is ErrorPayload =>
    (payload as ErrorPayload).error !== undefined;

export const isFailedPayload = (payload: ErrorOrFailedPayload): payload is FailedPayload =>
    (payload as FailedPayload).failure !== undefined;

export const isGroupedPayload = <TPayload, TAdditionalMeta = void, TType extends string = string>(
    payload: GroupablePayload<TPayload, TAdditionalMeta, TType>
): payload is GroupedPayload<TPayload, TAdditionalMeta, TType> =>
    (payload as GroupedPayload<TPayload, TAdditionalMeta, TType>).actions !== undefined;

export const isPayloadAction = <TPayload>(action: ReduxAction): action is PayloadAction<TPayload> =>
    (action as PayloadAction<TPayload>).payload !== undefined;
