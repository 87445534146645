import { FontSizes, Text, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { FieldMetaState } from 'react-final-form';
import { getSemanticColor } from '../../../utilities/styles';

const useErrorTextStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'inlineErrorMessageText'),
        fontSize: FontSizes.size12,
    },
}));

type MetaErrorProperties = Pick<FieldMetaState<unknown>, 'pristine' | 'valid' | 'error'>;

export interface FieldErrorProps {
    meta: MetaErrorProperties;
}

export const FieldError: React.FC<FieldErrorProps> = (props: FieldErrorProps) => {
    const { meta } = props;
    const { pristine, valid, error } = meta;

    const canShowError = !pristine;

    const errorTextStyles = useErrorTextStyles();

    if (canShowError && !valid) {
        return <Text styles={errorTextStyles}>{error}</Text>;
    }

    return <></>;
};
