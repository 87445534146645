import { DefaultLanguage, DefaultLocale, DefaultMarket, Language } from '../../constants/localization';
import { LocaleMessageData } from '../../language/languages';
import { createInitializer } from '../../utilities/initializer';
import { LocalizationDataState, LocalizationSettingState } from './localization-state';

export interface LocalizationStore {
    language: Language;
    locale: string;
    localizationDataState: LocalizationDataState;
    localizationSettingState: LocalizationSettingState;
    market: string;
    messages: LocaleMessageData | undefined;
}

export const LocalizationStore = createInitializer<LocalizationStore>({
    language: DefaultLanguage,
    locale: DefaultLocale,
    localizationDataState: LocalizationDataState.NotConfigured,
    localizationSettingState: LocalizationSettingState.NotSet,
    market: DefaultMarket,
    messages: undefined,
});
