import { createSelector } from '@reduxjs/toolkit';
import { getDevBoxes, getIsDevBoxInUsableProvisioningState } from '../../selector/dev-box-selectors';

/**
 * Application state selectors
 */

export const getIdsForDevBoxesRequiringRemoteConnections = createSelector([getDevBoxes], (devBoxes) =>
    devBoxes.filter(getIsDevBoxInUsableProvisioningState).map((devBox) => devBox.uri)
);
