import { hasClientErrorLikeMessage } from '../models/common';
import { isString } from './string';

export const getMessageFromThrown = (error: unknown): string => {
    // If some kind of error object: grab its message
    if (hasClientErrorLikeMessage(error)) {
        return error.message;
    }

    // If string: the argument is the message
    if (isString(error)) {
        return error;
    }

    // Otherwise, empty string is our well-known lingua franca default
    return '';
};
