import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { isValidDataPlaneUri, parseDataPlanePath } from '../ids/data-plane';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const schedulePathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.Pool}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.Schedule}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createScheduleDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'devCenter' | 'poolName' | 'projectName' | 'scheduleName'>
): string => {
    const { devCenter, poolName, projectName, scheduleName } = tokens;

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(poolName)) {
        throw new ClientError('Pool name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(scheduleName)) {
        throw new ClientError('Schedule name token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.Pool,
        poolName.toLowerCase(),
        DataPlaneResourcePathPrefix.Schedule,
        scheduleName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromScheduleDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'devCenter' | 'poolName' | 'projectName' | 'scheduleName'> => {
    if (!isValidScheduleDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a schedule');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , poolName, , scheduleName] = parseDataPlanePath(pathname);

    return {
        devCenter: origin,
        poolName,
        projectName,
        scheduleName,
    };
};

export const isValidScheduleDataPlaneUri = (uri: string): boolean => isValidDataPlaneUri(uri, schedulePathPattern);

export const tryCreateScheduleDataPlaneUri = tryOrDefault(createScheduleDataPlaneUri);
export const tryGetTokensFromScheduleDataPlaneUri = tryOrDefault(getTokensFromScheduleDataPlaneUri);
