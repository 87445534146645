import {
    DevBoxOperationKind as GeneratedDevBoxOperationKindStable,
    DevBoxOperation as GeneratedDevBoxOperationStable,
    DevBoxRepairOperationResult as GeneratedDevBoxRepairOperationResultStable,
    DevBoxRepairOperation as GeneratedDevBoxRepairOperationStable,
    DevBoxRepairOutcome as GeneratedDevBoxRepairOutcomeStable,
    DevBoxRestartOperation as GeneratedDevBoxRestartOperationStable,
    DevBoxStartOperation as GeneratedDevBoxStartOperationStable,
    DevBoxStopOperation as GeneratedDevBoxStopOperationStable,
} from 'devcenter-internal-stable';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

export type DevBoxOperationContract = GeneratedDevBoxOperationStable;
export type DevBoxOperationKindContract = GeneratedDevBoxOperationKindStable;

export const DevBoxOperationKindContract: UnionMap<DevBoxOperationKindContract> = {
    Repair: 'Repair',
    Restart: 'Restart',
    Start: 'Start',
    Stop: 'Stop',
};

export type DevBoxRepairOperationContract = GeneratedDevBoxRepairOperationStable;
export type DevBoxRepairOperationResultContract = GeneratedDevBoxRepairOperationResultStable;
export type DevBoxRepairOutcomeContract = GeneratedDevBoxRepairOutcomeStable;

export const DevBoxRepairOutcomeContract: UnionMap<DevBoxRepairOutcomeContract> = {
    FixApplied: 'FixApplied',
    IssuesDetected: 'IssuesDetected',
    NoIssuesDetected: 'NoIssuesDetected',
};

export type DevBoxRestartOperationContract = GeneratedDevBoxRestartOperationStable;
export type DevBoxStartOperationContract = GeneratedDevBoxStartOperationStable;
export type DevBoxStopOperationContract = GeneratedDevBoxStopOperationStable;

/**
 * Type guards
 */

export const isDevBoxOperationKindContract = (value: unknown): value is DevBoxOperationKindContract =>
    value === DevBoxOperationKindContract.Repair ||
    value === DevBoxOperationKindContract.Restart ||
    value === DevBoxOperationKindContract.Start ||
    value === DevBoxOperationKindContract.Stop;

export const isDevBoxRepairOperationContract = (
    contract: DevBoxOperationContract
): contract is DevBoxRepairOperationContract => contract.kind === DevBoxOperationKindContract.Repair;

export const isDevBoxRepairOutcomeContract = (value: unknown): value is DevBoxRepairOutcomeContract =>
    value === DevBoxRepairOutcomeContract.FixApplied ||
    value === DevBoxRepairOutcomeContract.IssuesDetected ||
    value === DevBoxRepairOutcomeContract.NoIssuesDetected;

export const isDevBoxRestartOperationContract = (
    contract: DevBoxOperationContract
): contract is DevBoxRestartOperationContract => contract.kind === DevBoxOperationKindContract.Restart;

export const isDevBoxStartOperationContract = (
    contract: DevBoxOperationContract
): contract is DevBoxStartOperationContract => contract.kind === DevBoxOperationKindContract.Start;

export const isDevBoxStopOperationContract = (
    contract: DevBoxOperationContract
): contract is DevBoxStopOperationContract => contract.kind === DevBoxOperationKindContract.Stop;
