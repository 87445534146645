import * as React from 'react';
import { useSelector } from 'react-redux';
import { SingleDevCenterMisconfigurationType } from '../../components/single-dev-center/models';
import {
    SingleDevCenterUriMisconfigured,
    SingleDevCenterUriMissing,
} from '../../components/single-dev-center/single-dev-center-uri-misconfigured';
import { useActionCreator } from '../../hooks/action-creator';
import { FailureCode } from '../../models/common';
import HomeIndexPageContainer from '../../pages/home';
import LoadingPage from '../../pages/loading';
import { initializeSingleDevCenterHome } from '../../redux/actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { didStatusFailWithCode, isStatusTerminal } from '../../redux/selector/common';
import { DisplayState, getDisplayState } from '../../redux/selector/display/display-state';
import {
    getSingleDevCenterUri,
    getStatusForInitializeSingleDevCenterHome,
    getStatusForLoadProjectsForSingleDevCenter,
} from '../../redux/selector/sub-applications/single-dev-center-selectors';

export const SingleDevCenterSubApplication: React.FC = () => {
    // Action creator hooks
    const initializeUri = useActionCreator(initializeSingleDevCenterHome);

    // Selector hooks
    const singleDevCenterUri = useSelector(getSingleDevCenterUri);
    const statusForInitializeSingleDevCenter = useSelector(getStatusForInitializeSingleDevCenterHome);
    const displayState = useSelector(getDisplayState);
    const statusForLoadProjectsForSingleDevCenter = useSelector(getStatusForLoadProjectsForSingleDevCenter);

    // Memoized data

    // If a dev center doesn't exist, we will fail to fetch because the DNS entry doesn't exist
    // We need to message that we failed to list projects, and this may mean that the dev center doesn't exist
    const devCenterFailedToFetchOrNotFound = React.useMemo(
        () => didStatusFailWithCode(statusForLoadProjectsForSingleDevCenter, FailureCode.REQUEST_SEND_ERROR),
        [statusForLoadProjectsForSingleDevCenter]
    );

    const invalidDevCenterUri = React.useMemo(
        () => didStatusFailWithCode(statusForInitializeSingleDevCenter, FailureCode.SingleDevCenterInvalidUri),
        [statusForInitializeSingleDevCenter]
    );

    const missingDevCenterUri = React.useMemo(
        () => didStatusFailWithCode(statusForInitializeSingleDevCenter, FailureCode.SingleDevCenterMissingUri),
        [statusForInitializeSingleDevCenter]
    );

    // Effect hooks

    React.useEffect(() => {
        initializeUri();
    }, []);

    // Render logic

    if (!isStatusTerminal(statusForInitializeSingleDevCenter)) {
        return <LoadingPage />;
    }

    if (missingDevCenterUri || !singleDevCenterUri) {
        return <SingleDevCenterUriMissing />;
    }

    if (invalidDevCenterUri) {
        return (
            <SingleDevCenterUriMisconfigured
                devCenterUri={singleDevCenterUri}
                misconfigurationType={SingleDevCenterMisconfigurationType.InvalidUri}
            />
        );
    }

    if (devCenterFailedToFetchOrNotFound) {
        return (
            <SingleDevCenterUriMisconfigured
                devCenterUri={singleDevCenterUri}
                misconfigurationType={SingleDevCenterMisconfigurationType.FailedToFetchOrNotFound}
            />
        );
    }

    if (displayState === DisplayState.Loading) {
        return <LoadingPage />;
    }

    return <HomeIndexPageContainer />;
};
