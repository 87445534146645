import { DataResponse } from '../../../models/common';
import { UserSettings } from '../../../types/user-settings';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload } from '../core-actions';
import {
    SetPartialUserSettingsPayload,
    SetUserSettingsPayload,
    SyncUserSettingsPayload,
    UserSettingsActionType,
} from './user-settings-actions';

export const setUserSettings = createActionCreator<SetUserSettingsPayload>(UserSettingsActionType.SetUserSettings);

export const setPartialUserSettings = createActionCreator<SetPartialUserSettingsPayload>(
    UserSettingsActionType.SetPartialUserSettings
);

export const storeUserSettingsError = createActionCreator<ErrorPayload>(UserSettingsActionType.StoreUserSettingsError);

export const storeUserSettingsFailed = createActionCreator<FailedPayload>(
    UserSettingsActionType.StoreUserSettingsFailed
);

export const storeUserSettingsSuccess = createActionCreatorWithoutPayload(
    UserSettingsActionType.StoreUserSettingsSuccess
);

export const syncUserSettings = createActionCreatorWithoutPayload<DataResponse<UserSettings | undefined>>(
    UserSettingsActionType.SyncUserSettings,
    {
        async: true,
    }
);

export const syncUserSettingsError = createActionCreator<ErrorPayload>(UserSettingsActionType.SyncUserSettingsError);

export const syncUserSettingsFailed = createActionCreator<FailedPayload>(UserSettingsActionType.SyncUserSettingsFailed);

export const syncUserSettingsSuccess = createActionCreator<SyncUserSettingsPayload>(
    UserSettingsActionType.SyncUserSettingsSuccess
);
