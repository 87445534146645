import { SerializableSet } from '../types/serializable-set';

export const add = <TValue>(set: SerializableSet<TValue>, value: TValue): SerializableSet<TValue> => {
    // Return initial set if value is already in set
    if (has(set, value)) {
        return set;
    }

    const nativeSet = new Set(set);
    nativeSet.add(value);
    return SerializableSet(nativeSet);
};

export const has = <TValue>(set: SerializableSet<TValue>, value: TValue): boolean => set.includes(value);

export const remove = <TValue>(set: SerializableSet<TValue>, value: TValue): SerializableSet<TValue> => {
    // Return initial set if value isn't in set already
    if (!has(set, value)) {
        return set;
    }

    const nativeSet = new Set(set);
    nativeSet.delete(value);
    return SerializableSet(nativeSet);
};

export const toArray = <TValue>(set: SerializableSet<TValue>): TValue[] => [...set];

export const union = <TValue>(...sets: SerializableSet<TValue>[]): SerializableSet<TValue> =>
    SerializableSet(new Set(sets.flatMap((set) => set)));

export const size = <TValue>(set: SerializableSet<TValue>): number => set.length;
