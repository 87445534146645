import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { DevBoxAction } from '../../models/dev-box-action';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxActionAdapter } from '../adapters/dev-box-action-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'loadDevBoxActionsForDevBoxes';

type IndexedStatusName =
    | 'delayAllDevBoxActions'
    | 'delayDevBoxAction'
    | 'listDevBoxActions'
    | 'skipAllDevBoxActions'
    | 'skipDevBoxAction';

export interface DevBoxActionDataStore {
    devBoxActions: EntityState<Entity<DevBoxAction>>;
}

export const DevBoxActionDataStore = createInitializer<DevBoxActionDataStore>({
    devBoxActions: devBoxActionAdapter.getInitialState(),
});

export type DevBoxActionStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const DevBoxActionStatusStore = createInitializer<DevBoxActionStatusStore>({
    /* Results of the "delay all" operation on all actions for a dev box*/
    delayAllDevBoxActions: statusAdapter.getInitialState(),
    /* Results of each dev box action delay operation */
    delayDevBoxAction: statusAdapter.getInitialState(),
    listDevBoxActions: statusAdapter.getInitialState(),
    loadDevBoxActionsForDevBoxes: Status(),
    /* Results of the "skip all" operation on all actions for a dev box */
    skipAllDevBoxActions: statusAdapter.getInitialState(),
    /* Results of each dev box action skip operation */
    skipDevBoxAction: statusAdapter.getInitialState(),
});

export interface DevBoxActionStore {
    data: DevBoxActionDataStore;
    status: DevBoxActionStatusStore;
}

export const DevBoxActionStore = createInitializer<DevBoxActionStore>({
    data: DevBoxActionDataStore(),
    status: DevBoxActionStatusStore(),
});
