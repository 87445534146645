import * as React from 'react';
import { DropdownValue, DropdownValueOption, DropdownValueRenderFunction } from '../form/dropdown/dropdown';
import ReadOnlyControl, { ReadOnlyControlProps } from './read-only-control';

interface ReadOnlyOptionControlProps<TValue extends DropdownValue, TData = undefined>
    extends Omit<ReadOnlyControlProps, 'value'> {
    onRenderOption?: DropdownValueRenderFunction<TValue, TData>;
    selectedOption: DropdownValueOption<TValue, TData>;
}

/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Justification: arrow functions have limitations with the use of generics when using JSX
export function ReadOnlyOptionControl<TValue extends DropdownValue, TData = undefined>(
    props: ReadOnlyOptionControlProps<TValue, TData>
): ReturnType<React.FC<ReadOnlyOptionControlProps<TValue, TData>>> {
    const { onRenderOption, selectedOption } = props;

    const readOnlyControlValue = onRenderOption ? onRenderOption(selectedOption.value) : selectedOption.text;

    return <ReadOnlyControl {...props} value={readOnlyControlValue} />;
}
/* eslint-enable prefer-arrow/prefer-arrow-functions */

export default ReadOnlyOptionControl;
