import { fluentTreeView } from '@fluentui/web-components';
import React from 'react';
import wrapFluentWebComponent from '../../react-wrapper';
import { TreeItemEvent } from './models';

const TreeViewReactWrapper = wrapFluentWebComponent(fluentTreeView(), {
    events: {
        handleSelectedChange: 'selected-change',
    },
});

interface TreeViewEvents {
    handleSelectedChange?: (event: TreeItemEvent) => void;
}

export type TreeViewProps = React.PropsWithChildren<TreeViewEvents>;

export const TreeView: React.FC<TreeViewProps> = (props: TreeViewProps) => {
    const { handleSelectedChange } = props;

    // Wrapper for type matching purposes
    const handleSelectedChangeWrapper = React.useCallback(
        (e: Event) => handleSelectedChange?.(e as TreeItemEvent),
        [handleSelectedChange]
    );

    return <TreeViewReactWrapper {...props} handleSelectedChange={handleSelectedChangeWrapper} />;
};
