import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { isValidDataPlaneUri, parseDataPlanePath } from '../ids/data-plane';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const devBoxPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.User}/${StringifiedPattern.Guid}/${DataPlaneResourcePathPrefix.DevBox}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createDevBoxDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'devBoxName' | 'devCenter' | 'projectName' | 'user'>
): string => {
    const { devBoxName, devCenter, projectName, user } = tokens;

    if (isUndefinedOrWhiteSpace(devBoxName)) {
        throw new ClientError('Dev box name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(user)) {
        throw new ClientError('User token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.User,
        user.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBox,
        devBoxName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromDevBoxDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'devBoxName' | 'devCenter' | 'projectName' | 'user'> => {
    if (!isValidDevBoxDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a dev box');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , user, , devBoxName] = parseDataPlanePath(pathname);

    return {
        devBoxName,
        devCenter: origin,
        projectName,
        user,
    };
};

export const isValidDevBoxDataPlaneUri = (uri: string): boolean => isValidDataPlaneUri(uri, devBoxPathPattern);

export const tryCreateDevBoxDataPlaneUri = tryOrDefault(createDevBoxDataPlaneUri);
export const tryGetTokensFromDevBoxDataPlaneUri = tryOrDefault(getTokensFromDevBoxDataPlaneUri);
