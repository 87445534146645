import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagName } from '../../../constants/features';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { keys } from '../../../utilities/serializable-map';
import { StoreStateSelector } from '../../selector/common';
import { getUniqueDevCentersFromProjectDataPlaneIds } from '../../selector/dev-center-selectors';
import { getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization } from '../../selector/project-from-discovery-service-selectors';
import { getProjectsWithAnyDevResourceAuthorizationByDataPlaneId } from '../../selector/project-selectors';

/**
 * Application state selectors
 */

export const getDevCenterEndpointsRequiringWarming: StoreStateSelector<string[]> = createSelector(
    [
        getProjectsWithAnyDevResourceAuthorizationByDataPlaneId,
        getProjectsFromDiscoveryServiceWithAnyDevResourceAuthorization,
    ],
    (projects, projectsFromDiscoveryService) =>
        getUniqueDevCentersFromProjectDataPlaneIds(
            keys(isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService) ? projectsFromDiscoveryService : projects)
        )
);
