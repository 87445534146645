import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { isAuthenticated, signOut as performSignOut } from '../../../data/services/identity';
import { ClientError, FailureOperation } from '../../../models/common';
import {
    signOut,
    signOutError,
    signOutRedirecting,
    signOutSuccess,
} from '../../../redux/actions/identity/identity-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { takeLeading } from '../../effects/take';

export function* signOutSaga(): SagaIterator {
    try {
        // Check whether user is already signed out
        const authenticated: boolean = yield call(isAuthenticated);

        if (!authenticated) {
            yield put(signOutSuccess());
            return;
        }

        // Redirect user for sign out
        yield put(signOutRedirecting());
        yield call(performSignOut);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SignOut);
        yield put(signOutError({ error }));
    }
}

export function* signOutListenerSaga(): SagaIterator {
    yield takeLeading(signOut, signOutSaga);
}
