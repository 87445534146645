import { combineReducers, createReducer } from '@reduxjs/toolkit';
import {
    initializeStorage,
    initializeStorageError,
    initializeStorageSuccess,
} from '../actions/storage/storage-action-creators';
import { InitializationStatusStore } from '../store/initialization-status-store';
import { StorageDataStore, StorageStore } from '../store/storage-store';
import { getPayload } from '../utilities/payload-action';
import { createInitializationStatusReducer } from './initialization-status-reducer';

export const storageReducer = combineReducers<StorageStore>({
    data: createReducer<StorageDataStore>(StorageDataStore(), (builder) => {
        builder.addCase(initializeStorageSuccess, (store, action) => {
            const { result } = getPayload(action);
            store.storageType = result;
        });
    }),

    status: combineReducers<InitializationStatusStore>({
        initialization: createInitializationStatusReducer({
            initializing: initializeStorage,
            error: initializeStorageError,
            success: initializeStorageSuccess,
        }),
    }),
});
