import { MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PortalMessageBar } from '../../message-bar';
import { CombinedInfoMessageKind } from '../models';

interface CombinedInfoMessageBarProps {
    kind: CombinedInfoMessageKind;
}

const CombinedInfoMessage: React.FC<CombinedInfoMessageBarProps> = React.memo((props: CombinedInfoMessageBarProps) => {
    const { kind } = props;

    switch (kind) {
        case CombinedInfoMessageKind.NoCreateResourcesForDevBoxesOrEnvironmentsMessage:
            return (
                <FormattedMessage
                    id="InfoMessageBar_CombinedInfoMessage_NoWriteResources"
                    defaultMessage="Your account has access to create dev boxes and environments on one or more projects, but those projects are missing resources."
                    description="Message informing user that they don't have the necessary resources to create environments or dev boxes"
                />
            );
        case CombinedInfoMessageKind.NoEnvironmentOrDevBoxWritePermissionsMessage:
            return (
                <FormattedMessage
                    id="InfoMessageBar_CombinedInfoMessage_NoWritePermission"
                    defaultMessage="Your account does not have access to create dev boxes or environments in any of the projects in this organization."
                    description="Message informing user that they don't have permissions to create dev boxes or environments."
                />
            );
        default:
            return <></>;
    }
});

export const CombinedInfoMessageBar: React.FC<CombinedInfoMessageBarProps> = React.memo(
    (props: CombinedInfoMessageBarProps) => {
        const { kind } = props;

        // Component state hooks
        const [isMessageBarOpen, setIsMessageBarOpen] = React.useState(true);

        const onDismissMessageBar = React.useCallback(() => {
            setIsMessageBarOpen(false);
        }, []);

        if (!isMessageBarOpen) {
            return <></>;
        }

        return (
            <PortalMessageBar onDismiss={onDismissMessageBar} messageBarType={MessageBarType.info}>
                <CombinedInfoMessage kind={kind} />
            </PortalMessageBar>
        );
    }
);
