import { Method } from '../../../constants/http';
import { DataResponse, FailureOperation, FailureResponse } from '../../../models/common';
import Settings from '../../../settings/settings';
import { GraphErrorContract, GraphListRequestSuccessContract } from '../../contracts/graph';
import { FetchOptions, fetchRequest } from '../fetch-request';

export type GraphRequestOptions = FetchOptions;

export interface GraphListRequestOptions extends FetchOptions {
    operation?: FailureOperation;
}

export const graphRequest = async (path: string, method: Method, options?: GraphRequestOptions): Promise<Response> =>
    await fetchRequest(`${Settings.GraphApiBaseUrl}${path}`, method, options);

export const graphListRequest = async <T>(
    path: string,
    method: Method,
    options?: GraphListRequestOptions
): Promise<DataResponse<T[]>> => {
    const result = await graphRequest(path, method, options);

    if (result.status === 200) {
        const resultJson: GraphListRequestSuccessContract<T> = await result.json();
        return {
            succeeded: true,
            data: resultJson.value,
        };
    } else {
        const errorResultJson: GraphErrorContract = await result.json();
        const { error } = errorResultJson;
        const { code, message } = error;

        return FailureResponse({ code, message, operation: options?.operation, statusCode: result.status });
    }
};
