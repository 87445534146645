import { createSelector } from '@reduxjs/toolkit';
import { getDevCenterFromDataPlaneUri } from '../../ids/data-plane';
import { unique } from '../../utilities/array';
import { filter, keys } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector, isStatusUnsuccessful } from './common';

/**
 * Basic selectors
 */

export const getUniqueDevCentersFromProjectDataPlaneIds = (ids: string[]): string[] =>
    unique(ids.map(getDevCenterFromDataPlaneUri));

/**
 * Entity state selectors
 */

const statusesForWarmDevCenterNameRecordSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.devCenterStore.status.warmDevCenterNameRecord
);

/**
 * Composed selectors
 */

export const getStatusesForWarmDevCenterNameRecord = createMapFromEntitiesSelector(
    statusesForWarmDevCenterNameRecordSelectors.selectAll
);

export const getFailedToWarmDevCenterEndpoints: StoreStateSelector<string[]> = createSelector(
    [getStatusesForWarmDevCenterNameRecord],
    (statuses) => keys(filter(statuses, isStatusUnsuccessful))
);
