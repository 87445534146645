import {
    DefaultButton,
    Dialog,
    DialogType,
    IDialogContentProps,
    IModalProps,
    IStackTokens,
    Link,
    Stack,
    makeStyles,
} from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { MultiMonitorTutorialLink } from '../../../constants/avd';
import { useOpenWindowsDesktopClientDialogContext } from '../../../hooks/context/dialogs';
import { openInSameWindow } from '../../../utilities/browser';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import DownloadWindowsClientButton from './download-windows-client-button';
import OpenWindowsDesktopClientOptionCard from './open-windows-desktop-client-option-card';

export interface OpenWindowsDesktopClientDialogComponentProps {
    hidden?: boolean;
    onDismiss: () => void;
    openInRdpClientUrl: string;
}

export interface AlreadySetUpFooterComponentProps {
    onOpenWindowsDesktopClicked: () => void;
    title: string;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'OpenWindowsDesktopClientDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for "close" button in the "Open Windows Desktop client" dialog',
    },
    downloadCardIconAriaLabel: {
        id: 'OpenWindowsDesktopClientDialog_DownloadCardIcon_AriaLabel',
        defaultMessage: 'Download Windows Desktop',
        description: 'Aria label for "download" icon in the "Open Windows Desktop client" dialog',
    },
    header: {
        id: 'OpenWindowsDesktopClientDialog_Header_Text',
        defaultMessage: 'Connect with the Remote Desktop client',
        description: 'Title of the "Open Windows Desktop client" dialog',
    },
    openCardIconAriaLabel: {
        id: 'OpenWindowsDesktopClientDialog_OpenCardIcon_AriaLabel',
        defaultMessage: 'Open Windows Desktop',
        description: 'Aria label for "open" icon in the "Open Windows Desktop client" dialog',
    },

    openWindowsDesktopClientDialogConnectText: {
        id: 'OpenWindowsDesktopClientDialog_Connect_Text',
        defaultMessage: 'Connect',
        description: 'Text to connect with remote desktop if already set up',
    },
    OpenWindowsDesktopClientDialog_MultiMonitorCardIcon_AriaLabel: {
        id: 'OpenWindowsDesktopClientDialog_MultiMonitorCardIcon_AriaLabel',
        defaultMessage: 'Multi monitor card icon',
        description: 'Aria label for "multi monitor" icon in the "Open Windows Desktop Client" dialog',
    },
    multiMonitorCardLinkAriaLabel: {
        id: 'OpenWindowsDesktopClientDialog_MultiMonitorCardLink_AriaLabel',
        defaultMessage: 'Multi monitor setup link',
        description: 'Aria label for Multi-Monitor setup link',
    },
});

/**
 * Styles
 */

const useDialogStyles = makeStyles({
    inner: {
        padding: '16px 24px 24px 24px',
        // New heading is less words than the one we had before, this is done to make sure dialog still remains same width
        width: '500px',
    },
});

/**
 * END Styles
 */

const modalProps: IModalProps = {
    isBlocking: true,
};

const optionsContainerTokens: IStackTokens = {
    childrenGap: 12,
};

const AlreadySetUpFooterComponent: React.FC<AlreadySetUpFooterComponentProps> = (
    props: AlreadySetUpFooterComponentProps
) => {
    const { title, onOpenWindowsDesktopClicked } = props;

    return (
        <Stack horizontal horizontalAlign="start">
            <Stack.Item>
                <DefaultButton text={title} onClick={onOpenWindowsDesktopClicked} />
            </Stack.Item>
        </Stack>
    );
};

const OpenWindowsDesktopClientDialog: React.FC<OpenWindowsDesktopClientDialogComponentProps> = (
    props: OpenWindowsDesktopClientDialogComponentProps
) => {
    const { hidden, onDismiss, openInRdpClientUrl } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const dialogStyles = useDialogStyles();

    // Callback hooks
    const onOpenWindowsDesktopClicked = React.useCallback(() => {
        if (isNotUndefinedOrWhiteSpace(openInRdpClientUrl)) {
            openInSameWindow(openInRdpClientUrl);
        }
    }, [openInRdpClientUrl]);

    // Memoized data
    const dialogContentProps = React.useMemo<IDialogContentProps>(
        () => ({
            styles: dialogStyles,
            title: formatMessage(messages.header),
            type: DialogType.normal,
        }),
        [dialogStyles, formatMessage]
    );

    return (
        <Dialog
            closeButtonAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
            dialogContentProps={dialogContentProps}
            hidden={hidden}
            maxWidth={560}
            modalProps={modalProps}
            onDismiss={onDismiss}
        >
            <Stack tokens={optionsContainerTokens}>
                <Stack.Item>
                    <OpenWindowsDesktopClientOptionCard
                        contentFooter={
                            <Stack horizontal horizontalAlign="start">
                                <Stack.Item>
                                    <DownloadWindowsClientButton />
                                </Stack.Item>
                            </Stack>
                        }
                        iconAriaLabel={formatMessage(messages.downloadCardIconAriaLabel)}
                        iconName="Download"
                        paddingBottom={20}
                        subtitle={
                            <FormattedMessage
                                id="OpenWindowsDesktopClientDialog_DownloadCardSubtitle_Text"
                                defaultMessage="Get the Remote Desktop app to connect to your dev box."
                                description="Subtitle for the download option within the 'Open Windows Desktop client' dialog"
                            />
                        }
                        title={
                            <FormattedMessage
                                id="OpenWindowsDesktopClientDialog_DownloadCardTitle_Text"
                                defaultMessage="New to Microsoft Dev Box?"
                                description="Title for the download option within the 'Open Windows Desktop client' dialog"
                            />
                        }
                    />
                </Stack.Item>

                <Stack.Item>
                    <OpenWindowsDesktopClientOptionCard
                        contentFooter={
                            <Stack horizontal horizontalAlign="start">
                                <Stack.Item>
                                    <Link
                                        href={MultiMonitorTutorialLink}
                                        target="_blank"
                                        aria-label={formatMessage(messages.multiMonitorCardLinkAriaLabel)}
                                    >
                                        <FormattedMessage
                                            id="OpenWindowsDesktopClientDialog_MultiMonitorCardLink_Text"
                                            defaultMessage="Learn how to configure display settings for a dev box"
                                            description="Link to website for multi monitor setup instructions"
                                        />
                                    </Link>
                                </Stack.Item>
                            </Stack>
                        }
                        iconAriaLabel={formatMessage(
                            messages.OpenWindowsDesktopClientDialog_MultiMonitorCardIcon_AriaLabel
                        )}
                        iconName="TVMonitor"
                        paddingBottom={20}
                        subtitle={
                            <FormattedMessage
                                id="OpenWindowsDesktopClientDialog_MultiMonitorCardSubtitle_Text"
                                defaultMessage="Enable multiple monitor support"
                                description="Subtitle for the multi-monitor option within the 'Open Windows Desktop client' dialog"
                            />
                        }
                        title={
                            <FormattedMessage
                                id="OpenWindowsDesktopClientDialog_MultiMonitorCard_Text"
                                defaultMessage="First time connecting to this machine?"
                                description="Title for the multi-monitor option within the 'Open Windows Desktop client' dialog"
                            />
                        }
                    />
                </Stack.Item>

                <Stack.Item>
                    <OpenWindowsDesktopClientOptionCard
                        iconAriaLabel={formatMessage(messages.openCardIconAriaLabel)}
                        iconName="Remote"
                        subtitle={
                            <FormattedMessage
                                id="OpenWindowsDesktopClientDialog_OpenCardSubtitle_Text"
                                defaultMessage="Go right to your machine in Remote Desktop."
                                description="Subtitle for the open option within the 'Open Windows Desktop client' dialog"
                            />
                        }
                        title={
                            <FormattedMessage
                                id="OpenWindowsDesktopClientDialog_OpenCardTitle_Text"
                                defaultMessage="Already set up?"
                                description="Title for the open option within the 'Open Windows Desktop client' dialog"
                            />
                        }
                        contentFooter={
                            <AlreadySetUpFooterComponent
                                onOpenWindowsDesktopClicked={onOpenWindowsDesktopClicked}
                                title={formatMessage(messages.openWindowsDesktopClientDialogConnectText)}
                            />
                        }
                    />
                </Stack.Item>
            </Stack>
        </Dialog>
    );
};

export const OpenWindowsDesktopClientDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useOpenWindowsDesktopClientDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <OpenWindowsDesktopClientDialog {...properties} onDismiss={closeSurface} hidden={!isOpen} />;
};

export default OpenWindowsDesktopClientDialogContextWrapper;
