import { createSelector } from '@reduxjs/toolkit';
import { compact, unique } from '../../../../utilities/array';
import { isNotUndefinedOrWhiteSpace } from '../../../../utilities/string';
import { StoreStateSelector } from '../../../selector/common';
import { getEnvironments } from '../../../selector/environment-selectors';

/**
 * Application state selectors
 */

export const getOwnerIdsFromEnvironments: StoreStateSelector<string[]> = createSelector(
    [getEnvironments],
    (environments) =>
        unique(compact(environments.map((environment) => environment.user).filter(isNotUndefinedOrWhiteSpace)))
);
