import { ICalloutProps, IStackTokens, Stack, TooltipHost, TooltipOverflowMode, makeStyles } from '@fluentui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { removeSpaces } from '../../../../../../utilities/string';
import { getSemanticColor } from '../../../../../../utilities/styles';
import { PoolViewModel } from '../../../../../add-dev-box-panel/models';

interface PoolDropdownItemProps {
    pool: PoolViewModel;
}

/**
 * Style Section
 */

const useDropdownNameContainerStyles = makeStyles({
    root: {
        paddingTop: 6,
    },
});

const useDropdownOptionContainerStyles = makeStyles({
    root: {
        height: 55,
        lineHeight: 20,
        width: '100%',
    },
});

const usePoolMetaDataContainerStyles = makeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'addDevBoxPanelPoolOptionMetaDataText'),
    },
}));

const useTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const tooltipCalloutProps: ICalloutProps = { gapSpace: 0 };

const poolMetaDataContainerTokens: IStackTokens = {
    childrenGap: 10,
};

const poolDropdownItemContainerTokens: IStackTokens = {
    childrenGap: 4,
};

/* END */

export const PoolDropdownItem: React.FC<PoolDropdownItemProps> = (props: PoolDropdownItemProps) => {
    const { pool } = props;
    const { cpuCount, memoryInGb, diskSizeInGb, region, displayName: poolName } = pool;

    // Intl hooks
    const { formatNumber } = useIntl();

    // Style hooks
    const dropdownNameContainerStyles = useDropdownNameContainerStyles();
    const dropdownOptionContainerStyles = useDropdownOptionContainerStyles();
    const poolMetaDataContainerStyles = usePoolMetaDataContainerStyles();
    const tooltipHostStyles = useTooltipHostStyles();

    const poolNameValues = React.useMemo(() => ({ poolName, region }), [poolName, region]);

    const poolNameContent = (
        <FormattedMessage
            id="AddDevBoxPoolDropdownItem_Location_Text"
            defaultMessage="{poolName} ({region})"
            description="Text informing user of the pool name and location. {poolName} and {region} should not be localized."
            values={poolNameValues}
        />
    );

    return (
        <Stack tokens={poolDropdownItemContainerTokens} styles={dropdownOptionContainerStyles}>
            <Stack.Item styles={dropdownNameContainerStyles}>
                <TooltipHost
                    calloutProps={tooltipCalloutProps}
                    content={poolNameContent}
                    overflowMode={TooltipOverflowMode.Self}
                    styles={tooltipHostStyles}
                >
                    {poolNameContent}
                </TooltipHost>
            </Stack.Item>
            <Stack.Item styles={poolMetaDataContainerStyles}>
                <Stack horizontal tokens={poolMetaDataContainerTokens}>
                    <Stack.Item>
                        <FormattedMessage
                            id="AddDevBoxPoolDropdownItem_CpuCount_Text"
                            defaultMessage="{cpuCount} vCPU"
                            description="Text informing user of how many cores are assigned to a dev box. {cpuCount} should not be localized."
                            values={{ cpuCount: formatNumber(cpuCount) }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <FormattedMessage
                            id="AddDevBoxPoolDropdownItem_MemoryInGb_Text"
                            defaultMessage="{memoryInGb} RAM"
                            description="Text informing user of how much memory a dev box has. {memoryInGb} should not be localized."
                            values={{
                                // Note: using removeSpaces to convert "__ GB" -> "__GB"
                                memoryInGb: removeSpaces(formatNumber(memoryInGb, { style: 'unit', unit: 'gigabyte' })),
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <FormattedMessage
                            id="AddDevBoxPoolDropdownItem_DiskSizeInGb_Text"
                            defaultMessage="{diskSizeInGb} Storage"
                            description="Text informing user of how much memory a dev box has. {diskSizeInGb} should not be localized."
                            values={{
                                // Note: using removeSpaces to convert "__ GB" -> "__GB"
                                diskSizeInGb: removeSpaces(
                                    formatNumber(diskSizeInGb, { style: 'unit', unit: 'gigabyte' })
                                ),
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default PoolDropdownItem;
