import { isPoolResourceProperties, PoolResourceProperties } from '../../models/pool';
import { isProjectResourceProperties, ProjectResourceProperties } from '../../models/project';
import { isResourceWithProperties, Resource } from '../../models/resource-manager';
import { normalizeResourceId } from './normalize-resource-id';

const normalizePoolProperties = (properties: PoolResourceProperties): PoolResourceProperties => {
    const { machineDefinitionId, networkSettingsId } = properties;

    return {
        ...properties,
        machineDefinitionId: normalizeResourceId(machineDefinitionId),
        networkSettingsId: normalizeResourceId(networkSettingsId),
    };
};

const normalizeProjectProperties = (properties: ProjectResourceProperties): ProjectResourceProperties => {
    const { devCenterId } = properties;
    return { ...properties, devCenterId: normalizeResourceId(devCenterId) };
};

export const normalizeResource = <TProperties = undefined>(resource: Resource<TProperties>): Resource<TProperties> => {
    let normalizedResource: Resource<TProperties> = {
        ...resource,
        id: normalizeResourceId(resource.id),
    };

    if (isResourceWithProperties(resource)) {
        const { properties } = resource;

        // Add more cases for different property types as they become relevant
        if (isPoolResourceProperties(properties)) {
            normalizedResource = { ...normalizedResource, properties: normalizePoolProperties(properties) };
        }

        if (isProjectResourceProperties(properties)) {
            normalizedResource = { ...normalizedResource, properties: normalizeProjectProperties(properties) };
        }
    }

    return normalizedResource;
};

// Note: unfortunately can't use tryOrDefault here, due to generics
export const tryNormalizeResource = <TProperties = undefined>(
    resource: Resource<TProperties>
): Resource<TProperties> | undefined => {
    try {
        return normalizeResource(resource);
    } catch {
        return undefined;
    }
};
