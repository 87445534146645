import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../models/permission';
import { SerializableMap } from '../../types/serializable-map';
import {
    projectEnvironmentTypePermissionRecordAdapter,
    projectPermissionRecordAdapter,
} from '../adapters/permission-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector, getDataById } from './common';

/**
 * Basic selectors
 */

export const getDoesProjectHaveAuthorizationForAnyDevResource = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => {
    const record = getDataById(store, id);

    if (!record) {
        return false;
    }

    const {
        hasDevBoxReadPermission,
        hasDevBoxWritePermission,
        hasEnvironmentReadPermission,
        hasEnvironmentWritePermission,
    } = record;

    return (
        hasDevBoxReadPermission ||
        hasDevBoxWritePermission ||
        hasEnvironmentReadPermission ||
        hasEnvironmentWritePermission
    );
};

export const getIsProjectAuthorizedForDevBoxCreate = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => {
    const { hasDevBoxReadPermission, hasDevBoxWritePermission } = getDataById(store, id) ?? {};
    return (hasDevBoxReadPermission && hasDevBoxWritePermission) ?? false;
};

export const getIsProjectAuthorizedForDevBoxRead = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasDevBoxReadPermission ?? false;

export const getIsProjectAuthorizedForDevBoxCustomize = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasDevBoxCustomizePermission ?? false;

export const getIsProjectAuthorizedForEnvironmentRead = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasEnvironmentReadPermission ?? false;

export const getIsProjectAuthorizedForEnvironmentWrite = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasEnvironmentWritePermission ?? false;

export const getIsProjectAuthorizedForProjectEnvironmentTypeRead = (
    store: EntityState<Entity<ProjectPermissionRecord>> | SerializableMap<ProjectPermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasProjectEnvironmentTypeReadPermission ?? false;

export const getIsProjectEnvironmentTypeAuthorizedForEnvironmentRead = (
    store:
        | EntityState<Entity<ProjectEnvironmentTypePermissionRecord>>
        | SerializableMap<ProjectEnvironmentTypePermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasEnvironmentReadPermission ?? false;

export const getIsProjectEnvironmentTypeAuthorizedForEnvironmentWrite = (
    store:
        | EntityState<Entity<ProjectEnvironmentTypePermissionRecord>>
        | SerializableMap<ProjectEnvironmentTypePermissionRecord>,
    id: string
): boolean => getDataById(store, id)?.hasEnvironmentWritePermission ?? false;

export const getStatusForDiscoverProjectEnvironmentTypePermissions: StoreStateSelector<Status> = (store) =>
    store.permissionStore.status.discoverProjectEnvironmentTypePermissions;

export const getStatusForDiscoverProjectPermissions: StoreStateSelector<Status> = (store) =>
    store.permissionStore.status.discoverProjectPermissions;

/**
 * Entity state selectors
 */

const projectEnvironmentTypePermissionsSelectors =
    projectEnvironmentTypePermissionRecordAdapter.getSelectors<StoreState>(
        (store) => store.permissionStore.data.projectEnvironmentTypePermissions
    );

const projectPermissionsSelectors = projectPermissionRecordAdapter.getSelectors<StoreState>(
    (store) => store.permissionStore.data.projectPermissions
);

/**
 * Composed selectors
 */

export const getPermissionsForProjectEnvironmentTypes = createMapFromEntitiesSelector(
    projectEnvironmentTypePermissionsSelectors.selectAll
);

export const getPermissionsForProjects = createMapFromEntitiesSelector(projectPermissionsSelectors.selectAll);
