import { SagaIterator } from 'redux-saga';
import { actionChannel, call, put, select, take } from 'redux-saga/effects';
import { GetAccessTokenResponse, getAccessToken } from '../../../data/services/identity';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    getAccessTokenError,
    getAccessTokenFailed,
    getAccessTokenRedirecting,
    getAccessTokenSkipped,
    getAccessTokenSuccess,
} from '../../../redux/actions/identity/identity-action-creators';
import { GetAccessTokenAction, IdentityActionType } from '../../../redux/actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { getAuthenticationState } from '../../selector/identity-selectors';
import { AuthenticationState } from '../../store/identity-state';
import { getPayload } from '../../utilities/payload-action';

export function* getAccessTokenSaga(action: GetAccessTokenAction): SagaIterator {
    const { meta } = action;
    const { onlyUseSilentRequests, scopes } = getPayload(action);

    try {
        const authenticationState = yield select(getAuthenticationState);
        // TODO: Refactor to create a cancelled state or a failed response to avoid additional request calls after a skipped action.
        if (authenticationState === AuthenticationState.IdentityErrorBasedExpiry) {
            const reason = `Skipping the getAccessToken request due to the authentication state ${authenticationState}`;
            yield put(getAccessTokenSkipped({ reason, scopes }, meta));
            yield resolveAction(action, '');
            return;
        }

        // Attempt to retrieve access token
        const response: GetAccessTokenResponse = yield call(getAccessToken, scopes, onlyUseSilentRequests);

        // Put any errors that occurred
        if (isFailureResponse(response)) {
            yield put(getAccessTokenFailed({ failure: response, scopes }, meta));

            // Special case: consumers expect GetAccessToken failures to behave like exceptions, so they don't have to
            // build in boilerplate unpacking of a DataResponse result.
            const error = new ClientError(response, FailureOperation.GetAccessToken);
            yield rejectAction(action, error);
            return;
        }

        const { data } = response;

        // Set state to redirecting if that's the case
        if (data.isRedirecting) {
            yield put(getAccessTokenRedirecting({ scopes }, meta));
            return;
        }

        const { accessToken, scopes: grantedScopes } = data;

        // Check for inconsistent state
        if (accessToken === undefined) {
            const error = new ClientError(
                'getAccessToken silently succeeded, but with undefined access token',
                FailureOperation.GetAccessToken
            );
            yield put(getAccessTokenError({ error, scopes }, meta));
            yield rejectAction(action, error);
            return;
        }

        if (grantedScopes === undefined) {
            const error = new ClientError(
                'getAccessToken silently succeeded, but with undefined scopes',
                FailureOperation.GetAccessToken
            );
            yield put(getAccessTokenError({ error, scopes }, meta));
            yield rejectAction(action, error);
            return;
        }

        // Otherwise, mark success
        yield put(getAccessTokenSuccess({ scopes }, meta));
        yield resolveAction(action, accessToken);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetAccessToken);
        yield put(getAccessTokenError({ error, scopes }, meta));
        yield rejectAction(action, error);
        return;
    }
}

export function* getAccessTokenListenerSaga(): SagaIterator {
    // Handle requests to get access tokens serially
    const getAccessTokenChannel = yield actionChannel(IdentityActionType.GetAccessToken);

    while (true) {
        const getAccessTokenAction: GetAccessTokenAction = yield take(getAccessTokenChannel);
        yield call(getAccessTokenSaga, getAccessTokenAction);
    }
}
