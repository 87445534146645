import { ApiVersion, AzureSearchParameter } from '../../../constants/azure';
import { ContentType, Method, StatusCode } from '../../../constants/http';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { ResourceContract, ResourceProvisioningStateContract } from '../../contracts/resource-manager';
import { resourceRequest } from '../resource-request';

interface ListResourcesInTenantQueryResult {
    count: number;
    data: ResourceContract<unknown>[];
    totalRecords: number;
}

export type ListResourcesInTenantResponse = DataResponse<ResourceContract<unknown>[]>;

const queryFilters = (provisioningStates?: ResourceProvisioningStateContract[]) => {
    let filters = '';

    if (provisioningStates && provisioningStates.length > 0) {
        filters =
            provisioningStates.length > 1
                ? `${filters}\n| where properties['provisioningState'] in~ (${provisioningStates
                      .map((s) => `'${s}'`)
                      .join(', ')})`
                : `${filters}\n| where properties['provisioningState'] =~ '${provisioningStates[0]}'`;
    }

    return filters;
};

const listResourcesInTenantQuery = (
    resourceTypes: string[],
    provisioningStates?: ResourceProvisioningStateContract[]
) => `
Resources
| where type in~ (${resourceTypes.map((resourceType) => `'${resourceType.toLowerCase()}'`).join(', ')})${queryFilters(
    provisioningStates
)}
| project id, location, tenantId, name, properties, type
`;

export const listResourcesInTenant = async (
    resourceTypes: string[],
    accessToken: string,
    provisioningStates?: ResourceProvisioningStateContract[],
    activityId?: string
): Promise<ListResourcesInTenantResponse> => {
    if (resourceTypes.length < 1) {
        throw new ClientError('You must provide at least one resource type.');
    }

    const path = `/providers/Microsoft.ResourceGraph/resources?${AzureSearchParameter.ApiVersion}=${ApiVersion.ResourceGraph}`;
    const result = await resourceRequest(path, Method.POST, accessToken, {
        activityId,
        body: JSON.stringify({
            query: listResourcesInTenantQuery(resourceTypes, provisioningStates),
            options: { allowPartialScopes: true }, // maximum of 5000 subs for cross tenant query
        }),
        contentType: ContentType.ApplicationJson,
        operation: FailureOperation.ListResourcesInTenant,
        retryOptions: { statusCodes: [StatusCode.TooManyRequests] },
    });

    // Immediately return any error
    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = (await result.data.json()) as ListResourcesInTenantQueryResult;
    return { data, succeeded: true };
};
