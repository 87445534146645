import { Schedule } from '../../../models/schedule';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    discoverSchedulesForDevBoxes,
    discoverSchedulesForPools,
    listSchedulesByProject,
} from './schedule-action-creators';

/**
 * Action types
 */

type ScheduleActionName =
    | ActionName<'DiscoverSchedulesForDevBoxes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DiscoverSchedulesForPools', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListSchedulesByProject', 'Error' | 'Failed' | 'Success'>;

export type ScheduleActionType =
    | ActionType<'DISCOVER_SCHEDULES_FOR_DEV_BOXES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISCOVER_SCHEDULES_FOR_POOLS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_SCHEDULES_BY_PROJECT', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const ScheduleActionType: UnionValueMap<ScheduleActionName, ScheduleActionType> = {
    DiscoverSchedulesForDevBoxes: 'DISCOVER_SCHEDULES_FOR_DEV_BOXES',
    DiscoverSchedulesForDevBoxesError: 'DISCOVER_SCHEDULES_FOR_DEV_BOXES_ERROR',
    DiscoverSchedulesForDevBoxesFailed: 'DISCOVER_SCHEDULES_FOR_DEV_BOXES_FAILED',
    DiscoverSchedulesForDevBoxesSuccess: 'DISCOVER_SCHEDULES_FOR_DEV_BOXES_SUCCESS',
    DiscoverSchedulesForPools: 'DISCOVER_SCHEDULES_FOR_POOLS',
    DiscoverSchedulesForPoolsError: 'DISCOVER_SCHEDULES_FOR_POOLS_ERROR',
    DiscoverSchedulesForPoolsFailed: 'DISCOVER_SCHEDULES_FOR_POOLS_FAILED',
    DiscoverSchedulesForPoolsSuccess: 'DISCOVER_SCHEDULES_FOR_POOLS_SUCCESS',
    ListSchedulesByProject: 'LIST_SCHEDULES_BY_PROJECT',
    ListSchedulesByProjectError: 'LIST_SCHEDULES_BY_PROJECT_ERROR',
    ListSchedulesByProjectFailed: 'LIST_SCHEDULES_BY_PROJECT_FAILED',
    ListSchedulesByProjectSuccess: 'LIST_SCHEDULES_BY_PROJECT_SUCCESS',
};

/**
 * Payloads
 */

export type ListSchedulesByProjectSuccessPayload = IndexedPayload & ResultPayload<Schedule[]>;

/**
 * Action shorthands
 */

export type DiscoverSchedulesForDevBoxesAction = ReturnType<typeof discoverSchedulesForDevBoxes>;
export type DiscoverSchedulesForPoolsAction = ReturnType<typeof discoverSchedulesForPools>;
export type ListSchedulesByProjectAction = ReturnType<typeof listSchedulesByProject>;
