import { UnionMap } from '../types/union-map';
import { DataPlaneErrorCode } from './data-plane';

export type EnvironmentFailureReason =
    | 'CreateFailed'
    | 'DeleteFailed'
    | 'DeployFailed'
    | 'ProvisioningCanceled'
    | 'ProvisioningFailed'
    | 'UpdateFailed';

export const EnvironmentFailureReason: UnionMap<EnvironmentFailureReason> = {
    CreateFailed: 'CreateFailed',
    DeleteFailed: 'DeleteFailed',
    DeployFailed: 'DeployFailed',
    ProvisioningCanceled: 'ProvisioningCanceled',
    ProvisioningFailed: 'ProvisioningFailed',
    UpdateFailed: 'UpdateFailed',
};

export const MeUserIdValue = 'me';

export const EnvironmentOperationConstants = {
    MeUserId: MeUserIdValue,
};

export type EnvironmentProvisioningState =
    | 'Succeeded'
    | 'Failed'
    | 'Canceled'
    | 'Provisioning'
    | 'Preparing'
    | 'Creating'
    | 'Deleting'
    | 'Updating'
    | 'Deploying';

export const EnvironmentProvisioningState: UnionMap<EnvironmentProvisioningState> = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Canceled: 'Canceled',

    Provisioning: 'Provisioning',
    Preparing: 'Preparing',
    Creating: 'Creating',
    Deleting: 'Deleting',
    Updating: 'Updating',
    Deploying: 'Deploying',
};

export const environmentProvisioningStates: EnvironmentProvisioningState[] = [
    EnvironmentProvisioningState.Creating,
    EnvironmentProvisioningState.Canceled,
    EnvironmentProvisioningState.Deleting,
    EnvironmentProvisioningState.Failed,
    EnvironmentProvisioningState.Provisioning,
    EnvironmentProvisioningState.Preparing,
    EnvironmentProvisioningState.Succeeded,
    EnvironmentProvisioningState.Updating,
    EnvironmentProvisioningState.Deploying,
];

export const terminalEnvironmentProvisioningStates: EnvironmentProvisioningState[] = [
    EnvironmentProvisioningState.Succeeded,
    EnvironmentProvisioningState.Failed,
    EnvironmentProvisioningState.Canceled,
];

export type CreateEnvironmentErrorCode = 'DeploymentMonthlyRuntimeExceeded' | DataPlaneErrorCode;

export const CreateEnvironmentErrorCode: UnionMap<CreateEnvironmentErrorCode> = {
    DeploymentMonthlyRuntimeExceeded: 'DeploymentMonthlyRuntimeExceeded',
    ...DataPlaneErrorCode,
};

export type EnvironmentResourceErrorCode = 'DeploymentCanceled' | 'DeploymentFailed' | 'EnvironmentStorageExceeded';

export const EnvironmentResourceErrorCode: UnionMap<EnvironmentResourceErrorCode> = {
    DeploymentCanceled: 'DeploymentCanceled',
    /** This error code is a placeholder for missing errorDetails on a failed environment, does not come from the backend. */
    DeploymentFailed: 'DeploymentFailed',
    EnvironmentStorageExceeded: 'EnvironmentStorageExceeded',
};

export type EnvironmentActionId = 'deploy';

export const EnvironmentActionId: UnionMap<EnvironmentActionId> = {
    deploy: 'deploy',
};
