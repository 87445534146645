import {
    CustomizationGroup,
    CustomizationTask,
    CustomizationTaskDefinition,
    CustomizationTaskListValidationResult,
    PutCustomizationTask,
} from '../../../models/customization';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, PollPayload, ResultPayload } from '../core-actions';
import {
    createCustomizationGroup,
    createCustomizationGroupAccepted,
    getCustomizationTaskLog,
    listCustomizationGroups,
    listCustomizationTaskDefinitions,
    loadCustomizationGroups,
    pollNonTerminalCustomizationGroup,
    trackCustomizationGroupProgress,
    validateCustomizationTasks,
} from './customization-action-creators';

/**
 * Action types
 */

type CustomizationActionName =
    | ActionName<'CreateCustomizationGroup', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetCustomizationTaskLog', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListCustomizationGroups', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListCustomizationTaskDefinitions', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadCustomizationGroups', 'Error' | 'Failed' | 'Success'>
    | ActionName<'PollNonTerminalCustomizationGroup', 'Complete' | 'Error' | 'Failed'>
    | 'TrackCustomizationGroupProgress'
    | ActionName<'ValidateCustomizationTasks', 'Error' | 'Failed' | 'Success'>
    | 'ClearValidationResult';

export type CustomizationActionType =
    | ActionType<'CREATE_CUSTOMIZATION_GROUP', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_CUSTOMIZATION_TASK_LOG', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_CUSTOMIZATION_GROUPS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_CUSTOMIZATION_TASK_DEFINITIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_CUSTOMIZATION_GROUPS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'POLL_NON_TERMINAL_CUSTOMIZATION_GROUP', '_COMPLETE' | '_ERROR' | '_FAILED'>
    | 'TRACK_CUSTOMIZATION_GROUP_PROGRESS'
    | ActionType<'VALIDATE_CUSTOMIZATION_TASKS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | 'CLEAR_VALIDATION_RESULT';

export const CustomizationActionType: UnionValueMap<CustomizationActionName, CustomizationActionType> = {
    CreateCustomizationGroup: 'CREATE_CUSTOMIZATION_GROUP',
    CreateCustomizationGroupError: 'CREATE_CUSTOMIZATION_GROUP_ERROR',
    CreateCustomizationGroupFailed: 'CREATE_CUSTOMIZATION_GROUP_FAILED',
    CreateCustomizationGroupAccepted: 'CREATE_CUSTOMIZATION_GROUP_ACCEPTED',
    CreateCustomizationGroupSuccess: 'CREATE_CUSTOMIZATION_GROUP_SUCCESS',
    ListCustomizationGroups: 'LIST_CUSTOMIZATION_GROUPS',
    ListCustomizationGroupsError: 'LIST_CUSTOMIZATION_GROUPS_ERROR',
    ListCustomizationGroupsFailed: 'LIST_CUSTOMIZATION_GROUPS_FAILED',
    ListCustomizationGroupsSuccess: 'LIST_CUSTOMIZATION_GROUPS_SUCCESS',
    LoadCustomizationGroups: 'LOAD_CUSTOMIZATION_GROUPS',
    LoadCustomizationGroupsError: 'LOAD_CUSTOMIZATION_GROUPS_ERROR',
    LoadCustomizationGroupsFailed: 'LOAD_CUSTOMIZATION_GROUPS_FAILED',
    LoadCustomizationGroupsSuccess: 'LOAD_CUSTOMIZATION_GROUPS_SUCCESS',
    ListCustomizationTaskDefinitions: 'LIST_CUSTOMIZATION_TASK_DEFINITIONS',
    ListCustomizationTaskDefinitionsError: 'LIST_CUSTOMIZATION_TASK_DEFINITIONS_ERROR',
    ListCustomizationTaskDefinitionsFailed: 'LIST_CUSTOMIZATION_TASK_DEFINITIONS_FAILED',
    ListCustomizationTaskDefinitionsSuccess: 'LIST_CUSTOMIZATION_TASK_DEFINITIONS_SUCCESS',
    PollNonTerminalCustomizationGroup: 'POLL_NON_TERMINAL_CUSTOMIZATION_GROUP',
    PollNonTerminalCustomizationGroupError: 'POLL_NON_TERMINAL_CUSTOMIZATION_GROUP_ERROR',
    PollNonTerminalCustomizationGroupFailed: 'POLL_NON_TERMINAL_CUSTOMIZATION_GROUP_FAILED',
    PollNonTerminalCustomizationGroupComplete: 'POLL_NON_TERMINAL_CUSTOMIZATION_GROUP_COMPLETE',
    ValidateCustomizationTasks: 'VALIDATE_CUSTOMIZATION_TASKS',
    ValidateCustomizationTasksError: 'VALIDATE_CUSTOMIZATION_TASKS_ERROR',
    ValidateCustomizationTasksFailed: 'VALIDATE_CUSTOMIZATION_TASKS_FAILED',
    ValidateCustomizationTasksSuccess: 'VALIDATE_CUSTOMIZATION_TASKS_SUCCESS',
    GetCustomizationTaskLog: 'GET_CUSTOMIZATION_TASK_LOG',
    GetCustomizationTaskLogError: 'GET_CUSTOMIZATION_TASK_LOG_ERROR',
    GetCustomizationTaskLogFailed: 'GET_CUSTOMIZATION_TASK_LOG_FAILED',
    GetCustomizationTaskLogSuccess: 'GET_CUSTOMIZATION_TASK_LOG_SUCCESS',
    TrackCustomizationGroupProgress: 'TRACK_CUSTOMIZATION_GROUP_PROGRESS',
    ClearValidationResult: 'CLEAR_VALIDATION_RESULT',
};

/**
 * Payloads
 */

export interface CreateCustomizationGroupPayload extends IndexedPayload {
    tasks: CustomizationTask[];
}

export type PollNonTerminalCustomizationGroupPayload = IndexedPayload & PollPayload;

export type CreateCustomizationGroupSuccessPayload = IndexedPayload & ResultPayload<CustomizationGroup>;
export type CreateCustomizationGroupAcceptedPayload = IndexedPayload & ResultPayload<CustomizationGroup>;

export type ListCustomizationGroupsSuccessPayload = IndexedPayload & ResultPayload<CustomizationGroup[]>;

export type ListCustomizationTaskDefinitionsSuccessPayload = IndexedPayload &
    ResultPayload<CustomizationTaskDefinition[]>;

export type PollNonTerminalCustomizationGroupCompletePayload = IndexedPayload & ResultPayload<CustomizationGroup>;

// Defining `projectId` explicitly rather than using `IndexedPayload` because we are not tracking this as an indexed operation -
// this is a singleton operation that occurs in the context of a project
export interface ValidateCustomizationTasksPayload {
    projectId: string;
    tasks: PutCustomizationTask[];
}
export type ValidateCustomizationTasksSuccessPayload = ResultPayload<CustomizationTaskListValidationResult>;

export type GetCustomizationTaskLogSuccessPayload = IndexedPayload & ResultPayload<string>;

export type TrackCustomizationGroupProgressPayload = IndexedPayload & ResultPayload<CustomizationGroup>;

/**
 * Action shorthands
 */

export type CreateCustomizationGroupAction = ReturnType<typeof createCustomizationGroup>;
export type CreateCustomizationGroupAcceptedAction = ReturnType<typeof createCustomizationGroupAccepted>;
export type ListCustomizationGroupsAction = ReturnType<typeof listCustomizationGroups>;
export type LoadCustomizationGroupsAction = ReturnType<typeof loadCustomizationGroups>;
export type ListCustomizationTaskDefinitionsAction = ReturnType<typeof listCustomizationTaskDefinitions>;
export type PollNonTerminalCustomizationGroupAction = ReturnType<typeof pollNonTerminalCustomizationGroup>;
export type ValidateCustomizationTasksAction = ReturnType<typeof validateCustomizationTasks>;
export type GetCustomizationTaskLogAction = ReturnType<typeof getCustomizationTaskLog>;
export type TrackCustomizationGroupProgressAction = ReturnType<typeof trackCustomizationGroupProgress>;
