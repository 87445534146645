import { Environment } from '../constants/app';
import { ApplicationMode, ApplicationModePathname } from '../constants/application-mode';
import { getPathnameFromCurrentLocation } from './url';

const localHostname = 'local.portal.fidalgo.azure-test.net';
const developmentDevBoxHostname = 'devbox.microsoft-ppe.com';
const developmentHostname = 'devportal.microsoft-ppe.com';
const publicDevBoxHostname = 'devbox.microsoft.com';
const publicHostname = 'devportal.microsoft.com';

const getCurrentEnvironment = (): Environment => {
    // TODO: add more hostnames as they become known
    const hostname = window.location.hostname.toLowerCase();
    switch (hostname) {
        case developmentDevBoxHostname:
        case developmentHostname:
            return Environment.Development;
        case localHostname:
            return Environment.Local;
        case publicDevBoxHostname:
        case publicHostname:
        default:
            return Environment.Public;
    }
};

export const currentEnvironment = getCurrentEnvironment();

const getIsLocalEnvironment = (): boolean => currentEnvironment === Environment.Local;

export const isLocalEnvironment = getIsLocalEnvironment();

const getIsLocalOrDevelopmentEnvironment = (): boolean =>
    isLocalEnvironment || currentEnvironment === Environment.Development;

export const isLocalOrDevelopmentEnvironment = getIsLocalOrDevelopmentEnvironment();

const getIsDevPortalEnvironment = (): boolean => {
    if (isLocalEnvironment) {
        return true;
    }

    const hostname = window.location.hostname.toLowerCase();
    switch (hostname) {
        case developmentHostname:
        case publicHostname:
            return true;
        default:
            return false;
    }
};

export const isDevPortalEnvironment = getIsDevPortalEnvironment();

export const getUseDefaultMessages = (): boolean => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('disableDefaultMessages') !== 'true';
};

export const getApplicationMode = (): ApplicationMode => {
    const pathname = getPathnameFromCurrentLocation();

    switch (pathname) {
        case ApplicationModePathname.SingleDevCenter:
            return ApplicationMode.SingleDevCenter;
        case ApplicationModePathname.AllDevCentersInTenant:
        default:
            return ApplicationMode.AllDevCentersInTenant;
    }
};

export const applicationMode = getApplicationMode();
