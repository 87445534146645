import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../../models/common';
import { getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { discoverPoolsForDevBoxes } from '../../../actions/pool/pool-action-creators';
import {
    loadSecondaryDevBoxCardContent,
    loadSecondaryDevBoxCardContentError,
    loadSecondaryDevBoxCardContentFailed,
    loadSecondaryDevBoxCardContentSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadSecondaryDevBoxCardContent } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';

export function* loadSecondaryDevBoxCardContentSaga(action: LoadSecondaryDevBoxCardContent): SagaIterator {
    const startTime = new Date();

    try {
        const result: AggregatedResult = yield putAndAwait(discoverPoolsForDevBoxes());

        if (isAggregatedFailure(result)) {
            const { failures } = result;
            const failure = aggregateFailureResponses(FailureOperation.LoadSecondaryDevBoxCardContent, ...failures);
            yield put(loadSecondaryDevBoxCardContentFailed({ failure }));
        } else {
            yield put(loadSecondaryDevBoxCardContentSuccess());
        }

        yield resolveAction(action, result);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadSecondaryDevBoxCardContent,
            startTime,
            result.outcome,
            isAggregatedSuccess(result) ? undefined : { errorCodes: getErrorCodes(result) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadSecondaryDevBoxCardContent);
        yield put(loadSecondaryDevBoxCardContentError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadSecondaryDevBoxCardContent,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadSecondaryDevBoxCardContentListenerSaga(): SagaIterator {
    yield takeLeading(loadSecondaryDevBoxCardContent, loadSecondaryDevBoxCardContentSaga);
}
