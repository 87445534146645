import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ListPoolsResponse, listPools } from '../../../data/services/data-plane-api/pool';
import { ClientError, FailureOperation, isSuccessResponse } from '../../../models/common';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    listPools as listPoolsActionCreator,
    listPoolsError,
    listPoolsFailed,
    listPoolsSuccess,
} from '../../actions/pool/pool-action-creators';
import { ListPoolsAction } from '../../actions/pool/pool-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listPoolsSaga(action: ListPoolsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListPoolsResponse = yield call(listPools, id, accessToken, activityId);

        if (isSuccessResponse(response)) {
            yield put(listPoolsSuccess({ id, result: response.data }, meta));
        } else {
            yield put(listPoolsFailed({ failure: response, id }, meta));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListPools);
        yield put(listPoolsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listPoolsListenerSaga(): SagaIterator {
    yield takeEvery(listPoolsActionCreator, listPoolsSaga);
}
