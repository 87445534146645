export const compareNumbers = (a: number | undefined, b: number | undefined): number => {
    if (a === undefined && b === undefined) {
        return 0;
    } else if (a === undefined) {
        return -1;
    } else if (b === undefined) {
        return 1;
    }

    return a < b ? -1 : a > b ? 1 : 0;
};

export const isNumber = (value: unknown): value is number => typeof value === 'number';
