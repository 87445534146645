import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ReadOnlyControl } from '../../../common/form/read-only-control';
import { EnvironmentDefinitionBooleanParameterViewModel } from '../models';
import { ParameterTypeControlProps } from './models';

const messages = defineMessages({
    booleanParameterControlTrue: {
        id: 'BooleanParameterControl_True',
        defaultMessage: 'true',
        description: 'Boolean value true',
    },
    booleanParameterControlFalse: {
        id: 'BooleanParameterControl_False',
        defaultMessage: 'false',
        description: 'Boolean value false',
    },
});

interface BooleanControlProps
    extends Omit<ParameterTypeControlProps<boolean>, 'dropdownPlaceholder' | 'textfieldPlaceholder'> {
    parameter: EnvironmentDefinitionBooleanParameterViewModel;
}

const getSelectedOption = (value: string | undefined) => value === 'true';

const getSelectedKey = (value: boolean) => (value ? 'true' : 'false');

export const BooleanParameterControl: React.FC<BooleanControlProps> = (props) => {
    const { onChange, value, ariaLabel, parameter, disabled } = props;
    const { required, readOnly, name } = parameter;

    const { formatMessage } = useIntl();

    const getSelectedOptionText = React.useCallback(
        (value: boolean) =>
            value
                ? formatMessage(messages.booleanParameterControlTrue)
                : formatMessage(messages.booleanParameterControlFalse),
        [formatMessage]
    );

    const options: IChoiceGroupOption[] = React.useMemo(
        () => [
            { key: 'true', text: getSelectedOptionText(true) },
            { key: 'false', text: getSelectedOptionText(false) },
        ],
        [getSelectedOptionText]
    );

    const onChangeCallback = React.useCallback(
        (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
            const booleanOption = getSelectedOption(option?.key);
            onChange(booleanOption);
        },
        [name, onChange]
    );

    const selectedKey = React.useMemo(() => (value !== undefined ? getSelectedKey(value) : undefined), [value]);
    const readOnlyValue = React.useMemo(
        () => (value !== undefined ? getSelectedOptionText(value) : undefined),
        [value]
    );

    if (readOnly) {
        return <ReadOnlyControl label={name} value={readOnlyValue} required={required} disabled={disabled} />;
    }

    return (
        <ChoiceGroup
            selectedKey={selectedKey}
            label={name}
            options={options}
            disabled={disabled}
            onChange={onChangeCallback}
            aria-label={ariaLabel}
            required={required}
        />
    );
};
export default BooleanParameterControl;
