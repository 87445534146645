import { ClientError } from '../../models/common';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../string';
import { tryOrDefault } from '../try-or-default';

// Note: not sharing this type b/w create and parse because some properties are required in this context which aren't in
// the parse context.
interface ResourceComponents {
    name: string;
    providerNamespace: string;
    type: string;
}

interface SubResourceComponents {
    name: string;
    providerNamespace?: string;
    type: string;
}

const createResourceSubstring = (resource: ResourceComponents) => {
    const { name, providerNamespace, type } = resource;

    if (isUndefinedOrWhiteSpace(name)) {
        throw new ClientError('resource name for resource ID cannot be undefined or white space.');
    }

    if (isUndefinedOrWhiteSpace(providerNamespace)) {
        throw new ClientError('resource providerNamespace for resource ID cannot be undefined or white space.');
    }

    if (isUndefinedOrWhiteSpace(type)) {
        throw new ClientError('resource type for resource ID cannot be undefined or white space.');
    }

    return `/providers/${providerNamespace}/${type}/${name}`;
};

const createSubResourceSubstring = (subResource: SubResourceComponents) => {
    const { name, providerNamespace, type } = subResource;

    if (isUndefinedOrWhiteSpace(name)) {
        throw new ClientError('subresource name for resource ID cannot be undefined or white space.');
    }

    if (isUndefinedOrWhiteSpace(type)) {
        throw new ClientError('subresource type for resource ID cannot be undefined or white space.');
    }

    return isNotUndefinedOrWhiteSpace(providerNamespace)
        ? `/providers/${providerNamespace}/${type}/${name}`
        : `/${type}/${name}`;
};

export const createResourceId = (
    subscriptionId: string,
    resourceGroupName: string,
    resource: ResourceComponents,
    subResource?: SubResourceComponents
): string => {
    if (isUndefinedOrWhiteSpace(subscriptionId)) {
        throw new ClientError('subscriptionId for resource ID cannot be undefined or white space.');
    }

    if (isUndefinedOrWhiteSpace(resourceGroupName)) {
        throw new ClientError('resourceGroupName for resource ID cannot be undefined or white space');
    }

    const resourceSubstring = createResourceSubstring(resource);
    const subResourceSubstring = subResource ? createSubResourceSubstring(subResource) : '';

    // We always normalize resource group names and subscription IDs to lower-case, as the IDs are case insensitive
    return `/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}${resourceSubstring}${subResourceSubstring}`;
};

export const tryCreateResourceId = tryOrDefault(createResourceId);
