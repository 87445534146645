import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import unauthorizedImage from '../../content/images/Blocked.svg';
import { MisconfiguredState } from './misconfigured-state';

interface GuestAccountsNotSupportedProps {
    organizationDisplayName: string;
}

const messages = defineMessages({
    unauthorizedImageAlt: {
        id: 'GuestAccountsNotSupported_UnauthorizedImage_Alt',
        defaultMessage: 'Guest accounts not supported',
        description: 'Alt text for "guest accounts not supported" image',
    },
    unauthorizedTitle: {
        id: 'GuestAccountsNotSupported_Title_Text',
        defaultMessage: 'Guest accounts not supported',
        description:
            "Title of message informing user that they can't use this feature of the app using a guest account.",
    },
    unauthorizedDescription: {
        id: 'GuestAccountsNotSupported_Description_Text',
        defaultMessage:
            'Your account is a guest in the {organizationDisplayName} organization. Azure guest accounts are not currently supported in Microsoft Dev Box. Please contact your administrator to request access through an account created in {organizationDisplayName}.',
        description:
            "Message informing user that they can't use this feature of the app using a guest account. {organizationDisplayName} should not be localized.",
    },
});

export const GuestAccountsNotSupported: React.FC<GuestAccountsNotSupportedProps> = React.memo(
    (props: GuestAccountsNotSupportedProps) => {
        const { organizationDisplayName } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Memoized data
        const descriptionMessageValues = React.useMemo(() => ({ organizationDisplayName }), [organizationDisplayName]);

        return (
            <MisconfiguredState
                title={formatMessage(messages.unauthorizedTitle)}
                description={formatMessage(messages.unauthorizedDescription, descriptionMessageValues)}
                imageSrc={unauthorizedImage}
                imageAlt={formatMessage(messages.unauthorizedImageAlt)}
            />
        );
    }
);

export default GuestAccountsNotSupported;
