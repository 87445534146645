import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { discoverEnvironmentDefinitionsForEnvironmentsListenerSaga } from './discover-environment-definitions-for-environments';
import { discoverEnvironmentDefinitionsForProjectsListenerSaga } from './discover-environment-definitions-for-projects';
import { listEnvironmentDefinitionsListenerSaga } from './list-environment-definitions';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(discoverEnvironmentDefinitionsForEnvironmentsListenerSaga),
        fork(discoverEnvironmentDefinitionsForProjectsListenerSaga),
        fork(listEnvironmentDefinitionsListenerSaga),
    ]);
}
