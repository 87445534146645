import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { EnvironmentDetailsPanelProps } from './environment-details-panel';

export type EnvironmentDetailsPanelContextProperties = Omit<
    EnvironmentDetailsPanelProps,
    'isOpen' | 'onDismiss' | 'locations'
>;

export const EnvironmentDetailsPanelContext = React.createContext<
    SurfaceContextWithProperties<EnvironmentDetailsPanelContextProperties>
>({ isOpen: false });

export const EnvironmentDetailsPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<EnvironmentDetailsPanelContextProperties>(
        EnvironmentDetailsPanelContext
    );
