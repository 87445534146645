import { Failure } from '../../models/common';
import { SerializableSet } from '../../types/serializable-set';
import { createInitializer } from '../../utilities/initializer';
import { InitializationStatusStore } from './initialization-status-store';

export interface ApplicationDataStore {
    dismissedContent: SerializableSet<string>;
    fatalError?: Failure;
}

export const ApplicationDataStore = createInitializer<ApplicationDataStore>({
    dismissedContent: SerializableSet<string>(),
});

export interface ApplicationStore {
    data: ApplicationDataStore;
    status: InitializationStatusStore;
}

export const ApplicationStore = createInitializer<ApplicationStore>({
    data: ApplicationDataStore(),
    status: InitializationStatusStore(),
});
