import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { listTenants, selectTenant } from './tenant-action-creators';

/**
 * Action types
 */

type TenantActionName =
    | ActionName<'ListTenants', 'Error' | 'Failed' | 'Success'>
    | ActionName<'SelectTenant', 'Error' | 'Redirecting'>;

export type TenantActionType =
    | ActionType<'LIST_TENANTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'SELECT_TENANT', '_ERROR' | '_REDIRECTING'>;

export const TenantActionType: UnionValueMap<TenantActionName, TenantActionType> = {
    ListTenants: 'LIST_TENANTS',
    ListTenantsError: 'LIST_TENANTS_ERROR',
    ListTenantsFailed: 'LIST_TENANTS_FAILED',
    ListTenantsSuccess: 'LIST_TENANTS_SUCCESS',
    SelectTenant: 'SELECT_TENANT',
    SelectTenantError: 'SELECT_TENANT_ERROR',
    SelectTenantRedirecting: 'SELECT_TENANT_REDIRECTING',
};

/**
 * Action shorthands
 */

export type ListTenantsAction = ReturnType<typeof listTenants>;
export type SelectTenantAction = ReturnType<typeof selectTenant>;
