import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    getScreenSelector,
    IDialogContentProps,
    IModalProps,
    IStackTokens,
    makeStyles,
    PrimaryButton,
    Stack,
} from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getSemanticColor } from '../../../utilities/styles';
import { PoolViewModel } from '../models';
import SelectDevBoxPoolDetailsList from './select-dev-box-pool-details-list';

interface SelectDevBoxPoolDialogProps {
    onDismiss: () => void;
    onChange: (selectedPool: PoolViewModel | undefined) => void;
    showDialog: boolean;
    pools: PoolViewModel[];
    selectedPool: PoolViewModel | undefined;
}

const messages = defineMessages({
    selectDevBoxPoolDialogHeaderText: {
        id: 'SelectDevBoxPoolDialog_Header_Text',
        defaultMessage: 'Select dev box pool',
        description: 'Text for the select dev box pool dialog title',
    },
    selectDevBoxPoolDialogSelectButtonText: {
        id: 'SelectDevBoxPoolDialog_SelectButton_Text',
        defaultMessage: 'Select',
        description: 'Text for the select dev box pool dialog select button',
    },
    selectDevBoxPoolDialogSelectButtonAriaLabel: {
        id: 'SelectDevBoxPoolDialog_SelectButton_AriaLabel',
        defaultMessage: 'Select the dev box pool',
        description: 'Aria label for the select dev box pool dialog select button',
    },
    selectDevBoxPoolDialogCancelButtonText: {
        id: 'SelectDevBoxPoolDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the select dev box pool dialog cancel button',
    },
    selectDevBoxPoolDialogCancelButtonAriaLabel: {
        id: 'SelectDevBoxPoolDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel button for the dev box pool dialog',
        description: 'Aria label for the select dev box pool dialog cancel button',
    },
    selectDevBoxPoolDialogCloseButtonAriaLabel: {
        id: 'SelectDevBoxPoolDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close button for the dev box pool dialog',
        description: 'Aria label for the select dev box pool dialog close button icon',
    },
});

/**
 * Style Section
 */

const dialogModalMainMaxWidth = 'unset !important';
const useSelectDevBoxTypeDialogModalStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: getSemanticColor(theme, 'selectDevBoxTypeDialogBackground'),

        [getScreenSelector(1440, undefined)]: {
            maxWidth: 'calc(100vw - 64px)',
        },

        [getScreenSelector(undefined, 1440)]: {
            maxWidth: dialogModalMainMaxWidth,
            width: 'calc(100vw - 64px)',
        },
    },
}));

const useSelectDevBoxTypeDialogFooterStyles = makeStyles({
    actions: {
        lineHeight: 0,
    },
});

const buttonsContainerTokens: IStackTokens = {
    childrenGap: 8,
};

/* END */

const SelectDevBoxPoolDialogComponent: React.FC<SelectDevBoxPoolDialogProps> = (props: SelectDevBoxPoolDialogProps) => {
    const { onDismiss, onChange, pools, selectedPool: initialSelectedPool } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const selectDevBoxTypeDialogModalStyles = useSelectDevBoxTypeDialogModalStyles();
    const selectDevBoxTypeDialogFooterStyles = useSelectDevBoxTypeDialogFooterStyles();

    // State hooks
    const [selectedPool, setSelectedPool] = React.useState<PoolViewModel | undefined>(initialSelectedPool);

    // Callback hooks
    const onDevBoxPoolSelectButtonClick = React.useCallback((): void => {
        onChange(selectedPool);
        onDismiss();
    }, [selectedPool, onChange, onDismiss]);

    // Memo hooks
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            closeButtonAriaLabel: formatMessage(messages.selectDevBoxPoolDialogCloseButtonAriaLabel),
        }),
        [formatMessage]
    );

    const dialogContentProps: IDialogContentProps = React.useMemo(
        () => ({
            type: DialogType.normal,
            title: formatMessage(messages.selectDevBoxPoolDialogHeaderText),
        }),
        [formatMessage]
    );

    return (
        <Dialog
            hidden={false}
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            styles={selectDevBoxTypeDialogModalStyles}
        >
            <SelectDevBoxPoolDetailsList setSelectedPool={setSelectedPool} pools={pools} selectedPool={selectedPool} />
            <DialogFooter styles={selectDevBoxTypeDialogFooterStyles}>
                <Stack horizontal horizontalAlign="end" tokens={buttonsContainerTokens}>
                    <Stack.Item>
                        <PrimaryButton
                            text={formatMessage(messages.selectDevBoxPoolDialogSelectButtonText)}
                            ariaLabel={formatMessage(messages.selectDevBoxPoolDialogSelectButtonAriaLabel)}
                            onClick={onDevBoxPoolSelectButtonClick}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            text={formatMessage(messages.selectDevBoxPoolDialogCancelButtonText)}
                            ariaLabel={formatMessage(messages.selectDevBoxPoolDialogCancelButtonAriaLabel)}
                            onClick={onDismiss}
                        />
                    </Stack.Item>
                </Stack>
            </DialogFooter>
        </Dialog>
    );
};

const SelectDevBoxPoolDialogWrapper: React.FC<SelectDevBoxPoolDialogProps> = (props: SelectDevBoxPoolDialogProps) => {
    const { showDialog } = props;

    if (!showDialog) {
        return <></>;
    }

    return <SelectDevBoxPoolDialogComponent {...props} />;
};

export const SelectDevBoxPoolDialog = SelectDevBoxPoolDialogWrapper;
export default SelectDevBoxPoolDialog;
