import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    getClosestRDGatewayRegion,
    loadDevBoxRegionRecommendations,
    loadDevBoxRegionRecommendationsComplete,
    loadDevBoxRegionRecommendationsError,
    loadDevBoxRegionRecommendationsFailed,
} from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-action-creators';
import { LoadDevBoxRegionRecommendationsAction } from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

function* loadDevBoxRegionRecommendationsSaga(action: LoadDevBoxRegionRecommendationsAction) {
    try {
        const rdGatewayRegionResponse: DataResponse<string> = yield putAndAwait(getClosestRDGatewayRegion());

        if (isFailureResponse(rdGatewayRegionResponse)) {
            yield resolveAction(action, rdGatewayRegionResponse);
            yield put(loadDevBoxRegionRecommendationsFailed({ failure: rdGatewayRegionResponse }));
            return;
        }

        // Call region recommendation API here (when it is done)

        yield resolveAction(action, { succeeded: true });
        yield put(loadDevBoxRegionRecommendationsComplete());
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadDevBoxRegionRecommendations);
        yield put(loadDevBoxRegionRecommendationsError({ error }));
        yield rejectAction(action, error);
    }
}

export function* loadDevBoxRegionRecommendationsListenerSaga(): SagaIterator {
    yield takeLeading(loadDevBoxRegionRecommendations, loadDevBoxRegionRecommendationsSaga);
}
