import { FontSizes, Spinner, SpinnerSize, Stack, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { PropsWithChildren } from '../../types/props-with-children';
import { AriaLiveWrapper } from './accessibility/aria-live-wrapper';

type InProgressOperationMessageProps = PropsWithChildren<{
    ariaLabel: string | undefined;
}>;

const useInProgressOperationMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: 16,
    },
});

const useSpinnerStyles = makeStyles({
    root: {
        position: 'absolute',
        left: 12,
    },
});

export const InProgressOperationMessage: React.FC<InProgressOperationMessageProps> = (props) => {
    const { children, ariaLabel } = props;

    // Style hooks
    const inProgressOperationMessageContainerStyles = useInProgressOperationMessageContainerStyles();
    const spinnerStyles = useSpinnerStyles();

    return (
        <AriaLiveWrapper>
            <Stack horizontal>
                <Stack.Item>
                    <Spinner ariaLabel={ariaLabel} styles={spinnerStyles} size={SpinnerSize.small} />
                </Stack.Item>

                <Stack.Item styles={inProgressOperationMessageContainerStyles}>{children}</Stack.Item>
            </Stack>
        </AriaLiveWrapper>
    );
};

export default InProgressOperationMessage;
