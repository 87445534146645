/**
 * Composed selectors
 */

import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagName } from '../../../constants/features';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { keys } from '../../../utilities/serializable-map';
import { StoreStateSelector, isStatusSuccessful } from '../../selector/common';
import { getStatusForDiscoverProjectPermissions } from '../../selector/permission-selectors';
import { getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead } from '../../selector/project-from-discovery-service-selectors';
import {
    getProjectsAuthorizedForDevBoxReadByDataPlaneId,
    getProjectsByDataPlaneId,
} from '../../selector/project-selectors';

/**
 * Application state selectors
 */

export const getIdsForProjectsRequiringDevBoxes: StoreStateSelector<string[]> = createSelector(
    [
        getStatusForDiscoverProjectPermissions,
        getProjectsAuthorizedForDevBoxReadByDataPlaneId,
        getProjectsFromDiscoveryServiceAuthorizedForDevBoxRead,
        getProjectsByDataPlaneId,
    ],
    (
        discoverProjectPermissionsStatus,
        projectsAuthorizedForDevBoxRead,
        projectsFromDiscoveryServiceAuthorizedForDevBoxRead,
        allProjects
    ) => {
        const isDiscoveryFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        // Still want to list dev boxes if permission discovery fails, just try to list for all projects
        if (isDiscoveryFeatureFlagEnabled) {
            return keys(projectsFromDiscoveryServiceAuthorizedForDevBoxRead);
        }
        if (isStatusSuccessful(discoverProjectPermissionsStatus)) {
            return keys(projectsAuthorizedForDevBoxRead);
        } else {
            return keys(allProjects);
        }
    }
);
