export const tryOrDefault =
    <TFunc extends (...args: Parameters<TFunc>) => ReturnType<TFunc>>(
        fn: TFunc,
        defaultValue: ReturnType<TFunc> | undefined = undefined
    ): ((...args: Parameters<TFunc>) => ReturnType<TFunc> | undefined) =>
    (...args) => {
        try {
            return fn(...args);
        } catch {
            return defaultValue;
        }
    };
