import { ProjectEnvironmentTypePermissionRecord, ProjectPermissionRecord } from '../../../models/permission';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    discoverPermissionsForProjectEnvironmentTypes,
    discoverPermissionsForProjects,
    getProjectEnvironmentTypePermissions,
    getProjectPermissions,
} from './permission-action-creators';

/**
 * Action types
 */

type PermissionActionName =
    | ActionName<'DiscoverPermissionsForProjectEnvironmentTypes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DiscoverPermissionsForProjects', 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetProjectEnvironmentTypePermissions', 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetProjectPermissions', 'Error' | 'Failed' | 'Success'>;

export type PermissionActionType =
    | ActionType<'DISCOVER_PERMISSIONS_FOR_PROJECT_ENVIRONMENT_TYPES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISCOVER_PERMISSIONS_FOR_PROJECTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_PROJECT_ENVIRONMENT_TYPE_PERMISSIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_PROJECT_PERMISSIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const PermissionActionType: UnionValueMap<PermissionActionName, PermissionActionType> = {
    DiscoverPermissionsForProjectEnvironmentTypes: 'DISCOVER_PERMISSIONS_FOR_PROJECT_ENVIRONMENT_TYPES',
    DiscoverPermissionsForProjectEnvironmentTypesError: 'DISCOVER_PERMISSIONS_FOR_PROJECT_ENVIRONMENT_TYPES_ERROR',
    DiscoverPermissionsForProjectEnvironmentTypesFailed: 'DISCOVER_PERMISSIONS_FOR_PROJECT_ENVIRONMENT_TYPES_FAILED',
    DiscoverPermissionsForProjectEnvironmentTypesSuccess: 'DISCOVER_PERMISSIONS_FOR_PROJECT_ENVIRONMENT_TYPES_SUCCESS',
    DiscoverPermissionsForProjects: 'DISCOVER_PERMISSIONS_FOR_PROJECTS',
    DiscoverPermissionsForProjectsError: 'DISCOVER_PERMISSIONS_FOR_PROJECTS_ERROR',
    DiscoverPermissionsForProjectsFailed: 'DISCOVER_PERMISSIONS_FOR_PROJECTS_FAILED',
    DiscoverPermissionsForProjectsSuccess: 'DISCOVER_PERMISSIONS_FOR_PROJECTS_SUCCESS',
    GetProjectEnvironmentTypePermissions: 'GET_PROJECT_ENVIRONMENT_TYPE_PERMISSIONS',
    GetProjectEnvironmentTypePermissionsError: 'GET_PROJECT_ENVIRONMENT_TYPE_PERMISSIONS_ERROR',
    GetProjectEnvironmentTypePermissionsFailed: 'GET_PROJECT_ENVIRONMENT_TYPE_PERMISSIONS_FAILED',
    GetProjectEnvironmentTypePermissionsSuccess: 'GET_PROJECT_ENVIRONMENT_TYPE_PERMISSIONS_SUCCESS',
    GetProjectPermissions: 'GET_PROJECT_PERMISSIONS',
    GetProjectPermissionsError: 'GET_PROJECT_PERMISSIONS_ERROR',
    GetProjectPermissionsFailed: 'GET_PROJECT_PERMISSIONS_FAILED',
    GetProjectPermissionsSuccess: 'GET_PROJECT_PERMISSIONS_SUCCESS',
};

/**
 * Payloads
 */

export type GetProjectEnvironmentTypePermissionsSuccessPayload = IndexedPayload &
    ResultPayload<ProjectEnvironmentTypePermissionRecord>;

export type GetProjectPermissionsSuccessPayload = IndexedPayload & ResultPayload<ProjectPermissionRecord>;

/**
 * Action shorthands
 */

export type DiscoverPermissionsForProjectEnvironmentTypesAction = ReturnType<
    typeof discoverPermissionsForProjectEnvironmentTypes
>;

export type DiscoverPermissionsForProjectsAction = ReturnType<typeof discoverPermissionsForProjects>;
export type GetProjectEnvironmentTypePermissionsAction = ReturnType<typeof getProjectEnvironmentTypePermissions>;
export type GetProjectPermissionsAction = ReturnType<typeof getProjectPermissions>;
