import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListProjectEnvironmentTypesResponse } from '../../../data/services/resource-manager/project-environment-type';
import {
    AggregatedSuccess,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverProjectEnvironmentTypesFromDataplane,
    discoverProjectEnvironmentTypesFromDataplaneError,
    discoverProjectEnvironmentTypesFromDataplaneFailed,
    discoverProjectEnvironmentTypesFromDataplaneSuccess,
    listProjectEnvironmentTypesFromDataplane,
} from '../../actions/project-environment-type/project-environment-type-action-creators';
import { DiscoverProjectEnvironmentTypesFromDataplaneAction } from '../../actions/project-environment-type/project-environment-type-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getDataPlaneIdsForProjects } from './selectors';

export function* discoverProjectEnvironmentTypesFromDataplaneSaga(
    action: DiscoverProjectEnvironmentTypesFromDataplaneAction
): SagaIterator {
    const startTime = new Date();

    try {
        const projectDataplaneIds: string[] = yield select(getDataPlaneIdsForProjects);

        // Exit immediately if no dataplane IDs are available
        if (projectDataplaneIds.length < 1) {
            yield put(discoverProjectEnvironmentTypesFromDataplaneSuccess());
            yield resolveAction(action, AggregatedSuccess());
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DiscoverProjectEnvironmentTypes,
                startTime,
                AsyncOutcome.Success
            );
            return;
        }

        const responses: ListProjectEnvironmentTypesResponse[] = yield all(
            projectDataplaneIds.map((id) => putAndAwait(listProjectEnvironmentTypesFromDataplane({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(
                FailureOperation.DiscoverProjectEnvironmentTypesFromDataPlane,
                ...failures
            );
            yield put(discoverProjectEnvironmentTypesFromDataplaneFailed({ failure }));
        } else {
            yield put(discoverProjectEnvironmentTypesFromDataplaneSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverProjectEnvironmentTypesFromDataPlane,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.DiscoverProjectEnvironmentTypesFromDataPlane
        );

        yield put(discoverProjectEnvironmentTypesFromDataplaneError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverProjectEnvironmentTypesFromDataPlane,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverProjectEnvironmentTypesFromDataplaneListenerSaga(): SagaIterator {
    yield takeLeading(discoverProjectEnvironmentTypesFromDataplane, discoverProjectEnvironmentTypesFromDataplaneSaga);
}
