import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    listEnvironmentOperations,
    listEnvironmentOperationsError,
    listEnvironmentOperationsFailed,
    listEnvironmentOperationsSuccess,
} from '../actions/environment-operation/environment-operation-action-creators';
import { environmentOperationAdapter } from '../adapters/environment-operation-adapter';
import {
    EnvironmentOperationDataStore,
    EnvironmentOperationStatusStore,
    EnvironmentOperationsStore,
} from '../store/environment-operation-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';

export const environmentOperationReducer = combineReducers<EnvironmentOperationsStore>({
    data: createReducer(EnvironmentOperationDataStore(), (builder) => {
        builder.addCase(listEnvironmentOperationsSuccess, (store, action) => {
            const { result } = getPayload(action);

            environmentOperationAdapter.setMany(
                store.operations,
                result.map((operation) => Entity(operation.uri, operation))
            );
        });
    }),

    status: combineReducers<EnvironmentOperationStatusStore>({
        listEnvironmentOperations: createIndexedStatusReducer({
            inProgress: listEnvironmentOperations,
            error: listEnvironmentOperationsError,
            failed: listEnvironmentOperationsFailed,
            success: listEnvironmentOperationsSuccess,
        }),
    }),
});
