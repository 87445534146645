import { SerializableMap } from '../../types/serializable-map';
import { CapitalizedObjectNameMap } from '../../types/union-map';
import { ProjectViewModel } from '../common/models';
import { AddOrUpdateExpirationFormData } from '../environment/expiration-date/add-or-update-expiration-form-field-group/models';
import {
    EnvironmentDefinitionParameterValue,
    EnvironmentDefinitionParameterViewModel,
} from '../environment/parameters-field/models';

export interface AddEnvironmentFormData extends AddOrUpdateExpirationFormData {
    environmentName: string;
    selectedProject: ProjectViewModel | undefined;
    selectedEnvironmentType: EnvironmentTypeViewModel | undefined;
    selectedEnvironmentDefinition: EnvironmentDefinitionViewModel | undefined;
    selectedParameters: SerializableMap<EnvironmentDefinitionParameterValue | undefined>;
}

export const AddEnvironmentFormFieldName: CapitalizedObjectNameMap<AddEnvironmentFormData> = {
    EnvironmentName: 'environmentName',
    SelectedProject: 'selectedProject',
    SelectedEnvironmentType: 'selectedEnvironmentType',
    SelectedEnvironmentDefinition: 'selectedEnvironmentDefinition',
    SelectedParameters: 'selectedParameters',
    EnableExpiration: 'enableExpiration',
    EnvironmentExpiration: 'environmentExpiration',
};

export type AddEnvironmentFormErrorsByField = {
    [key in keyof AddEnvironmentFormData]?: string | SerializableMap<string> | boolean;
};

export interface EnvironmentTypeViewModel {
    id: string;
    name: string;
}

export interface EnvironmentDefinitionViewModel {
    id: string;
    name: string;
    catalogName: string;
    isDuplicateName: boolean;
    description: string | undefined;
    parameters: EnvironmentDefinitionParameterViewModel[];
}

export type ProjectToProjectEnvironmentTypeViewModelMap = SerializableMap<EnvironmentTypeViewModel[]>;
export type ProjectToEnvironmentDefinitionViewModelMap = SerializableMap<EnvironmentDefinitionViewModel[]>;

export const ProjectToProjectEnvironmentTypeViewModelMap = (): ProjectToProjectEnvironmentTypeViewModelMap => ({});
export const ProjectToEnvironmentDefinitionViewModelMap = (): ProjectToEnvironmentDefinitionViewModelMap => ({});
