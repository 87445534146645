import { UnionValueMap } from '../../../../types/union-map';
import { ActionName, ActionType } from '../../core-actions';
import {
    initializeSingleDevCenterHome,
    loadDevBoxesForSingleDevCenter,
    loadProjectsForDevCenter,
    loadResourcesForSingleDevCenter,
    setSingleDevCenterUri,
} from './single-dev-center-action-creators';

/**
 * Action names
 */

type SingleDevCenterActionNamePrefix =
    | 'InitializeSingleDevCenterHome'
    | 'LoadResourcesForSingleDevCenter'
    | 'LoadProjectsForSingleDevCenter'
    | 'LoadDevBoxesForSingleDevCenter';

type SingleDevCenterActionName =
    | 'SetSingleDevCenterUri'
    | ActionName<'SetSingleDevCenterUriFromInput', 'Error'>
    | ActionName<SingleDevCenterActionNamePrefix, 'Error' | 'Failed' | 'Success'>;

/**
 * Action types
 */

type SingleDevCenterActionTypePrefix =
    | 'INITIALIZE_SINGLE_DEV_CENTER_HOME'
    | 'LOAD_RESOURCES_FOR_SINGLE_DEV_CENTER'
    | 'LOAD_PROJECTS_FOR_SINGLE_DEV_CENTER'
    | 'LOAD_DEV_BOXES_FOR_SINGLE_DEV_CENTER';

type SingleDevCenterActionType =
    | 'SET_SINGLE_DEV_CENTER_URI'
    | ActionType<'SET_SINGLE_DEV_CENTER_URI_FROM_INPUT', '_ERROR'>
    | ActionType<SingleDevCenterActionTypePrefix, '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const SingleDevCenterActionType: UnionValueMap<SingleDevCenterActionName, SingleDevCenterActionType> = {
    SetSingleDevCenterUri: 'SET_SINGLE_DEV_CENTER_URI',

    SetSingleDevCenterUriFromInput: 'SET_SINGLE_DEV_CENTER_URI_FROM_INPUT',
    SetSingleDevCenterUriFromInputError: 'SET_SINGLE_DEV_CENTER_URI_FROM_INPUT_ERROR',

    InitializeSingleDevCenterHome: 'INITIALIZE_SINGLE_DEV_CENTER_HOME',
    InitializeSingleDevCenterHomeError: 'INITIALIZE_SINGLE_DEV_CENTER_HOME_ERROR',
    InitializeSingleDevCenterHomeFailed: 'INITIALIZE_SINGLE_DEV_CENTER_HOME_FAILED',
    InitializeSingleDevCenterHomeSuccess: 'INITIALIZE_SINGLE_DEV_CENTER_HOME_SUCCESS',

    LoadResourcesForSingleDevCenter: 'LOAD_RESOURCES_FOR_SINGLE_DEV_CENTER',
    LoadResourcesForSingleDevCenterError: 'LOAD_RESOURCES_FOR_SINGLE_DEV_CENTER_ERROR',
    LoadResourcesForSingleDevCenterFailed: 'LOAD_RESOURCES_FOR_SINGLE_DEV_CENTER_FAILED',
    LoadResourcesForSingleDevCenterSuccess: 'LOAD_RESOURCES_FOR_SINGLE_DEV_CENTER_SUCCESS',

    LoadProjectsForSingleDevCenter: 'LOAD_PROJECTS_FOR_SINGLE_DEV_CENTER',
    LoadProjectsForSingleDevCenterError: 'LOAD_PROJECTS_FOR_SINGLE_DEV_CENTER_ERROR',
    LoadProjectsForSingleDevCenterFailed: 'LOAD_PROJECTS_FOR_SINGLE_DEV_CENTER_FAILED',
    LoadProjectsForSingleDevCenterSuccess: 'LOAD_PROJECTS_FOR_SINGLE_DEV_CENTER_SUCCESS',

    LoadDevBoxesForSingleDevCenter: 'LOAD_DEV_BOXES_FOR_SINGLE_DEV_CENTER',
    LoadDevBoxesForSingleDevCenterError: 'LOAD_DEV_BOXES_FOR_SINGLE_DEV_CENTER_ERROR',
    LoadDevBoxesForSingleDevCenterFailed: 'LOAD_DEV_BOXES_FOR_SINGLE_DEV_CENTER_FAILED',
    LoadDevBoxesForSingleDevCenterSuccess: 'LOAD_DEV_BOXES_FOR_SINGLE_DEV_CENTER_SUCCESS',
};

/**
 * Payloads
 */

export type DevCenterUriPayload = {
    devCenterUri: string;
};

/**
 * Action shorthands
 */

export type SetSingleDevCenterUriAction = ReturnType<typeof setSingleDevCenterUri>;

export type InitializeSingleDevCenterUriAction = ReturnType<typeof initializeSingleDevCenterHome>;

export type LoadResourcesForSingleDevCenterAction = ReturnType<typeof loadResourcesForSingleDevCenter>;

export type LoadProjectsForDevCenterAction = ReturnType<typeof loadProjectsForDevCenter>;

export type LoadDevBoxesForSingleDevCenterAction = ReturnType<typeof loadDevBoxesForSingleDevCenter>;
