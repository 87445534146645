import * as React from 'react';
import { SurfaceContext, SurfaceContextWithProperties } from '../../types/contexts';
import { PropsWithChildren } from '../../types/props-with-children';

export const createSurfaceContextProvider = (context: React.Context<SurfaceContext>): React.FC<PropsWithChildren> => {
    return (props: PropsWithChildren) => {
        const { children } = props;

        // Component state hooks
        const [isOpen, setIsOpen] = React.useState(false);

        // Callback hooks
        const closeSurface = React.useCallback(() => setIsOpen(false), []);
        const openSurface = React.useCallback(() => setIsOpen(true), []);

        // Memoized data
        const contextValue = React.useMemo<SurfaceContext>(
            () => ({
                closeSurface,
                isOpen,
                openSurface,
            }),
            [closeSurface, isOpen, openSurface]
        );

        return <context.Provider value={contextValue}>{children}</context.Provider>;
    };
};

export const createSurfaceContextWithPropertiesProvider = <TProperties,>(
    context: React.Context<SurfaceContextWithProperties<TProperties>>
): React.FC<PropsWithChildren> => {
    return (props: PropsWithChildren) => {
        const { children } = props;

        // Component state hooks
        const [isOpen, setIsOpen] = React.useState(false);
        const [properties, setProperties] = React.useState<TProperties>();

        // Callback hooks
        const closeSurface = React.useCallback(() => {
            setIsOpen(false);
            setProperties(undefined);
        }, []);

        const openSurface = React.useCallback((newProperties: TProperties) => {
            setIsOpen(true);
            setProperties(newProperties);
        }, []);

        // Memoized data
        const contextValue = React.useMemo<SurfaceContextWithProperties<TProperties>>(
            () => ({
                closeSurface,
                isOpen,
                openSurface,
                properties,
            }),
            [closeSurface, isOpen, openSurface, properties]
        );

        return <context.Provider value={contextValue}>{children}</context.Provider>;
    };
};
