import { SearchParameter } from '../constants/app';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from './string';
import { tryOrDefault } from './try-or-default';

export const clearSearchParameterFromCurrentLocation = (searchParameter: SearchParameter): URL =>
    clearSearchParameterFromUrl(window.location, searchParameter);

export const clearSearchParameterFromUrl = (url: Location | URL, searchParameter: SearchParameter): URL => {
    const searchParameters = new URLSearchParams(window.location.search);
    searchParameters.delete(searchParameter);

    return setSearchStringOnUrl(url, searchParameters);
};

export const clearSearchStringFromCurrentLocation = (): URL => clearSearchStringFromUrl(window.location);

export const clearSearchStringFromUrl = (url: Location | URL): URL => new URL(url.pathname, url.toString());

export const getSearchParameterFromCurrentLocation = (searchParameter: SearchParameter): string | undefined =>
    getSearchParameterFromUrl(window.location, searchParameter);

export const getPathnameFromCurrentLocation = (): string => window.location.pathname;

export const getSearchParameterFromUrl = (
    url: Location | URL,
    searchParameter: SearchParameter
): string | undefined => {
    const searchParameters = new URLSearchParams(url.search);

    return searchParameters.get(searchParameter) ?? undefined;
};

export const parseHostnameFromUrlString = (urlString: string): string => new URL(urlString).hostname;

export const parsePathnameFromUrlString = (urlString: string): string => new URL(urlString).pathname;

export const parseSearchFromUrlString = (urlString: string): string => new URL(urlString).search;

export const replaceCurrentLocation = (url: URL): void => {
    history.replaceState(undefined, '', url);
};

export const setSearchParameterOnCurrentLocation = (searchParameter: SearchParameter, value: string): URL =>
    setSearchParameterOnUrl(window.location, searchParameter, value);

export const setSearchParameterOnUrl = (url: Location | URL, searchParameter: SearchParameter, value: string): URL => {
    const searchParameters = new URLSearchParams(url.search);
    searchParameters.set(searchParameter, value);

    return setSearchStringOnUrl(url, searchParameters);
};

export const setSearchStringOnCurrentLocation = (searchParameters: URLSearchParams): URL =>
    setSearchStringOnUrl(window.location, searchParameters);

export const setSearchStringOnUrl = (url: Location | URL, searchParameters: URLSearchParams): URL => {
    const { pathname } = window.location;
    const search = searchParameters.toString();

    if (isUndefinedOrWhiteSpace(search)) {
        return clearSearchStringFromUrl(url);
    }

    return new URL(`${pathname}?${search}`, url.toString());
};

export const tryParseHostnameFromUrlString = tryOrDefault(parseHostnameFromUrlString);

export const tryParsePathnameFromUrlString = tryOrDefault(parsePathnameFromUrlString);

export const tryParseSearchFromUrlString = tryOrDefault(parseSearchFromUrlString);

// TODO [nipope] (Task #1921011): use these helpers everywhere instead of try/catching with `new URL(...)`
export const tryGetUrl = tryOrDefault((value: string) => new URL(value));

export const isUrl = (value: string): boolean => !!tryGetUrl(value);

const addMultiMonitorAndSourceParameters = (connectionString: string, useMultiMonitor: boolean): string | undefined => {
    if (isNotUndefinedOrWhiteSpace(connectionString)) {
        const url = new URL(connectionString);

        // Add the usemultimon parameter to the url
        if (useMultiMonitor) {
            url.searchParams.set('usemultimon', `${useMultiMonitor}`);
        }
        // Add the source parameter to the url
        url.searchParams.set('source', 'devportal');

        return `${url}`;
    } else {
        return undefined;
    }
};

export const tryAddMultiMonitorAndSourceParameters = tryOrDefault(addMultiMonitorAndSourceParameters);
