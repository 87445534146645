import * as React from 'react';
import { FilterContext } from '../../types/contexts';
import { PropsWithChildren } from '../../types/props-with-children';

export const createFilterContextProvider = <T,>(
    context: React.Context<FilterContext<T>>,
    setFilterToCurrentLocation: (filter: T | undefined) => void,
    getFilterFromCurrentLocation: () => T | undefined
): React.FC<PropsWithChildren> => {
    return (props: PropsWithChildren) => {
        const { children } = props;
        const { Provider } = context;

        // Component state hooks
        const [filter, setFilter] = React.useState<T | undefined>(getFilterFromCurrentLocation());

        const onFilterChange = React.useCallback(
            (value: T | undefined) => {
                setFilter(value);
                setFilterToCurrentLocation(value);
            },
            [setFilterToCurrentLocation]
        );

        // Memoized data
        const contextValue = React.useMemo<FilterContext<T>>(
            () => ({
                value: filter,
                onChange: onFilterChange,
            }),
            [filter, onFilterChange]
        );

        return <Provider value={contextValue}>{children}</Provider>;
    };
};
