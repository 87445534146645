import { ProjectEnvironmentTypeAbilities } from '../../../models/project-environment-type';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    discoverProjectEnvironmentTypesAbilities,
    getProjectEnvironmentTypeAbilities,
} from './project-environment-type-abilities-action-creators';

/**
 * Action types
 */

type ProjectEnvironmentTypeAbilitiesActionName =
    | ActionName<'DiscoverProjectEnvironmentTypesAbilities', 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetProjectEnvironmentTypeAbilities', 'Error' | 'Failed' | 'Success'>;

export type ProjectEnvironmentTypeAbilitiesActionType =
    | ActionType<'DISCOVER_PROJECT_ENVIRONMENT_TYPES_ABILITIES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_PROJECT_ENVIRONMENT_TYPE_ABILITIES', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const ProjectEnvironmentTypeAbilitiesActionType: UnionValueMap<
    ProjectEnvironmentTypeAbilitiesActionName,
    ProjectEnvironmentTypeAbilitiesActionType
> = {
    DiscoverProjectEnvironmentTypesAbilities: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_ABILITIES',
    DiscoverProjectEnvironmentTypesAbilitiesError: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_ABILITIES_ERROR',
    DiscoverProjectEnvironmentTypesAbilitiesFailed: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_ABILITIES_FAILED',
    DiscoverProjectEnvironmentTypesAbilitiesSuccess: 'DISCOVER_PROJECT_ENVIRONMENT_TYPES_ABILITIES_SUCCESS',
    GetProjectEnvironmentTypeAbilities: 'GET_PROJECT_ENVIRONMENT_TYPE_ABILITIES',
    GetProjectEnvironmentTypeAbilitiesError: 'GET_PROJECT_ENVIRONMENT_TYPE_ABILITIES_ERROR',
    GetProjectEnvironmentTypeAbilitiesFailed: 'GET_PROJECT_ENVIRONMENT_TYPE_ABILITIES_FAILED',
    GetProjectEnvironmentTypeAbilitiesSuccess: 'GET_PROJECT_ENVIRONMENT_TYPE_ABILITIES_SUCCESS',
};

/**
 * Payloads
 */

export type GetProjectEnvironmentTypeAbilitiesSuccessPayload = IndexedPayload &
    ResultPayload<ProjectEnvironmentTypeAbilities>;

/**
 * Action shorthands
 */

export type DiscoverProjectEnvironmentTypesAbilitiesAction = ReturnType<
    typeof discoverProjectEnvironmentTypesAbilities
>;
export type GetProjectEnvironmentTypeAbilitiesAction = ReturnType<typeof getProjectEnvironmentTypeAbilities>;
