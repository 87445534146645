import { makeStyles, Panel } from '@fluentui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/action-creator';
import { useDevMenuPanelContext } from '../../hooks/context/panels';
import { FeatureFlagSet } from '../../models/features';
import { setFeatures } from '../../redux/actions/features/features-action-creators';
import { getEnabledFeatures, getFrozenFeatures } from '../../redux/selector/features-selectors';
import { AppSemanticColor } from '../../themes/app-semantic-colors';
import { ReturnVoid } from '../../types/return-void';
import { getSemanticColor } from '../../utilities/styles';
import { DevMenuFormFieldGroup } from './dev-menu-form-field-group';
import { DevMenuPanelFooter } from './dev-menu-panel-footer';
import { DevMenuFormData } from './models';
import { getDevMenuPanelInitialValues, getFeatureFlagSetFromFormData } from './selectors';

interface DevMenuPanelComponentProps {
    enabledFeatures: FeatureFlagSet;
    frozenFeatures: FeatureFlagSet;
    isOpen: boolean;
    onDismiss: () => void;
    onSubmit: ReturnVoid<typeof setFeatures>;
}

const messages = defineMessages({
    devMenuPanelHeaderText: {
        id: 'DevMenuPanel_Header_Text',
        defaultMessage: 'Feature flags',
        description: 'Header for the dev/feature flags menu',
    },
    devMenuPanelCloseButtonAriaLabel: {
        id: 'DevMenuPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for dev menu panel close button',
    },
});

/**
 * Styles
 */

const usePanelStyles = makeStyles((theme) => ({
    header: {
        marginBottom: '24px',
    },
    main: {
        marginTop: '48px',
    },
    overlay: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
    },
}));

/**
 * END Styles
 */

const DevMenuPanelComponent: React.FC<DevMenuPanelComponentProps> = (props: DevMenuPanelComponentProps) => {
    const { enabledFeatures, frozenFeatures, isOpen, onDismiss, onSubmit } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const panelStyles = usePanelStyles();

    // Callback hooks
    const onSubmitClicked = React.useCallback(
        (data: DevMenuFormData) => {
            onDismiss();

            const features = getFeatureFlagSetFromFormData(data);
            onSubmit({ features });
        },
        [onDismiss, onSubmit]
    );

    // Memoized data
    const initialValues = React.useMemo(
        () => getDevMenuPanelInitialValues(enabledFeatures, frozenFeatures),
        [enabledFeatures, frozenFeatures]
    );

    return (
        <Form<DevMenuFormData> initialValues={initialValues} onSubmit={onSubmitClicked}>
            {(formProps) => {
                const { form, pristine, valid } = formProps;
                const { reset, submit } = form;

                // Callback hooks
                const onPanelDismissed = React.useCallback(() => {
                    onDismiss();
                    reset();
                }, [onDismiss, reset]);

                const onRenderFooterContent = React.useCallback(
                    () => (
                        <DevMenuPanelFooter
                            isSubmitDisabled={pristine || !valid}
                            onCancelClicked={onPanelDismissed}
                            onSubmitClicked={submit}
                        />
                    ),
                    [onPanelDismissed, pristine, submit, valid]
                );

                return (
                    <Panel
                        closeButtonAriaLabel={formatMessage(messages.devMenuPanelCloseButtonAriaLabel)}
                        headerText={formatMessage(messages.devMenuPanelHeaderText)}
                        isFooterAtBottom
                        isLightDismiss
                        isOpen={isOpen}
                        onDismiss={onPanelDismissed}
                        onRenderFooterContent={onRenderFooterContent}
                        styles={panelStyles}
                    >
                        <DevMenuFormFieldGroup />
                    </Panel>
                );
            }}
        </Form>
    );
};

const DevMenuPanelContainer: React.FC = () => {
    // Application state hooks
    const enabledFeatures = useSelector(getEnabledFeatures);
    const frozenFeatures = useSelector(getFrozenFeatures);

    // Action hooks
    const onSubmit = useActionCreator(setFeatures);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useDevMenuPanelContext();

    return (
        <DevMenuPanelComponent
            enabledFeatures={enabledFeatures}
            frozenFeatures={frozenFeatures}
            isOpen={isOpen}
            onDismiss={closePanel}
            onSubmit={onSubmit}
        />
    );
};

export const DevMenuPanelContextWrapper: React.FC = () => {
    const { isOpen } = useDevMenuPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DevMenuPanelContainer />;
};

export default DevMenuPanelContextWrapper;
