import { FeatureFlagSet } from '../../models/features';
import { createInitializer } from '../../utilities/initializer';
import { InitializationStatusStore } from './initialization-status-store';

export interface FeaturesDataStore {
    /**
     * enabledFeatures: tracks which feature flags are currently enabled in the app.
     */
    enabledFeatures: FeatureFlagSet;

    /**
     * frozenFeatures: tracks which feature flags aren't allowed to be changed by the user. If true, that feature flag's
     * checkbox is disabled in the dev menu.
     */
    frozenFeatures: FeatureFlagSet;
}

export const FeaturesDataStore = createInitializer<FeaturesDataStore>({
    enabledFeatures: FeatureFlagSet(),
    frozenFeatures: FeatureFlagSet(),
});

export interface FeaturesStore {
    data: FeaturesDataStore;
    status: InitializationStatusStore;
}

export const FeaturesStore = createInitializer<FeaturesStore>({
    data: FeaturesDataStore(),
    status: InitializationStatusStore(),
});
