import { UnionMap, UnionValueMap } from '../../../../types/union-map';
import { MockScenarioConfiguration } from '../../../types';
import discoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsManyDevCenters from './discover-dev-box-control-plane-resources-in-tenant-many-pools-many-projects-many-dev-centers.json';
import discoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsOneDevCenter from './discover-dev-box-control-plane-resources-in-tenant-many-pools-many-projects-one-dev-center.json';
import discoverDevBoxControlPlaneResourcesInTenantManyPoolsOneProjectOneDevCenter from './discover-dev-box-control-plane-resources-in-tenant-many-pools-one-project-one-dev-center.json';
import discoverDevBoxControlPlaneResourcesInTenantNoPoolsNoProjects from './discover-dev-box-control-plane-resources-in-tenant-no-pools-no-projects.json';
import discoverDevBoxControlPlaneResourcesInTenantNoPoolsOneProject from './discover-dev-box-control-plane-resources-in-tenant-no-pools-one-project.json';
import discoverDevBoxControlPlaneResourcesInTenantOnePoolNoProjects from './discover-dev-box-control-plane-resources-in-tenant-one-pool-no-projects.json';
import discoverDevBoxControlPlaneResourcesInTenantOnePoolOneProjectOneDevCenter from './discover-dev-box-control-plane-resources-in-tenant-one-pool-one-project-one-dev-center.json';

export type ResourceManagerResourceGraphScenarioName =
    | 'DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsManyDevCenters'
    | 'DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsOneDevCenter'
    | 'DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsOneProjectOneDevCenter'
    | 'DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsNoProjects'
    | 'DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsOneProject'
    | 'DiscoverDevBoxControlPlaneResourcesInTenantOnePoolNoProjects'
    | 'DiscoverDevBoxControlPlaneResourcesInTenantOnePoolOneProjectOneDevCenter';

export const ResourceManagerResourceGraphScenarioName: UnionMap<ResourceManagerResourceGraphScenarioName> = {
    DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsManyDevCenters:
        'DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsManyDevCenters',
    DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsOneDevCenter:
        'DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsOneDevCenter',
    DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsOneProjectOneDevCenter:
        'DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsOneProjectOneDevCenter',
    DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsNoProjects:
        'DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsNoProjects',
    DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsOneProject:
        'DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsOneProject',
    DiscoverDevBoxControlPlaneResourcesInTenantOnePoolNoProjects:
        'DiscoverDevBoxControlPlaneResourcesInTenantOnePoolNoProjects',
    DiscoverDevBoxControlPlaneResourcesInTenantOnePoolOneProjectOneDevCenter:
        'DiscoverDevBoxControlPlaneResourcesInTenantOnePoolOneProjectOneDevCenter',
};

export const ResourceManagerResourceGraphScenarios: UnionValueMap<
    ResourceManagerResourceGraphScenarioName,
    MockScenarioConfiguration
> = {
    DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsManyDevCenters:
        discoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsManyDevCenters as MockScenarioConfiguration,
    DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsOneDevCenter:
        discoverDevBoxControlPlaneResourcesInTenantManyPoolsManyProjectsOneDevCenter as MockScenarioConfiguration,
    DiscoverDevBoxControlPlaneResourcesInTenantManyPoolsOneProjectOneDevCenter:
        discoverDevBoxControlPlaneResourcesInTenantManyPoolsOneProjectOneDevCenter as MockScenarioConfiguration,
    DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsNoProjects:
        discoverDevBoxControlPlaneResourcesInTenantNoPoolsNoProjects as MockScenarioConfiguration,
    DiscoverDevBoxControlPlaneResourcesInTenantNoPoolsOneProject:
        discoverDevBoxControlPlaneResourcesInTenantNoPoolsOneProject as MockScenarioConfiguration,
    DiscoverDevBoxControlPlaneResourcesInTenantOnePoolNoProjects:
        discoverDevBoxControlPlaneResourcesInTenantOnePoolNoProjects as MockScenarioConfiguration,
    DiscoverDevBoxControlPlaneResourcesInTenantOnePoolOneProjectOneDevCenter:
        discoverDevBoxControlPlaneResourcesInTenantOnePoolOneProjectOneDevCenter as MockScenarioConfiguration,
};
