import { MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ActionAbility,
    CannotCreateEnvironmentReason,
    EnvironmentCreateAbilityState,
} from '../../../redux/selector/display/create-ability/models';
import { PortalMessageBar } from '../../message-bar';

export interface EnvironmentCreateInfoMessageBarProps {
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
}

export const EnvironmentCreateInfoMessageBar: React.FC<EnvironmentCreateInfoMessageBarProps> = React.memo(
    (props: EnvironmentCreateInfoMessageBarProps) => {
        const { environmentCreateAbilityState } = props;

        // Component state hooks
        const [isMessageBarOpen, setIsMessageBarOpen] = React.useState(true);

        const onDismissMessageBar = React.useCallback(() => {
            setIsMessageBarOpen(false);
        }, []);

        // Render logic

        if (!isMessageBarOpen) {
            return <></>;
        }

        // If we are not an environment user / environments are not enabled, no message to display here
        if (environmentCreateAbilityState.reason === CannotCreateEnvironmentReason.IsNotEnvironmentUser) {
            return <></>;
        }

        // Failure to load resources has blocked us from creating environments, show error message bar
        if (environmentCreateAbilityState.createAbility === ActionAbility.FailedToLoad) {
            return (
                <PortalMessageBar onDismiss={onDismissMessageBar} messageBarType={MessageBarType.error}>
                    <FormattedMessage
                        id="InfoMessageBar_EnvironmentInfoMessage_FailedToLoad"
                        defaultMessage="We encountered an error loading your projects, and new environments cannot be created at this time. Please retry, or contact your admin if this problem persists."
                        description="Message informing user that we failed to load project resources and they can't create environments."
                    />
                </PortalMessageBar>
            );
        }

        return <></>;
    }
);

export default EnvironmentCreateInfoMessageBar;
