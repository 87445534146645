import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { ProjectFromDiscoveryService, ProjectResource } from '../../models/project';
import { createInitializer } from '../../utilities/initializer';
import { stringAdapter } from '../adapters/common/string-adapter';
import { projectResourceAdapter, projectsFromDiscoveryServiceAdapter } from '../adapters/project-adapters';
import { StatusStore } from './status-store';

type StatusName = 'listProjectsFromDiscoveryService';

export interface ProjectDataStore {
    dataPlaneIdsToResourceIds: EntityState<Entity<string>>;
    projects: EntityState<Entity<ProjectResource>>;
    projectsFromDiscoveryService: EntityState<Entity<ProjectFromDiscoveryService>>;
    resourceIdsToDataPlaneIds: EntityState<Entity<string>>;
}

export const ProjectDataStore = createInitializer<ProjectDataStore>({
    dataPlaneIdsToResourceIds: stringAdapter.getInitialState(),
    projects: projectResourceAdapter.getInitialState(),
    projectsFromDiscoveryService: projectsFromDiscoveryServiceAdapter.getInitialState(),
    resourceIdsToDataPlaneIds: stringAdapter.getInitialState(),
});

export type ProjectStatusStore = StatusStore<StatusName>;

export const ProjectStatusStore = createInitializer<ProjectStatusStore>({
    listProjectsFromDiscoveryService: Status(),
});

export interface ProjectStore {
    data: ProjectDataStore;
    status: ProjectStatusStore;
}

export const ProjectStore = createInitializer<ProjectStore>({
    data: ProjectDataStore(),
    status: ProjectStatusStore(),
});
