import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { getStorageValueListenerSaga } from './get-storage-value';
import { initializeStorageListenerSaga } from './initialize';
import { setStorageValueListenerSaga } from './set-storage-value';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(getStorageValueListenerSaga),
        fork(initializeStorageListenerSaga),
        fork(setStorageValueListenerSaga),
    ]);
}
