import { CustomizationGroupStatus } from '../models/customization';
import { UnionMap } from '../types/union-map';

export type CustomizationOperationName = 'Create';

export const CustomizationOperationName: UnionMap<CustomizationOperationName> = {
    Create: 'Create',
};

export const failedCustomizationGroupStatuses: CustomizationGroupStatus[] = [
    CustomizationGroupStatus.Failed,
    CustomizationGroupStatus.ValidationFailed,
];

export const workloadYamlName = 'workload.yaml';
export const devboxYamlName = 'devbox.yaml';
export const wingetConfigurationExtension = '.dsc.yaml';
