import { IButtonProps, IButtonStyles } from '@fluentui/react';
import { getSemanticColors } from '../../utilities/styles';

export const IconButtonStyles = (props: IButtonProps): Partial<IButtonStyles> => {
    const { theme } = props;

    if (!theme) {
        return {};
    }

    const [
        buttonBackgroundHovered,
        buttonBackgroundPressed,
        iconButtonBorderFocused,
        iconButtonText,
        iconButtonTextHovered,
        iconButtonTextPressed,
    ] = getSemanticColors(
        theme,
        'buttonBackgroundHovered',
        'buttonBackgroundPressed',
        'iconButtonBorderFocused',
        'iconButtonText',
        'iconButtonTextHovered',
        'iconButtonTextPressed'
    );

    return {
        icon: {
            color: iconButtonText,
        },
        iconExpanded: {
            color: iconButtonTextPressed,
        },
        iconExpandedHovered: {
            color: iconButtonTextPressed,
        },
        iconHovered: {
            color: iconButtonTextHovered,
        },
        iconPressed: {
            color: iconButtonTextPressed,
        },
        rootExpanded: {
            backgroundColor: buttonBackgroundPressed,
        },
        rootExpandedHovered: {
            backgroundColor: buttonBackgroundHovered,
        },
        rootFocused: {
            border: `1px solid ${iconButtonBorderFocused}`,
        },
        rootHovered: {
            backgroundColor: buttonBackgroundHovered,
        },
        rootPressed: {
            backgroundColor: buttonBackgroundPressed,
        },
    };
};
