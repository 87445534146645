import {
    EnableStatusContract,
    EnvironmentTypeAbilitiesContract,
    EnvironmentTypeAbilityAsAdminContract,
    EnvironmentTypeAbilityAsDeveloperContract,
    EnvironmentTypeContract,
    ProjectEnvironmentTypeResourceContractProperties,
} from '../data/contracts/project-environment-type';
import { SerializableMap } from '../types/serializable-map';
import { UnionMap } from '../types/union-map';
import { isString } from '../utilities/string';
import { Resource, ResourceProvisioningState } from './resource-manager';

/**
 * Application models
 */

export type EnvironmentType = EnvironmentTypeContract;

export type ProjectEnvironmentTypeProperties = Omit<
    ProjectEnvironmentTypeResourceContractProperties,
    'enableStatus' | 'provisioningState'
> & {
    provisioningState: ResourceProvisioningState;
    status?: EnableStatus;
};

export type ProjectEnvironmentTypeResource = Resource<ProjectEnvironmentTypeProperties>;

export type ProjectEnvironmentTypeFromDataPlane = EnvironmentType;

export type ProjectEnvironmentTypeAbilities = EnvironmentTypeAbilitiesContract;

export type ProjectEnvironmentTypeAbilityAsAdmin = EnvironmentTypeAbilityAsAdminContract;

export type ProjectEnvironmentTypeAbilityAsDeveloper = EnvironmentTypeAbilityAsDeveloperContract;

export const ProjectEnvironmentTypeAbilityAsAdmin: UnionMap<ProjectEnvironmentTypeAbilityAsAdmin> = {
    DeleteEnvironments: 'DeleteEnvironments',
    ManageEnvironmentActions: 'ManageEnvironmentActions',
    ReadEnvironmentActions: 'ReadEnvironmentActions',
    ReadEnvironmentOutputs: 'ReadEnvironmentOutputs',
    ReadEnvironments: 'ReadEnvironments',
    WriteEnvironments: 'WriteEnvironments',
};

export const ProjectEnvironmentTypeAbilityAsDeveloper: UnionMap<ProjectEnvironmentTypeAbilityAsDeveloper> = {
    DeleteEnvironments: 'DeleteEnvironments',
    ManageEnvironmentActions: 'ManageEnvironmentActions',
    ReadEnvironmentActions: 'ReadEnvironmentActions',
    ReadEnvironmentOutputs: 'ReadEnvironmentOutputs',
    ReadEnvironments: 'ReadEnvironments',
    WriteEnvironments: 'WriteEnvironments',
};

/**
 * Map types
 */

export type ProjectEnvironmentTypeMap = SerializableMap<ProjectEnvironmentTypeResource>;
export type ProjectToProjectEnvironmentTypeMap = SerializableMap<SerializableMap<ProjectEnvironmentTypeResource>>;

// TODO: add arguments to function a la serializable-map if they're ever needed
export const ProjectEnvironmentTypeMap = (): ProjectEnvironmentTypeMap => ({});
export const ProjectToProjectEnvironmentTypeMap = (): ProjectToProjectEnvironmentTypeMap => ({});

/**
 * Type unions
 */

export type EnableStatus = EnableStatusContract;

export const EnableStatus: UnionMap<EnableStatus> = {
    Disabled: 'Disabled',
    Enabled: 'Enabled',
};

/**
 * Type guards
 */

export const isProjectEnvironmentTypeProperties = (value: unknown): value is ProjectEnvironmentTypeProperties => {
    const properties = value as ProjectEnvironmentTypeProperties;

    return (
        (properties.deploymentTargetId === undefined || isString(properties.deploymentTargetId)) &&
        properties.provisioningState !== undefined &&
        (properties.status === undefined || isString(properties.status))
    );
};
