import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { DefaultLanguage, DefaultLocale, DefaultMarket, Language } from '../../../constants/localization';
import {
    LocaleMessageData,
    getConfiguredLanguageAndLocale,
    getMessagesForLocale,
    initLocaleData,
    parseMarketFromLocale,
} from '../../../language/languages';
import { ClientError, FailureOperation } from '../../../models/common';
import { LocaleData } from '../../../models/localization';
import {
    initializeLocalization,
    initializeLocalizationError,
    initializeLocalizationSuccess,
} from '../../actions/localization/localization-action-creators';
import { InitializeLocalizationAction } from '../../actions/localization/localization-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

export function* initializeLocalizationSaga(action: InitializeLocalizationAction): SagaIterator {
    try {
        // Initialize language module
        yield call(initLocaleData);

        // Try to automatically determine locale based on location or user's browser configuration
        const languageAndLocale: [Language, string] | undefined = yield call(getConfiguredLanguageAndLocale);

        // Locale isn't configured by user; fallback to default locale
        if (languageAndLocale === undefined) {
            // Note: it's safe to not account for undefined below; DefaultLocale is guaranteed to return non-undefined
            const defaultMessages: LocaleMessageData = yield call(getMessagesForLocale, DefaultLocale);

            const data: LocaleData = {
                language: DefaultLanguage,
                locale: DefaultLocale,
                market: DefaultMarket,
                messages: defaultMessages,
                usingDefaultAsFallback: true,
            };

            yield put(initializeLocalizationSuccess({ result: data }));
            yield resolveAction(action, { data, succeeded: true });
            return;
        }

        const [language, locale] = languageAndLocale;
        const market: string = yield call(parseMarketFromLocale, locale);
        const messages: LocaleMessageData = yield call(getMessagesForLocale, locale);
        const data: LocaleData = { language, locale, market, messages };

        yield put(initializeLocalizationSuccess({ result: data }));
        yield resolveAction(action, { data, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeLocalization);
        yield put(initializeLocalizationError({ error }));
        yield rejectAction(action, error);
    }
}

export function* initializeLocalizationListenerSaga(): SagaIterator {
    yield takeLeading(initializeLocalization, initializeLocalizationSaga);
}
