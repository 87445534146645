import { getTokensFromCatalogDataPlaneUri } from '../../../ids/catalog';
import { getTokensFromEnvironmentDefinitionDataPlaneUri } from '../../../ids/environment-definition';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { DataResponse, FailureOperation } from '../../../models/common';
import { EnvironmentDefinitionContract } from '../../contracts/environment-definition';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetEnvironmentDefinitionResponse = DataResponse<EnvironmentDefinitionContract>;
export type ListEnvironmentDefinitionsResponse = DataResponse<EnvironmentDefinitionContract[]>;

export const getEnvironmentDefinition = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetEnvironmentDefinitionResponse> => {
    const { catalogName, devCenter, environmentDefinitionName, projectName } =
        getTokensFromEnvironmentDefinitionDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetEnvironmentDefinition,

        whenUsingStableClient: (client) =>
            client.environments.getEnvironmentDefinition(
                projectName,
                catalogName,
                environmentDefinitionName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.environments.getEnvironmentDefinition(
                projectName,
                catalogName,
                environmentDefinitionName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listEnvironmentDefinitionsByCatalog = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListEnvironmentDefinitionsResponse> => {
    const { catalogName, devCenter, projectName } = getTokensFromCatalogDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListEnvironmentDefinitionsByCatalog,

        whenUsingStableClient: (client) =>
            client.environments.listEnvironmentDefinitionsByCatalog(
                projectName,
                catalogName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.environments.listEnvironmentDefinitionsByCatalog(
                projectName,
                catalogName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listEnvironmentDefinitionsByProject = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListEnvironmentDefinitionsResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListEnvironmentDefinitionsByProject,

        whenUsingStableClient: (client) =>
            client.environments.listEnvironmentDefinitionsByProject(
                projectName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.environments.listEnvironmentDefinitionsByProject(
                projectName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};
