import { ClientError, FailureOperation } from '../../models/common';
import { trackException } from '../telemetry/channel';

/**
 * parseJson
 */
export const parseJson = <T>(jsonString: string): T => {
    try {
        return JSON.parse(jsonString) as T;
    } catch (err) {
        const clientError = new ClientError(err, FailureOperation.DataPlaneErrorParsing);
        trackException(clientError);
        throw clientError;
    }
};

/**
 * tryParseJson
 */

export const tryParseJson = <T>(jsonString: string): T | undefined => {
    try {
        return parseJson<T>(jsonString);
    } catch (err) {
        // do nothing
    }

    return undefined;
};
