import * as React from 'react';
import { SurfaceContextWithProperties } from '../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../common/surface-context-provider';
import { RedeployEnvironmentPanelProps } from './redeploy-environment-panel';

export type RedeployEnvironmentPanelContextProperties = Omit<
    RedeployEnvironmentPanelProps,
    'isOpen' | 'onDismiss' | 'onRedeployEnvironmentSubmitted' | 'statusForDeployEnvironment'
>;

export const RedeployEnvironmentPanelContext = React.createContext<
    SurfaceContextWithProperties<RedeployEnvironmentPanelContextProperties>
>({ isOpen: false });

export const RedeployEnvironmentPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<RedeployEnvironmentPanelContextProperties>(
        RedeployEnvironmentPanelContext
    );
