import { UnionMap } from '../../types/union-map';

export type CombinedInfoMessageKind =
    | 'NoCreateResourcesForDevBoxesOrEnvironmentsMessage'
    | 'NoEnvironmentOrDevBoxWritePermissionsMessage'
    | 'None';

export const CombinedInfoMessageKind: UnionMap<CombinedInfoMessageKind> = {
    NoCreateResourcesForDevBoxesOrEnvironmentsMessage: 'NoCreateResourcesForDevBoxesOrEnvironmentsMessage',
    NoEnvironmentOrDevBoxWritePermissionsMessage: 'NoEnvironmentOrDevBoxWritePermissionsMessage',
    None: 'None',
};
