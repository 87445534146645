import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import { GetEnvironmentResponse, getEnvironment } from '../../../data/services/data-plane-api/environment';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { isEnvironmentInTerminalState } from '../../../utilities/environment';
import {
    pollNonTerminalEnvironment,
    pollNonTerminalEnvironmentComplete,
    pollNonTerminalEnvironmentError,
} from '../../actions/environment/environment-action-creators';
import { PollNonTerminalEnvironmentAction } from '../../actions/environment/environment-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getPollingInitialDelayMs, getPollingIntervalMs } from '../../utilities/poll-action';

export function* pollNonTerminalEnvironmentSaga(action: PollNonTerminalEnvironmentAction): SagaIterator {
    let shouldPoll = true;
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        yield delay(getPollingInitialDelayMs(action));

        const pollingInterval = getPollingIntervalMs(action);

        while (shouldPoll) {
            const accessToken: string = yield putAndAwait(
                getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
            );

            const response: GetEnvironmentResponse = yield call(getEnvironment, id, accessToken, activityId);

            if (isFailureResponse(response)) {
                // should we ignore / re-try under certain conditions or error codes?
                yield put(pollNonTerminalEnvironmentComplete({ id }, meta));
                yield resolveAction(action, response);

                return;
            }

            const { data } = response;
            shouldPoll = !isEnvironmentInTerminalState(data.provisioningState);

            if (shouldPoll) {
                yield delay(pollingInterval);
            } else {
                yield put(pollNonTerminalEnvironmentComplete({ id, result: response.data }, meta));
                yield resolveAction(action, response);
            }
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.PollNonTerminalEnvironment);
        yield put(pollNonTerminalEnvironmentError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* pollNonTerminalEnvironmentListenerSaga(): SagaIterator {
    yield takeEvery(pollNonTerminalEnvironment, pollNonTerminalEnvironmentSaga);
}
