import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { ChangeEnvironmentExpirationDialogProps } from './change-environment-expiration-dialog';

export type ChangeEnvironmentExpirationDialogProperties = Omit<
    ChangeEnvironmentExpirationDialogProps,
    'hidden' | 'onDismiss'
>;

export const ChangeEnvironmentExpirationDialogContext = React.createContext<
    SurfaceContextWithProperties<ChangeEnvironmentExpirationDialogProperties>
>({ isOpen: false });

export const ChangeEnvironmentExpirationDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<ChangeEnvironmentExpirationDialogProperties>(
        ChangeEnvironmentExpirationDialogContext
    );
