import { ITextFieldProps, TextField } from '@fluentui/react';
import React from 'react';

interface TextInputProps extends Omit<ITextFieldProps, 'onChange'> {
    onChange: (value: string | undefined) => void;
}

export const TextInput: React.FC<TextInputProps> = (props) => {
    const { onChange } = props;

    const onChangeHandler = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string): void => {
            onChange(value);
        },
        [onChange]
    );

    return <TextField {...props} onChange={onChangeHandler} />;
};

export default TextInput;
