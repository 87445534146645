import * as React from 'react';
import { useCallback } from 'react';
import { Form } from 'react-final-form';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FeatureFlagName } from '../../constants/features';
import { useActionCreator } from '../../hooks/action-creator';
import { useAddEnvironmentPanelContext } from '../../hooks/context/panels';
import { usePrevious } from '../../hooks/use-previous';
import { createEnvironmentDataPlaneUri } from '../../ids/environment';
import { getTokensFromProjectDataPlaneUri } from '../../ids/project';
import { Status } from '../../models/common';
import {
    addEnvironment,
    clearAddEnvironmentFailure,
} from '../../redux/actions/environment/environment-action-creators';
import { isStatusInProgress, isStatusSuccessful, isStatusUnsuccessful } from '../../redux/selector/common';
import { getDidSomeProjectFailToLoadEnvironmentCreateResources } from '../../redux/selector/display/load-state';
import { getEnvironmentIds, getStatusForAddEnvironment } from '../../redux/selector/environment-selectors';
import { getObjectId } from '../../redux/selector/identity-selectors';
import { ReturnVoid } from '../../types/return-void';
import { SerializableMap } from '../../types/serializable-map';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { hasNoValue } from '../../utilities/object';
import { get } from '../../utilities/serializable-map';
import { getDefaultTimeZone } from '../../utilities/time';
import { FormPanel } from '../common/form-panel';
import { ProjectViewModel } from '../common/models';
import { EnvironmentExpirationViewModel } from '../environment/expiration-date/add-or-update-expiration-form-field-group/models';
import { EnvironmentParametersPanelContent } from '../environment/parameters-field/parameters-field';
import { getInitialParameterValues } from '../environment/parameters-field/selectors';
import { AddEnvironmentFormFieldGroup } from './add-environment-form-field-group/add-environment-form-field-group';
import {
    EnvironmentExpirationFieldErrorType,
    EnvironmentNameFieldErrorType,
} from './add-environment-form-field-group/models';
import {
    getEnvironmentExpirationFieldErrorType,
    getEnvironmentNameFieldErrorType,
} from './add-environment-form-field-group/selectors';
import AddEnvironmentPanelFooter from './add-environment-panel-footer';
import {
    AddEnvironmentFormData,
    AddEnvironmentFormErrorsByField,
    AddEnvironmentFormFieldName,
    EnvironmentDefinitionViewModel,
    EnvironmentTypeViewModel,
    ProjectToEnvironmentDefinitionViewModelMap,
    ProjectToProjectEnvironmentTypeViewModelMap,
} from './models';
import {
    getEnvironmentDefinitionViewModels,
    getExpirationDate,
    getProcessedParameterValues,
    getProjectDataplaneKey,
    getProjectEnvironmentTypeFromDataplaneViewModels,
    getProjectEnvironmentTypeViewModels,
    getProjectFromDiscoveryServiceViewModelsForEnvironments,
    getProjectViewModels,
} from './selectors';

interface AddEnvironmentPanelProps {
    isOpen: boolean;
    onDismiss: () => void;
    onAddEnvironmentSubmitted: ReturnVoid<typeof addEnvironment>;
    projects: ProjectViewModel[];
    environmentDefinitions: ProjectToEnvironmentDefinitionViewModelMap;
    environmentTypes: ProjectToProjectEnvironmentTypeViewModelMap;
    existingEnvironmentIds: string[];
    userId: string;
    didSomeProjectFailToLoadCreateResources: boolean;
    statusForAddEnvironment: Status;
}

const messages = defineMessages({
    addEnvironmentPanelTitleText: {
        id: 'AddEnvironmentPanel_Title_Text',
        defaultMessage: 'Add an environment',
        description: 'Text for add environment panel title',
    },
    addEnvironmentPanelCloseButtonAriaLabel: {
        id: 'AddEnvironmentPanel_CloseButton_AriaLabel',
        defaultMessage: 'Add environment control panel close',
        description: 'Aria label for the help menu panel close button label',
    },
    addEnvironmentPanelParametersTitleText: {
        id: 'AddEnvironmentPanel_ParametersTitle_Text',
        defaultMessage: 'Parameters for {selectedEnvironmentDefinition}',
        description:
            'Text for add environment panel parameters title. {selectedEnvironmentDefinition} should not be localized.',
    },
    nameTextFieldAlreadyExistsError: {
        id: 'AddEnvironmentPanel_NameTextField_AlreadyExistsError',
        defaultMessage: 'An environment with this name already exists. Please use a unique name.',
        description: 'Error text indicating that an environment already exists with this name in the selected pool',
    },
    nameTextFieldInvalidFormatError: {
        id: 'AddEnvironmentPanel_NameTextField_InvalidFormatError',
        // Note: ensure this message is kept up-to-date with the validation function.
        defaultMessage:
            'Please enter a name that contains only alphanumeric characters and hyphens, and does not start with a hypen.',
        description: 'Error text indicating that the environment name is not in a valid format',
    },
    nameTextFieldTooLongError: {
        id: 'AddEnvironmentPanel_NameTextField_TooLongError',
        // Note: ensure this message is kept up-to-date with the validation function.
        defaultMessage: 'Please enter a name with less than sixty-three chracters.',
        description: 'Error text indicating that the environment name is too long',
    },
    nameTextFieldTooShortError: {
        id: 'AddEnvironmentPanel_NameTextField_TooShortError',
        // Note: ensure this message is kept up-to-date with the validation function.
        defaultMessage: 'Please enter a name with more than three characters.',
        description: 'Error text indicating that the environment name is too short',
    },
    projectDropdownRequiredError: {
        id: 'AddEnvironmentPanel_ProjectDropdown_RequiredError',
        // This string is expected to never appear
        defaultMessage: 'Required',
        description:
            'Error text indicating that the project dropdown in the add environment panel has not yet been filled out',
    },
    environmentTypeDropdownRequiredError: {
        id: 'AddEnvironmentPanel_EnvironmentTypeDropdown_RequiredError',
        // This string is expected to never appear
        defaultMessage: 'Required',
        description:
            'Error text indicating that the environment type dropdown in the add environment panel has not yet been filled out',
    },
    environmentDefinitionDropdownRequiredError: {
        id: 'AddEnvironmentPanel_EnvironmentDefinitionDropdown_RequiredError',
        // This string is expected to never appear
        defaultMessage: 'Required',
        description:
            'Error text indicating that the environment definition dropdown in the add environment panel has not yet been filled out',
    },
    expirationDateControlInvalidError: {
        id: 'AddEnvironmentPanel_ExpirationDateControl_InvalidError',
        defaultMessage: 'The expiration date must be in the future',
        description: 'Error text indicating that the expiration date control in the add environment panel is invalid',
    },
});

// Note: NOT putting this in selectors so that we can keep the localized messages contained in this module.
const getErrorMessageDescriptorForEnvironmentNameFieldErrorType = (
    environmentNameFieldErrorType: EnvironmentNameFieldErrorType
): MessageDescriptor | undefined => {
    switch (environmentNameFieldErrorType) {
        case EnvironmentNameFieldErrorType.AlreadyExists:
            return messages.nameTextFieldAlreadyExistsError;
        case EnvironmentNameFieldErrorType.InvalidFormat:
            return messages.nameTextFieldInvalidFormatError;
        case EnvironmentNameFieldErrorType.TooLong:
            return messages.nameTextFieldTooLongError;
        case EnvironmentNameFieldErrorType.TooShort:
            return messages.nameTextFieldTooShortError;
        default:
            return undefined;
    }
};

export const AddEnvironmentPanelComponent: React.FC<AddEnvironmentPanelProps> = (props: AddEnvironmentPanelProps) => {
    const {
        onDismiss,
        onAddEnvironmentSubmitted,
        projects,
        environmentDefinitions,
        isOpen,
        environmentTypes,
        existingEnvironmentIds,
        userId,
        didSomeProjectFailToLoadCreateResources,
        statusForAddEnvironment,
    } = props;
    const { failure: failureForAddEnvironment } = statusForAddEnvironment ?? {};

    // Intl hooks
    const { formatMessage } = useIntl();

    const [isParametersPanel, setIsParametersPanel] = React.useState(false);

    // Form
    const isErrorOrFailedAddEnvironmentState = React.useMemo(
        () => isStatusUnsuccessful(statusForAddEnvironment),
        [statusForAddEnvironment]
    );

    const initialValues: AddEnvironmentFormData = React.useMemo(
        () => ({
            environmentName: '',
            selectedProject: undefined,
            selectedEnvironmentDefinition: undefined,
            selectedEnvironmentType: undefined,
            selectedParameters: {},
            enableExpiration: false,
            environmentExpiration: {
                expirationCalendarDate: undefined,
                expirationTimeZone: getDefaultTimeZone(),
                expirationTime: undefined,
            },
        }),
        []
    );

    const onFormSubmit = useCallback(
        (data: AddEnvironmentFormData) => {
            const {
                environmentName,
                selectedEnvironmentType,
                selectedProject,
                selectedEnvironmentDefinition,
                selectedParameters,
                environmentExpiration,
                enableExpiration,
            } = data;

            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            // Justification: Selected environment type will not be undefined since it is validated at the record-level validation.
            const { id: projectId } = selectedProject!;
            /* eslint-enable @typescript-eslint/no-non-null-assertion */

            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            // Justification: Selected environment type will not be undefined since it is validated at the record-level validation.
            const { name: environmentTypeName } = selectedEnvironmentType!;
            /* eslint-enable @typescript-eslint/no-non-null-assertion */

            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            // Justification: Selected environment definition will not be undefined since it is validated at the record-level validation.
            const { name: environmentDefinitionName, catalogName } = selectedEnvironmentDefinition!;
            /* eslint-enable @typescript-eslint/no-non-null-assertion */

            const parameterValues = getProcessedParameterValues(
                selectedParameters,
                selectedEnvironmentDefinition?.parameters
            );

            const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(projectId);

            const id = createEnvironmentDataPlaneUri({
                devCenter,
                environmentName,
                projectName,
                user: userId,
            });

            const isExpirationEnabled = !!enableExpiration;
            const expirationDate = isExpirationEnabled ? getExpirationDate(environmentExpiration) : undefined;

            onAddEnvironmentSubmitted({
                catalog: catalogName,
                environmentDefinition: environmentDefinitionName,
                expirationDate,
                environmentType: environmentTypeName,
                id,
                name: environmentName,
                parameters: parameterValues,
            });
        },
        [userId, onAddEnvironmentSubmitted]
    );

    const isEnvironmentNameValid = React.useCallback(
        (value: string, selectedProject: ProjectViewModel | undefined): string | undefined => {
            const environmentNameErrorType = getEnvironmentNameFieldErrorType(
                value,
                selectedProject?.id,
                existingEnvironmentIds,
                userId
            );
            const errorMessageDescriptor =
                getErrorMessageDescriptorForEnvironmentNameFieldErrorType(environmentNameErrorType);
            return errorMessageDescriptor !== undefined ? formatMessage(errorMessageDescriptor) : undefined;
        },
        [formatMessage, existingEnvironmentIds]
    );

    const isSelectedProjectValid = React.useCallback(
        (value: ProjectViewModel | undefined): string | undefined =>
            hasNoValue(value) ? formatMessage(messages.projectDropdownRequiredError) : undefined,
        [formatMessage]
    );

    const isSelectedEnvironmentTypeValid = React.useCallback(
        (value: EnvironmentTypeViewModel | undefined): string | undefined =>
            hasNoValue(value) ? formatMessage(messages.environmentTypeDropdownRequiredError) : undefined,
        [formatMessage]
    );

    const isSelectedEnvironmentDefinitionValid = React.useCallback(
        (value: EnvironmentDefinitionViewModel | undefined): string | undefined =>
            hasNoValue(value) ? formatMessage(messages.environmentDefinitionDropdownRequiredError) : undefined,
        [formatMessage]
    );

    const isEnvironmentExpirationValid = React.useCallback(
        (
            environmentExpiration: EnvironmentExpirationViewModel | undefined,
            enableExpiration: boolean
        ): string | undefined | boolean => {
            const environmentExpirationErrorType = getEnvironmentExpirationFieldErrorType(
                environmentExpiration,
                enableExpiration
            );

            if (
                environmentExpirationErrorType === EnvironmentExpirationFieldErrorType.DateRequired ||
                environmentExpirationErrorType === EnvironmentExpirationFieldErrorType.TimeRequired
            ) {
                return true;
            }

            return environmentExpirationErrorType === EnvironmentExpirationFieldErrorType.Invalid
                ? formatMessage(messages.expirationDateControlInvalidError)
                : undefined;
        },
        [formatMessage]
    );

    const validateAddEnvironmentFormFieldGroup = React.useCallback(
        (values: AddEnvironmentFormData) => {
            const errors: AddEnvironmentFormErrorsByField = { selectedParameters: SerializableMap<string>() };

            const environmentNameValidationErrors = isEnvironmentNameValid(
                values.environmentName,
                values.selectedProject
            );
            const selectedProjectValidationErrors = isSelectedProjectValid(values.selectedProject);
            const selectedEnvironmentTypeValidationErrors = isSelectedEnvironmentTypeValid(
                values.selectedEnvironmentType
            );
            const selectedEnvironmentDefinitionValidationErrors = isSelectedEnvironmentDefinitionValid(
                values.selectedEnvironmentDefinition
            );

            const environmentExpirationValidationErrors = isEnvironmentExpirationValid(
                values.environmentExpiration,
                values.enableExpiration
            );

            if (environmentNameValidationErrors !== undefined) {
                errors.environmentName = environmentNameValidationErrors;
            }

            if (selectedProjectValidationErrors !== undefined) {
                errors.selectedProject = selectedProjectValidationErrors;
            }

            if (selectedEnvironmentTypeValidationErrors !== undefined) {
                errors.selectedEnvironmentType = selectedEnvironmentTypeValidationErrors;
            }

            if (selectedEnvironmentDefinitionValidationErrors !== undefined) {
                errors.selectedEnvironmentDefinition = selectedEnvironmentDefinitionValidationErrors;
            }

            if (environmentExpirationValidationErrors !== undefined) {
                errors.environmentExpiration = environmentExpirationValidationErrors;
            }

            return errors;
        },
        [
            isEnvironmentNameValid,
            isSelectedProjectValid,
            isSelectedEnvironmentTypeValid,
            isSelectedEnvironmentDefinitionValid,
            isEnvironmentExpirationValid,
        ]
    );

    return (
        <Form<AddEnvironmentFormData>
            initialValues={initialValues}
            onSubmit={onFormSubmit}
            validate={isParametersPanel ? undefined : validateAddEnvironmentFormFieldGroup}
        >
            {(formProps) => {
                const { form, valid, submitting, values } = formProps;
                const { restart, submit, change } = form;
                const { selectedEnvironmentDefinition, selectedProject, selectedEnvironmentType, enableExpiration } =
                    values;
                const { parameters, description } = selectedEnvironmentDefinition ?? {};

                const isSubmitting = React.useMemo(
                    () => submitting || isStatusInProgress(statusForAddEnvironment),
                    [submitting, statusForAddEnvironment]
                );

                const hasParameters = !!parameters && parameters.length > 0;
                const hasSelectedEnvironmentDefinition = !!selectedEnvironmentDefinition;

                // detect when we should close ourselves
                const previousStatusForAddEnvironment = usePrevious(statusForAddEnvironment);
                React.useEffect(() => {
                    if (
                        isStatusInProgress(previousStatusForAddEnvironment) &&
                        isStatusSuccessful(statusForAddEnvironment)
                    ) {
                        onDismiss();
                        restart(initialValues);
                        setIsParametersPanel(false);
                    }
                }, [previousStatusForAddEnvironment, statusForAddEnvironment, onDismiss, restart, initialValues]);

                const onCancelClicked = React.useCallback(() => {
                    onDismiss();
                    restart(initialValues);
                    setIsParametersPanel(false);
                }, [onDismiss, restart, initialValues]);

                const onNextClicked = React.useCallback(() => setIsParametersPanel(true), []);
                const onBackClicked = React.useCallback(() => setIsParametersPanel(false), []);

                const onRenderFooterContent = React.useCallback(() => {
                    return (
                        <AddEnvironmentPanelFooter
                            failure={failureForAddEnvironment}
                            isErrorOrFailedState={isErrorOrFailedAddEnvironmentState}
                            isSubmitting={isSubmitting}
                            isValid={valid}
                            hasParameters={hasParameters}
                            isParametersPanel={isParametersPanel}
                            onCancelClicked={onCancelClicked}
                            onSubmitClicked={submit}
                            onNextClicked={onNextClicked}
                            onBackClicked={onBackClicked}
                            hasSelectedEnvironmentDefinition={hasSelectedEnvironmentDefinition}
                        />
                    );
                }, [
                    failureForAddEnvironment,
                    isErrorOrFailedAddEnvironmentState,
                    isSubmitting,
                    valid,
                    hasParameters,
                    isParametersPanel,
                    onCancelClicked,
                    submit,
                    onNextClicked,
                    hasSelectedEnvironmentDefinition,
                ]);

                const environmentDefinitionOnChange = React.useCallback(
                    (value: EnvironmentDefinitionViewModel | undefined) => {
                        change(AddEnvironmentFormFieldName.SelectedEnvironmentDefinition, value);
                        change(
                            AddEnvironmentFormFieldName.SelectedParameters,
                            getInitialParameterValues(value?.parameters)
                        );
                    },
                    [change]
                );

                const headerText = React.useMemo(
                    () =>
                        isParametersPanel
                            ? formatMessage(messages.addEnvironmentPanelParametersTitleText, {
                                  selectedEnvironmentDefinition: selectedEnvironmentDefinition?.name,
                              })
                            : formatMessage(messages.addEnvironmentPanelTitleText),
                    [formatMessage, isParametersPanel, selectedEnvironmentDefinition]
                );

                const environmentTypesForSelectedProject = React.useMemo(() => {
                    if (!selectedProject) {
                        return [];
                    }

                    return get(environmentTypes, selectedProject.resourceId) ?? [];
                }, [environmentTypes, selectedProject]);

                const environmentDefinitionsForSelectedProject = React.useMemo(() => {
                    if (!selectedProject) {
                        return [];
                    }

                    return get(environmentDefinitions, getProjectDataplaneKey(selectedProject)) ?? [];
                }, [environmentDefinitions, selectedProject]);

                return (
                    <FormPanel
                        isOpen={isOpen}
                        headerText={headerText}
                        isLightDismiss={!isSubmitting}
                        onDismiss={isSubmitting ? undefined : onCancelClicked}
                        closeButtonAriaLabel={formatMessage(messages.addEnvironmentPanelCloseButtonAriaLabel)}
                        onRenderFooterContent={onRenderFooterContent}
                    >
                        {isParametersPanel ? (
                            <EnvironmentParametersPanelContent
                                name={AddEnvironmentFormFieldName.SelectedParameters}
                                isSubmitting={isSubmitting}
                                parameters={parameters}
                                description={description}
                            />
                        ) : (
                            <AddEnvironmentFormFieldGroup
                                isSubmitting={isSubmitting}
                                projects={projects}
                                environmentTypes={environmentTypesForSelectedProject}
                                environmentDefinitions={environmentDefinitionsForSelectedProject}
                                environmentDefinitionOnChange={environmentDefinitionOnChange}
                                selectedProject={selectedProject}
                                selectedEnvironmentType={selectedEnvironmentType}
                                selectedEnvironmentDefinition={selectedEnvironmentDefinition}
                                didSomeProjectFailToLoadCreateResources={didSomeProjectFailToLoadCreateResources}
                                enableExpiration={enableExpiration}
                            />
                        )}
                    </FormPanel>
                );
            }}
        </Form>
    );
};

const AddEnvironmentPanelContainer: React.FC = () => {
    // Application state hooks
    const projects = useSelector(getProjectViewModels);
    const projectsFromDiscoveryService = useSelector(getProjectFromDiscoveryServiceViewModelsForEnvironments);
    const environmentTypes = useSelector(getProjectEnvironmentTypeViewModels);
    const environmentTypesFromDataplane = useSelector(getProjectEnvironmentTypeFromDataplaneViewModels);
    const environmentDefinitions = useSelector(getEnvironmentDefinitionViewModels);
    const existingEnvironmentIds = useSelector(getEnvironmentIds);
    const userId = useSelector(getObjectId);
    const didSomeProjectFailToLoadCreateResources = useSelector(getDidSomeProjectFailToLoadEnvironmentCreateResources);
    const statusForAddEnvironment = useSelector(getStatusForAddEnvironment);

    const projectsToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
        ? projectsFromDiscoveryService
        : projects;

    const environmentTypesToUse = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
        ? environmentTypesFromDataplane
        : environmentTypes;

    // Action hooks
    const onAddEnvironmentSubmitted = useActionCreator(addEnvironment);
    const onClearAddEnvironmentFailure = useActionCreator(clearAddEnvironmentFailure);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useAddEnvironmentPanelContext();

    const onDismiss = React.useCallback(() => {
        closePanel();
        onClearAddEnvironmentFailure();
    }, [closePanel, onClearAddEnvironmentFailure]);

    return (
        <AddEnvironmentPanelComponent
            onDismiss={onDismiss}
            onAddEnvironmentSubmitted={onAddEnvironmentSubmitted}
            isOpen={isOpen}
            projects={projectsToUse}
            environmentDefinitions={environmentDefinitions}
            environmentTypes={environmentTypesToUse}
            existingEnvironmentIds={existingEnvironmentIds}
            userId={userId}
            didSomeProjectFailToLoadCreateResources={didSomeProjectFailToLoadCreateResources}
            statusForAddEnvironment={statusForAddEnvironment}
        />
    );
};

export const AddEnvironmentPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useAddEnvironmentPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <AddEnvironmentPanelContainer />;
};

export default AddEnvironmentPanelContextWrapper;
