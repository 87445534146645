import { GetSignedInUserResponse } from '../../../data/services/graph/signed-in-user';
import { DataResponse } from '../../../models/common';
import { GraphUser } from '../../../models/graph';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import {
    GetPhotoForSignedInUserPayload,
    GetPhotoForSignedInUserSuccessPayload,
    SignedInUserActionType,
} from './signed-in-user-actions';

/**
 * Action creators
 */

export const getPhotoForSignedInUser = createActionCreator<GetPhotoForSignedInUserPayload>(
    SignedInUserActionType.GetPhotoForSignedInUser
);

export const getPhotoForSignedInUserError = createActionCreator<ErrorPayload>(
    SignedInUserActionType.GetPhotoForSignedInUserError
);

export const getPhotoForSignedInUserFailed = createActionCreator<FailedPayload>(
    SignedInUserActionType.GetPhotoForSignedInUserFailed
);

export const getPhotoForSignedInUserSuccess = createActionCreator<GetPhotoForSignedInUserSuccessPayload>(
    SignedInUserActionType.GetPhotoForSignedInUserSuccess
);

export const getSignedInUser = createActionCreatorWithoutPayload<GetSignedInUserResponse>(
    SignedInUserActionType.GetSignedInUser,
    { async: true }
);

export const getSignedInUserError = createActionCreator<ErrorPayload>(SignedInUserActionType.GetSignedInUserError);

export const getSignedInUserFailed = createActionCreator<FailedPayload>(SignedInUserActionType.GetSignedInUserFailed);

export const getSignedInUserSuccess = createActionCreator<ResultPayload<GraphUser>>(
    SignedInUserActionType.GetSignedInUserSuccess
);

export const initializeUserMetadata = createActionCreatorWithoutPayload<DataResponse>(
    SignedInUserActionType.InitializeUserMetadata,
    { async: true }
);

export const initializeUserMetadataError = createActionCreator<ErrorPayload>(
    SignedInUserActionType.InitializeUserMetadataError
);

export const initializeUserMetadataFailed = createActionCreator<FailedPayload>(
    SignedInUserActionType.InitializeUserMetadataFailed
);

export const initializeUserMetadataSuccess = createActionCreatorWithoutPayload(
    SignedInUserActionType.InitializeUserMetadataSuccess
);
