import { DefaultButton, DialogFooter, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

type DialogFooterButtonsProps = {
    onCancelClicked: () => void;
    onContinueClicked: () => void;
};

const messages = defineMessages({
    cancelButton: {
        id: 'InitializeConnectViaAppDialogFooter_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the "cancel" button in the "Initialize connect via app dialog"',
    },
    cancelButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialogFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "cancel" button in the "Initialize connect via app dialog"',
    },
    continueButton: {
        id: 'InitializeConnectViaAppDialogFooter_ContinueButton_Text',
        defaultMessage: 'Continue',
        description: 'Text for the "continue" button in the "Initialize connect via app dialog"',
    },
    continueButtonAriaLabel: {
        id: 'InitializeConnectViaAppDialogFooter_ContinueButton_AriaLabel',
        defaultMessage: 'Continue',
        description: 'Aria label for the "continue" button in the "Initialize connect via app dialog"',
    },
});

export const DialogFooterButtons: React.FC<DialogFooterButtonsProps> = (props) => {
    const { formatMessage } = useIntl();
    const { onCancelClicked, onContinueClicked } = props;

    return (
        <DialogFooter>
            <PrimaryButton
                ariaLabel={formatMessage(messages.continueButtonAriaLabel)}
                onClick={onContinueClicked}
                text={formatMessage(messages.continueButton)}
            />
            <DefaultButton
                ariaLabel={formatMessage(messages.cancelButtonAriaLabel)}
                onClick={onCancelClicked}
                text={formatMessage(messages.cancelButton)}
            />
        </DialogFooter>
    );
};
