import { ApiVersion, AzureSearchParameter } from '../../../constants/azure';
import { Method } from '../../../constants/http';
import { BatchRequestName } from '../../../constants/resource-manager';
import { DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { KeyValuePair } from '../../../types/key-value-pair';
import { LocationListResultContract } from '../../contracts/resource-manager';
import { resourceRequest } from '../resource-request';
import { BatchedResourceRequest, batchedResourceRequest } from './batched-resource-request';

export type ListLocationsResponse = DataResponse<LocationListResultContract>;

const createBatchedListLocationsRequest = (subscriptionId: string): BatchedResourceRequest => ({
    method: Method.GET,
    name: `${BatchRequestName.ListLocations}|${subscriptionId}`,
    operation: FailureOperation.ListLocations,
    path: getPathForListLocations(subscriptionId),
});

const getPathForListLocations = (subscriptionId: string) =>
    `/subscriptions/${subscriptionId}/locations?${AzureSearchParameter.ApiVersion}=${ApiVersion.Subscriptions}`;

export const listLocations = async (
    subscriptionId: string,
    accessToken: string,
    activityId?: string
): Promise<ListLocationsResponse> => {
    const path = `/subscriptions/${subscriptionId}/locations?${AzureSearchParameter.ApiVersion}=${ApiVersion.Subscriptions}`;

    const result = await resourceRequest(path, Method.GET, accessToken, {
        activityId,
        operation: FailureOperation.ListLocations,
    });

    // Immediately return any error
    if (isFailureResponse(result)) {
        return result;
    }

    const data = (await result.data.json()) as LocationListResultContract;

    return { data, succeeded: true };
};

export const listManyLocations = async (
    subscriptionIds: string[],
    accessToken: string,
    activityId?: string
): Promise<KeyValuePair<string, ListLocationsResponse>[]> => {
    // If only one subscription was requested, use the unbatched function
    if (subscriptionIds.length === 1) {
        const [subscriptionId] = subscriptionIds;
        const result = await listLocations(subscriptionId, accessToken, activityId);

        return [KeyValuePair(subscriptionId, result)];
    }

    // Fire off initial set of requests as one batch request
    const batchRequests = subscriptionIds.map(createBatchedListLocationsRequest);

    const batchedResponses = await batchedResourceRequest<LocationListResultContract>(
        batchRequests,
        accessToken,
        activityId
    );

    const responses = batchedResponses.map<KeyValuePair<string, ListLocationsResponse>>((response, index) =>
        KeyValuePair(subscriptionIds[index], response)
    );

    return responses;
};
