import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { SearchParameter } from '../../../constants/app';
import { ClientError, FailureOperation } from '../../../models/common';
import { trackException } from '../../../utilities/telemetry/channel';
import { clearSearchParameterFromCurrentLocation, replaceCurrentLocation } from '../../../utilities/url';
import { clearFeatures } from '../../actions/features/features-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { takeEvery } from '../../effects/take';

export function* clearFeaturesSaga(): SagaIterator {
    try {
        // Sync location: remove features search string param entirely
        const newUrl: URL = yield call(clearSearchParameterFromCurrentLocation, SearchParameter.Features);
        yield call(replaceCurrentLocation, newUrl);
    } catch (err) {
        // No error actions for updating features - we just log the exception
        const error: ClientError = yield createSagaError(err, FailureOperation.ClearFeatures);
        yield call(trackException, error);
    }
}

export function* clearFeaturesListenerSaga(): SagaIterator {
    yield takeEvery(clearFeatures, clearFeaturesSaga);
}
