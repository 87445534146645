import * as React from 'react';
import { SurfaceContextWithProperties } from '../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../common/surface-context-provider';
import { CustomizationDetailsPanelProps } from './customization-details-panel';

export type CustomizationDetailsPanelContextProperties = Omit<CustomizationDetailsPanelProps, 'isOpen' | 'onDismiss'>;

export const CustomizationDetailsPanelContext = React.createContext<
    SurfaceContextWithProperties<CustomizationDetailsPanelContextProperties>
>({ isOpen: false });

export const CustomizationDetailsPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<CustomizationDetailsPanelContextProperties>(
        CustomizationDetailsPanelContext
    );
