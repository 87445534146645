import { ListPoolsResponse } from '../../../data/services/data-plane-api/pool';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import { ListPoolsSuccessPayload, PoolActionType } from './pool-actions';

export const discoverPoolsForProjects = createActionCreatorWithoutPayload<AggregatedResult>(
    PoolActionType.DiscoverPoolsForProjects,
    {
        async: true,
    }
);

export const discoverPoolsForProjectsError = createActionCreator<ErrorPayload>(
    PoolActionType.DiscoverPoolsForProjectsError
);
export const discoverPoolsForProjectsFailed = createActionCreator<FailedPayload>(
    PoolActionType.DiscoverPoolsForProjectsFailed
);
export const discoverPoolsForProjectsSuccess = createActionCreatorWithoutPayload(
    PoolActionType.DiscoverPoolsForProjectsSuccess
);

export const listPools = createActionCreator<IndexedPayload, ListPoolsResponse>(PoolActionType.ListPools, {
    async: true,
    startsActivity: true,
});

export const listPoolsError = createActionCreator<ErrorPayload & IndexedPayload>(PoolActionType.ListPoolsError);
export const listPoolsFailed = createActionCreator<FailedPayload & IndexedPayload>(PoolActionType.ListPoolsFailed);
export const listPoolsSuccess = createActionCreator<ListPoolsSuccessPayload>(PoolActionType.ListPoolsSuccess);

export const discoverPoolsForDevBoxes = createActionCreatorWithoutPayload<AggregatedResult>(
    PoolActionType.DiscoverPoolsForDevBoxes,
    {
        async: true,
    }
);

export const discoverPoolsForDevBoxesError = createActionCreator<ErrorPayload>(
    PoolActionType.DiscoverPoolsForDevBoxesError
);
export const discoverPoolsForDevBoxesFailed = createActionCreator<FailedPayload>(
    PoolActionType.DiscoverPoolsForDevBoxesFailed
);
export const discoverPoolsForDevBoxesSuccess = createActionCreatorWithoutPayload(
    PoolActionType.DiscoverPoolsForDevBoxesSuccess
);
