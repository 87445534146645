import { UnionMap, UnionValueMap } from '../../../types/union-map';
import { MockScenarioConfiguration } from '../../types';
import {
    ResourceManagerPermissionsScenarioName,
    ResourceManagerPermissionsScenarios,
} from './permissions/resource-manager-permissions-scenarios';
import {
    ResourceManagerResourceGraphScenarioName,
    ResourceManagerResourceGraphScenarios,
} from './resource-graph/resource-manager-resource-graph-scenarios';

export type ResourceManagerScenarioName =
    | ResourceManagerPermissionsScenarioName
    | ResourceManagerResourceGraphScenarioName;

export const ResourceManagerScenarioName: UnionMap<ResourceManagerScenarioName> = {
    ...ResourceManagerPermissionsScenarioName,
    ...ResourceManagerResourceGraphScenarioName,
};

export const ResourceManagerScenarios: UnionValueMap<ResourceManagerScenarioName, MockScenarioConfiguration> = {
    ...ResourceManagerPermissionsScenarios,
    ...ResourceManagerResourceGraphScenarios,
};
