import { getTokensFromEnvironmentDataPlaneUri } from '../../../ids/environment';
import { getTokensFromEnvironmentOperationDataPlaneUri } from '../../../ids/environment-operation';
import { DataResponse, FailureOperation } from '../../../models/common';
import { EnvironmentOperationContract } from '../../contracts/environment-operation';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetEnvironmentOperationResponse = DataResponse<EnvironmentOperationContract>;
export type ListEnvironmentOperationsResponse = DataResponse<EnvironmentOperationContract[]>;

export const getEnvironmentOperation = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetEnvironmentOperationResponse> => {
    const { devCenter, environmentName, environmentOperationId, projectName, user } =
        getTokensFromEnvironmentOperationDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetEnvironmentOperation,

        whenUsingBetaClient: (client) =>
            client.environments.getOperation(
                projectName,
                user,
                environmentName,
                environmentOperationId,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.getOperation(
                projectName,
                user,
                environmentName,
                environmentOperationId,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listEnvironmentOperations = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListEnvironmentOperationsResponse> => {
    const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListEnvironmentOperations,

        whenUsingBetaClient: (client) =>
            client.environments.listOperations(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.listOperations(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};
