import { registerIcons } from '@fluentui/react';
import * as React from 'react';
import { UnionMap, UnionValueMap } from '../types/union-map';
import CpuIcon from './cpu-icon';
import MicrosoftLogoIcon from './microsoft-logo-icon';
import RamIcon from './ram-icon';
import RemoteApplicationIcon from './remote-application-icon';
import WebEnvironmentIcon from './web-environment-icon';

export type ExtendedIcon = 'CPU' | 'MicrosoftLogo' | 'RAM' | 'RemoteApplication' | 'WebEnvironment';

export const ExtendedIcon: UnionMap<ExtendedIcon> = {
    CPU: 'CPU',
    MicrosoftLogo: 'MicrosoftLogo',
    RAM: 'RAM',
    RemoteApplication: 'RemoteApplication',
    WebEnvironment: 'WebEnvironment',
};

// Side benefit of below code: this prevents VS Code settings from incorrectly removing React from imports when saving.
const icons: UnionValueMap<ExtendedIcon, React.ReactElement> = {
    CPU: <CpuIcon />,
    MicrosoftLogo: <MicrosoftLogoIcon />,
    RAM: <RamIcon />,
    RemoteApplication: <RemoteApplicationIcon />,
    WebEnvironment: <WebEnvironmentIcon />,
};

export const initializeExtendedIcons = (): void => {
    registerIcons({ icons });
};
