export type SerializableSet<TValue> = Array<TValue>;

export const SerializableSet = <TValue>(data?: ReadonlyArray<TValue> | Set<TValue>): SerializableSet<TValue> => {
    // If no argument given, return empty array
    if (data === undefined) {
        return [];
    }

    // Otherwise, set-copy the existing data
    return [...new Set(data)];
};
