import { IStackTokens, MessageBarButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

export interface ErrorBannerActionProps {
    title: string;
    onClick: () => void;
}

interface ErrorBannerActionsProps {
    onRetry?: () => void;
    onSeeDetails?: () => void;
    retryable?: boolean;
    additionalActions?: ErrorBannerActionProps[];
}

const messages = defineMessages({
    retryButtonAriaLabel: {
        id: 'ErrorBannerActions_RetryButton_AriaLabel',
        defaultMessage: 'Retry',
        description: 'Aria label of the "Retry" button in the error banner',
    },
    seeDetailsButtonAriaLabel: {
        id: 'ErrorBannerActions_SeeDetailsButton_AriaLabel',
        defaultMessage: 'See details',
        description: 'Aria label of the "See details" button in the error banner',
    },
});

const tokens: IStackTokens = { childrenGap: 8 };

export const ErrorBannerActions: React.FC<ErrorBannerActionsProps> = React.memo((props: ErrorBannerActionsProps) => {
    const { onRetry, onSeeDetails, retryable, additionalActions } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const showSeeDetailsButton = !!onSeeDetails;
    const showRetryButton = retryable && onRetry;

    // Render nothing rather than an empty stack if no buttons would end up being rendered
    if (!showSeeDetailsButton && !showRetryButton && !additionalActions) {
        return <></>;
    }

    return (
        <Stack horizontal tokens={tokens}>
            {onSeeDetails && (
                <Stack.Item>
                    <MessageBarButton
                        ariaLabel={formatMessage(messages.seeDetailsButtonAriaLabel)}
                        onClick={onSeeDetails}
                    >
                        <FormattedMessage
                            id="ErrorBannerActions_SeeDetailsButton_Text"
                            defaultMessage="See details"
                            description="Text of the 'See details' button in the error banner"
                        />
                    </MessageBarButton>
                </Stack.Item>
            )}

            {retryable && onRetry && (
                <Stack.Item>
                    <MessageBarButton ariaLabel={formatMessage(messages.retryButtonAriaLabel)} onClick={onRetry}>
                        <FormattedMessage
                            id="ErrorBannerActions_RetryButton_Text"
                            defaultMessage="Retry"
                            description="Text of the 'Retry' button in the error banner"
                        />
                    </MessageBarButton>
                </Stack.Item>
            )}

            {additionalActions &&
                additionalActions.map((action, index) => (
                    <Stack.Item key={`additional-action-${index}`}>
                        <MessageBarButton ariaLabel={action.title} onClick={action.onClick}>
                            {action.title}
                        </MessageBarButton>
                    </Stack.Item>
                ))}
        </Stack>
    );
});

export default ErrorBannerActions;
