import { makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { useDynamicMakeStyles } from '../hooks/styling';
import { CssSelector } from '../themes/constants/css-selectors';
import { PortalLayout, PortalLayoutProps } from './portal-layout';

interface Props extends PortalLayoutProps {
    contentPaddingBottom?: number;
    contentPaddingTop?: number;
    verticalFill?: boolean;
    hideActionBar?: boolean;
}

export type CenteredPortalLayoutProps = Props;

const useStyles = makeStyles({
    container: {
        justifyContent: 'center',
    },
});

const contentStylesFactory = (contentPaddingTop?: number, contentPaddingBottom?: number) => {
    return makeStyles({
        root: {
            height: 'fit-content',
            textAlign: 'center',
            // Note: ensure that width for ScreenSizeSmallAndBelow is kept in sync with this padding value.
            padding: '0 58px',
            paddingBottom: contentPaddingBottom || undefined,
            paddingTop: contentPaddingTop || undefined,

            [CssSelector.ScreenSizeSmallAndBelow]: {
                // At screen sizes small and below, we switch from fixed width + variable gutters mode to variable
                // width + fixed gutters mode. To give a defined width to descendant elements, we calculate the width
                // to be the total viewport width minus the total width of the gutters. That total gutter width should
                // be kept in sync with the padding defined for this element.
                width: 'calc(100vw - 116px)',
            },

            [CssSelector.ScreenSizeMedium]: {
                maxWidth: '524px',
            },

            [CssSelector.ScreenSizeBetweenLargeAndXLarge]: {
                maxWidth: '816px',
            },

            [CssSelector.ScreenSizeXXLarge]: {
                maxWidth: '864px',
            },
        },
    });
};

export const CenteredPortalLayout: React.FC<Props> = (props) => {
    const { id, showFooter, children, contentPaddingBottom, contentPaddingTop, verticalFill, hideActionBar } = props;

    const { container } = useStyles();
    const useContentStyles = useDynamicMakeStyles(contentStylesFactory, contentPaddingTop, contentPaddingBottom);
    const contentStyles = useContentStyles();

    return (
        <PortalLayout id={id} showFooter={showFooter} hideActionBar={hideActionBar}>
            <Stack
                horizontal
                verticalFill={verticalFill}
                grow={verticalFill}
                verticalAlign="center"
                horizontalAlign="center"
                className={container}
            >
                <Stack.Item styles={contentStyles}>{children}</Stack.Item>
            </Stack>
        </PortalLayout>
    );
};

export default CenteredPortalLayout;
