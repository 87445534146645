import { FontSizes, FontWeights, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import noProjectsImage from '../../content/images/Blocked.svg';

interface NoProjectsProps {
    hasMultipleOrganizations?: boolean;
}

const messages = defineMessages({
    noProjectsImageAlt: {
        id: 'NoProjects_NoProjectsImage_Alt',
        defaultMessage: 'No project access',
        description: 'Alt text for "no projects" image',
    },
});

/**
 * Style Section
 */

// Note: using paddingTop to space StackItems as Stack will override margins
const useNoAccessContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: 6,
    },
});

const useTryAgainContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '432px',
        paddingTop: 6,
    },
});

/* END */

export const NoProjects: React.FC<NoProjectsProps> = React.memo((props: NoProjectsProps) => {
    const { hasMultipleOrganizations } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const noAccessContainerStyles = useNoAccessContainerStyles();
    const tryAgainContainerStyles = useTryAgainContainerStyles();

    return (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
            <Stack.Item>
                <img alt={formatMessage(messages.noProjectsImageAlt)} src={noProjectsImage} />
            </Stack.Item>

            <Stack.Item styles={noAccessContainerStyles}>
                <FormattedMessage
                    id="NoProjects_NoAccessMessage_Text"
                    defaultMessage="You don't have access to any projects yet"
                    description="Message informing user that they don't have access to any projects"
                />
            </Stack.Item>

            <Stack.Item styles={tryAgainContainerStyles}>
                {hasMultipleOrganizations ? (
                    <FormattedMessage
                        id="NoProjects_TryAgainMultipleOrganizationsMessage_Text"
                        defaultMessage="Please try selecting another organization, or contact your administrator to gain access."
                        description="Message informing user that they should try selecting a different organization"
                    />
                ) : (
                    <FormattedMessage
                        id="NoProjects_TryAgainSingleOrganizationMessage_Text"
                        defaultMessage="Please contact your administrator to gain access."
                        description="Message informing user that they should try contacting their administrator about access"
                    />
                )}
            </Stack.Item>
        </Stack>
    );
});
