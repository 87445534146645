import { AzureLocation } from '../../../models/resource-manager';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import { discoverLocations, listLocations } from './subscription-action-creators';

/**
 * Action types
 */

type SubscriptionActionName =
    | ActionName<'DiscoverLocations', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListLocations', 'Error' | 'Failed' | 'Success'>;

export type SubscriptionActionType =
    | ActionType<'DISCOVER_LOCATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_LOCATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const SubscriptionActionType: UnionValueMap<SubscriptionActionName, SubscriptionActionType> = {
    DiscoverLocations: 'DISCOVER_LOCATIONS',
    DiscoverLocationsError: 'DISCOVER_LOCATIONS_ERROR',
    DiscoverLocationsFailed: 'DISCOVER_LOCATIONS_FAILED',
    DiscoverLocationsSuccess: 'DISCOVER_LOCATIONS_SUCCESS',
    ListLocations: 'LIST_LOCATIONS',
    ListLocationsError: 'LIST_LOCATIONS_ERROR',
    ListLocationsFailed: 'LIST_LOCATIONS_FAILED',
    ListLocationsSuccess: 'LIST_LOCATIONS_SUCCESS',
};

/**
 * Payloads
 */

export type ListLocationsSuccessPayload = IndexedPayload & ResultPayload<AzureLocation[]>;

/**
 * Action shorthands
 */

export type DiscoverLocationsAction = ReturnType<typeof discoverLocations>;
export type ListLocationsAction = ReturnType<typeof listLocations>;
