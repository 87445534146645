import { SerializableMap } from '../../../types/serializable-map';
import { get, remove, set } from '../../../utilities/serializable-map';
import { EnvironmentDefinitionParameterValue, EnvironmentDefinitionParameterViewModel } from './models';

export const getInitialParameterValues = (
    parameters: EnvironmentDefinitionParameterViewModel[] | undefined
): SerializableMap<EnvironmentDefinitionParameterValue> => {
    let parameterValues = SerializableMap<EnvironmentDefinitionParameterValue>();

    parameters?.forEach((parameter) => {
        const { id, default: defaultValue } = parameter;

        if (defaultValue !== undefined) {
            parameterValues = setParameterValue(parameterValues, id, defaultValue);
        }
    });

    return parameterValues;
};

export const setParameterValue = (
    parameterValues: SerializableMap<EnvironmentDefinitionParameterValue>,
    id: string,
    value: EnvironmentDefinitionParameterValue
): SerializableMap<EnvironmentDefinitionParameterValue> => set(parameterValues, id, value, true);

export const getParameterValue = (
    parameterValues: SerializableMap<EnvironmentDefinitionParameterValue>,
    id: string
): EnvironmentDefinitionParameterValue | undefined => get(parameterValues, id, true);

export const removeParameterValue = (
    parameterValues: SerializableMap<EnvironmentDefinitionParameterValue>,
    id: string
): SerializableMap<EnvironmentDefinitionParameterValue> => remove(parameterValues, id, true);

export const setParameterError = (
    parameterErrors: SerializableMap<string>,
    id: string,
    value: string
): SerializableMap<string> => set(parameterErrors, id, value, true);

export const getParameterError = (parameterErrors: SerializableMap<string>, id: string): string | undefined =>
    get(parameterErrors, id, true);

export const isValidJson = (value: string): boolean => {
    try {
        JSON.parse(value);
        return true;
    } catch {
        return false;
    }
};

export const isArray = (value: string): boolean => {
    if (value.length < 2) {
        return false;
    }

    if (value[0] !== '[' || value[value.length - 1] !== ']') {
        return false;
    }

    try {
        return Array.isArray(JSON.parse(value));
    } catch {
        return false;
    }
};
