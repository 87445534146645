import { DataPlaneIdToken, DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { HostnamePattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { areStringsEquivalent, isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';

const validateTokens = (pathPrefix: string, expectedPathPrefix: string, name: string, tokenName: DataPlaneIdToken) => {
    if (isUndefinedOrWhiteSpace(pathPrefix)) {
        throw new ClientError('Path prefix token cannot be undefined or white space');
    }

    if (!areStringsEquivalent(pathPrefix, expectedPathPrefix, true)) {
        throw new ClientError(`Path prefix was not expected value "${expectedPathPrefix}"`);
    }

    if (isUndefinedOrWhiteSpace(name)) {
        throw new ClientError(`Token "${tokenName}" cannot be undefined or white space`);
    }
};

export const getDevCenterFromDataPlaneUri = (uri: string): string => new URL(uri).origin;

export const getTenantIdFromDevCenterUri = (uri: string): string | undefined => {
    const parsed = HostnamePattern.DataPlaneRegional.exec(uri);

    if (!parsed) {
        return undefined;
    }

    const [, tenantId] = parsed;

    return tenantId;
};

export const tryGetTenantIdFromDevCenterUri = tryOrDefault(getTenantIdFromDevCenterUri);

export const parseDataPlanePath = (path: string): string[] => {
    if (isUndefinedOrWhiteSpace(path)) {
        throw new ClientError('Path cannot be undefined or white space');
    }

    // Explanation: leading slash contributes +1 entry in array
    const [, ...parts] = path.split('/');

    return parts;
};

export const isNonRegionalDataPlaneUri = (uri: string): boolean => {
    try {
        const { origin } = new URL(uri);
        return HostnamePattern.DataPlaneNonRegional.test(origin);
    } catch {
        return false;
    }
};

export const isRegionalDataPlaneUri = (uri: string): boolean => {
    try {
        const { origin } = new URL(uri);
        return HostnamePattern.DataPlaneRegional.test(origin);
    } catch {
        return false;
    }
};

export const isValidDataPlaneUri = (uri: string, pattern: RegExp): boolean => {
    try {
        const { pathname } = new URL(uri);
        return pattern.test(pathname);
    } catch {
        return false;
    }
};

export const tryGetDevCenterFromDataPlaneUri = tryOrDefault(getDevCenterFromDataPlaneUri);
export const tryParseDataPlanePath = tryOrDefault(parseDataPlanePath);

export const validateCatalogTokens = (resourceType: string, catalog: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.Catalog, catalog, 'catalogName');

export const validateDevBoxActionTokens = (resourceType: string, action: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.DevBoxAction, action, 'devBoxActionName');

export const validateDevBoxOperationTokens = (resourceType: string, operation: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.DevBoxOperation, operation, 'devBoxOperationId');

export const validateDevBoxTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.DevBox, name, 'devBoxName');

export const validateEnvironmentDefinitionTokens = (resourceType: string, environmentDefinition: string): void =>
    validateTokens(
        resourceType,
        DataPlaneResourcePathPrefix.EnvironmentDefinition,
        environmentDefinition,
        'environmentDefinitionName'
    );

export const validateEnvironmentOperationTokens = (resourceType: string, operation: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.EnvironmentOperation, operation, 'environmentOperationId');

export const validateEnvironmentTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.Environment, name, 'environmentName');

export const validateEnvironmentTypeTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.EnvironmentType, name, 'environmentTypeName');

export const validatePoolTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.Pool, name, 'poolName');

export const validateProjectTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.Project, name, 'projectName');

export const validateScheduleTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.Schedule, name, 'scheduleName');

export const validateUserTokens = (resourceType: string, name: string): void =>
    validateTokens(resourceType, DataPlaneResourcePathPrefix.User, name, 'user');
