import { IStackTokens, MessageBarButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

export interface InfoBannerActionProps {
    title: string;
    onClick: () => void;
}

interface InfoBannerActionsProps {
    onSeeDetails?: () => void;
}

const messages = defineMessages({
    seeDetailsButtonAriaLabel: {
        id: 'InfoBannerActions_SeeDetailsButton_AriaLabel',
        defaultMessage: 'See details',
        description: 'Aria label of the "See details" button in the info banner',
    },
});

const tokens: IStackTokens = { childrenGap: 8 };

export const InfoBannerActions: React.FC<InfoBannerActionsProps> = React.memo((props: InfoBannerActionsProps) => {
    const { onSeeDetails } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const showSeeDetailsButton = !!onSeeDetails;

    if (!showSeeDetailsButton) {
        return <></>;
    }

    return (
        <Stack horizontal tokens={tokens}>
            {onSeeDetails && (
                <Stack.Item>
                    <MessageBarButton
                        ariaLabel={formatMessage(messages.seeDetailsButtonAriaLabel)}
                        onClick={onSeeDetails}
                    >
                        <FormattedMessage
                            id="InfoBannerActions_SeeDetailsButton_Text"
                            defaultMessage="See details"
                            description="Text of the 'See details' button in the info banner"
                        />
                    </MessageBarButton>
                </Stack.Item>
            )}
        </Stack>
    );
});

export default InfoBannerActions;
