import { ListSchedulesByProjectResponse } from '../../../data/services/data-plane-api/schedule';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import { ListSchedulesByProjectSuccessPayload, ScheduleActionType } from './schedule-actions';

export const discoverSchedulesForDevBoxes = createActionCreatorWithoutPayload<AggregatedResult>(
    ScheduleActionType.DiscoverSchedulesForDevBoxes,
    { async: true }
);

export const discoverSchedulesForDevBoxesError = createActionCreator<ErrorPayload>(
    ScheduleActionType.DiscoverSchedulesForDevBoxesError
);

export const discoverSchedulesForDevBoxesFailed = createActionCreator<FailedPayload>(
    ScheduleActionType.DiscoverSchedulesForDevBoxesFailed
);

export const discoverSchedulesForDevBoxesSuccess = createActionCreatorWithoutPayload(
    ScheduleActionType.DiscoverSchedulesForDevBoxesSuccess
);

export const discoverSchedulesForPools = createActionCreatorWithoutPayload<AggregatedResult>(
    ScheduleActionType.DiscoverSchedulesForPools,
    { async: true }
);

export const discoverSchedulesForPoolsError = createActionCreator<ErrorPayload>(
    ScheduleActionType.DiscoverSchedulesForPoolsError
);

export const discoverSchedulesForPoolsFailed = createActionCreator<FailedPayload>(
    ScheduleActionType.DiscoverSchedulesForPoolsFailed
);

export const discoverSchedulesForPoolsSuccess = createActionCreatorWithoutPayload(
    ScheduleActionType.DiscoverSchedulesForPoolsSuccess
);

export const listSchedulesByProject = createActionCreator<IndexedPayload, ListSchedulesByProjectResponse>(
    ScheduleActionType.ListSchedulesByProject,
    { async: true, startsActivity: true }
);

export const listSchedulesByProjectError = createActionCreator<ErrorPayload & IndexedPayload>(
    ScheduleActionType.ListSchedulesByProjectError
);

export const listSchedulesByProjectFailed = createActionCreator<FailedPayload & IndexedPayload>(
    ScheduleActionType.ListSchedulesByProjectFailed
);

export const listSchedulesByProjectSuccess = createActionCreator<ListSchedulesByProjectSuccessPayload>(
    ScheduleActionType.ListSchedulesByProjectSuccess
);
