import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListPoolsResponse } from '../../../data/services/data-plane-api/pool';
import { ClientError, FailureOperation, isAggregatedFailure, isAggregatedSuccess } from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverPoolsForDevBoxes,
    discoverPoolsForDevBoxesError,
    discoverPoolsForDevBoxesFailed,
    discoverPoolsForDevBoxesSuccess,
    listPools,
} from '../../actions/pool/pool-action-creators';
import { DiscoverPoolsForDevBoxesAction } from '../../actions/pool/pool-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getProjectIdsForDevBoxesRequiringPools } from './selectors';

export function* discoverPoolsForDevBoxesSaga(action: DiscoverPoolsForDevBoxesAction): SagaIterator {
    const startTime = new Date();

    try {
        const ids: string[] = yield select(getProjectIdsForDevBoxesRequiringPools);
        const responses: ListPoolsResponse[] = yield all(ids.map((id) => putAndAwait(listPools({ id }))));

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.DiscoverPoolsForDevBoxes, ...failures);
            yield put(discoverPoolsForDevBoxesFailed({ failure }));
        } else {
            yield put(discoverPoolsForDevBoxesSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPoolsForDevBoxes,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DiscoverPoolsForDevBoxes);
        yield put(discoverPoolsForDevBoxesError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPoolsForDevBoxes,
            startTime,
            AsyncOutcome.Error,
            {
                errorCodes: [error.code],
            }
        );
    }
}

export function* discoverPoolsForDevBoxesListenerSaga(): SagaIterator {
    yield takeLeading(discoverPoolsForDevBoxes, discoverPoolsForDevBoxesSaga);
}
