import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { compact } from '../../../utilities/array';
import {
    syncDismissedContent,
    syncDismissedContentError,
    syncDismissedContentFailed,
    syncDismissedContentSuccess,
} from '../../actions/application/application-action-creators';
import { SyncDismissedContentAction } from '../../actions/application/application-actions';
import { getStorageValue } from '../../actions/storage/storage-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';

export function* syncDismissedContentSaga(action: SyncDismissedContentAction): SagaIterator {
    try {
        const storageType = yield select(getStorageType);

        const response: DataResponse<string> = yield putAndAwait(
            getStorageValue({
                getForSignedInUser: true,
                key: StorageKey.DismissedMessages,
                storageType,
            })
        );

        if (isFailureResponse(response)) {
            yield put(syncDismissedContentFailed({ failure: response }));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;

        const dismissedContent = compact(
            data.split(',').map((message) => {
                const rawValue = message.trim();
                return rawValue;
            })
        );

        yield put(syncDismissedContentSuccess({ result: dismissedContent }));
        yield resolveAction(action, { data: dismissedContent, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SyncDismissedMessages);
        yield put(syncDismissedContentError({ error }));
        yield rejectAction(action, error);
    }
}

export function* syncDismissedContentListenerSaga(): SagaIterator {
    yield takeLeading(syncDismissedContent, syncDismissedContentSaga);
}
