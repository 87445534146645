import { RedirectRequest } from '@azure/msal-browser';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { IdentityErrorCode } from '../../../constants/identity';
import {
    didAuthenticationFail,
    getAuthenticationErrorCode,
    getAuthenticationErrorMessage,
    isAuthenticated,
    signIn as performSignIn,
} from '../../../data/services/identity';
import { ClientError, FailureOperation, FailureResponse } from '../../../models/common';
import {
    signIn,
    signInError,
    signInFailed,
    signInRedirecting,
    signInSuccess,
} from '../../../redux/actions/identity/identity-action-creators';
import { SignInAction } from '../../../redux/actions/identity/identity-actions';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { createSagaError } from '../../effects/create-saga-error';
import { takeLeading } from '../../effects/take';
import { getPayload } from '../../utilities/payload-action';

const getSignInOptions = (redirectStartPage?: string): Partial<RedirectRequest> | undefined => {
    if (isUndefinedOrWhiteSpace(redirectStartPage)) {
        return undefined;
    }

    return { redirectStartPage };
};

export function* signInSaga(action: SignInAction): SagaIterator {
    const { postSignInPath } = getPayload(action);

    try {
        // Check for any errors from redirection
        const authenticationFailed: boolean = yield call(didAuthenticationFail);

        if (authenticationFailed) {
            // Note: below values are guaranteed to be defined if authentication did fail
            const errorCode: string = yield call(getAuthenticationErrorCode);
            const errorMessage: string = yield call(getAuthenticationErrorMessage);

            switch (errorCode) {
                // LoginRequested: not really an error per se, just need to sign in again
                case IdentityErrorCode.LoginRequired:
                    yield put(signInRedirecting());
                    yield call(performSignIn);
                    return;

                // AccessDenied, UserCancelled, or UserLoginError: "expected" failure
                case IdentityErrorCode.AccessDenied:
                case IdentityErrorCode.UserCanceled:
                case IdentityErrorCode.UserLoginError:
                    const failure = FailureResponse({ code: errorCode, message: errorMessage });
                    yield put(signInFailed({ failure }));
                    return;

                default:
                    const error = new ClientError(errorMessage, FailureOperation.SignIn, errorCode);
                    yield put(signInError({ error }));
                    return;
            }
        }

        // Check whether the user is already authenticated to the requested tenant
        const isSignedIn = yield call(isAuthenticated);

        if (isSignedIn) {
            yield put(signInSuccess());
            return;
        }

        // Redirect user for authentication
        yield put(signInRedirecting());
        yield call(performSignIn, getSignInOptions(postSignInPath));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SignIn);
        yield put(signInError({ error }));
    }
}

export function* signInListenerSaga(): SagaIterator {
    yield takeLeading(signIn, signInSaga);
}
