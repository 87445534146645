import { SagaIterator } from 'redux-saga';
import { all, put, select } from 'redux-saga/effects';
import { ListDevBoxesResponse } from '../../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isAggregatedFailure } from '../../../../models/common';
import { combineResults } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { listDevBoxes } from '../../../actions/dev-box/dev-box-action-creators';
import {
    loadDevBoxesForSingleDevCenter,
    loadDevBoxesForSingleDevCenterError,
    loadDevBoxesForSingleDevCenterFailed,
    loadDevBoxesForSingleDevCenterSuccess,
} from '../../../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { LoadDevBoxesForSingleDevCenterAction } from '../../../actions/sub-applications/single-dev-center/single-dev-center-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { getProjectIdsInSingleDevCenter } from '../../../selector/sub-applications/single-dev-center-selectors';

export function* loadDevBoxesForSingleDevCenterSaga(action: LoadDevBoxesForSingleDevCenterAction): SagaIterator {
    const { meta } = action;

    try {
        const projectIds: string[] = yield select(getProjectIdsInSingleDevCenter);
        const responses: ListDevBoxesResponse[] = yield all(
            projectIds.map((id) => putAndAwait(listDevBoxes({ id }, meta)))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.LoadDevBoxesForSingleDevCenter, ...failures);
            yield put(loadDevBoxesForSingleDevCenterFailed({ failure }, meta));
        } else {
            yield put(loadDevBoxesForSingleDevCenterSuccess(meta));
        }

        yield resolveAction(action, completeResult);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadDevBoxesForSingleDevCenter);
        yield put(loadDevBoxesForSingleDevCenterError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* loadDevBoxesForSingleDevCenterListenerSaga(): SagaIterator {
    yield takeLeading(loadDevBoxesForSingleDevCenter, loadDevBoxesForSingleDevCenterSaga);
}
