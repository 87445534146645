import { SagaIterator } from 'redux-saga';
import { all, put, select } from 'redux-saga/effects';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isClientError, isFailureResponse } from '../../../models/common';
import { UserSettings, convertUserSettingsToString } from '../../../types/user-settings';
import { createFailureResponseFromThrown } from '../../../utilities/failure';
import { setStorageValue } from '../../actions/storage/storage-action-creators';
import {
    setPartialUserSettings,
    setUserSettings,
    storeUserSettingsError,
    storeUserSettingsFailed,
    storeUserSettingsSuccess,
} from '../../actions/user-settings/user-settings-action-creator';
import { SetPartialUserSettingsAction, SetUserSettingsAction } from '../../actions/user-settings/user-settings-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndSettle } from '../../effects/put-and-settle';
import { takeLeading } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';
import { getUserSettingsSelector } from '../../selector/user-settings-selector';
import { getPayload } from '../../utilities/payload-action';

export function* storeSettingsSaga(action: SetUserSettingsAction): SagaIterator {
    const userSettings = getPayload(action);

    try {
        const storageType = yield select(getStorageType);

        //Set theme if browser storage is empty, and if the new theme is different
        const setStorageValueResponse: DataResponse | ClientError = yield putAndSettle(
            setStorageValue({
                key: StorageKey.UserSettings,
                setForSignedInUser: true,
                storageType,
                value: convertUserSettingsToString(userSettings),
            })
        );

        if (isClientError(setStorageValueResponse)) {
            const failure = createFailureResponseFromThrown(
                setStorageValueResponse,
                FailureOperation.StoreUserSettings
            );
            yield put(storeUserSettingsFailed({ failure }));
            return;
        }
        if (isFailureResponse(setStorageValueResponse)) {
            yield put(storeUserSettingsFailed({ failure: setStorageValueResponse }));
            return;
        }
        yield put(storeUserSettingsSuccess());
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.StoreUserSettings);
        yield put(storeUserSettingsError({ error }));
    }
}

export function* storeSettingsPartialSaga(action: SetPartialUserSettingsAction): SagaIterator {
    try {
        const currentSettings: UserSettings = yield select(getUserSettingsSelector);
        const storageType = yield select(getStorageType);

        const setStorageValueResponse: DataResponse | ClientError = yield putAndSettle(
            setStorageValue({
                key: StorageKey.UserSettings,
                setForSignedInUser: true,
                storageType,
                value: convertUserSettingsToString({
                    ...currentSettings,
                    ...action.payload,
                }),
            })
        );

        if (isClientError(setStorageValueResponse)) {
            const failure = createFailureResponseFromThrown(
                setStorageValueResponse,
                FailureOperation.StoreUserSettings
            );
            yield put(storeUserSettingsFailed({ failure }));
            return;
        }
        if (isFailureResponse(setStorageValueResponse)) {
            yield put(storeUserSettingsFailed({ failure: setStorageValueResponse }));
            return;
        }
        yield put(storeUserSettingsSuccess());
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.StoreUserSettings);
        yield put(storeUserSettingsError({ error }));
    }
}

export function* storeSettingsListenerSaga(): SagaIterator {
    yield all([
        takeLeading(setUserSettings, storeSettingsSaga),
        takeLeading(setPartialUserSettings, storeSettingsPartialSaga),
    ]);
}
