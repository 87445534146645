import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    listTenants,
    listTenantsError,
    listTenantsFailed,
    listTenantsSuccess,
} from '../actions/tenant/tenant-action-creators';
import { tenantAdapter } from '../adapters/tenant-adapters';
import { TenantDataStore, TenantStore } from '../store/tenant-store';
import { getPayload } from '../utilities/payload-action';
import { createStatusReducer } from './status-reducer';

export const tenantReducer = combineReducers<TenantStore>({
    data: createReducer(TenantDataStore(), (builder) => {
        builder.addCase(listTenantsSuccess, (store, action) => {
            const { result } = getPayload(action);

            tenantAdapter.setMany(
                store.tenants,
                result.map((tenant) => Entity(tenant.tenantId, tenant))
            );
        });
    }),

    status: combineReducers({
        listTenants: createStatusReducer({
            inProgress: listTenants,
            error: listTenantsError,
            failed: listTenantsFailed,
            success: listTenantsSuccess,
        }),
    }),
});
