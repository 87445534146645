import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { Tenant } from '../../models/resource-manager';
import { createInitializer } from '../../utilities/initializer';
import { tenantAdapter } from '../adapters/tenant-adapters';
import { StatusStore } from './status-store';

type StatusName = 'listTenants';

export interface TenantDataStore {
    tenants: EntityState<Entity<Tenant>>;
}

export const TenantDataStore = createInitializer<TenantDataStore>({ tenants: tenantAdapter.getInitialState() });

export type TenantStatusStore = StatusStore<StatusName>;

export const TenantStatusStore = createInitializer<TenantStatusStore>({
    listTenants: Status(),
});

export interface TenantStore {
    data: TenantDataStore;
    status: TenantStatusStore;
}

export const TenantStore = createInitializer<TenantStore>({
    data: TenantDataStore(),
    status: TenantStatusStore(),
});
