import { TelemetryProperties } from '../../models/telemetry';
import { createInitializer } from '../../utilities/initializer';

export interface TelemetryStore {
    globalProperties: TelemetryProperties;
}

export const TelemetryStore = createInitializer<TelemetryStore>({
    globalProperties: {},
});
