import { createSelector } from '@reduxjs/toolkit';
import { FeatureFlagName } from '../../../constants/features';
import { UnionMap } from '../../../types/union-map';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { getIsSingleDevCenterMode } from '../../../utilities/single-dev-center';
import { ActiveAccountState } from '../../store/identity-state';
import { StoreStateSelector } from '../common';
import { getHasDevBoxes } from '../dev-box-selectors';
import { getHasEnvironments } from '../environment-selectors';
import { getActiveAccountState } from '../identity-selectors';
import { getCountOfProjectsFromDiscoveryService } from '../project-from-discovery-service-selectors';
import { getCountOfProjects as getCountOfControlPlaneProjects } from '../project-selectors';
import { getCountOfProjectsInSingleDevCenter } from '../sub-applications/single-dev-center-selectors';
import { ActionAbility } from './create-ability/models';
import { getDevBoxCreateAbilityState, getEnvironmentCreateAbilityState } from './create-ability/selectors';
import { ResourceUserState } from './user-state/models';
import { getDevBoxUserState, getEnvironmentUserState } from './user-state/selectors';

/**
 * Types
 */

export type DisplayState =
    | 'Loading'
    | 'HasNoProjects'
    | 'GuestAccount'
    | 'HasResources'
    | 'EmptyState'
    | 'FullScreenMisconfiguredState';

export const DisplayState: UnionMap<DisplayState> = {
    Loading: 'Loading',
    HasNoProjects: 'HasNoProjects',
    GuestAccount: 'GuestAccount',
    HasResources: 'HasResources',
    EmptyState: 'EmptyState',
    FullScreenMisconfiguredState: 'FullScreenMisconfiguredState',
};

/**
 * Composed selectors
 */

const getCountOfProjects: StoreStateSelector<number> = createSelector(
    [
        getCountOfControlPlaneProjects,
        getCountOfProjectsFromDiscoveryService,
        getCountOfProjectsInSingleDevCenter,
        getIsSingleDevCenterMode,
    ],
    (
        countOfControlPlaneProjects,
        countOfProjectsFromDiscoveryService,
        countOfProjectsInSingleDevCenter,
        isSingleDevCenterMode
    ) => {
        const isDiscoveryFeatureFlagEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        if (isDiscoveryFeatureFlagEnabled) {
            return countOfProjectsFromDiscoveryService;
        } else if (isSingleDevCenterMode) {
            return countOfProjectsInSingleDevCenter;
        } else {
            return countOfControlPlaneProjects;
        }
    }
);

export const getDisplayState: StoreStateSelector<DisplayState> = createSelector(
    [
        getHasDevBoxes,
        getHasEnvironments,
        getDevBoxUserState,
        getEnvironmentUserState,
        getActiveAccountState,
        getDevBoxCreateAbilityState,
        getEnvironmentCreateAbilityState,
        getCountOfProjects,
    ],
    (
        hasDevBoxes,
        hasEnvironments,
        devBoxUserState,
        environmentUserState,
        activeAccountState,
        devBoxCreateAbilityState,
        environmentCreateAbilityState,
        projectCount
    ): DisplayState => {
        const isGuestAccount = activeAccountState === ActiveAccountState.GuestAccount;
        const isDevBoxUser = devBoxUserState === ResourceUserState.IsUser;
        const isEnvironmentUser = environmentUserState === ResourceUserState.IsUser;
        const canWriteDevBoxes = devBoxCreateAbilityState.createAbility === ActionAbility.CanPerformAction;
        const canWriteEnvironments = environmentCreateAbilityState.createAbility === ActionAbility.CanPerformAction;

        // If we have any resources, display them (regardless of whether our resource states have loaded)
        if (hasDevBoxes || hasEnvironments) {
            return DisplayState.HasResources;
        }

        // If we don't have resources, we need to wait for other states to load to determine which full screen display to use
        if (
            environmentUserState === ResourceUserState.Unknown ||
            devBoxUserState === ResourceUserState.Unknown ||
            devBoxCreateAbilityState.createAbility === ActionAbility.Unknown ||
            environmentCreateAbilityState.createAbility === ActionAbility.Unknown
        ) {
            return DisplayState.Loading;
        }

        // Are we only trying to use dev box and are in a guest account?
        if (isDevBoxUser && !isEnvironmentUser && isGuestAccount) {
            return DisplayState.GuestAccount;
        }

        // Do we have any projects?
        if (projectCount === 0) {
            return DisplayState.HasNoProjects;
        }

        // Can we create any resources? Show empty state with option to create
        if (
            (isDevBoxUser &&
                canWriteDevBoxes &&
                (!isGuestAccount || isFeatureFlagEnabled(FeatureFlagName.EnableGuestUser))) ||
            (isEnvironmentUser && canWriteEnvironments)
        ) {
            return DisplayState.EmptyState;
        }

        // We can't read or create resources, full screen misconfigured state
        return DisplayState.FullScreenMisconfiguredState;
    }
);
