import { PutCustomizationTask } from '../../../models/customization';
import { DevBox, DevBoxRepairOperationResult } from '../../../models/dev-box';
import { UnionValueMap } from '../../../types/union-map';
import {
    ActionName,
    ActionType,
    ErrorPayload,
    FailedPayload,
    IndexedPayload,
    OptionalResultPayload,
    ResultPayload,
} from '../core-actions';
import {
    addDevBox,
    addDevBoxAccepted,
    deleteDevBox,
    deleteDevBoxAccepted,
    discoverDevBoxesInTenant,
    hibernateDevBox,
    hibernateDevBoxAccepted,
    listDevBoxes,
    pollNonTerminalDevBox,
    repairDevBox,
    repairDevBoxAccepted,
    restartDevBox,
    restartDevBoxAccepted,
    resumeDevBox,
    resumeDevBoxAccepted,
    shutdownDevBox,
    shutdownDevBoxAccepted,
    startDevBox,
    startDevBoxAccepted,
} from './dev-box-action-creators';

/**
 * Action types
 */

type DevBoxActionName =
    | ActionName<'AddDevBox', 'Accepted' | 'Error' | 'Failed'>
    | ActionName<'AddDevBoxOperation', 'Error' | 'Failed' | 'Success', false>
    | 'ClearAddDevBoxFailure'
    | ActionName<'DeleteDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'DiscoverDevBoxesInTenant', 'Error' | 'Failed' | 'Success'>
    | ActionName<'HibernateDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListDevBoxes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'PollNonTerminalDevBox', 'Complete' | 'Error'>
    | ActionName<'RepairDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'RestartDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'ResumeDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'ShutdownDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>
    | ActionName<'StartDevBox', 'Accepted' | 'Error' | 'Failed' | 'Success'>;

export type DevBoxActionType =
    | ActionType<'ADD_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED'>
    | ActionType<'ADD_DEV_BOX_OPERATION', '_ERROR' | '_FAILED' | '_SUCCESS', false>
    | 'CLEAR_ADD_DEV_BOX_FAILURE'
    | ActionType<'DELETE_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISCOVER_DEV_BOXES_IN_TENANT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'HIBERNATE_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_DEV_BOXES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'POLL_NON_TERMINAL_DEV_BOX', '_COMPLETE' | '_ERROR'>
    | ActionType<'REPAIR_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'RESTART_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'RESUME_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'SHUTDOWN_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'START_DEV_BOX', '_ACCEPTED' | '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const DevBoxActionType: UnionValueMap<DevBoxActionName, DevBoxActionType> = {
    AddDevBox: 'ADD_DEV_BOX',
    AddDevBoxAccepted: 'ADD_DEV_BOX_ACCEPTED',
    AddDevBoxError: 'ADD_DEV_BOX_ERROR',
    AddDevBoxFailed: 'ADD_DEV_BOX_FAILED',
    AddDevBoxOperationError: 'ADD_DEV_BOX_OPERATION_ERROR',
    AddDevBoxOperationFailed: 'ADD_DEV_BOX_OPERATION_FAILED',
    AddDevBoxOperationSuccess: 'ADD_DEV_BOX_OPERATION_SUCCESS',
    ClearAddDevBoxFailure: 'CLEAR_ADD_DEV_BOX_FAILURE',
    DeleteDevBox: 'DELETE_DEV_BOX',
    DeleteDevBoxAccepted: 'DELETE_DEV_BOX_ACCEPTED',
    DeleteDevBoxError: 'DELETE_DEV_BOX_ERROR',
    DeleteDevBoxFailed: 'DELETE_DEV_BOX_FAILED',
    DeleteDevBoxSuccess: 'DELETE_DEV_BOX_SUCCESS',
    DiscoverDevBoxesInTenant: 'DISCOVER_DEV_BOXES_IN_TENANT',
    DiscoverDevBoxesInTenantError: 'DISCOVER_DEV_BOXES_IN_TENANT_ERROR',
    DiscoverDevBoxesInTenantFailed: 'DISCOVER_DEV_BOXES_IN_TENANT_FAILED',
    DiscoverDevBoxesInTenantSuccess: 'DISCOVER_DEV_BOXES_IN_TENANT_SUCCESS',
    HibernateDevBox: 'HIBERNATE_DEV_BOX',
    HibernateDevBoxAccepted: 'HIBERNATE_DEV_BOX_ACCEPTED',
    HibernateDevBoxError: 'HIBERNATE_DEV_BOX_ERROR',
    HibernateDevBoxFailed: 'HIBERNATE_DEV_BOX_FAILED',
    HibernateDevBoxSuccess: 'HIBERNATE_DEV_BOX_SUCCESS',
    ListDevBoxes: 'LIST_DEV_BOXES',
    ListDevBoxesError: 'LIST_DEV_BOXES_ERROR',
    ListDevBoxesFailed: 'LIST_DEV_BOXES_FAILED',
    ListDevBoxesSuccess: 'LIST_DEV_BOXES_SUCCESS',
    PollNonTerminalDevBox: 'POLL_NON_TERMINAL_DEV_BOX',
    PollNonTerminalDevBoxComplete: 'POLL_NON_TERMINAL_DEV_BOX_COMPLETE',
    PollNonTerminalDevBoxError: 'POLL_NON_TERMINAL_DEV_BOX_ERROR',
    RepairDevBox: 'REPAIR_DEV_BOX',
    RepairDevBoxAccepted: 'REPAIR_DEV_BOX_ACCEPTED',
    RepairDevBoxError: 'REPAIR_DEV_BOX_ERROR',
    RepairDevBoxFailed: 'REPAIR_DEV_BOX_FAILED',
    RepairDevBoxSuccess: 'REPAIR_DEV_BOX_SUCCESS',
    RestartDevBox: 'RESTART_DEV_BOX',
    RestartDevBoxAccepted: 'RESTART_DEV_BOX_ACCEPTED',
    RestartDevBoxError: 'RESTART_DEV_BOX_ERROR',
    RestartDevBoxFailed: 'RESTART_DEV_BOX_FAILED',
    RestartDevBoxSuccess: 'RESTART_DEV_BOX_SUCCESS',
    ResumeDevBox: 'RESUME_DEV_BOX',
    ResumeDevBoxAccepted: 'RESUME_DEV_BOX_ACCEPTED',
    ResumeDevBoxError: 'RESUME_DEV_BOX_ERROR',
    ResumeDevBoxFailed: 'RESUME_DEV_BOX_FAILED',
    ResumeDevBoxSuccess: 'RESUME_DEV_BOX_SUCCESS',
    ShutdownDevBox: 'SHUTDOWN_DEV_BOX',
    ShutdownDevBoxAccepted: 'SHUTDOWN_DEV_BOX_ACCEPTED',
    ShutdownDevBoxError: 'SHUTDOWN_DEV_BOX_ERROR',
    ShutdownDevBoxFailed: 'SHUTDOWN_DEV_BOX_FAILED',
    ShutdownDevBoxSuccess: 'SHUTDOWN_DEV_BOX_SUCCESS',
    StartDevBox: 'START_DEV_BOX',
    StartDevBoxAccepted: 'START_DEV_BOX_ACCEPTED',
    StartDevBoxError: 'START_DEV_BOX_ERROR',
    StartDevBoxFailed: 'START_DEV_BOX_FAILED',
    StartDevBoxSuccess: 'START_DEV_BOX_SUCCESS',
};

/**
 * Payloads
 */

export interface AddDevBoxPayload extends IndexedPayload {
    name: string;
    poolName: string;
    customizationGroupId?: string;
    taskList?: PutCustomizationTask[];
}

export type AddDevBoxAcceptedPayload = IndexedPayload & ResultPayload<DevBox>;
export type AddDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type AddDevBoxFailedPayload = FailedPayload & IndexedPayload;
export type AddDevBoxOperationFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;
export type AddDevBoxOperationSuccessPayload = IndexedPayload & ResultPayload<DevBox>;

export type DeleteDevBoxErrorPayload = IndexedPayload & ErrorPayload;
export type DeleteDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;

export type HibernateDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type HibernateDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;
export type HibernateDevBoxSuccessPayload = IndexedPayload & ResultPayload<DevBox>;

export type ListDevBoxesErrorPayload = ErrorPayload & IndexedPayload;
export type ListDevBoxesFailedPayload = FailedPayload & IndexedPayload;
export type ListDevBoxesSuccessPayload = IndexedPayload & ResultPayload<DevBox[]>;

export type PollNonTerminalDevBoxCompletePayload = IndexedPayload & OptionalResultPayload<DevBox>;

export type RepairDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type RepairDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;

export interface RepairDevBoxSuccessPayload extends IndexedPayload, ResultPayload<DevBox> {
    repairOperationResult?: DevBoxRepairOperationResult;
}

export type RestartDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type RestartDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;
export type RestartDevBoxSuccessPayload = IndexedPayload & ResultPayload<DevBox>;

export type ResumeDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type ResumeDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;
export type ResumeDevBoxSuccessPayload = IndexedPayload & ResultPayload<DevBox>;

export type ShutdownDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type ShutdownDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;
export type ShutdownDevBoxSuccessPayload = IndexedPayload & ResultPayload<DevBox>;

export type StartDevBoxErrorPayload = ErrorPayload & IndexedPayload;
export type StartDevBoxFailedPayload = FailedPayload & IndexedPayload & OptionalResultPayload<DevBox>;
export type StartDevBoxSuccessPayload = IndexedPayload & ResultPayload<DevBox>;

/**
 * Action shorthands
 */

export type AddDevBoxAction = ReturnType<typeof addDevBox>;
export type AddDevBoxAcceptedAction = ReturnType<typeof addDevBoxAccepted>;
export type DeleteDevBoxAction = ReturnType<typeof deleteDevBox>;
export type DeleteDevBoxAcceptedAction = ReturnType<typeof deleteDevBoxAccepted>;
export type DiscoverDevBoxesInTenantAction = ReturnType<typeof discoverDevBoxesInTenant>;
export type HibernateDevBoxAction = ReturnType<typeof hibernateDevBox>;
export type HibernateDevBoxAcceptedAction = ReturnType<typeof hibernateDevBoxAccepted>;
export type ListDevBoxesAction = ReturnType<typeof listDevBoxes>;
export type PollNonTerminalDevBoxAction = ReturnType<typeof pollNonTerminalDevBox>;
export type RepairDevBoxAction = ReturnType<typeof repairDevBox>;
export type RepairDevBoxAcceptedAction = ReturnType<typeof repairDevBoxAccepted>;
export type RestartDevBoxAction = ReturnType<typeof restartDevBox>;
export type RestartDevBoxAcceptedAction = ReturnType<typeof restartDevBoxAccepted>;
export type ResumeDevBoxAction = ReturnType<typeof resumeDevBox>;
export type ResumeDevBoxAcceptedAction = ReturnType<typeof resumeDevBoxAccepted>;
export type ShutdownDevBoxAction = ReturnType<typeof shutdownDevBox>;
export type ShutdownDevBoxAcceptedAction = ReturnType<typeof shutdownDevBoxAccepted>;
export type StartDevBoxAction = ReturnType<typeof startDevBox>;
export type StartDevBoxAcceptedAction = ReturnType<typeof startDevBoxAccepted>;
