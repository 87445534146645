import { Dialog, DialogType, IDialogContentProps, IModalProps, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useChangeEnvironmentExpirationDialogContext } from '../../../hooks/context/dialogs';
import { Status } from '../../../models/common';
import { Environment, EnvironmentUpdateProperties } from '../../../models/environment';
import { updateEnvironment } from '../../../redux/actions/environment/environment-action-creators';
import { getStatusForUpdateEnvironment } from '../../../redux/selector/environment-selectors';
import { ReturnVoid } from '../../../types/return-void';
import ChangeEnvironmentExpirationDialogForm from './change-environment-expiration-dialog-form';

export interface ChangeEnvironmentExpirationDialogComponentProps extends ChangeEnvironmentExpirationDialogProps {
    onDismiss: () => void;
    onUpdateEnvironment: ReturnVoid<typeof updateEnvironment>;
    statusForUpdateEnvironment: Status;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'ChangeEnvironmentExpirationDialog_CloseButton_AriaLabel',
        defaultMessage: 'Dismiss dialog',
        description: "Aria label for the redeploy dialog's close button",
    },
    cancelButtonText: {
        id: 'ChangeEnvironmentExpirationDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Cancel button to dismiss the redeploy dialog and perform no operation',
    },
});

/**
 * Styles
 */

// Not able to find a work around from using !important here. The model automatically sets the width of the dialog.
const modalWidth = '560px !important';

const useModalStyles = makeStyles({
    main: {
        maxWidth: modalWidth,
        width: modalWidth,
    },
});

const useDialogStyles = makeStyles({
    inner: {
        padding: '0px 24px 24px 24px',
    },
});

/**
 * END Styles
 */

export const ChangeEnvironmentExpirationDialogComponent: React.FC<ChangeEnvironmentExpirationDialogComponentProps> = (
    props: ChangeEnvironmentExpirationDialogComponentProps
) => {
    const { environment, hidden, onDismiss, onUpdateEnvironment, statusForUpdateEnvironment } = props;
    const { name } = environment;

    // Intl hooks
    const { formatMessage } = useIntl();
    // Style hooks
    const dialogStyles = useDialogStyles();
    const modalStyles = useModalStyles();

    // Memoized data
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    const values = React.useMemo(() => ({ name }), [name]);

    const dialogContentProps = React.useMemo<IDialogContentProps>(
        () => ({
            styles: dialogStyles,
            title: (
                <FormattedMessage
                    id="ChangeEnvironmentExpirationDialog_Header_Text"
                    defaultMessage="Change {name} expiration"
                    description="Title of the change expiration dialog. {name} should not be localized."
                    values={values}
                />
            ),
            type: DialogType.normal,
        }),
        [dialogStyles, values]
    );

    // Callback hooks
    const onSubmit = React.useCallback(
        (id: string, properties: EnvironmentUpdateProperties) => {
            onUpdateEnvironment({ id, ...properties });
        },
        [onUpdateEnvironment]
    );

    return (
        <Dialog
            closeButtonAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
            dialogContentProps={dialogContentProps}
            hidden={hidden}
            modalProps={modalProps}
            onDismiss={onDismiss}
        >
            <ChangeEnvironmentExpirationDialogForm
                environment={environment}
                onDismiss={onDismiss}
                onSubmit={onSubmit}
                status={statusForUpdateEnvironment}
            />
        </Dialog>
    );
};

export interface ChangeEnvironmentExpirationDialogProps {
    hidden?: boolean;
    onDismiss: () => void;
    environment: Environment;
}

export const ChangeEnvironmentExpirationDialogContainer: React.FC<ChangeEnvironmentExpirationDialogProps> = (
    props: ChangeEnvironmentExpirationDialogProps
) => {
    //Application state hooks
    const statusForUpdateEnvironment = useSelector(getStatusForUpdateEnvironment);

    // Action hooks
    const onUpdateEnvironment = useActionCreator(updateEnvironment);

    return (
        <ChangeEnvironmentExpirationDialogComponent
            onUpdateEnvironment={onUpdateEnvironment}
            statusForUpdateEnvironment={statusForUpdateEnvironment}
            {...props}
        />
    );
};

export const ChangeEnvironmentExpirationDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface, properties } = useChangeEnvironmentExpirationDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <ChangeEnvironmentExpirationDialogContainer {...properties} hidden={!isOpen} onDismiss={closeSurface} />;
};
