import { StorageType } from '../../constants/storage';
import { Severity } from '../../constants/telemetry';
import { ClientError } from '../../models/common';
import { browserName, getIsInPrivate } from '../../utilities/browser';
import { trackTrace } from '../../utilities/telemetry/channel';
import { tryAndConfirm } from '../../utilities/try-and-confirm';
import { tryOrDefault } from '../../utilities/try-or-default';

const performStorageOperation = <TReturnType>(
    storageType: StorageType,
    operation: (storage: Storage) => TReturnType
): TReturnType => {
    try {
        switch (storageType) {
            case StorageType.LocalStorage:
                return operation(localStorage);
            case StorageType.SessionStorage:
                return operation(sessionStorage);
            default:
                trackTrace(
                    `Operation requested for unknown storage type "${storageType}", defaulting to ${StorageType.SessionStorage}`,
                    { severity: Severity.Warning }
                );
                return operation(sessionStorage);
        }
    } catch (error) {
        throw new ClientError(error);
    }
};

export const clear = (storageType: StorageType): void => {
    performStorageOperation(storageType, (storage) => storage.clear());
};

export const getItem = (storageType: StorageType, key: string): string => {
    return performStorageOperation(storageType, (storage) => storage.getItem(key) ?? '');
};

export const getPreferredStorageTypeForBrowser = async (): Promise<StorageType> => {
    const isInPrivate = await getIsInPrivate();

    // Decide which storage kind to use based on browser type + is In Private
    switch (browserName) {
        case 'edge':
            return StorageType.LocalStorage;

        default:
            // TODO: in future, consider whether we can present messages to the user indicating that they should
            // avoid using the app in certain browsers w/ Incognito/InPrivate
            return isInPrivate ? StorageType.SessionStorage : StorageType.LocalStorage;
    }
};

export const removeItem = (storageType: StorageType, key: string): void => {
    performStorageOperation(storageType, (storage) => storage.removeItem(key));
};

export const setItem = (storageType: StorageType, key: string, value: string): void => {
    // TODO: see if we need to handle case where access to storage is blocked
    performStorageOperation(storageType, (storage) => storage.setItem(key, value));
};

export const tryClear = tryAndConfirm(clear);
export const tryGetItem = tryOrDefault(getItem);
export const tryRemoveItem = tryAndConfirm(removeItem);
export const trySetItem = tryAndConfirm(setItem);
