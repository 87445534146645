import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { DropdownDividerOption } from '../../common/form/dropdown/divider';
import {
    ValueDropdown,
    ValueDropdownOption,
    ValueDropdownWrapperProps,
} from '../../common/form/dropdown/value-dropdown';
import { DelayShutdownDropdownItem } from './delay-shutdown-dropdown-item';
import { DelayShutdownDropdownOption } from './model';

export type DateDropdownProps = ValueDropdownWrapperProps<DelayShutdownDropdownOption>;

const getOptionKey = (date: DelayShutdownDropdownOption): string => date.dateValue.getTime().toLocaleString();
const onRenderOption = (date: DelayShutdownDropdownOption): JSX.Element => <DelayShutdownDropdownItem date={date} />;

const messages = defineMessages({
    dropdownAriaLabel: {
        id: 'DelayShutdownDialog_Dropdown_AriaLabel',
        defaultMessage: 'Delay shutdown until',
        description: 'Aria label for dropdown in the "Delay shutdown" dialog',
    },
});

export const DelayShutdownDropdown: React.FC<DateDropdownProps> = (props: DateDropdownProps) => {
    // Intl hooks
    const { formatMessage } = useIntl();

    const { options } = props;

    const optionsWithDivider: ValueDropdownOption<DelayShutdownDropdownOption>[] = React.useMemo(() => {
        //If there is only option, don't insert divider.
        if (options.length > 1 && options[options.length - 1].isSkip) {
            const optionsWithDivider: ValueDropdownOption<DelayShutdownDropdownOption>[] = options.slice(
                0,
                options.length - 1
            );
            optionsWithDivider.push(DropdownDividerOption('skip-delay-divider'), options[options.length - 1]);

            return optionsWithDivider;
        }
        return options;
    }, [options]);

    return (
        <ValueDropdown<DelayShutdownDropdownOption>
            {...props}
            options={optionsWithDivider}
            getOptionKey={getOptionKey}
            onRenderOption={onRenderOption}
            ariaLabel={formatMessage(messages.dropdownAriaLabel)}
        />
    );
};

export default DelayShutdownDropdown;
