import { ClientError } from '../../models/common';
import { CapitalizedUnionMap } from '../../types/union-map';
import { isUndefinedOrWhiteSpace } from '../string';
import { tryOrDefault } from '../try-or-default';
import { parseResourceId, ResourceIdComponents } from './parse-resource-id';

type ResourceIdComponentName =
    | 'resourceGroupName'
    | 'resourceName'
    | 'resourceProviderNamespace'
    | 'resourceType'
    | 'subResourceName'
    | 'subResourceProviderNamespace'
    | 'subResourceType'
    | 'subscriptionId';

export const ResourceIdComponentName: CapitalizedUnionMap<ResourceIdComponentName> = {
    ResourceGroupName: 'resourceGroupName',
    ResourceName: 'resourceName',
    ResourceProviderNamespace: 'resourceProviderNamespace',
    ResourceType: 'resourceType',
    SubResourceName: 'subResourceName',
    SubResourceProviderNamespace: 'subResourceProviderNamespace',
    SubResourceType: 'subResourceType',
    SubscriptionId: 'subscriptionId',
};

const extractComponent = (components: ResourceIdComponents, componentName: ResourceIdComponentName): string => {
    const componentValue = getComponent(components, componentName);

    if (isUndefinedOrWhiteSpace(componentValue)) {
        throw new ClientError(`Expected resource ID to contain ${getComponentFriendlyName(componentName)}`);
    }

    return componentValue;
};

const getComponent = (components: ResourceIdComponents, componentName: ResourceIdComponentName): string | undefined => {
    switch (componentName) {
        case ResourceIdComponentName.ResourceGroupName:
            return components.resourceGroupName;
        case ResourceIdComponentName.ResourceName:
            return components.resource?.name;
        case ResourceIdComponentName.ResourceProviderNamespace:
            return components.resource?.providerNamespace;
        case ResourceIdComponentName.ResourceType:
            return components.resource?.type;
        case ResourceIdComponentName.SubResourceName:
            return components.subResource?.name;
        case ResourceIdComponentName.SubResourceProviderNamespace:
            return components.subResource?.providerNamespace;
        case ResourceIdComponentName.SubResourceType:
            return components.subResource?.type;
        case ResourceIdComponentName.SubscriptionId:
            return components.subscriptionId;
        default:
            throw new ClientError('Could not get unknown resource ID component');
    }
};

const getComponentFriendlyName = (componentName: ResourceIdComponentName): string => {
    switch (componentName) {
        case ResourceIdComponentName.ResourceGroupName:
            return 'resource group name';
        case ResourceIdComponentName.ResourceName:
            return 'resource name';
        case ResourceIdComponentName.ResourceProviderNamespace:
            return 'provider namespace for resource';
        case ResourceIdComponentName.ResourceType:
            return 'resource type';
        case ResourceIdComponentName.SubResourceName:
            return 'sub-resource name';
        case ResourceIdComponentName.SubResourceProviderNamespace:
            return 'provider namespace for sub-resource';
        case ResourceIdComponentName.SubResourceType:
            return 'type for sub-resource';
        case ResourceIdComponentName.SubscriptionId:
            return 'subscription ID';
        default:
            return 'unknown resource ID component';
    }
};

export const getResourceIdComponent = (resourceId: string, componentName: ResourceIdComponentName): string => {
    const parsedResourceId = parseResourceId(resourceId);
    return extractComponent(parsedResourceId, componentName);
};

export const getResourceIdComponents = (resourceId: string, ...componentNames: ResourceIdComponentName[]): string[] => {
    const parsedResourceId = parseResourceId(resourceId);
    return componentNames.map((componentName) => extractComponent(parsedResourceId, componentName));
};

export const tryGetResourceIdComponent = tryOrDefault(getResourceIdComponent);
export const tryGetResourceIdComponents = tryOrDefault(getResourceIdComponents);
