import { createSelector } from '@reduxjs/toolkit';
import { keys } from '../../../utilities/serializable-map';
import { StoreStateSelector } from '../../selector/common';
import { getProjectEnvironmentTypesFromDataplane } from '../../selector/project-environment-type-from-dataplane-selectors';

/**
 * Application state selectors
 */

export const getIdsForProjectEnvironmentTypesFromDataplane: StoreStateSelector<string[]> = createSelector(
    [getProjectEnvironmentTypesFromDataplane],
    (projectEnvironmentTypes) => keys(projectEnvironmentTypes)
);
