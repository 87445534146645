import * as React from 'react';

interface Props {
    placeholder: string;
}

export type PoolDropdownPlaceholderProps = Props;

const style: React.CSSProperties = {
    display: 'block',
    width: 'inherit',
    height: 'inherit',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    paddingTop: '6px',
};

export const PoolDropdownPlaceholder: React.FC<Props> = (props) => {
    const { placeholder } = props;

    return <span style={style}>{placeholder}</span>;
};

export default PoolDropdownPlaceholder;
