import { createSelector } from '@reduxjs/toolkit';
import { keys } from '../../../utilities/serializable-map';
import { StoreStateSelector } from '../../selector/common';
import { getProjectsByDiscoveryServiceURI } from '../../selector/project-from-discovery-service-selectors';

/**
 * Application state selectors
 */

export const getDataPlaneIdsForProjects: StoreStateSelector<string[]> = createSelector(
    [getProjectsByDiscoveryServiceURI],
    (projects) => keys(projects)
);
