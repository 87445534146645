import { createAction } from '@reduxjs/toolkit';
import { Action as ReduxAction } from 'redux';
import { IsVoid } from '../../types/is-void';
import { OneOrMany } from '../../types/one-or-many';
import { toMany } from '../../utilities/one-or-many';
import { createActivityId } from '../../utilities/telemetry/helpers';
import { Action, AsyncContent, GroupablePayload, Metadata, TrackableMetadata } from './core-actions';

const prepareMetaForNewActivity = <TMeta extends Partial<TrackableMetadata>>(
    meta: TMeta | undefined
): TMeta | undefined => ({ activityId: createActivityId(), ...(meta ?? {}) } as TMeta);

/**
 * Types
 */

type BaseActionCreator<
    TPayload = void,
    TAdditionalMeta = void,
    TAwaited = void,
    TType extends string = string,
    TActionPayload = TPayload
> = {
    match: (action: ReduxAction<unknown>) => action is Action<TActionPayload, TAdditionalMeta, TAwaited, TType>;
    type: TType;
} & IsVoid<
    TPayload,
    (meta?: Metadata<TAdditionalMeta>) => Action<TActionPayload, TAdditionalMeta, TAwaited, TType>,
    (payload: TPayload, meta?: Metadata<TAdditionalMeta>) => Action<TActionPayload, TAdditionalMeta, TAwaited, TType>
>;

/**
 * A Redux action creator implementing the standards outlined in this doc: https://dev.azure.com/devdiv/OnlineServices/_wiki/wikis/OnlineServices.wiki/35235/Proposal-for-unified-approach-to-our-Redux-logic
 *
 * @param TPayload `payload` type of created action. If void, `payload` is not included.
 * @param TAdditionalMeta additional properties to allow on `meta` of created action. If void, the defaults specified by {@link Metadata} are used.
 * @param TAwaited type of result sent back by the created action when awaited. If void, `async` is not included.
 * @param TType type of `type` of created action. Defaults to string.
 */
export type ActionCreator<
    TPayload = void,
    TAdditionalMeta = void,
    TAwaited = void,
    TType extends string = string
> = BaseActionCreator<TPayload, TAdditionalMeta, TAwaited, TType>;

/**
 * A groupable Redux action creator implementing the standards outlined in this doc: https://dev.azure.com/devdiv/OnlineServices/_wiki/wikis/OnlineServices.wiki/35235/Proposal-for-unified-approach-to-our-Redux-logic
 *
 * @param TPayload `payload` type of created action.
 * @param TAdditionalMeta additional properties to allow on `meta` of created action. If void, the defaults specified by {@link Metadata} are used.
 * @param TAwaited type of result sent back by the created action when awaited. If void, `async` is not included.
 * @param TType type of `type` of created action. Defaults to string.
 */
export type GroupableActionCreator<
    TPayload,
    TAdditionalMeta = void,
    TAwaited = void,
    TType extends string = string
> = BaseActionCreator<
    OneOrMany<TPayload>,
    TAdditionalMeta,
    IsVoid<TAwaited, void, OneOrMany<TAwaited>>,
    TType,
    GroupablePayload<TPayload, TAdditionalMeta, TType>
>;

/**
 * Action creator factories
 */

/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Justification: function overloads can only be provided via old-school functions.

/**
 * Creates an action creator.
 *
 * @param type value of `type` on every created action.
 * @param options Options for the action creator, including:
 * - `async`: if true, action creator will create awaitable actions.
 * - `groupable`: if true, action creator will allow multiple payloads to be submitted in one action.
 * - `startsActivity`: if true, action creator adds a unique `activityId` to `meta` on every action.
 */
export function createActionCreator<TPayload, TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options?: { startsActivity?: boolean }
): ActionCreator<TPayload, TAdditionalMeta, void, TType>;

/**
 * Creates an action creator.
 *
 * @param type value of `type` on every created action.
 * @param options Options for the action creator, including:
 * - `async`: if true, action creator will create awaitable actions.
 * - `groupable`: if true, action creator will allow multiple payloads to be submitted in one action.
 * - `startsActivity`: if true, action creator adds a unique `activityId` to `meta` on every action.
 */
export function createActionCreator<TPayload, TAwaited, TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options: { async: true; startsActivity?: boolean }
): ActionCreator<TPayload, TAdditionalMeta, TAwaited, TType>;

/**
 * Creates an action creator.
 *
 * @param type value of `type` on every created action.
 * @param options Options for the action creator, including:
 * - `async`: if true, action creator will create awaitable actions.
 * - `groupable`: if true, action creator will allow multiple payloads to be submitted in one action.
 * - `startsActivity`: if true, action creator adds a unique `activityId` to `meta` on every action.
 */
export function createActionCreator<TPayload, TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options: { groupable: true; startsActivity?: boolean }
): GroupableActionCreator<TPayload, TAdditionalMeta, void, TType>;

/**
 * Creates an action creator.
 *
 * @param type value of `type` on every created action.
 * @param options Options for the action creator, including:
 * - `async`: if true, action creator will create awaitable actions.
 * - `groupable`: if true, action creator will allow multiple payloads to be submitted in one action.
 * - `startsActivity`: if true, action creator adds a unique `activityId` to `meta` on every action.
 */
export function createActionCreator<TPayload, TAwaited, TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options: { async: true; groupable: true; startsActivity?: boolean }
): GroupableActionCreator<TPayload, TAdditionalMeta, TAwaited, TType>;

export function createActionCreator<TPayload, TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options?: { async?: true; groupable?: true; startsActivity?: boolean }
): unknown {
    const { async, groupable, startsActivity } = options ?? {};

    // RTK's createAction has a couple of incompatibilites with our action typedef:
    //      - async isn't accounted for and is also ignored by their action prepper
    //      - meta is treated as required when it's not void. We want it to be optional.
    // Because of that, we rely on it for core prep but also wrap it to suit our needs.
    const baseActionCreator = createAction(type, (payload: TPayload) => ({
        payload,
    }));

    if (groupable) {
        const groupableActionCreator = (
            payloads: OneOrMany<TPayload>,
            meta?: Metadata<TAdditionalMeta>
        ):
            | Action<GroupablePayload<TPayload, TAdditionalMeta, TType>, TAdditionalMeta, OneOrMany<unknown>, TType>
            | Action<GroupablePayload<TPayload, TAdditionalMeta, TType>, TAdditionalMeta, void, TType> => {
            const preparedMeta = startsActivity ? prepareMetaForNewActivity(meta) : meta;

            const actions = toMany(payloads).map((payload) => {
                const { payload: preparedPayload, type: preparedType } = baseActionCreator(payload);

                return {
                    payload: preparedPayload,
                    type: preparedType,
                    // Meta from parent is shared to all actions in group
                    ...(preparedMeta ? { meta: preparedMeta } : {}),
                };
            });

            // If there's only one action, return that directly, with the async content when applicable
            if (actions.length === 1) {
                const [action] = actions;

                return {
                    ...action,
                    ...(async ? { async: AsyncContent<OneOrMany<unknown>>() } : {}),
                };
            }

            // Otherwise, put generated actions in payload
            return {
                payload: { actions },
                type,
                ...(async ? { async: AsyncContent<OneOrMany<unknown>>() } : {}),
                ...(preparedMeta ? { meta: preparedMeta } : {}),
            };
        };

        groupableActionCreator.match = baseActionCreator.match;
        groupableActionCreator.toString = baseActionCreator.toString;
        groupableActionCreator.type = baseActionCreator.type;

        return groupableActionCreator;
    }

    const actionCreator = (
        payload: TPayload,
        meta?: Metadata<TAdditionalMeta>
    ): Action<TPayload, TAdditionalMeta, unknown, TType> | Action<TPayload, TAdditionalMeta, void, TType> => {
        const preparedMeta = startsActivity ? prepareMetaForNewActivity(meta) : meta;
        const { payload: preparedPayload, type: preparedType } = baseActionCreator(payload);

        return {
            payload: preparedPayload,
            type: preparedType,
            ...(async ? { async: AsyncContent<unknown>() } : {}),
            ...(preparedMeta ? { meta: preparedMeta } : {}),
        };
    };

    actionCreator.match = baseActionCreator.match;
    actionCreator.toString = baseActionCreator.toString;
    actionCreator.type = baseActionCreator.type;

    return actionCreator;
}

/**
 * Creates an action creator which doesn't accept payloads.
 *
 * @param type value of `type` on every created action.
 * @param options Options for the action creator, including:
 * - `async`: if true, action creator will create awaitable actions.
 * - `startsActivity`: if true, action creator adds a unique `activityId` to `meta` on every action.
 */
export function createActionCreatorWithoutPayload<TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options?: { startsActivity?: boolean }
): ActionCreator<void, TAdditionalMeta, void, TType>;

/**
 * Creates an action creator which doesn't accept payloads.
 *
 * @param type value of `type` on every created action.
 * @param options Options for the action creator, including:
 * - `async`: if true, action creator will create awaitable actions.
 * - `startsActivity`: if true, action creator adds a unique `activityId` to `meta` on every action.
 */
export function createActionCreatorWithoutPayload<TAwaited, TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options: { async: true; startsActivity?: boolean }
): ActionCreator<void, TAdditionalMeta, TAwaited, TType>;

export function createActionCreatorWithoutPayload<TAdditionalMeta = void, TType extends string = string>(
    type: TType,
    options?: { async?: true; startsActivity?: boolean }
): unknown {
    const { async, startsActivity } = options ?? {};

    // RTK's createAction has a couple of incompatibilites with our action typedef:
    //      - async isn't accounted for and is also ignored by their action prepper
    //      - meta is treated as required when it's not void. We want it to be optional.
    // Because of that, we rely on it for core prep but also wrap it to suit our needs.
    const baseActionCreator = createAction(type);

    const actionCreator = (
        meta?: Metadata<TAdditionalMeta>
    ): Action<void, TAdditionalMeta, unknown, TType> | Action<void, TAdditionalMeta, void, TType> => {
        const { type: preparedType } = baseActionCreator();
        const preparedMeta = startsActivity ? prepareMetaForNewActivity(meta) : meta;

        return {
            type: preparedType,
            ...(async ? { async: AsyncContent<unknown>() } : {}),
            ...(preparedMeta ? { meta: preparedMeta } : {}),
        };
    };

    actionCreator.match = baseActionCreator.match;
    actionCreator.toString = baseActionCreator.toString;
    actionCreator.type = baseActionCreator.type;

    return actionCreator;
}

/* eslint-enable prefer-arrow/prefer-arrow-functions */

/**
 * This type is used in action-creator-based saga `take` effects.
 * It allows us to write effects that take action creators, rather than string action types, as the match pattern.
 */
export type ActionCreatorPattern<P, Meta, Awaited, TType extends string, TActionPayload> =
    | BaseActionCreator<P, Meta, Awaited, TType, TActionPayload>
    | BaseActionCreator<P, Meta, Awaited, TType, TActionPayload>[];
