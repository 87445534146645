import { createSelector } from '@reduxjs/toolkit';
import { Severity } from '../../../constants/telemetry';
import { compact, unique } from '../../../utilities/array';
import { tryGetResourceIdComponent } from '../../../utilities/resource-manager/get-resource-id-components';
import { keys } from '../../../utilities/serializable-map';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { trackTrace } from '../../../utilities/telemetry/channel';
import { StoreStateSelector } from '../../selector/common';
import { getIsProjectAuthorizedForDevBoxRead, getPermissionsForProjects } from '../../selector/permission-selectors';
import { getProjectsByResourceId } from '../../selector/project-selectors';

/**
 * Application state selectors
 */

export const getSubscriptionIdsForProjectsAuthorizedForDevBoxRead: StoreStateSelector<string[]> = createSelector(
    [getProjectsByResourceId, getPermissionsForProjects],
    (projects, permissions) => {
        const projectResourceIds = keys(projects).filter((id) => getIsProjectAuthorizedForDevBoxRead(permissions, id));

        const subscriptionIds = projectResourceIds.map((id) => {
            const subscriptionId = tryGetResourceIdComponent(id, 'subscriptionId');

            // If dev center name is undefined or white space, this project resource has an invalid devCenterId
            if (isUndefinedOrWhiteSpace(subscriptionId)) {
                trackTrace(
                    'Unexpected state: subscription ID was undefined or white space. This usually means the resource ID is malformed.',
                    { severity: Severity.Warning }
                );
                return undefined;
            }

            return subscriptionId;
        });

        return unique(compact(subscriptionIds));
    }
);
