import { Checkbox as FluentCheckbox, ICheckboxProps, Label, Stack, makeStyles } from '@fluentui/react';
import React from 'react';

interface CheckboxProps extends Omit<ICheckboxProps, 'label' | 'ariaLabel'> {
    value: boolean;
    onChangeHandler: (value: boolean) => void;
    label: string;
    checkboxLabel: string;
    checkboxAriaLabel: string;
}

const useLabelStyles = makeStyles({
    root: {
        paddingBottom: '10px',
        marginTop: '15px',
    },
});

const containerTokens = { childrenGap: 8 };

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const { value, onChangeHandler, label, checkboxLabel, checkboxAriaLabel } = props;

    const environmentExpirationEnabledOnChange = React.useCallback(
        (_event?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
            onChangeHandler(!!checked);
        },
        [onChangeHandler]
    );

    const labelStyles = useLabelStyles();

    return (
        <Stack tokens={containerTokens}>
            <Stack.Item>
                <Label styles={labelStyles}>{label}</Label>
            </Stack.Item>
            <Stack.Item>
                <FluentCheckbox
                    {...props}
                    label={checkboxLabel}
                    ariaLabel={checkboxAriaLabel}
                    onChange={environmentExpirationEnabledOnChange}
                    checked={value}
                />
            </Stack.Item>
        </Stack>
    );
};

export default Checkbox;
