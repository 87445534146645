import { statusAdapter } from '../adapters/common/status-adapter';
import { remoteConnectionAdapter } from '../adapters/remote-connection-adapters';
import { StoreState } from '../store/store-state';
import { createMapFromEntitiesSelector } from './common';

/**
 * Entity state selectors
 */

const remoteConnectionsSelectors = remoteConnectionAdapter.getSelectors<StoreState>(
    (store) => store.remoteConnectionStore.data.remoteConnections
);

const statusesForGetRemoteConnectionSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.remoteConnectionStore.status.getRemoteConnection
);

/**
 * Composed selectors
 */

export const getRemoteConnectionsByDevBoxId = createMapFromEntitiesSelector(remoteConnectionsSelectors.selectAll);

export const getStatusesForGetRemoteConnectionByDevBoxId = createMapFromEntitiesSelector(
    statusesForGetRemoteConnectionSelectors.selectAll
);
