import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { initializeHomeListenerSaga } from './initialize-home';
import { loadAddDevBoxFormContentListenerSaga } from './load-add-dev-box-form-content';
import { loadAddEnvironmentFormContentListenerSaga } from './load-add-environment-form-content';
import { loadBackgroundResourcesForHomeListenerSaga } from './load-background-resources-for-home';
import { loadControlPlaneResourcesForHomeListenerSaga } from './load-control-plane-resources-for-home';
import { loadDevBoxCardContentListenerSaga } from './load-dev-box-card-content';
import { loadEnvironmentCardContentListenerSaga } from './load-environment-card-content';
import { loadResourcesForHomeListenerSaga } from './load-resources-for-home';
import { loadSecondaryDevBoxCardContentListenerSaga } from './load-secondary-dev-box-card-content';
import { syncDismissedHomeQuickActionsListenerSaga } from './sync-dismissed-home-quick-actions';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(initializeHomeListenerSaga),
        fork(loadAddDevBoxFormContentListenerSaga),
        fork(loadAddEnvironmentFormContentListenerSaga),
        fork(loadControlPlaneResourcesForHomeListenerSaga),
        fork(loadDevBoxCardContentListenerSaga),
        fork(loadEnvironmentCardContentListenerSaga),
        fork(loadResourcesForHomeListenerSaga),
        fork(syncDismissedHomeQuickActionsListenerSaga),
        fork(loadBackgroundResourcesForHomeListenerSaga),
        fork(loadSecondaryDevBoxCardContentListenerSaga),
    ]);
}
