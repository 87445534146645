import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import {
    GetLongRunningOperationStatusResponse,
    getLongRunningOperationStatus,
} from '../../../data/services/data-plane-api/long-running-operation';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { isLongRunningOperationInTerminalState } from '../../../utilities/long-running-operation';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import {
    pollOperationStatus,
    pollOperationStatusComplete,
    pollOperationStatusError,
    pollOperationStatusFailed,
} from '../../actions/long-running-operation/long-running-operation-action-creators';
import { PollLongRunningOperationAction } from '../../actions/long-running-operation/long-running-operation-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getPollingInitialDelayMs, getPollingIntervalMs } from '../../utilities/poll-action';

export function* pollLongRunningOperationSaga(action: PollLongRunningOperationAction): SagaIterator {
    let shouldPoll = true;
    const { payload, meta } = action;
    const { activityId } = meta ?? {};
    const { operationLocation } = payload;

    try {
        yield delay(getPollingInitialDelayMs(action));

        const pollingInterval = getPollingIntervalMs(action);

        while (shouldPoll) {
            const accessToken: string = yield putAndAwait(
                getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
            );

            const response: GetLongRunningOperationStatusResponse = yield call(
                getLongRunningOperationStatus,
                operationLocation,
                accessToken,
                activityId
            );

            if (isFailureResponse(response)) {
                // should we ignore / re-try under certain conditions or error codes?
                yield put(pollOperationStatusFailed({ failure: response }, meta));
                yield resolveAction(action, response);
                return;
            }

            const { data } = response;
            const status = data.status;

            shouldPoll = !isLongRunningOperationInTerminalState(status);

            if (shouldPoll) {
                yield delay(pollingInterval);
            } else {
                yield put(pollOperationStatusComplete({ result: response.data }, meta));
                yield resolveAction(action, response);
            }
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.PollLongRunningOperation);
        yield put(pollOperationStatusError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* pollLongRunningOperationListenerSaga(): SagaIterator {
    yield takeEvery(pollOperationStatus, pollLongRunningOperationSaga);
}
