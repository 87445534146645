import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    warmAllDevCenterNameRecords,
    warmAllDevCenterNameRecordsError,
    warmAllDevCenterNameRecordsFailed,
    warmAllDevCenterNameRecordsSuccess,
    warmDevCenterNameRecord,
} from '../../actions/dev-center/dev-center-action-creators';
import { WarmAllDevCenterNameRecordsAction } from '../../actions/dev-center/dev-center-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { getDevCenterEndpointsRequiringWarming } from './selectors';

export function* warmAllDevCenterNameRecordsSaga(action: WarmAllDevCenterNameRecordsAction): SagaIterator {
    const startTime = new Date();

    try {
        const devCenterEndpoints: string[] = yield select(getDevCenterEndpointsRequiringWarming);

        const responses: DataResponse[] = yield all(
            devCenterEndpoints.map((endpoint) => putAndAwait(warmDevCenterNameRecord({ id: endpoint })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.WarmAllDevCenterNameRecords, ...failures);
            yield put(warmAllDevCenterNameRecordsFailed({ failure }));
        } else {
            yield put(warmAllDevCenterNameRecordsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.WarmAllDevCenterNameRecords,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.WarmAllDevCenterNameRecords);
        yield put(warmAllDevCenterNameRecordsError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.WarmAllDevCenterNameRecords,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* warmAllDevCenterNameRecordsListenerSaga(): SagaIterator {
    yield takeLeading(warmAllDevCenterNameRecords, warmAllDevCenterNameRecordsSaga);
}
