import {
    IContextualMenuItem,
    IContextualMenuItemStyleProps,
    IContextualMenuItemStyles,
    IStyleFunctionOrObject,
} from '@fluentui/react';
import { IntlFormatters, MessageDescriptor } from 'react-intl';
import { Tenant } from '../../../models/resource-manager';
import { areStringsEquivalent, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { tenantPickerMessages } from './messages';

interface TenantDisplayNameFormatterValue {
    homeTenantId: string | undefined;
    tenant: Tenant;
}

const tenantDisplayNameFormatter = (
    homeTenantId: string | undefined,
    tenant: Tenant,
    formatters: IntlFormatters,
    messageDescriptor: MessageDescriptor
): string => {
    // If current tenant is the user's home tenant, or home tenant couldn't be determined for some reason, just show the
    // normal display name
    if (isUndefinedOrWhiteSpace(homeTenantId) || areStringsEquivalent(homeTenantId, tenant.tenantId, true)) {
        return tenant.displayName;
    }

    const { formatMessage } = formatters;

    return formatMessage(messageDescriptor, { tenantDisplayName: tenant.displayName });
};

export const formatTenantDisplayNameAriaLabel = (
    value: TenantDisplayNameFormatterValue,
    formatters: IntlFormatters
): string =>
    tenantDisplayNameFormatter(
        value.homeTenantId,
        value.tenant,
        formatters,
        tenantPickerMessages.guestTenantDisplayNameAriaLabel
    );

export const formatTenantDisplayNameText = (
    value: TenantDisplayNameFormatterValue,
    formatters: IntlFormatters
): string =>
    tenantDisplayNameFormatter(
        value.homeTenantId,
        value.tenant,
        formatters,
        tenantPickerMessages.guestTenantDisplayNameText
    );

export const getTenantPickerItems = (
    formatAriaLabel: (value: TenantDisplayNameFormatterValue) => string,
    formatText: (value: TenantDisplayNameFormatterValue) => string,
    homeTenantId: string | undefined,
    itemStyles: IStyleFunctionOrObject<IContextualMenuItemStyleProps, IContextualMenuItemStyles>,
    onMenuItemClicked: (tenant: Tenant) => void,
    tenants: Tenant[]
): IContextualMenuItem[] =>
    tenants.map<IContextualMenuItem>((tenant) => {
        const tenantDisplayNameFormatterValue: TenantDisplayNameFormatterValue = {
            homeTenantId,
            tenant,
        };

        return {
            ariaLabel: formatAriaLabel(tenantDisplayNameFormatterValue),
            key: tenant.tenantId,
            onClick: (_ev?: unknown) => {
                onMenuItemClicked(tenant);
            },
            itemProps: {
                styles: itemStyles,
            },
            // Note: it MAY be possible for a user to belong to two or more organizations that have the same display name.
            // In future, we may want to add some additional disambiguation to the text, e.g. tenant ID in parens.
            text: formatText(tenantDisplayNameFormatterValue),
        };
    });
