import * as React from 'react';

/** A hook to debounce the value of a variable by `delay` ms. Set `delayToUndefined` to use a different delay time for "clearing" (setting undefined) the value. By default, `delayToUndefined` = `delay`. */
export const useDebounce = <TValue>(value: TValue, delay: number, delayToUndefined = delay): TValue => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        // Allow for different delay time to clear the debounced value
        const debounceDelay = value === undefined ? delayToUndefined : delay;

        // Update debounced value after delay
        const debounceHandler = setTimeout(() => {
            setDebouncedValue(value);
        }, debounceDelay);

        // Clear timeout when invoked
        return () => {
            clearTimeout(debounceHandler);
        };
    }, [value, delay]);

    return debouncedValue;
};
