import * as React from 'react';
import { useIntl } from 'react-intl';
import { ValueDropdown, ValueDropdownWrapperProps } from '../value-dropdown';

export type NumberDropdownProps = ValueDropdownWrapperProps<number>;

const getOptionKey = (value: number) => (value === undefined ? '' : value.toString());

const NumberDropdown: React.FC<NumberDropdownProps> = (props) => {
    const { formatNumber } = useIntl();

    const getOptionText = React.useCallback((value: number) => formatNumber(value), [formatNumber]);

    return <ValueDropdown<number> {...props} getOptionKey={getOptionKey} getOptionText={getOptionText} />;
};

export default NumberDropdown;
