import { createSelector } from 'reselect';
import { Language } from '../../constants/localization';
import { LocaleMessageData } from '../../language/languages';
import { LocalizationDataState, LocalizationSettingState, LocalizationState } from '../store/localization-state';
import { StoreStateSelector } from './common';

/**
 * Basic selectors
 */

export const getLanguage: StoreStateSelector<Language> = (store) => store.localizationStore.language;
export const getLocale: StoreStateSelector<string> = (store) => store.localizationStore.locale;

export const getLocalizationDataState: StoreStateSelector<LocalizationDataState> = (store) =>
    store.localizationStore.localizationDataState;

export const getLocalizationSettingState: StoreStateSelector<LocalizationSettingState> = (store) =>
    store.localizationStore.localizationSettingState;

export const getMarket: StoreStateSelector<string> = (store) => store.localizationStore.market;

export const getMessages: StoreStateSelector<LocaleMessageData | undefined> = (store) =>
    store.localizationStore.messages;

/**
 * Composed selectors
 */

export const getLocalizationState: StoreStateSelector<LocalizationState> = createSelector(
    [getLocalizationDataState, getLocalizationSettingState],
    (dataState, settingState) => {
        switch (dataState) {
            case LocalizationDataState.Configured:
                switch (settingState) {
                    case LocalizationSettingState.Set:
                    case LocalizationSettingState.SetToFallback:
                        return LocalizationState.Ready;
                    default:
                        return LocalizationState.NotReady;
                }
            case LocalizationDataState.Error:
                return LocalizationState.Error;
            default:
                return LocalizationState.NotReady;
        }
    }
);

export const getIsLocalizationConfigured: StoreStateSelector<boolean> = createSelector(
    [getLocalizationState],
    (state) => state === LocalizationState.Ready
);
