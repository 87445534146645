import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import {
    CustomizationFailure,
    CustomizationGroup,
    CustomizationTaskDefinition,
    CustomizationTaskListValidationResult,
} from '../../models/customization';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { stringAdapter } from '../adapters/common/string-adapter';
import {
    customizationFailureAdapter,
    customizationGroupAdapter,
    customizationTaskDefinitionAdapter,
} from '../adapters/customization-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName =
    | 'createCustomizationGroup'
    | 'loadCustomizationGroups'
    | 'validateCustomizationTasks'
    | 'trackCustomizationGroupProgress'
    | 'listCustomizationTaskDefinitions';
type IndexedStatusName = 'listCustomizationGroups' | 'getCustomizationTaskLog';

export interface CustomizationDataStore {
    customizationGroups: EntityState<Entity<CustomizationGroup>>;
    customizationTaskDefinitions: EntityState<Entity<CustomizationTaskDefinition[]>>;
    customizationValidationResult?: CustomizationTaskListValidationResult;
    customizationFailures: EntityState<Entity<CustomizationFailure>>;
    downloadCustomizationTaskLogUrls: EntityState<Entity<string>>;
}

export const CustomizationDataStore = createInitializer<CustomizationDataStore>({
    customizationGroups: customizationGroupAdapter.getInitialState(),
    customizationTaskDefinitions: customizationTaskDefinitionAdapter.getInitialState(),
    customizationFailures: customizationFailureAdapter.getInitialState(),
    downloadCustomizationTaskLogUrls: stringAdapter.getInitialState(),
});

export type CustomizationStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const CustomizationStatusStore = createInitializer<CustomizationStatusStore>({
    createCustomizationGroup: Status(),
    listCustomizationGroups: statusAdapter.getInitialState(),
    listCustomizationTaskDefinitions: Status(),
    loadCustomizationGroups: Status(),
    validateCustomizationTasks: Status(),
    getCustomizationTaskLog: statusAdapter.getInitialState(),
    trackCustomizationGroupProgress: Status(),
});

export interface CustomizationStore {
    data: CustomizationDataStore;
    status: CustomizationStatusStore;
}

export const CustomizationStore = createInitializer<CustomizationStore>({
    data: CustomizationDataStore(),
    status: CustomizationStatusStore(),
});
