import cs from '@loc/resources.cs.json';
import de from '@loc/resources.de.json';
import en from '@loc/resources.en.json';
import es from '@loc/resources.es.json';
import fr from '@loc/resources.fr.json';
import hu from '@loc/resources.hu.json';
import id from '@loc/resources.id.json';
import it from '@loc/resources.it.json';
import ja from '@loc/resources.ja.json';
import ko from '@loc/resources.ko.json';
import nl from '@loc/resources.nl.json';
import pl from '@loc/resources.pl.json';
import ptBR from '@loc/resources.pt-BR.json';
import ptPT from '@loc/resources.pt-PT.json';
import qpsPLOC from '@loc/resources.qps-ploc.json';
import qpsPLOCM from '@loc/resources.qps-plocm.json';
import ru from '@loc/resources.ru.json';
import sv from '@loc/resources.sv.json';
import tr from '@loc/resources.tr.json';
import zhCN from '@loc/resources.zh-Hans.json';
import zhTW from '@loc/resources.zh-Hant.json';
import memoizeOne from 'memoize-one';
import { SearchParameter } from '../constants/app';
import {
    DefaultLanguage,
    DefaultLocale,
    DefaultMarket,
    isSupportedLanguage,
    isSupportedLocale,
    Language,
    SupportedLocale,
} from '../constants/localization';
import { Severity } from '../constants/telemetry';
import { ClientError } from '../models/common';
import { UnionValueMap } from '../types/union-map';
import { getUseDefaultMessages } from '../utilities/app';
import { isNotUndefinedOrWhiteSpace } from '../utilities/string';
import { trackTrace } from '../utilities/telemetry/channel';

export type LocaleMessageData = {
    [key: string]: string;
};

type LocalizedMessages = UnionValueMap<SupportedLocale, LocaleMessageData>;

let isInitialized = false;

// Note: using literals because TypeScript misinfers the key type as string rather than SupportedLocale
const localizedMessages: LocalizedMessages = {
    cs: cs as LocaleMessageData,
    'cs-CZ': cs as LocaleMessageData,
    de: de as LocaleMessageData,
    'de-DE': de as LocaleMessageData,
    en: en as LocaleMessageData,
    'en-US': en as LocaleMessageData,
    es: es as LocaleMessageData,
    'es-ES': es as LocaleMessageData,
    fr: fr as LocaleMessageData,
    'fr-FR': fr as LocaleMessageData,
    hu: hu as LocaleMessageData,
    'hu-HU': hu as LocaleMessageData,
    id: id as LocaleMessageData,
    'id-ID': id as LocaleMessageData,
    it: it as LocaleMessageData,
    'it-IT': it as LocaleMessageData,
    ja: ja as LocaleMessageData,
    'ja-JA': ja as LocaleMessageData,
    ko: ko as LocaleMessageData,
    'ko-KR': ko as LocaleMessageData,
    nl: nl as LocaleMessageData,
    'nl-NL': nl as LocaleMessageData,
    pl: pl as LocaleMessageData,
    'pl-PL': pl as LocaleMessageData,
    pt: ptPT as LocaleMessageData,
    'pt-BR': ptBR as LocaleMessageData,
    'pt-PT': ptPT as LocaleMessageData,
    qps: qpsPLOC as LocaleMessageData,
    'qps-ploc': qpsPLOC as LocaleMessageData,
    'qps-plocm': qpsPLOCM as LocaleMessageData,
    ru: ru as LocaleMessageData,
    'ru-RU': ru as LocaleMessageData,
    sv: sv as LocaleMessageData,
    'sv-SE': sv as LocaleMessageData,
    tr: tr as LocaleMessageData,
    'tr-TR': tr as LocaleMessageData,
    zh: zhCN as LocaleMessageData,
    'zh-CN': zhCN as LocaleMessageData,
    'zh-HK': zhTW as LocaleMessageData,
    'zh-MO': zhTW as LocaleMessageData,
    'zh-SG': zhCN as LocaleMessageData,
    'zh-TW': zhTW as LocaleMessageData,
};

export const initLocaleData = (): LocalizedMessages => {
    if (isInitialized) {
        return localizedMessages;
    }

    // Clear messages from default language/locale when in development (react-intl will then use default messages)
    if (process.env.NODE_ENV === 'development') {
        const useDefaultMessage = getUseDefaultMessages();

        if (useDefaultMessage) {
            localizedMessages[DefaultLanguage] = {};
            localizedMessages[DefaultLocale] = {};
        }
    }
    isInitialized = true;

    return localizedMessages;
};

const getLanguageAndLocaleFromCode = (localeCode: string | undefined): [Language, string] | undefined => {
    // Note: awkward double-negative is awkward, but this allows us to take advantage of the type guard (can't do this
    // with isUndefinedOrWhiteSpace)
    if (!isNotUndefinedOrWhiteSpace(localeCode)) {
        return undefined;
    }

    // Log whether the given locale was valid.
    // (Calling here and saving result to minimize try/catch occurrences at runtime)
    const isLocaleCodeValid = isLocaleValid(localeCode);

    if (!isLocaleCodeValid) {
        trackTrace('Invalid locale code encountered.', { severity: Severity.Warning });
        return undefined;
    }

    const language = parseLanguageFromLocale(localeCode);

    if (isSupportedLanguage(language)) {
        return [language, localeCode];
    }

    return undefined;
};

export const getAcceptLanguageHeaderValue = (locale: string, language?: Language): string => {
    return `${locale}, ${isNotUndefinedOrWhiteSpace(language) ? language : parseLanguageFromLocale(locale)};q=0.5`;
};

export const getConfiguredLanguageAndLocale = (): [Language, string] | undefined => {
    // Precedence: locale > browser's list of preferred locales > default
    const searchParams = new URLSearchParams(window.location.search);
    const localeFromSearch = searchParams.get(SearchParameter.Locale) ?? undefined;

    // If there's a loc search parameter, attempt to use that if it's a supported locale or uses a supported language
    const languageAndLocaleFromSearch = getLanguageAndLocaleFromCode(localeFromSearch);

    if (languageAndLocaleFromSearch !== undefined) {
        return languageAndLocaleFromSearch;
    }

    // Otherwise, find the first valid locale in the list that is either fully supported as a locale or has a supportable language
    const languagesAndLocalesFromBrowser = window.navigator.languages.map((browserLocale) =>
        getLanguageAndLocaleFromCode(browserLocale)
    );

    return languagesAndLocalesFromBrowser.find((languageAndLocale) => languageAndLocale !== undefined);
};

export const getMessagesForLocale = memoizeOne((locale: string): LocaleMessageData | undefined => {
    if (!isInitialized) {
        initLocaleData();
    }

    if (localizedMessages === undefined) {
        throw new ClientError(
            'Unexpected state: localization module is initialized, but localized messages are undefined.'
        );
    }

    // If we receive a supported locale: try loading messages for locale, then fall back to messages for language.
    if (isSupportedLocale(locale)) {
        return localizedMessages[locale];
    }

    // Else, if we receive a locale with a supported language: use language component
    const languageString = parseLanguageFromLocale(locale);

    if (isSupportedLanguage(languageString)) {
        return localizedMessages[languageString];
    }

    // Otherwise: return undefined
    return undefined;
});

export const getLanguageForSupportedLocale = (locale: SupportedLocale): Language => {
    // Note: this cast is safe as long as Locale is a template literal type based on Language
    return parseLanguageFromLocale(locale) as Language;
};

export const isLocaleValid = (locale: string): boolean => {
    try {
        // The below expression should throw an error if the locale is invalid
        Intl.NumberFormat.supportedLocalesOf(locale);
        return true;
    } catch (err) {
        // RangeError appears to be the exception type used by Intl to indicate invalid locales.
        // See: https://tc39.es/ecma402/#sec-intl-locale-constructor
        if (err instanceof RangeError) {
            return false;
        }

        throw err;
    }
};

export const parseLanguageFromLocale = (locale: string): string => locale.split('-')[0];

export const parseMarketFromLocale = (locale: string): string => {
    // Get first token of locale formatted XX found when looking backward.
    // This accounts for cases like ca-ES-valencia. (ES is the value we want to return)
    const tokens = locale.trim().split('-');

    for (let i = tokens.length - 1; i >= 0; i--) {
        if (tokens[i].length === 2) {
            return tokens[i];
        }
    }

    return DefaultMarket;
};

const languages = {
    initLocaleData,
    getMessagesForLocale,
};

export default languages;
