import { EntityState, createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createDevBoxDataPlaneUri } from '../../ids/dev-box';
import { getTokensFromDevBoxActionDataPlaneUri } from '../../ids/dev-box-action';
import { Entity, Status } from '../../models/common';
import { compact } from '../../utilities/array';
import { areStringsEquivalent } from '../../utilities/string';
import { IndexedPayload, PayloadAction } from '../actions/core-actions';
import {
    delayAllDevBoxActions,
    delayAllDevBoxActionsError,
    delayAllDevBoxActionsFailed,
    delayAllDevBoxActionsSuccess,
    delayDevBoxActionFailed,
    delayDevBoxActionSuccess,
    listDevBoxActions,
    listDevBoxActionsError,
    listDevBoxActionsFailed,
    listDevBoxActionsSuccess,
    loadDevBoxActionsForDevBoxes,
    loadDevBoxActionsForDevBoxesError,
    loadDevBoxActionsForDevBoxesFailed,
    loadDevBoxActionsForDevBoxesSuccess,
    skipAllDevBoxActions,
    skipAllDevBoxActionsError,
    skipAllDevBoxActionsFailed,
    skipAllDevBoxActionsSuccess,
    skipDevBoxActionFailed,
    skipDevBoxActionSuccess,
} from '../actions/dev-box-action/dev-box-action-action-creators';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxActionAdapter } from '../adapters/dev-box-action-adapters';
import { DevBoxActionDataStore, DevBoxActionStatusStore, DevBoxActionStore } from '../store/dev-box-action-store';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

const resetStatusesForAllChildActionsReducer = (
    store: EntityState<Entity<Status>>,
    action: PayloadAction<IndexedPayload>
) => {
    const { id: devBoxId } = getPayload(action);

    // HACK: type assertion is because of RTK's lack of ability to specify "this is a string index only store"
    const actionIds = store.ids as string[];

    const entities = actionIds
        .filter((id) => {
            const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxActionDataPlaneUri(id);
            const parentDevBoxId = createDevBoxDataPlaneUri({ devBoxName, devCenter, projectName, user });

            return areStringsEquivalent(devBoxId, parentDevBoxId, true);
        })
        .map((id) => Entity(id, Status()));

    statusAdapter.setMany(store, entities);
};

export const devBoxActionReducer = combineReducers<DevBoxActionStore>({
    data: createReducer(DevBoxActionDataStore(), (builder) => {
        builder
            .addCase(delayDevBoxActionSuccess, (store, action) => {
                const actions = getActionsInGroup(action);

                const entities = compact(
                    actions.map((action) => {
                        const { id, result } = getPayload(action);
                        return result ? Entity(id, result) : undefined;
                    })
                );

                devBoxActionAdapter.setMany(store.devBoxActions, entities);
            })
            .addCase(listDevBoxActionsSuccess, (store, action) => {
                const { result } = getPayload(action);
                const entities = result.map((result) => Entity(result.uri, result));

                devBoxActionAdapter.setMany(store.devBoxActions, entities);
            })
            .addCase(skipDevBoxActionSuccess, (store, action) => {
                const actions = getActionsInGroup(action);
                const ids = actions.map((action) => action.payload.id);

                devBoxActionAdapter.removeMany(store.devBoxActions, ids);
            });
    }),

    status: combineReducers<DevBoxActionStatusStore>({
        delayAllDevBoxActions: createIndexedStatusReducer({
            inProgress: delayAllDevBoxActions,
            error: delayAllDevBoxActionsError,
            failed: delayAllDevBoxActionsFailed,
            success: delayAllDevBoxActionsSuccess,
            // Delay-all async state for a dev box should be cleared when a skip-all for that dev box begins
            reset: skipAllDevBoxActions,
        }),

        delayDevBoxAction: createReducer(statusAdapter.getInitialState(), (builder) => {
            // Special case for reset: remove all actions that are children of the given dev box
            builder.addCase(skipAllDevBoxActions, resetStatusesForAllChildActionsReducer);

            builder.addDefaultCase(
                createIndexedStatusReducer({
                    failed: delayDevBoxActionFailed,
                    success: delayDevBoxActionSuccess,
                })
            );
        }),

        listDevBoxActions: createIndexedStatusReducer({
            inProgress: listDevBoxActions,
            error: listDevBoxActionsError,
            failed: listDevBoxActionsFailed,
            success: listDevBoxActionsSuccess,
        }),

        loadDevBoxActionsForDevBoxes: createStatusReducer({
            inProgress: loadDevBoxActionsForDevBoxes,
            error: loadDevBoxActionsForDevBoxesError,
            failed: loadDevBoxActionsForDevBoxesFailed,
            success: loadDevBoxActionsForDevBoxesSuccess,
        }),

        skipAllDevBoxActions: createIndexedStatusReducer({
            inProgress: skipAllDevBoxActions,
            error: skipAllDevBoxActionsError,
            failed: skipAllDevBoxActionsFailed,
            success: skipAllDevBoxActionsSuccess,
            // Skip-all async state for a dev box should be cleared when a delay-all for that dev box begins
            reset: delayAllDevBoxActions,
        }),

        skipDevBoxAction: createReducer(statusAdapter.getInitialState(), (builder) => {
            // Special case for reset: remove all actions that are children of the given dev box
            builder.addCase(delayAllDevBoxActions, resetStatusesForAllChildActionsReducer);

            builder.addDefaultCase(
                createIndexedStatusReducer({
                    failed: skipDevBoxActionFailed,
                    success: skipDevBoxActionSuccess,
                })
            );
        }),
    }),
});
