import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { initializeSingleDevCenterHomeListenerSaga } from './initialize-single-dev-center-home';
import { loadDevBoxesForSingleDevCenterListenerSaga } from './load-dev-boxes-for-single-devcenter';
import { loadProjectsForSingleDevCenterListenerSaga } from './load-projects-for-single-devcenter';
import { loadResourcesForSingleDevCenterListenerSaga } from './load-resources-for-single-devcenter';
import { setSingleDevCenterUriFromInputListenerSaga } from './set-single-dev-center-uri-from-input';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(setSingleDevCenterUriFromInputListenerSaga),
        fork(initializeSingleDevCenterHomeListenerSaga),
        fork(loadResourcesForSingleDevCenterListenerSaga),
        fork(loadProjectsForSingleDevCenterListenerSaga),
        fork(loadDevBoxesForSingleDevCenterListenerSaga),
    ]);
}
