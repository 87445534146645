import { Link, MessageBarType } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PortalMessageBar } from '../../message-bar';

const surveyLink = 'https://aka.ms/devbox/devportal-survey';

export type PortalSurveyMessageBarProps = {
    isPastExpirationDate: boolean;
    isPortalSurveyDismissed: boolean;
    onDismissMessageBar: () => void;
};

const surveyMessageValue = () => ({
    Link: (chunks: string) => (
        <Link href={surveyLink} target="_blank">
            {chunks}
        </Link>
    ),
});

export const PortalSurveyMessageBar: React.FC<PortalSurveyMessageBarProps> = React.memo(
    (props: PortalSurveyMessageBarProps) => {
        const { isPastExpirationDate, isPortalSurveyDismissed, onDismissMessageBar } = props;

        // User has closed the banner we will not reshow the banner
        if (isPortalSurveyDismissed) {
            return <></>;
        }

        // Message is now past the expiration date
        if (isPastExpirationDate) {
            return <></>;
        }

        return (
            <PortalMessageBar onDismiss={onDismissMessageBar} messageBarType={MessageBarType.info}>
                <FormattedMessage
                    id="InfoMessageBar_PortalSurvey_Message"
                    defaultMessage="Please share your Dev Box and Azure Deployment Environments feedback to help the product team improve your experience. <Link>Share Feedback</Link>"
                    description="Message asking user to fill out survey, <Link></Link> tags are not to be localized."
                    values={surveyMessageValue()}
                />
            </PortalMessageBar>
        );
    }
);

export default PortalSurveyMessageBar;
