import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { GetDevBoxResponse, StopDevBoxResponse, stopDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, FailureResponse, isFailureResponse } from '../../../models/common';
import { getDevBoxState } from '../../../utilities/dev-box';
import { createFailureResponseFromCloudErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    hibernateDevBox,
    hibernateDevBoxAccepted,
    hibernateDevBoxError,
    hibernateDevBoxFailed,
    hibernateDevBoxSuccess,
    pollNonTerminalDevBox,
    shutdownDevBox,
    shutdownDevBoxAccepted,
    shutdownDevBoxError,
    shutdownDevBoxFailed,
    shutdownDevBoxSuccess,
} from '../../actions/dev-box/dev-box-action-creators';
import {
    HibernateDevBoxAcceptedAction,
    HibernateDevBoxAction,
    ShutdownDevBoxAcceptedAction,
    ShutdownDevBoxAction,
} from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { DevBoxState } from '../../store/dev-box-state';

export function* shutdownOrHibernateDevBoxSaga(action: HibernateDevBoxAction | ShutdownDevBoxAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;
    const isHibernate = hibernateDevBox.match(action);

    const metric = isHibernate ? PerformanceMetric.HibernateDevBox : PerformanceMetric.ShutdownDevBox;
    const operation = isHibernate ? FailureOperation.HibernateDevBox : FailureOperation.ShutdownDevBox;
    const acceptedActionCreator = isHibernate ? hibernateDevBoxAccepted : shutdownDevBoxAccepted;
    const errorActionCreator = isHibernate ? hibernateDevBoxError : shutdownDevBoxError;
    const failedActionCreator = isHibernate ? hibernateDevBoxFailed : shutdownDevBoxFailed;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: StopDevBoxResponse = yield call(stopDevBox, id, accessToken, isHibernate, activityId);

        if (isFailureResponse(response)) {
            // Ensure the operation is correct
            const failure = FailureResponse({ ...response, operation });
            yield put(failedActionCreator({ failure, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                metric,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );

            return;
        }

        yield put(acceptedActionCreator({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            metric,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, operation);
        yield put(errorActionCreator({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            metric,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* shutdownOrHibernateDevBoxAcceptedSaga(
    action: HibernateDevBoxAcceptedAction | ShutdownDevBoxAcceptedAction
): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;
    const isHibernate = hibernateDevBox.match(action);

    const operation = isHibernate ? FailureOperation.HibernateDevBox : FailureOperation.ShutdownDevBox;
    const errorActionCreator = isHibernate ? hibernateDevBoxError : shutdownDevBoxError;
    const failedActionCreator = isHibernate ? hibernateDevBoxFailed : shutdownDevBoxFailed;
    const successActionCreator = isHibernate ? hibernateDevBoxSuccess : shutdownDevBoxSuccess;

    try {
        const response: GetDevBoxResponse = yield putAndAwait(pollNonTerminalDevBox({ id }, meta));

        if (isFailureResponse(response)) {
            // Ensure the operation is correct
            const failure = FailureResponse({ ...response, operation });
            yield put(failedActionCreator({ failure, id }, meta));
            return;
        }

        const { data } = response;
        const state = getDevBoxState(data);
        switch (state) {
            case DevBoxState.Hibernated:
                yield put(successActionCreator({ id, result: data }, meta));
                break;
            case DevBoxState.Stopped:
                if (isHibernate) {
                    const failure = createFailureResponseFromCloudErrorBodyOrDefault(data.error, operation);

                    yield put(failedActionCreator({ failure, id }, meta));
                    break;
                }

                yield put(successActionCreator({ id, result: data }, meta));
                break;
            default:
                // Unexpected state: log cases where we're falling back on the default failure message. This means we're
                // in a failed state, but there are no error details.
                if (!data.error) {
                    trackEvent(EventName.DevBoxInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState: data.provisioningState,
                        },
                    });
                }

                const failure = createFailureResponseFromCloudErrorBodyOrDefault(data.error, operation);

                yield put(failedActionCreator({ failure, id, result: data }, meta));
                break;
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, operation);
        yield put(errorActionCreator({ error, id }, meta));
    }
}

export function* shutdownOrHibernateDevBoxListenerSaga(): SagaIterator {
    yield takeLeading([shutdownDevBox, hibernateDevBox], shutdownOrHibernateDevBoxSaga);
}

export function* shutdownOrHibernateDevBoxAcceptedListenerSaga(): SagaIterator {
    yield takeEvery([shutdownDevBoxAccepted, hibernateDevBoxAccepted], shutdownOrHibernateDevBoxAcceptedSaga);
}
