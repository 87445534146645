import { Action } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { EnvironmentProvisioningState } from '../../../constants/environment';
import { ListEnvironmentsResponse, listEnvironments } from '../../../data/services/data-plane-api/environment';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { Environment } from '../../../models/environment';
import {
    addEnvironmentAccepted,
    deleteEnvironmentAccepted,
    deployEnvironmentAccepted,
    listEnvironments as listEnvironmentsActionCreator,
    listEnvironmentsError,
    listEnvironmentsFailed,
    listEnvironmentsSuccess,
} from '../../actions/environment/environment-action-creators';
import { ListEnvironmentsAction } from '../../actions/environment/environment-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

const getPendingOperationActions = (resource: Environment, activityId?: string): Action[] => {
    const actions = [];
    const meta = activityId ? { activityId } : undefined;
    const { provisioningState, uri } = resource;

    switch (provisioningState) {
        case EnvironmentProvisioningState.Creating:
        case EnvironmentProvisioningState.Preparing:
            actions.push(addEnvironmentAccepted({ id: uri, result: resource }, meta));
            break;
        case EnvironmentProvisioningState.Updating:
            actions.push(deployEnvironmentAccepted({ id: uri }, meta));
        case EnvironmentProvisioningState.Deleting:
            actions.push(deleteEnvironmentAccepted({ id: uri }, meta));
            break;
        default:
            break;
    }

    return actions;
};

export function* listEnvironmentsSaga(action: ListEnvironmentsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListEnvironmentsResponse = yield call(listEnvironments, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(listEnvironmentsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;

        // dispatch actions to track pending operations
        yield all(
            data.flatMap((environment) =>
                getPendingOperationActions(environment, activityId).map((action) => put(action))
            )
        );

        yield put(listEnvironmentsSuccess({ id, result: data }, meta));
        yield resolveAction(action, { data, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListEnvironments);
        yield put(listEnvironmentsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listEnvironmentsListenerSaga(): SagaIterator {
    yield takeEvery(listEnvironmentsActionCreator, listEnvironmentsSaga);
}
