import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';
import { QuickActionName } from '../../../constants/app';
import { StorageKey } from '../../../constants/storage';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { compact } from '../../../utilities/array';
import {
    dismissQuickAction,
    dismissQuickActionError,
    dismissQuickActionFailed,
    dismissQuickActionSuccess,
} from '../../actions/application/application-action-creators';
import { DismissQuickActionAction } from '../../actions/application/application-actions';
import { getStorageValue, setStorageValue } from '../../actions/storage/storage-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery } from '../../effects/take';
import { getStorageType } from '../../selector/storage-selectors';
import { getPayload } from '../../utilities/payload-action';

export function* dismissQuickActionSaga(action: DismissQuickActionAction): SagaIterator {
    const { quickAction } = getPayload(action);

    try {
        const storageType = yield select(getStorageType);

        const getStorageValueResponse: DataResponse<string> = yield putAndAwait(
            getStorageValue({
                getForSignedInUser: true,
                key: StorageKey.DismissedQuickActions,
                storageType,
            })
        );

        if (isFailureResponse(getStorageValueResponse)) {
            yield put(dismissQuickActionFailed({ failure: getStorageValueResponse, quickAction }));
            return;
        }

        const { data } = getStorageValueResponse;

        const dismissedQuickActions = compact(
            data.split(',').map((quickAction) => {
                const rawValue = quickAction.trim();
                return Object.values(QuickActionName).includes(rawValue as QuickActionName)
                    ? (rawValue as QuickActionName)
                    : undefined;
            })
        );

        const newDismissedQuickActions = dismissedQuickActions.includes(quickAction as QuickActionName)
            ? dismissedQuickActions
            : [...dismissedQuickActions, quickAction];

        const setStorageValueResponse: DataResponse = yield putAndAwait(
            setStorageValue({
                key: StorageKey.DismissedQuickActions,
                setForSignedInUser: true,
                storageType,
                value: newDismissedQuickActions.join(','),
            })
        );

        if (isFailureResponse(setStorageValueResponse)) {
            yield put(dismissQuickActionFailed({ failure: setStorageValueResponse, quickAction }));
            return;
        }

        yield put(dismissQuickActionSuccess({ quickAction }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DismissQuickAction);
        yield put(dismissQuickActionError({ error, quickAction }));
    }
}

export function* dismissQuickActionListenerSaga(): SagaIterator {
    yield takeEvery(dismissQuickAction, dismissQuickActionSaga);
}
