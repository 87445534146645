import { ThemeMode } from '../../themes/themes';
import { RemoteAppOption } from '../../types/user-settings';
import { createInitializer } from '../../utilities/initializer';

export type UserSettingsStore = {
    themeMode: ThemeMode;
    useMultiMonitor: boolean;
    preferredRemoteApp?: RemoteAppOption;
};

export const UserSettingsStore = createInitializer<UserSettingsStore>({
    themeMode: ThemeMode.DarkMode,
    useMultiMonitor: false,
});
