import { IObjectWithKey } from '@fluentui/react';
import { CapitalizedUnionMap } from '../../../types/union-map';
import { EnvironmentDefinitionViewModel } from '../models';

type EnvironmentDefinitionDetailsListItemColumns = {
    name: string;
    catalog: string | undefined;
    description: string | undefined;
};

export type EnvironmentDefinitionDetailsListItem = IObjectWithKey & {
    key: string;
    value: EnvironmentDefinitionViewModel;
    columns: EnvironmentDefinitionDetailsListItemColumns;
};

export type EnvironmentDefinitionColumnKey = keyof EnvironmentDefinitionDetailsListItemColumns;

export const EnvironmentDefinitionColumnKey: CapitalizedUnionMap<EnvironmentDefinitionColumnKey> = {
    Name: 'name',
    Catalog: 'catalog',
    Description: 'description',
};
