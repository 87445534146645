import { ITextFieldStyleProps, ITextFieldStyles } from '@fluentui/react';
import { getSemanticColor } from '../../utilities/styles';

export const TextFieldStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
    const { theme } = props;

    if (!theme) {
        return {};
    }

    return {
        errorMessage: {
            color: getSemanticColor(theme, 'inlineErrorMessageText'),
        },
        subComponentStyles: {
            label: {
                root: {
                    '::after': {
                        content: ' !important', //Used to override default asterix for required form fields
                    },
                },
            },
        },
    };
};
