import { UnionMap } from '../../types/union-map';

export type DevBoxState =
    | 'Creating'
    | 'Deleting'
    | 'Running'
    | 'Starting'
    | 'Stopped'
    | 'Stopping'
    | 'Unknown'
    | 'Failed'
    | 'Hibernated'
    | 'Hibernating'
    | 'Resuming'
    | 'Restarting'
    | 'Repairing'
    | 'Repaired';

export const DevBoxState: UnionMap<DevBoxState> = {
    Creating: 'Creating',
    Deleting: 'Deleting',
    Running: 'Running',
    Starting: 'Starting',
    Stopped: 'Stopped',
    Stopping: 'Stopping',
    Unknown: 'Unknown',
    Failed: 'Failed',
    Hibernated: 'Hibernated',
    Hibernating: 'Hibernating',
    Resuming: 'Resuming',
    Restarting: 'Restarting',
    Repairing: 'Repairing',
    Repaired: 'Repaired',
};

export const terminalDevBoxStates: DevBoxState[] = [
    DevBoxState.Running,
    DevBoxState.Stopped,
    DevBoxState.Unknown,
    DevBoxState.Failed,
    DevBoxState.Hibernated,
    DevBoxState.Repaired,
];
