import { DataResponse, Nothing } from '../../../models/common';
import { GraphDirectoryObject } from '../../../models/graph';
import { createActionCreator } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, ResultPayload } from '../core-actions';
import { GraphDirectoryObjectsActionType } from './directory-objects-actions';

/**
 * Action creators
 */

export const getGraphDirectoryObject = createActionCreator<
    IndexedPayload,
    DataResponse<GraphDirectoryObject | Nothing>
>(GraphDirectoryObjectsActionType.GetGraphDirectoryObject, { async: true, groupable: true, startsActivity: true });

export const getGraphDirectoryObjectError = createActionCreator<ErrorPayload & IndexedPayload>(
    GraphDirectoryObjectsActionType.GetGraphDirectoryObjectError,
    { groupable: true }
);

export const getGraphDirectoryObjectFailed = createActionCreator<FailedPayload & IndexedPayload>(
    GraphDirectoryObjectsActionType.GetGraphDirectoryObjectFailed,
    { groupable: true }
);

export const getGraphDirectoryObjectSuccess = createActionCreator<
    IndexedPayload & ResultPayload<GraphDirectoryObject | Nothing>
>(GraphDirectoryObjectsActionType.GetGraphDirectoryObjectSuccess, { groupable: true });
