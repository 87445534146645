import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { FeatureFlagName } from '../../../../constants/features';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedFailure,
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../../models/common';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { isFeatureFlagEnabled } from '../../../../utilities/features';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { discoverPermissionsForProjectEnvironmentTypes } from '../../../actions/permission/permission-action-creators';
import { discoverProjectEnvironmentTypesAbilities } from '../../../actions/project-environment-type-abilities/project-environment-type-abilities-action-creators';
import {
    discoverProjectEnvironmentTypes,
    discoverProjectEnvironmentTypesFromDataplane,
} from '../../../actions/project-environment-type/project-environment-type-action-creators';
import {
    loadAddEnvironmentFormContent,
    loadAddEnvironmentFormContentError,
    loadAddEnvironmentFormContentFailed,
    loadAddEnvironmentFormContentSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadAddEnvironmentFormContentAction } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';
import { performDiscoverEnvironmentDefinitionsForProjects } from '../../environment-definition/discover-environment-definitions-for-projects';

function* terminateWithFailure(
    action: LoadAddEnvironmentFormContentAction,
    aggregatedFailure: AggregatedFailure,
    startTime: Date
): SagaIterator {
    const { failures } = aggregatedFailure;
    const failure = aggregateFailureResponses(FailureOperation.LoadAddEnvironmentFormContent, ...failures);
    yield put(loadAddEnvironmentFormContentFailed({ failure }));
    yield resolveAction(action, aggregatedFailure);
    yield call(
        trackTimedPerformanceMetric,
        PerformanceMetric.LoadAddEnvironmentFormContent,
        startTime,
        AsyncOutcome.Failed,
        { errorCodes: getErrorCodes(aggregatedFailure) }
    );
}

export function* loadAddEnvironmentFormContentSaga(action: LoadAddEnvironmentFormContentAction): SagaIterator {
    const startTime = new Date();

    try {
        const isDiscoveryServiceEnabled = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService);

        // NOTE: getting environment types and their permissions ahead of environment definitions because we may be able
        // to avoid some environment definition requests if permissions result in projects not being auth'd for create.
        const environmentTypesResult: AggregatedResult = isDiscoveryServiceEnabled
            ? yield putAndAwait(discoverProjectEnvironmentTypesFromDataplane())
            : yield putAndAwait(discoverProjectEnvironmentTypes());

        if (isAggregatedFailure(environmentTypesResult)) {
            yield call(terminateWithFailure, action, environmentTypesResult, startTime);
            return;
        }

        const permissionsResult: AggregatedResult = isDiscoveryServiceEnabled
            ? yield putAndAwait(discoverProjectEnvironmentTypesAbilities())
            : yield putAndAwait(discoverPermissionsForProjectEnvironmentTypes());

        if (isAggregatedFailure(permissionsResult)) {
            yield call(terminateWithFailure, action, permissionsResult, startTime);
            return;
        }

        const environmentDefinitionsResult: AggregatedResult = yield call(
            performDiscoverEnvironmentDefinitionsForProjects
        );

        if (isAggregatedFailure(environmentDefinitionsResult)) {
            yield call(terminateWithFailure, action, environmentDefinitionsResult, startTime);
            return;
        }

        const completeResult = combineResults([
            environmentTypesResult,
            permissionsResult,
            environmentDefinitionsResult,
        ]);

        yield put(loadAddEnvironmentFormContentSuccess());
        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadAddEnvironmentFormContent,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadAddEnvironmentFormContent);
        yield put(loadAddEnvironmentFormContentError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadAddEnvironmentFormContent,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadAddEnvironmentFormContentListenerSaga(): SagaIterator {
    yield takeLeading(loadAddEnvironmentFormContent, loadAddEnvironmentFormContentSaga);
}
