import { QuickActionName } from '../../../../constants/app';
import { AggregatedResult, DataResponse } from '../../../../models/common';
import { ProjectResource } from '../../../../models/project';
import { createActionCreator, createActionCreatorWithoutPayload } from '../../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../../core-actions';
import { HomeActionType } from './home-actions';

/**
 * Action creators
 */

export const initializeHome = createActionCreatorWithoutPayload(HomeActionType.InitializeHome);
export const initializeHomeError = createActionCreator<ErrorPayload>(HomeActionType.InitializeHomeError);
export const initializeHomeFailed = createActionCreator<FailedPayload>(HomeActionType.InitializeHomeFailed);
export const initializeHomeSuccess = createActionCreatorWithoutPayload(HomeActionType.InitializeHomeSuccess);

export const loadAddDevBoxFormContent = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadAddDevBoxFormContent,
    { async: true }
);

export const loadAddDevBoxFormContentError = createActionCreator<ErrorPayload>(
    HomeActionType.LoadAddDevBoxFormContentError
);

export const loadAddDevBoxFormContentFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadAddDevBoxFormContentFailed
);

export const loadAddDevBoxFormContentSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadAddDevBoxFormContentSuccess
);

export const loadAddEnvironmentFormContent = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadAddEnvironmentFormContent,
    { async: true }
);

export const loadAddEnvironmentFormContentError = createActionCreator<ErrorPayload>(
    HomeActionType.LoadAddEnvironmentFormContentError
);

export const loadAddEnvironmentFormContentFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadAddEnvironmentFormContentFailed
);

export const loadAddEnvironmentFormContentSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadAddEnvironmentFormContentSuccess
);

export const loadBackgroundResourcesForHome = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadBackgroundResourcesForHome,
    { async: true }
);

export const loadBackgroundResourcesForHomeError = createActionCreator<ErrorPayload>(
    HomeActionType.LoadBackgroundResourcesForHomeError
);

export const loadBackgroundResourcesForHomeFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadBackgroundResourcesForHomeFailed
);

export const loadBackgroundResourcesForHomeSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadBackgroundResourcesForHomeSuccess
);

export const loadControlPlaneResourcesForHome = createActionCreatorWithoutPayload<DataResponse<ProjectResource[]>>(
    HomeActionType.LoadControlPlaneResourcesForHome,
    { async: true, startsActivity: true }
);

export const loadControlPlaneResourcesForHomeError = createActionCreator<ErrorPayload>(
    HomeActionType.LoadControlPlaneResourcesForHomeError
);

export const loadControlPlaneResourcesForHomeFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadControlPlaneResourcesForHomeFailed
);

export const loadControlPlaneResourcesForHomeSuccess = createActionCreator<ResultPayload<ProjectResource[]>>(
    HomeActionType.LoadControlPlaneResourcesForHomeSuccess
);

export const loadDevBoxCardContent = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadDevBoxCardContent,
    { async: true }
);

export const loadDevBoxCardContentError = createActionCreator<ErrorPayload>(HomeActionType.LoadDevBoxCardContentError);

export const loadDevBoxCardContentFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadDevBoxCardContentFailed
);

export const loadDevBoxCardContentSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadDevBoxCardContentSuccess
);

export const loadSecondaryDevBoxCardContent = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadSecondaryDevBoxCardContent,
    { async: true }
);

export const loadSecondaryDevBoxCardContentError = createActionCreator<ErrorPayload>(
    HomeActionType.LoadSecondaryDevBoxCardContentError
);

export const loadSecondaryDevBoxCardContentFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadSecondaryDevBoxCardContentFailed
);

export const loadSecondaryDevBoxCardContentSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadSecondaryDevBoxCardContentSuccess
);

export const loadEnvironmentCardContent = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadEnvironmentCardContent,
    { async: true }
);

export const loadEnvironmentCardContentError = createActionCreator<ErrorPayload>(
    HomeActionType.LoadEnvironmentCardContentError
);

export const loadEnvironmentCardContentFailed = createActionCreator<FailedPayload>(
    HomeActionType.LoadEnvironmentCardContentFailed
);

export const loadEnvironmentCardContentSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadEnvironmentCardContentSuccess
);

export const loadResourcesForHome = createActionCreatorWithoutPayload<AggregatedResult>(
    HomeActionType.LoadResourcesForHome,
    { async: true }
);

export const loadResourcesForHomeError = createActionCreator<ErrorPayload>(HomeActionType.LoadResourcesForHomeError);
export const loadResourcesForHomeFailed = createActionCreator<FailedPayload>(HomeActionType.LoadResourcesForHomeFailed);

export const loadResourcesForHomeSuccess = createActionCreatorWithoutPayload(
    HomeActionType.LoadResourcesForHomeSuccess
);

export const syncDismissedHomeQuickActions = createActionCreatorWithoutPayload<DataResponse<QuickActionName[]>>(
    HomeActionType.SyncDismissedHomeQuickActions,
    { async: true }
);

export const syncDismissedHomeQuickActionsError = createActionCreator<ErrorPayload>(
    HomeActionType.SyncDismissedHomeQuickActionsError
);

export const syncDismissedHomeQuickActionsFailed = createActionCreator<FailedPayload>(
    HomeActionType.SyncDismissedHomeQuickActionsFailed
);

export const syncDismissedHomeQuickActionsSuccess = createActionCreator<ResultPayload<QuickActionName[]>>(
    HomeActionType.SyncDismissedHomeQuickActionsSuccess
);
