import { GetOrganizationResponse } from '../../../data/services/graph/organization';
import { GraphDirectoryObject } from '../../../models/graph';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, ResultPayload } from '../core-actions';
import { GetBannerLogoForOrganizationSuccessPayload, OrganizationActionType } from './organization-actions';

/**
 * Action creators
 */

export const getBannerLogoForOrganization = createActionCreator<IndexedPayload>(
    OrganizationActionType.GetBannerLogoForOrganization,
    { startsActivity: true }
);

export const getBannerLogoForOrganizationError = createActionCreator<ErrorPayload & IndexedPayload>(
    OrganizationActionType.GetBannerLogoForOrganizationError
);

export const getBannerLogoForOrganizationFailed = createActionCreator<FailedPayload & IndexedPayload>(
    OrganizationActionType.GetBannerLogoForOrganizationFailed
);

export const getBannerLogoForOrganizationSuccess = createActionCreator<GetBannerLogoForOrganizationSuccessPayload>(
    OrganizationActionType.GetBannerLogoForOrganizationSuccess
);

export const getOrganization = createActionCreatorWithoutPayload<GetOrganizationResponse>(
    OrganizationActionType.GetOrganization,
    { async: true, startsActivity: true }
);

export const getOrganizationError = createActionCreator<ErrorPayload>(OrganizationActionType.GetOrganizationError);
export const getOrganizationFailed = createActionCreator<FailedPayload>(OrganizationActionType.GetOrganizationFailed);

export const getOrganizationSuccess = createActionCreator<ResultPayload<GraphDirectoryObject>>(
    OrganizationActionType.GetOrganizationSuccess
);

export const initializeOrganizationMetadata = createActionCreatorWithoutPayload(
    OrganizationActionType.InitializeOrganizationMetadata
);

export const initializeOrganizationMetadataError = createActionCreator<ErrorPayload>(
    OrganizationActionType.InitializeOrganizationMetadataError
);

export const initializeOrganizationMetadataFailed = createActionCreator<FailedPayload>(
    OrganizationActionType.InitializeOrganizationMetadataFailed
);

export const initializeOrganizationMetadataSuccess = createActionCreatorWithoutPayload(
    OrganizationActionType.InitializeOrganizationMetadataSuccess
);
