import { ITelemetryItem, Tags } from '@microsoft/applicationinsights-web';
import { Property, Tag, TelemetryType } from '../../constants/telemetry';
import {
    ReduxActionTelemetryFilteringRule,
    SanitizedTelemetryItem,
    TelemetryFilteringAction,
    TelemetryFilteringActionAndTarget,
    TelemetryFilteringActionSet,
    TelemetryFilteringActionTarget,
    TelemetryFilteringRule,
    TelemetryFilteringRuleSet,
    TelemetryProperties,
    isTelemetryFilteringAction,
    isTelemetryFilteringActionAndTarget,
    isTelemetryFilteringActionTargetForScrub,
} from '../../models/telemetry';
import { isObject } from '../object';
import { isNotUndefinedOrWhiteSpace, isString } from '../string';
import { scrubValue } from './helpers';

type TelemetryBag = { [key: string]: unknown };

// Limits determined here: https://github.com/MicrosoftDocs/azure-docs/blob/main/includes/application-insights-limits.md
const maximumPropertyLength = 8192;

export const sanitizeTelemetryItem = (
    item: ITelemetryItem,
    rules: TelemetryFilteringRuleSet
): SanitizedTelemetryItem => {
    const {
        all: universalRules,
        dependency: dependencyRules,
        event: eventRules,
        exception: exceptionRules,
        metric: metricRules,
        trace: traceRules,
    } = rules;

    // DO NOT REMOVE: override user tracking tags
    const filteredTags: Tags & Tags[] = [];
    filteredTags[Tag.DeviceId] = '';
    filteredTags[Tag.LocationIp] = '0.0.0.0';
    filteredTags[Tag.UserId] = '';

    // Create a copy of the telemetry item, importing data we don't expect to change
    const newItem: SanitizedTelemetryItem = {
        baseData: {},
        baseType: item.baseType,
        data: {},
        ext: item.ext,
        iKey: item.iKey,
        name: item.name,
        tags: filteredTags,
        time: item.time,
        ver: item.ver,
    };

    // Apply our defined rules to the relevant telemetry types
    if (universalRules) {
        copyTelemetryItemUsingRules(item, newItem, universalRules);
    }

    if (dependencyRules && item.baseType === TelemetryType.Dependency) {
        copyTelemetryItemUsingRules(item, newItem, dependencyRules);
    }

    if (eventRules && item.baseType === TelemetryType.Event) {
        copyTelemetryItemUsingRules(item, newItem, eventRules);
    }

    if (exceptionRules && item.baseType === TelemetryType.Exception) {
        copyTelemetryItemUsingRules(item, newItem, exceptionRules);
    }

    if (metricRules && item.baseType === TelemetryType.Metric) {
        copyTelemetryItemUsingRules(item, newItem, metricRules);
    }

    if (traceRules && item.baseType === TelemetryType.Trace) {
        copyTelemetryItemUsingRules(item, newItem, traceRules);
    }

    // Truncate any long properties once filtering is complete
    truncateProperties(newItem);

    return newItem;
};

const copyTelemetryItemUsingRules = (
    item: ITelemetryItem,
    newItem: SanitizedTelemetryItem,
    rules: TelemetryFilteringRule[]
): void => {
    rules.forEach((rule) => {
        const {
            baseData: baseDataActionSet,
            data: dataActionSet,
            name: nameFilteringRule,
            reduxActionType: reduxActionTypeRule,
        } = rule as ReduxActionTelemetryFilteringRule;

        // Skip applying this rule unless the name filtering rule passes
        if (nameFilteringRule && !doesTelemetryItemMatchNameFilter(item, nameFilteringRule)) {
            return;
        }

        // Skip applying this rule unless the action type filtering rule passes
        if (reduxActionTypeRule && !doesTelemetryItemMatchReduxActionFilter(item, reduxActionTypeRule)) {
            return;
        }

        // Apply rules to baseData and data
        if (baseDataActionSet && item.baseData) {
            copyTelemetryBagUsingActionSet(item.baseData, newItem.baseData, baseDataActionSet);
        }

        if (dataActionSet && item.data) {
            copyTelemetryBagUsingActionSet(item.data, newItem.data, dataActionSet);
        }
    });
};

const copyTelemetryBagUsingActionSet = (
    bag: TelemetryBag,
    newBag: TelemetryBag,
    actionSet: TelemetryFilteringActionSet
): void => {
    Object.keys(actionSet).forEach((property) => {
        const rule = actionSet[property];

        const action = getTelemetryFilteringAction(rule);
        const target = getTelemetryFilteringActionTarget(rule);

        // No identified action for the current action set entry means this is another action set. Sanitize recursively.
        // Note: may want to consider supporting arrays in the future, depending on scenarios
        if (!action) {
            performRulesetOnComplexPropertyInBag(bag, newBag, property, rule as TelemetryFilteringActionSet);
            return;
        }

        // If key in action set is one of the predefined actions, apply it
        switch (action) {
            case TelemetryFilteringAction.Allow:
                performAllowRule(bag, newBag, property);
                return;

            case TelemetryFilteringAction.Count:
                performCountRule(bag, newBag, property);
                return;

            case TelemetryFilteringAction.Scrub:
                // Treat rule as no-op if no target was given
                if (!target) {
                    return;
                }

                performScrubRule(target, bag, newBag, property);
                return;

            // Default action is to block the data
            // Note: we also delete in case a previous rule copied the value over
            case TelemetryFilteringAction.Block:
            default:
                delete newBag[property];
                return;
        }
    });
};

const doesTelemetryItemMatchNameFilter = (item: ITelemetryItem, filter: string | string[] | RegExp) => {
    const name = item.baseData?.name;
    return isNotUndefinedOrWhiteSpace(name) ? doesStringValueMatchFilter(name, filter) : false;
};

const doesTelemetryItemMatchReduxActionFilter = (item: ITelemetryItem, filter: string | string[] | RegExp) => {
    const actionType = getPropertyValue(item, 'actionDispatched-type');
    return isString(actionType) ? doesStringValueMatchFilter(actionType, filter) : false;
};

const doesStringValueMatchFilter = (value: string, filter: string | string[] | RegExp) => {
    if (isString(filter) && value !== filter) {
        return false;
    }

    if (Array.isArray(filter) && !filter.includes(value)) {
        return false;
    }

    if (filter instanceof RegExp && !value.match(filter)) {
        return false;
    }

    return true;
};

const getPropertyValue = (item: ITelemetryItem, property: string): unknown => {
    // Metrics don't have a property bag in baseData. Instead, properties are posted directly to data.
    if (item.baseType === TelemetryType.Metric) {
        return item?.data?.[property];
    }

    return item?.baseData?.['properties']?.[property];
};

const getTelemetryFilteringAction = (
    actionSetEntry: TelemetryFilteringAction | TelemetryFilteringActionAndTarget | TelemetryFilteringActionSet
) => {
    if (isTelemetryFilteringAction(actionSetEntry)) {
        return actionSetEntry;
    }

    if (isTelemetryFilteringActionAndTarget(actionSetEntry)) {
        return actionSetEntry.action;
    }

    return undefined;
};

const getTelemetryFilteringActionTarget = (
    actionSetEntry: TelemetryFilteringAction | TelemetryFilteringActionAndTarget | TelemetryFilteringActionSet
) => {
    if (isTelemetryFilteringActionAndTarget(actionSetEntry)) {
        return actionSetEntry.target;
    }

    return undefined;
};

const performAllowRule = (bag: TelemetryBag, newBag: TelemetryBag, property: string) => {
    // Do nothing if the original value is undefined
    if (bag[property] === undefined) {
        return;
    }

    newBag[property] = bag[property];
};

const performCountRule = (bag: TelemetryBag, newBag: TelemetryBag, property: string) => {
    const value = bag[property];

    // Note: rule is effectively a no-op if original value isn't object or array
    if (Array.isArray(value)) {
        newBag[property] = `array[${value.length}]`;
        return;
    }

    if (isObject(value)) {
        newBag[property] = `object[${Object.keys(value).length}]`;
        return;
    }
};

const performScrubRule = (
    target: TelemetryFilteringActionTarget,
    bag: TelemetryBag,
    newBag: TelemetryBag,
    property: string
) => {
    // Note: scrub actions are intended to be paired with specific sub-rules, but if one of those sub-rules isn't
    // specified, treat as a no-op.
    if (!isTelemetryFilteringActionTargetForScrub(target)) {
        return;
    }

    // Treat rule as a no-op if value isn't a string
    const originalValue = bag[property];

    if (!isString(originalValue)) {
        return;
    }

    const value = scrubValue(target, originalValue);
    newBag[property] = value;
};

const performRulesetOnComplexPropertyInBag = (
    bag: TelemetryBag,
    newBag: TelemetryBag,
    property: string,
    actionSet: TelemetryFilteringActionSet
) => {
    const value = bag[property];

    // If the data is not an object, ignore it
    if (!isObject(value)) {
        return;
    }

    const newValue: TelemetryBag = isObject(newBag[property]) ? (newBag[property] as TelemetryBag) : {};
    copyTelemetryBagUsingActionSet(value as TelemetryBag, newValue, actionSet);
    newBag[property] = newValue;
};

const truncateArray = (array: unknown[]) => {
    // Keep removing items until serialized value is small enough
    let isTruncated = false;

    while (JSON.stringify(array).length > maximumPropertyLength) {
        array.pop();
        isTruncated = true;
    }

    return isTruncated;
};

const truncateObject = (obj: { [key: string]: unknown }) => {
    // Keep deleting properties until serialized value is small enough
    let isTruncated = false;

    while (JSON.stringify(obj).length > maximumPropertyLength) {
        const keys = Object.keys(obj);
        const keyToDelete = keys[keys.length - 1];
        delete obj[keyToDelete];
        isTruncated = true;
    }

    return isTruncated;
};

const truncateProperties = (item: SanitizedTelemetryItem) => {
    // Properties longer than max allowed property length must be truncated
    const properties: TelemetryProperties = item.baseData['properties'];

    // Skip if there's no properties bag
    if (!properties) {
        return;
    }

    const truncatedProperties: string[] = [];

    Object.keys(properties).forEach((key) => {
        const property = properties[key];
        let isTruncated = false;

        if (Array.isArray(property)) {
            isTruncated = truncateArray(property);
        } else if (isObject(property) && property !== null) {
            isTruncated = truncateObject(property as { [key: string]: unknown });
        } else if (isString(property) && property.length > maximumPropertyLength) {
            properties[key] = property.substring(0, maximumPropertyLength);
            isTruncated = true;
        }

        // If the property was truncated, add its name to the list
        if (isTruncated) {
            truncatedProperties.push(key);
        }
    });

    // Skip adding truncated properties if none were truncated
    if (truncatedProperties.length > 0) {
        properties[Property.TruncatedProps] = truncatedProperties;
    }
};
