import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import {
    getClosestRDGatewayRegion,
    loadDevBoxRegionRecommendations,
} from './dev-box-region-recommendation-action-creators';

type DevBoxRegionRecommendationActionName =
    | ActionName<'GetClosestRDGatewayRegion', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadDevBoxRegionRecommendations', 'Error' | 'Failed' | 'Complete'>;
export type DevBoxRegionRecommendationActionType =
    | ActionType<'GET_CLOSEST_RD_GATEWAY_REGION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_DEV_BOX_REGION_RECOMMENDATIONS', '_ERROR' | '_FAILED' | '_COMPLETE'>;

export const DevBoxRegionRecommendationActionType: UnionValueMap<
    DevBoxRegionRecommendationActionName,
    DevBoxRegionRecommendationActionType
> = {
    GetClosestRDGatewayRegion: 'GET_CLOSEST_RD_GATEWAY_REGION',
    GetClosestRDGatewayRegionError: 'GET_CLOSEST_RD_GATEWAY_REGION_ERROR',
    GetClosestRDGatewayRegionFailed: 'GET_CLOSEST_RD_GATEWAY_REGION_FAILED',
    GetClosestRDGatewayRegionSuccess: 'GET_CLOSEST_RD_GATEWAY_REGION_SUCCESS',

    LoadDevBoxRegionRecommendations: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS',
    LoadDevBoxRegionRecommendationsError: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS_ERROR',
    LoadDevBoxRegionRecommendationsFailed: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS_FAILED',
    LoadDevBoxRegionRecommendationsComplete: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS_COMPLETE',
};

// Action shorthands

export type GetClosestRDGatewayRegionAction = ReturnType<typeof getClosestRDGatewayRegion>;

export type LoadDevBoxRegionRecommendationsAction = ReturnType<typeof loadDevBoxRegionRecommendations>;
