import { FontWeights, GroupHeader, IGroupHeaderProps, IStackTokens, Stack, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { getSemanticColor } from '../../../utilities/styles';

interface AddDevBoxSummaryCustomizationSectionDetailsProps {
    groupHeaderProps?: IGroupHeaderProps;
}

interface AddDevBoxSummaryCustomizationFileTitleProps {
    groupHeaderProps?: IGroupHeaderProps;
}

/**
 * Styles
 */

const groupHeaderStylesFactory = (isCollapsed: boolean) =>
    makeStyles((theme) => ({
        expand: {
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
        },
        expandIsCollapsed: {
            color: isCollapsed ? getSemanticColor(theme, 'expandIcon') : getSemanticColor(theme, 'activeExpandIcon'),
        },
        groupHeaderContainer: {
            backgroundColor: isCollapsed
                ? getSemanticColor(theme, 'collapsedCustomizationTaskBackground')
                : getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
            margin: isCollapsed ? '6px 0' : '6px 0 0 0',
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
        },
    }));

const customizationFileNameStyles = (isCollapsed: boolean) =>
    makeStyles({
        root: {
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
        },
    });

const customizationFileTokens: IStackTokens = { childrenGap: 8 };

const onRenderName = (props?: IGroupHeaderProps): JSX.Element => {
    return <AddDevBoxSummaryCustomizationFileTitle groupHeaderProps={props} />;
};

export const AddDevBoxSummaryCustomizationFileTitle: React.FC<AddDevBoxSummaryCustomizationFileTitleProps> = React.memo(
    (props: AddDevBoxSummaryCustomizationFileTitleProps) => {
        const { groupHeaderProps } = props;
        const { group } = groupHeaderProps ?? {};

        const useCustomizationFileNameStyles = useDynamicMakeStyles(
            customizationFileNameStyles,
            group?.isCollapsed ?? true
        );
        const fileNameStyles = useCustomizationFileNameStyles();

        return (
            <Stack tokens={customizationFileTokens} grow horizontal verticalAlign="center">
                <Stack.Item styles={fileNameStyles} grow>
                    {group?.name}
                </Stack.Item>
            </Stack>
        );
    }
);

export const AddDevBoxSummaryCustomizationSectionDetails: React.FC<AddDevBoxSummaryCustomizationSectionDetailsProps> =
    React.memo((props: AddDevBoxSummaryCustomizationSectionDetailsProps) => {
        const { groupHeaderProps } = props;
        const { group } = groupHeaderProps ?? {};

        // Style hooks
        const useGroupHeaderStyles = useDynamicMakeStyles(groupHeaderStylesFactory, group?.isCollapsed ?? true);
        const groupHeaderStyles = useGroupHeaderStyles();

        return <GroupHeader onRenderTitle={onRenderName} styles={groupHeaderStyles} {...groupHeaderProps} />;
    });

export default AddDevBoxSummaryCustomizationSectionDetails;
