import { IButtonProps, IButtonStyles } from '@fluentui/react';
import { getSemanticColor } from '../../utilities/styles';
import { AppSemanticColor } from '../app-semantic-colors';

export const MessageBarButtonStyles = (props: IButtonProps): Partial<IButtonStyles> => {
    const { theme } = props;

    if (!theme) {
        return {};
    }

    return {
        root: {
            color: getSemanticColor(theme, AppSemanticColor.messageBarButton),
            backgroundColor: getSemanticColor(theme, AppSemanticColor.messageBarButtonBackground),
        },
        rootHovered: {
            color: getSemanticColor(theme, AppSemanticColor.messageBarButtonHovered),
            backgroundColor: getSemanticColor(theme, AppSemanticColor.messageBarButtonBackgroundHovered),
        },
        rootPressed: {
            color: getSemanticColor(theme, AppSemanticColor.messageBarButtonPressed),
            backgroundColor: getSemanticColor(theme, AppSemanticColor.messageBarButtonBackgroundPressed),
        },
    };
};
