import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import {
    AggregatedSuccess,
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../models/common';
import { ProjectPermissionRecord } from '../../../models/permission';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    discoverPermissionsForProjects,
    discoverPermissionsForProjectsError,
    discoverPermissionsForProjectsFailed,
    discoverPermissionsForProjectsSuccess,
    getProjectPermissions,
} from '../../actions/permission/permission-action-creators';
import { DiscoverPermissionsForProjectsAction } from '../../actions/permission/permission-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getProjectResourceIds } from '../../selector/project-selectors';
import { AsyncOutcome } from '../../store/common-state';

export function* discoverPermissionsForProjectsSaga(action: DiscoverPermissionsForProjectsAction): SagaIterator {
    const startTime = new Date();

    try {
        const resourceIds: string[] = yield select(getProjectResourceIds);

        // Exit immediately if no resource IDs are available
        if (resourceIds.length < 1) {
            yield put(discoverPermissionsForProjectsSuccess());
            yield resolveAction(action, AggregatedSuccess());

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DiscoverPermissionsForProjects,
                startTime,
                AsyncOutcome.Success
            );

            return;
        }

        const responses: DataResponse<ProjectPermissionRecord>[] = yield putAndAwait(
            getProjectPermissions(resourceIds.map((id) => ({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(FailureOperation.DiscoverPermissionsForProjects, ...failures);
            yield put(discoverPermissionsForProjectsFailed({ failure }));
        } else {
            yield put(discoverPermissionsForProjectsSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPermissionsForProjects,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DiscoverPermissionsForProjects);
        yield put(discoverPermissionsForProjectsError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverPermissionsForProjects,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverPermissionsForProjectsListenerSaga(): SagaIterator {
    yield takeLeading(discoverPermissionsForProjects, discoverPermissionsForProjectsSaga);
}
