import { FontSizes, FontWeights, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';

export interface MisconfiguredStateProps {
    title: string;
    description: string;
    /* Alt text for the empty state image */
    imageAlt: string;
    imageSrc: string;
}

/**
 * Style Section
 */

const useDescriptionContainerStyles = makeStyles({
    root: {
        lineHeight: '20px',
        maxWidth: '432px',
        textAlign: 'left',
    },
});

const useTitleContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        textAlign: 'left',
    },
});

/* END */

const containerTokens = { childrenGap: 34 };
const messageTokens = { childrenGap: 6 };

export const MisconfiguredState: React.FC<MisconfiguredStateProps> = React.memo((props: MisconfiguredStateProps) => {
    const { title, description, imageAlt, imageSrc } = props;

    // Style hooks
    const descriptionContainerStyles = useDescriptionContainerStyles();
    const titleContainerStyles = useTitleContainerStyles();

    return (
        <Stack horizontal tokens={containerTokens}>
            <Stack.Item>
                <img alt={imageAlt} src={imageSrc} />
            </Stack.Item>

            <Stack.Item grow={1}>
                <Stack tokens={messageTokens} verticalAlign="center" verticalFill>
                    <Stack.Item styles={titleContainerStyles}>{title}</Stack.Item>

                    <Stack.Item styles={descriptionContainerStyles}>{description}</Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
});
