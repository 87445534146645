import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import { isAuthenticated } from '../../../../data/services/identity';
import {
    AggregatedFailure,
    AggregatedResult,
    ClientError,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../../models/common';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses, createFailureResponseFromThrown } from '../../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { initializeOrganizationMetadata } from '../../../actions/graph/organization-action-creators';
import { initializeUserMetadata } from '../../../actions/graph/signed-in-user-action-creators';
import {
    initializeHome,
    initializeHomeError,
    initializeHomeFailed,
    initializeHomeSuccess,
    loadBackgroundResourcesForHome,
    loadResourcesForHome,
    syncDismissedHomeQuickActions,
} from '../../../actions/sub-applications/home/home-action-creators';
import { listTenants } from '../../../actions/tenant/tenant-action-creators';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';

function* initiateBackgroundLoad(): SagaIterator<AggregatedResult> {
    try {
        const result: AggregatedResult = yield putAndAwait(loadBackgroundResourcesForHome());
        return result;
    } catch (err) {
        const failure = createFailureResponseFromThrown(err, FailureOperation.InitializeHome);
        return AggregatedFailure(failure);
    }
}

export function* initializeHomeSaga(): SagaIterator {
    const startTime = new Date();

    try {
        // Don't issue any requests if the user isn't signed in
        // Note: won't record performance if unauthenticated
        const isSignedIn = yield call(isAuthenticated);

        if (!isSignedIn) {
            yield put(initializeHomeSuccess());
            return;
        }

        // Get metadata for signed-in user
        yield all([
            put(initializeUserMetadata()),
            // Fire-and-forget: check what tenants a user belongs to; get branding for signed-in tenant
            put(initializeOrganizationMetadata()),
            put(listTenants()),
        ]);

        // Discover all resources for home sub-application and issue background operations
        const [loadResourcesForHomeResult]: [AggregatedResult] = yield all([
            putAndAwait(loadResourcesForHome()),

            // Fire-and-forget: initialize Quick Actions state
            put(syncDismissedHomeQuickActions()),
        ]);

        if (isAggregatedFailure(loadResourcesForHomeResult)) {
            const { failures } = loadResourcesForHomeResult;
            const failure = aggregateFailureResponses(FailureOperation.InitializeHome, ...failures);
            yield put(initializeHomeFailed({ failure }));
            yield call(trackTimedPerformanceMetric, PerformanceMetric.InitializeHome, startTime, AsyncOutcome.Failed, {
                errorCodes: getErrorCodes(loadResourcesForHomeResult),
            });
            return;
        }

        yield put(initializeHomeSuccess());

        // Fire-and-forget: load card content and form content
        // Note: waiting for the result so we include execution time and outcome, but don't let exceptions from
        // background loading halt the whole application.
        const backgroundResourcesResult: AggregatedResult = yield call(initiateBackgroundLoad);

        const completeResult = combineResults([loadResourcesForHomeResult, backgroundResourcesResult]);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.InitializeHome,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeHome);
        yield put(initializeHomeError({ error }));
        yield call(trackTimedPerformanceMetric, PerformanceMetric.InitializeHome, startTime, AsyncOutcome.Error, {
            errorCodes: [error.code],
        });
    }
}

export function* initializeHomeListenerSaga(): SagaIterator {
    yield takeLeading(initializeHome, initializeHomeSaga);
}
