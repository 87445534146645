import {
    AzureLocationContract,
    AzureLocationMetadataContract,
    LocationListResultContract,
    PairedRegionContract,
    RegionCategoryContract,
    RegionTypeContract,
    ResourceContractProperties,
    ResourceProvisioningStateContract,
    RoleContract,
    TenantContract,
} from '../data/contracts/resource-manager';
import { UnionMap } from '../types/union-map';
import { isString } from '../utilities/string';

/**
 * Models
 */

export type AzureLocation = Omit<AzureLocationContract, 'metadata'> & {
    metadata: AzureLocationMetadata;
};

export type AzureLocationMetadata = Omit<
    AzureLocationMetadataContract,
    'pairedRegion' | 'regionCategory' | 'regionType'
> & {
    pairedRegion: PairedRegion[];
    regionCategory: RegionCategory;
    regionType: RegionType;
};

export type LocationListResult = Omit<LocationListResultContract, 'value'> & {
    value: AzureLocation[];
};

export type PairedRegion = PairedRegionContract;

export type Role = RoleContract;

type ResourceProperties = ResourceContractProperties;

export type Resource<TProperties = undefined> = TProperties extends undefined
    ? ResourceProperties
    : ResourceProperties & { properties: TProperties };

export type Tenant = TenantContract;

/**
 * Type unions
 */

export type RegionCategory = RegionCategoryContract;

export const RegionCategory: UnionMap<RegionCategory> = {
    Other: 'Other',
    Recommended: 'Recommended',
};

export type RegionType = RegionTypeContract;

export const RegionType: UnionMap<RegionType> = {
    Logical: 'Logical',
    Physical: 'Physical',
};

export type ResourceProvisioningState = ResourceProvisioningStateContract;

export const ResourceProvisioningState: UnionMap<ResourceProvisioningState> = {
    Canceled: 'Canceled',
    Deleting: 'Deleting',
    Failed: 'Failed',
    InProgress: 'InProgress',
    Succeeded: 'Succeeded',
};

/**
 * Type guards
 */

export const isResource = (value: unknown): value is Resource => {
    const resource = value as Resource;
    return (
        resource.id !== undefined &&
        (resource.location === undefined || isString(resource.location)) &&
        resource.name !== undefined
    );
};

export const isResourceWithProperties = (value: unknown): value is Resource<NonNullable<unknown>> => {
    if (!isResource(value)) {
        return false;
    }

    const resource = value as Resource<NonNullable<unknown>>;
    return resource.properties !== undefined;
};
