import { createTheme, FontSizes, NeutralColors, Theme } from '@fluentui/react';
import { IAppSemanticColors } from '../app-semantic-colors';
import { FontStyles } from '../constants/font-styles';
import { IExtendedSemanticColors } from '../extended-semantic-colors';

const lightThemePalette = {
    black: NeutralColors.black,
    neutralDark: NeutralColors.gray190,
    neutralLight: NeutralColors.white,
    neutralLighter: NeutralColors.gray10,
    neutralLighterAlt: NeutralColors.gray20,
    neutralPrimary: NeutralColors.gray160,
    neutralPrimaryAlt: NeutralColors.gray140,
    neutralQuaternary: NeutralColors.gray60,
    neutralQuaternaryAlt: NeutralColors.gray70,
    neutralSecondary: NeutralColors.gray130,
    neutralSecondaryAlt: NeutralColors.gray110,
    neutralTertiary: NeutralColors.gray90,
    neutralTertiaryAlt: NeutralColors.gray80,
    themeDark: NeutralColors.gray20,
    themeDarkAlt: NeutralColors.gray30,
    themeLight: NeutralColors.gray200,
    themeLighter: NeutralColors.gray210,
    themeLighterAlt: NeutralColors.gray220,
    themePrimary: '#0078d4',
    themeSecondary: '#2b88d8',
    themeTertiary: '#71afe5',
    redLight: '#d13438',
    greenLight: '#00ad56',
    themeDarker: '#82c7ff',
    yellowLight: '#fce100',
    white: NeutralColors.white,
};

// App colors for different custom semantic slots for the app
const appSemanticColors: IAppSemanticColors = {
    actionBarBorder: lightThemePalette.neutralQuaternary,
    addDevBoxPanelPoolOptionMetaDataText: lightThemePalette.neutralSecondary,
    addDevBoxPanelFooterErrorIcon: lightThemePalette.redLight,
    addDevBoxPanelFooterErrorText: lightThemePalette.redLight,
    addEnvironmentPanelFooterErrorIcon: lightThemePalette.redLight,
    addEnvironmentPanelFooterErrorText: lightThemePalette.redLight,
    commandBarButtonIcon: lightThemePalette.black,
    confirmationText: lightThemePalette.greenLight,
    downloadRemoteDesktopCardBackground: lightThemePalette.neutralLight,
    downloadRemoteDesktopCardImageBorder: lightThemePalette.black,
    errorMessageIcon: lightThemePalette.redLight,
    errorMessageText: lightThemePalette.redLight,
    successMessageIcon: '#67D48B',
    expandErrorIcon: lightThemePalette.black,
    footerBackground: lightThemePalette.neutralLight,
    footerLinkText: lightThemePalette.black,
    helpMenuPanelPrivacyTermsDivider: lightThemePalette.neutralSecondary,
    helpMenuPanelPrivacyTermsText: lightThemePalette.neutralTertiary,
    iconButtonBorderFocused: lightThemePalette.neutralSecondary,
    iconButtonText: lightThemePalette.black,
    iconButtonTextHovered: '#3AA0F3',
    iconButtonTextPressed: '#6CB8F6',
    meControlSubmenuBackground: lightThemePalette.neutralLighter,
    meControlSubmenuText: lightThemePalette.black,
    metadataItemHighlightedTextColor: lightThemePalette.themePrimary,
    openWindowsDesktopClientOptionCardBackground: lightThemePalette.neutralLight,
    openWindowsDesktopClientOptionCardBackgroundHovered: lightThemePalette.neutralQuaternaryAlt,
    openWindowsDesktopClientOptionCardIcon: lightThemePalette.themePrimary,
    panelBackground: lightThemePalette.neutralLighter,
    personaCoinInitialsColor: lightThemePalette.black,
    personaCoinInitialsColorUnknown: lightThemePalette.redLight,
    quickActionCardBackground: lightThemePalette.neutralLighterAlt,
    quickActionCardIcon: lightThemePalette.themePrimary,
    quickActionCardStripeBackground: lightThemePalette.themePrimary,
    resourceCardBackground: lightThemePalette.neutralLighterAlt,
    selectDevBoxTypeDialogBackground: lightThemePalette.neutralLighter,
    selectEnvironmentDefinitionDialogBackground: lightThemePalette.neutralLighter,
    titleBarBackground: lightThemePalette.neutralLight,
    documentCardBackground: lightThemePalette.neutralQuaternaryAlt,
    dropdownDividerBackground: lightThemePalette.neutralTertiaryAlt,
    infoMessageBarBackground: lightThemePalette.neutralQuaternary,
    infoMessageBarColor: lightThemePalette.black,
    infoMessageBarIconColor: lightThemePalette.black,
    warningMessageText: lightThemePalette.black,
    warningMessageIcon: lightThemePalette.yellowLight,
    disabledText: lightThemePalette.neutralTertiary,
    inlineErrorMessageText: lightThemePalette.redLight,
    /* Begin message bar colors */
    messageBarButton: NeutralColors.gray200,
    messageBarButtonBackground: NeutralColors.white,
    messageBarButtonBackgroundHovered: NeutralColors.gray20,
    messageBarButtonBackgroundPressed: NeutralColors.gray30,
    messageBarButtonHovered: NeutralColors.gray190,
    messageBarButtonPressed: NeutralColors.gray190,
    /* End message bar colors */
    tagBackgroundColor: '#373277',
    customizationDetailsPanelInfoText: NeutralColors.gray80,
    collapsedCustomizationTaskBackground: lightThemePalette.neutralLight,
    expandedCustomizationTaskBackground: lightThemePalette.neutralQuaternaryAlt,
    expandedCustomizationTaskText: lightThemePalette.neutralPrimaryAlt,
    expandIcon: '#919191',
    activeExpandIcon: lightThemePalette.black,
    customizationTaskTimeText: lightThemePalette.neutralTertiary,
    queuedIcon: '#404040',
    skippedIcon: '#919191',
    downloadButtonText: lightThemePalette.themeDarkAlt,
    separator: lightThemePalette.neutralQuaternary,
    transparentBackground: 'transparent',
};

// Fluent's extended semantic colors
const extendedSemanticColors: Partial<IExtendedSemanticColors> = {
    commandBarButtonBackgroundHover: '#004C87',
    commandBarButtonBackgroundSelected: NeutralColors.gray130,
    commandBarButtonBackgroundSelectedHover: NeutralColors.gray130,
    buttonText: lightThemePalette.black,
    buttonTextPressed: lightThemePalette.neutralDark,
    buttonTextHovered: lightThemePalette.neutralPrimary,
    buttonBackgroundHovered: '#3B3A39',
    buttonBackgroundPressed: lightThemePalette.neutralLight,
    disabledBackground: lightThemePalette.neutralQuaternaryAlt,
    inputBackgroundChecked: lightThemePalette.themePrimary,
    menuBackground: lightThemePalette.neutralLight,
    menuItemBackgroundHovered: lightThemePalette.neutralQuaternaryAlt,
    menuItemBackgroundPressed: lightThemePalette.neutralQuaternary,
    menuDivider: lightThemePalette.neutralTertiaryAlt,
    menuIcon: lightThemePalette.themeDarkAlt,
    menuHeader: lightThemePalette.black,
    menuItemText: lightThemePalette.neutralPrimary,
    menuItemTextHovered: lightThemePalette.neutralDark,
    link: '#3AA0F3',
};

export const LightTheme: Theme = createTheme({
    fonts: {
        medium: {
            fontFamily: FontStyles.fontFamily,
            fontSize: FontSizes.size14,
        },
        large: {
            fontSize: FontSizes.size16,
        },
    },
    palette: lightThemePalette,
    semanticColors: { ...extendedSemanticColors, ...appSemanticColors },
});
