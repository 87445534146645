import { Pool } from '../../../models/pool';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import { discoverPoolsForDevBoxes, discoverPoolsForProjects, listPools } from './pool-action-creators';

/**
 * Action types
 */

type PoolActionName =
    | ActionName<'DiscoverPoolsForProjects', 'Error' | 'Failed' | 'Success'>
    | ActionName<'DiscoverPoolsForDevBoxes', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListPools', 'Error' | 'Failed' | 'Success'>;

export type PoolActionType =
    | ActionType<'DISCOVER_POOLS_FOR_PROJECTS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'DISCOVER_POOLS_FOR_DEV_BOXES', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_POOLS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const PoolActionType: UnionValueMap<PoolActionName, PoolActionType> = {
    DiscoverPoolsForProjects: 'DISCOVER_POOLS_FOR_PROJECTS',
    DiscoverPoolsForProjectsError: 'DISCOVER_POOLS_FOR_PROJECTS_ERROR',
    DiscoverPoolsForProjectsFailed: 'DISCOVER_POOLS_FOR_PROJECTS_FAILED',
    DiscoverPoolsForProjectsSuccess: 'DISCOVER_POOLS_FOR_PROJECTS_SUCCESS',
    DiscoverPoolsForDevBoxes: 'DISCOVER_POOLS_FOR_DEV_BOXES',
    DiscoverPoolsForDevBoxesError: 'DISCOVER_POOLS_FOR_DEV_BOXES_ERROR',
    DiscoverPoolsForDevBoxesFailed: 'DISCOVER_POOLS_FOR_DEV_BOXES_FAILED',
    DiscoverPoolsForDevBoxesSuccess: 'DISCOVER_POOLS_FOR_DEV_BOXES_SUCCESS',
    ListPools: 'LIST_POOLS',
    ListPoolsError: 'LIST_POOLS_ERROR',
    ListPoolsFailed: 'LIST_POOLS_FAILED',
    ListPoolsSuccess: 'LIST_POOLS_SUCCESS',
};

/**
 * Payloads
 */

export type ListPoolsSuccessPayload = IndexedPayload & ResultPayload<Pool[]>;

/**
 * Action shorthands
 */

export type DiscoverPoolsForProjectsAction = ReturnType<typeof discoverPoolsForProjects>;
export type DiscoverPoolsForDevBoxesAction = ReturnType<typeof discoverPoolsForDevBoxes>;
export type ListPoolsAction = ReturnType<typeof listPools>;
