import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { isRegionalDataPlaneUri } from '../../../../ids/data-plane';
import { ClientError, FailureCode, FailureOperation, FailureResponse } from '../../../../models/common';
import { getSingleDevCenterUriFromCurrentLocation } from '../../../../utilities/single-dev-center';
import { isUndefinedOrEmpty } from '../../../../utilities/string';
import {
    initializeSingleDevCenterHome,
    initializeSingleDevCenterHomeError,
    initializeSingleDevCenterHomeFailed,
    initializeSingleDevCenterHomeSuccess,
    loadResourcesForSingleDevCenter,
    setSingleDevCenterUri,
} from '../../../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { InitializeSingleDevCenterUriAction } from '../../../actions/sub-applications/single-dev-center/single-dev-center-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { takeLeading } from '../../../effects/take';

export function* initializeSingleDevCenterHomeSaga(action: InitializeSingleDevCenterUriAction): SagaIterator {
    const { meta } = action;

    try {
        // Initialize uri
        const uri: string | undefined = yield call(getSingleDevCenterUriFromCurrentLocation);

        if (isUndefinedOrEmpty(uri)) {
            // If no uri is provided in location query params,
            // we consider this a success (and allow the user to input the uri through the UI)
            yield put(
                initializeSingleDevCenterHomeFailed(
                    { failure: FailureResponse({ code: FailureCode.SingleDevCenterMissingUri }) },
                    meta
                )
            );
            return;
        }

        // Put the uri on the store even if it is malformed, but don't load resources if it is malformed
        yield put(setSingleDevCenterUri({ devCenterUri: uri }, meta));

        const isDevCenterUri: boolean = yield call(isRegionalDataPlaneUri, uri);

        if (!isDevCenterUri) {
            yield put(
                initializeSingleDevCenterHomeFailed(
                    { failure: FailureResponse({ code: FailureCode.SingleDevCenterInvalidUri }) },
                    meta
                )
            );
            return;
        }

        // Fire and forget load resources action, then dispatch initialization success action
        yield put(loadResourcesForSingleDevCenter(meta));
        yield put(initializeSingleDevCenterHomeSuccess(meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeSingleDevCenterUri);
        yield put(initializeSingleDevCenterHomeError({ error }, meta));
    }
}

export function* initializeSingleDevCenterHomeListenerSaga(): SagaIterator {
    yield takeLeading(initializeSingleDevCenterHome, initializeSingleDevCenterHomeSaga);
}
