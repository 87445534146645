import { Buffer } from 'buffer';
import { parse, stringify } from 'yaml';
import { CustomizationTaskExecutionAccount, PutCustomizationTask } from '../../../models/customization';
import { tryOrDefault } from '../../../utilities/try-or-default';
import {
    wingetBase64ParameterName,
    WingetConfigurationSchemaVersion,
    WingetConfigurationYamlContract,
    WingetResourceItem,
} from './models';

const createWingetTask = (wingetFileContent: WingetConfigurationYamlContract): PutCustomizationTask => ({
    name: 'winget',
    runAs: CustomizationTaskExecutionAccount.User,
    parameters: {
        [wingetBase64ParameterName]: Buffer.from(stringify(wingetFileContent)).toString('base64'),
    },
});

export const parseWingetYaml = (content: string): WingetConfigurationYamlContract => parse(content);

export const isWingetConfiguration = (content: string): boolean =>
    parseWingetYaml(content)?.properties?.configurationVersion === WingetConfigurationSchemaVersion;

const orderResourceItems = (items: WingetResourceItem[]): WingetResourceItem[] => {
    const sortedItems: WingetResourceItem[] = [];
    let itemsToSort = [...items]; // Clone the original array

    while (itemsToSort.length > 0) {
        let itemAdded = false;

        itemsToSort = itemsToSort.filter((item) => {
            const dependenciesMet = item.dependsOn
                ? item.dependsOn.every((dependency) => sortedItems.some((i) => i.id === dependency))
                : true;

            if (dependenciesMet) {
                sortedItems.push(item);
                itemAdded = true;
                return false; // Remove the item from itemsToSort
            }
            return true; // Keep the item in itemsToSort
        });

        if (!itemAdded) {
            // If no item from itemsToSort meets the dependency criteria to be added to the sortedItems,
            // we have a circular or missing dependency
            break;
        }
    }

    // Items with circular or missing dependencies won't get added to the sortedItem array.
    // Customizations for that should fail, but we want to send the request for that regardless.
    // Adding those remaining items to the end of our list
    return [...sortedItems, ...itemsToSort];
};

const toWingetOrderedList = (contract: WingetConfigurationYamlContract): WingetConfigurationYamlContract[] => {
    const orderedResources = orderResourceItems(contract.properties.resources);
    return orderedResources.map((resource) => {
        // For each resource create a winget contract, maintaining all original properties, but removing dependsOn
        // as tasks are being sent separately. The dependency is maintained by the order of the tasks.
        delete resource.dependsOn;
        return {
            properties: {
                ...contract.properties,
                resources: [resource],
            },
        };
    });
};

// For the first version of the winget feature we won't be breaking and ordering the winget resources,
// we will be using tryParsedWingetCustomizationTask instead. After maturing, we should use this one.
export const tryParseOrderedWingetCustomizationTasks = tryOrDefault((file: string): PutCustomizationTask[] => {
    if (isWingetConfiguration(file)) {
        const parsedWinget = parseWingetYaml(file);
        const orderedResourceList = toWingetOrderedList(parsedWinget);

        return orderedResourceList.map((winget) => createWingetTask(winget));
    }

    return [];
});

export const tryParseWingetCustomizationTask = tryOrDefault((file: string): PutCustomizationTask[] => {
    if (isWingetConfiguration(file)) {
        const parsedWinget = parseWingetYaml(file);

        return [createWingetTask(parsedWinget)];
    }

    return [];
});
