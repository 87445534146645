import { UnionMap } from '../types/union-map';

/**
 * These are semantic colors for components specific to our app.
 *
 * These are separated from ISemanticColors and IExtendedSemanticColors to clearly delineate that they style our
 * controls rather than Fluent's built-in components.
 */
export interface IAppSemanticColors {
    actionBarBorder: string;
    addDevBoxPanelPoolOptionMetaDataText: string;
    addDevBoxPanelFooterErrorIcon: string;
    addDevBoxPanelFooterErrorText: string;
    addEnvironmentPanelFooterErrorIcon: string;
    addEnvironmentPanelFooterErrorText: string;
    commandBarButtonIcon: string;
    confirmationText: string;
    errorMessageIcon: string;
    errorMessageText: string;
    successMessageIcon: string;
    expandErrorIcon: string;
    downloadRemoteDesktopCardBackground: string;
    downloadRemoteDesktopCardImageBorder: string;
    footerBackground: string;
    footerLinkText: string;
    helpMenuPanelPrivacyTermsDivider: string;
    helpMenuPanelPrivacyTermsText: string;
    iconButtonBorderFocused: string;
    iconButtonText: string;
    iconButtonTextHovered: string;
    iconButtonTextPressed: string;
    meControlSubmenuBackground: string;
    meControlSubmenuText: string;
    metadataItemHighlightedTextColor: string;
    openWindowsDesktopClientOptionCardBackground: string;
    openWindowsDesktopClientOptionCardBackgroundHovered: string;
    openWindowsDesktopClientOptionCardIcon: string;
    panelBackground: string;
    personaCoinInitialsColor: string;
    personaCoinInitialsColorUnknown: string;
    quickActionCardBackground: string;
    quickActionCardIcon: string;
    quickActionCardStripeBackground: string;
    resourceCardBackground: string;
    selectDevBoxTypeDialogBackground: string;
    selectEnvironmentDefinitionDialogBackground: string;
    titleBarBackground: string;
    documentCardBackground: string;
    dropdownDividerBackground: string;
    infoMessageBarBackground: string;
    infoMessageBarColor: string;
    infoMessageBarIconColor: string;
    warningMessageText: string;
    warningMessageIcon: string;
    disabledText: string;
    messageBarButton: string;
    messageBarButtonBackground: string;
    messageBarButtonBackgroundHovered: string;
    messageBarButtonBackgroundPressed: string;
    messageBarButtonHovered: string;
    messageBarButtonPressed: string;
    inlineErrorMessageText: string;
    tagBackgroundColor: string;
    customizationDetailsPanelInfoText: string;
    collapsedCustomizationTaskBackground: string;
    expandedCustomizationTaskBackground: string;
    expandedCustomizationTaskText: string;
    expandIcon: string;
    activeExpandIcon: string;
    customizationTaskTimeText: string;
    queuedIcon: string;
    skippedIcon: string;
    downloadButtonText: string;
    separator: string;
    transparentBackground: string;
}

export type AppSemanticColor = keyof IAppSemanticColors;

export const AppSemanticColor: UnionMap<AppSemanticColor> = {
    actionBarBorder: 'actionBarBorder',
    addDevBoxPanelPoolOptionMetaDataText: 'addDevBoxPanelPoolOptionMetaDataText',
    addDevBoxPanelFooterErrorIcon: 'addDevBoxPanelFooterErrorIcon',
    addDevBoxPanelFooterErrorText: 'addDevBoxPanelFooterErrorText',
    addEnvironmentPanelFooterErrorIcon: 'addEnvironmentPanelFooterErrorIcon',
    addEnvironmentPanelFooterErrorText: 'addEnvironmentPanelFooterErrorText',
    commandBarButtonIcon: 'commandBarButtonIcon',
    confirmationText: 'confirmationText',
    downloadRemoteDesktopCardBackground: 'downloadRemoteDesktopCardBackground',
    downloadRemoteDesktopCardImageBorder: 'downloadRemoteDesktopCardImageBorder',
    errorMessageIcon: 'errorMessageIcon',
    errorMessageText: 'errorMessageText',
    successMessageIcon: 'successMessageIcon',
    expandErrorIcon: 'expandErrorIcon',
    footerBackground: 'footerBackground',
    footerLinkText: 'footerLinkText',
    helpMenuPanelPrivacyTermsDivider: 'helpMenuPanelPrivacyTermsDivider',
    helpMenuPanelPrivacyTermsText: 'helpMenuPanelPrivacyTermsText',
    iconButtonBorderFocused: 'iconButtonBorderFocused',
    iconButtonText: 'iconButtonText',
    iconButtonTextHovered: 'iconButtonTextHovered',
    iconButtonTextPressed: 'iconButtonTextPressed',
    meControlSubmenuBackground: 'meControlSubmenuBackground',
    meControlSubmenuText: 'meControlSubmenuText',
    metadataItemHighlightedTextColor: 'metadataItemHighlightedTextColor',
    openWindowsDesktopClientOptionCardBackground: 'openWindowsDesktopClientOptionCardBackground',
    openWindowsDesktopClientOptionCardBackgroundHovered: 'openWindowsDesktopClientOptionCardBackgroundHovered',
    openWindowsDesktopClientOptionCardIcon: 'openWindowsDesktopClientOptionCardIcon',
    panelBackground: 'panelBackground',
    personaCoinInitialsColor: 'personaCoinInitialsColor',
    personaCoinInitialsColorUnknown: 'personaCoinInitialsColorUnknown',
    quickActionCardBackground: 'quickActionCardBackground',
    quickActionCardIcon: 'quickActionCardIcon',
    quickActionCardStripeBackground: 'quickActionCardStripeBackground',
    resourceCardBackground: 'resourceCardBackground',
    selectDevBoxTypeDialogBackground: 'selectDevBoxTypeDialogBackground',
    selectEnvironmentDefinitionDialogBackground: 'selectEnvironmentDefinitionDialogBackground',
    titleBarBackground: 'titleBarBackground',
    documentCardBackground: 'documentCardBackground',
    dropdownDividerBackground: 'dropdownDividerBackground',
    infoMessageBarBackground: 'infoMessageBarBackground',
    infoMessageBarColor: 'infoMessageBarColor',
    infoMessageBarIconColor: 'infoMessageBarIconColor',
    warningMessageText: 'warningMessageText',
    warningMessageIcon: 'warningMessageIcon',
    disabledText: 'disabledText',
    inlineErrorMessageText: 'inlineErrorMessageText',
    messageBarButtonBackground: 'messageBarButtonBackground',
    messageBarButton: 'messageBarButton',
    messageBarButtonBackgroundHovered: 'messageBarButtonBackgroundHovered',
    messageBarButtonBackgroundPressed: 'messageBarButtonBackgroundPressed',
    messageBarButtonHovered: 'messageBarButtonHovered',
    messageBarButtonPressed: 'messageBarButtonPressed',
    tagBackgroundColor: 'tagBackgroundColor',
    customizationDetailsPanelInfoText: 'customizationDetailsPanelInfoText',
    collapsedCustomizationTaskBackground: 'collapsedCustomizationTaskBackground',
    expandedCustomizationTaskBackground: 'expandedCustomizationTaskBackground',
    expandedCustomizationTaskText: 'expandedCustomizationTaskText',
    expandIcon: 'expandIcon',
    activeExpandIcon: 'activeExpandIcon',
    customizationTaskTimeText: 'customizationTaskTimeText',
    queuedIcon: 'queuedIcon',
    skippedIcon: 'skippedIcon',
    downloadButtonText: 'downloadButtonText',
    separator: 'separator',
    transparentBackground: 'transparentBackground',
};
