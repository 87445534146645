import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { getPhotoForSignedInUser, getSignedInUser } from '../../../data/services/graph/signed-in-user';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { GraphUser } from '../../../models/graph';
import { createObjectUrlForBlob } from '../../../utilities/data';
import {
    getPhotoForSignedInUser as getPhotoForSignedInUserActionCreator,
    getPhotoForSignedInUserError,
    getPhotoForSignedInUserFailed,
    getPhotoForSignedInUserSuccess,
    getSignedInUser as getSignedInUserActionCreator,
    getSignedInUserError,
    getSignedInUserFailed,
    getSignedInUserSuccess,
} from '../../actions/graph/signed-in-user-action-creators';
import { GetPhotoForSignedInUserAction, GetSignedInUserAction } from '../../actions/graph/signed-in-user-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForGraphPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

export function* getPhotoForSignedInUserSaga(action: GetPhotoForSignedInUserAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { photoSize } = payload;

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForGraphPayload(), meta));
        const response: DataResponse<Blob> = yield call(getPhotoForSignedInUser, accessToken, photoSize, activityId);

        if (isFailureResponse(response)) {
            yield put(getPhotoForSignedInUserFailed({ failure: response }, meta));
            return;
        }

        const { data } = response;
        const photoUrl = yield call(createObjectUrlForBlob, data);
        yield put(getPhotoForSignedInUserSuccess({ photoUrl }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetPhotoForSignedInUser);
        yield put(getPhotoForSignedInUserError({ error }, meta));
    }
}

export function* getPhotoForSignedInUserListenerSaga(): SagaIterator {
    yield takeLeading(getPhotoForSignedInUserActionCreator, getPhotoForSignedInUserSaga);
}

export function* getSignedInUserSaga(action: GetSignedInUserAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForGraphPayload(), meta));
        const response: DataResponse<GraphUser> = yield call(getSignedInUser, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(getSignedInUserFailed({ failure: response }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;
        yield put(getSignedInUserSuccess({ result: data }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetSignedInUser);
        yield put(getSignedInUserError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* getSignedInUserListenerSaga(): SagaIterator {
    yield takeLeading(getSignedInUserActionCreator, getSignedInUserSaga);
}
