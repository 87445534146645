import { createReducer } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { InitializationState, InitializationStatus } from '../../models/common';
import { ActionCreator } from '../actions/core-action-creators';
import { ErrorPayload, FailedPayload, PayloadAction } from '../actions/core-actions';

interface CreateInitializationStatusReducerOptions<
    TErrorPayload extends ErrorPayload = ErrorPayload,
    TFailedPayload extends FailedPayload = FailedPayload,
    TInitializingPayload = void,
    TSuccessPayload = void
> {
    error?: ActionCreator<TErrorPayload>;
    failed?: ActionCreator<TFailedPayload>;
    initializing?: ActionCreator<TInitializingPayload>;
    success?: ActionCreator<TSuccessPayload>;
}

export type InitializationStatusReducer = (
    store: InitializationStatus | undefined,
    action: Action
) => InitializationStatus;

const errorReducer = (status: InitializationStatus, action: PayloadAction<ErrorPayload>) => {
    status.failure = action.payload.error;
    status.state = InitializationState.Error;
};

const failedReducer = (status: InitializationStatus, action: PayloadAction<FailedPayload>) => {
    status.failure = action.payload.failure;
    status.state = InitializationState.Failed;
};

const initializingReducer = (status: InitializationStatus) => {
    status.failure = undefined;
    status.state = InitializationState.Initializing;
};

const successReducer = (status: InitializationStatus) => {
    status.state = InitializationState.Success;
};

export const createInitializationStatusReducer = <
    TErrorPayload extends ErrorPayload = ErrorPayload,
    TFailedPayload extends FailedPayload = FailedPayload,
    TInitializing = void,
    TSuccessPayload = void
>(
    options: CreateInitializationStatusReducerOptions<TErrorPayload, TFailedPayload, TInitializing, TSuccessPayload>
): InitializationStatusReducer =>
    createReducer(InitializationStatus(), (builder) => {
        const { error, failed, initializing, success } = options;

        if (error) {
            builder.addCase(error, errorReducer);
        }

        if (failed) {
            builder.addCase(failed, failedReducer);
        }

        if (initializing) {
            builder.addCase(initializing, initializingReducer);
        }

        if (success) {
            builder.addCase(success, successReducer);
        }
    });
