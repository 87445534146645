import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { DevBoxOperationConstants } from '../../../constants/dev-box';
import { ListDevBoxesResponse, listDevBoxes } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { DevBox } from '../../../models/dev-box';
import { getDevBoxState } from '../../../utilities/dev-box';
import {
    addDevBoxAccepted,
    deleteDevBoxAccepted,
    hibernateDevBoxAccepted,
    listDevBoxes as listDevBoxesActionCreator,
    listDevBoxesError,
    listDevBoxesFailed,
    listDevBoxesSuccess,
    repairDevBoxAccepted,
    restartDevBoxAccepted,
    resumeDevBoxAccepted,
    shutdownDevBoxAccepted,
    startDevBoxAccepted,
} from '../../actions/dev-box/dev-box-action-creators';
import { ListDevBoxesAction } from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { DevBoxState } from '../../store/dev-box-state';

const getPendingOperationAndSubResourceActions = (devBox: DevBox, activityId?: string): Action[] => {
    const actions = [];
    const { uri: id } = devBox;
    const meta = activityId ? { activityId } : undefined;

    const state = getDevBoxState(devBox);
    switch (state) {
        case DevBoxState.Creating:
            actions.push(addDevBoxAccepted({ id, result: devBox }, meta));
            break;
        case DevBoxState.Deleting:
            actions.push(deleteDevBoxAccepted({ id }, meta));
            break;
        case DevBoxState.Resuming:
            // To note: "Resuming" can currently only be set as a pending state. It's included here for completeness.
            actions.push(resumeDevBoxAccepted({ id }, meta));
            break;
        case DevBoxState.Starting:
            actions.push(startDevBoxAccepted({ id }, meta));
            break;
        case DevBoxState.Stopping:
            actions.push(shutdownDevBoxAccepted({ id }, meta));
            break;
        case DevBoxState.Hibernating:
            // To note: "Hibernating" can currently only be set as a pending state. It's included here for completeness.
            actions.push(hibernateDevBoxAccepted({ id }, meta));
            break;
        case DevBoxState.Restarting:
            actions.push(restartDevBoxAccepted({ id }, meta));
            break;
        case DevBoxState.Repairing:
            actions.push(repairDevBoxAccepted({ id }, meta));
        default:
            break;
    }

    return actions;
};

export function* listDevBoxesSaga(action: ListDevBoxesAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListDevBoxesResponse = yield call(
            listDevBoxes,
            id,
            DevBoxOperationConstants.UserId,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(listDevBoxesFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data: devBoxes } = response;

        // dispatch actions to track sub resource actions
        // and hydrate additional dev box state
        yield all(
            devBoxes.flatMap((devBox) =>
                getPendingOperationAndSubResourceActions(devBox, activityId).map((action) => put(action))
            )
        );

        yield put(listDevBoxesSuccess({ id, result: devBoxes }, meta));
        yield resolveAction(action, { data: devBoxes, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListDevBoxes);
        yield put(listDevBoxesError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listDevBoxesListenerSaga(): SagaIterator {
    yield takeEvery(listDevBoxesActionCreator, listDevBoxesSaga);
}
