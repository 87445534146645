import { DataPlaneResourcePathPrefix } from '../constants/data-plane';
import { StringifiedPattern } from '../constants/patterns';
import { ClientError } from '../models/common';
import { DataPlaneIdTokenSet } from '../models/data-plane';
import { isUndefinedOrWhiteSpace } from '../utilities/string';
import { tryOrDefault } from '../utilities/try-or-default';
import { isValidDataPlaneUri, parseDataPlanePath } from './data-plane';

/* eslint-disable security/detect-non-literal-regexp */
// Justification: regex is non-literal, but is not constructed from user input.
const customizationGroupPathPattern = new RegExp(
    `^/${DataPlaneResourcePathPrefix.Project}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.User}/${StringifiedPattern.Guid}/${DataPlaneResourcePathPrefix.DevBox}/${StringifiedPattern.ResourceName}/${DataPlaneResourcePathPrefix.CustomizationGroup}/${StringifiedPattern.ResourceName}$`,
    'i'
);
/* eslint-enable security/detect-non-literal-regexp */

export const createCustomizationGroupDataPlaneUri = (
    tokens: DataPlaneIdTokenSet<'devBoxName' | 'devCenter' | 'projectName' | 'customizationGroupName' | 'user'>
): string => {
    const { devBoxName, devCenter, projectName, customizationGroupName, user } = tokens;

    if (isUndefinedOrWhiteSpace(devBoxName)) {
        throw new ClientError('Dev box name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(devCenter)) {
        throw new ClientError('Dev center token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(projectName)) {
        throw new ClientError('Project name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(customizationGroupName)) {
        throw new ClientError('Customization group name token cannot be undefined or white space');
    }

    if (isUndefinedOrWhiteSpace(user)) {
        throw new ClientError('User token cannot be undefined or white space');
    }

    const path = `/${[
        DataPlaneResourcePathPrefix.Project,
        projectName.toLowerCase(),
        DataPlaneResourcePathPrefix.User,
        user.toLowerCase(),
        DataPlaneResourcePathPrefix.DevBox,
        devBoxName.toLowerCase(),
        DataPlaneResourcePathPrefix.CustomizationGroup,
        customizationGroupName.toLowerCase(),
    ].join('/')}`;

    const uri = new URL(path, devCenter);

    return `${uri}`;
};

export const getTokensFromCustomizationGroupDataPlaneUri = (
    uri: string
): DataPlaneIdTokenSet<'devBoxName' | 'devCenter' | 'projectName' | 'customizationGroupName' | 'user'> => {
    if (!isValidCustomizationGroupDataPlaneUri(uri)) {
        throw new ClientError('Given URI was not a valid Data Plane URI for a customization group');
    }

    const { origin, pathname } = new URL(uri);
    const [, projectName, , user, , devBoxName, , customizationGroupName] = parseDataPlanePath(pathname);

    return {
        devBoxName,
        devCenter: origin,
        projectName,
        customizationGroupName,
        user,
    };
};

export const isValidCustomizationGroupDataPlaneUri = (uri: string): boolean =>
    isValidDataPlaneUri(uri, customizationGroupPathPattern);

export const tryCreateCustomizationGroupDataPlaneUri = tryOrDefault(createCustomizationGroupDataPlaneUri);
export const tryGetTokensFromCustomizationGroupDataPlaneUri = tryOrDefault(getTokensFromCustomizationGroupDataPlaneUri);
