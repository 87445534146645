import { DefaultButton, IStackTokens, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';

interface DevMenuPanelFooterProps {
    isSubmitDisabled?: boolean;
    onCancelClicked: () => void;
    onSubmitClicked: () => void;
}

const messages = defineMessages({
    cancelButtonAriaLabel: {
        id: 'DevMenuPanelFooter_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for "Cancel" button in dev menu form',
    },
    cancelButtonText: {
        id: 'DevMenuPanelFooter_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for "Cancel" button in dev menu form',
    },
    saveButtonAriaLabel: {
        id: 'DevMenuPanelFooter_SaveButton_AriaLabel',
        defaultMessage: 'Save',
        description: 'Aria label for "Save" button in dev menu form',
    },
    saveButtonText: {
        id: 'DevMenuPanelFooter_SaveButton_Text',
        defaultMessage: 'Save',
        description: 'Text for "Save" button in dev menu form',
    },
});

/**
 * Styles
 */

const buttonContainerTokens: IStackTokens = {
    childrenGap: 8,
};

/**
 * END Styles
 */

export const DevMenuPanelFooter: React.FC<DevMenuPanelFooterProps> = (props: DevMenuPanelFooterProps) => {
    const { isSubmitDisabled, onCancelClicked, onSubmitClicked } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    return (
        <Stack horizontal tokens={buttonContainerTokens} verticalAlign="center" verticalFill>
            <Stack.Item>
                <PrimaryButton
                    ariaLabel={formatMessage(messages.saveButtonAriaLabel)}
                    disabled={isSubmitDisabled}
                    onClick={onSubmitClicked}
                    text={formatMessage(messages.saveButtonText)}
                />
            </Stack.Item>

            <Stack.Item>
                <DefaultButton
                    ariaLabel={formatMessage(messages.cancelButtonAriaLabel)}
                    onClick={onCancelClicked}
                    text={formatMessage(messages.cancelButtonText)}
                />
            </Stack.Item>
        </Stack>
    );
};
