import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import { listProjects } from './project-action-creators';

/**
 * Action types
 */

type ProjectActionName = ActionName<'ListProjects', 'Error' | 'Failed' | 'Success'>;

export type ProjectActionType = ActionType<'LIST_PROJECTS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const ProjectActionType: UnionValueMap<ProjectActionName, ProjectActionType> = {
    ListProjects: 'LIST_PROJECTS',
    ListProjectsError: 'LIST_PROJECTS_ERROR',
    ListProjectsFailed: 'LIST_PROJECTS_FAILED',
    ListProjectsSuccess: 'LIST_PROJECTS_SUCCESS',
};

/**
 * Action shorthands
 */

export type ListProjectsAction = ReturnType<typeof listProjects>;
