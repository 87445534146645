import { makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import errorImage from '../../content/images/error.svg';
import CenteredPortalLayout from '../../layouts/centered-portal-layout';
import { SingleDevCenterMisconfigurationType } from './models';

export type SingleDevCenterUriMisconfiguredProps = {
    devCenterUri: string;
    misconfigurationType: SingleDevCenterMisconfigurationType;
};

const messages = defineMessages({
    errorImageAlt: {
        id: 'SingleDevCenter_ErrorImageAlt',
        defaultMessage: 'Misconfigured dev center uri',
        description: 'Alt text for "misconfigured dev center uri" image',
    },
});

/**
 * Style Section
 */

// Note: using paddingTop to space StackItems as Stack will override margins
const useContainerStyles = makeStyles({
    root: {
        lineHeight: '22px',
        paddingTop: 6,
    },
});

/* END */

const SingleDevCenterUriMisconfigurationDisplay: React.FC<React.PropsWithChildren<unknown>> = (
    props: React.PropsWithChildren<unknown>
) => {
    const { children } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const containerStyles = useContainerStyles();

    return (
        <CenteredPortalLayout
            contentPaddingBottom={40}
            contentPaddingTop={40}
            id="single-dev-center-misconfigured"
            verticalFill
        >
            <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
                <Stack.Item>
                    <img alt={formatMessage(messages.errorImageAlt)} src={errorImage} />
                </Stack.Item>

                <Stack.Item styles={containerStyles}>{children}</Stack.Item>
            </Stack>
        </CenteredPortalLayout>
    );
};

export const SingleDevCenterUriMisconfigured: React.FC<SingleDevCenterUriMisconfiguredProps> = React.memo(
    (props: SingleDevCenterUriMisconfiguredProps) => {
        const { misconfigurationType, devCenterUri } = props;

        const messageValues = React.useMemo(() => (devCenterUri ? { devCenterUri } : undefined), [devCenterUri]);

        switch (misconfigurationType) {
            case SingleDevCenterMisconfigurationType.InvalidUri:
                return (
                    <SingleDevCenterUriMisconfigurationDisplay>
                        <FormattedMessage
                            id="SingleDevCenter_InvalidUriMessage"
                            defaultMessage="{devCenterUri} is not a valid dev center uri"
                            description="Full screen error message informing user the dev center uri they inputted is invalid."
                            values={messageValues}
                        />
                    </SingleDevCenterUriMisconfigurationDisplay>
                );
            case SingleDevCenterMisconfigurationType.FailedToFetchOrNotFound:
            default:
                return (
                    <SingleDevCenterUriMisconfigurationDisplay>
                        <FormattedMessage
                            id="SingleDevCenter_NoAccessMessage"
                            defaultMessage="We were unable to list projects for the dev center at {devCenterUri}. This might mean the dev center does not exist."
                            description="Full screen error message informing user that we failed to list projects for the dev center uri they inputted."
                            values={messageValues}
                        />
                    </SingleDevCenterUriMisconfigurationDisplay>
                );
        }
    }
);

export const SingleDevCenterUriMissing: React.FC = React.memo(() => (
    <SingleDevCenterUriMisconfigurationDisplay>
        <FormattedMessage
            id="SingleDevCenter_MissingUriMessage"
            defaultMessage="Missing devcenter uri"
            description="Full screen error message informing user the dev center uri they inputted is invalid."
        />
    </SingleDevCenterUriMisconfigurationDisplay>
));
