import { FontSizes, FontWeights, IPanelStyleProps, IPanelStyles } from '@fluentui/react';
import { getSemanticColor } from '../../utilities/styles';

export const PanelStyles = (props: IPanelStyleProps): Partial<IPanelStyles> => {
    const { theme } = props;

    return {
        main: {
            backgroundColor: getSemanticColor(theme, 'panelBackground'),
            boxShadow: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
            colorScheme: 'dark',
        },
        headerText: {
            fontSize: FontSizes.size20,
            fontWeight: FontWeights.semibold,
            lineHeight: '28px',
        },
        commands: {
            backgroundColor: getSemanticColor(theme, 'panelBackground'),
        },
        footer: {
            backgroundColor: getSemanticColor(theme, 'panelBackground'),
        },
    };
};
