import { Method } from '../../constants/http';
import { DataResponse, FailureOperation } from '../../models/common';
import { createFailureResponseFromThrown } from '../../utilities/failure';
import { fetchRequest } from './fetch-request';

export const warmNameRecord = async (url: string): Promise<DataResponse> => {
    try {
        await fetchRequest(url, Method.HEAD);
        return { succeeded: true };
    } catch (err) {
        if (err instanceof TypeError) {
            return createFailureResponseFromThrown(err, FailureOperation.WarmDevCenterNameRecord);
        }

        throw err;
    }
};
