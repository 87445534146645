import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    listDevBoxOperations,
    listDevBoxOperationsError,
    listDevBoxOperationsFailed,
    listDevBoxOperationsSuccess,
    loadDevBoxOperationsForDevBoxes,
    loadDevBoxOperationsForDevBoxesError,
    loadDevBoxOperationsForDevBoxesFailed,
    loadDevBoxOperationsForDevBoxesSuccess,
} from '../actions/dev-box-operation/dev-box-operation-action-creators';
import { devBoxOperationAdapter } from '../adapters/dev-box-operation-adapters';
import {
    DevBoxOperationDataStore,
    DevBoxOperationStatusStore,
    DevBoxOperationsStore,
} from '../store/dev-box-operation-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const devBoxOperationReducer = combineReducers<DevBoxOperationsStore>({
    data: createReducer(DevBoxOperationDataStore(), (builder) => {
        builder.addCase(listDevBoxOperationsSuccess, (store, action) => {
            const { result } = getPayload(action);

            devBoxOperationAdapter.setMany(
                store.operations,
                result.map((operation) => Entity(operation.uri, operation))
            );
        });
    }),

    status: combineReducers<DevBoxOperationStatusStore>({
        listDevBoxOperations: createIndexedStatusReducer({
            inProgress: listDevBoxOperations,
            error: listDevBoxOperationsError,
            failed: listDevBoxOperationsFailed,
            success: listDevBoxOperationsSuccess,
        }),

        loadDevBoxOperationsForDevBoxes: createStatusReducer({
            inProgress: loadDevBoxOperationsForDevBoxes,
            error: loadDevBoxOperationsForDevBoxesError,
            failed: loadDevBoxOperationsForDevBoxesFailed,
            success: loadDevBoxOperationsForDevBoxesSuccess,
        }),
    }),
});
