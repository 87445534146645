import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { RoleContract } from '../../../data/contracts/resource-manager';
import { GetPermissionsResponse } from '../../../data/services/resource-manager/permission';
import { processProjectEnvironmentTypePermissions } from '../../../data/services/web-worker/permission-worker-pool';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    FailureResponse,
    SuccessResponse,
    isFailureResponse,
    isSuccessResponse,
} from '../../../models/common';
import { ProjectEnvironmentTypePermissionRecord } from '../../../models/permission';
import { KeyValuePair } from '../../../types/key-value-pair';
import { SerializableMap } from '../../../types/serializable-map';
import { getKey, getValue } from '../../../utilities/key-value-pair';
import { get } from '../../../utilities/serializable-map';
import {
    getProjectEnvironmentTypePermissions,
    getProjectEnvironmentTypePermissionsError,
    getProjectEnvironmentTypePermissionsFailed,
    getProjectEnvironmentTypePermissionsSuccess,
} from '../../actions/permission/permission-action-creators';
import { GetProjectEnvironmentTypePermissionsAction } from '../../actions/permission/permission-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getActionsInGroup } from '../../utilities/groupable-action';
import { getPermissions } from './get-permissions';

export function* getProjectEnvironmentTypePermissionsSaga(
    action: GetProjectEnvironmentTypePermissionsAction
): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};
    const actions = getActionsInGroup(action);
    const ids = actions.map((action) => action.payload.id);

    try {
        // Request data from permissions API
        const responses: KeyValuePair<string, GetPermissionsResponse>[] = yield call(
            getPermissions,
            ids,
            activityId,
            FailureOperation.GetProjectEnvironmentTypePermissions
        );

        const failedResponses = responses.filter((pair) => isFailureResponse(getValue(pair)));
        const succeededResponses = responses.filter((pair) => isSuccessResponse(getValue(pair)));
        let processedResponses: SerializableMap<ProjectEnvironmentTypePermissionRecord> = SerializableMap();

        // Issue failed action if any of the get permissions calls failed
        if (failedResponses.length > 0) {
            const payloads = responses.map((pair) => {
                const [id, failure] = pair as KeyValuePair<string, FailureResponse>;
                return { failure, id };
            });

            yield put(getProjectEnvironmentTypePermissionsFailed(payloads, meta));
        }

        // Process permissions for succeeded records and issue success action
        if (succeededResponses.length > 0) {
            const roles = succeededResponses.map((pair) => {
                const { data } = getValue(pair) as SuccessResponse<RoleContract[]>;
                return data;
            });

            const permissions: ProjectEnvironmentTypePermissionRecord[] = yield call(
                processProjectEnvironmentTypePermissions,
                roles
            );

            const records = permissions.map((record, index) => {
                const id = getKey(succeededResponses[index]);
                return KeyValuePair(id, record);
            });

            // Keep result map up-to-date for easy map-back in promise resolution
            processedResponses = SerializableMap(records);

            const payloads = records.map((record) => ({ id: getKey(record), result: getValue(record) }));
            yield put(getProjectEnvironmentTypePermissionsSuccess(payloads, meta));
        }

        // When resolving the action, send back the processed records rather than the raw roles
        const result = responses.map<DataResponse<ProjectEnvironmentTypePermissionRecord>>((pair) => {
            const [id, response] = pair;

            if (isFailureResponse(response)) {
                return response;
            }

            const data = get(processedResponses, id) as ProjectEnvironmentTypePermissionRecord;

            return { data, succeeded: true };
        });

        yield resolveAction(action, result);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetProjectEnvironmentTypePermissions);
        const payloads = ids.map((id) => ({ error, id }));
        yield put(getProjectEnvironmentTypePermissionsError(payloads, meta));
        yield rejectAction(action, error);
    }
}

export function* getProjectEnvironmentTypePermissionsListenerSaga(): SagaIterator {
    yield takeEvery(getProjectEnvironmentTypePermissions, getProjectEnvironmentTypePermissionsSaga);
}
