import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ListLocationsResponse, listManyLocations } from '../../../data/services/resource-manager/subscription';
import {
    ClientError,
    FailureOperation,
    FailureResponse,
    SuccessResponse,
    isFailureResponse,
    isSuccessResponse,
} from '../../../models/common';
import { LocationListResult } from '../../../models/resource-manager';
import { KeyValuePair } from '../../../types/key-value-pair';
import { getValue } from '../../../utilities/key-value-pair';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureResourceManagerPayload } from '../../actions/identity/identity-actions';
import {
    listLocations,
    listLocationsError,
    listLocationsFailed,
    listLocationsSuccess,
} from '../../actions/subscription/subscription-action-creators';
import { ListLocationsAction } from '../../actions/subscription/subscription-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';
import { getActionsInGroup } from '../../utilities/groupable-action';

export function* listLocationsSaga(action: ListLocationsAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};
    const actions = getActionsInGroup(action);
    const ids = actions.map((action) => action.payload.id);

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForAzureResourceManagerPayload(), meta)
        );

        const responses: KeyValuePair<string, ListLocationsResponse>[] = yield call(
            listManyLocations,
            ids,
            accessToken,
            activityId
        );

        const failures = responses.filter((pair) => isFailureResponse(getValue(pair)));
        const successes = responses.filter((pair) => isSuccessResponse(getValue(pair)));

        if (failures.length > 0) {
            const payloads = failures.map((pair) => {
                const [id, failure] = pair as KeyValuePair<string, FailureResponse>;
                return { failure, id };
            });

            yield put(listLocationsFailed(payloads, meta));
        }

        if (successes.length > 0) {
            const payloads = successes.map((pair) => {
                const [id, response] = pair as KeyValuePair<string, SuccessResponse<LocationListResult>>;
                const { data } = response;
                const { value: result } = data;
                return { id, result };
            });

            yield put(listLocationsSuccess(payloads, meta));
        }

        yield resolveAction(action, responses.map(getValue));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListLocations);
        const payloads = ids.map((id) => ({ error, id }));
        yield put(listLocationsError(payloads, meta));
        yield rejectAction(action, error);
    }
}

export function* listLocationsListenerSaga(): SagaIterator {
    yield takeEvery(listLocations, listLocationsSaga);
}
