import { FontSizes, FontWeights, IStackTokens, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { CannotCreateResourcesElement } from '../../components/blocking-states/cannot-create-resources';
import GuestAccountsNotSupported from '../../components/blocking-states/guest-accounts-not-supported';
import { NoProjects } from '../../components/blocking-states/no-projects';
import NoResourcesContainer from '../../components/no-resources';
import CenteredPortalLayout from '../../layouts/centered-portal-layout';
import { getHasDevBoxes } from '../../redux/selector/dev-box-selectors';
import {
    ActionAbility,
    DevBoxCreateAbilityState,
    EnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/models';
import {
    getDevBoxCreateAbilityState,
    getEnvironmentCreateAbilityState,
} from '../../redux/selector/display/create-ability/selectors';
import { DisplayState, getDisplayState } from '../../redux/selector/display/display-state';
import { ResourceUserState } from '../../redux/selector/display/user-state/models';
import { getDevBoxUserState, getEnvironmentUserState } from '../../redux/selector/display/user-state/selectors';
import { getHasEnvironments } from '../../redux/selector/environment-selectors';
import { getOrganizationDisplayName, getUserDisplayName, getUserGivenName } from '../../redux/selector/graph-selectors';
import { Resources } from './resources';
import { isFeatureFlagEnabled } from 'src/utilities/features';
import { FeatureFlagName } from 'src/constants/features';

interface HomeIndexPageBodyProps {
    hasDevBoxes: boolean;
    hasEnvironments: boolean;
    devBoxUserState: ResourceUserState;
    environmentUserState: ResourceUserState;
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    displayState: DisplayState;
}

interface HomeIndexPageComponentProps {
    givenName: string | undefined;
    displayName: string;
    hasDevBoxes: boolean;
    hasEnvironments: boolean;
    organizationDisplayName: string;
    devBoxUserState: ResourceUserState;
    environmentUserState: ResourceUserState;
    devBoxCreateAbilityState: DevBoxCreateAbilityState;
    environmentCreateAbilityState: EnvironmentCreateAbilityState;
    displayState: DisplayState;
}

/**
 * Style Section
 */

const useIndexPageStyles = makeStyles({
    root: {
        marginTop: 56,
    },
});

const useWelcomeMessageContainerStyles = makeStyles({
    h1: {
        fontSize: FontSizes.size28,
        fontWeight: FontWeights.semibold,
        lineHeight: 36,
        textAlign: 'left',
    },
});

/* END */

const HomeIndexPageBody: React.FC<HomeIndexPageBodyProps> = (props: HomeIndexPageBodyProps) => {
    const {
        hasDevBoxes,
        hasEnvironments,
        devBoxUserState,
        environmentUserState,
        devBoxCreateAbilityState,
        environmentCreateAbilityState,
        displayState,
    } = props;

    const isDevBoxUser = devBoxUserState === ResourceUserState.IsUser;
    const isEnvironmentUser = environmentUserState === ResourceUserState.IsUser;
    const canWriteDevBoxes = devBoxCreateAbilityState.createAbility === ActionAbility.CanPerformAction;
    const canWriteEnvironments = environmentCreateAbilityState.createAbility === ActionAbility.CanPerformAction;

    switch (displayState) {
        case DisplayState.Loading:
            return <></>;
        case DisplayState.HasResources:
            return <Resources hasDevBoxes={hasDevBoxes} hasEnvironments={hasEnvironments} />;
        case DisplayState.EmptyState:
            return (
                <NoResourcesContainer
                    isDevBoxUser={isDevBoxUser}
                    canWriteDevBoxes={canWriteDevBoxes}
                    isEnvironmentUser={isEnvironmentUser}
                    canWriteEnvironments={canWriteEnvironments}
                />
            );
        // For the guest user FF, we'll want to show empty
        case DisplayState.GuestAccount:
            if (isFeatureFlagEnabled(FeatureFlagName.EnableGuestUser)) {
                return (
                    <NoResourcesContainer
                        isDevBoxUser={isDevBoxUser}
                        canWriteDevBoxes={canWriteDevBoxes}
                        isEnvironmentUser={isEnvironmentUser}
                        canWriteEnvironments={canWriteEnvironments}
                    />
                );
            }
        case DisplayState.FullScreenMisconfiguredState:
        default:
            return (
                <CannotCreateResourcesElement
                    isEnvironmentUser={isEnvironmentUser}
                    isDevBoxUser={isDevBoxUser}
                    cannotCreateEnvironmentReason={environmentCreateAbilityState.reason}
                    cannotCreateDevBoxReason={devBoxCreateAbilityState.reason}
                />
            );
    }
};

export const HomeIndexPageComponent: React.FC<HomeIndexPageComponentProps> = (props: HomeIndexPageComponentProps) => {
    const {
        givenName,
        displayName,
        hasDevBoxes,
        hasEnvironments,
        organizationDisplayName,
        devBoxUserState,
        environmentUserState,
        devBoxCreateAbilityState,
        environmentCreateAbilityState,
        displayState,
    } = props;

    // Style hooks
    const indexPageStyles = useIndexPageStyles();
    const welcomeMessageContainerStyles = useWelcomeMessageContainerStyles();

    // Memoized data
    const addProgressBarOffset =
        devBoxCreateAbilityState.createAbility === ActionAbility.Unknown ||
        environmentCreateAbilityState.createAbility === ActionAbility.Unknown;

    const headerTokens = React.useMemo<IStackTokens>(() => ({ childrenGap: 6 }), []);

    // HACK: adding this offset so that, when action bar appears on load complete, the content within this component
    // isn't shifted downward.
    const contentPaddingTopOffset = addProgressBarOffset ? 47 : 0;

    if (displayState === DisplayState.HasNoProjects) {
        return (
            <CenteredPortalLayout contentPaddingBottom={40} contentPaddingTop={40} id="index" verticalFill>
                <NoProjects />
            </CenteredPortalLayout>
        );
    }

    if (displayState === DisplayState.GuestAccount && !isFeatureFlagEnabled(FeatureFlagName.EnableGuestUser)) {
        return (
            <CenteredPortalLayout contentPaddingBottom={40} contentPaddingTop={40} id="index" verticalFill>
                <GuestAccountsNotSupported organizationDisplayName={organizationDisplayName} />
            </CenteredPortalLayout>
        );
    }

    return (
        <CenteredPortalLayout contentPaddingBottom={20} contentPaddingTop={40 + contentPaddingTopOffset} id="index">
            <Stack horizontalAlign="start" tokens={headerTokens}>
                <Stack.Item>
                    <h1 className={welcomeMessageContainerStyles.h1}>
                        <FormattedMessage
                            id="IndexPage_WelcomeMessage_Text"
                            defaultMessage="Welcome, {givenName}"
                            description="Welcome message for signed-in user. {givenName} should not be localized."
                            values={{ givenName: givenName ?? displayName }}
                        />
                    </h1>
                </Stack.Item>
            </Stack>

            <Stack styles={indexPageStyles}>
                <Stack.Item>
                    <HomeIndexPageBody
                        hasDevBoxes={hasDevBoxes}
                        hasEnvironments={hasEnvironments}
                        devBoxUserState={devBoxUserState}
                        environmentUserState={environmentUserState}
                        devBoxCreateAbilityState={devBoxCreateAbilityState}
                        environmentCreateAbilityState={environmentCreateAbilityState}
                        displayState={displayState}
                    />
                </Stack.Item>
            </Stack>
        </CenteredPortalLayout>
    );
};

export const HomeIndexPageContainer: React.FC = () => {
    // Application state hooks
    const givenName = useSelector(getUserGivenName);
    const displayName = useSelector(getUserDisplayName);
    const hasDevBoxes = useSelector(getHasDevBoxes);
    const organizationDisplayName = useSelector(getOrganizationDisplayName);
    const hasEnvironments = useSelector(getHasEnvironments);
    const devBoxUserState = useSelector(getDevBoxUserState);
    const environmentUserState = useSelector(getEnvironmentUserState);
    const devBoxCreateAbilityState = useSelector(getDevBoxCreateAbilityState);
    const environmentCreateAbilityState = useSelector(getEnvironmentCreateAbilityState);
    const displayState = useSelector(getDisplayState);

    return (
        <HomeIndexPageComponent
            givenName={givenName}
            displayName={displayName}
            hasDevBoxes={hasDevBoxes}
            hasEnvironments={hasEnvironments}
            organizationDisplayName={organizationDisplayName}
            devBoxUserState={devBoxUserState}
            environmentUserState={environmentUserState}
            devBoxCreateAbilityState={devBoxCreateAbilityState}
            environmentCreateAbilityState={environmentCreateAbilityState}
            displayState={displayState}
        />
    );
};

export default HomeIndexPageContainer;
