import { SagaIterator } from 'redux-saga';
import { CallEffect, call } from 'redux-saga/effects';
import { ClientError } from '../../models/common';
import { AsyncAction } from '../actions/core-actions';
import { putAndAwait } from './put-and-await';

/**
 * Types
 */

export type PutAndSettleEffect<TAwaited> = CallEffect<TAwaited | ClientError>;
export type PutAndSettleSignature<TAwaited> = (action: AsyncAction<TAwaited>) => SagaIterator<TAwaited | ClientError>;

/**
 * Effects
 */

export function* putAndSettleSaga<TAwaited>(action: AsyncAction<TAwaited>): SagaIterator<TAwaited | ClientError> {
    try {
        return yield putAndAwait(action);
    } catch (err) {
        return new ClientError(err);
    }
}

export const putAndSettle = <TAwaited>(action: AsyncAction<TAwaited>): PutAndSettleEffect<TAwaited> =>
    call<PutAndSettleSignature<TAwaited>>(putAndSettleSaga, action);
