import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { DevBoxOperation } from '../../models/dev-box';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { devBoxOperationAdapter } from '../adapters/dev-box-operation-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'loadDevBoxOperationsForDevBoxes';
type IndexedStatusName = 'listDevBoxOperations';

export interface DevBoxOperationDataStore {
    operations: EntityState<Entity<DevBoxOperation>>;
}

export const DevBoxOperationDataStore = createInitializer<DevBoxOperationDataStore>({
    operations: devBoxOperationAdapter.getInitialState(),
});

export type DevBoxOperationStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const DevBoxOperationStatusStore = createInitializer<DevBoxOperationStatusStore>({
    listDevBoxOperations: statusAdapter.getInitialState(),
    loadDevBoxOperationsForDevBoxes: Status(),
});

export interface DevBoxOperationsStore {
    data: DevBoxOperationDataStore;
    status: DevBoxOperationStatusStore;
}

export const DevBoxOperationsStore = createInitializer<DevBoxOperationsStore>({
    data: DevBoxOperationDataStore(),
    status: DevBoxOperationStatusStore(),
});
