import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { GetAzureDevOpsRepoItemResponse, getAzureDevOpsRepoItem } from '../../../data/services/azure-dev-ops/vso-code';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { parseAzureDevOpsRepoItemId } from '../../../utilities/azure-dev-ops';
import {
    getAzureDevOpsRepoItem as getAzureDevOpsRepoItemActionCreator,
    getAzureDevOpsRepoItemError,
    getAzureDevOpsRepoItemFailed,
    getAzureDevOpsRepoItemSuccess,
} from '../../actions/azure-dev-ops/azure-dev-ops-action-creators';
import { GetAzureDevOpsRepoItemAction } from '../../actions/azure-dev-ops/azure-dev-ops-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureDevOpsPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeLatest } from '../../effects/take';

export function* getAzureDevOpsRepoItemSaga(action: GetAzureDevOpsRepoItemAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { repoItemPathIdentifier } = payload;
    const { repoUrl, branchName, path } = parseAzureDevOpsRepoItemId(repoItemPathIdentifier);

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForAzureDevOpsPayload(), meta));

        const response: GetAzureDevOpsRepoItemResponse = yield call(
            getAzureDevOpsRepoItem,
            repoUrl,
            path,
            branchName,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(getAzureDevOpsRepoItemFailed({ repoItemPathIdentifier, failure: response }, meta));
            return;
        }

        yield put(getAzureDevOpsRepoItemSuccess({ repoItemPathIdentifier, result: response.data }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetAzureDevOpsRepoForCloneUri);
        yield put(getAzureDevOpsRepoItemError({ repoItemPathIdentifier, error }, meta));
    }
}

export function* getAzureDevOpsRepoItemListenerSaga(): SagaIterator {
    yield takeLatest(getAzureDevOpsRepoItemActionCreator, getAzureDevOpsRepoItemSaga);
}
