import { UnionMap } from '../../../../types/union-map';

type CommonCreateAbilityState = 'Unknown' | 'FailedToLoad';

const CommonCreateAbilityState: UnionMap<CommonCreateAbilityState> = {
    Unknown: 'Unknown',
    FailedToLoad: 'FailedToLoad',
};

export type ActionAbility = 'CanPerformAction' | 'CannotPerformAction' | CommonCreateAbilityState;

export const ActionAbility: UnionMap<ActionAbility> = {
    CanPerformAction: 'CanPerformAction',
    CannotPerformAction: 'CannotPerformAction',
    ...CommonCreateAbilityState,
};

// Dev box

type ResourcesToWriteDevBoxesMisconfiguredState = 'HasNoAuthorizedProjects' | 'NoAuthorizedProjectsHavePools';

const ResourcesToWriteDevBoxesMisconfiguredState: UnionMap<ResourcesToWriteDevBoxesMisconfiguredState> = {
    HasNoAuthorizedProjects: 'HasNoAuthorizedProjects',
    NoAuthorizedProjectsHavePools: 'NoAuthorizedProjectsHavePools',
};

export type ResourcesToWriteDevBoxesState =
    | 'HasAtLeastOneAuthorizedProjectWithPools'
    | CommonCreateAbilityState
    | ResourcesToWriteDevBoxesMisconfiguredState;

export const ResourcesToWriteDevBoxesState: UnionMap<ResourcesToWriteDevBoxesState> = {
    HasAtLeastOneAuthorizedProjectWithPools: 'HasAtLeastOneAuthorizedProjectWithPools',
    ...CommonCreateAbilityState,
    ...ResourcesToWriteDevBoxesMisconfiguredState,
};

export type CannotCreateDevBoxReason = 'GuestAccount' | 'IsNotDevBoxUser' | ResourcesToWriteDevBoxesMisconfiguredState;

export const CannotCreateDevBoxReason: UnionMap<CannotCreateDevBoxReason> = {
    GuestAccount: 'GuestAccount',
    IsNotDevBoxUser: 'IsNotDevBoxUser',
    ...ResourcesToWriteDevBoxesMisconfiguredState,
};

export interface DevBoxCreateAbilityState {
    createAbility: ActionAbility;
    reason?: CannotCreateDevBoxReason;
}

// This typeguard exists so that we can pipe through a ResourcesToWriteDevBoxesMisconfiguredState from the ResourcesToWriteDevBoxesState selector
// as a CannotCreateDevBoxReason.
export const isResourcesToWriteDevBoxesMisconfiguredState = (
    state: ResourcesToWriteDevBoxesState
): state is ResourcesToWriteDevBoxesMisconfiguredState => {
    const hasNoResourcesState = state as ResourcesToWriteDevBoxesMisconfiguredState;

    return (
        !!hasNoResourcesState &&
        (hasNoResourcesState === ResourcesToWriteDevBoxesMisconfiguredState.HasNoAuthorizedProjects ||
            hasNoResourcesState === ResourcesToWriteDevBoxesMisconfiguredState.NoAuthorizedProjectsHavePools)
    );
};

// Environment

type ResourcesToWriteEnvironmentsMisconfiguredState = 'HasNoAuthorizedProjects' | 'NoAuthorizedProjectsHaveResources';

const ResourcesToWriteEnvironmentsMisconfiguredState: UnionMap<ResourcesToWriteEnvironmentsMisconfiguredState> = {
    HasNoAuthorizedProjects: 'HasNoAuthorizedProjects',
    NoAuthorizedProjectsHaveResources: 'NoAuthorizedProjectsHaveResources',
};

export type ResourcesToWriteEnvironmentsState =
    | 'HasAtLeastOneAuthorizedProjectWithResources'
    | CommonCreateAbilityState
    | ResourcesToWriteEnvironmentsMisconfiguredState;

export const ResourcesToWriteEnvironmentsState: UnionMap<ResourcesToWriteEnvironmentsState> = {
    HasAtLeastOneAuthorizedProjectWithResources: 'HasAtLeastOneAuthorizedProjectWithResources',
    ...CommonCreateAbilityState,
    ...ResourcesToWriteEnvironmentsMisconfiguredState,
};

export type CannotCreateEnvironmentReason = 'IsNotEnvironmentUser' | ResourcesToWriteEnvironmentsMisconfiguredState;

export const CannotCreateEnvironmentReason: UnionMap<CannotCreateEnvironmentReason> = {
    IsNotEnvironmentUser: 'IsNotEnvironmentUser',
    ...ResourcesToWriteEnvironmentsMisconfiguredState,
};

export interface EnvironmentCreateAbilityState {
    createAbility: ActionAbility;
    reason?: CannotCreateEnvironmentReason;
}

// This typeguard exists so that we can pipe through a ResourcesToWriteEnvironmentsMisconfiguredState from the ResourcesToWriteEnvironmentsState selector
// as a CannotCreateEnvironmentReason.
export const isResourcesToWriteEnvironmentsMisconfiguredState = (
    state: ResourcesToWriteEnvironmentsState
): state is ResourcesToWriteEnvironmentsMisconfiguredState => {
    const misconfiguredState = state as ResourcesToWriteEnvironmentsMisconfiguredState;

    return (
        !!misconfiguredState &&
        (misconfiguredState === ResourcesToWriteEnvironmentsMisconfiguredState.NoAuthorizedProjectsHaveResources ||
            misconfiguredState === ResourcesToWriteEnvironmentsMisconfiguredState.HasNoAuthorizedProjects)
    );
};
