import { StandardLuminance, baseLayerLuminance, fluentTreeItem } from '@fluentui/web-components';
import { ReactWrapperProps } from '@microsoft/fast-react-wrapper';
import * as React from 'react';
import wrapFluentWebComponent from '../../react-wrapper';
import { TreeItemAttributes, TreeItemEvent } from './models';

const TreeItemReactWrapper = wrapFluentWebComponent(fluentTreeItem(), {
    events: {
        handleExpandedChange: 'expanded-change',
    },
    properties: ['selected', 'expanded'],
});

interface TreeItemEventProps {
    handleExpandedChange?: (event: TreeItemEvent) => void;
}

// We need to require these attribute props to ensure the attribute properties will be present on `TreeItemEventTarget`
type TreeItemAttributeProps = { [key in keyof TreeItemAttributes]: string };

export type TreeItemRefTarget = HTMLElement & React.Component<ReactWrapperProps<HTMLElement, TreeItemEventProps>>;

export interface TreeItemProps extends TreeItemEventProps, Partial<TreeItemAttributeProps> {
    selected?: boolean;
    expanded?: boolean;
    disabled?: boolean;
    innerRef?: React.RefObject<TreeItemRefTarget>;
}

const disabledStyle: React.CSSProperties = 'opacity: 1; cursor: default;' as React.CSSProperties;

const TreeItem: React.FC<TreeItemProps> = (props: TreeItemProps) => {
    const { disabled, innerRef: providedRef, handleExpandedChange } = props;

    const managedRef = React.useRef<TreeItemRefTarget>(null);
    const ref = providedRef ?? managedRef;

    React.useEffect(() => {
        if (ref.current) {
            baseLayerLuminance.setValueFor(ref.current, StandardLuminance.DarkMode);
        }
    }, [baseLayerLuminance, ref.current]);

    const style = React.useMemo(() => {
        if (disabled) {
            return disabledStyle;
        }

        return undefined;
    }, [disabled]);

    // Wrapper for type matching purposes
    const handleExpandedChangeWrapper = React.useCallback(
        (e: Event) => handleExpandedChange?.(e as TreeItemEvent),
        [handleExpandedChange]
    );

    return (
        <TreeItemReactWrapper {...props} ref={ref} style={style} handleExpandedChange={handleExpandedChangeWrapper} />
    );
};

export default TreeItem;
