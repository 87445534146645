import { Status } from '../../models/common';
import { azureLocationAdapter } from '../adapters/subscription-adapters';
import { StoreState } from '../store/store-state';
import { StoreStateSelector, createMapFromEntitiesSelector } from './common';

/**
 * Basic selectors
 */

export const getStatusForDiscoverLocations: StoreStateSelector<Status> = (store) =>
    store.subscriptionStore.status.discoverLocations;

/**
 * Entity state selectors
 */

const locationsSelectors = azureLocationAdapter.getSelectors<StoreState>(
    (store) => store.subscriptionStore.data.locations
);

/**
 * Composed selectors
 */

export const getLocations = createMapFromEntitiesSelector(locationsSelectors.selectAll);
