import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { EnvironmentDefinition } from '../../models/environment-definition';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { environmentDefinitionAdapter } from '../adapters/environment-definition-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'discoverEnvironmentDefinitionsForEnvironments' | 'discoverEnvironmentDefinitionsForProjects';
type IndexedStatusName = 'listEnvironmentDefinitions';

export interface EnvironmentDefinitionDataStore {
    environmentDefinitions: EntityState<Entity<EnvironmentDefinition>>;
}

export const EnvironmentDefinitionDataStore = createInitializer<EnvironmentDefinitionDataStore>({
    environmentDefinitions: environmentDefinitionAdapter.getInitialState(),
});

export type EnvironmentDefinitionStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const EnvironmentDefinitionStatusStore = createInitializer<EnvironmentDefinitionStatusStore>({
    discoverEnvironmentDefinitionsForEnvironments: Status(),
    discoverEnvironmentDefinitionsForProjects: Status(),
    listEnvironmentDefinitions: statusAdapter.getInitialState(),
});

export interface EnvironmentDefinitionStore {
    data: EnvironmentDefinitionDataStore;
    status: EnvironmentDefinitionStatusStore;
}

export const EnvironmentDefinitionStore = createInitializer<EnvironmentDefinitionStore>({
    data: EnvironmentDefinitionDataStore(),
    status: EnvironmentDefinitionStatusStore(),
});
