import { IButtonStyles, ICommandBarItemProps } from '@fluentui/react';
import { createSelector } from '@reduxjs/toolkit';
import * as React from 'react';
import { DismissableContentName, Environment } from '../../constants/app';
import { OperatingSystemFamily } from '../../constants/browser';
import { FeatureFlagName } from '../../constants/features';
import { Status } from '../../models/common';
import { getDismissedMessages } from '../../redux/selector/application-selectors';
import { isStatusInProgress, StoreStateSelector } from '../../redux/selector/common';
import { currentEnvironment } from '../../utilities/app';
import { getOperatingSystemFamily } from '../../utilities/browser';
import { isFeatureFlagEnabled } from '../../utilities/features';
import { has } from '../../utilities/serializable-set';
import MeControl from './me-control';
import TenantPicker from './tenant-picker/tenant-picker';

export const getFarItems = (
    commonCommandBarButtonStyles: IButtonStyles | undefined,
    devMenuAriaLabel: string,
    hasMultipleTenants: boolean,
    helpMenuAriaLabel: string,
    isDevMenuChecked: boolean,
    isHelpMenuChecked: boolean,
    isSettingsChecked: boolean,
    isSignedIn: boolean,
    meControlAriaLabel: string,
    onDevMenuButtonClicked: () => void,
    onHelpMenuButtonClicked: () => void,
    onSettingsButtonClicked: () => void,
    statusForGetSignedInUser: Status,
    statusForGetOrganization: Status,
    tenantPickerAriaLabel: string,
    statusForListTenants: Status,
    settingsMenuAriaLabel: string,
    settingsMenuId: string
): ICommandBarItemProps[] => {
    const devMenuItem: ICommandBarItemProps = {
        ariaLabel: devMenuAriaLabel,
        buttonStyles: commonCommandBarButtonStyles,
        checked: isDevMenuChecked,
        iconProps: { iconName: 'Flag' },
        key: 'dev-menu',
        onClick: onDevMenuButtonClicked,
    };

    const helpMenuItem: ICommandBarItemProps = {
        ariaLabel: helpMenuAriaLabel,
        buttonStyles: commonCommandBarButtonStyles,
        checked: isHelpMenuChecked,
        iconProps: { iconName: 'Help' },
        key: 'help-control',
        onClick: onHelpMenuButtonClicked,
    };

    /**
     * Settings Menu button needs the id property.
     * This is used to identify the button in the DOM for attachment of the teach bubble.
     */
    const settingsItem: ICommandBarItemProps = {
        id: settingsMenuId,
        ariaLabel: settingsMenuAriaLabel,
        buttonStyles: commonCommandBarButtonStyles,
        checked: isSettingsChecked,
        iconProps: { iconName: 'Settings' },
        key: 'settings-menu',
        onClick: onSettingsButtonClicked,
    };

    const meControlItem: ICommandBarItemProps = {
        key: 'me-control',
        onRender: (): React.ReactNode => <MeControl ariaLabel={meControlAriaLabel} />,
    };

    const tenantPickerItem: ICommandBarItemProps = {
        key: 'tenant-picker',
        onRender: (): React.ReactNode => <TenantPicker ariaLabel={tenantPickerAriaLabel} />,
    };

    // Avoid showing content until requests backing the data are resolved.
    // (This avoids unpleasant user experiences where the content re-renders multiple times)
    if (
        !isSignedIn ||
        isStatusInProgress(statusForGetSignedInUser) ||
        isStatusInProgress(statusForGetOrganization) ||
        isStatusInProgress(statusForListTenants)
    ) {
        // Don't show dev menu in production
        return currentEnvironment === Environment.Public ? [] : [devMenuItem];
    }

    const isWindows = getOperatingSystemFamily() === OperatingSystemFamily.Windows;

    return [
        ...(isFeatureFlagEnabled(FeatureFlagName.OpenUserSettingsMenu) && isWindows ? [settingsItem] : []),
        // Don't show dev menu in production
        ...(currentEnvironment === Environment.Public ? [] : [devMenuItem]),
        helpMenuItem,
        ...(hasMultipleTenants ? [tenantPickerItem] : []),
        meControlItem,
    ];
};

export const getIsSettingsMenuTeachBubbleDismissed: StoreStateSelector<boolean> = createSelector(
    [getDismissedMessages],
    (dismissedMessages) => has(dismissedMessages, DismissableContentName.SettingsMenuTeachableBubble)
);
