import { UnionMap } from '../types/union-map';
import { isString } from '../utilities/string';

export type ResourceFilterType = 'DeploymentEnvironments' | 'DevBoxes';

export const ResourceFilterType: UnionMap<ResourceFilterType> = {
    DeploymentEnvironments: 'DeploymentEnvironments',
    DevBoxes: 'DevBoxes',
};

export const isResourceFilter = (value: unknown): value is ResourceFilterType => {
    if (!isString(value)) {
        return false;
    }

    switch (value) {
        case ResourceFilterType.DevBoxes:
        case ResourceFilterType.DeploymentEnvironments:
            return true;
        default:
            return false;
    }
};
