import { ClientError } from '../../models/common';
import { tryOrDefault } from '../try-or-default';
import { createResourceId } from './create-resource-id';
import { parseResourceId } from './parse-resource-id';

// This function should be used when receiving a resource ID from an Azure API response to ensure normalization.
// This utility may seem redundant, but it serves a couple of key functions by going through this process:
//      - Ensures that the given resource ID is, in fact, a valid resource ID (via parsing)
//      - Normalizes the casing, so that the same resource ID isn't misidentified as different due to casings
export const normalizeResourceId = (resourceId: string): string => {
    const { resource, resourceGroupName, subResource, subscriptionId } = parseResourceId(resourceId);

    if (resource === undefined) {
        throw new ClientError('Could not parse any resource specification from given resource ID.');
    }

    const { name, providerNamespace, type } = resource;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: createResourceId will check whether the asserted properties are undefined or white space.
    if (subResource !== undefined) {
        return createResourceId(
            subscriptionId!,
            resourceGroupName!,
            { name: name!, providerNamespace, type: type! },
            {
                name: subResource.name!,
                providerNamespace: subResource.providerNamespace,
                type: subResource.type!,
            }
        );
    }

    return createResourceId(subscriptionId!, resourceGroupName!, { name: name!, providerNamespace, type: type! });
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

export const tryNormalizeResourceId = tryOrDefault(normalizeResourceId);
