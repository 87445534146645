import { GetRemoteConnectionResponse } from '../../../data/services/data-plane-api/dev-box';
import { AggregatedResult } from '../../../models/common';
import { RemoteConnection } from '../../../models/dev-box';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, ResultPayload } from '../core-actions';
import { RemoteConnectionActionType } from './remote-connection-actions';

/**
 * Action creators
 */

export const getRemoteConnection = createActionCreator<IndexedPayload, GetRemoteConnectionResponse>(
    RemoteConnectionActionType.GetRemoteConnection,
    { async: true, startsActivity: true }
);

export const getRemoteConnectionError = createActionCreator<ErrorPayload & IndexedPayload>(
    RemoteConnectionActionType.GetRemoteConnectionError
);

export const getRemoteConnectionFailed = createActionCreator<FailedPayload & IndexedPayload>(
    RemoteConnectionActionType.GetRemoteConnectionFailed
);

export const getRemoteConnectionSuccess = createActionCreator<IndexedPayload & ResultPayload<RemoteConnection>>(
    RemoteConnectionActionType.GetRemoteConnectionSuccess
);

export const loadRemoteConnectionsForDevBoxes = createActionCreatorWithoutPayload<AggregatedResult>(
    RemoteConnectionActionType.LoadRemoteConnectionsForDevBoxes,
    { async: true }
);

export const loadRemoteConnectionsForDevBoxesError = createActionCreator<ErrorPayload>(
    RemoteConnectionActionType.LoadRemoteConnectionsForDevBoxesError
);

export const loadRemoteConnectionsForDevBoxesFailed = createActionCreator<FailedPayload>(
    RemoteConnectionActionType.LoadRemoteConnectionsForDevBoxesFailed
);

export const loadRemoteConnectionsForDevBoxesSuccess = createActionCreatorWithoutPayload(
    RemoteConnectionActionType.LoadRemoteConnectionsForDevBoxesSuccess
);
