import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { RemoteConnection } from '../../models/dev-box';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { remoteConnectionAdapter } from '../adapters/remote-connection-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'loadRemoteConnectionsForDevBoxes';
type IndexedStatusName = 'getRemoteConnection';

export interface RemoteConnectionDataStore {
    remoteConnections: EntityState<Entity<RemoteConnection>>;
}

export const RemoteConnectionDataStore = createInitializer<RemoteConnectionDataStore>({
    remoteConnections: remoteConnectionAdapter.getInitialState(),
});

export type RemoteConnectionStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const RemoteConnectionStatusStore = createInitializer<RemoteConnectionStatusStore>({
    getRemoteConnection: statusAdapter.getInitialState(),
    loadRemoteConnectionsForDevBoxes: Status(),
});

export interface RemoteConnectionStore {
    data: RemoteConnectionDataStore;
    status: RemoteConnectionStatusStore;
}

export const RemoteConnectionStore = createInitializer<RemoteConnectionStore>({
    data: RemoteConnectionDataStore(),
    status: RemoteConnectionStatusStore(),
});
