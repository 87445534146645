import { EntityState } from '@reduxjs/toolkit';
import { EnvironmentProvisioningState } from '../../constants/environment';
import { Entity, Status } from '../../models/common';
import { Environment, FailureOnEnvironment } from '../../models/environment';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import {
    environmentAdapter,
    environmentProvisioningStateAdapter,
    failureOnEnvironmentAdapter,
} from '../adapters/environment-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'addEnvironment' | 'deployEnvironment' | 'discoverEnvironmentsInTenant' | 'updateEnvironment';
type IndexedStatusName = 'listEnvironments';

export interface EnvironmentDataStore {
    environments: EntityState<Entity<Environment>>;
    failuresFromOperations: EntityState<Entity<FailureOnEnvironment>>;
    failuresFromResources: EntityState<Entity<FailureOnEnvironment>>;
    pendingStates: EntityState<Entity<EnvironmentProvisioningState>>;
}

export const EnvironmentDataStore = createInitializer<EnvironmentDataStore>({
    environments: environmentAdapter.getInitialState(),
    failuresFromOperations: failureOnEnvironmentAdapter.getInitialState(),
    failuresFromResources: failureOnEnvironmentAdapter.getInitialState(),
    pendingStates: environmentProvisioningStateAdapter.getInitialState(),
});

export type EnvironmentStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const EnvironmentStatusStore = createInitializer<EnvironmentStatusStore>({
    addEnvironment: Status(),
    deployEnvironment: Status(),
    discoverEnvironmentsInTenant: Status(),
    listEnvironments: statusAdapter.getInitialState(),
    updateEnvironment: Status(),
});

export interface EnvironmentStore {
    data: EnvironmentDataStore;
    status: EnvironmentStatusStore;
}

export const EnvironmentStore = createInitializer<EnvironmentStore>({
    data: EnvironmentDataStore(),
    status: EnvironmentStatusStore(),
});
