import { CapitalizedUnionMap, UnionMap, UnionValueMap } from '../types/union-map';

export const DataPlaneBaseHostname = 'devcenter.azure.com';

export type DataPlaneErrorCode = 'ValidationError';

export const DataPlaneErrorCode: UnionMap<DataPlaneErrorCode> = {
    ValidationError: 'ValidationError',
};

export type DataPlaneIdToken =
    | 'catalogName'
    | 'customizationGroupName'
    | 'customizationTaskName'
    | 'devBoxActionName'
    | 'devBoxName'
    | 'devBoxOperationId'
    | 'devCenter'
    | 'environmentActionName'
    | 'environmentDefinitionName'
    | 'environmentName'
    | 'environmentOperationId'
    | 'environmentTypeName'
    | 'poolName'
    | 'projectName'
    | 'scheduleName'
    | 'user'
    | 'customizationTaskId';

export const DataPlaneIdToken: CapitalizedUnionMap<DataPlaneIdToken> = {
    CatalogName: 'catalogName',
    CustomizationGroupName: 'customizationGroupName',
    CustomizationTaskName: 'customizationTaskName',
    DevBoxActionName: 'devBoxActionName',
    DevBoxName: 'devBoxName',
    DevBoxOperationId: 'devBoxOperationId',
    DevCenter: 'devCenter',
    EnvironmentActionName: 'environmentActionName',
    EnvironmentDefinitionName: 'environmentDefinitionName',
    EnvironmentName: 'environmentName',
    EnvironmentOperationId: 'environmentOperationId',
    EnvironmentTypeName: 'environmentTypeName',
    PoolName: 'poolName',
    ProjectName: 'projectName',
    ScheduleName: 'scheduleName',
    User: 'user',
    CustomizationTaskId: 'customizationTaskId',
};

export type DataPlaneResourceType =
    | 'Catalog'
    | 'CustomizationGroup'
    | 'CustomizationTask'
    | 'DevBox'
    | 'DevBoxAction'
    | 'DevBoxOperation'
    | 'EnvironmentAction'
    | 'Environment'
    | 'EnvironmentDefinition'
    | 'EnvironmentOperation'
    | 'EnvironmentType'
    | 'Pool'
    | 'Project'
    | 'Schedule'
    | 'User'
    | 'CustomizationTaskLog';

export const DataPlaneResourceType: UnionMap<DataPlaneResourceType> = {
    Catalog: 'Catalog',
    CustomizationGroup: 'CustomizationGroup',
    CustomizationTask: 'CustomizationTask',
    DevBox: 'DevBox',
    DevBoxAction: 'DevBoxAction',
    DevBoxOperation: 'DevBoxOperation',
    EnvironmentAction: 'EnvironmentAction',
    Environment: 'Environment',
    EnvironmentDefinition: 'EnvironmentDefinition',
    EnvironmentOperation: 'EnvironmentOperation',
    EnvironmentType: 'EnvironmentType',
    Pool: 'Pool',
    Project: 'Project',
    Schedule: 'Schedule',
    User: 'User',
    CustomizationTaskLog: 'CustomizationTaskLog',
};

export const LowerCaseDataPlaneResourceType: UnionValueMap<DataPlaneResourceType, Lowercase<DataPlaneResourceType>> = {
    Catalog: 'catalog',
    CustomizationGroup: 'customizationgroup',
    CustomizationTask: 'customizationtask',
    DevBox: 'devbox',
    DevBoxAction: 'devboxaction',
    DevBoxOperation: 'devboxoperation',
    EnvironmentAction: 'environmentaction',
    Environment: 'environment',
    EnvironmentDefinition: 'environmentdefinition',
    EnvironmentOperation: 'environmentoperation',
    EnvironmentType: 'environmenttype',
    Pool: 'pool',
    Project: 'project',
    Schedule: 'schedule',
    User: 'user',
    CustomizationTaskLog: 'customizationtasklog',
};

export const DataPlaneResourcePathPrefix: UnionValueMap<Exclude<DataPlaneResourceType, 'DevCenter'>, string> = {
    Catalog: 'catalogs',
    CustomizationGroup: 'customizationgroups',
    CustomizationTask: 'customizationtasks',
    DevBox: 'devboxes',
    DevBoxAction: 'actions',
    DevBoxOperation: 'operations',
    EnvironmentAction: 'environmentactions',
    Environment: 'environments',
    EnvironmentDefinition: 'environmentdefinitions',
    EnvironmentOperation: 'operations',
    EnvironmentType: 'environmenttypes',
    Pool: 'pools',
    Project: 'projects',
    Schedule: 'schedules',
    User: 'users',
    CustomizationTaskLog: 'logs',
};
