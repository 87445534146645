import { FontSizes, FontWeights, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { commonMessages } from '../language/messages';
import { CenteredPortalLayout } from '../layouts/centered-portal-layout';
import { isDevPortalEnvironment } from '../utilities/app';

interface LoadingPageProps {
    message?: {
        defaultMessage: string;
        description: string;
        id: string;
    };
}

/**
 * Style Section
 */

const useLoadingMessageContainerStyles = makeStyles({
    // Note: using paddingTop to space StackItems as Stack will override margins
    root: {
        lineHeight: '20px',
        paddingTop: 6,
    },
});

const useWelcomeMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        lineHeight: '22px',
        paddingTop: 15,
    },
});

/* END */

const welcomeMessage = isDevPortalEnvironment
    ? commonMessages.welcomeMessageDevPortalText
    : commonMessages.welcomeMessageText;

export const LoadingPage: React.FC<LoadingPageProps> = (props: LoadingPageProps) => {
    const { message } = props;

    // Style hooks
    const loadingMessageContainerStyles = useLoadingMessageContainerStyles();
    const welcomeMessageContainerStyles = useWelcomeMessageContainerStyles();

    return (
        <CenteredPortalLayout contentPaddingBottom={40} contentPaddingTop={40} id="loading" verticalFill>
            <Stack grow horizontalAlign="center" verticalAlign="center" verticalFill>
                <Stack.Item styles={welcomeMessageContainerStyles}>
                    <FormattedMessage {...welcomeMessage} />
                </Stack.Item>

                <Stack.Item styles={loadingMessageContainerStyles}>
                    {message ? (
                        <FormattedMessage {...message} />
                    ) : (
                        <FormattedMessage
                            id="LoadingPage_LoadingMessage_Text"
                            defaultMessage="Loading..."
                            description="Message indicating that the app is loading"
                        />
                    )}
                </Stack.Item>
            </Stack>
        </CenteredPortalLayout>
    );
};

export default LoadingPage;
