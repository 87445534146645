import { FontSizes, IStackTokens, Icon, Link, Stack, makeStyles } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { SupportSectionLinks } from '../../constants/support-section-links';
import { HibernateSupport } from '../../models/pool';
import Label from '../common/form/label';

export interface HibernateReadonlyFieldProps {
    hibernateSupport: HibernateSupport;
    disabled?: boolean;
}

const hibernatePreviewMessageValues = {
    Link: (chunks: string) => (
        <Link href={SupportSectionLinks.HibernationPublicPreview} target="_blank">
            {chunks}
        </Link>
    ),
};

const hibernateMessageContainerToken: IStackTokens = {
    childrenGap: 8,
};

const useMessageIconStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        lineHeight: 16,
    },
});

const useMessageStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        lineHeight: 19,
    },
});

const messages = defineMessages({
    hibernateFieldLabel: {
        id: 'HibernateReadonlyField_Label',
        defaultMessage: 'Hibernate',
        description: 'Field label for a readonly field indicating whether the selected pool has hibernate capability.',
    },
    hibernateFieldSupported: {
        id: 'HibernateReadonlyField_Supported',
        defaultMessage: 'supported',
        description: 'Readonly field value indicating that the selected pool supports hibernation.',
    },
    hibernateFieldNotSupported: {
        id: 'HibernateReadonlyField_NotSupported',
        defaultMessage: 'not supported',
        description: 'Readonly field value indicating that the selected pool does not support hibernation.',
    },
    hibernateFieldOsUnsupported: {
        id: 'HibernateReadonlyField_OsUnsupported',
        defaultMessage: 'OS not supported',
        description:
            'Readonly field value indicating that OS specific configurations have caused hibernate support to be disabled.',
    },
    hibernatePreviewTag: {
        id: 'HibernateReadonlyField_PreviewTag',
        defaultMessage: 'preview',
        description:
            'A tag displayed to the right of the "Hibernate" field of the create dev box form indicating that hibernate is a preview feature.',
    },
    hibernateFieldAriaLabel: {
        id: 'HibernateReadonlyField_AriaLabel',
        defaultMessage: 'Hibernate (preview)',
        description: 'Aria label for hibernate field with preview tag.',
    },
    hibernateInfoIconAriaLabel: {
        id: 'Hibernate_InfoIcon_AriaLabel',
        defaultMessage: 'Information icon for the user to learn more about hibernate',
        description: 'Aria label for the information icon for user to learn more about hibernate',
    },
});

const hibernateContainerTokens: IStackTokens = {
    childrenGap: 5,
};

export const HibernateReadonlyField: React.FC<HibernateReadonlyFieldProps> = React.memo(
    (props: HibernateReadonlyFieldProps) => {
        const { hibernateSupport, disabled } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const messageIconStyles = useMessageIconStyles();
        const messageStyles = useMessageStyles();

        const infoIconAriaLabel = formatMessage(messages.hibernateInfoIconAriaLabel);

        // Memoized data
        const value = React.useMemo(() => {
            switch (hibernateSupport) {
                case HibernateSupport.Enabled:
                    return formatMessage(messages.hibernateFieldSupported);
                case HibernateSupport.OsUnsupported:
                    return formatMessage(messages.hibernateFieldOsUnsupported);
                case HibernateSupport.Disabled:
                default:
                    return formatMessage(messages.hibernateFieldNotSupported);
            }
        }, [hibernateSupport, formatMessage]);

        const hibernateSupportedOrNotValues = React.useMemo(
            () => ({
                supportedOrNot: value,
            }),
            [value]
        );

        return (
            <Stack tokens={hibernateContainerTokens}>
                <Stack.Item>
                    <Label
                        disabled={disabled}
                        tagContent={
                            hibernateSupport === HibernateSupport.Enabled
                                ? formatMessage(messages.hibernatePreviewTag)
                                : undefined
                        }
                        aria-label={formatMessage(messages.hibernateFieldAriaLabel)}
                    >
                        <FormattedMessage
                            id="HibernateSupportedOrNot_Text"
                            defaultMessage="Hibernate: {supportedOrNot}"
                            description="Text showing if hibernate is supported or not for the dev box"
                            values={hibernateSupportedOrNotValues}
                        />
                    </Label>
                </Stack.Item>
                {hibernateSupport === HibernateSupport.Enabled && (
                    <Stack horizontal tokens={hibernateMessageContainerToken}>
                        <Stack.Item styles={messageIconStyles}>
                            <Icon aria-label={infoIconAriaLabel} iconName="Info" />
                        </Stack.Item>
                        <Stack.Item styles={messageStyles}>
                            <FormattedMessage
                                id="HibernateInfoMessage_Text"
                                defaultMessage="You may experience <Link> issues with provisioning and hibernate/resume operations </Link>"
                                description="Text directing to learning more about hibernate"
                                values={hibernatePreviewMessageValues}
                            />
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
        );
    }
);
