import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AzureDevOpsGitObjectTypeContract } from '../../../data/contracts/azure-dev-ops';
import {
    GetAzureDevOpsCommitResponse,
    GetAzureDevOpsTreeResponse,
    getAzureDevOpsCommit,
    getAzureDevOpsTree,
} from '../../../data/services/azure-dev-ops/vso-code';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    getAzureDevOpsRepoContents,
    getAzureDevOpsRepoContentsError,
    getAzureDevOpsRepoContentsFailed,
    getAzureDevOpsRepoContentsSuccess,
} from '../../actions/azure-dev-ops/azure-dev-ops-action-creators';
import { GetAzureDevOpsRepoContentsAction } from '../../actions/azure-dev-ops/azure-dev-ops-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureDevOpsPayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* getAzureDevOpsRepoContentsSaga(action: GetAzureDevOpsRepoContentsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { branchObjectId, repoUrl } = payload;

    try {
        const accessToken: string = yield putAndAwait(getAccessToken(GetAccessTokenForAzureDevOpsPayload(), meta));

        // ADO treeId is a property on the branch commit, so need to get commit before getting tree
        const commitResponse: GetAzureDevOpsCommitResponse = yield call(
            getAzureDevOpsCommit,
            repoUrl,
            branchObjectId,
            accessToken,
            activityId
        );

        if (isFailureResponse(commitResponse)) {
            yield resolveAction(action, commitResponse);
            yield put(getAzureDevOpsRepoContentsFailed({ failure: commitResponse, branchObjectId }, meta));
            return;
        }

        const treeId = commitResponse.data.treeId;

        const treeResponse: GetAzureDevOpsTreeResponse = yield call(
            getAzureDevOpsTree,
            repoUrl,
            treeId,
            accessToken,
            activityId
        );

        if (isFailureResponse(treeResponse)) {
            yield resolveAction(action, treeResponse);
            yield put(getAzureDevOpsRepoContentsFailed({ failure: treeResponse, branchObjectId }, meta));
            return;
        }

        const fileEntries = treeResponse.data.treeEntries.filter(
            (treeEntry) => treeEntry.gitObjectType === AzureDevOpsGitObjectTypeContract.blob
        );

        yield resolveAction(action, { succeeded: true, data: fileEntries });
        yield put(getAzureDevOpsRepoContentsSuccess({ branchObjectId, result: fileEntries }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetAzureDevOpsRepoContents);
        yield put(getAzureDevOpsRepoContentsError({ error, branchObjectId }, meta));
        yield rejectAction(action, error);
    }
}

export function* getAzureDevOpsRepoContentsListenerSaga(): SagaIterator {
    yield takeEvery(getAzureDevOpsRepoContents, getAzureDevOpsRepoContentsSaga);
}
