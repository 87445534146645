import { Label as FluentLabel, ILabelProps, Stack, makeStyles, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import InfoTooltip, { InfoTooltipProps } from '../info-tooltip';
import { Tag } from '../tag/tag';

/**
 * Styles
 */

const useInfoTooltipStyles = makeStyles({
    root: { paddingBottom: '5px', paddingTop: '7px' },
});

const useLabelStackItemStyles = makeStyles({ root: { paddingRight: '5px' } });

const useTooltipLabelStyles = makeStyles({
    root: {
        '::after': {
            paddingRight: '2px',
        },
    },
});

/**
 * END Styles
 */

export interface LabelProps extends ILabelProps {
    tooltipValue?: React.ReactElement | string;
    tagContent?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = (props: LabelProps) => {
    const { tooltipValue, children, styles, disabled, tagContent } = props;

    // Style hooks
    const infoTooltipStyles = useInfoTooltipStyles();
    const labelStackItemStyles = useLabelStackItemStyles();
    const tooltipLabelStyles = useTooltipLabelStyles();

    // Memoized data
    const tooltipProps = React.useMemo(
        (): InfoTooltipProps | undefined =>
            tooltipValue
                ? {
                      content: tooltipValue,
                  }
                : undefined,
        [tooltipValue]
    );

    const labelStyles = React.useMemo(() => mergeStyleSets(tooltipLabelStyles, styles), [tooltipLabelStyles, styles]);

    if (!tooltipProps && !tagContent) {
        return <FluentLabel {...props}>{children}</FluentLabel>;
    }

    return (
        <Stack grow={false} horizontal>
            <Stack.Item align="center" styles={labelStackItemStyles}>
                <FluentLabel {...props} styles={labelStyles}>
                    {children}
                </FluentLabel>
            </Stack.Item>
            {tooltipProps && (
                <Stack.Item align="center">
                    <InfoTooltip {...tooltipProps} styles={infoTooltipStyles} disabled={disabled} />
                </Stack.Item>
            )}
            {tagContent && (
                <Stack.Item>
                    <Tag>{tagContent}</Tag>
                </Stack.Item>
            )}
        </Stack>
    );
};

export default Label;
