import { FontWeights, IPanelProps, makeStyles, mergeStyleSets, Panel, PanelType } from '@fluentui/react';
import * as React from 'react';

/**
 * Style Section
 */

const useFormPanelHeaderStyles = makeStyles({
    header: {
        fontWeight: FontWeights.semibold,
        marginBottom: '28px',
    },
});

const useFormPanelStyles = makeStyles({
    root: {
        justifyContent: 'center',
    },
});

const useFormPanelOverlayStyles = makeStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
});

/* END */

export type FormPanelProps = Omit<
    IPanelProps,
    'type' | 'isFooterAtBottom' | 'focusTrapZoneProps' | 'headerTextProps' | 'overlayProps'
>;

export const FormPanel: React.FC<FormPanelProps> = (props: FormPanelProps) => {
    const { styles: providedStyles, customWidth } = props;

    // Style hooks
    const formPanelStyles = useFormPanelStyles();
    const formPanelHeaderStyles = useFormPanelHeaderStyles();
    const formPanelOverlayStyles = useFormPanelOverlayStyles();

    const headerTextProps = React.useMemo(() => ({ className: formPanelHeaderStyles.header }), [formPanelHeaderStyles]);
    const overlayStyleProps = React.useMemo(() => ({ styles: formPanelOverlayStyles }), [formPanelOverlayStyles]);

    const styles = React.useMemo(
        () => mergeStyleSets(formPanelStyles, providedStyles),
        [formPanelStyles, providedStyles]
    );

    return (
        <Panel
            {...props}
            customWidth={customWidth ?? '456px'}
            type={PanelType.custom}
            isFooterAtBottom
            styles={styles}
            headerTextProps={headerTextProps}
            overlayProps={overlayStyleProps}
        />
    );
};
