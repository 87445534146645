import { FontSizes, FontWeights, makeStyles, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { isDevPortalEnvironment } from '../../utilities/app';

/**
 * Style Section
 */

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
        lineHeight: '19px',
    },
});

const useTitleRootStyles = makeStyles({
    root: {
        height: '48px',
        justifyContent: 'center',
    },
});

const useTitleHeadingClassName = makeStyles({
    heading: {
        fontSize: FontSizes.size16,
    },
});

/* END */

const titleMessage = isDevPortalEnvironment ? (
    <FormattedMessage
        id="TitleBar_Title_DevPortal_Text"
        defaultMessage="Microsoft developer portal"
        description="Title of the application, used in application header. Variant for devportal prefixed hosts."
    />
) : (
    <FormattedMessage
        id="TitleBar_Title_Text"
        defaultMessage="Microsoft Dev Box"
        description="Title of the application, used in application header."
    />
);

const Title: React.FC = React.memo(() => {
    // Style hooks
    const { heading: headingClassName } = useTitleHeadingClassName();
    const titleRootStyles = useTitleRootStyles();
    const titleStyles = useTitleStyles();

    return (
        <Stack styles={titleRootStyles}>
            <Stack.Item styles={titleStyles}>
                <h1 className={headingClassName}>{titleMessage}</h1>
            </Stack.Item>
        </Stack>
    );
});

export default Title;
