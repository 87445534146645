import * as React from 'react';
import { useSelector } from 'react-redux';
import DevBoxes from '../../components/dev-box/dev-boxes';
import { DevBoxViewModel } from '../../components/dev-box/models';
import { getDevBoxViewModels, getDevBoxViewModelsInProject } from '../../components/dev-box/selectors';
import Environments from '../../components/environment/environments';
import { EnvironmentViewModel } from '../../components/environment/models';
import { getEnvironmentViewModels, getEnvironmentViewModelsInProject } from '../../components/environment/selectors';
import NoResourcesFilterContainer from '../../components/no-resources-filter';
import { ResourceFilterType } from '../../constants/filters';
import { useProjectFilterContext, useResourceFilterContext } from '../../hooks/context/filters';

interface ResourcesProps {
    hasDevBoxes: boolean;
    hasEnvironments: boolean;
}

interface ResourcesComponentProps {
    hasDevBoxes: boolean;
    hasEnvironments: boolean;
    devBoxes: DevBoxViewModel[];
    environments: EnvironmentViewModel[];
}

export const ResourcesComponent: React.FC<ResourcesComponentProps> = (props: ResourcesComponentProps) => {
    const { hasDevBoxes, hasEnvironments, devBoxes, environments } = props;

    const { value: resourceFilter } = useResourceFilterContext();
    const { value: projectFilter } = useProjectFilterContext();

    const filtersIncludeDevBoxes = resourceFilter === undefined || resourceFilter === ResourceFilterType.DevBoxes;

    const filtersIncludeEnvironments =
        resourceFilter === undefined || resourceFilter === ResourceFilterType.DeploymentEnvironments;

    const filteredDevBoxes = React.useMemo(
        () => (projectFilter ? getDevBoxViewModelsInProject(devBoxes, projectFilter) : devBoxes),
        [devBoxes, projectFilter]
    );
    const filteredEnvironments = React.useMemo(
        () => (projectFilter ? getEnvironmentViewModelsInProject(environments, projectFilter) : environments),
        [environments, projectFilter]
    );

    const showDevBoxes = React.useMemo(
        () => hasDevBoxes && filtersIncludeDevBoxes && filteredDevBoxes.length !== 0,
        [hasDevBoxes, filtersIncludeDevBoxes, filteredDevBoxes]
    );

    const showEnvironments = React.useMemo(
        () => hasEnvironments && filtersIncludeEnvironments && filteredEnvironments.length !== 0,
        [hasEnvironments, filtersIncludeEnvironments, filteredEnvironments]
    );

    if (!showDevBoxes && !showEnvironments) {
        return <NoResourcesFilterContainer />;
    }

    return (
        <>
            {showDevBoxes && <DevBoxes />}
            {showEnvironments && <Environments />}
        </>
    );
};

export const Resources: React.FC<ResourcesProps> = (props: ResourcesProps) => {
    const { hasDevBoxes, hasEnvironments } = props;

    const devBoxes = useSelector(getDevBoxViewModels);
    const environments = useSelector(getEnvironmentViewModels);

    return (
        <ResourcesComponent
            hasDevBoxes={hasDevBoxes}
            hasEnvironments={hasEnvironments}
            devBoxes={devBoxes}
            environments={environments}
        />
    );
};
