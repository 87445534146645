import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    discoverLocations,
    discoverLocationsError,
    discoverLocationsFailed,
    discoverLocationsSuccess,
    listLocations,
    listLocationsError,
    listLocationsFailed,
    listLocationsSuccess,
} from '../actions/subscription/subscription-action-creators';
import { azureLocationAdapter } from '../adapters/subscription-adapters';
import { SubscriptionDataStore, SubscriptionStatusStore, SubscriptionStore } from '../store/subscription-store';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const subscriptionReducer = combineReducers<SubscriptionStore>({
    data: createReducer(SubscriptionDataStore(), (builder) => {
        builder.addCase(listLocationsSuccess, (store, action) => {
            const actions = getActionsInGroup(action);

            // Note: if we receive the same location from multiple subscriptions, we take the latest instance. In
            // practice, the differences between these instances are minimal and don't impact our scenarios.
            azureLocationAdapter.setMany(
                store.locations,
                actions.flatMap((action) => {
                    const { result } = getPayload(action);
                    return result.map((location) => Entity(location.name, location));
                })
            );
        });
    }),

    status: combineReducers<SubscriptionStatusStore>({
        discoverLocations: createStatusReducer({
            inProgress: discoverLocations,
            error: discoverLocationsError,
            failed: discoverLocationsFailed,
            success: discoverLocationsSuccess,
        }),

        listLocations: createIndexedStatusReducer({
            inProgress: listLocations,
            error: listLocationsError,
            failed: listLocationsFailed,
            success: listLocationsSuccess,
        }),
    }),
});
