import { UnionMap, UppercasedUnionMap } from '../types/union-map';

export type Language =
    | 'cs'
    | 'de'
    | 'en'
    | 'es'
    | 'fr'
    | 'hu'
    | 'id'
    | 'it'
    | 'ja'
    | 'ko'
    | 'nl'
    | 'pl'
    | 'pt'
    | 'qps'
    | 'ru'
    | 'sv'
    | 'tr'
    | 'zh';

export const Language: UppercasedUnionMap<Language> = {
    CS: 'cs',
    DE: 'de',
    EN: 'en',
    ES: 'es',
    FR: 'fr',
    HU: 'hu',
    ID: 'id',
    IT: 'it',
    JA: 'ja',
    KO: 'ko',
    NL: 'nl',
    PL: 'pl',
    PT: 'pt',
    QPS: 'qps',
    RU: 'ru',
    SV: 'sv',
    TR: 'tr',
    ZH: 'zh',
};

export type SupportedMarket =
    | 'BR'
    | 'CN'
    | 'CZ'
    | 'DE'
    | 'ES'
    | 'FR'
    | 'HK'
    | 'HU'
    | 'ID'
    | 'IT'
    | 'JA'
    | 'KR'
    | 'MO'
    | 'NL'
    | 'PL'
    | 'ploc'
    | 'plocm'
    | 'PT'
    | 'RU'
    | 'SE'
    | 'SG'
    | 'TR'
    | 'TW'
    | 'US';

export const SupportedMarket: UnionMap<SupportedMarket> = {
    BR: 'BR',
    CN: 'CN',
    CZ: 'CZ',
    DE: 'DE',
    ES: 'ES',
    FR: 'FR',
    HK: 'HK',
    HU: 'HU',
    ID: 'ID',
    IT: 'IT',
    JA: 'JA',
    KR: 'KR',
    MO: 'MO',
    NL: 'NL',
    PL: 'PL',
    ploc: 'ploc',
    plocm: 'plocm',
    PT: 'PT',
    RU: 'RU',
    SE: 'SE',
    SG: 'SG',
    TR: 'TR',
    TW: 'TW',
    US: 'US',
};

type SupportedLocaleForLanguage<
    TLanguage extends Language,
    TMarket extends SupportedMarket
> = `${TLanguage}-${TMarket}`;

type SupportedCSLocale = SupportedLocaleForLanguage<'cs', 'CZ'>;
type SupportedDELocale = SupportedLocaleForLanguage<'de', 'DE'>;
type SupportedENLocale = SupportedLocaleForLanguage<'en', 'US'>;
type SupportedESLocale = SupportedLocaleForLanguage<'es', 'ES'>;
type SupportedFRLocale = SupportedLocaleForLanguage<'fr', 'FR'>;
type SupportedHULocale = SupportedLocaleForLanguage<'hu', 'HU'>;
type SupportedIDLocale = SupportedLocaleForLanguage<'id', 'ID'>;
type SupportedITLocale = SupportedLocaleForLanguage<'it', 'IT'>;
type SupportedJALocale = SupportedLocaleForLanguage<'ja', 'JA'>;
type SupportedKOLocale = SupportedLocaleForLanguage<'ko', 'KR'>;
type SupportedNLLocale = SupportedLocaleForLanguage<'nl', 'NL'>;
type SupportedPLLocale = SupportedLocaleForLanguage<'pl', 'PL'>;
type SupportedPTLocale = SupportedLocaleForLanguage<'pt', 'BR' | 'PT'>;
type SupportedQPSLocale = SupportedLocaleForLanguage<'qps', 'ploc' | 'plocm'>;
type SupportedRULocale = SupportedLocaleForLanguage<'ru', 'RU'>;
type SupportedSVLocale = SupportedLocaleForLanguage<'sv', 'SE'>;
type SupportedTRLocale = SupportedLocaleForLanguage<'tr', 'TR'>;
type SupportedZHLocale = SupportedLocaleForLanguage<'zh', 'CN' | 'HK' | 'MO' | 'SG' | 'TW'>;

export type SupportedLocale =
    | Language
    | SupportedCSLocale
    | SupportedDELocale
    | SupportedENLocale
    | SupportedESLocale
    | SupportedFRLocale
    | SupportedHULocale
    | SupportedIDLocale
    | SupportedITLocale
    | SupportedJALocale
    | SupportedKOLocale
    | SupportedNLLocale
    | SupportedPLLocale
    | SupportedPTLocale
    | SupportedQPSLocale
    | SupportedRULocale
    | SupportedSVLocale
    | SupportedTRLocale
    | SupportedZHLocale;

export const SupportedLocale: UnionMap<SupportedLocale> = {
    cs: 'cs',
    'cs-CZ': 'cs-CZ',
    de: 'de',
    'de-DE': 'de-DE',
    en: 'en',
    'en-US': 'en-US',
    es: 'es',
    'es-ES': 'es-ES',
    fr: 'fr',
    'fr-FR': 'fr-FR',
    hu: 'hu',
    'hu-HU': 'hu-HU',
    id: 'id',
    'id-ID': 'id-ID',
    it: 'it',
    'it-IT': 'it-IT',
    ja: 'ja',
    'ja-JA': 'ja-JA',
    ko: 'ko',
    'ko-KR': 'ko-KR',
    nl: 'nl',
    'nl-NL': 'nl-NL',
    pl: 'pl',
    'pl-PL': 'pl-PL',
    pt: 'pt',
    'pt-BR': 'pt-BR',
    'pt-PT': 'pt-PT',
    qps: 'qps',
    'qps-ploc': 'qps-ploc',
    'qps-plocm': 'qps-plocm',
    ru: 'ru',
    'ru-RU': 'ru-RU',
    sv: 'sv',
    'sv-SE': 'sv-SE',
    tr: 'tr',
    'tr-TR': 'tr-TR',
    zh: 'zh',
    'zh-CN': 'zh-CN',
    'zh-HK': 'zh-HK',
    'zh-MO': 'zh-MO',
    'zh-SG': 'zh-SG',
    'zh-TW': 'zh-TW',
};

export const DefaultLanguage: Language = Language.EN;
export const DefaultLocale: SupportedLocale = SupportedLocale['en-US'];
export const DefaultMarket = SupportedMarket.US;
export const InvariantLocale = 'en-001';

export const isSupportedLanguage = (value: string): value is Language =>
    Object.values(Language).includes(value as Language);

export const isSupportedLocale = (value: string): value is SupportedLocale =>
    Object.keys(SupportedLocale).includes(value);
