import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import {
    GetCustomizationTaskLogResponse,
    getCustomizationTaskLog,
} from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { createObjectUrlForBlob } from '../../../utilities/data';
import {
    getCustomizationTaskLog as getCustomizationTaskLogActionCreator,
    getCustomizationTaskLogError,
    getCustomizationTaskLogFailed,
    getCustomizationTaskLogSuccess,
} from '../../actions/customization/customization-action-creators';
import { GetCustomizationTaskLogAction } from '../../actions/customization/customization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery } from '../../effects/take';

export function* getCustomizationTaskLogSaga(action: GetCustomizationTaskLogAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: GetCustomizationTaskLogResponse = yield call(
            getCustomizationTaskLog,
            id,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(getCustomizationTaskLogFailed({ failure: response, id }, meta));
            return;
        }

        const { data } = response;

        const taskLogUrl = yield call(createObjectUrlForBlob, data);

        yield put(getCustomizationTaskLogSuccess({ id, result: taskLogUrl }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetCustomizationTaskLog);
        yield put(getCustomizationTaskLogError({ error, id }, meta));
    }
}

export function* getCustomizationTaskLogListenerSaga(): SagaIterator {
    yield takeEvery(getCustomizationTaskLogActionCreator, getCustomizationTaskLogSaga);
}
