import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { AzureLocation } from '../../models/resource-manager';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { azureLocationAdapter } from '../adapters/subscription-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'discoverLocations';
type IndexedStatusName = 'listLocations';

export interface SubscriptionDataStore {
    locations: EntityState<Entity<AzureLocation>>;
}

export const SubscriptionDataStore = createInitializer<SubscriptionDataStore>({
    locations: azureLocationAdapter.getInitialState(),
});

export type SubscriptionStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const SubscriptionStatusStore = createInitializer<SubscriptionStatusStore>({
    discoverLocations: Status(),
    listLocations: statusAdapter.getInitialState(),
});

export interface SubscriptionStore {
    data: SubscriptionDataStore;
    status: SubscriptionStatusStore;
}

export const SubscriptionStore = createInitializer<SubscriptionStore>({
    data: SubscriptionDataStore(),
    status: SubscriptionStatusStore(),
});
