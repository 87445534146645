import { ContentType, Method } from '../../../constants/http';
import { DataResponse, FailureOperation } from '../../../models/common';
import { LongRunningOperationResponseContract } from '../../contracts/common';
import { FetchOptions, fetchRequest, handleFetchResponse } from '../fetch-request';

export type GetLongRunningOperationStatusResponse = DataResponse<LongRunningOperationResponseContract>;

export const getLongRunningOperationStatus = async (
    operationLocation: string,
    accessToken: string,
    activityId?: string
): Promise<GetLongRunningOperationStatusResponse> => {
    const options: FetchOptions<Response> = {
        activityId,
        contentType: ContentType.ApplicationJson,
        accessToken,
    };

    const response = await fetchRequest(operationLocation, Method.GET, options);

    return await handleFetchResponse(response, FailureOperation.GetLongRunningOperation);
};
