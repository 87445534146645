export type Initializer<T> = (opt?: Partial<T>) => T;

export const createInitializer =
    <T>(defaults: T, allowUndefinedOverrides = false): Initializer<T> =>
    (opt) => {
        // Values from the overrides that are explicitly undefined will essentially delete any default values, which may
        // result in the type def becoming un-sync'd from the runtime contents. Allow that behavior if the consumer
        // wants to, though.
        if (allowUndefinedOverrides) {
            return {
                ...defaults,
                ...(opt ?? {}),
            };
        }

        // Filter out any explicitly undefined properties before unpacking on top of the defaults
        const optCopy: Partial<T> = opt ? { ...opt } : {};

        Object.getOwnPropertyNames(optCopy).forEach((prop) => {
            const key = prop as keyof Partial<T>;
            const value = optCopy[key];

            if (value === undefined) {
                delete optCopy[key];
            }
        });

        return {
            ...defaults,
            ...(optCopy ?? {}),
        };
    };
