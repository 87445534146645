import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { delayAllDevBoxActionsListenerSaga } from './delay/delay-all-dev-box-actions';
import { listDevBoxActionsListenerSaga } from './list/list-dev-box-actions';
import { loadDevBoxActionsForDevBoxesListenerSaga } from './list/load-dev-box-actions-for-dev-boxes';
import { skipAllDevBoxActionsListenerSaga } from './skip/skip-all-dev-box-actions';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(delayAllDevBoxActionsListenerSaga),
        fork(listDevBoxActionsListenerSaga),
        fork(loadDevBoxActionsForDevBoxesListenerSaga),
        fork(skipAllDevBoxActionsListenerSaga),
    ]);
}
