import { createSelector } from '@reduxjs/toolkit';
import { getDevBoxes } from '../../selector/dev-box-selectors';
import { DevBoxState } from '../../store/dev-box-state';

/**
 * Application state selectors
 */

export const getIdsForDevBoxesRequiringDevBoxOperations = createSelector([getDevBoxes], (devBoxes) =>
    devBoxes.filter((devBox) => devBox.actionState === DevBoxState.Repaired).map((devBox) => devBox.uri)
);
