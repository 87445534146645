import { IColumn } from '@fluentui/react';
import { IntlFormatters, MessageDescriptor } from 'react-intl';
import { sortBy } from '../../../utilities/array';
import { compareDates } from '../../../utilities/date';
import { compareNumbers } from '../../../utilities/number';
import {
    compareStrings,
    isSubstringOfTextCaseInsensitive,
    isUndefinedOrWhiteSpace,
    removeSpaces,
} from '../../../utilities/string';
import { getRelativeTimeComponents } from '../../../utilities/time';
import { PoolKey, PoolViewModel } from '../models';
import { selectDevBoxPoolDetailsListMessages } from './messages';
import { PoolDetailsListItem } from './models';

const comparePoolViewModels = (a: PoolViewModel, b: PoolViewModel, columnKey: PoolKey): number => {
    switch (columnKey) {
        case PoolKey.LastUpdated:
            return compareDates(a.lastUpdated, b.lastUpdated);

        case PoolKey.CpuCount:
        case PoolKey.DiskSizeInGb:
        case PoolKey.MemoryInGb:
            return compareNumbers(a[columnKey] as number, b[columnKey] as number);

        // For everything else, do a case-insensitive string comparison
        default:
            return compareStrings(`${a[columnKey]}`, `${b[columnKey]}`, true);
    }
};

const createPoolDetailsListColumn = (
    ariaLabel: string,
    isSortedDescending: boolean,
    key: PoolKey,
    keyOfSortedColumn: PoolKey,
    name: string,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRender: (item: PoolDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null,
    sortAscendingAriaLabel: string,
    sortDescendingAriaLabel: string,
    minWidth: number,
    maxWidth?: number,
    isPadded = true,
    isRowHeader = false
): IColumn => ({
    ariaLabel,
    isPadded,
    isResizable: minWidth !== maxWidth,
    isRowHeader,
    isSorted: key === keyOfSortedColumn,
    ...(key === keyOfSortedColumn ? { isSortedDescending } : {}),
    key,
    maxWidth,
    minWidth,
    name,
    onColumnClick,
    onRender,
    sortAscendingAriaLabel,
    sortDescendingAriaLabel,
});

export const getPoolViewModelKey = (value: PoolViewModel): string => value.name;

const createPoolDetailsListItem = (
    formatCpuCount: (value: number) => string,
    formatDiskSizeInGb: (value: number) => string,
    formatLastImageUpdate: (value: Date | undefined) => string,
    formatMemoryInGb: (value: number) => string,
    formatHibernation: (value: boolean) => string,
    poolViewModel: PoolViewModel
): PoolDetailsListItem => {
    const {
        cpuCount,
        diskSizeInGb,
        imageName,
        imageVersion,
        lastUpdated,
        memoryInGb,
        region,
        supportsHibernate,
        displayName,
    } = poolViewModel;

    return {
        key: getPoolViewModelKey(poolViewModel),
        columns: {
            cpuCount: formatCpuCount(cpuCount),
            diskSizeInGb: formatDiskSizeInGb(diskSizeInGb),
            imageName: imageName ?? '',
            imageVersion: imageVersion ?? '',
            lastUpdated: formatLastImageUpdate(lastUpdated),
            memoryInGb: formatMemoryInGb(memoryInGb),
            name: displayName,
            region,
            supportsHibernate: formatHibernation(supportsHibernate),
        },
        value: poolViewModel,
    };
};

const doesPoolMatchSearchText = (searchText: string, pool: PoolDetailsListItem) => {
    const { columns } = pool;
    const {
        name,
        cpuCount,
        memoryInGb,
        diskSizeInGb,
        imageName,
        imageVersion,
        region,
        lastUpdated,
        supportsHibernate,
    } = columns;

    return (
        isSubstringOfTextCaseInsensitive(name, searchText) ||
        isSubstringOfTextCaseInsensitive(cpuCount, searchText) ||
        isSubstringOfTextCaseInsensitive(memoryInGb, searchText) ||
        isSubstringOfTextCaseInsensitive(diskSizeInGb, searchText) ||
        isSubstringOfTextCaseInsensitive(imageName, searchText) ||
        isSubstringOfTextCaseInsensitive(imageVersion, searchText) ||
        isSubstringOfTextCaseInsensitive(region, searchText) ||
        isSubstringOfTextCaseInsensitive(lastUpdated, searchText) ||
        isSubstringOfTextCaseInsensitive(supportsHibernate, searchText)
    );
};

const filterPoolDetailsListItems = (filterText: string, items: PoolDetailsListItem[]): PoolDetailsListItem[] =>
    items.filter((item) => doesPoolMatchSearchText(filterText, item));

export const formatHibernation = (supportsHibernate: boolean, formatters: IntlFormatters): string => {
    const { formatMessage } = formatters;

    return supportsHibernate
        ? formatMessage(selectDevBoxPoolDetailsListMessages.hibernationSupportedText)
        : formatMessage(selectDevBoxPoolDetailsListMessages.hibernationNotSupportedText);
};

export const formatCpuCount = (cpuCount: number, formatters: IntlFormatters): string => {
    const { formatMessage, formatNumber } = formatters;

    return formatMessage(selectDevBoxPoolDetailsListMessages.cpuFormattedText, {
        cpuCount: formatNumber(cpuCount),
    });
};

export const formatDiskSizeInGb = (diskSizeInGb: number, formatters: IntlFormatters): string => {
    const { formatMessage, formatNumber } = formatters;

    return formatMessage(selectDevBoxPoolDetailsListMessages.diskSizeInGbFormattedText, {
        // Note: using removeSpaces to convert "__ GB" -> "__GB"
        diskSizeInGb: removeSpaces(formatNumber(diskSizeInGb, { style: 'unit', unit: 'gigabyte' })),
    });
};

export const formatLastImageUpdate = (lastUpdatedDate: Date | undefined, formatters: IntlFormatters): string => {
    if (lastUpdatedDate === undefined) {
        return '';
    }

    const { formatRelativeTime } = formatters;
    const { unit, value } = getRelativeTimeComponents(lastUpdatedDate, new Date());

    // Note: converting value to negative to get "x days ago" rather than "in x days"
    return formatRelativeTime(value * -1, unit);
};

export const formatMemoryInGb = (memoryInGb: number, formatters: IntlFormatters): string => {
    const { formatNumber } = formatters;

    // Note: using removeSpaces to convert "__ GB" -> "__GB"
    return removeSpaces(formatNumber(memoryInGb, { style: 'unit', unit: 'gigabyte' }));
};

export const getPoolDetailsListColumns = (
    descending: boolean,
    formatMessage: (message: MessageDescriptor) => string,
    keyOfSortedColumn: PoolKey,
    onColumnClick: (ev: unknown, column: IColumn) => void,
    onRenderItem: (item: PoolDetailsListItem, index?: number, column?: IColumn) => JSX.Element | null,
    hasPoolWithHibernate: boolean
): IColumn[] => {
    const sortAscendingAriaLabel = formatMessage(selectDevBoxPoolDetailsListMessages.sortAscendingOrderAriaLabel);
    const sortDescendingAriaLabel = formatMessage(selectDevBoxPoolDetailsListMessages.sortDescendingOrderAriaLabel);

    if (hasPoolWithHibernate) {
        return [
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.nameColumnHeaderAriaLabel),
                descending,
                PoolKey.Name,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.nameColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                254,
                undefined,
                false,
                true
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.regionColumnHeaderAriaLabel),
                descending,
                PoolKey.Region,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.regionColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                95,
                95
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.cpuColumnHeaderAriaLabel),
                descending,
                PoolKey.CpuCount,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.cpuColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                49,
                49
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.ramColumnHeaderAriaLabel),
                descending,
                PoolKey.MemoryInGb,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.ramColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                49,
                49
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.storageColumnHeaderAriaLabel),
                descending,
                PoolKey.DiskSizeInGb,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.storageColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                69,
                69
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.hibernationColumnHeaderText),
                descending,
                PoolKey.SupportsHibernate,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.hibernationColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                80,
                80
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.sourceImageColumnHeaderAriaLabel),
                descending,
                PoolKey.ImageName,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.sourceImageColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                250
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.versionColumnHeaderAriaLabel),
                descending,
                PoolKey.ImageVersion,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.versionColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                59,
                59
            ),
            createPoolDetailsListColumn(
                formatMessage(selectDevBoxPoolDetailsListMessages.lastImageUpdateColumnHeaderAriaLabel),
                descending,
                PoolKey.LastUpdated,
                keyOfSortedColumn,
                formatMessage(selectDevBoxPoolDetailsListMessages.lastImageUpdateColumnHeaderText),
                onColumnClick,
                onRenderItem,
                sortAscendingAriaLabel,
                sortDescendingAriaLabel,
                118,
                118
            ),
        ];
    }

    return [
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.nameColumnHeaderAriaLabel),
            descending,
            PoolKey.Name,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.nameColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            254,
            undefined,
            false,
            true
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.regionColumnHeaderAriaLabel),
            descending,
            PoolKey.Region,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.regionColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            95,
            95
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.cpuColumnHeaderAriaLabel),
            descending,
            PoolKey.CpuCount,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.cpuColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            49,
            49
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.ramColumnHeaderAriaLabel),
            descending,
            PoolKey.MemoryInGb,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.ramColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            49,
            49
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.storageColumnHeaderAriaLabel),
            descending,
            PoolKey.DiskSizeInGb,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.storageColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            69,
            69
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.sourceImageColumnHeaderAriaLabel),
            descending,
            PoolKey.ImageName,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.sourceImageColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            250
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.versionColumnHeaderAriaLabel),
            descending,
            PoolKey.ImageVersion,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.versionColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            72,
            72
        ),
        createPoolDetailsListColumn(
            formatMessage(selectDevBoxPoolDetailsListMessages.lastImageUpdateColumnHeaderAriaLabel),
            descending,
            PoolKey.LastUpdated,
            keyOfSortedColumn,
            formatMessage(selectDevBoxPoolDetailsListMessages.lastImageUpdateColumnHeaderText),
            onColumnClick,
            onRenderItem,
            sortAscendingAriaLabel,
            sortDescendingAriaLabel,
            118,
            118
        ),
    ];
};

export const getPoolDetailsListItems = (
    columnKey: PoolKey,
    descending: boolean,
    filterText: string,
    formatCpuCount: (value: number) => string,
    formatDiskSizeInGb: (value: number) => string,
    formatLastImageUpdate: (value: Date | undefined) => string,
    formatMemoryInGb: (value: number) => string,
    formatHibernation: (value: boolean) => string,
    poolViewModels: PoolViewModel[]
): PoolDetailsListItem[] => {
    // Sort items by their current given key
    const orderedItems = sortBy(
        poolViewModels,
        (item) => item,
        (a, b) => comparePoolViewModels(a, b, columnKey)
    );
    const sortedItems = descending ? orderedItems.reverse() : orderedItems;

    // Convert view models into formatted items
    const formattedItems = sortedItems.map((item) =>
        createPoolDetailsListItem(
            formatCpuCount,
            formatDiskSizeInGb,
            formatLastImageUpdate,
            formatMemoryInGb,
            formatHibernation,
            item
        )
    );

    // Apply filter
    return isUndefinedOrWhiteSpace(filterText)
        ? formattedItems
        : filterPoolDetailsListItems(filterText, formattedItems);
};
