import { GetEnvironmentResponse, ListEnvironmentsResponse } from '../../../data/services/data-plane-api/environment';
import { AggregatedResult } from '../../../models/common';
import { Environment } from '../../../models/environment';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload, PollPayload, ResultPayload } from '../core-actions';
import {
    AddEnvironmentOperationFailedPayload,
    AddEnvironmentPayload,
    DeleteEnvironmentFailedPayload,
    DeployEnvironmentErrorPayload,
    DeployEnvironmentFailedPayload,
    DeployEnvironmentOperationFailedPayload,
    DeployEnvironmentPayload,
    EnvironmentActionType,
    ListEnvironmentsSuccessPayload,
    PollNonTerminalEnvironmentCompletePayload,
    UpdateEnvironmentPayload,
    UpdateEnvironmentSuccessPaylaod,
} from './environment-actions';

export const addEnvironment = createActionCreator<AddEnvironmentPayload>(EnvironmentActionType.AddEnvironment, {
    startsActivity: true,
});

export const addEnvironmentAccepted = createActionCreator<IndexedPayload & ResultPayload<Environment>>(
    EnvironmentActionType.AddEnvironmentAccepted
);

export const addEnvironmentError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.AddEnvironmentError
);

export const addEnvironmentFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentActionType.AddEnvironmentFailed
);

export const addEnvironmentOperationError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.AddEnvironmentOperationError
);

export const addEnvironmentOperationFailed = createActionCreator<AddEnvironmentOperationFailedPayload>(
    EnvironmentActionType.AddEnvironmentOperationFailed
);

export const addEnvironmentOperationSuccess = createActionCreator<IndexedPayload & ResultPayload<Environment>>(
    EnvironmentActionType.AddEnvironmentOperationSuccess
);

export const clearAddEnvironmentFailure = createActionCreatorWithoutPayload(
    EnvironmentActionType.ClearAddEnvironmentFailure
);

export const clearDeployEnvironmentFailure = createActionCreatorWithoutPayload(
    EnvironmentActionType.ClearDeployEnvironmentFailure
);

export const deleteEnvironment = createActionCreator<IndexedPayload>(EnvironmentActionType.DeleteEnvironment, {
    startsActivity: true,
});

export const deleteEnvironmentAccepted = createActionCreator<IndexedPayload>(
    EnvironmentActionType.DeleteEnvironmentAccepted
);

export const deleteEnvironmentError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.DeleteEnvironmentError
);

export const deleteEnvironmentFailed = createActionCreator<DeleteEnvironmentFailedPayload>(
    EnvironmentActionType.DeleteEnvironmentFailed
);

export const deleteEnvironmentSuccess = createActionCreator<IndexedPayload>(
    EnvironmentActionType.DeleteEnvironmentSuccess
);

export const deployEnvironment = createActionCreator<DeployEnvironmentPayload>(
    EnvironmentActionType.DeployEnvironment,
    { startsActivity: true }
);

export const deployEnvironmentAccepted = createActionCreator<IndexedPayload>(
    EnvironmentActionType.DeployEnvironmentAccepted
);

export const deployEnvironmentError = createActionCreator<DeployEnvironmentErrorPayload>(
    EnvironmentActionType.DeployEnvironmentError
);

export const deployEnvironmentFailed = createActionCreator<DeployEnvironmentFailedPayload>(
    EnvironmentActionType.DeployEnvironmentFailed
);

export const deployEnvironmentOperationError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.DeployEnvironmentOperationError
);

export const deployEnvironmentOperationFailed = createActionCreator<DeployEnvironmentOperationFailedPayload>(
    EnvironmentActionType.DeployEnvironmentOperationFailed
);

export const deployEnvironmentSuccess = createActionCreator<IndexedPayload & ResultPayload<Environment>>(
    EnvironmentActionType.DeployEnvironmentSuccess
);

export const discoverEnvironmentsInTenant = createActionCreatorWithoutPayload<AggregatedResult>(
    EnvironmentActionType.DiscoverEnvironmentsInTenant,
    { async: true }
);

export const discoverEnvironmentsInTenantError = createActionCreator<ErrorPayload>(
    EnvironmentActionType.DiscoverEnvironmentsInTenantError
);

export const discoverEnvironmentsInTenantFailed = createActionCreator<FailedPayload>(
    EnvironmentActionType.DiscoverEnvironmentsInTenantFailed
);

export const discoverEnvironmentsInTenantSuccess = createActionCreatorWithoutPayload(
    EnvironmentActionType.DiscoverEnvironmentsInTenantSuccess
);

export const listEnvironments = createActionCreator<IndexedPayload, ListEnvironmentsResponse>(
    EnvironmentActionType.ListEnvironments,
    { async: true, startsActivity: true }
);

export const listEnvironmentsError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.ListEnvironmentsError
);

export const listEnvironmentsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentActionType.ListEnvironmentsFailed
);

export const listEnvironmentsSuccess = createActionCreator<ListEnvironmentsSuccessPayload>(
    EnvironmentActionType.ListEnvironmentsSuccess
);

export const pollNonTerminalEnvironment = createActionCreator<IndexedPayload & PollPayload, GetEnvironmentResponse>(
    EnvironmentActionType.PollNonTerminalEnvironment,
    { async: true, startsActivity: true }
);

export const pollNonTerminalEnvironmentComplete = createActionCreator<PollNonTerminalEnvironmentCompletePayload>(
    EnvironmentActionType.PollNonTerminalEnvironmentComplete
);

export const pollNonTerminalEnvironmentError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.PollNonTerminalEnvironmentError
);

export const updateEnvironment = createActionCreator<UpdateEnvironmentPayload>(
    EnvironmentActionType.UpdateEnvironment,
    { startsActivity: true }
);

export const updateEnvironmentError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentActionType.UpdateEnvironmentError
);

export const updateEnvironmentFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentActionType.UpdateEnvironmentFailed
);

export const updateEnvironmentSuccess = createActionCreator<UpdateEnvironmentSuccessPaylaod>(
    EnvironmentActionType.UpdateEnvironmentSuccess
);
