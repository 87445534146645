import * as React from 'react';
import { useSelector } from 'react-redux';
import { getIsSignedIn } from '../../redux/selector/identity-selectors';
import { PropsWithChildren } from '../../types/props-with-children';

export const WhenSignedIn: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { children } = props;

    // Application state hooks
    const isSignedIn = useSelector(getIsSignedIn);

    if (!isSignedIn) {
        return <></>;
    }

    return <>{children}</>;
};

export default WhenSignedIn;
