import { DependencyList, useCallback, useRef } from 'react';
import { IsVoid } from '../types/is-void';

export type ThrottledCallback<TCallback extends (...args: Parameters<TCallback>) => ReturnType<TCallback>> = IsVoid<
    ReturnType<TCallback>,
    (...args: Parameters<TCallback>) => void,
    (...args: Parameters<TCallback>) => ReturnType<TCallback> | undefined
>;

export const useThrottledCallback = <TCallback extends (...args: Parameters<TCallback>) => ReturnType<TCallback>>(
    delayInMilliseconds: number,
    callback: TCallback,
    dependencies: DependencyList
): ThrottledCallback<TCallback> => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    return useCallback(
        (...args: Parameters<TCallback>) => {
            // Skip execution if timeout is still active
            if (timeoutRef.current !== undefined) {
                return undefined;
            }

            // Otherwise, execute the callback and start a new blocking timer
            const value = callback(...args);

            timeoutRef.current = setTimeout(() => {
                timeoutRef.current = undefined;
            }, delayInMilliseconds);

            return value;
        },
        [...dependencies]
    );
};
