import { AccountInfo } from '@azure/msal-browser';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { getActiveAccount, getAuthority, isAuthenticated, signIn, signOut } from '../../../data/services/identity';
import { ClientError, FailureOperation } from '../../../models/common';
import { selectTenant, selectTenantError, selectTenantRedirecting } from '../../actions/tenant/tenant-action-creators';
import { SelectTenantAction } from '../../actions/tenant/tenant-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { takeLeading } from '../../effects/take';
import { getPayload } from '../../utilities/payload-action';

export function* selectTenantSaga(action: SelectTenantAction): SagaIterator {
    const { id } = getPayload(action);

    try {
        // Stash current active account for later. This will be deleted if a sign-out occurs, but will be used to
        // provide a login hint for new tenant sign-in.
        const previousAccount: AccountInfo | undefined = yield call(getActiveAccount);

        // If user is already authenticated, sign them out locally. This will avoid a redirect or popup, but they won't
        // be truly signed out on the server side.
        const isSignedIn: boolean = yield call(isAuthenticated);

        if (isSignedIn) {
            yield call(signOut, true);
        }

        // Sign user in with authority used for given tenant
        const authority: string = yield call(getAuthority, id);

        // Note: current behavior is we redirect to index when switching tenants, which clears search
        yield call(signIn, {
            account: previousAccount,
            authority,
            redirectStartPage: `https://${window.location.host}/`,
        });
        yield put(selectTenantRedirecting({ id }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.SelectTenant);
        yield put(selectTenantError({ error, id }));
    }
}

export function* selectTenantListenerSaga(): SagaIterator {
    yield takeLeading(selectTenant, selectTenantSaga);
}
