import { useCallback } from 'react';

// Sidesteps react-final-form's goofy behavior around checkbox's onChange handler
export const useOnChangeCallbackForCheckbox = (
    onChange: (checked: boolean) => void
): ((event: unknown, checked?: boolean) => void) => {
    return useCallback(
        (_ev: unknown, checked?: boolean) => {
            onChange(checked ?? false);
        },
        [onChange]
    );
};
