import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    discoverPoolsForProjects,
    discoverPoolsForProjectsError,
    discoverPoolsForProjectsFailed,
    discoverPoolsForProjectsSuccess,
    listPools,
    listPoolsError,
    listPoolsFailed,
    listPoolsSuccess,
} from '../actions/pool/pool-action-creators';
import { poolAdapter } from '../adapters/pool-adapters';
import { PoolDataStore, PoolStatusStore, PoolStore } from '../store/pool-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const poolReducer = combineReducers<PoolStore>({
    data: createReducer(PoolDataStore(), (builder) => {
        builder.addCase(listPoolsSuccess, (store, action) => {
            const { result } = getPayload(action);

            poolAdapter.setMany(
                store.pools,
                result.map((data) => Entity(data.uri, data))
            );
        });
    }),

    status: combineReducers<PoolStatusStore>({
        discoverPoolsForProjects: createStatusReducer({
            inProgress: discoverPoolsForProjects,
            error: discoverPoolsForProjectsError,
            failed: discoverPoolsForProjectsFailed,
            success: discoverPoolsForProjectsSuccess,
        }),

        listPools: createIndexedStatusReducer({
            inProgress: listPools,
            error: listPoolsError,
            failed: listPoolsFailed,
            success: listPoolsSuccess,
        }),

        discoverPoolsForDevBoxes: createStatusReducer({
            inProgress: discoverPoolsForProjects,
            error: discoverPoolsForProjectsError,
            failed: discoverPoolsForProjectsFailed,
            success: discoverPoolsForProjectsSuccess,
        }),
    }),
});
