import { Theme } from '@fluentui/react';
import { UnionMap, UnionValueMap } from '../types/union-map';
import { DarkTheme } from './custom-themes/dark-theme';
import { LightTheme } from './custom-themes/light-theme';

//Add more themes if necessary
export type ThemeMode = 'DarkMode' | 'LightMode';

export const ThemeMode: UnionMap<ThemeMode> = {
    DarkMode: 'DarkMode',
    LightMode: 'LightMode',
};

export const CustomThemes: UnionValueMap<ThemeMode, Theme> = {
    DarkMode: DarkTheme,
    LightMode: LightTheme,
};
