import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { GraphDirectoryObject } from '../../models/graph';
import { SerializableSet } from '../../types/serializable-set';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { graphDirectoryObjectAdapter } from '../adapters/graph-adapters';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'getBannerLogoForOrganization' | 'getOrganization' | 'getPhotoForSignedInUser' | 'getSignedInUser';
type IndexedStatusName = 'getGraphDirectoryObject';

// TODO: eventually, coalesce the user-related data from this store and the user-related data from the identity store
// into one single UserStore.
export interface GraphDataStore {
    bannerLogoUrl?: string;
    graphDirectoryObjects: EntityState<Entity<GraphDirectoryObject>>;
    organizationDisplayName?: string;
    organizationId?: string;
    orphanedObjectIds: SerializableSet<string>;
    userDisplayName?: string;
    userGivenName?: string;
    userSurname?: string;
    userPhotoUrl?: string;
    userId?: string;
}

export const GraphDataStore = createInitializer<GraphDataStore>({
    graphDirectoryObjects: graphDirectoryObjectAdapter.getInitialState(),
    orphanedObjectIds: SerializableSet(),
});

export type GraphStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const GraphStatusStore = createInitializer<GraphStatusStore>({
    getBannerLogoForOrganization: Status(),
    getGraphDirectoryObject: statusAdapter.getInitialState(),
    getOrganization: Status(),
    getPhotoForSignedInUser: Status(),
    getSignedInUser: Status(),
});

export interface GraphStore {
    data: GraphDataStore;
    status: GraphStatusStore;
}

export const GraphStore = createInitializer<GraphStore>({
    data: GraphDataStore(),
    status: GraphStatusStore(),
});
