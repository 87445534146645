import {
    azureDevOpsBranchesAdapter,
    azureDevOpsRepoAdapter,
    azureDevOpsRepoContentsAdapter,
} from '../adapters/azure-dev-ops-adapters';
import { statusAdapter } from '../adapters/common/status-adapter';
import { stringAdapter } from '../adapters/common/string-adapter';
import { StoreState } from '../store/store-state';
import { createMapFromEntitiesSelector } from './common';

/**
 * Entity state selectors
 */

const azureDevOpsRepoSelectors = azureDevOpsRepoAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.data.repos
);

const azureDevOpsRepoStatusSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.status.getRepoByCloneUrl
);

const azureDevOpsBranchesSelectors = azureDevOpsBranchesAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.data.branches
);

const azureDevOpsBranchesStatusSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.status.listBranches
);

const azureDevOpsRepoResourcesStatusSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.status.loadRepoResources
);

const azureDevOpsRepoContentsSelectors = azureDevOpsRepoContentsAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.data.repoContents
);

const azureDevOpsRepoContentsStatusSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.status.getRepoContents
);

const azureDevOpsRepoItemSelectors = stringAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.data.repoItems
);

const azureDevOpsRepoItemStatusSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.azureDevOpsStore.status.getRepoItem
);

/**
 * Composed selectors
 */

/** Indexed by clone url */
export const getAzureDevOpsRepos = createMapFromEntitiesSelector(azureDevOpsRepoSelectors.selectAll);

/** Indexed by clone url */
export const getStatusesForGetAzureDevOpsRepos = createMapFromEntitiesSelector(
    azureDevOpsRepoStatusSelectors.selectAll
);

/** Indexed by repo `url` property */
export const getStatusesForLoadAzureDevOpsRepoResources = createMapFromEntitiesSelector(
    azureDevOpsRepoResourcesStatusSelectors.selectAll
);

/** Indexed by repo `url` property */
export const getAzureDevOpsBranches = createMapFromEntitiesSelector(azureDevOpsBranchesSelectors.selectAll);

/** Indexed by repo `url` property */
export const getStatusesForListAzureDevOpsBranches = createMapFromEntitiesSelector(
    azureDevOpsBranchesStatusSelectors.selectAll
);

/** Indexed by branch key */
export const getAzureDevOpsRepoContents = createMapFromEntitiesSelector(azureDevOpsRepoContentsSelectors.selectAll);

/** Indexed by branch key */
export const getStatusesForLoadAzureDevOpsRepoContents = createMapFromEntitiesSelector(
    azureDevOpsRepoContentsStatusSelectors.selectAll
);

/** Indexed by repo item identifier (see `getAzureDevOpsRepoItemId`) */
export const getAzureDevOpsRepoItems = createMapFromEntitiesSelector(azureDevOpsRepoItemSelectors.selectAll);

export const getStatusesForGetAzureDevOpsRepoItem = createMapFromEntitiesSelector(
    azureDevOpsRepoItemStatusSelectors.selectAll
);
