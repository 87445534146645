import { KeyValuePair } from '../types/key-value-pair';
import { SerializableMap } from '../types/serializable-map';

export const getKey = (key: string, caseSensitive = false): string => (caseSensitive ? key : key.toLowerCase());

export const entries = <TValue>(serializableMap: SerializableMap<TValue>): KeyValuePair<string, TValue>[] =>
    values(map(serializableMap, (value, key) => KeyValuePair(key, value)));

export const every = <TValue>(map: SerializableMap<TValue>, fn: (value: TValue, key: string) => boolean): boolean =>
    keys(map).every((key) => fn(map[key], key));

export const filter = <TValue>(
    map: SerializableMap<TValue>,
    fn: (value: TValue, key: string) => boolean
): SerializableMap<TValue> => {
    const filtered: { [key: string]: TValue } = {};
    let allTrue = true;

    forEach(map, (value, key) => {
        if (fn(value, key)) {
            filtered[key] = value;
        } else {
            allTrue = false;
        }
    });

    return allTrue ? map : filtered;
};

export const compact = <TValue>(map: SerializableMap<TValue | undefined>): SerializableMap<TValue> => {
    const filtered: { [key: string]: TValue } = {};

    forEach(map, (value, key) => {
        if (!!value) {
            filtered[key] = value;
        }
    });

    return filtered;
};

export const forEach = <TValue>(map: SerializableMap<TValue>, fn: (value: TValue, key: string) => void): void => {
    keys(map).forEach((key) => fn(map[key], key));
};

export const get = <TValue>(
    map: SerializableMap<TValue>,
    key: string | undefined,
    useCaseSensitiveKey = false
): TValue | undefined =>
    !key ? undefined : has(map, key, useCaseSensitiveKey) ? map[getKey(key, useCaseSensitiveKey)] : undefined;

export const has = (map: SerializableMap<unknown>, key: string, useCaseSensitiveKey = false): boolean =>
    map.hasOwnProperty(getKey(key, useCaseSensitiveKey));

export const keys = (map: SerializableMap<unknown>): string[] => Object.keys(map);

export const map = <TValue, TMapped>(
    map: SerializableMap<TValue>,
    fn: (value: TValue, key: string) => TMapped
): SerializableMap<TMapped> => {
    const mapped: { [key: string]: TMapped } = {};

    forEach(map, (value, key) => {
        mapped[key] = fn(value, key);
    });

    return mapped;
};

export const mapKeys = <TValue>(
    map: SerializableMap<TValue>,
    fn: (value: TValue, key: string) => string
): SerializableMap<TValue> => {
    const mapped: { [key: string]: TValue } = {};

    forEach(map, (value, key) => {
        mapped[fn(value, key)] = value;
    });

    return mapped;
};

export const remove = <TValue>(
    map: SerializableMap<TValue>,
    key: string,
    useCaseSensitiveKey = false
): SerializableMap<TValue> => {
    // Return initial map if the key isn't present
    if (!has(map, key, useCaseSensitiveKey)) {
        return map;
    }

    const copy = { ...map };
    delete copy[getKey(key, useCaseSensitiveKey)];
    return copy;
};

export const set = <TValue>(
    map: SerializableMap<TValue>,
    key: string,
    value: TValue,
    useCaseSensitiveKey = false
): SerializableMap<TValue> => {
    // Return initial map if set wouldn't change value
    if (get(map, key, useCaseSensitiveKey) === value) {
        return map;
    }

    return {
        ...map,
        [getKey(key, useCaseSensitiveKey)]: value,
    };
};

export const size = (map: SerializableMap<unknown>): number => keys(map).length;

export const some = <TValue>(map: SerializableMap<TValue>, fn: (value: TValue, key: string) => boolean): boolean =>
    keys(map).some((key) => fn(map[key], key));

export const toObject = <TValue>(map: SerializableMap<TValue>): { [key: string]: TValue } => map;
export const values = <TValue>(map: SerializableMap<TValue>): TValue[] => Object.values(map);

export const findKey = <TValue>(
    map: SerializableMap<TValue>,
    fn: (value: TValue, key: string) => boolean
): string | undefined => keys(map).find((key) => fn(map[key], key));
