import * as React from 'react';
import { ValueDropdown, ValueDropdownProps } from '../value-dropdown';

export type ResourceDropdownProps<TResourceViewModel extends ResourceViewModel> = Omit<
    ValueDropdownProps<TResourceViewModel>,
    'onRenderOption'
>;

export type ResourceDropdownWrapperProps<TResourceViewModel extends ResourceViewModel> = Omit<
    ResourceDropdownProps<TResourceViewModel>,
    'getOptionKey' | 'getOptionText'
>;

interface ResourceViewModel {
    name: string;
}

const getOptionText = (resource: ResourceViewModel): string => resource.name;

/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Justification: arrow functions have limitations with the use of generics when using JSX
export function ResourceDropdown<TResourceViewModel extends ResourceViewModel>(
    props: ResourceDropdownProps<TResourceViewModel>
): ReturnType<React.FC<ResourceDropdownProps<TResourceViewModel>>> {
    const { getOptionKey, getOptionText: providedGetOptionText } = props;

    return (
        <ValueDropdown<TResourceViewModel>
            {...props}
            getOptionKey={getOptionKey}
            getOptionText={providedGetOptionText ?? getOptionText}
        />
    );
}
/* eslint-enable prefer-arrow/prefer-arrow-functions */

export default ResourceDropdown;
