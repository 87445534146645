import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import devBoxStoppedImage from '../../../content/images/dev-box-stopped.svg';
import devBoxStoppingImage from '../../../content/images/dev-box-stopping.svg';
import devBoxImage from '../../../content/images/dev-box.svg';
import { DevBoxState } from '../../../redux/store/dev-box-state';
import { DevBoxStatusImageKind } from './models';
import { getShowStatusImage, getStatusImageKind } from './selectors';

interface DevBoxStatusImageProps {
    state: DevBoxState;
}

const messages = defineMessages({
    runningImageAlt: {
        id: 'DevBoxStatusImage_RunningImage_Alt',
        defaultMessage: 'Dev box is currently running',
        description: 'Alt text for status image, when dev box is running.',
    },
    stoppedImageAlt: {
        id: 'DevBoxStatusImage_StoppedImage_Alt',
        defaultMessage: 'Dev box is currently stopped',
        description: 'Alt text for status image, when dev box is stopped.',
    },
    stoppingImageAlt: {
        id: 'DevBoxStatusImage_StoppingImage_Alt',
        defaultMessage: 'Dev box is stopping',
        description: 'Alt text for status image, when dev box is stopping.',
    },
});

const getImage = (kind: DevBoxStatusImageKind) => {
    switch (kind) {
        case DevBoxStatusImageKind.Stopped:
            return devBoxStoppedImage;

        case DevBoxStatusImageKind.Stopping:
            return devBoxStoppingImage;

        case DevBoxStatusImageKind.Running:
        default:
            return devBoxImage;
    }
};

const getMessageDescriptor = (kind: DevBoxStatusImageKind) => {
    switch (kind) {
        case DevBoxStatusImageKind.Stopped:
            return messages.stoppedImageAlt;

        case DevBoxStatusImageKind.Stopping:
            return messages.stoppingImageAlt;

        case DevBoxStatusImageKind.Running:
        default:
            return messages.runningImageAlt;
    }
};

export const DevBoxStatusImage: React.FC<DevBoxStatusImageProps> = React.memo((props: DevBoxStatusImageProps) => {
    const { state } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    const showStatusImage = React.useMemo(() => getShowStatusImage(state), [state]);
    const statusImageKind = React.useMemo(() => getStatusImageKind(state), [state]);

    if (!showStatusImage) {
        return <></>;
    }

    return <img alt={formatMessage(getMessageDescriptor(statusImageKind))} src={getImage(statusImageKind)} />;
});

export default DevBoxStatusImage;
