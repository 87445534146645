import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ListProjectsResponse } from '../../../../data/services/data-plane-api/project';
import {
    AggregatedResult,
    ClientError,
    FailureCode,
    FailureOperation,
    isAggregatedFailure,
    isFailureResponse,
} from '../../../../models/common';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { trackTrace } from '../../../../utilities/telemetry/channel';
import {
    loadAddDevBoxFormContent,
    loadDevBoxCardContent,
} from '../../../actions/sub-applications/home/home-action-creators';
import {
    loadDevBoxesForSingleDevCenter,
    loadProjectsForDevCenter,
    loadResourcesForSingleDevCenter,
    loadResourcesForSingleDevCenterError,
    loadResourcesForSingleDevCenterFailed,
    loadResourcesForSingleDevCenterSuccess,
} from '../../../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { LoadResourcesForSingleDevCenterAction } from '../../../actions/sub-applications/single-dev-center/single-dev-center-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { putAndSettle } from '../../../effects/put-and-settle';
import { takeLeading } from '../../../effects/take';
import { getActiveAccountState } from '../../../selector/identity-selectors';
import { ActiveAccountState } from '../../../store/identity-state';

export function* loadResourcesForSingleDevCenterSaga(action: LoadResourcesForSingleDevCenterAction): SagaIterator {
    const { meta } = action;

    try {
        // If we're in a guest account, don't start listing projects
        const activeAccountState: ActiveAccountState = yield select(getActiveAccountState);
        const isGuestAccount = activeAccountState === ActiveAccountState.GuestAccount;

        if (isGuestAccount) {
            yield put(loadResourcesForSingleDevCenterSuccess(meta));
            return;
        }

        // List projects in dev center
        const listProjectsResponse: ListProjectsResponse = yield putAndAwait(loadProjectsForDevCenter(meta));

        if (isFailureResponse(listProjectsResponse)) {
            if (listProjectsResponse.code === FailureCode.REQUEST_SEND_ERROR) {
                // We can expect this error code if a user has inputted a nonexistent devcenter uri, so we don't want to hard fail
                // (If a dev center doesn't exist, we will fail to fetch because the DNS entry doesn't exist)
                yield put(loadResourcesForSingleDevCenterSuccess(meta));
                return;
            }

            yield call(
                trackTrace,
                `List projects for single devcenter failed with unexpected error code: ${listProjectsResponse.code}`
            );
            yield put(loadResourcesForSingleDevCenterFailed({ failure: listProjectsResponse }));
            return;
        }

        // Load dev boxes for all projects in dev center
        const loadDevBoxesResult: AggregatedResult = yield putAndAwait(loadDevBoxesForSingleDevCenter(meta));

        if (isAggregatedFailure(loadDevBoxesResult)) {
            yield put(
                loadResourcesForSingleDevCenterFailed({
                    failure: aggregateFailureResponses(
                        FailureOperation.LoadResourcesForSingleDevCenter,
                        ...loadDevBoxesResult.failures
                    ),
                })
            );
        }

        // Discover data for card content
        yield putAndSettle(loadDevBoxCardContent(meta));

        // Discover data for create
        yield putAndSettle(loadAddDevBoxFormContent(meta));

        yield put(loadResourcesForSingleDevCenterSuccess(meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadResourcesForSingleDevCenter);
        yield put(loadResourcesForSingleDevCenterError({ error }));
    }
}

export function* loadResourcesForSingleDevCenterListenerSaga(): SagaIterator {
    yield takeLeading(loadResourcesForSingleDevCenter, loadResourcesForSingleDevCenterSaga);
}
