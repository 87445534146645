import { AggregatedResult, DataResponse } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import { DevCenterActionType } from './dev-center-actions';

/**
 * Action creators
 */

export const warmAllDevCenterNameRecords = createActionCreatorWithoutPayload<AggregatedResult>(
    DevCenterActionType.WarmAllDevCenterNameRecords,
    { async: true }
);

export const warmAllDevCenterNameRecordsError = createActionCreator<ErrorPayload>(
    DevCenterActionType.WarmAllDevCenterNameRecordsError
);

export const warmAllDevCenterNameRecordsFailed = createActionCreator<FailedPayload>(
    DevCenterActionType.WarmAllDevCenterNameRecordsFailed
);

export const warmAllDevCenterNameRecordsSuccess = createActionCreatorWithoutPayload(
    DevCenterActionType.WarmAllDevCenterNameRecordsSuccess
);

export const warmDevCenterNameRecord = createActionCreator<IndexedPayload, DataResponse>(
    DevCenterActionType.WarmDevCenterNameRecord,
    { async: true }
);

export const warmDevCenterNameRecordError = createActionCreator<ErrorPayload & IndexedPayload>(
    DevCenterActionType.WarmDevCenterNameRecordError
);

export const warmDevCenterNameRecordFailed = createActionCreator<FailedPayload & IndexedPayload>(
    DevCenterActionType.WarmDevCenterNameRecordFailed
);

export const warmDevCenterNameRecordSuccess = createActionCreator<IndexedPayload>(
    DevCenterActionType.WarmDevCenterNameRecordSuccess
);
