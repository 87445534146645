import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ListProjectsResponse, listProjectsByDevCenter } from '../../../../data/services/data-plane-api/project';
import { ClientError, FailureOperation, isSuccessResponse } from '../../../../models/common';
import { getAccessToken } from '../../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../../actions/identity/identity-actions';
import {
    loadProjectsForDevCenter,
    loadProjectsForDevCenterError,
    loadProjectsForDevCenterFailed,
    loadProjectsForDevCenterSuccess,
} from '../../../actions/sub-applications/single-dev-center/single-dev-center-action-creators';
import { LoadProjectsForDevCenterAction } from '../../../actions/sub-applications/single-dev-center/single-dev-center-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { getSingleDevCenterUri } from '../../../selector/sub-applications/single-dev-center-selectors';

export function* loadProjectsForSingleDevCenterSaga(action: LoadProjectsForDevCenterAction): SagaIterator {
    const { meta } = action;

    try {
        const devCenterUri: string | undefined = yield select(getSingleDevCenterUri);

        if (!devCenterUri) {
            throw new ClientError(
                'LoadProjectsForSingleDevCenter dispatched before devCenterId was set.',
                FailureOperation.LoadProjectsForSingleDevCenter
            );
        }

        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const listProjectsResponse: ListProjectsResponse = yield call(
            listProjectsByDevCenter,
            devCenterUri,
            accessToken,
            meta?.activityId
        );

        if (isSuccessResponse(listProjectsResponse)) {
            yield put(loadProjectsForDevCenterSuccess({ result: listProjectsResponse.data }, meta));
        } else {
            yield put(loadProjectsForDevCenterFailed({ failure: listProjectsResponse }, meta));
        }

        yield resolveAction(action, listProjectsResponse);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadProjectsForSingleDevCenter);
        yield put(loadProjectsForDevCenterError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* loadProjectsForSingleDevCenterListenerSaga(): SagaIterator {
    yield takeLeading(loadProjectsForDevCenter, loadProjectsForSingleDevCenterSaga);
}
