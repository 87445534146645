import { ThemeMode } from '../../themes/themes';
import { RemoteAppOption, UserSettings } from '../../types/user-settings';
import { StoreStateSelector } from './common';

/**
 * Basic selectors
 */

export const getThemeModeSelector: StoreStateSelector<ThemeMode> = (store) => store.userSettingsStore.themeMode;

export const getUseMultiMonitorSelector: StoreStateSelector<boolean> = (store) =>
    store.userSettingsStore.useMultiMonitor;

export const getRemoteAppOptionsSelector: StoreStateSelector<RemoteAppOption | undefined> = (store) =>
    store.userSettingsStore.preferredRemoteApp;

export const getUserSettingsSelector: StoreStateSelector<UserSettings> = (store) => store.userSettingsStore;
