import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ClientError, FailureOperation } from '../../../models/common';
import { FeatureFlagSet } from '../../../models/features';
import { getFeaturesFromCurrentLocation } from '../../../utilities/features';
import {
    initializeFeatures,
    initializeFeaturesError,
    initializeFeaturesSuccess,
} from '../../actions/features/features-action-creators';
import { InitializeFeaturesAction } from '../../actions/features/features-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';

export function* initializeFeaturesSaga(action: InitializeFeaturesAction): SagaIterator {
    try {
        const featureFlags: FeatureFlagSet = yield call(getFeaturesFromCurrentLocation);
        yield put(initializeFeaturesSuccess({ result: featureFlags }));
        yield resolveAction(action, { data: featureFlags, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeFeatures);
        yield put(initializeFeaturesError({ error }));
        yield rejectAction(action, error);
    }
}

export function* initializeFeaturesListenerSaga(): SagaIterator {
    yield takeLeading(initializeFeatures, initializeFeaturesSaga);
}
