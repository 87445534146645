import { UnionValueMap } from '../../../types/union-map';
import { deleteGlobalProperty, setGlobalProperty } from './telemetry-action-creators';

/**
 * Action types
 */

type TelemetryActionName = 'DeleteGlobalProperty' | 'SetGlobalProperty';

export type TelemetryActionType = 'DELETE_GLOBAL_PROPERTY' | 'SET_GLOBAL_PROPERTY';

export const TelemetryActionType: UnionValueMap<TelemetryActionName, TelemetryActionType> = {
    DeleteGlobalProperty: 'DELETE_GLOBAL_PROPERTY',
    SetGlobalProperty: 'SET_GLOBAL_PROPERTY',
};

/**
 * Payloads
 */

export interface DeleteGlobalPropertyPayload {
    propertyName: string;
}

export interface SetGlobalPropertyPayload {
    propertyName: string;
    value: string;
}

/**
 * Action shorthands
 */

export type DeleteGlobalPropertyAction = ReturnType<typeof deleteGlobalProperty>;
export type SetGlobalPropertyAction = ReturnType<typeof setGlobalProperty>;
