import React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { ConfirmationDialogComponentProps } from './confirmation-dialog';

export type ConfirmationDialogProperties = Omit<ConfirmationDialogComponentProps, 'hidden' | 'onDismiss'>;

export const ConfirmationDialogContext = React.createContext<
    SurfaceContextWithProperties<ConfirmationDialogProperties>
>({ isOpen: false });

export const ConfirmationDialogContextProvider =
    createSurfaceContextWithPropertiesProvider<ConfirmationDialogProperties>(ConfirmationDialogContext);
