import { UnionMap, UnionValueMap } from '../types/union-map';

export type ApplicationMode = 'AllDevCentersInTenant' | 'SingleDevCenter';

export const ApplicationMode: UnionMap<ApplicationMode> = {
    AllDevCentersInTenant: 'AllDevCentersInTenant',
    SingleDevCenter: 'SingleDevCenter',
};

export type ApplicationModePathname = '/' | '/single-devcenter';

export const ApplicationModePathname: UnionValueMap<ApplicationMode, ApplicationModePathname> = {
    AllDevCentersInTenant: '/',
    SingleDevCenter: '/single-devcenter',
};
