import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { QuickActionName } from '../../constants/app';
import { SerializableSet } from '../../types/serializable-set';
import { add } from '../../utilities/serializable-set';
import { dismissQuickActionSuccess } from '../actions/application/application-action-creators';
import {
    initializeHome,
    initializeHomeError,
    initializeHomeFailed,
    initializeHomeSuccess,
    loadAddDevBoxFormContent,
    loadAddDevBoxFormContentError,
    loadAddDevBoxFormContentFailed,
    loadAddDevBoxFormContentSuccess,
    loadAddEnvironmentFormContent,
    loadAddEnvironmentFormContentError,
    loadAddEnvironmentFormContentFailed,
    loadAddEnvironmentFormContentSuccess,
    loadBackgroundResourcesForHome,
    loadBackgroundResourcesForHomeError,
    loadBackgroundResourcesForHomeFailed,
    loadBackgroundResourcesForHomeSuccess,
    loadControlPlaneResourcesForHome,
    loadControlPlaneResourcesForHomeError,
    loadControlPlaneResourcesForHomeFailed,
    loadControlPlaneResourcesForHomeSuccess,
    loadDevBoxCardContent,
    loadDevBoxCardContentError,
    loadDevBoxCardContentFailed,
    loadDevBoxCardContentSuccess,
    loadEnvironmentCardContent,
    loadEnvironmentCardContentError,
    loadEnvironmentCardContentFailed,
    loadEnvironmentCardContentSuccess,
    loadResourcesForHome,
    loadResourcesForHomeError,
    loadResourcesForHomeFailed,
    loadResourcesForHomeSuccess,
    syncDismissedHomeQuickActionsSuccess,
} from '../actions/sub-applications/home/home-action-creators';
import {
    HomeSubApplicationDataStore,
    HomeSubApplicationStatusStore,
    HomeSubApplicationStore,
} from '../store/sub-application/home-sub-application-store';
import { SubApplicationsStore } from '../store/sub-application/sub-application-store';
import { getPayload } from '../utilities/payload-action';
import { createInitializationStatusReducer } from './initialization-status-reducer';
import { singleDevCenterSubApplicationReducer } from './single-dev-center-sub-application-reducer';
import { createStatusReducer } from './status-reducer';

const isQuickActionForHome = (quickAction: QuickActionName) => quickAction === QuickActionName.ConfigureRemoteDesktop;

export const subApplicationsReducer = combineReducers<SubApplicationsStore>({
    home: combineReducers<HomeSubApplicationStore>({
        data: createReducer(HomeSubApplicationDataStore(), (builder) => {
            builder
                .addCase(dismissQuickActionSuccess, (store, action) => {
                    const { quickAction: quickActionValue } = getPayload(action);
                    const quickAction = quickActionValue as QuickActionName;

                    if (isQuickActionForHome(quickAction)) {
                        store.dismissedQuickActions = add(store.dismissedQuickActions, quickAction);
                    }
                })
                .addCase(syncDismissedHomeQuickActionsSuccess, (store, action) => {
                    const { result } = getPayload(action);
                    store.dismissedQuickActions = SerializableSet(result);
                });
        }),

        status: combineReducers<HomeSubApplicationStatusStore>({
            initialization: createInitializationStatusReducer({
                initializing: initializeHome,
                error: initializeHomeError,
                failed: initializeHomeFailed,
                success: initializeHomeSuccess,
            }),

            loadAddDevBoxFormContent: createStatusReducer({
                inProgress: loadAddDevBoxFormContent,
                error: loadAddDevBoxFormContentError,
                failed: loadAddDevBoxFormContentFailed,
                success: loadAddDevBoxFormContentSuccess,
            }),

            loadAddEnvironmentFormContent: createStatusReducer({
                inProgress: loadAddEnvironmentFormContent,
                error: loadAddEnvironmentFormContentError,
                failed: loadAddEnvironmentFormContentFailed,
                success: loadAddEnvironmentFormContentSuccess,
            }),

            loadBackgroundResources: createStatusReducer({
                inProgress: loadBackgroundResourcesForHome,
                error: loadBackgroundResourcesForHomeError,
                failed: loadBackgroundResourcesForHomeFailed,
                success: loadBackgroundResourcesForHomeSuccess,
            }),

            loadControlPlaneResources: createStatusReducer({
                inProgress: loadControlPlaneResourcesForHome,
                error: loadControlPlaneResourcesForHomeError,
                failed: loadControlPlaneResourcesForHomeFailed,
                success: loadControlPlaneResourcesForHomeSuccess,
            }),

            loadDevBoxCardContent: createStatusReducer({
                inProgress: loadDevBoxCardContent,
                error: loadDevBoxCardContentError,
                failed: loadDevBoxCardContentFailed,
                success: loadDevBoxCardContentSuccess,
            }),

            loadEnvironmentCardContent: createStatusReducer({
                inProgress: loadEnvironmentCardContent,
                error: loadEnvironmentCardContentError,
                failed: loadEnvironmentCardContentFailed,
                success: loadEnvironmentCardContentSuccess,
            }),

            loadResources: createStatusReducer({
                inProgress: loadResourcesForHome,
                error: loadResourcesForHomeError,
                failed: loadResourcesForHomeFailed,
                success: loadResourcesForHomeSuccess,
            }),
        }),
    }),

    singleDevCenter: singleDevCenterSubApplicationReducer,
});
