import { UnionMap, UnionValueMap } from '../types/union-map';

export type TenantCategory = 'Home' | 'ManagedBy' | 'ProjectedBy';

export const TenantCategory: UnionMap<TenantCategory> = {
    Home: 'Home',
    ManagedBy: 'ManagedBy',
    ProjectedBy: 'ProjectedBy',
};

export type TenantName = 'Microsoft';

export const TenantName: UnionMap<TenantName> = {
    Microsoft: 'Microsoft',
};

export const TenantId: UnionValueMap<TenantName, string> = {
    Microsoft: '72f988bf-86f1-41af-91ab-2d7cd011db47',
};
