import { IDocumentCardStyleProps, IDocumentCardStyles } from '@fluentui/react';
import { getSemanticColor } from '../../utilities/styles';

export const DocumentCardStyles = (props: IDocumentCardStyleProps): Partial<IDocumentCardStyles> => {
    const { theme } = props;

    return {
        root: {
            backgroundColor: getSemanticColor(theme, 'documentCardBackground'),
        },
    };
};
