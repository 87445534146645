import { DeepPartial } from '@fluentui/merge-styles';
import { IDropdownStyleProps, IDropdownStyles } from '@fluentui/react';
import { getSemanticColor } from '../../utilities/styles';

export const DropdownStyles = (props: IDropdownStyleProps): DeepPartial<IDropdownStyles> => {
    const { theme } = props;

    if (!theme) {
        return {};
    }

    return {
        dropdownDivider: {
            backgroundColor: getSemanticColor(theme, 'dropdownDividerBackground'),
            height: 0.5,
        },
        subComponentStyles: {
            label: {
                root: {
                    '::after': {
                        content: ' !important', //Used to override default asterix for required form fields
                    },
                },
            },
        },
    };
};
